import React, { useState } from "react";

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useRequestResetPasswordMutation } from "../../api/auth.generated";
import Alert from "../alerts/Alert";
import Button from "../buttons/Button";
import CancelButton from "../buttons/CancelButton";
import ValidatedInput from "../inputs/ValidatedInput";

interface Properties {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
}

export default function ForgotPasswordModal({
  showModal,
  setShowModal,
}: Properties) {
  const { t } = useTranslation();
  const [validationError, setValidationError] = useState<string>("");
  const [inputData, setInputData] = useState({
    email: "",
  });
  const [requestResetPassword] = useRequestResetPasswordMutation();
  const [inProgress, setInProgress] = useState(false);

  const handleCloseClick = () => {
    setShowModal(false);
  };

  const handleChangePassword = () => {
    setInProgress(true);
    setValidationError("");
    if (inputData.email === "") {
      setValidationError(t("forgotPassword.emailNotSet") || "");
    } else {
      requestResetPassword({
        email: inputData.email,
      }).then((response: any) => {
        if (response.data && response.data.status === "ok") {
          toast.success(t("app.forgot-password-modal.link-sent"));
        } else if (response.error) {
          toast.warning(response.error.data.message);
        }
        setInProgress(false);
        setShowModal(false);
      });
    }
  };

  return (
    <div className={`${showModal ? "block" : "hidden"}`}>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-normal">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/* content */}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-192 bg-white outline-none focus:outline-none">
            {/* header */}
            <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
              <h3 className="text-2xl font-semibold">
                <span>{t("app.forgot-password-modal.title")}</span>
              </h3>
              <button
                type="button"
                className="p-1 ml-auto bg-transparent border-0 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={handleCloseClick}
              >
                <span className="bg-transparent h-6 w-6 text-2xl outline-none focus:outline-none flex items-center">
                  <FontAwesomeIcon icon={faTimes} />
                </span>
              </button>
            </div>
            {/* body */}
            <div className="relative p-6 pt-2 flex-auto">
              {validationError.length > 0 ? (
                <Alert variant="danger">
                  {validationError.length ? validationError : ""}
                </Alert>
              ) : (
                ""
              )}
              <div className="col-span-2">
                <ValidatedInput
                  label={t("app.form.email")}
                  field="email"
                  type="email"
                  value={inputData.email}
                  errors={[]}
                  required
                  callback={(e) =>
                    setInputData({
                      ...inputData,
                      email: e.currentTarget.value,
                    })
                  }
                />
              </div>
              {/* footer */}
              <div className="flex items-center pt-3">
                <Button
                  className="mr-2"
                  onClick={handleChangePassword}
                  disabled={inProgress}
                >
                  <span>{t("button.change")}</span>
                </Button>
                <CancelButton onClick={handleCloseClick} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black" />
    </div>
  );
}
