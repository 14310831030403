import React from "react";

import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import Badge from "./Badge";

interface ComponentProperties {
  email: string;
}

function EmailBadge({ email }: ComponentProperties): JSX.Element {
  return (
    <Badge
      bgClass="bg-gray-200"
      color="text-gray-500 text-xs"
      icon={faEnvelope}
      label={email}
      pill
    />
  );
}

export default EmailBadge;
