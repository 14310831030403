import React, { ChangeEvent } from "react";

import propTypes from "prop-types";
import { useTranslation } from "react-i18next";

import {
  PermissionObjectType,
  PermissionObjectTypeKey,
} from "../../api/permissionRule.generated";
import ObjectSelectOptions from "../../enums/PermissionObjectSelectOptions";
import SelectOption from "../../enums/SelectOption";

interface Properties {
  selectedOption?: PermissionObjectTypeKey;
  excludeOptions?: PermissionObjectType[];
  onSelect: (item: PermissionObjectType) => void;
  disabled?: boolean;
}

function SelectPermissionObjectType({
  selectedOption,
  excludeOptions,
  onSelect,
  disabled,
}: Properties) {
  const { t } = useTranslation();

  const handleOnChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const item = ObjectSelectOptions.find(
      (option) => option.key === e.target.value
    ) as SelectOption<PermissionObjectTypeKey>;
    onSelect(item as PermissionObjectType);
  };

  const filteredOptions = ObjectSelectOptions.filter(
    (option) =>
      !excludeOptions?.map((excludeOpt) => excludeOpt.key).includes(option.key)
  );

  return (
    <div>
      <div className="flex text-gray-500 text-sm mb-1">
        <span>{t("app.form.permissionObjectType")}</span>
      </div>
      <select
        className="block text-gray-700 mb-2 border border-gray-300 w-full p-2 rounded"
        defaultValue={selectedOption}
        onChange={handleOnChange}
        disabled={disabled}
      >
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <option value="" />
        {filteredOptions.map((item) => (
          <option
            key={`index-${item.key}`}
            label={t(item.name) || ""}
            value={item.key}
          >
            <span>{t(item.name)}</span>
          </option>
        ))}
      </select>
    </div>
  );
}

SelectPermissionObjectType.propTypes = {
  selectedOption: propTypes.string,
};

SelectPermissionObjectType.defaultProps = {
  selectedOption: undefined,
  excludeOptions: [],
  disabled: false,
};

export default SelectPermissionObjectType;
