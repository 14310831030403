import React, { useEffect, useState } from "react";

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

import { Advisor } from "../../api/documents.generated";
import {
  ExternalLinkConfiguration,
  ExternalLinkConfigurationStatusKey,
  ValidationError,
} from "../../api/external-link.generated";
import ExternalLinkConfigurationStatusType from "../../enums/ExternalLinkConfigurationStatusType";
import SelectOption from "../../enums/SelectOption";
import Button from "../buttons/Button";
import UpdateButton from "../buttons/UpdateButton";
import Card from "../card/Card";
import CardBody from "../card/CardBody";
import SelectExternalLinkConfigurationStatusType from "../inputs/SelectExternalLinkConfigurationStatusType";
import ValidatedInput from "../inputs/ValidatedInput";

interface Properties {
  item?: ExternalLinkConfiguration;
  addingAdvisor?: Advisor;
  // id: string;
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  callback?: (
    advisor?: Advisor | undefined,
    login?: string | undefined,
    password?: string | undefined,
    status?: ExternalLinkConfigurationStatusKey | undefined
  ) => void;
}

function ExternalLinkConfigurationModal({
  item,
  addingAdvisor,
  showModal,
  callback,
  setShowModal,
}: Properties) {
  const { t } = useTranslation();
  const [itemData, setItemData] = useState(item);
  const [formErrors] = useState<ValidationError[]>([]);

  const mode = item ? "edit" : "create";

  useEffect(() => {
    setItemData(item);
  }, [item]);

  const handleSaveClick = () => {
    callback?.(
      addingAdvisor,
      itemData?.login,
      itemData?.password,
      itemData?.status?.key
    );
  };

  const handleCloseClick = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-normal">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/* content */}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none w-[500px]">
            {/* header */}
            <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
              <h3 className="text-2xl font-semibold">
                {mode === "edit"
                  ? `${item?.advisorFirstame} ${item?.advisorLastname}`
                  : addingAdvisor?.businessName}
              </h3>
              <button
                type="button"
                className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => handleCloseClick()}
              >
                <span className="bg-transparent text-black h-6 w-6 text-2xl outline-none focus:outline-none flex items-center">
                  ×
                </span>
              </button>
            </div>
            {/* body */}
            <div>
              <Card className="border-b-0">
                <CardBody>
                  <form autoComplete="off">
                    <div className="py-1 grid grid-cols-1 gap-4">
                      <SelectExternalLinkConfigurationStatusType
                        selectedOption={item?.status?.key}
                        onChange={(e) => {
                          setItemData({
                            ...itemData,
                            status:
                              ExternalLinkConfigurationStatusType.filter(
                                (
                                  i: SelectOption<ExternalLinkConfigurationStatusKey>
                                ) => i.key === e.currentTarget.value
                              ).pop() ??
                              ExternalLinkConfigurationStatusType.pop(),
                          });
                        }}
                      />

                      <form autoComplete="off">
                        <ValidatedInput
                          label={t("app.form.login")}
                          field="login"
                          type="text"
                          value={itemData?.login ?? item?.login ?? ""}
                          errors={formErrors}
                          required={false}
                          disabled={false}
                          callback={(e) =>
                            setItemData({
                              ...itemData,
                              login: e.currentTarget.value,
                            })
                          }
                        />
                      </form>
                      <ValidatedInput
                        label={t("app.form.password")}
                        field="password"
                        type="password"
                        value={itemData?.password ?? item?.password ?? ""}
                        errors={formErrors}
                        required={false}
                        disabled={false}
                        callback={(e) =>
                          setItemData({
                            ...itemData,
                            password: e.currentTarget.value,
                          })
                        }
                      />
                    </div>
                  </form>
                </CardBody>
              </Card>
            </div>
            {/* footer */}
            <div className="flex gap-4 items-center justify-center p-6 pt-0 border-t-0 border-solid border-blueGray-200 rounded-b">
              <Button
                className="bg-gray-200 text-gray-700 hover:bg-gray-300 focus:ring-gray-300"
                onClick={() => handleCloseClick()}
                icon={faTimes}
              >
                <span>{t("button.close")}</span>
              </Button>
              <UpdateButton onClick={() => handleSaveClick()} />
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black" />
    </>
  );
}

ExternalLinkConfigurationModal.defaultProps = {
  item: undefined,
  addingAdvisor: undefined,
  callback: undefined,
};

export default ExternalLinkConfigurationModal;
