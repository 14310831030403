import React from "react";

import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Properties {
  label?: string;
  icon?: IconDefinition;
  children?: React.ReactNode;
}

function CardHeader({ icon, label, children }: Properties): JSX.Element {
  return (
    <div className="px-5 py-3 border-b border-gray-200 text-sm">
      <span>
        {icon ? <FontAwesomeIcon icon={icon} size="1x" className="mr-2" /> : ""}
        {label}
      </span>
      <span className="float-right">{children ?? ""}</span>
    </div>
  );
}

CardHeader.defaultProps = {
  children: undefined,
  icon: undefined,
  label: undefined,
};

export default CardHeader;
