import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import { DocumentTypeKeys } from "../../enums/DocumentTypes";
import { documentStatusZuz, documentStatusCheck } from "../../enums/Status";

interface Properties {
  callback: (name: string) => void | undefined;
  filters: string[];
  docType: string;
}

function DocumentStatusFilter({
  docType: mode,
  filters,
  callback,
}: Properties) {
  const { t } = useTranslation();
  const handleChange = (type: string): void => {
    callback(type);
  };

  const lastIndex = documentStatusZuz.length - 1;

  return (
    <div className="inline-block rounded border border-gray-300 text-sm overflow-hidden">
      {mode === DocumentTypeKeys.ZUZ
        ? documentStatusZuz.map((i, index) => (
            <button
              key={`document-status-filter-item-${i.type}`}
              onClick={() => handleChange(i.type)}
              type="button"
              className={
                `py-1 px-3 text-white ` +
                `${
                  filters.includes(i.type)
                    ? `${i.activeBgClass} hover:bg-opacity-90 `
                    : "bg-white text-gray-600 hover:bg-gray-100 "
                } ` +
                `${index !== lastIndex ? "border-r border-gray-300 " : ""}`
              }
            >
              <FontAwesomeIcon icon={i.icon} />
              <span className="pl-3">{t(`status.${i.type}`)}</span>
            </button>
          ))
        : documentStatusCheck.map((i, index) => (
            <button
              key={`document-status-filter-item-${i.type}`}
              onClick={() => handleChange(i.type)}
              type="button"
              className={
                `py-1 px-3 text-white ` +
                `${
                  filters.includes(i.type)
                    ? `${i.activeBgClass} hover:bg-opacity-90 `
                    : "bg-white text-gray-600 hover:bg-gray-100 "
                } ` +
                `${index !== lastIndex ? "border-r border-gray-300 " : ""}`
              }
            >
              <FontAwesomeIcon icon={i.icon} />
              <span className="pl-3">{t(`status.${i.type}`)}</span>
            </button>
          ))}
    </div>
  );
}

export default DocumentStatusFilter;
