import React, { ChangeEvent, useRef, useState } from "react";

import { faFile, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DndProvider, DropTargetMonitor, useDrop } from "react-dnd";
import { HTML5Backend, NativeTypes } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useDoUploadMutation } from "../../api/upload.generated";
import Button from "../buttons/Button";
import UpdateButton from "../buttons/UpdateButton";
import Card from "../card/Card";
import CardBody from "../card/CardBody";

interface Properties {
  showModal: boolean;
  header: string;
  setShowModal: (value: boolean) => void;
  callback: (imageId: string, file: File) => void;
}

function FileUploadModal({
  showModal,
  header,
  setShowModal,
  callback,
}: Properties) {
  const { t } = useTranslation();
  const [file, setFile] = useState<any>();
  const [doUpload] = useDoUploadMutation();
  const [isUploading, setIsUploading] = useState(false);

  const handleSaveClick = () => {
    setIsUploading(true);
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      doUpload({ body: formData }).then((response: any) => {
        if (response.data) {
          callback(response.data.id, file);
          setFile(null);
        }
        if (response.error) {
          toast.error(t("error.generic"));
        }
        setIsUploading(false);
      });
    }
  };

  const onFileSelect = (item: { files: any[] }) => {
    setFile(item.files[0]);
  };

  const handleCloseClick = () => {
    setShowModal(false);
  };

  return (
    <div>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-normal">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/* content */}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/* header */}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-2xl font-semibold">
                    <span>{header}</span>
                  </h3>
                  <button
                    type="button"
                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => handleCloseClick()}
                  >
                    <span className="bg-transparent text-black h-6 w-6 text-2xl outline-none focus:outline-none flex items-center">
                      ×
                    </span>
                  </button>
                </div>
                {/* body */}
                <div>
                  <Card className="border-b-0">
                    <CardBody>
                      <div className="py-1 grid grid-cols-1 gap-4">
                        <DndProvider backend={HTML5Backend}>
                          <form
                            className="mt-6"
                            onSubmit={(e) => e.preventDefault()}
                          >
                            {file ? (
                              <SelectedFileView file={file} />
                            ) : (
                              <DropArea onFileSelect={onFileSelect} />
                            )}
                          </form>
                        </DndProvider>
                      </div>
                    </CardBody>
                  </Card>
                </div>
                {/* footer */}
                <div className="flex gap-4 items-center justify-center p-6 pt-0 border-t-0 border-solid border-blueGray-200 rounded-b">
                  <Button
                    className="bg-gray-200 text-gray-700 hover:bg-gray-300 focus:ring-gray-300"
                    onClick={() => handleCloseClick()}
                    icon={faTimes}
                  >
                    <span>{t("button.close")}</span>
                  </Button>
                  <UpdateButton
                    disabled={isUploading}
                    onClick={() => handleSaveClick()}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black" />
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default FileUploadModal;

export function SelectedFileView({ file }: { file: File }) {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <div className="text-2xl text-gray-400 mb-2">
        <FontAwesomeIcon icon={faFile} />
      </div>
      <div className="text-sm text-center text-gray-400">{file?.name}</div>
    </div>
  );
}

export function DropArea({
  onFileSelect,
}: {
  onFileSelect: (item: any) => void;
}) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item: { files: any[] }) {
        if (onFileSelect) {
          onFileSelect(item);
        }
      },
      canDrop(item: any) {
        return true;
      },
      collect: (monitor: DropTargetMonitor) => {
        const item = monitor.getItem() as any;

        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
    }),
    [{ onFileSelect }]
  );

  const handleOnChange = (e: ChangeEvent) => {
    if (onFileSelect) {
      onFileSelect(e.target);
    }
  };

  return (
    <label
      ref={drop}
      className={`h-32 p-4 flex justify-center items-center border-2 border-dashed border-gray-300 rounded-md outline-none cursor-pointer ${
        canDrop && isOver ? "bg-gray-100" : "bg-white"
      } hover:bg-gray-50 focus:bg-gray-50 transiton duration-200 ease-in-out relative`}
      htmlFor="input-file-upload"
    >
      <input
        ref={inputRef}
        type="file"
        id="input-file-upload"
        className="hidden"
        onChange={handleOnChange}
      />
      <p className="w-3/4 select-none text-sm text-center text-gray-500">
        Súbor preneste do tejto oblasti alebo kliknite pre výber
      </p>
    </label>
  );
}
