import React from "react";

import {
  faBan,
  faCheckCircle,
  faExclamationTriangle,
  faHourglassStart,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

import { IdentificationStatus } from "../../api/identification.generated";
import IdentificationStates from "../../enums/IdentificationStates";
import Badge from "./Badge";

interface Properties {
  status: IdentificationStatus | undefined;
}

function IdentificationStatusBadge({ status }: Properties): JSX.Element {
  let icon = faCheckCircle;
  let bgClass = "bg-spf-green";
  let color = "text-stone-100";

  if (status && status.key === IdentificationStates.SUCCESS_IRELEVANT) {
    bgClass = "bg-spf-yellow";
    color = "text-stone-100";
    icon = faExclamationTriangle;
  }

  if (status && status.key === IdentificationStates.REFUSED) {
    bgClass = "bg-purple-500";
    color = "text-stone-100";
    icon = faBan;
  }

  if (status && status.key === IdentificationStates.ERROR) {
    bgClass = "bg-spf-red";
    color = "text-stone-100";
    icon = faTimesCircle;
  }

  if (status && status.key === IdentificationStates.INITIATED) {
    bgClass = "bg-blue-500";
    color = "text-stone-100";
    icon = faHourglassStart;
  }

  if (status && status.key === IdentificationStates.DECLINED) {
    bgClass = "bg-spf-violet";
    color = "text-stone-100";
    icon = faBan;
  }

  return (
    <Badge
      bgClass={bgClass}
      color={color}
      icon={icon}
      label={`status.${status?.key}`}
      pill
    />
  );
}

export default IdentificationStatusBadge;
