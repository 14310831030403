import React, { FormEvent, useState } from "react";

import {
  faDownload,
  faList,
  faSearch,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { useNavigate } from "react-router";

import {
  ExternalLink,
  useGetAdminExternalLinksQuery,
} from "../../api/external-link.generated";
import Alert from "../../components/alerts/Alert";
import CountBadge from "../../components/badges/CountBadge";
import ExternalLinkStatusBadge from "../../components/badges/ExternalLinkStatusBadge";
import AddButton from "../../components/buttons/AddButton";
import Button from "../../components/buttons/Button";
import Card from "../../components/card/Card";
import CardBody from "../../components/card/CardBody";
import CardFooter from "../../components/card/CardFooter";
import CardHeader from "../../components/card/CardHeader";
import ExternalLinkCategoryFilter from "../../components/filters/ExternalLinkCategoryFilter";
import ExternalLinkStatusFilter from "../../components/filters/ExternalLinkStatusFilter";
import PageHeader from "../../components/page/PageHeader";
import { useExternalLinksQuery } from "../../hooks/externalLinks";
import useFormatters from "../../hooks/formatter";
import { getRoleId } from "../../hooks/role";
import { makeRoute } from "../../hooks/route";
import AllRoutes from "../route/Route";
import TableBodyLoading from "../table/TableBodyLoading";
import TableColumnHeadingButton from "../table/TableColumnHeadingButton";
import TablePageSize from "../table/TablePageSize";
import TablePagination from "../table/TablePagination";

function ExternalLinksListView() {
  const [query, setQuery] = useExternalLinksQuery();
  const navigate = useNavigate();
  const { formatTableTime } = useFormatters();
  const [searchValue, setSearchValue] = useState(query.keyword);

  const { currentData, isFetching, isError } = useGetAdminExternalLinksQuery(
    { "X-Role-Id": getRoleId(), ...query },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const searchFilterChange = (event: FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearchValue(value);
  };

  const searchLinks = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === "keyup" &&
      (event as React.KeyboardEvent).key !== "Enter"
    ) {
      return null;
    }

    return setQuery({
      ...query,
      page: 1,
      keyword: searchValue,
    });
  };

  const redirectToDetail = (id: string) => {
    const path = makeRoute(AllRoutes.EXTERNAL_LINKS_EDIT, {
      id,
    });
    navigate(path);
  };

  const onExternalLinkCategoryFilterChanged = (name: string) => {
    const { categories } = query;
    const newCategoriesTypes = [...categories];
    const existingIndex = newCategoriesTypes.indexOf(name);
    if (existingIndex >= 0) {
      newCategoriesTypes.splice(existingIndex, 1);
    } else {
      newCategoriesTypes.push(name);
    }
    setQuery({
      ...query,
      page: 1,
      categories: newCategoriesTypes,
    });
  };

  const onExternalLinkStatusFilterChanged = (name: string) => {
    const { states } = query;
    const newExternalLinkStates = [...states];
    const existingIndex = newExternalLinkStates.indexOf(name);
    if (existingIndex >= 0) {
      newExternalLinkStates.splice(existingIndex, 1);
    } else {
      newExternalLinkStates.push(name);
    }
    setQuery({
      ...query,
      page: 1,
      states: newExternalLinkStates,
    });
  };

  const downloadCSVTemplate = () => {
    window.open("/csv/externalLinkExportTemplate.csv");
  };

  if (isError) return <div>{t("app.page.error.api") || ""}</div>;

  return (
    <>
      <PageHeader
        icon={AllRoutes.EXTERNAL_LINKS_LIST.icon}
        label={t("app.page.external-links-settings.heading")}
      >
        <Button icon={faDownload} onClick={downloadCSVTemplate}>
          CSV šablóna
        </Button>
        <Button icon={faUpload} to={AllRoutes.EXTERNAL_LINKS_IMPORT}>
          Import
        </Button>
        <AddButton to={AllRoutes.EXTERNAL_LINKS_ADD} />
      </PageHeader>

      <Card>
        <CardHeader
          icon={faList}
          label={t("app.customer.list.card.header") || ""}
        >
          <span className="float-right">
            <CountBadge count={currentData?.count} />
          </span>
        </CardHeader>
        <CardBody>
          <div className="flex p-4 mb-4 bg-slate-200 rounded-lg">
            <div className="flex ml-2">
              <ExternalLinkStatusFilter
                callback={onExternalLinkStatusFilterChanged}
                filters={query.states}
              />
            </div>
            <div className="flex ml-2">
              <ExternalLinkCategoryFilter
                callback={onExternalLinkCategoryFilterChanged}
                filters={query.categories}
              />
            </div>
            <div className="flex ml-auto">
              <div className="flex border border-gray-300 rounded">
                <span className="w-8 text-center text-gray-300 px-1 border-gray-300 border-r bg-white rounded-l">
                  <FontAwesomeIcon
                    icon={faSearch}
                    className="text-gray-600 mt-1.5"
                  />
                </span>
                <input
                  type="text"
                  className="border-none text-gray-700 px-4 text-sm py-1 outline-0 rounded-r"
                  placeholder={t("table.filter.name") || ""}
                  value={searchValue}
                  onKeyUp={(e) => searchLinks(e)}
                  onChange={(e) => searchFilterChange(e)}
                />
              </div>
              <input
                type="button"
                className="ml-1 border border-gray-300 bg-white text-gray-700 px-4 text-sm py-1 outline-0 rounded"
                value={t("button.search") as string}
                onClick={(e) => searchLinks(e)}
              />
            </div>
          </div>

          <table>
            <thead>
              <tr>
                <td>
                  <TableColumnHeadingButton
                    field="name"
                    label={t("table.header.name") || ""}
                    sort={query?.sort}
                  />
                </td>
                <td>
                  <TableColumnHeadingButton
                    field="category"
                    label={t("table.header.category") || ""}
                    sort={query?.sort}
                  />
                </td>
                <td>
                  <TableColumnHeadingButton
                    field="login"
                    label={t("table.header.login") || ""}
                    sort={query?.sort}
                  />
                </td>
                <td>
                  <TableColumnHeadingButton
                    field="authType"
                    label={t("table.header.authType") || ""}
                    sort={query?.sort}
                  />
                </td>
                <td>
                  <TableColumnHeadingButton
                    field="link"
                    label={t("table.header.link") || ""}
                    sort={query?.sort}
                  />
                </td>
                <td>
                  <TableColumnHeadingButton
                    field="login"
                    label={t("table.header.status") || ""}
                    sort={query?.sort}
                  />
                </td>
                <td>
                  <TableColumnHeadingButton
                    field="login"
                    label={t("table.header.updatedAt") || ""}
                    sort={query?.sort}
                  />
                </td>
              </tr>
            </thead>
            {isFetching && !currentData ? (
              <TableBodyLoading cols={7} rows={query.size ?? 10} />
            ) : (
              <tbody>
                {currentData
                  ? currentData?.items?.map((link: ExternalLink) => (
                      <tr
                        key={`index-${link.id}`}
                        className="cursor-pointer"
                        onClick={() => redirectToDetail(link.id ?? "")}
                      >
                        <td className="text-spf-primary">{link.name}</td>
                        <td>{link.category?.name}</td>
                        <td>{link.authType?.name}</td>
                        <td>{link.type?.name}</td>
                        <td>{link.displayLink}</td>
                        <td>
                          <ExternalLinkStatusBadge status={link.status} />
                        </td>
                        <td>{formatTableTime(link.updatedAt)}</td>
                      </tr>
                    ))
                  : t("table.noData") || ""}
              </tbody>
            )}
          </table>
          {!isFetching && currentData?.count === 0 ? (
            <Alert className="mt-4">{t("table.noData") || ""}</Alert>
          ) : (
            <span />
          )}
        </CardBody>
        <CardFooter>
          <div className="">
            <TablePagination
              callback={setQuery}
              query={query}
              count={currentData?.count ?? 0}
            />
          </div>
          <div className="ml-auto">
            <TablePageSize query={query} callback={setQuery} />
          </div>
        </CardFooter>
      </Card>
    </>
  );
}

export default ExternalLinksListView;
