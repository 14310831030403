import React, { useState } from "react";

import { t } from "i18next";

import {
  ExternalLink,
  useGetExternalLinkFavouritesQuery,
} from "../../api/external-link.generated";
import Card from "../../components/card/Card";
import ExternalLinkItem from "../../components/links/ExternalLinkItem";
import ExternalLinkConfigurationInfoModal from "../../components/modals/ExternalLinkConfigurationInfoModal";
import ExternalLinkAuthTypeEnum from "../../enums/ExternalLinkAuthTypeEnum";
import { ExternalLinkStatusKeys } from "../../enums/ExternalLinkStatusType";
import Roles from "../../enums/Roles";
import {
  getAdvisorDataFromRole,
  getAdvisorSections,
} from "../../hooks/advisor";
import { isLinkEnabled } from "../../hooks/externalLinks";
import { getRoleId } from "../../hooks/role";
import setLinkAccessToken from "../../hooks/setLinkAccessToken";
import { useAppSelector } from "../../hooks/store";
import { authSelector } from "../auth/authSlice";
import PageLoader from "../loader/PageLoader";
import RoutePath from "../route/RouteKeys";
import LinkCard from "./LinkCard";

function DashboardView() {
  const [initializedCards] = useState<string[]>([
    "init",
    "init",
    "init",
    "init",
    "init",
    "init",
  ]);
  const { currentRole } = useAppSelector(authSelector);
  const [clickedItem, setClickedItem] = useState<ExternalLink | undefined>();
  const [showLinkInfoModal, setShowLinkInfoModal] = useState(false);

  const advisorData = getAdvisorDataFromRole(
    currentRole,
    !currentRole?.role?.key
  );
  const sectors = getAdvisorSections(advisorData);
  const { currentData: favouriteLinks, refetch: refetchFavourites } =
    useGetExternalLinkFavouritesQuery({ "X-Role-Id": getRoleId() });

  const showFavouriteModal = (item: ExternalLink) => {
    if (isLinkEnabled(item, sectors, advisorData)) {
      if (
        item.authType?.key === ExternalLinkAuthTypeEnum.SSO ||
        item.authType?.key === ExternalLinkAuthTypeEnum.NO_AUTHENTIFICATION
      ) {
        setLinkAccessToken();
        window.open(item.displayLink, "_blank");
      } else if (item.linkConfigurations) {
        setClickedItem(item);
        setShowLinkInfoModal(true);
      }
    }
  };

  return (
    <>
      {initializedCards.length < 6 ? <PageLoader /> : ""}
      {currentRole.role?.key === Roles.ASSISTANT ||
      (currentRole.role?.key === Roles.ADVISOR &&
        favouriteLinks?.items &&
        favouriteLinks.items.length > 0) ? (
        <Card className="mb-8">
          <div className="pt-4 px-8 text-xs font-semibold uppercase">
            {t("app.favouritesDashboard") || ""}
          </div>
          <div className="px-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-8">
            {favouriteLinks?.items
              ?.filter((item) => {
                return item.status?.key === ExternalLinkStatusKeys.ACTIVE;
              })
              .map((item) => (
                <ExternalLinkItem
                  isFavourite={false}
                  item={item}
                  key={item.id}
                  enabledFavouriteIcons={false}
                  enabled={isLinkEnabled(item, sectors, advisorData)}
                  enabledShadow={false}
                  borderColorClass="slate-300"
                  callbackLinkClick={showFavouriteModal}
                />
              ))}
          </div>
        </Card>
      ) : (
        ""
      )}

      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-8 mb-8">
        <LinkCard
          routeKey={RoutePath.CUSTOMERS_LIST}
          dataTestId="/admin/dashboard/link-customers"
        />
        <LinkCard
          routeKey={RoutePath.DOCUMENTS_LIST_ZUZ}
          dataTestId="/admin/dashboard/link-documents-zuz"
        />
        <LinkCard
          routeKey={RoutePath.DOCUMENTS_LIST_CHECK}
          dataTestId="/admin/dashboard/link-documents-check"
        />
        <LinkCard
          routeKey={RoutePath.IDENTIFICATION_LIST}
          dataTestId="/admin/dashboard/link-identifications"
        />
        {currentRole.role?.key === Roles.ASSISTANT ||
        currentRole.role?.key === Roles.ADVISOR ? (
          <LinkCard routeKey={RoutePath.EXTERNAL_LINKS_DASHBOARD_VIEW} />
        ) : null}
      </div>

      <ExternalLinkConfigurationInfoModal
        link={clickedItem}
        showModal={showLinkInfoModal}
        setShowModal={setShowLinkInfoModal}
        callback={() => {
          refetchFavourites();
        }}
      />
    </>
  );
}

export default DashboardView;
