import React from "react";

import { AnswerClientPartnerType } from "../../../../models/DocumentContent";

export type CPfield = "client" | "partner";

type QuestionABCErrors = {
  client?: string | undefined;
  partner?: string | undefined;
};

interface Props {
  name: string;
  question: string;
  answerA: string | React.ReactNode;
  answerB: string | React.ReactNode;
  answerC: string | React.ReactNode;
  answers: AnswerClientPartnerType;
  errors: QuestionABCErrors;
  onChange: (answers: AnswerClientPartnerType) => void;
}

function QuestionABC({
  name,
  question,
  answerA,
  answerB,
  answerC,
  answers,
  onChange,
  errors,
}: Props) {
  const { client: clientError, partner: partnerError } = errors;

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: CPfield
  ) => {
    const newAnswers = { ...answers };
    newAnswers[field] = event.target.value;
    onChange(newAnswers);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>,
    field: CPfield
  ) => {
    if (event.target instanceof HTMLInputElement) {
      const newAnswers = { ...answers };
      newAnswers[field] = undefined;
      onChange(newAnswers);
    }
  };

  return (
    <table className="mb-4">
      <tbody>
        <tr className="border border-slate-300 bg-slate-200">
          <td className="px-2 py-1 font-bold w-full">{question}</td>
          <td className="!text-center px-2 py-1 text-sm w-36">Klient</td>
          <td className="!text-center px-2 py-1 text-sm w-36">Partner</td>
        </tr>
        <tr>
          <td className="px-2 py-1">
            <strong>A: </strong>
            {answerA}
          </td>
          <td
            className={`!text-center px-2 py-1 ${
              clientError || partnerError ? "!bg-red-100" : ""
            }`}
          >
            <input
              type="radio"
              id={`${name}ClientQuestionRadio`}
              name={`${name}ClientQuestionRadio`}
              value="A"
              checked={answers.client === "A"}
              onChange={(event) => handleChange(event, "client")}
              onClick={(event) => handleClick(event, "client")}
              data-testid={`/documents/zuz/esg/input-${name}-ClientQuestionRadio`}
            />
          </td>
          <td
            className={`!text-center px-2 py-1 ${
              partnerError ? "!bg-red-100" : ""
            }`}
          >
            <input
              type="radio"
              id={`${name}PartnerQuestionRadio`}
              name={`${name}PartnerQuestionRadio`}
              value="A"
              checked={answers.partner === "A"}
              onChange={(event) => handleChange(event, "partner")}
              onClick={(event) => handleClick(event, "partner")}
              data-testid={`/documents/zuz/esg/input-${name}-PartnerQuestionRadio`}
            />
          </td>
        </tr>
        <tr>
          <td className="px-2 py-1">
            {" "}
            <strong>B: </strong>
            {answerB}
          </td>
          <td
            className={`!text-center px-2 py-1 ${
              clientError || partnerError ? "!bg-red-100" : ""
            }`}
          >
            <input
              type="radio"
              id={`${name}ClientQuestionRadio`}
              name={`${name}ClientQuestionRadio`}
              value="B"
              checked={answers.client === "B"}
              onChange={(event) => handleChange(event, "client")}
              onClick={(event) => handleClick(event, "client")}
              data-testid={`/documents/zuz/esg/input-${name}-ClientQuestionRadio`}
            />
          </td>
          <td
            className={`!text-center px-2 py-1 ${
              partnerError ? "!bg-red-100" : ""
            }`}
          >
            <input
              type="radio"
              id={`${name}PartnerQuestionRadio`}
              name={`${name}PartnerQuestionRadio`}
              value="B"
              checked={answers.partner === "B"}
              onChange={(event) => handleChange(event, "partner")}
              onClick={(event) => handleClick(event, "partner")}
              data-testid={`/documents/zuz/esg/input-${name}-PartnerQuestionRadio`}
            />
          </td>
        </tr>
        <tr>
          <td className="px-2 py-1">
            {" "}
            <strong>C: </strong>
            {answerC}
          </td>
          <td
            className={`!text-center px-2 py-1 ${
              clientError || partnerError ? "!bg-red-100" : ""
            }`}
          >
            <input
              type="radio"
              id={`${name}ClientQuestionRadio`}
              name={`${name}ClientQuestionRadio`}
              value="C"
              checked={answers.client === "C"}
              onChange={(event) => handleChange(event, "client")}
              onClick={(event) => handleClick(event, "client")}
              data-testid={`/documents/zuz/esg/input-${name}-ClientQuestionRadio`}
            />
          </td>
          <td
            className={`!text-center px-2 py-1 ${
              partnerError ? "!bg-red-100" : ""
            }`}
          >
            <input
              type="radio"
              id={`${name}PartnerQuestionRadio`}
              name={`${name}PartnerQuestionRadio`}
              value="C"
              checked={answers.partner === "C"}
              onChange={(event) => handleChange(event, "partner")}
              onClick={(event) => handleClick(event, "partner")}
              data-testid={`/documents/zuz/esg/input-${name}-PartnerQuestionRadio`}
            />
          </td>
        </tr>
        {clientError || partnerError ? (
          <tr>
            <td className="p-1 text-red-400 !text-left" colSpan={3}>
              {clientError || partnerError}
            </td>
          </tr>
        ) : null}
      </tbody>
    </table>
  );
}

export default QuestionABC;
