import React from "react";

interface Properties {
  className?: string;
  children: React.ReactNode;
}

function CardBody({ className, children }: Properties): JSX.Element {
  return <div className={`px-4 pt-4 pb-4 ${className}`}>{children}</div>;
}

CardBody.defaultProps = {
  className: "",
};

export default CardBody;
