import React from "react";

import { faSyncAlt, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useNavigate } from "react-router";

import ApplicationRoute from "../../features/route/ApplicationRoute";

interface Properties {
  className: string;
  children: React.ReactNode;
  disabled: boolean;
  inProgress: boolean;
  type: "button" | "submit" | undefined;
  icon?: IconDefinition;
  to?: ApplicationRoute;
  form?: string;
  dataTestId?: string;
  onClick?: () => void;
}

function Button({
  className,
  children,
  icon,
  type,
  inProgress,
  disabled,
  to,
  form,
  dataTestId,
  onClick,
}: Properties) {
  const navigate = useNavigate();
  const finalOnClick =
    to !== undefined
      ? () => {
          navigate(to.path);
        }
      : onClick;

  return (
    <button
      className={
        `px-2 py-1 lg:px-4 lg:py-2 rounded ` +
        `transition-colors duration-500 ` +
        `${
          disabled
            ? "bg-spf-primary bg-opacity-75 text-white"
            : "bg-spf-primary text-white cursor-pointer hover:bg-spf-primary-hover focus:ring-offset-2 focus:ring-2 focus:ring-spf-primary-hover"
        } ` +
        `${className} `
      }
      // eslint-disable-next-line react/button-has-type
      type={type}
      disabled={disabled}
      onClick={finalOnClick}
      form={form}
      data-testid={dataTestId}
    >
      {icon !== undefined ? (
        <span className="pt-2 pr-2">
          <FontAwesomeIcon
            icon={inProgress ? faSyncAlt : icon}
            spin={inProgress}
          />
        </span>
      ) : (
        <span />
      )}
      {children}
    </button>
  );
}

Button.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  inProgress: PropTypes.bool,
  type: PropTypes.string,
};

Button.defaultProps = {
  className: "",
  dataTestId: "",
  disabled: false,
  inProgress: false,
  icon: undefined,
  type: "button",
  to: undefined,
  onClick: undefined,
  form: undefined,
};

export default Button;
