import ClientLanguage from "../enums/ClientLanguage";

export const LANG_STORAGE_KEY = "app.portal.lang";

export function getLanguage(): string {
  const lang = localStorage.getItem(LANG_STORAGE_KEY);
  return lang ?? ClientLanguage.SK.toString();
}

export function setLanguage(newLanguage: string): void {
  localStorage.setItem(LANG_STORAGE_KEY, newLanguage);
}

const allHooks = {
  getLanguage,
  setLanguage,
};

export default allHooks;
