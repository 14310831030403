import React from "react";

import {
  SustainabiltyRequestType,
  SustainabiltyRequest as SustainabiltyRequestEnum,
} from "../../../../models/DocumentContent";

export type CPfield = "client" | "partner";

type SustainabiltyRequestSelectErrors = {
  client?: string | undefined;
  partner?: string | undefined;
};

interface Props {
  name: string;
  question: string;
  answers: SustainabiltyRequestType;
  onChange: (answers: SustainabiltyRequestType) => void;
  errors: SustainabiltyRequestSelectErrors;
}

function SustainabiltyRequestSelect({
  question,
  answers,
  name,
  onChange,
  errors,
}: Props) {
  const { client: clientError, partner: partnerError } = errors;
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: CPfield
  ) => {
    const newAnswers = { ...answers };
    const newValue = event.target.value as SustainabiltyRequestEnum;
    onChange({
      ...newAnswers,
      [field]: [newValue],
    });
  };

  const handleClick = (field: CPfield) => {
    const newAnswers = { ...answers };
    onChange({
      ...newAnswers,
      [field]: [],
    });
  };

  return (
    <>
      <h3 className="border border-slate-300 bg-slate-200 px-2 py-1 font-bold">
        {question}
      </h3>
      <table className="mb-4 w-96">
        <tbody>
          <tr className="">
            <td className="px-2 py-1 font-bold" />
            <td className="!text-center uppercase text-gray-500 px-2 py-1 !text-sm w-1/10">
              Všetky súčasne
            </td>
            <td className="!text-center uppercase text-gray-500 px-2 py-1 !text-sm w-1/10">
              Aspoň jedna z nich
            </td>
          </tr>
          <tr className={clientError || partnerError ? "bg-red-100" : ""}>
            <td
              className={`px-2 py-1 ${
                clientError || partnerError ? "text-red-400" : ""
              }`}
            >
              Klient
            </td>
            <td className="px-2 py-1 text-center">
              <input
                type="radio"
                id={`${name}ClientQuestionRadio`}
                name={`${name}ClientQuestionRadio`}
                value={SustainabiltyRequestEnum.ALL_THE_SAME_TIME}
                checked={answers.client?.includes(
                  SustainabiltyRequestEnum.ALL_THE_SAME_TIME
                )}
                onClick={() => handleClick("client")}
                onChange={(event) => handleChange(event, "client")}
                data-testid={`/documents/zuz/esg/input-${name}-ClientQuestionRadio`}
              />
            </td>
            <td className="!text-center px-2 py-1">
              <input
                type="radio"
                id={`${name}ClientQuestionRadio`}
                name={`${name}ClientQuestionRadio`}
                value={SustainabiltyRequestEnum.ATLEAST_ONE}
                checked={answers.client?.includes(
                  SustainabiltyRequestEnum.ATLEAST_ONE
                )}
                onClick={() => handleClick("client")}
                onChange={(event) => handleChange(event, "client")}
                data-testid={`/documents/zuz/esg/input-${name}-ClientQuestionRadio`}
              />
            </td>
          </tr>
          {clientError || partnerError ? (
            <tr>
              <td className="p-1 text-red-400" colSpan={3}>
                {clientError || partnerError}
              </td>
            </tr>
          ) : null}
          <tr className={partnerError ? "bg-red-100" : ""}>
            <td className={`px-2 py-1 ${partnerError ? "text-red-400" : ""}`}>
              Partner
            </td>
            <td className="px-2 py-1 text-center">
              <input
                type="radio"
                id={`${name}PartnerQuestionRadio`}
                name={`${name}PartnerQuestionRadio`}
                value={SustainabiltyRequestEnum.ALL_THE_SAME_TIME}
                checked={answers.partner?.includes(
                  SustainabiltyRequestEnum.ALL_THE_SAME_TIME
                )}
                onClick={() => handleClick("partner")}
                onChange={(event) => handleChange(event, "partner")}
                data-testid={`/documents/zuz/esg/input-${name}-PartnerQuestionRadio`}
              />
            </td>
            <td className="!text-center px-2 py-1">
              <input
                type="radio"
                id={`${name}PartnerQuestionRadio`}
                name={`${name}PartnerQuestionRadio`}
                value={SustainabiltyRequestEnum.ATLEAST_ONE}
                checked={answers.partner?.includes(
                  SustainabiltyRequestEnum.ATLEAST_ONE
                )}
                onClick={() => handleClick("partner")}
                onChange={(event) => handleChange(event, "partner")}
                data-testid={`/documents/zuz/esg/input-${name}-PartnerQuestionRadio`}
              />
            </td>
          </tr>
          {partnerError ? (
            <tr>
              <td className="p-1 text-red-400" colSpan={3}>
                {partnerError}
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
    </>
  );
}

export default SustainabiltyRequestSelect;
