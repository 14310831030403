enum Color {
  PRIMARY = "#97BF0D",
  PRIMARY_75 = "#688b9b",
  PRIMARY_50 = "#99b1bb",
  PRIMARY_25 = "#cbd7dd",
  DANGER = "#cc6666",
  DANGER_LIGHT = "#ebb2af",
  GRAY_900 = "#131e23",
  GRAY_800 = "#1a3540",
  GRAY_700 = "#354b55",
  GRAY_600 = "#50626b",
  GRAY_500 = "#6b7a81",
  GRAY_400 = "#889398",
  GRAY_300 = "#a5adb0",
  GRAY_200 = "#384660",
  GRAY_100 = "#ebebeb",
  GRAY_50 = "#f5f5f5",
  GRAY_0 = "#ffffff",
}

export default Color;
