import { useState } from "react";

import { useNavigate } from "react-router";

import { Advisor, AdvisorSector } from "../api/advisors.generated";
import { ExternalLink } from "../api/external-link.generated";
import { Central } from "../api/me.generated";
import { ExternalLinkStatusKeys } from "../enums/ExternalLinkStatusType";
import ExternalLinkTypeEnum from "../enums/ExternalLinkTypeEnum";
import AllRoutes from "../features/route/Route";
import useLogger from "./logger";
import { SortablePaginationQuery, useQuery } from "./query";
import { makeRoute } from "./route";

export interface externalLinkQuery extends SortablePaginationQuery {
  keyword: string;
  categories: Array<string>;
  states: Array<string>;
}

export type ExternalLinkQueryDispatcher = (query: externalLinkQuery) => void;

type ExternalLinkQueryHook = [
  externalLinkQuery,
  (query: externalLinkQuery) => void
];

const localStorageSizeKey = "external-link.page.size";

function getExternalLinksPageSizeFromStorage(): number {
  return Number(localStorage.getItem(localStorageSizeKey)) || 20;
}

function setExternalLinksPageSizeToStorage(newSize: number): void {
  localStorage.setItem(localStorageSizeKey, newSize.toString());
}

export function useExternalLinksQuery(): ExternalLinkQueryHook {
  const navigate = useNavigate();
  const query = useQuery();
  const { logger } = useLogger();
  const [externalLinkQuery, setExternalLinkQuery] = useState<externalLinkQuery>(
    {
      page: query.page ?? 1,
      size: query.size ?? getExternalLinksPageSizeFromStorage(),
      sort: query.sort ? [query.sort] : ["createdAt:desc"],
      keyword: query.keyword ?? "",
      categories: query.categories ? query.categories.split(",") : [],
      states: query.states
        ? query.states.split(",")
        : [ExternalLinkStatusKeys.ACTIVE, ExternalLinkStatusKeys.DEACTIVATED],
    }
  );

  logger.debug("PARSED EXTERNAL LINK QUERY", externalLinkQuery);

  return [
    externalLinkQuery,
    (updatedExternalLinkQuery: externalLinkQuery) => {
      const queryString = Object.keys(updatedExternalLinkQuery)
        .map((key) => {
          const parameter =
            updatedExternalLinkQuery[key as keyof externalLinkQuery];
          if (Array.isArray(parameter)) {
            const val = parameter.join(",");
            return val.length > 0 ? `${key}=${val}` : undefined;
          }

          return (parameter ?? "").toString().length > 0
            ? `${key}=${parameter}`
            : undefined;
        })
        .filter((item) => item !== undefined)
        .join("&");

      logger.debug("QUERY STRING", queryString);

      // Important order: First update the URI/history, than the state because
      // it forces a repaint of the component
      setExternalLinksPageSizeToStorage(updatedExternalLinkQuery.size ?? 10);
      navigate(`${makeRoute(AllRoutes.EXTERNAL_LINKS_LIST)}?${queryString}`);
      setExternalLinkQuery(updatedExternalLinkQuery);
    },
  ];
}

export function isLinkEnabled(
  externalLink: ExternalLink,
  sectors?: AdvisorSector[],
  advisor?: Advisor | Central
): boolean {
  if (
    externalLink.type?.toString() ===
      ExternalLinkTypeEnum.PERSONALIZED.toString() &&
    !advisor
  ) {
    return false;
  }

  if (
    externalLink.type?.key === ExternalLinkTypeEnum.PERSONALIZED &&
    (!externalLink.linkConfigurations ||
      externalLink.linkConfigurations.length === 0 ||
      externalLink.linkConfigurations.filter((config) => {
        return config.advisorId === advisor?.id;
      }).length === 0)
  ) {
    return false;
  }

  const mandatorySectors = externalLink.sectors
    ?.filter((sector) => {
      return sector.mandatory;
    })
    .map((sector) => {
      return sector.sectorType?.key;
    });

  // return false;

  const userSectors = sectors?.map((sector) => {
    return sector.type?.key;
  });

  if (!mandatorySectors) {
    return true;
  }

  for (const sector of mandatorySectors) {
    if (!userSectors?.includes(sector)) {
      return false;
    }
  }

  return true;
}

const allHooks = { useExternalLinksQuery, isLinkEnabled };

export default allHooks;
