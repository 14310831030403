import React, { useContext } from "react";

import { InvestmentIntentionError } from "../../../../hooks/documentValidation";
import {
  InvestmentIntentionOnce,
  InvestmentIntentionRepeated,
} from "../../../../models/DocumentContent";
import DocumentZuzContext from "../../DocumentZuzFormViewContext";

interface Props {
  investmentIntentionOnce: InvestmentIntentionOnce[];
  investmenIntentionRepeated: InvestmentIntentionRepeated[];
  errors: InvestmentIntentionError[];
  onChange: (
    investmentIntentionOnce: InvestmentIntentionOnce[],
    investmentIntentionsRepeated: InvestmentIntentionRepeated[]
  ) => void;
}

function InvestmentIntenttionTableOverview({
  investmentIntentionOnce,
  investmenIntentionRepeated,
  errors,
  onChange,
}: Props) {
  const ctx = useContext(DocumentZuzContext);

  const getError = (
    frontendID: string,
    field: keyof InvestmentIntentionError,
    parentField: string
  ) => {
    const error = errors.find((e) => e.frontendID === frontendID);

    if (!!error && !!ctx.getFieldError(parentField)) {
      return error[field] ?? "";
    }
    return "";
  };

  return (
    <table className="table-auto">
      <thead>
        <tr className="border border-slate-400 bg-gray-50">
          <td className="w-2/12 border-r border-slate-400">
            Investícia (1,2,3 ...)
          </td>
          <td className="w-8/12 border-r border-slate-400">Popis riešenia</td>
          <td className="w-2/12">Zodpovedá investičnej stratégii (áno/nie)</td>
        </tr>
      </thead>
      <tbody className="border border-slate-400">
        {investmentIntentionOnce.map((item, index) => (
          <tr className="border border-slate-400" key={item.frontendID}>
            <td className="border-r border-slate-400">
              Investicia {index + 1}
            </td>
            <td className="border-r border-slate-400">
              <input
                className={`w-full py-2 px-4 rounded-lg ${
                  getError(
                    item.frontendID,
                    "description",
                    "investmentIntentionsOnce"
                  )
                    ? "border border-red-500"
                    : ""
                }`}
                type="text"
                placeholder="Popis riešenia"
                name={`investmentIntentionOnce[${index}].description`}
                value={item.description ?? ""}
                onChange={(e) => {
                  const intention = {
                    ...investmentIntentionOnce[index],
                    description: e.target.value,
                  };

                  const newinvestmentIntentionOnce = [
                    ...investmentIntentionOnce,
                  ];
                  newinvestmentIntentionOnce[index] = intention;

                  onChange(
                    newinvestmentIntentionOnce,
                    investmenIntentionRepeated
                  );
                }}
                data-testid={`/documents/zuz/esg/input-investmentIntentionOnce[${index}].description`}
              />
              {getError(
                item.frontendID,
                "description",
                "investmentIntentionsOnce"
              ) ? (
                <p className="text-red-500 text-left text-sm">
                  {getError(
                    item.frontendID,
                    "description",
                    "investmentIntentionsOnce"
                  )}
                </p>
              ) : null}
            </td>
            <td>
              <input
                className={`w-full py-2 px-4 rounded-lg ${
                  getError(
                    item.frontendID,
                    "achivable",
                    "investmentIntentionsOnce"
                  )
                    ? "border border-red-500"
                    : ""
                }`}
                type="text"
                placeholder="áno / nie"
                name={`investmentIntentionOnce[${index}].achivable`}
                value={item.achivable ?? ""}
                onChange={(e) => {
                  const newinvestmentIntentionOnce = [
                    ...investmentIntentionOnce,
                  ];
                  newinvestmentIntentionOnce[index].achivable = e.target.value;
                  onChange(
                    newinvestmentIntentionOnce,
                    investmenIntentionRepeated
                  );
                }}
                data-testid={`/documents/zuz/esg/input-investmentIntentionOnce[${index}].description`}
              />
              {getError(
                item.frontendID,
                "achivable",
                "investmentIntentionsOnce"
              ) ? (
                <p className="text-red-500 text-left text-sm">
                  {getError(
                    item.frontendID,
                    "achivable",
                    "investmentIntentionsOnce"
                  )}
                </p>
              ) : null}
            </td>
          </tr>
        ))}
        {investmenIntentionRepeated.map((item, index) => (
          <tr className="border border-slate-400" key={item.frontendID}>
            <td className="border-r border-slate-400">
              Investícia {index + 5}
            </td>
            <td className="border-r border-slate-400">
              <input
                className={`w-full py-2 px-4 rounded-lg ${
                  getError(
                    item.frontendID,
                    "description",
                    "investmentIntentionsRepeated"
                  )
                    ? "border border-red-500"
                    : ""
                }`}
                type="text"
                placeholder="Popis riešenia"
                name={`investmenIntentionRepeated[${index}].description`}
                value={item.description ?? ""}
                onChange={(e) => {
                  const newInvestmenIntentionRepeated = [
                    ...investmenIntentionRepeated,
                  ];
                  const newIndex = {
                    ...newInvestmenIntentionRepeated[index],
                  };
                  newIndex.description = e.target.value;
                  newInvestmenIntentionRepeated[index] = newIndex;
                  onChange(
                    investmentIntentionOnce,
                    newInvestmenIntentionRepeated
                  );
                }}
                data-testid={`/documents/zuz/esg/input-investmenIntentionRepeated[${index}].description`}
              />
              {getError(
                item.frontendID,
                "description",
                "investmentIntentionsRepeated"
              ) ? (
                <p className="text-red-500 text-left text-sm">
                  {getError(
                    item.frontendID,
                    "description",
                    "investmentIntentionsRepeated"
                  )}
                </p>
              ) : null}
            </td>
            <td>
              <input
                className={`w-full py-2 px-4 rounded-lg ${
                  getError(
                    item.frontendID,
                    "achivable",
                    "investmentIntentionsRepeated"
                  )
                    ? "border border-red-500"
                    : ""
                }`}
                type="text"
                placeholder="áno / nie"
                name={`investmenIntentionRepeated[${index}].achivable`}
                value={item.achivable ?? ""}
                onChange={(e) => {
                  const newInvestmenIntentionRepeated = [
                    ...investmenIntentionRepeated,
                  ];
                  const newIndex = {
                    ...newInvestmenIntentionRepeated[index],
                  };
                  newIndex.achivable = e.target.value;
                  newInvestmenIntentionRepeated[index] = newIndex;
                  onChange(
                    investmentIntentionOnce,
                    newInvestmenIntentionRepeated
                  );
                }}
                data-testid={`/documents/zuz/esg/input-investmenIntentionRepeated[${index}].achivable`}
              />
              {getError(
                item.frontendID,
                "achivable",
                "investmentIntentionsRepeated"
              ) ? (
                <p className="text-red-500 text-left text-sm">
                  {getError(
                    item.frontendID,
                    "achivable",
                    "investmentIntentionsRepeated"
                  )}
                </p>
              ) : null}
            </td>
          </tr>
        ))}
        {Array.from({
          length:
            8 -
            (investmenIntentionRepeated.length +
              investmentIntentionOnce.length),
        }).map((_, index) => (
          <tr
            className="border border-slate-400"
            key={`empty-table-row-${index as number}`}
          >
            <td className="border-r border-slate-400">&nbsp;</td>
            <td className="border-r border-slate-400">&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default InvestmentIntenttionTableOverview;
