import Cookies from "js-cookie";

import { getRoleId } from "./role";
import { getAccessToken } from "./token";

type SSOCookie = {
  expires: Date;
  domain: string;
  path: string;
  secure: boolean;
  httpOnly: boolean;
};

type WindowExtended = Window &
  typeof globalThis & {
    APP_SSO_COOKIE_DOMAIN: string;
  };
const w = window as WindowExtended;

const cookieSettings = (): SSOCookie => {
  return {
    expires: new Date(new Date().getTime() + 1 * 60000),
    domain:
      w.APP_SSO_COOKIE_DOMAIN || process.env.REACT_APP_SSO_COOKIE_DOMAIN || "",
    path: "/",
    secure: true,
    httpOnly: false,
  };
};

function setLinkAccessToken() {
  Cookies.set("dvc_token1", getAccessToken() ?? "", cookieSettings());
  Cookies.set("dvc_token2", getRoleId() ?? "", cookieSettings());
}

export default setLinkAccessToken;
