import SelectOption from "./SelectOption";

const PermissionRuleTypePositionOptions: SelectOption<string>[] = [
  {
    key: "SP",
    name: "Spoločnosť",
  },
  {
    key: "KR",
    name: "Krajský riaditeľ",
  },
  {
    key: "RR",
    name: "Regionálny riaditeľ",
  },
  {
    key: "OR",
    name: "Oblastný riaditeľ",
  },
  {
    key: "M",
    name: "Manažér",
  },
  {
    key: "S3",
    name: "Špecialista III.",
  },
  {
    key: "S2",
    name: "Špecialista II.",
  },
  {
    key: "S1",
    name: "Špecialista I.",
  },
  {
    key: "SK",
    name: "Samostatný konzultant",
  },
  {
    key: "K2",
    name: "Konzultant II.",
  },
  {
    key: "K1",
    name: "Konzultant I.",
  },
  {
    key: "T",
    name: "Tiper",
  },
  {
    key: "BP",
    name: "Broker Pool",
  },
  {
    key: "BP90",
    name: "Broker Pool 90",
  },
  {
    key: "BP85",
    name: "Broker Pool 85",
  },
  {
    key: "BP70",

    name: "Broker Pool 70",
  },
  {
    key: "BP00",
    name: "Broker Pool 00",
  },
];

export default PermissionRuleTypePositionOptions;
