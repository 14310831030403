import { baseSplitApi as api } from "./baseApi";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    synchronizeSingleAdvisor: build.mutation<
      SynchronizeSingleAdvisorApiResponse,
      SynchronizeSingleAdvisorApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/synchronization/advisors/${queryArg.advisorId}/sync`,
        method: "PATCH",
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    synchronizeAdvisors: build.mutation<
      SynchronizeAdvisorsApiResponse,
      SynchronizeAdvisorsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/synchronization/advisors/${queryArg.days}`,
        method: "POST",
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    synchronizeAllEntities: build.mutation<
      SynchronizeAllEntitiesApiResponse,
      SynchronizeAllEntitiesApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/synchronization/all/${queryArg.days}`,
        method: "POST",
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    synchronizeAssistants: build.mutation<
      SynchronizeAssistantsApiResponse,
      SynchronizeAssistantsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/synchronization/assistants/`,
        method: "POST",
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    synchronizeCentrals: build.mutation<
      SynchronizeCentralsApiResponse,
      SynchronizeCentralsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/synchronization/centrals/`,
        method: "POST",
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    synchronizeCustomersSingleDiff: build.mutation<
      SynchronizeCustomersSingleDiffApiResponse,
      SynchronizeCustomersSingleDiffApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/synchronization/customers/single-sync-diff/`,
        method: "POST",
        body: queryArg.singleClientSyncRequest,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    synchronizeCustomers: build.mutation<
      SynchronizeCustomersApiResponse,
      SynchronizeCustomersApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/synchronization/customers/${queryArg.days}`,
        method: "POST",
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    synchronizeSendCustomerEmail: build.mutation<
      SynchronizeSendCustomerEmailApiResponse,
      SynchronizeSendCustomerEmailApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/synchronization/send-customer-sync-email`,
        method: "POST",
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    synchronizeCustomerIcmData: build.mutation<
      SynchronizeCustomerIcmDataApiResponse,
      SynchronizeCustomerIcmDataApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/synchronization/update-customer-icm-data`,
        method: "PATCH",
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as spfApi };
export type SynchronizeSingleAdvisorApiResponse =
  /** status 200 OK */ SuccessResponse200;
export type SynchronizeSingleAdvisorApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** uuid */
  advisorId: string;
};
export type SynchronizeAdvisorsApiResponse =
  /** status 200 OK */ SuccessResponse200;
export type SynchronizeAdvisorsApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** int */
  days: number;
};
export type SynchronizeAllEntitiesApiResponse =
  /** status 200 OK */ SuccessResponse200;
export type SynchronizeAllEntitiesApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** int */
  days: number;
};
export type SynchronizeAssistantsApiResponse =
  /** status 200 OK */ SuccessResponse200;
export type SynchronizeAssistantsApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
};
export type SynchronizeCentralsApiResponse =
  /** status 200 OK */ SuccessResponse200;
export type SynchronizeCentralsApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
};
export type SynchronizeCustomersSingleDiffApiResponse =
  /** status 200 actualDiff, diffAfterSync, ICMClient */ SingleCustomerDiffResponse;
export type SynchronizeCustomersSingleDiffApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** pin or ico of customer for diff */
  singleClientSyncRequest: SingleClientSyncRequest;
};
export type SynchronizeCustomersApiResponse =
  /** status 200 OK */ SuccessResponse200;
export type SynchronizeCustomersApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** int */
  days: number;
};
export type SynchronizeSendCustomerEmailApiResponse =
  /** status 200 OK */ SuccessResponse200;
export type SynchronizeSendCustomerEmailApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
};
export type SynchronizeCustomerIcmDataApiResponse =
  /** status 200 ok */ SuccessResponse200;
export type SynchronizeCustomerIcmDataApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
};
export type SuccessResponse200 = {
  id?: string;
  status?: string;
};
export type ValidationError = {
  code?: string;
  field?: string;
  message?: string;
};
export type ErrorResponse400 = {
  code?: number;
  data?: ValidationError[];
  message?: string;
};
export type ErrorResponse401 = {
  code?: number;
  data?: {
    [key: string]: string;
  }[];
  message?: string;
};
export type ErrorResponse500 = {
  code?: number;
  data?: {
    [key: string]: string;
  }[];
  message?: string;
};
export type ModelIcmAddress = {
  city?: string;
  street?: string;
  zip?: string;
};
export type ModelIcmTime = {
  "time.Time"?: string;
};
export type ModelIcmGenderKey = "muz" | "zena" | "ziadne";
export type ModelGcClientsAdvisorFields = {
  name?: string;
  personalNumber?: string;
  surname?: string;
};
export type ModelGcClientsTypeKey = "FO" | "FOP" | "PO";
export type GcClient = {
  authorizedPersonPosition?: string;
  businessAddress?: ModelIcmAddress;
  businessname?: string;
  contractAgreement?: boolean;
  contractSignedAt?: ModelIcmTime;
  cop?: string;
  correspondenceAddress?: ModelIcmAddress;
  dateOfBirth?: ModelIcmTime;
  dateOfConsent?: ModelIcmTime;
  email?: string;
  firstname?: string;
  foregnClient?: boolean;
  gender?: ModelIcmGenderKey;
  hasRights?: ModelGcClientsAdvisorFields[];
  icmId?: string;
  icmStatus?: boolean;
  ico?: string;
  lastUpdatedAt?: ModelIcmTime;
  lastname?: string;
  note?: string;
  otherIdCardNumber?: string;
  passportNumber?: string;
  permanentAddress?: ModelIcmAddress;
  personalAdvisorFields?: ModelGcClientsAdvisorFields;
  personalMarketingInformation?: boolean;
  phoneNumber?: string;
  pin?: string;
  title?: string;
  type?: ModelGcClientsTypeKey;
  updatedByIcm?: string;
};
export type SingleCustomerDiffResponse = {
  actualDiff?: string;
  diffAfterSync?: string;
  icmClientToSync?: GcClient;
};
export type SingleClientSyncRequest = {
  ico?: string;
  pin?: string;
};
export type ErrorResponse404 = {
  code?: number;
  data?: {
    [key: string]: string;
  }[];
  message?: string;
};
export const {
  useSynchronizeSingleAdvisorMutation,
  useSynchronizeAdvisorsMutation,
  useSynchronizeAllEntitiesMutation,
  useSynchronizeAssistantsMutation,
  useSynchronizeCentralsMutation,
  useSynchronizeCustomersSingleDiffMutation,
  useSynchronizeCustomersMutation,
  useSynchronizeSendCustomerEmailMutation,
  useSynchronizeCustomerIcmDataMutation,
} = injectedRtkApi;
