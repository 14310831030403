import React, { useState } from "react";

import { faCrosshairs, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
  useChangeMyPasswordMutation,
  UserProfile,
} from "../../api/me.generated";
import Alert from "../alerts/Alert";
import Button from "../buttons/Button";
import CancelButton from "../buttons/CancelButton";
import ValidatedInput from "../inputs/ValidatedInput";

interface Properties {
  user: UserProfile;
  showModal: boolean;
  setShowModal: (value: boolean) => void;
}

export default function ChangePasswordModal({
  user,
  showModal,
  setShowModal,
}: Properties) {
  const { t } = useTranslation();
  const [validationError, setValidationError] = useState<string>("");
  const [inProgress] = useState(false);
  const [inputData, setInputData] = useState({
    oldPassword: "",
    newPassword: "",
    repeatPassword: "",
  });

  const [changePasswordRequest] = useChangeMyPasswordMutation();

  const handleCloseClick = () => {
    setShowModal(false);
  };

  const handleChangePassword = () => {
    setValidationError("");
    if (inputData.oldPassword === "") {
      setValidationError(t("changePassword.oldPasswordNotSet") || "");
      return;
    }
    if (inputData.newPassword === "") {
      setValidationError(t("changePassword.newPasswordNotSet") || "");
      return;
    }
    if (inputData.repeatPassword === "") {
      setValidationError(t("changePassword.repeatPasswordNotSet") || "");
      return;
    }
    if (inputData.repeatPassword !== inputData.newPassword) {
      setValidationError(t("changePassword.repeatPasswordNotEqual") || "");
      return;
    }
    changePasswordRequest({
      userChangePasswordRequest: {
        new: inputData.newPassword,
        old: inputData.oldPassword,
      },
    }).then((response: any) => {
      setValidationError("");
      if (response.data && response.data.status === "ok") {
        toast.success(t("changePassword.success"));
        setShowModal(false);
      }
      if (response.error && response.error.status === 400) {
        setValidationError(t("changePassword.oldMatchFailed") || "");
      }
    });
  };

  return (
    <div className={`${showModal ? "block" : "hidden"}`}>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-normal">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/* content */}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-192 bg-white outline-none focus:outline-none">
            {/* header */}
            <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
              <h3 className="text-2xl font-semibold">
                <span>{t("app.change-password-modal.title")}</span>
              </h3>
              <button
                type="button"
                className="p-1 ml-auto bg-transparent border-0 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={handleCloseClick}
              >
                <span className="bg-transparent h-6 w-6 text-2xl outline-none focus:outline-none flex items-center">
                  <FontAwesomeIcon icon={faTimes} />
                </span>
              </button>
            </div>
            {/* body */}
            <div className="relative p-6 pt-7 flex-auto">
              {validationError.length > 0 ? (
                <Alert variant="danger">
                  {validationError.length ? validationError : ""}
                </Alert>
              ) : (
                ""
              )}
              <div className="col-span-2">
                <ValidatedInput
                  label={t("app.form.oldPassword")}
                  field="oldPassword"
                  type="password"
                  value={inputData.oldPassword}
                  errors={[]}
                  required
                  callback={(e) =>
                    setInputData({
                      ...inputData,
                      oldPassword: e.currentTarget.value,
                    })
                  }
                />
              </div>
              <div className="col-span-2">
                <ValidatedInput
                  label={t("app.form.newPassword")}
                  field="newPassword"
                  type="password"
                  value={inputData.newPassword}
                  errors={[]}
                  required
                  callback={(e) =>
                    setInputData({
                      ...inputData,
                      newPassword: e.currentTarget.value,
                    })
                  }
                />
              </div>
              <div className="col-span-2">
                <ValidatedInput
                  label={t("app.form.repeatPassword")}
                  field="repeatPassword"
                  type="password"
                  value={inputData.repeatPassword}
                  errors={[]}
                  required
                  callback={(e) =>
                    setInputData({
                      ...inputData,
                      repeatPassword: e.currentTarget.value,
                    })
                  }
                />
              </div>
            </div>
            {/* footer */}
            <div className="flex gap-4 items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
              <Button onClick={handleChangePassword} disabled={inProgress}>
                <span>{t("button.change")}</span>
              </Button>
              <CancelButton onClick={handleCloseClick} withIcon={false} />
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black" />
    </div>
  );
}
