/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext } from "react";

import { privacyPolicyAgree } from "../../../enums/Document";
import useDocumentGetters from "../../../hooks/documentContent";
import { Document } from "../../../models/Document";
import DocumentZuzContext from "../DocumentZuzFormViewContext";
import PageCardHeading from "./PageCardHeading";

interface Properties {
  document: Document;
  setDocument: React.Dispatch<Document>;
}

function Page06Card({ document, setDocument }: Properties) {
  const { isChecked, handleCheckboxChange } = useDocumentGetters();
  const ctx = useContext(DocumentZuzContext);
  return (
    <>
      <PageCardHeading title="Súhlas so spracovaním osobných údajov Klienta / Partnera" />
      <div className="px-4">
        <p className="mt-2">
          Týmto udeľujem v zmysle článku 6 a článku 7 Nariadenia Európskeho
          parlamentu a Rady (EÚ) 2016/679 z 27. apríla 2016 o ochrane fyzických
          osôb pri spracúvaní osobných údajov a o voľnom pohybe týchto údajov
          (ďalej len „Nariadenie EPR“) a zákona č. 18/2018 Z.z. o ochrane
          osobných údajov spoločnosti SPF súhlas so spracovaním mojich osobných
          údajov (ďalej len Zákon 18/2018) v rozsahu: meno, priezvisko, trvalý
          pobyt, iný pobyt, dátum narodenia, rodné číslo, číslo OP, štátna
          príslušnosť, telefónne číslo, e-mailová adresa, tak ako sú uvedené v
          tomto Zázname za účelom analýzy informovania o ďalších službách a
          produktoch spoločnosti, o informáciách z finančného trhu, o
          informáciách za účelom podpory marketingu a za účelom priameho
          marketingu. Právny základ spracúvania osobných údajov je daný v zmysle
          čl. 6 ods. 1 písm. a) Nariadenia EPR. Ostatné informácie ohľadne
          spracovania osobných údajov sa nachádzajú na stranách číslo 6 a 7
          tohto Záznamu.
        </p>
        <div className="flex mt-4 gap-4">
          <div>
            Klient
            <div className="flex mr-4">
              {privacyPolicyAgree.map(({ name, label }) => (
                <div className="form-check mr-2" key={`checkbox-${name}`}>
                  <input
                    className="mr-1"
                    type="radio"
                    value={name}
                    key={`key-${name}`}
                    name="clientPrivacyPolicy"
                    id={`clientPrivacyPolicy-${name}`}
                    onClick={(e) => {
                      handleCheckboxChange(
                        document,
                        setDocument,
                        "clientPrivacyPolicy",
                        name,
                        false
                      );
                      ctx.resetFieldError("clientPrivacyPolicy");
                    }}
                    onChange={(e) => {
                      handleCheckboxChange(
                        document,
                        setDocument,
                        "clientPrivacyPolicy",
                        name,
                        false
                      );
                      ctx.resetFieldError("clientPrivacyPolicy");
                    }}
                    checked={isChecked(document, "clientPrivacyPolicy", name)}
                    data-testid="/documents/zuz/card-05/input-clientPrivacyPolicy"
                  />
                  <label
                    className="form-check-label inline-block text-gray-800"
                    htmlFor={`clientPrivacyPolicy-${name}`}
                    key={`label-${name}`}
                  >
                    {label}
                  </label>
                </div>
              ))}
            </div>
            {ctx.getFieldError("clientPrivacyPolicy") ? (
              <p className="text-red-500">
                {ctx.getFieldError("clientPrivacyPolicy")?.message}
              </p>
            ) : null}
          </div>
          <div>
            Partner
            <div className="flex">
              {privacyPolicyAgree.map(({ name, label }) => (
                <div className="form-check mr-2" key={`checkbox-${name}`}>
                  <input
                    className="mr-1"
                    type="radio"
                    value={name}
                    key={`key-${name}`}
                    name="partnerPrivacyPolicy"
                    id={`partnerPrivacyPolicy-${name}`}
                    onClick={(e) => {
                      handleCheckboxChange(
                        document,
                        setDocument,
                        "partnerPrivacyPolicy",
                        name,
                        false
                      );
                      ctx.resetFieldError("partnerPrivacyPolicy");
                    }}
                    onChange={(e) => {
                      handleCheckboxChange(
                        document,
                        setDocument,
                        "partnerPrivacyPolicy",
                        name,
                        false
                      );
                      ctx.resetFieldError("partnerPrivacyPolicy");
                    }}
                    checked={isChecked(document, "partnerPrivacyPolicy", name)}
                    data-testid="/documents/zuz/card-05/input-partnerPrivacyPolicy"
                  />
                  <label
                    className="form-check-label inline-block text-gray-800"
                    htmlFor={`partnerPrivacyPolicy-${name}`}
                    key={`label-${name}`}
                  >
                    {label}
                  </label>
                </div>
              ))}
            </div>
            {ctx.getFieldError("partnerPrivacyPolicy") ? (
              <p className="text-red-500">
                {ctx.getFieldError("partnerPrivacyPolicy")?.message}
              </p>
            ) : null}
          </div>
        </div>

        {/* vysvetlivky */}
      </div>
    </>
  );
}

export default Page06Card;
