import React from "react";

import {
  YearsPeriod,
  FinancialToolsFields,
  FinancialToolsExperienceType,
} from "../../../../models/DocumentContent";

export type CPfield = "client" | "partner";

type Errors = {
  client?: string | undefined;
  partner?: string | undefined;
};

interface Props {
  name: string;
  question: string;
  answers: FinancialToolsExperienceType;
  onChange: (answers: FinancialToolsExperienceType) => void;
  errors: Errors;
}

function FinancialToolsExperience({
  question,
  answers,
  name,
  onChange,
  errors,
}: Props) {
  const { client: clientError, partner: partnerError } = errors;
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: CPfield,
    financialTool: keyof FinancialToolsFields
  ) => {
    const newAnswers = { ...answers[field] };
    const newValue = event.target.value as YearsPeriod;
    newAnswers[financialTool] = newValue;
    const updatedAnswers = { ...answers, [field]: newAnswers };

    onChange(updatedAnswers);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>,
    field: CPfield,
    financialTool: keyof FinancialToolsFields
  ) => {
    if (event.target instanceof HTMLInputElement) {
      const newAnswers = { ...answers[field] };
      newAnswers[financialTool] = undefined;
      const updatedAnswers = { ...answers, [field]: newAnswers };
      onChange(updatedAnswers);
    }
  };

  return (
    <table className="mb-4">
      <tbody>
        <tr className="border border-t-0 border-slate-300 bg-slate-200">
          <td className="px-2 py-1 font-bold w-3/6">{question}</td>
          <td className="!text-center px-2 py-1 text-sm w-1/6" colSpan={2}>
            <div className="text-center">Nemám&nbsp;žiadne</div>
          </td>
          <td className="!text-center px-2 py-1 text-sm w-1/6" colSpan={2}>
            <div className="text-center">Mám&nbsp;&lt;&nbsp;3&nbsp;roky</div>
          </td>
          <td className="!text-center px-2 py-1 text-sm w-1/6" colSpan={2}>
            <div className="text-center">Mám&nbsp;&gt;&nbsp;3&nbsp;roky</div>
          </td>
        </tr>
        <tr>
          <td className="p-0">&nbsp;</td>
          <td
            className={`text-sm !text-center p-0 ${
              clientError || partnerError ? "!bg-red-100" : ""
            }`}
          >
            Klient
          </td>
          <td
            className={`text-sm !text-center p-0 ${
              partnerError ? "!bg-red-100" : ""
            }`}
          >
            Partner
          </td>
          <td
            className={`text-sm !text-center p-0 ${
              clientError || partnerError ? "!bg-red-100" : ""
            }`}
          >
            Klient
          </td>
          <td
            className={`text-sm !text-center p-0 ${
              partnerError ? "!bg-red-100" : ""
            }`}
          >
            Partner
          </td>
          <td
            className={`text-sm !text-center p-0 ${
              clientError || partnerError ? "!bg-red-100" : ""
            }`}
          >
            Klient
          </td>
          <td
            className={`text-sm !text-center p-0 ${
              partnerError ? "!bg-red-100" : ""
            }`}
          >
            Partner
          </td>
        </tr>
        <tr>
          <td className="px-2 py-1">Dlhopisy</td>
          <td
            className={`!text-center px-2 py-1 ${
              clientError || partnerError ? "!bg-red-100" : ""
            }`}
          >
            <input
              type="radio"
              id="bondClientQuestionCheckbox"
              name="bondClientQuestionCheckbox"
              value={YearsPeriod.NONE}
              checked={answers.client?.bond === YearsPeriod.NONE}
              onChange={(event) => handleChange(event, "client", "bond")}
              onClick={(event) => handleClick(event, "client", "bond")}
              data-testid="/documents/zuz/esg/input-bondClientQuestionCheckbox"
            />
          </td>
          <td
            className={`!text-center px-2 py-1 ${
              partnerError ? "!bg-red-100" : ""
            }`}
          >
            <input
              type="radio"
              id="bondPartnerQuestionCheckbox"
              name="bondPartnerQuestionCheckbox"
              value={YearsPeriod.NONE}
              checked={answers.partner?.bond === YearsPeriod.NONE}
              onChange={(event) => handleChange(event, "partner", "bond")}
              onClick={(event) => handleClick(event, "partner", "bond")}
              data-testid="/documents/zuz/esg/input-bondPartnerQuestionCheckbox"
            />
          </td>
          <td
            className={`text-center px-2 py-1 ${
              clientError || partnerError ? "!bg-red-100" : ""
            }`}
          >
            <input
              type="radio"
              id="bondClientQuestionCheckbox"
              name="bondClientQuestionCheckbox"
              value={YearsPeriod.LESS_THAN_3}
              checked={answers.client?.bond === YearsPeriod.LESS_THAN_3}
              onChange={(event) => handleChange(event, "client", "bond")}
              onClick={(event) => handleClick(event, "client", "bond")}
              data-testid="/documents/zuz/esg/input-bondClientQuestionCheckbox"
            />
          </td>
          <td
            className={`text-center px-2 py-1 ${
              partnerError ? "!bg-red-100" : ""
            }`}
          >
            <input
              type="radio"
              id="bondPartnerQuestionCheckbox"
              name="bondPartnerQuestionCheckbox"
              value={YearsPeriod.LESS_THAN_3}
              checked={answers.partner?.bond === YearsPeriod.LESS_THAN_3}
              onChange={(event) => handleChange(event, "partner", "bond")}
              onClick={(event) => handleClick(event, "partner", "bond")}
              data-testid="/documents/zuz/esg/input-bondPartnerQuestionCheckbox"
            />
          </td>
          <td
            className={`text-center px-2 py-1 ${
              clientError || partnerError ? "!bg-red-100" : ""
            }`}
          >
            <input
              type="radio"
              id="bondClientQuestionCheckbox"
              name="bondClientQuestionCheckbox"
              value={YearsPeriod.MORE_THAN_3}
              checked={answers.client?.bond === YearsPeriod.MORE_THAN_3}
              onChange={(event) => handleChange(event, "client", "bond")}
              onClick={(event) => handleClick(event, "client", "bond")}
              data-testid="/documents/zuz/esg/input-bondClientQuestionCheckbox"
            />
          </td>
          <td
            className={`!text-center px-2 py-1 ${
              partnerError ? "!bg-red-100" : ""
            }`}
          >
            <input
              type="radio"
              id="bondPartnerQuestionCheckbox"
              name="bondPartnerQuestionCheckbox"
              value={YearsPeriod.MORE_THAN_3}
              checked={answers.partner?.bond === YearsPeriod.MORE_THAN_3}
              onChange={(event) => handleChange(event, "partner", "bond")}
              onClick={(event) => handleClick(event, "partner", "bond")}
              data-testid="/documents/zuz/esg/input-bondPartnerQuestionCheckbox"
            />
          </td>
        </tr>
        <tr>
          <td className="px-2 py-1">Akcie</td>
          <td
            className={`text-center px-2 py-1 ${
              clientError || partnerError ? "!bg-red-100" : ""
            }`}
          >
            <input
              type="radio"
              id="stockClientQuestionCheckbox"
              name="stockClientQuestionCheckbox"
              value={YearsPeriod.NONE}
              checked={answers.client?.stock === YearsPeriod.NONE}
              onChange={(event) => handleChange(event, "client", "stock")}
              onClick={(event) => handleClick(event, "client", "stock")}
              data-testid="/documents/zuz/esg/input-stockClientQuestionCheckbox"
            />
          </td>
          <td
            className={`text-center px-2 py-1 ${
              partnerError ? "!bg-red-100" : ""
            }`}
          >
            <input
              type="radio"
              id="stockPartnerQuestionCheckbox"
              name="stockPartnerQuestionCheckbox"
              value={YearsPeriod.NONE}
              checked={answers.partner?.stock === YearsPeriod.NONE}
              onChange={(event) => handleChange(event, "partner", "stock")}
              onClick={(event) => handleClick(event, "partner", "stock")}
              data-testid="/documents/zuz/esg/input-stockPartnerQuestionCheckbox"
            />
          </td>
          <td
            className={`text-center px-2 py-1 ${
              clientError || partnerError ? "!bg-red-100" : ""
            }`}
          >
            <input
              type="radio"
              id="stockClientQuestionCheckbox"
              name="stockClientQuestionCheckbox"
              value={YearsPeriod.LESS_THAN_3}
              checked={answers.client?.stock === YearsPeriod.LESS_THAN_3}
              onChange={(event) => handleChange(event, "client", "stock")}
              onClick={(event) => handleClick(event, "client", "stock")}
              data-testid="/documents/zuz/esg/input-stockClientQuestionCheckbox"
            />
          </td>
          <td
            className={`!text-center px-2 py-1 ${
              partnerError ? "!bg-red-100" : ""
            }`}
          >
            <input
              type="radio"
              id="stockPartnerQuestionCheckbox"
              name="stockPartnerQuestionCheckbox"
              value={YearsPeriod.LESS_THAN_3}
              checked={answers.partner?.stock === YearsPeriod.LESS_THAN_3}
              onChange={(event) => handleChange(event, "partner", "stock")}
              onClick={(event) => handleClick(event, "partner", "stock")}
              data-testid="/documents/zuz/esg/input-stockPartnerQuestionCheckbox"
            />
          </td>
          <td
            className={`text-center px-2 py-1 ${
              clientError || partnerError ? "!bg-red-100" : ""
            }`}
          >
            <input
              type="radio"
              id="stockClientQuestionCheckbox"
              name="stockClientQuestionCheckbox"
              value={YearsPeriod.MORE_THAN_3}
              checked={answers.client?.stock === YearsPeriod.MORE_THAN_3}
              onChange={(event) => handleChange(event, "client", "stock")}
              onClick={(event) => handleClick(event, "client", "stock")}
              data-testid="/documents/zuz/esg/input-stockClientQuestionCheckbox"
            />
          </td>
          <td
            className={`!text-center px-2 py-1 ${
              partnerError ? "!bg-red-100" : ""
            }`}
          >
            <input
              type="radio"
              id="stockPartnerQuestionCheckbox"
              name="stockPartnerQuestionCheckbox"
              value={YearsPeriod.MORE_THAN_3}
              checked={answers.partner?.stock === YearsPeriod.MORE_THAN_3}
              onChange={(event) => handleChange(event, "partner", "stock")}
              onClick={(event) => handleClick(event, "partner", "stock")}
              data-testid="/documents/zuz/esg/input-stockPartnerQuestionCheckbox"
            />
          </td>
        </tr>
        <tr>
          <td className="px-2 py-1">Podielové fondy</td>
          <td
            className={`text-center px-2 py-1 ${
              clientError || partnerError ? "!bg-red-100" : ""
            }`}
          >
            <input
              type="radio"
              id="mutualFundClientQuestionCheckbox"
              name="mutualFundClientQuestionCheckbox"
              value={YearsPeriod.NONE}
              checked={answers.client?.mutualFund === YearsPeriod.NONE}
              onChange={(event) => handleChange(event, "client", "mutualFund")}
              onClick={(event) => handleClick(event, "client", "mutualFund")}
              data-testid="/documents/zuz/esg/input-mutualFundClientQuestionCheckbox"
            />
          </td>
          <td
            className={`!text-center px-2 py-1 ${
              partnerError ? "!bg-red-100" : ""
            }`}
          >
            <input
              type="radio"
              id="mutualFundPartnerQuestionCheckbox"
              name="mutualFundPartnerQuestionCheckbox"
              value={YearsPeriod.NONE}
              checked={answers.partner?.mutualFund === YearsPeriod.NONE}
              onChange={(event) => handleChange(event, "partner", "mutualFund")}
              onClick={(event) => handleClick(event, "partner", "mutualFund")}
              data-testid="/documents/zuz/esg/input-mutualFundPartnerQuestionCheckbox"
            />
          </td>
          <td
            className={`text-center px-2 py-1 ${
              clientError || partnerError ? "!bg-red-100" : ""
            }`}
          >
            <input
              type="radio"
              id="mutualFundClientQuestionCheckbox"
              name="mutualFundClientQuestionCheckbox"
              value={YearsPeriod.LESS_THAN_3}
              checked={answers.client?.mutualFund === YearsPeriod.LESS_THAN_3}
              onChange={(event) => handleChange(event, "client", "mutualFund")}
              onClick={(event) => handleClick(event, "client", "mutualFund")}
              data-testid="/documents/zuz/esg/input-mutualFundClientQuestionCheckbox"
            />
          </td>
          <td
            className={`!text-center px-2 py-1 ${
              partnerError ? "!bg-red-100" : ""
            }`}
          >
            <input
              type="radio"
              id="mutualFundPartnerQuestionCheckbox"
              name="mutualFundPartnerQuestionCheckbox"
              value={YearsPeriod.LESS_THAN_3}
              checked={answers.partner?.mutualFund === YearsPeriod.LESS_THAN_3}
              onChange={(event) => handleChange(event, "partner", "mutualFund")}
              onClick={(event) => handleClick(event, "partner", "mutualFund")}
              data-testid="/documents/zuz/esg/input-mutualFundPartnerQuestionCheckbox"
            />
          </td>
          <td
            className={`text-center px-2 py-1 ${
              clientError || partnerError ? "!bg-red-100" : ""
            }`}
          >
            <input
              type="radio"
              id="mutualFundClientQuestionCheckbox"
              name="mutualFundClientQuestionCheckbox"
              value={YearsPeriod.MORE_THAN_3}
              checked={answers.client?.mutualFund === YearsPeriod.MORE_THAN_3}
              onChange={(event) => handleChange(event, "client", "mutualFund")}
              onClick={(event) => handleClick(event, "client", "mutualFund")}
              data-testid="/documents/zuz/esg/input-mutualFundClientQuestionCheckbox"
            />
          </td>
          <td
            className={`!text-center px-2 py-1 ${
              partnerError ? "!bg-red-100" : ""
            }`}
          >
            <input
              type="radio"
              id="mutualFundPartnerQuestionCheckbox"
              name="mutualFundPartnerQuestionCheckbox"
              value={YearsPeriod.MORE_THAN_3}
              checked={answers.partner?.mutualFund === YearsPeriod.MORE_THAN_3}
              onChange={(event) => handleChange(event, "partner", "mutualFund")}
              onClick={(event) => handleClick(event, "partner", "mutualFund")}
              data-testid="/documents/zuz/esg/input-mutualFundPartnerQuestionCheckbox"
            />
          </td>
        </tr>
        <tr>
          <td className="px-2 py-1">ETF</td>
          <td
            className={`text-center px-2 py-1 ${
              clientError || partnerError ? "!bg-red-100" : ""
            }`}
          >
            <input
              type="radio"
              id="etfClientQuestionCheckbox"
              name="etfClientQuestionCheckbox"
              value={YearsPeriod.NONE}
              checked={answers.client?.etf === YearsPeriod.NONE}
              onChange={(event) => handleChange(event, "client", "etf")}
              onClick={(event) => handleClick(event, "client", "etf")}
              data-testid="/documents/zuz/esg/input-etfClientQuestionCheckbox"
            />
          </td>
          <td
            className={`!text-center px-2 py-1 ${
              partnerError ? "!bg-red-100" : ""
            }`}
          >
            <input
              type="radio"
              id="etfPartnerQuestionCheckbox"
              name="etfPartnerQuestionCheckbox"
              value={YearsPeriod.NONE}
              checked={answers.partner?.etf === YearsPeriod.NONE}
              onChange={(event) => handleChange(event, "partner", "etf")}
              onClick={(event) => handleClick(event, "partner", "etf")}
              data-testid="/documents/zuz/esg/input-etfPartnerQuestionCheckbox"
            />
          </td>
          <td
            className={`text-center px-2 py-1 ${
              clientError || partnerError ? "!bg-red-100" : ""
            }`}
          >
            <input
              type="radio"
              id="etfClientQuestionCheckbox"
              name="etfClientQuestionCheckbox"
              value={YearsPeriod.LESS_THAN_3}
              checked={answers.client?.etf === YearsPeriod.LESS_THAN_3}
              onChange={(event) => handleChange(event, "client", "etf")}
              onClick={(event) => handleClick(event, "client", "etf")}
              data-testid="/documents/zuz/esg/input-etfClientQuestionCheckbox"
            />
          </td>
          <td
            className={`!text-center px-2 py-1 ${
              partnerError ? "!bg-red-100" : ""
            }`}
          >
            <input
              type="radio"
              id="etfPartnerQuestionCheckbox"
              name="etfPartnerQuestionCheckbox"
              value={YearsPeriod.LESS_THAN_3}
              checked={answers.partner?.etf === YearsPeriod.LESS_THAN_3}
              onChange={(event) => handleChange(event, "partner", "etf")}
              onClick={(event) => handleClick(event, "partner", "etf")}
              data-testid="/documents/zuz/esg/input-etfPartnerQuestionCheckbox"
            />
          </td>
          <td
            className={`text-center px-2 py-1 ${
              clientError || partnerError ? "!bg-red-100" : ""
            }`}
          >
            <input
              type="radio"
              id="etfClientQuestionCheckbox"
              name="etfClientQuestionCheckbox"
              value={YearsPeriod.MORE_THAN_3}
              checked={answers.client?.etf === YearsPeriod.MORE_THAN_3}
              onChange={(event) => handleChange(event, "client", "etf")}
              onClick={(event) => handleClick(event, "client", "etf")}
              data-testid="/documents/zuz/esg/input-etfClientQuestionCheckbox"
            />
          </td>
          <td
            className={`!text-center px-2 py-1 ${
              partnerError ? "!bg-red-100" : ""
            }`}
          >
            <input
              type="radio"
              id="etfPartnerQuestionCheckbox"
              name="etfPartnerQuestionCheckbox"
              value={YearsPeriod.MORE_THAN_3}
              checked={answers.partner?.etf === YearsPeriod.MORE_THAN_3}
              onChange={(event) => handleChange(event, "partner", "etf")}
              onClick={(event) => handleClick(event, "partner", "etf")}
              data-testid="/documents/zuz/esg/input-etfPartnerQuestionCheckbox"
            />
          </td>
        </tr>
        <tr>
          <td className="px-2 py-1">
            Deriváty (opcie, futures, forwardy, swapy)
          </td>
          <td
            className={`text-center px-2 py-1 ${
              clientError || partnerError ? "!bg-red-100" : ""
            }`}
          >
            <input
              type="radio"
              id="derivativeClientQuestionCheckbox"
              name="derivativeClientQuestionCheckbox"
              value={YearsPeriod.NONE}
              checked={answers.client?.derivative === YearsPeriod.NONE}
              onChange={(event) => handleChange(event, "client", "derivative")}
              onClick={(event) => handleClick(event, "client", "derivative")}
              data-testid="/documents/zuz/esg/input-derivativeClientQuestionCheckbox"
            />
          </td>
          <td
            className={`!text-center px-2 py-1 ${
              partnerError ? "!bg-red-100" : ""
            }`}
          >
            <input
              type="radio"
              id="derivativePartnerQuestionCheckbox"
              name="derivativePartnerQuestionCheckbox"
              value={YearsPeriod.NONE}
              checked={answers.partner?.derivative === YearsPeriod.NONE}
              onChange={(event) => handleChange(event, "partner", "derivative")}
              onClick={(event) => handleClick(event, "partner", "derivative")}
              data-testid="/documents/zuz/esg/input-derivativePartnerQuestionCheckbox"
            />
          </td>
          <td
            className={`text-center px-2 py-1 ${
              clientError || partnerError ? "!bg-red-100" : ""
            }`}
          >
            <input
              type="radio"
              id="derivativeClientQuestionCheckbox"
              name="derivativeClientQuestionCheckbox"
              value={YearsPeriod.LESS_THAN_3}
              checked={answers.client?.derivative === YearsPeriod.LESS_THAN_3}
              onChange={(event) => handleChange(event, "client", "derivative")}
              onClick={(event) => handleClick(event, "client", "derivative")}
              data-testid="/documents/zuz/esg/input-derivativeClientQuestionCheckbox"
            />
          </td>
          <td
            className={`!text-center px-2 py-1 ${
              partnerError ? "!bg-red-100" : ""
            }`}
          >
            <input
              type="radio"
              id="derivativePartnerQuestionCheckbox"
              name="derivativePartnerQuestionCheckbox"
              value={YearsPeriod.LESS_THAN_3}
              checked={answers.partner?.derivative === YearsPeriod.LESS_THAN_3}
              onChange={(event) => handleChange(event, "partner", "derivative")}
              onClick={(event) => handleClick(event, "partner", "derivative")}
              data-testid="/documents/zuz/esg/input-derivativePartnerQuestionCheckbox"
            />
          </td>
          <td
            className={`text-center px-2 py-1 ${
              clientError || partnerError ? "!bg-red-100" : ""
            }`}
          >
            <input
              type="radio"
              id="derivativeClientQuestionCheckbox"
              name="derivativeClientQuestionCheckbox"
              value={YearsPeriod.MORE_THAN_3}
              checked={answers.client?.derivative === YearsPeriod.MORE_THAN_3}
              onChange={(event) => handleChange(event, "client", "derivative")}
              onClick={(event) => handleClick(event, "client", "derivative")}
              data-testid="/documents/zuz/esg/input-derivativeClientQuestionCheckbox"
            />
          </td>
          <td
            className={`!text-center px-2 py-1 ${
              partnerError ? "!bg-red-100" : ""
            }`}
          >
            <input
              type="radio"
              id="derivativePartnerQuestionCheckbox"
              name="derivativePartnerQuestionCheckbox"
              value={YearsPeriod.MORE_THAN_3}
              checked={answers.partner?.derivative === YearsPeriod.MORE_THAN_3}
              onChange={(event) => handleChange(event, "partner", "derivative")}
              onClick={(event) => handleClick(event, "partner", "derivative")}
              data-testid="/documents/zuz/esg/input-derivativePartnerQuestionCheckbox"
            />
          </td>
        </tr>
        {clientError || partnerError ? (
          <tr>
            <td className="p-1 text-red-400 !text-left" colSpan={7}>
              {clientError || partnerError}
            </td>
          </tr>
        ) : null}
      </tbody>
    </table>
  );
}

export default FinancialToolsExperience;
