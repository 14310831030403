import React from "react";

import { PaginationQuery } from "../../hooks/query";

interface ComponentInterface<Q extends PaginationQuery> {
  query: Q;
  callback: (query: Q) => void;
}

function TablePageSize<Q extends PaginationQuery>({
  query,
  callback,
}: ComponentInterface<Q>): JSX.Element {
  const size = Number(query?.size) ?? 10;
  const sizes = [5, 10, 20, 50, 100];

  const updateSize = (newSize: number) => {
    callback({
      ...query,
      size: newSize,
    });
  };

  return (
    <div className="border border-gray-200 rounded overflow-hidden">
      {sizes.map((item) => {
        return (
          <button
            type="button"
            key={`table-page-size-button-${item}`}
            className={
              `px-4 py-2 text-sm border-r border-gray-300 last:border-0 ` +
              `${
                size === item
                  ? "bg-spf-primary text-white"
                  : "text-gray-500  hover:bg-gray-100"
              }`
            }
            onClick={() => {
              updateSize(item);
            }}
          >
            {item}
          </button>
        );
      })}
    </div>
  );
}

export default TablePageSize;
