import {
  createApi,
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { Mutex } from "async-mutex";

import { getRoleId, removeRoleId } from "../hooks/role";
import { getAccessToken, getRefreshToken, setTokens } from "../hooks/token";

export type ErrorResponse = {
  code?: number;
  message?: string;
};

const mutex = new Mutex();
const cleanBaseQuery = fetchBaseQuery({
  baseUrl: "",
});

const baseQuery = fetchBaseQuery({
  baseUrl: "",
  prepareHeaders: (headers) => {
    const token = getAccessToken();
    const roleId = getRoleId();

    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }

    if (roleId) {
      headers.set("X-Role-Id", roleId);
    }

    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    const error = result.error.data as ErrorResponse;
    if (error.code === 40104) {
      removeRoleId();
      result = await baseQuery(args, api, extraOptions);
    }

    if (error.code === 40102) {
      await mutex.waitForUnlock();
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();
        try {
          // Retry with refresh
          const refreshResult: any = await cleanBaseQuery(
            {
              url: "/v1/auth/login-refresh",
              method: "POST",
              body: JSON.stringify({ token: getRefreshToken() }),
            },
            api,
            {
              ...extraOptions,
            }
          );
          if (refreshResult.data) {
            setTokens(refreshResult.data);
            result = await baseQuery(args, api, extraOptions);
          } else {
            // TODO: api.dispatch(loggedOut());
          }
        } finally {
          // release must be called once the mutex should be released again.
          release();
        }
      }
    }
  }
  return result;
};

export const baseSplitApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});

export default baseSplitApi;
