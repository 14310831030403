import React from "react";

import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import useFeatureFlags from "../../hooks/featureFlags";
import { matchRoute } from "../../hooks/route";
import { useAppSelector } from "../../hooks/store";
import { authSelector } from "../auth/authSlice";
import ApplicationRoute from "../route/ApplicationRoute";
import AllRoutes from "../route/Route";
import { usePrivateContentContext } from "./PrivateContentContext";

type SidebarMenuItemType = "title" | "route";

interface SidebarMenuItem {
  name?: string;
  type: SidebarMenuItemType;
  route?: ApplicationRoute;
  disabled?: boolean;
  hidden?: boolean;
  badge?: JSX.Element;
  requiredRoles?: string[];
}

function GetSidebarMenu(): SidebarMenuItem[] {
  const { isFeatureEnabled, featureFlags } = useFeatureFlags();

  const sidebarMenu: SidebarMenuItem[] = [
    {
      type: "route",
      route: AllRoutes.DASHBOARD,
    },
    {
      type: "title",
      name: "app.sidebar.section.personal",
    },
    {
      type: "route",
      route: AllRoutes.CUSTOMERS_LIST,
    },
    {
      type: "route",
      route: AllRoutes.DOCUMENTS_LIST_ZUZ,
    },
    {
      type: "route",
      route: AllRoutes.DOCUMENTS_LIST_CHECK,
    },
    {
      type: "route",
      route: AllRoutes.IDENTIFICATION_LIST,
    },
    {
      type: "route",
      route: AllRoutes.EXTERNAL_LINKS_DASHBOARD_VIEW,
      requiredRoles: ["rk-advisor", "rk-assistant"],
    },
    {
      type: "title",
      name: "app.sidebar.section.administration",
      requiredRoles: ["rk-central", "rk-system-admin"],
    },
    {
      type: "route",
      route: AllRoutes.RULES_LIST,
      requiredRoles: ["rk-system-admin"],
      disabled: !isFeatureEnabled(featureFlags.RULE_RIGHTS),
      hidden: !isFeatureEnabled(featureFlags.RULE_RIGHTS),
    },
    {
      type: "route",
      route: AllRoutes.RULES_MATRIX_LIST,
      requiredRoles: ["rk-system-admin"],
      disabled: !isFeatureEnabled(featureFlags.RULE_RIGHTS),
      hidden: !isFeatureEnabled(featureFlags.RULE_RIGHTS),
    },
    {
      type: "route",
      route: AllRoutes.USERS_LIST,
      requiredRoles: ["rk-central", "rk-system-admin"],
    },
    {
      type: "route",
      route: AllRoutes.ADVISORS_LIST,
      requiredRoles: ["rk-central", "rk-system-admin"],
    },
    {
      type: "route",
      route: AllRoutes.EXTERNAL_LINKS_LIST,
      requiredRoles: ["rk-central", "rk-system-admin"],
    },
    {
      type: "route",
      route: AllRoutes.EVENTS_LIST,
      requiredRoles: ["rk-central", "rk-system-admin"],
    },
    {
      type: "route",
      route: AllRoutes.SYSTEM_MANAGEMENT_VIEW,
      requiredRoles: ["rk-system-admin"],
    },
  ];

  return sidebarMenu;
}

function Sidebar(): JSX.Element {
  const { currentRole } = useAppSelector(authSelector);
  const location = useLocation();
  const { t } = useTranslation();
  const { menu } = usePrivateContentContext();
  let i = 0;
  return (
    <>
      <div
        className={`fixed xl:relative flex flex-col flex-none w-72 h-full z-20 transition-all duration-300 ${
          menu.show ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div className="flex-grow bg-white border-r py-4 overflow-y-auto h-full">
          {GetSidebarMenu()
            .filter((item) => !item.hidden)
            .map((item: SidebarMenuItem) => {
              i += 1;
              const key = `sni-${item.name}-${i}`;

              // Check permissions
              if (
                item?.requiredRoles &&
                item?.requiredRoles.filter(
                  (role) => role === currentRole?.role?.key
                ).length === 0
              ) {
                return "";
              }

              if (item.disabled) {
                // Temporary for presentation
                return (
                  <div className="disabled" key={key}>
                    <div className="c-sidebar-nav-link">
                      <FontAwesomeIcon
                        className="text-spf-blue"
                        icon={item.route?.icon ?? faHome}
                        size="1x"
                      />
                      <span>{item.route?.name}</span>
                    </div>
                  </div>
                );
              }

              if (item.type === "title") {
                return (
                  <div
                    key={item.name}
                    className="mt-4 py-4 px-8 text-gray-800 text-opacity-80 font-semibold text-xs uppercase"
                  >
                    <span>{t(item.name ?? "")}</span>
                  </div>
                );
              }

              return (
                <Link
                  key={`istem-${i}`}
                  to={item.route?.path as string}
                  onClick={() => menu.close()}
                  data-testid={`/global/sidebar/btn${item.route?.path.replaceAll(
                    "/",
                    "-"
                  )}`}
                >
                  <div
                    className={
                      `w-full hover:bg-slate-100 cursor-pointer py-4 px-8 ` +
                      `transition-colors duration-500 ` +
                      `
                    ${
                      matchRoute(item.route?.path as string, location.pathname)
                        ? "bg-slate-100"
                        : ""
                    }`
                    }
                    // TODO:
                    // disabled={item.disabled}
                  >
                    <span className="w-full mr-4">
                      <FontAwesomeIcon
                        className="xl:mr-4 text-spf-primary"
                        icon={item.route?.icon ?? faHome}
                        size="1x"
                      />
                    </span>
                    <span className="truncate">
                      {t(item.route?.name ?? "")}
                    </span>
                    {item.badge ?? <>&nbsp;</>}
                  </div>
                </Link>
              );
            })}
          <div className="h-10" />
        </div>
      </div>
      <button
        type="button"
        onClick={() => menu.close()}
        className={`fixed xl:hidden bg-black  w-full h-full z-10 transition-all duration-300 ${
          menu.show ? "opacity-20" : "opacity-0 pointer-events-none"
        }`}
      >
        &nbsp;
      </button>
    </>
  );
}

export default Sidebar;
