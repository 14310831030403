import React from "react";

import {
  faAsterisk,
  faBars,
  faCaretDown,
  faCogs,
  faHamburger,
  faShieldAlt,
  faSignOutAlt,
  faUserCircle,
  faUserShield,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";

import Button from "../../components/buttons/Button";
import ChangePasswordModal from "../../components/modals/ChangePasswordModal";
import ChangeRoleInDocumentEditModal from "../../components/modals/ChangeRoleInDocumentEditModal";
import NoPasswordModal from "../../components/modals/NoPasswordModal";
import { setRoleId } from "../../hooks/role";
import { useAppDispatch } from "../../hooks/store";
import { authSelector, logoutUser, reloadRoleId } from "../auth/authSlice";
import Loader from "../loader/Loader";
import AllRoutes from "../route/Route";
import { usePrivateContentContext } from "./PrivateContentContext";

function Header(): JSX.Element {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { id } = useParams();

  const { isInitialized, user, currentRole, roles } = useSelector(authSelector);

  const [show, setShow] = React.useState(false);
  const [showRoles, setShowRoles] = React.useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] =
    React.useState(false);
  const [showNoLoginYetModal, setShowNoLoginYetModal] = React.useState(false);
  const [showRoleChangeModalWarning, setShowRoleChangeModalWarning] =
    React.useState(false);
  const [newRoleId, setNewRoleId] = React.useState("0");
  const location = useLocation();
  const { menu } = usePrivateContentContext();

  const logout = async () => {
    dispatch(logoutUser());
  };

  const onLogoutClick = () => {
    logout();
  };

  const onChangePasswordClick = () => {
    setShow(false);
    if (user.hasPassword) {
      return setShowChangePasswordModal(true);
    }
    return setShowNoLoginYetModal(true);
  };

  const changeRoleInDocument = () => {
    setRoleId(newRoleId ?? "");
    dispatch(reloadRoleId());
    setShowRoleChangeModalWarning(false);
    setShowRoles(!showRoles);
    window.location.href = AllRoutes.DASHBOARD.path;
  };

  const onRolesItemDropdownClick = (roleid: string) => {
    let locationPath = location.pathname;
    locationPath = locationPath.replace(id as string, ":id");
    if (
      [
        AllRoutes.DOCUMENTS_ADD_ZUZ.path,
        AllRoutes.DOCUMENT_EDIT_ZUZ.path,
        AllRoutes.DOCUMENT_EDIT_CHECK.path,
        AllRoutes.DOCUMENTS_ADD_CHECK.path,
        AllRoutes.CUSTOMERS_ADD.path,
        AllRoutes.CUSTOMERS_EDIT.path,
      ].includes(locationPath)
    ) {
      setNewRoleId(roleid);
      setShowRoleChangeModalWarning(true);
    } else {
      setRoleId(roleid);
      dispatch(reloadRoleId());
      setShowRoles(!showRoles);
      window.location.href = AllRoutes.DASHBOARD.path;
    }
  };

  const onBlurEvent = (
    event: React.FocusEvent<HTMLElement>,
    callback: () => void
  ) => {
    // if event is not in the current target, then call the callback
    if (!event.currentTarget.contains(event.relatedTarget as Node)) {
      callback();
    }
  };

  const noPasswordConfirm = () => {
    sessionStorage.setItem("showForgotPasswordModal", "yes");
    logout();
  };

  return (
    <header className="flex justify-between xl:justify-initial w-full bg-spf-blue h-16 shadow-xl z-20 px-4 lg:px-8">
      <div className="w-1/3 xl:w-auto xl:hidden relative flex">
        <button type="button" onClick={() => menu.toggle()}>
          <FontAwesomeIcon
            icon={faBars}
            className="inline-block text-white w-6"
          />
        </button>
      </div>

      <div className="w-1/3 xl:w-auto sbg-blue-900 bg-spf-blue h-16 flex flex-col place-content-center">
        <div className="text-center text-white text-xl tracking-wide">
          <img className="w-40" src="/logo-white.svg" alt="" />
        </div>
      </div>

      <div className="w-1/3 xl:w-auto xl:ml-auto flex flex-row place-content-end h-full">
        {!isInitialized || !currentRole ? (
          <div className="flex flex-col justify-center">
            <Loader />
          </div>
        ) : (
          <>
            <div
              className="relative text-sm flex items-center xl:w-60"
              onBlur={(event) => onBlurEvent(event, () => setShowRoles(false))}
            >
              <Button
                className="xl:w-full flex text-left text-white outline-none focus:ring-offset-0 focus:ring-0 bg-transparent hover:bg-blue-700 hover:bg-opacity-50"
                onClick={() => setShowRoles(!showRoles)}
                dataTestId="/global/btn-role-menu"
              >
                <FontAwesomeIcon
                  icon={faShieldAlt}
                  className="inline-block xl:mt-0.5 xl:mr-4 text-white w-6"
                />
                <span className="flex-grow truncate hidden xl:block">
                  <span>{t(`role.label.${currentRole?.role?.key}`)}</span>{" "}
                  {currentRole?.role?.key === "rk-assistant"
                    ? `${t(`role.label.${currentRole?.role?.key}-for`)} ${
                        currentRole?.assistant?.advisor?.firstname
                      } ${currentRole?.assistant?.advisor?.lastname}`
                    : ""}
                </span>
                <FontAwesomeIcon
                  icon={faCaretDown}
                  className="justify-end hidden xl:inline-block mt-0.5 ml-2 text-white w-6 float-right"
                />
              </Button>

              <div
                className={
                  "absolute top-12 right-0 z-10 text-base list-none bg-white rounded divide-y " +
                  "border border-gray-200 " +
                  "divide-gray-100 shadow-xl " +
                  `${showRoles ? "block" : "hidden"}`
                }
              >
                <ul
                  className="py-1 w-[221px] whitespace-nowrap overflow-hidden text-overflow-ellipsis"
                  aria-labelledby="dropdownRolesButton"
                >
                  {roles?.map((item) => (
                    <li key={item.id} className="block">
                      <a
                        href="#reload"
                        className="flex py-2 px-4 text-sm text-gray-700 hover:bg-gray-100"
                        onClick={(e) => {
                          e.preventDefault();
                          onRolesItemDropdownClick(item?.id ?? "");
                        }}
                        data-testid={`/global/btn-role-${
                          item?.role?.key ?? "none"
                        }`}
                      >
                        <span className="inline-block mr-2 text-spf-primary w-5">
                          <FontAwesomeIcon icon={faUserShield} />
                        </span>
                        <span className="truncate">
                          {t(`role.label.${item?.role?.key}`)}{" "}
                          {item?.role?.key === "rk-assistant"
                            ? `${t(`role.label.${item?.role?.key}-for`)} ${
                                item?.assistant?.advisor?.firstname
                              } ${item?.assistant?.advisor?.lastname}`
                            : ""}
                        </span>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div
              className="relative flex items-center text-sm"
              onBlur={(event) => onBlurEvent(event, () => setShow(false))}
            >
              <Button
                className="text-white outline-none focus:ring-offset-0 focus:ring-0 bg-transparent hover:bg-blue-700 hover:bg-opacity-50"
                onClick={() => setShow(!show)}
                dataTestId="/global/btn-user-menu"
              >
                <FontAwesomeIcon
                  icon={faUserCircle}
                  className="inline-block xl:mr-4 text-white w-6"
                />
                <span className="hidden xl:inline">{user.name ?? ""}</span>
                <FontAwesomeIcon
                  icon={faCaretDown}
                  className="hidden xl:inline-block ml-2 text-white w-6"
                />
              </Button>

              <div
                className={
                  "absolute top-12 right-0 z-30 w-fit text-base list-none bg-white rounded divide-y " +
                  "border border-gray-200 " +
                  "divide-gray-100 shadow-xl " +
                  `${show ? "block" : "hidden"}`
                }
              >
                <div className="py-3 px-4 text-gray-900">
                  <span className="block text-sm">{`${user?.name}`}</span>
                  <span className="block text-xs text-gray-400 sfont-medium truncate">
                    {user?.email ?? "?"}
                  </span>
                </div>
                <ul
                  className="py-1"
                  aria-labelledby="dropdownInformationButton"
                >
                  <li>
                    <Link
                      to={AllRoutes.USER_SETTINGS.path}
                      className="block sflex py-2 px-4 text-sm text-gray-700 hover:bg-gray-100"
                      onClick={() => setShow(false)}
                      data-testid="/global/bnt-settings"
                    >
                      <span className="inline-block mr-2 text-spf-primary w-5">
                        <FontAwesomeIcon icon={faCogs} />
                      </span>
                      <span>{t("app.settings")}</span>
                    </Link>
                  </li>
                </ul>
                <div className="py-1">
                  <button
                    className="w-full text-left py-2 px-4 text-sm text-gray-700 hover:bg-gray-100"
                    type="button"
                    onClick={onChangePasswordClick}
                    data-testid="/global/bnt-change-password"
                  >
                    <span className="inline-block mr-2 text-spf-primary w-5">
                      <FontAwesomeIcon icon={faAsterisk} />
                    </span>
                    <span>{t("app.auth.changePassword")}</span>
                  </button>
                </div>
                <div className="py-1">
                  <button
                    className="w-full text-left py-2 px-4 text-sm text-gray-700 hover:bg-gray-100"
                    type="button"
                    onClick={onLogoutClick}
                    data-testid="/global/btn-logout"
                  >
                    <span className="inline-block mr-2 text-spf-primary w-5">
                      <FontAwesomeIcon icon={faSignOutAlt} />
                    </span>
                    <span>{t("app.auth.logout")}</span>
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {noPasswordConfirm ? (
        <NoPasswordModal
          showModal={showNoLoginYetModal}
          setShowModal={setShowNoLoginYetModal}
          callback={noPasswordConfirm}
        />
      ) : (
        ""
      )}
      {showChangePasswordModal ? (
        <ChangePasswordModal
          user={user}
          showModal={showChangePasswordModal}
          setShowModal={setShowChangePasswordModal}
        />
      ) : (
        ""
      )}
      {showRoleChangeModalWarning ? (
        <ChangeRoleInDocumentEditModal
          showModal={showRoleChangeModalWarning}
          setShowModal={setShowRoleChangeModalWarning}
          callbackYes={() => changeRoleInDocument()}
        />
      ) : (
        ""
      )}
    </header>
  );
}

export default Header;
