import React from "react";

import { t } from "i18next";

import { Event } from "../../api/events.generated";
import EventTypes from "../../enums/EventType";
import useFormatters from "../../hooks/formatter";

interface Properties {
  events: Event[];
}

function DocumentHistory({ events }: Properties) {
  const { formatTime } = useFormatters();

  return (
    <div className="relative overflow-x-auto">
      <table className="w-full text-sm text-left">
        <thead className="">
          <tr>
            <th scope="col" className="px-6 py-3">
              {t("document.history.table.column.message") || ""}
            </th>
            <th scope="col" className="px-6 py-3">
              {t("document.history.table.column.user") || ""}
            </th>
            <th scope="col" className="px-6 py-3">
              {t("document.history.table.column.createdAt") || ""}
            </th>
            <th scope="col" className="px-0">
              {" "}
            </th>
          </tr>
        </thead>
        <tbody>
          {(events ?? [])?.map((event) => (
            <tr className="bg-white border-b" key={event.id}>
              <td className="px-6 py-4">{getEventMsg(event)}</td>
              <td className="px-6 py-4">{event.user?.displayName ?? ""}</td>
              <td className="px-6 py-4">{formatTime(event.createdAt)}</td>
              <td />
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

const getEventMsg = (event: Event): string => {
  switch (event.type?.key) {
    case EventTypes.DOCUMENT_CREATED:
      return t("eventType.documentCreated");
    case EventTypes.DOCUMENT_UPDATED:
      return t("eventType.documentUpdated");
    case EventTypes.DOCUMENT_STATE_CHANGED:
      return t(
        "eventType.documentStateChanged",
        translateEventData(event.data as changedStateEventData)
      );
    case EventTypes.DOCUMENT_SIGNED:
      return t("eventType.documentSigned");
  }
  return "";
};
interface changedStateEventData {
  toState: string;
  fromState: string;
}
const translateEventData = (
  data: changedStateEventData
): changedStateEventData => {
  return {
    toState: t(`status.${data.toState}`),
    fromState: t(`status.${data.fromState}`),
  };
};

export default DocumentHistory;
