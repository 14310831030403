import React from "react";

import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

import ApplicationRoute from "../../features/route/ApplicationRoute";
import Button from "./Button";

interface Properties {
  className?: string;
  disabled?: boolean;
  to?: ApplicationRoute | undefined;
  onClick?: () => void | undefined;
}

function EditButton({ className, disabled, to, onClick }: Properties) {
  const { t } = useTranslation();
  return (
    <Button
      className={className}
      disabled={disabled}
      icon={faEdit}
      onClick={onClick}
      to={to}
    >
      <span>{t("button.edit")}</span>
    </Button>
  );
}

EditButton.defaultProps = {
  className: "",
  disabled: false,
  to: undefined,
  onClick: undefined,
};

export default EditButton;
