import axios from "axios";

import Roles from "../enums/Roles";
import { getRoleId } from "../hooks/role";
import { getAccessToken, removeTokens } from "../hooks/token";
import { Role, RoleUser } from "./me.generated";
import { RoleKey } from "./users.generated";

export const API_URL = "/v1/";

export const login = async (username: string, password: string) => {
  return axios
    .post(`${API_URL}auth/login`, {
      email: username,
      password,
    })
    .then((response) => {
      if (response.data.access_token) {
        localStorage.setItem("auth_tokens", JSON.stringify(response.data));
      }
      return response.data;
    });
};

export const refreshLogin = async (token: string) => {
  if (!token) {
    return Promise.reject(new Error("No refresh token found"));
  }

  return axios
    .post(`${API_URL}auth/login-refresh`, {
      token,
    })
    .then((response) => {
      if (response.data.access_token) {
        localStorage.setItem("auth_tokens", JSON.stringify(response.data));
      }
      return response.data;
    });
};

export const logout = async () => {
  const token = getAccessToken();
  if (!token) {
    return Promise.reject(new Error("No token found"));
  }

  // Clean up
  removeTokens();

  return axios
    .post(
      `${API_URL}auth/logout`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
};

export type UserProfile = {
  email: string;
  name: string;
};

export const getMyProfile = async (): Promise<UserProfile> => {
  const token = getAccessToken();
  if (!token) {
    return Promise.reject(new Error("No token found"));
  }

  return axios
    .get(`${API_URL}me`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    });
};

export const getMyRoles = async (): Promise<RoleUser[]> => {
  const token = getAccessToken();
  if (!token) {
    return Promise.reject(new Error("No token found"));
  }

  return axios
    .get(`${API_URL}me/roles`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const order = [
        Roles.ASSISTANT as RoleKey,
        Roles.ADVISOR as RoleKey,
        Roles.CENTRAL as RoleKey,
        Roles.SYSTEM_ADMIN as RoleKey,
      ];

      const roles: RoleUser[] = response.data;
      roles.sort((a: RoleUser, b: RoleUser) => {
        const roleA = a?.role?.key;
        const roleB = b?.role?.key;
        if (roleA && roleB) {
          const indexA = order.indexOf(roleA);
          const indexB = order.indexOf(roleB);

          return indexA - indexB;
        }
        return 0;
      });
      return roles;
    });
};

export const getCurrentAuthTokens = async () => {
  const userStr = localStorage.getItem("auth_tokens");
  if (userStr) return JSON.parse(userStr);

  return null;
};

export const openDocumentPreview = async (id: string, role: string) => {
  return fetch(`${API_URL}documents/${id}/preview?download=false`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      "x-role-id": role,
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      window.open(url, "_blank")?.focus();
    });
};

export const openIdentificationPreview = async (id: string, role: string) => {
  return fetch(`${API_URL}identifications/${id}/preview?download=false`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      "x-role-id": role,
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error();
      }
      return response.blob();
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      window.open(url, "_blank")?.focus();
    });
};

export const openDocument = async (id: string) => {
  return fetch(`${API_URL}documents/${id}/download-signed`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      "x-role-id": getRoleId(),
    },
  })
    .then(async (response) => {
      if (!response.ok) {
        throw new Error();
      }
      const filename = response.headers
        ?.get("Content-Disposition")
        ?.split("filename=")[1]
        .slice(1, -1);
      return { blob: await response.blob(), filename };
    })
    .then(({ blob, filename }) => {
      const url = window.URL.createObjectURL(blob);
      const tempLink = document.createElement("a");
      tempLink.href = url;
      tempLink.download = filename || "";
      tempLink.click();
    });
};

export const openControlDocument = async (id: string) => {
  return fetch(`${API_URL}documents/${id}/download-control-file`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      "x-role-id": getRoleId(),
    },
  })
    .then(async (response) => {
      if (!response.ok) {
        throw new Error();
      }
      const filename = response.headers
        ?.get("Content-Disposition")
        ?.split("filename=")[1]
        .slice(1, -1);
      return { blob: await response.blob(), filename };
    })
    .then(({ blob, filename }) => {
      const url = window.URL.createObjectURL(blob);
      const tempLink = document.createElement("a");
      tempLink.href = url;
      tempLink.download = filename || "";
      tempLink.click();
    });
};

export const openFilePreview = async (id: string, role: string) => {
  return fetch(`${API_URL}files/${id}/preview?download=false`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      "x-role-id": role,
    },
  })
    .then(async (response) => {
      if (!response.ok) {
        throw new Error();
      }
      const filename = response.headers
        ?.get("Content-Disposition")
        ?.split("filename=")[1]
        .slice(1, -1);
      return { blob: await response.blob(), filename };
    })
    .then(({ blob, filename }) => {
      const url = window.URL.createObjectURL(blob);
      const tempLink = document.createElement("a");
      tempLink.href = url;
      tempLink.download = filename || "";
      tempLink.click();
    });
};

const allFunctions = {
  login,
  logout,
  getCurrentAuthTokens,
  openFilePreview,
};

export default allFunctions;
