import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Card from "../../components/card/Card";
import AllRoutes from "../route/Route";
import RoutePath from "../route/RouteKeys";

interface Properties {
  routeKey: RoutePath;
  dataTestId?: string;
}

function LinkCard({ routeKey, dataTestId }: Properties) {
  const { t } = useTranslation();
  const route = AllRoutes[routeKey];

  return (
    <Link to={route.path} data-testid={dataTestId}>
      <Card>
        <div className="flex flex-col place-content-between">
          <div className="flex p-8 py-16 gap-4 items-center justify-center">
            <FontAwesomeIcon
              icon={route.icon}
              className="mr-4 text-spf-primary text-5xl"
            />
            <span className="text-3xl">{t(route.name ?? "")}</span>
          </div>
        </div>
      </Card>
    </Link>
  );
}

LinkCard.defaultProps = {
  dataTestId: "",
};

export default LinkCard;
