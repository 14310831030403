import { DocumentStatusKey } from "../api/documents.generated";

export const DRAFT = "ds-draft" as DocumentStatusKey;
export const SIGNED = "ds-signed" as DocumentStatusKey;
export const SIGNING_IN_PROGRESS =
  "ds-signing-in-progress" as DocumentStatusKey;
export const SIGNING_REJECTED = "ds-signing-rejected" as DocumentStatusKey;
export const SIGNING_EXPIRED = "ds-signing-expired" as DocumentStatusKey;
export const DELETED = "ds-deleted" as DocumentStatusKey;
export const CHECK_DRAFT = "ds-check-draft" as DocumentStatusKey;
export const CHECK_IN_OFFICE = "ds-check-in-office" as DocumentStatusKey;
export const CHECK_CLOSED = "ds-check-closed" as DocumentStatusKey;
export const CHECK_DELETED = "ds-check-deleted" as DocumentStatusKey;
export const CHECK_INCOMING_POST =
  "ds-check-incoming-post" as DocumentStatusKey;

const DocumentStatusKeys = {
  DRAFT,
  SIGNED,
  SIGNING_IN_PROGRESS,
  SIGNING_REJECTED,
  SIGNING_EXPIRED,
  DELETED,
  CHECK_DRAFT,
  CHECK_IN_OFFICE,
  CHECK_CLOSED,
  CHECK_DELETED,
  CHECK_INCOMING_POST,
};

export default DocumentStatusKeys;
