import { FilterOptionsKey } from "../api/documents.generated";

// fo-user-owned" | "fo-structural" | "fo-all"
export const USER_OWNED = "fo-user-owned" as FilterOptionsKey;
export const STRUCTURAL = "fo-structural" as FilterOptionsKey;
export const ALL = "fo-all" as FilterOptionsKey;

const FilterLevelKey = {
  USER_OWNED,
  STRUCTURAL,
  ALL,
};

export default FilterLevelKey;
