import React, { useEffect } from "react";

import { faSyncAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Button from "../buttons/Button";

interface Properties {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  text: string;
  confirmBtnText: string;
  noBtnText: string;
  actionConfirm: () => void;
  actionNo: () => void;
  showX?: boolean;
  useKeyHandler?: boolean;
  actionInProgress?: boolean;
}

function ConfirmModal({
  showModal,
  setShowModal,
  text,
  confirmBtnText,
  noBtnText,
  actionInProgress,
  actionConfirm,
  actionNo,
  showX,
  useKeyHandler,
}: Properties): JSX.Element {
  const handleCloseClick = () => {
    setShowModal(false);
    actionNo();
  };

  useEffect(() => {
    const eventHandler = (e: KeyboardEvent) => {
      if (showModal) {
        if (e.key === "Escape") {
          handleCloseClick();
        }
        if (e.key === "Enter") {
          actionConfirm();
        }
      }
    };

    if (useKeyHandler) {
      window.addEventListener("keydown", eventHandler);
    }
    return () => {
      window.removeEventListener("keydown", eventHandler);
    };
  }, [showModal, useKeyHandler]);

  return (
    <div className={`${showModal ? "block" : "hidden"}`}>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-normal">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/* content */}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-96 bg-white outline-none focus:outline-none">
            {/* header */}
            <div className="flex items-start justify-between p-3 rounded-t">
              {showX && (
                <button
                  type="button"
                  className="p-1 ml-auto bg-transparent border-0 float-right leading-none font-semibold outline-none focus:outline-none"
                  onClick={handleCloseClick}
                >
                  <span className="bg-transparent h-6 w-6 outline-none focus:outline-none flex items-center">
                    <FontAwesomeIcon icon={faTimes} />
                  </span>
                </button>
              )}
            </div>
            {/* body */}
            <div className="relative p-6 pt-2 flex-auto">
              <span>{text}</span>
              {/* footer */}
              <div className="flex justify-center items-center pt-5">
                <Button
                  className="mr-2 w-24"
                  onClick={actionConfirm}
                  dataTestId="/global/confirm-modal/btn-yes"
                  disabled={actionInProgress}
                >
                  <span>
                    {actionInProgress ? (
                      <FontAwesomeIcon icon={faSyncAlt} spin />
                    ) : (
                      confirmBtnText
                    )}
                  </span>
                </Button>
                <Button
                  className="mr-2 w-24 bg-gray-200 text-gray-700 hover:bg-gray-300"
                  onClick={actionNo}
                  dataTestId="/global/confirm-modal/btn-no"
                  disabled={actionInProgress}
                >
                  <span>{noBtnText}</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black" />
    </div>
  );
}

ConfirmModal.defaultProps = {
  showX: true,
  useKeyHandler: true,
  actionInProgress: false,
};

export default ConfirmModal;
