import {
  CustomerSkillTypeCheckbox,
  CUSTOMER_SKILL_TYPE,
} from "./CustomerSkillType";

export type CheckboxItemFieldKeys = PFASectorKeys &
  CUSTOMER_SKILL_TYPE &
  PersonTypeKeys &
  DocumentPassedToCustomerKeys &
  PrivacyPolicyKeys;

export type CheckboxItemField =
  | PFASector
  | CustomerSkillTypeCheckbox
  | PersonType
  | DocumentPassedToCustomer
  | PrivacyPolicy
  | EsgQ1Checkboxes;

export type CheckboxItemFields =
  | PFASector[]
  | CustomerSkillTypeCheckbox[]
  | PersonType[]
  | DocumentPassedToCustomer[]
  | PrivacyPolicy[]
  | EsgQ1Checkboxes[];

export type CheckboxItemFields2 = PFASector[] &
  CustomerSkillTypeCheckbox[] &
  PersonType[] &
  DocumentPassedToCustomer[] &
  PrivacyPolicy[] &
  EsgCheckKeys[];

export type PersonType = {
  checked: boolean;
  label: string;
  name: PersonTypeKeys;
};

export enum PersonTypeKeys {
  INDIVIDUAL = "ct-fo",
  SELF_EMPLOYED = "ct-fop",
  COMPANY = "ct-po",
}

export type PrivacyPolicy = {
  checked: boolean;
  name: PrivacyPolicyKeys;
  label: string;
};

export enum PrivacyPolicyKeys {
  YES = "yes",
  NO = "no",
}

export type PFASector = {
  checked: boolean;
  name: PFASectorKeys;
  label: string;
};

export enum PFASectorKeys {
  SUP = "ast-sup",
  ACC = "ast-acc",
  LEN = "ast-len",
  INS = "ast-ins",
  CAP = "ast-cap",
  OLD = "ast-old",
}

export type DocumentPassedToCustomer = {
  name: DocumentPassedToCustomerKeys;
  checked: boolean;
  label: string;
};

export enum DocumentPassedToCustomerKeys {
  COPY_OF_THIS_DOCUMENT = "copyOfThisDocument",
  COPY_OF_CONTRACT_DRAFT = "copyOfContractDraft",
  GENERAL_INSURANCE_TERMS = "generalInsuranceTerms",
  GENERAL_TOC = "GeneralTOC",
  FUND_STATUS = "fundStatus",
  WAGE_DEDUCTION_AGREEMENT = "wageDeductionAgreement",
  COPY_OF_CREDIT_REQUEST = "copyOfCreditRequest",
  KIDIPID = "kidIpid",
  OTHER = "other",
}

export type EsgQ1Checkboxes = {
  checked: boolean;
  name: EsgCheckKeys;
  label: string;
};

export type EsgQ2Checkboxes = {
  checked: boolean;
  name: EsgCheckKeys;
  label: string;
};

export type EsgQ3Checkboxes = {
  checked: boolean;
  name: EsgCheckKeys;
  label: string;
};

export enum EsgCheckKeys {
  ESG_INTEREST = "esgInterest",
  ESG_NO_INTEREST = "esgNoInterest",
  ESG_ENVIRONMENT = "esgEnviroment",
  ESG_SOCIAL = "esgSocial",
  ESG_CORPORATE = "esgCorporate",
  ESG_No_PREFERENCE = "esgNoPreference",
  ESG_ALL_PREFERENCIES = "esgAllPreferencies",
  ESG_AT_LEAST_ONE_PREFERENCIES = "esgAtLeastOnePreferencies",
  COPY_OF_THIS_DOCUMENT = "copyOfThisDocument",
  COPY_OF_CONTRACT_DRAFT = "copyOfContractDraft",
  GENERAL_INSURANCE_TERMS = "generalInsuranceTerms",
  GENERAL_TOC = "GeneralTOC",
  FUND_STATUS = "fundStatus",
  WAGE_DEDUCTION_AGREEMENT = "wageDeductionAgreement",
  COPY_OF_CREDIT_REQUEST = "copyOfCreditRequest",
  KIDIPID = "kidIpid",
}
