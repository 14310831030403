import { ExternalLinkCategoryKey } from "../api/external-link.generated";
import SelectOption from "./SelectOption";

const ExternalLinkCategoryType: SelectOption<ExternalLinkCategoryKey>[] = [
  {
    key: "lct-int",
    name: "category.int",
  },
  {
    key: "lct-ins",
    name: "category.ins",
  },
  {
    key: "lct-bnk",
    name: "category.bnk",
  },
  {
    key: "lct-dss",
    name: "category.dss",
  },
  {
    key: "lct-inc",
    name: "category.inc",
  },
  {
    key: "lct-oth",
    name: "category.oth",
  },
];

export default ExternalLinkCategoryType;
