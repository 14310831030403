/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext } from "react";

import Card from "../../../components/card/Card";
import ClientPartnerCheckbox from "../../../components/inputs/ClientPartnerCheckbox";
import ClientPartnerRadio from "../../../components/inputs/ClientPartnerRadio";
import useDocumentGetters from "../../../hooks/documentContent";
import { Document } from "../../../models/Document";
import DocumentZuzContext from "../DocumentZuzFormViewContext";

interface Properties {
  document: Document;
  setDocument: React.Dispatch<Document>;
}

function Page02SDSCard({ document, setDocument }: Properties) {
  const { getValue, handleInputChange } = useDocumentGetters();
  const ctx = useContext(DocumentZuzContext);
  return (
    <Card className="mb-8 px-4">
      <div className="py-3 mb-6">
        {/* SDS Start */}
        <div className="mb-4">
          <div className="text-base font-bold my-2">
            Vstupné informácie (podľa § 35 zákona č. 186/2009 Z.z.)
          </div>
          <div className="text-base font-bold my-2">
            Požiadavky a potreby vyslovené Klientom / Partnerom
          </div>
          <div className="flex">
            <div className="w-1/2 mb-4 pr-2 inline-block">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="sdsClientEntryRequests"
              >
                Klient
              </label>
              <input
                className={`inline-block appearance-none border ${
                  ctx.getFieldError("sdsClientEntryRequests")
                    ? "border-red-400"
                    : ""
                } rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                id="sdsClientEntryRequests"
                type="text"
                name="sdsClientEntryRequests"
                placeholder="Klient"
                value={getValue(document, "sdsClientEntryRequests")}
                onChange={(e) => {
                  handleInputChange(
                    document,
                    setDocument,
                    "sdsClientEntryRequests",
                    e
                  );
                  ctx.resetFieldsErrors([
                    "sdsClientEntryRequests",
                    "sdsPartnerEntryRequests",
                  ]);
                }}
                data-testid="/documents/zuz/card-02/input-sdsClientEntryRequests"
              />
              {ctx.getFieldError("sdsClientEntryRequests") ? (
                <p className="text-red-400">
                  {ctx.getFieldError("sdsClientEntryRequests")?.message}
                </p>
              ) : null}
            </div>
            <div className="w-1/2 mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="sdsPartnerEntryRequests"
              >
                Partner
              </label>
              <input
                className={`inline-block appearance-none border ${
                  ctx.getFieldError("sdsPartnerEntryRequests")
                    ? "border-red-400"
                    : ""
                } rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                id="sdsPartnerEntryRequests"
                type="text"
                name="sdsPartnerEntryRequests"
                placeholder="Partner"
                value={getValue(document, "sdsPartnerEntryRequests")}
                onChange={(e) => {
                  handleInputChange(
                    document,
                    setDocument,
                    "sdsPartnerEntryRequests",
                    e
                  );
                  ctx.resetFieldsErrors([
                    "sdsClientEntryRequests",
                    "sdsPartnerEntryRequests",
                  ]);
                }}
                data-testid="/documents/zuz/card-02/input-sdsPartnerEntryRequests"
              />
              {ctx.getFieldError("sdsPartnerEntryRequests") ? (
                <p className="text-red-400">
                  {ctx.getFieldError("sdsPartnerEntryRequests")?.message}
                </p>
              ) : null}
            </div>
          </div>
        </div>
        <div>
          {/* <div className="w-full bg-slate-200 mb-3">
              <h3 className="pl-1 text-base font-bold">SDS</h3>
            </div> */}
          <div className="flex flex-col mb-4">
            <h3 className="mb-2 font-bold">Znalosti a skúsenosti s SDS</h3>
            <div className="w-1/4">
              <ClientPartnerRadio
                name="sdsExperiences"
                document={document}
                setDocument={setDocument}
                client={{
                  hasError: !!ctx.getFieldError("sdsExperiencesClient"),
                  errorMsg: ctx.getFieldError("sdsExperiencesClient")?.message,
                  onChange: (e) => {
                    ctx.resetFieldsErrors([
                      "sdsExperiencesPartner",
                      "sdsExperiencesClient",
                    ]);
                  },
                }}
                partner={{
                  hasError: !!ctx.getFieldError("sdsExperiencesPartner"),
                  errorMsg: ctx.getFieldError("sdsExperiencesPartner")?.message,
                  onChange: (e) => {
                    ctx.resetFieldsErrors([
                      "sdsExperiencesPartner",
                      "sdsExperiencesClient",
                    ]);
                  },
                }}
              />
            </div>
          </div>
          <div className="w-full flex items-center mt-3">
            <div className="inline-block">
              <ClientPartnerCheckbox
                document={document}
                setDocument={setDocument}
                name="SDSWantVoluntaryDues"
                text="Mám záujem o dobrovoľné príspevky do SDS vo výške"
              />
            </div>
            <div className="flex flex-col pl-3">
              <div className="flex items-center">
                <input
                  className={`inline-block appearance-none border rounded w-full py-2 px-3 text-gray-700 ${
                    ctx.getFieldError("sdsVoluntaryDuesAmount")
                      ? "border-red-400"
                      : ""
                  } leading-tight focus:outline-none focus:shadow-outline`}
                  id="sdsVoluntaryDuesAmount"
                  type="text"
                  name="sdsVoluntaryDuesAmount"
                  placeholder=""
                  value={getValue(document, "sdsVoluntaryDuesAmount")}
                  onChange={(e) =>
                    handleInputChange(
                      document,
                      setDocument,
                      "sdsVoluntaryDuesAmount",
                      e
                    )
                  }
                  data-testid="/documents/zuz/card-02/input-sdsVoluntaryDuesAmount"
                />
                <span className="inline-block pl-2">Eur</span>
              </div>
              {ctx.getFieldError("sdsVoluntaryDuesAmount") ? (
                <p className="text-red-400">
                  {ctx.getFieldError("sdsVoluntaryDuesAmount")?.message}
                </p>
              ) : null}
            </div>
          </div>
          <p className="py-3 font-bold">
            Pri sprostredkovaní finančných služieb v sektore SDS je potrebné
            vyplniť s Klientom/Partnerom Investičný-rizikový dotazník.
          </p>
          {/* </div> */}
        </div>
      </div>
    </Card>
  );
}

export default Page02SDSCard;
