/**
 * Feature Flags
 * Usage
 * import{isFeatureEnabled, featureFlags} from 'featureflags'
 *
 * if(isFeatureEnabled(featureFlags.FEATURE_IN_DEVELOPMENT)){
 * ---{ your code }---
 * }
 */

const featureFlags = {
  RULE_RIGHTS: "RULE_RIGHTS",
};

const isEnvironment = (env: string): boolean => {
  return process.env.NODE_ENV === env;
};

const isProduction = isEnvironment("prod");
const isStage = isEnvironment("stage");
const isDemo = isEnvironment("demo");
const isDev = isEnvironment("dev");

const isRuleRightsFeatureEnabled = (): boolean => {
  return (
    process.env.REACT_APP_FEATURE_RULE_RIGHTS === "yes" ||
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore global variable
    window.APP_FEATURE_RULE_RIGHTS === "yes"
  );
};

const isFeatureEnabled = (feature: string): boolean => {
  switch (feature) {
    case featureFlags.RULE_RIGHTS:
      return isRuleRightsFeatureEnabled();
    default:
      return false;
  }
};

const features = {
  isFeatureEnabled,
  featureFlags,
  isProduction,
  isStage,
  isDemo,
  isDev,
};

function useFeatureFlags(): typeof features {
  return features;
}

export default useFeatureFlags;
