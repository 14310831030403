import { RoleKey } from "../api/users.generated";
import RoleType from "./RoleType";
import SelectOption from "./SelectOption";

const RoleOptions: SelectOption<RoleKey>[] = [
  {
    name: "Centrála",
    key: RoleType.CENTRAL,
  },
  {
    name: "Systémový administrátor",
    key: RoleType.SYSTEM_ADMIN,
  },
  {
    name: "Asistent",
    key: RoleType.ASSISTANT,
  },
  {
    name: "Poradca",
    key: RoleType.ADVISOR,
  },
];

export const getRoleName = (key: string | undefined) => {
  const role = RoleOptions.find((r) => r.key === key);
  return role ? role.name : "";
};

export default RoleOptions;
