import React, { FormEvent, useEffect, useState } from "react";

import {
  faHeart,
  faKey,
  faPaperPlane,
  faSyncAlt,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";

import Alert from "../../components/alerts/Alert";
import Button from "../../components/buttons/Button";
import Input from "../../components/inputs/Input";
import InputGroup from "../../components/inputs/InputGroup";
import ForgotPasswordModal from "../../components/modals/ForgotPasswordModal";
import { useAppDispatch } from "../../hooks/store";
import { useVersion } from "../../hooks/version";
import Logo from "../logo/Logo";
import AllRoutes from "../route/Route";
import { authenticateUser, authSelector } from "./authSlice";

function LoginView(): JSX.Element {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const version = useVersion();

  const { isInProgress, isLoggedIn, error } = useSelector(authSelector);

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [validationError, setValidationError] = useState<string>("");
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);

  useEffect(() => {
    if (
      sessionStorage.getItem("showForgotPasswordModal") &&
      sessionStorage.getItem("showForgotPasswordModal") === "yes"
    ) {
      setShowForgotPasswordModal(true);
      sessionStorage.removeItem("showForgotPasswordModal");
    }
  });

  if (isLoggedIn) {
    return <Navigate to={AllRoutes.DASHBOARD.path} />;
  }

  const doLogin = async () => {
    if (username && password) {
      setValidationError("");
      dispatch(
        authenticateUser({
          username,
          password,
        })
      );
    } else {
      setValidationError(t("login.error.empty") || "");
    }
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    doLogin();
  };

  const onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      doLogin();
    }
  };

  return (
    <main>
      <div className="w-full h-screen flex flex-col md:items-center md:justify-center bg-gray-100 text-gray-700 overflow-hidden">
        <div className="flex flex-col md:flex-row bg-white border border-gray-200 w-full max-w-screen-md rounded-xl">
          <div className="order-2 md:order-1 w-full md:w-1/2 bg-white rounded-l-xl p-8 mt-4">
            <form className="login" onSubmit={onSubmit}>
              <h1 className="text-4xl mb-6">
                <span>{t("app.auth.login")}</span>
              </h1>
              {validationError.length > 0 || error?.message ? (
                <Alert variant="danger">
                  {validationError.length ? validationError : error?.message}
                </Alert>
              ) : (
                <div className="text-gray-400 mb-4">
                  <span>{t("app.auth.pleaseSignIn")}</span>
                </div>
              )}
              <InputGroup>
                <Input
                  autoFocus
                  icon={faUserCircle}
                  value={username}
                  placeholder={t("login.form.username")}
                  onChange={(e) => setUsername(e.currentTarget.value)}
                  onKeyUp={onKeyUp}
                  autocomplete="on"
                />
              </InputGroup>
              <InputGroup>
                <Input
                  icon={faKey}
                  type="password"
                  value={password}
                  placeholder={t("login.form.password")}
                  onChange={(e) => setPassword(e.currentTarget.value)}
                  onKeyUp={onKeyUp}
                  autocomplete="current-password"
                />
              </InputGroup>
              <InputGroup>
                <Button
                  className="mr-2"
                  icon={isInProgress ? faSyncAlt : faPaperPlane}
                  inProgress={isInProgress}
                  type="submit"
                  dataTestId="/auth/login/btn-login"
                >
                  <span>{t("app.auth.login").toUpperCase()}</span>
                </Button>
              </InputGroup>
              <div
                aria-hidden="true"
                className="mt-2 inline-block hover:underline cursor-pointer"
                onClick={() => setShowForgotPasswordModal(true)}
              >
                <span>{t("login.form.forgot-password")}</span>
              </div>
            </form>
          </div>
          <div className="order-1 md:order-2 text-white bg-spf-blue p-10 d-md-down-none ml-auto w-full md:w-1/2 md:rounded-r-xl flex flex-row h-full place-items-center justify-center">
            <div className="text-center">
              <Logo size="8x" color="white" />
              <div className="tracking-widest">
                <h1 className="font-semibold text-4xl text-gray-200">
                  <span>{t("app.name")}</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-10 text-center opacity-10 hover:opacity-80 transition-opacity">
          <small>
            <span>Version: {version}, developed by</span>
            <a className="mx-2" href="https://www.ezmid.com/">
              EZMID
            </a>
            <span>with</span>
            <FontAwesomeIcon
              className="ml-2"
              color="red"
              icon={faHeart}
              size="1x"
            />
          </small>
        </div>
      </div>
      <ForgotPasswordModal
        showModal={showForgotPasswordModal}
        setShowModal={setShowForgotPasswordModal}
      />
    </main>
  );
}

export default LoginView;
