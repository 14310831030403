import { baseSplitApi as api } from "./baseApi";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPermissionRuleRights: build.query<
      GetPermissionRuleRightsApiResponse,
      GetPermissionRuleRightsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/permission-rule-rights/`,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort: queryArg.sort,
          permissionRuleId: queryArg.permissionRuleId,
        },
      }),
    }),
    createPermissionRuleRights: build.mutation<
      CreatePermissionRuleRightsApiResponse,
      CreatePermissionRuleRightsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/permission-rule-rights/`,
        method: "POST",
        body: queryArg.permissionRuleRightsUpdateRequest,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    getPermissionRuleRightsById: build.query<
      GetPermissionRuleRightsByIdApiResponse,
      GetPermissionRuleRightsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/permission-rule-rights/${queryArg.id}`,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    updatePermissionRuleRights: build.mutation<
      UpdatePermissionRuleRightsApiResponse,
      UpdatePermissionRuleRightsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/permission-rule-rights/${queryArg.id}`,
        method: "PUT",
        body: queryArg.permissionRuleUpdateRequest,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    deletePermissionRuleRights: build.mutation<
      DeletePermissionRuleRightsApiResponse,
      DeletePermissionRuleRightsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/permission-rule-rights/${queryArg.id}`,
        method: "DELETE",
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    createPermissionRule: build.mutation<
      CreatePermissionRuleApiResponse,
      CreatePermissionRuleApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/permission-rules`,
        method: "POST",
        body: queryArg.permissionRuleUpdateRequest,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    getPermissionRules: build.query<
      GetPermissionRulesApiResponse,
      GetPermissionRulesApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/permission-rules/`,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort: queryArg.sort,
          keyword: queryArg.keyword,
          types: queryArg.types,
          states: queryArg.states,
        },
      }),
    }),
    getPermissionRuleById: build.query<
      GetPermissionRuleByIdApiResponse,
      GetPermissionRuleByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/permission-rules/${queryArg.id}`,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    updatePermissionRule: build.mutation<
      UpdatePermissionRuleApiResponse,
      UpdatePermissionRuleApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/permission-rules/${queryArg.id}`,
        method: "PUT",
        body: queryArg.permissionRuleUpdateRequest,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    deletePermissionRule: build.mutation<
      DeletePermissionRuleApiResponse,
      DeletePermissionRuleApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/permission-rules/${queryArg.id}`,
        method: "DELETE",
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as spfApi };
export type GetPermissionRuleRightsApiResponse =
  /** status 200 OK */ PermissionRuleRights;
export type GetPermissionRuleRightsApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** page */
  page?: number;
  /** size */
  size?: number;
  /** Format is sort=created_at:desc. Supported keys: created_at */
  sort?: string[];
  /** permission rule Id */
  permissionRuleId?: string[];
};
export type CreatePermissionRuleRightsApiResponse =
  /** status 200 OK */ SuccessResponse200;
export type CreatePermissionRuleRightsApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** PermissionRuleRights to be updated */
  permissionRuleRightsUpdateRequest: PermissionRuleRightsUpdateRequest;
};
export type GetPermissionRuleRightsByIdApiResponse =
  /** status 200 OK */ PermissionRuleRights;
export type GetPermissionRuleRightsByIdApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** uuid */
  id: string;
};
export type UpdatePermissionRuleRightsApiResponse =
  /** status 200 OK */ SuccessResponse200;
export type UpdatePermissionRuleRightsApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** uuid */
  id: string;
  /** PermissionRule to be updated */
  permissionRuleUpdateRequest: PermissionRuleUpdateRequest;
};
export type DeletePermissionRuleRightsApiResponse =
  /** status 200 OK */ SuccessResponse200;
export type DeletePermissionRuleRightsApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** uuid */
  id: string;
};
export type CreatePermissionRuleApiResponse =
  /** status 200 OK */ PermissionRule;
export type CreatePermissionRuleApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** PermissionRule to be created */
  permissionRuleUpdateRequest: PermissionRuleUpdateRequest;
};
export type GetPermissionRulesApiResponse =
  /** status 200 OK */ PermissionRulePage;
export type GetPermissionRulesApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** page */
  page?: number;
  /** size */
  size?: number;
  /** Format is sort=type:asc,name:desc,... */
  sort?: string[];
  /** keyword to search for in permission rules */
  keyword?: string;
  /** permission rules types filter [pr-all, pr-advisor-position, pr-role, pr-user-role, pr-advisor-tree, pr-advisor-children] */
  types?: string[];
  /** permission rules states filter [prs-active, prs-deactivated] */
  states?: string[];
};
export type GetPermissionRuleByIdApiResponse =
  /** status 200 OK */ PermissionRule;
export type GetPermissionRuleByIdApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** uuid */
  id: string;
};
export type UpdatePermissionRuleApiResponse =
  /** status 200 OK */ SuccessResponse200;
export type UpdatePermissionRuleApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** uuid */
  id: string;
  /** PermissionRule to be updated */
  permissionRuleUpdateRequest: PermissionRuleUpdateRequest;
};
export type DeletePermissionRuleApiResponse =
  /** status 200 OK */ SuccessResponse200;
export type DeletePermissionRuleApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** uuid */
  id: string;
};
export type PermissionRuleRightsActionTypeKey =
  | "prra-add"
  | "prra-none"
  | "prra-remove";
export type PermissionRuleRightsActionType = {
  key?: PermissionRuleRightsActionTypeKey;
  name?: string;
};
export type PermissionObjectTypeKey =
  | "pt-customers"
  | "pt-external-links"
  | "pt-cca"
  | "pt-check"
  | "pt-identifications"
  | "pt-users"
  | "pt-permissions"
  | "pt-system-settings";
export type PermissionObjectType = {
  key?: PermissionObjectTypeKey;
  name?: string;
};
export type CountryKey = "sk";
export type Country = {
  key?: CountryKey;
  name?: string;
};
export type Address = {
  city?: string;
  country?: Country;
  street?: string;
  zip?: string;
};
export type ExternalLinkAuthTypeKey = "elat-sso" | "elat-log" | "elat-noa";
export type LinkType = {
  key?: ExternalLinkAuthTypeKey;
  name?: string;
};
export type ExternalLinkCategoryKey =
  | "lct-int"
  | "lct-ins"
  | "lct-bnk"
  | "lct-dss"
  | "lct-oth"
  | "lct-inc";
export type ExternalLinkCategory = {
  key?: ExternalLinkCategoryKey;
  name?: string;
};
export type ImageResponse = {
  type?: string;
  url?: string;
};
export type ExternalLinkConfigurationStatusKey =
  | "elcs-active"
  | "elcs-deactivated";
export type ExternalLinkConfigurationStatus = {
  key?: ExternalLinkConfigurationStatusKey;
  name?: string;
};
export type ExternalLinkConfiguration = {
  advisorExternalID?: string;
  advisorFirstame?: string;
  advisorId?: string;
  advisorLastname?: string;
  createdAt?: string;
  id?: string;
  login?: string;
  password?: string;
  status?: ExternalLinkConfigurationStatus;
  updatedAt?: string;
};
export type AdvisorSectorTypeKey =
  | "ast-aml"
  | "ast-ins"
  | "ast-len"
  | "ast-acc"
  | "ast-cap"
  | "ast-sup"
  | "ast-old";
export type AdvisorSectorType = {
  key?: AdvisorSectorTypeKey;
  name?: string;
};
export type ExternalLinkSector = {
  mandatory?: boolean;
  sectorType?: AdvisorSectorType;
};
export type ExternalLinkStatusKey = "els-active" | "els-deactivated";
export type ExternalLinkStatus = {
  key?: ExternalLinkStatusKey;
  name?: string;
};
export type ExternalLinkTypeKey = "ast-per" | "ast-gen";
export type ExternalLinkType = {
  key?: ExternalLinkTypeKey;
  name?: string;
};
export type ExternalLink = {
  authType?: LinkType;
  category?: ExternalLinkCategory;
  createdAt?: string;
  displayLink?: string;
  id?: string;
  image?: ImageResponse[];
  imageId?: string;
  linkConfigurations?: ExternalLinkConfiguration[];
  name?: string;
  order?: number;
  sectors?: ExternalLinkSector[];
  status?: ExternalLinkStatus;
  type?: ExternalLinkType;
  updatedAt?: string;
};
export type FavouriteExternalLink = {
  createdAt?: string;
  externalLink?: ExternalLink;
  order?: number;
  updatedAt?: string;
};
export type GenderKey = "gk-male" | "gk-female" | "gk-none";
export type Gender = {
  key?: GenderKey;
  name?: string;
};
export type AdvisorPositionKey =
  | "SP"
  | "KR"
  | "RR"
  | "OR"
  | "M"
  | "S3"
  | "S2"
  | "S1"
  | "SK"
  | "K2"
  | "K1"
  | "T"
  | "BP"
  | "BP90"
  | "BP85"
  | "BP70"
  | "BP00"
  | "FEL1"
  | "FEL2"
  | "FEL3"
  | "FEL4";
export type AdvisorPosition = {
  higherMLM?: AdvisorPositionKey;
  key?: AdvisorPositionKey;
  name?: string;
};
export type AdvisorSector = {
  certificate_from?: string;
  certificate_to?: string;
  createdAt?: string;
  id?: string;
  ofv_from?: string;
  ofv_to?: string;
  registration_from?: string;
  registration_to?: string;
  type?: AdvisorSectorType;
  updatedAt?: string;
};
export type AdvisorTypeKey = "at-fo" | "at-fop";
export type AdvisorType = {
  key?: AdvisorTypeKey;
  name?: string;
};
export type OriginSystemKey = "uo-portal" | "uo-icm";
export type OriginSystem = {
  key?: OriginSystemKey;
  name?: string;
};
export type UserStatusKey = "us-active" | "us-deactivated" | "us-resigned";
export type UserStatus = {
  key?: UserStatusKey;
  name?: string;
};
export type User = {
  createdAt?: string;
  displayName: string;
  email: string;
  id?: string;
  originSystem?: OriginSystem;
  status?: UserStatus;
  updatedAt?: string;
};
export type Advisor = {
  businessAddress?: Address;
  businessName?: string;
  createdAt?: string;
  displayName?: string;
  evidenceNumber: string;
  externalId?: string;
  favouriteLinks?: FavouriteExternalLink[];
  firstname?: string;
  gender?: Gender;
  ico?: string;
  id?: string;
  lastname?: string;
  mentor?: Advisor;
  nbsRegistrationNumber?: string;
  parent?: Advisor;
  permanentAddress?: Address;
  phone_number?: string;
  pin?: string;
  position?: AdvisorPosition;
  sectors?: AdvisorSector[];
  title?: string;
  type?: AdvisorType;
  updatedAt?: string;
  updatedBy?: User;
  updatedByICM?: string;
  user?: User;
};
export type RoleKey =
  | "rk-assistant"
  | "rk-advisor"
  | "rk-central"
  | "rk-system-admin";
export type Role = {
  key?: RoleKey;
  name?: string;
};
export type PermissionRuleStatusKey = "prs-active" | "prs-deactivated";
export type PermissionRuleStatus = {
  key?: PermissionRuleStatusKey;
  name?: string;
};
export type PermissionRuleTypeKey =
  | "pr-all"
  | "pr-advisor-position"
  | "pr-role"
  | "pr-user-role"
  | "pr-advisor-tree"
  | "pr-advisor-children";
export type PermissionRuleType = {
  key?: PermissionRuleTypeKey;
  name?: string;
};
export type Assistant = {
  advisor?: Advisor;
  contractEnd?: string;
  contractStart?: string;
  createdAt?: string;
  displayName?: string;
  evidenceNumber: string;
  id?: string;
  name?: string;
  phone_number?: string;
  updatedAt?: string;
  user?: User;
};
export type Central = {
  clientDisplayName?: string;
  createdAt?: string;
  externalId: string;
  firstname?: string;
  icmId: number;
  id?: string;
  lastname?: string;
  updatedAt?: string;
  user?: User;
};
export type ComputedRightLogEntry = {
  create?: PermissionRuleRightsActionTypeKey;
  delete?: PermissionRuleRightsActionTypeKey;
  id?: string;
  name?: string;
  read?: PermissionRuleRightsActionTypeKey;
  type?: PermissionRuleTypeKey;
  update?: PermissionRuleRightsActionTypeKey;
  updatedAt?: string;
};
export type RoleUserRight = {
  create?: PermissionRuleRightsActionTypeKey;
  delete?: PermissionRuleRightsActionTypeKey;
  log?: ComputedRightLogEntry[];
  read?: PermissionRuleRightsActionTypeKey;
  type?: PermissionObjectTypeKey;
  update?: PermissionRuleRightsActionTypeKey;
};
export type RoleUserStatusKey = "rus-active" | "rus-deactivated";
export type RoleUserStatus = {
  key?: RoleUserStatusKey;
  name?: string;
};
export type RoleUser = {
  advisor?: Advisor;
  assistant?: Assistant;
  central?: Central;
  computedRights?: RoleUserRight[];
  id?: string;
  role?: Role;
  status?: RoleUserStatus;
};
export type PermissionRule = {
  advisor?: Advisor;
  advisor_position?: AdvisorPosition;
  createdAt?: string;
  id?: string;
  name?: string;
  permissionRuleRights?: PermissionRuleRights[];
  role?: Role;
  status?: PermissionRuleStatus;
  type?: PermissionRuleType;
  updatedAt?: string;
  updatedBy?: User;
  users_role?: RoleUser[];
};
export type PermissionRuleRights = {
  create?: PermissionRuleRightsActionType;
  createdAt?: string;
  delete?: PermissionRuleRightsActionType;
  id?: string;
  permission_object_type?: PermissionObjectType;
  permission_rule?: PermissionRule;
  read?: PermissionRuleRightsActionType;
  update?: PermissionRuleRightsActionType;
  updatedAt?: string;
};
export type ValidationError = {
  code?: string;
  field?: string;
  message?: string;
};
export type ErrorResponse400 = {
  code?: number;
  data?: ValidationError[];
  message?: string;
};
export type SuccessResponse200 = {
  id?: string;
  status?: string;
};
export type ErrorResponse401 = {
  code?: number;
  data?: {
    [key: string]: string;
  }[];
  message?: string;
};
export type ErrorResponse500 = {
  code?: number;
  data?: {
    [key: string]: string;
  }[];
  message?: string;
};
export type PermissionRuleRightsUpdateRequest = {
  createKey: PermissionRuleRightsActionTypeKey;
  deleteKey: PermissionRuleRightsActionTypeKey;
  id?: string;
  permissionRuleID?: string;
  readKey: PermissionRuleRightsActionTypeKey;
  typeKey: PermissionObjectTypeKey;
  updateKey: PermissionRuleRightsActionTypeKey;
};
export type ErrorResponse404 = {
  code?: number;
  data?: {
    [key: string]: string;
  }[];
  message?: string;
};
export type PermissionRuleUpdateRequest = {
  advisorId?: string;
  advisorPositionKey?: AdvisorPositionKey;
  name: string;
  permissionRuleRights?: PermissionRuleRightsUpdateRequest[];
  roleKey?: RoleKey;
  roleUsers?: string[];
  statusKey: PermissionRuleStatusKey;
  typeKey: PermissionRuleTypeKey;
};
export type PermissionRuleFilter = {
  keyword?: string;
  states?: PermissionRuleStatusKey[];
  types?: PermissionRuleTypeKey[];
};
export type SortFieldDirection = "asc" | "desc";
export type SortFieldKey =
  | "createdAt"
  | "updatedAt"
  | "startsAt"
  | "validTo"
  | "validUntil"
  | "firstname"
  | "lastname"
  | "name"
  | "displayName"
  | "evidenceNumber"
  | "email"
  | "pin"
  | "ico"
  | "advisorEmail"
  | "customerEmail"
  | "mentorEmail"
  | "phoneNumber"
  | "displayId"
  | "street"
  | "city"
  | "zip"
  | "id"
  | "owner"
  | "type"
  | "status"
  | "customer"
  | "advisor"
  | "updatedBy";
export type SortField = {
  direction?: SortFieldDirection;
  field?: SortFieldKey;
};
export type PermissionRulePage = {
  count?: number;
  filter?: PermissionRuleFilter;
  items?: PermissionRule[];
  last?: boolean;
  page?: number;
  size?: number;
  sort?: SortField[];
};
export const {
  useGetPermissionRuleRightsQuery,
  useCreatePermissionRuleRightsMutation,
  useGetPermissionRuleRightsByIdQuery,
  useUpdatePermissionRuleRightsMutation,
  useDeletePermissionRuleRightsMutation,
  useCreatePermissionRuleMutation,
  useGetPermissionRulesQuery,
  useGetPermissionRuleByIdQuery,
  useUpdatePermissionRuleMutation,
  useDeletePermissionRuleMutation,
} = injectedRtkApi;
