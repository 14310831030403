import { baseSplitApi as api } from "./baseApi";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    checkValidCustomers: build.mutation<
      CheckValidCustomersApiResponse,
      CheckValidCustomersApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/admin/identifications/check-valid-customers`,
        method: "POST",
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    checkValidCustomersSingle: build.mutation<
      CheckValidCustomersSingleApiResponse,
      CheckValidCustomersSingleApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/admin/identifications/${queryArg.id}/check-valid-customers`,
        method: "POST",
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    updatePdfFromVerificationProvider: build.mutation<
      UpdatePdfFromVerificationProviderApiResponse,
      UpdatePdfFromVerificationProviderApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/admin/identifications/${queryArg.id}/update-pdf-from-provider`,
        method: "POST",
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as spfApi };
export type CheckValidCustomersApiResponse =
  /** status 200 OK */ SuccessResponse200;
export type CheckValidCustomersApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
};
export type CheckValidCustomersSingleApiResponse =
  /** status 200 OK */ SuccessResponse200;
export type CheckValidCustomersSingleApiArg = {
  /** uuid */
  id: string;
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
};
export type UpdatePdfFromVerificationProviderApiResponse =
  /** status 200 OK */ SuccessResponse200;
export type UpdatePdfFromVerificationProviderApiArg = {
  /** uuid */
  id: string;
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
};
export type SuccessResponse200 = {
  id?: string;
  status?: string;
};
export type ValidationError = {
  code?: string;
  field?: string;
  message?: string;
};
export type ErrorResponse400 = {
  code?: number;
  data?: ValidationError[];
  message?: string;
};
export type ErrorResponse401 = {
  code?: number;
  data?: {
    [key: string]: string;
  }[];
  message?: string;
};
export type ErrorResponse500 = {
  code?: number;
  data?: {
    [key: string]: string;
  }[];
  message?: string;
};
export const {
  useCheckValidCustomersMutation,
  useCheckValidCustomersSingleMutation,
  useUpdatePdfFromVerificationProviderMutation,
} = injectedRtkApi;
