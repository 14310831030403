import React from "react";

import {
  faCheckCircle,
  faHourglassStart,
} from "@fortawesome/free-solid-svg-icons";
import { t } from "i18next";
import { useNavigate } from "react-router";

import AllRoutes from "../../features/route/Route";
import { makeRoute } from "../../hooks/route";
import Badge from "./Badge";

interface Properties {
  className?: string;
  small?: boolean;
  id?: string;
}

function CustomerValidIdentificationBadge({
  className,
  small,
  id,
}: Properties): JSX.Element {
  const navigate = useNavigate();
  let icon = faHourglassStart;
  let label = "app.customer.not-identified";

  let bgClass = "bg-spf-red";
  let color = "text-stone-100";

  if (id) {
    icon = faCheckCircle;
    label = "app.customer.identified";
    bgClass = "bg-spf-green";
    color = "text-stone-100";
    return (
      <Badge
        className={className}
        bgClass={bgClass}
        color={color}
        icon={icon}
        label={t(label) ?? ""}
        small={small}
        onClick={() => {
          const path = makeRoute(AllRoutes.IDENTIFICATION_DETAIL, {
            id,
          });
          navigate(path);
        }}
        pill
      />
    );
  }
  return (
    <Badge
      className={className}
      bgClass={bgClass}
      color={color}
      icon={icon}
      label={t(label) ?? ""}
      small={small}
      pill
    />
  );
}

CustomerValidIdentificationBadge.defaultProps = {
  className: "",
  small: false,
  id: "",
};

export default CustomerValidIdentificationBadge;
