import React from "react";

import { faCheckCircle, faStopCircle } from "@fortawesome/free-solid-svg-icons";

import { ExternalLinkConfigurationStatus } from "../../api/external-link.generated";
import Badge from "./Badge";

interface Properties {
  status: ExternalLinkConfigurationStatus | undefined;
}

function ExternalLinkConfigurationStatusBadge({
  status,
}: Properties): JSX.Element {
  let icon = faCheckCircle;
  let bgClass = "bg-spf-green";
  let color = "text-stone-100";
  if (status && status.key === "elcs-active") {
    bgClass = "bg-spf-green";
    color = "text-stone-100";
    icon = faCheckCircle;
  }
  if (status && status.key === "elcs-deactivated") {
    bgClass = "bg-spf-red";
    color = "text-stone-100";
    icon = faStopCircle;
  }
  return (
    <Badge
      bgClass={bgClass}
      color={color}
      icon={icon}
      label={`status.${status?.key}`}
      pill
    />
  );
}

export default ExternalLinkConfigurationStatusBadge;
