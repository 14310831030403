import React, { useState } from "react";

import {
  faExchangeAlt,
  faList,
  faUsers,
  faUserShield,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
  useCheckValidCustomersMutation,
  useCheckValidCustomersSingleMutation,
} from "../../api/adminIdentification.generated";
import {
  useSetDocumentSearchTsvMutation,
  useSendDocumentSignedEmailMutation,
} from "../../api/documents.generated";
import {
  useSeedAdvisorsMutation,
  useSeedCustomersMutation,
} from "../../api/seeder.generated";
import {
  useSynchronizeAdvisorsMutation,
  useSynchronizeAllEntitiesMutation,
  useSynchronizeCustomerIcmDataMutation,
  useSynchronizeCustomersMutation,
  useSynchronizeSendCustomerEmailMutation,
} from "../../api/synchronization.generated";
import Button from "../../components/buttons/Button";
import Card from "../../components/card/Card";
import CardBody from "../../components/card/CardBody";
import CardHeader from "../../components/card/CardHeader";
import InputEdit from "../../components/inputs/InputEdit";
import PageHeader from "../../components/page/PageHeader";
import { getRoleId } from "../../hooks/role";
import AllRoutes from "../route/Route";

function SystemManagementView() {
  const { t } = useTranslation();

  const [seedAdvisors, seedAdvisorsResult] = useSeedAdvisorsMutation({});
  const [seedCustomers, seedCustomersResult] = useSeedCustomersMutation({});

  const [syncAdvisors, syncAdvisorsResult] = useSynchronizeAdvisorsMutation({});
  const [syncCustomers, syncCustomersResult] = useSynchronizeCustomersMutation(
    {}
  );
  const [syncSendCustomerEmail, syncSendCustomerEmailResult] =
    useSynchronizeSendCustomerEmailMutation({});

  const [syncCustomerICMData, syncCustomerICMDataResult] =
    useSynchronizeCustomerIcmDataMutation({});
  const [syncAllEntitites, syncAllEntitiesResult] =
    useSynchronizeAllEntitiesMutation({});
  const [setDocumentSearchTSV, setDocumentSearchTSVResult] =
    useSetDocumentSearchTsvMutation({});
  const [sendDocumentSignedEmail, sendDocumentSignedEmailResult] =
    useSendDocumentSignedEmailMutation({});
  const [checkValidCustomers, checkValidCustomersResult] =
    useCheckValidCustomersMutation({});
  const [checkValidCustomersSingle, checkValidCustomersSingleResult] =
    useCheckValidCustomersSingleMutation({});

  const [syncAllEntitiesDays, setSyncAllEntititesDays] = useState<number>(1);
  const [syncCustomersDays, setSyncCustomersDays] = useState<number>(1);
  const [checkValidCustomersSingleID, setCheckValidCustomersSingleID] =
    useState<string>("uuid");
  const [syncAdvisorsDays, setSyncAdvisorsDays] = useState<number>(1);
  const [sendSignedDocumentEmailID, setSendSignedDocumentEmailID] =
    useState<string>("uuid");

  const handleSendDocumentSignedEmail = () => {
    if (!sendDocumentSignedEmailResult.isLoading) {
      sendDocumentSignedEmail({
        "X-Role-Id": getRoleId(),
        id: sendSignedDocumentEmailID,
      })
        .unwrap()
        .then((response) => {
          if (response) {
            toast.success("Sending email was successful");
          }
        })
        .catch((error) => {
          toast.error(
            `An error occured, check console: ${error?.data?.message}`
          );
        });
    }
  };
  const handleCheckValidCustomers = () => {
    if (!checkValidCustomersResult.isLoading) {
      checkValidCustomers({
        "X-Role-Id": getRoleId(),
      })
        .unwrap()
        .then((response) => {
          if (response) {
            toast.success("checkValidCustomersResult successful");
          }
        })
        .catch((error) => {
          toast.error(
            `An error occured, check console: ${error?.data?.message}`
          );
        });
    }
  };
  const handleCheckValidCustomersSingle = () => {
    if (!checkValidCustomersSingleResult.isLoading) {
      checkValidCustomersSingle({
        "X-Role-Id": getRoleId(),
        id: checkValidCustomersSingleID,
      })
        .unwrap()
        .then((response) => {
          if (response) {
            toast.success("checkValidCustomersResult successful");
          }
        })
        .catch((error) => {
          toast.error(
            `An error occured, check console: ${error?.data?.message}`
          );
        });
    }
  };
  const handleDocumentSearchTSV = () => {
    if (!setDocumentSearchTSVResult.isLoading) {
      setDocumentSearchTSV({ "X-Role-Id": getRoleId() })
        .unwrap()
        .then((response) => {
          if (response) {
            toast.success("Setting searchTSVs was successful");
          }
        })
        .catch((error) => {
          toast.error(
            `An error occured, check console: ${error?.data?.message}`
          );
        });
    }
  };

  const handleSeedAdvisors = () => {
    if (!seedAdvisorsResult.isLoading) {
      seedAdvisors({ "X-Role-Id": getRoleId() })
        .unwrap()
        .then((response) => {
          if (response) {
            toast.success("Seed was successful");
          }
        })
        .catch((error) => {
          toast.error(
            `An error occured, check console: ${error?.data?.message}`
          );
        });
    }
  };

  const handleSeedCustomers = () => {
    if (!seedCustomersResult.isLoading) {
      seedCustomers({ "X-Role-Id": getRoleId() })
        .unwrap()
        .then((response) => {
          if (response) {
            toast.success("Seed was successful");
          }
        })
        .catch((error) => {
          toast.error(
            `An error occured, check console: ${error?.data?.message}`
          );
        });
    }
  };

  const handleSyncAdvisors = () => {
    if (!syncAdvisorsResult.isLoading) {
      syncAdvisors({ "X-Role-Id": getRoleId(), days: syncAdvisorsDays })
        .unwrap()
        .then((response) => {
          if (response) {
            toast.success("Sync was successful");
          }
        })
        .catch((error) => {
          toast.error(
            `An error occured, check console: ${error?.data?.message}`
          );
        });
    }
  };

  const handleSyncCustomers = () => {
    if (!syncCustomersResult.isLoading) {
      syncCustomers({ "X-Role-Id": getRoleId(), days: syncCustomersDays })
        .unwrap()
        .then((response) => {
          if (response) {
            toast.success("Sync was successful");
          }
        })
        .catch((error) => {
          toast.error(
            `An error occured, check console: ${error?.data?.message}`
          );
        });
    }
  };

  const handleSyncAllEntities = () => {
    if (!syncAllEntitiesResult.isLoading) {
      syncAllEntitites({ "X-Role-Id": getRoleId(), days: syncAllEntitiesDays })
        .unwrap()
        .then((response) => {
          if (response) {
            toast.success("Sync was successful");
          }
        })
        .catch((error) => {
          toast.error(
            `An error occured, check console: ${error?.data?.message}`
          );
        });
    }
  };

  const handleSendCustomerSyncEmail = () => {
    if (!syncSendCustomerEmailResult.isLoading) {
      syncSendCustomerEmail({ "X-Role-Id": getRoleId() })
        .unwrap()
        .then((response) => {
          if (response) {
            toast.success("Sync was successful");
          }
        })
        .catch((error) => {
          toast.error(
            `An error occured, check console: ${error?.data?.message}`
          );
        });
    }
  };

  const handleSyncCustomerICMData = () => {
    if (!syncCustomerICMDataResult.isLoading) {
      syncCustomerICMData({ "X-Role-Id": getRoleId() })
        .unwrap()
        .then((response) => {
          if (response) {
            toast.success("Sync was successful");
          }
        })
        .catch((error) => {
          toast.error(
            `An error occured, check console: ${error?.data?.message}`
          );
        });
    }
  };

  return (
    <>
      <PageHeader
        icon={AllRoutes.SYSTEM_MANAGEMENT_VIEW.icon}
        label={t("app.page.systemManagement.heading")}
      />

      <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
        <Card>
          <CardHeader label={t("card.header.actions") || ""} icon={faList} />
          <CardBody>
            <div className="p-4 bg-slate-200 rounded-xl grid gap-4">
              <div className="font-bold border-b border-slate-300 pb-4 mb-2">
                {t("card.header.synchronization") || ""}
              </div>
              <div className="gap-4 justify-end grid grid-cols-3">
                <div className="w-20 mt-11">
                  {t("general.advisors.label") || "Advisors"}
                </div>
                <div className="">
                  <InputEdit
                    label={
                      t("general.number-of-days.label") || "number of days"
                    }
                    value={syncAdvisorsDays.toString()}
                    onChange={(e) => {
                      if (!isNaN(Number(e.currentTarget.value))) {
                        setSyncAdvisorsDays(Number(e.currentTarget.value));
                      }
                    }}
                  />
                </div>
                <div className="mt-9 ml-auto">
                  <Button
                    icon={faExchangeAlt}
                    className="bg-green-500"
                    inProgress={syncAdvisorsResult.isLoading}
                    onClick={handleSyncAdvisors}
                  >
                    {t("general.sync-now.label") || "Sync now"}
                  </Button>
                </div>
              </div>
              <div className="gap-4 justify-end grid grid-cols-3">
                <div className="w-20 mt-11">
                  {" "}
                  {t("general.customers.label") || "Customers"}
                </div>
                <div className="">
                  <InputEdit
                    label={
                      t("general.number-of-days.label") || "number of days"
                    }
                    value={syncCustomersDays.toString()}
                    onChange={(e) => {
                      if (!isNaN(Number(e.currentTarget.value))) {
                        setSyncCustomersDays(Number(e.currentTarget.value));
                      }
                    }}
                  />
                </div>
                <div className="mt-9 ml-auto">
                  <Button
                    icon={faExchangeAlt}
                    className="bg-green-500"
                    inProgress={syncCustomersResult.isLoading}
                    onClick={handleSyncCustomers}
                  >
                    {t("general.sync-now.label") || "Sync now"}
                  </Button>
                </div>
              </div>
              <div className="gap-4 justify-end grid grid-cols-3">
                <div className="w-20 mt-11">Sync All</div>
                <div className="">
                  <InputEdit
                    label={
                      t("general.number-of-days.label") || "number of days"
                    }
                    value={syncAllEntitiesDays.toString()}
                    onChange={(e) => {
                      if (!isNaN(Number(e.currentTarget.value))) {
                        setSyncAllEntititesDays(Number(e.currentTarget.value));
                      }
                    }}
                  />
                </div>
                <div className="mt-9 ml-auto">
                  <Button
                    icon={faExchangeAlt}
                    className="bg-green-500"
                    inProgress={syncAllEntitiesResult.isLoading}
                    onClick={handleSyncAllEntities}
                  >
                    {t("general.sync-now.label") || "Sync now"}
                  </Button>
                </div>
              </div>
              <div className="grid lg:grid-cols-3 gap-4 items-center">
                <div className="">Send Customer Sync Email</div>
                <div className="" />
                <div className="text-right">
                  <Button
                    icon={faExchangeAlt}
                    className="bg-green-500"
                    inProgress={syncSendCustomerEmailResult.isLoading}
                    onClick={handleSendCustomerSyncEmail}
                  >
                    {t("general.send.label") || "Send"}
                  </Button>
                </div>
              </div>
            </div>
            <div className="p-4 bg-slate-200 rounded-xl flex flex-col gap-4 mt-4">
              <div className="font-bold border-b border-slate-300 pb-4 mb-2">
                {t("card.header.customers") || ""}
              </div>
              <div className="gap-4 justify-end grid grid-cols-3">
                <div className="mt-8">
                  {t("bo-admin.update-customer-icmdata.label") ||
                    "Update Customer ICMData"}
                </div>
                <div className="">
                  <InputEdit
                    label={t("general.no-arguments.label") || "No arguments"}
                    value=""
                    disabled
                  />
                </div>
                <div className="mt-9 ml-auto">
                  <Button
                    icon={faExchangeAlt}
                    className="bg-green-500"
                    inProgress={syncCustomerICMDataResult.isLoading}
                    onClick={handleSyncCustomerICMData}
                  >
                    {t("general.set.label") || "Set"}
                  </Button>
                </div>
              </div>
            </div>
            <div className="p-4 bg-slate-200 rounded-xl flex flex-col gap-4 mt-4">
              <div className="font-bold border-b border-slate-300 pb-4 mb-2">
                {t("card.header.seeder") || ""}
              </div>
              <div className="gap-4 justify-end grid grid-cols-3">
                <div className="mt-11">
                  {t("general.advisors.label") || "Advisors"}
                </div>
                <div className="">
                  <InputEdit
                    label={t("general.no-arguments.label") || "No arguments"}
                    value=""
                    disabled
                  />
                </div>
                <div className="mt-9 ml-auto">
                  <Button
                    icon={faUserShield}
                    disabled
                    className="bg-red-500"
                    inProgress={seedAdvisorsResult.isLoading}
                    onClick={handleSeedAdvisors}
                  >
                    {t("general.sync-now.label") || "Sync now"}
                  </Button>
                </div>
              </div>
              <div className="gap-4 justify-end grid grid-cols-3">
                <div className="mt-11">
                  {t("general.customers.label") || "Customers"}
                </div>
                <div className="">
                  <InputEdit
                    label={t("general.no-arguments.label") || "No arguments"}
                    value=""
                    disabled
                  />
                </div>
                <div className="mt-9 ml-auto">
                  <Button
                    icon={faUsers}
                    disabled
                    className="bg-red-500"
                    inProgress={seedCustomersResult.isLoading}
                    onClick={handleSeedCustomers}
                  >
                    {t("general.sync-now.label") || "Sync now"}
                  </Button>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardHeader label={t("card.header.actions") || ""} icon={faList} />
          <CardBody>
            <div className="p-4 bg-slate-200 rounded-xl flex flex-col gap-4">
              <div className="font-bold border-b border-slate-300 pb-4 mb-2">
                {t("card.header.documents") || ""}
              </div>
              <div className="gap-4 justify-end grid grid-cols-3">
                <div className="mt-8">
                  {t("bo-admin.update-document-searchtsv.label") ||
                    "Update Document search column(searchTSV)"}
                </div>
                <div className="">
                  <InputEdit
                    label={t("general.no-arguments.label") || "No arguments"}
                    value=""
                    disabled
                  />
                </div>
                <div className="mt-9 ml-auto">
                  <Button
                    icon={faExchangeAlt}
                    className="bg-green-500"
                    inProgress={syncCustomerICMDataResult.isLoading}
                    onClick={handleDocumentSearchTSV}
                  >
                    {t("general.set.label") || "Set"}
                  </Button>
                </div>
              </div>
              <div className="gap-4 justify-end grid grid-cols-3">
                <div className="w-20 mt-11">Send Email</div>
                <div className="">
                  <InputEdit
                    label="uuid"
                    value={sendSignedDocumentEmailID}
                    onChange={(e) => {
                      setSendSignedDocumentEmailID(e.currentTarget.value);
                    }}
                  />
                </div>
                <div className="mt-9 ml-auto">
                  <Button
                    icon={faExchangeAlt}
                    className="bg-green-500"
                    inProgress={sendDocumentSignedEmailResult.isLoading}
                    onClick={handleSendDocumentSignedEmail}
                  >
                    {t("general.send.label") || "Send"}
                  </Button>
                </div>
              </div>
            </div>
            <div className="p-4 bg-slate-200 rounded-xl flex-col gap-4 mt-4">
              <div className="font-bold border-b border-slate-300 pb-4 mb-2">
                {t("card.header.identifications") || ""}
              </div>
              <div className="gap-4 justify-end grid  grid-cols-3">
                <div className="mt-8">
                  {t("bo-admin.check-valid-customers-all.label") ||
                    "Check valid customers for all identifications"}
                </div>
                <div className="">
                  <InputEdit
                    label={t("general.no-arguments.label") || "No arguments"}
                    value=""
                    disabled
                  />
                </div>
                <div className="mt-9 ml-auto">
                  <Button
                    icon={faExchangeAlt}
                    className="bg-green-500"
                    inProgress={checkValidCustomersResult.isLoading}
                    onClick={handleCheckValidCustomers}
                  >
                    {t("general.send.label") || "Send"}
                  </Button>
                </div>
              </div>
              <div className="gap-4 justify-end grid  grid-cols-3">
                <div className="mt-8">
                  {t("bo-admin.check-valid-customers-single.label") ||
                    "Check valid customers for single identification"}
                </div>
                <div className="">
                  <InputEdit
                    label="uuid"
                    value={checkValidCustomersSingleID}
                    onChange={(e) => {
                      setCheckValidCustomersSingleID(e.currentTarget.value);
                    }}
                  />
                </div>
                <div className="mt-9 ml-auto">
                  <Button
                    icon={faExchangeAlt}
                    className="bg-green-500"
                    inProgress={checkValidCustomersSingleResult.isLoading}
                    onClick={handleCheckValidCustomersSingle}
                  >
                    {t("general.send.label") || "Send"}
                  </Button>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
}

export default SystemManagementView;
