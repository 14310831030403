/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext } from "react";

import Card from "../../../components/card/Card";
import ClientPartnerCheckbox from "../../../components/inputs/ClientPartnerCheckbox";
import ClientPartnerRadio from "../../../components/inputs/ClientPartnerRadio";
import useDocumentGetters from "../../../hooks/documentContent";
import { Document } from "../../../models/Document";
import DocumentZuzContext from "../DocumentZuzFormViewContext";
import DocumentInput from "./DocumentInput";

interface Properties {
  document: Document;
  setDocument: React.Dispatch<Document>;
}

function Page02DDSCard({ document, setDocument }: Properties) {
  const { getValue, handleInputChange } = useDocumentGetters();
  const ctx = useContext(DocumentZuzContext);

  return (
    <Card className="mb-8 px-4">
      <div className="py-3 mb-6">
        <div className="mb-4">
          <div className="text-base font-bold my-2">
            Vstupné informácie (podľa § 35 zákona č. 186/2009 Z.z.)
          </div>
          <div className="text-base font-bold my-2">
            Požiadavky a potreby vyslovené Klientom / Partnerom
          </div>
          <div className="flex">
            <div className="w-1/2 mb-4 pr-2 inline-block">
              <DocumentInput
                document={document}
                setDocument={setDocument}
                name="ddsClientEntryRequests"
                label="Klient"
                disabled={false}
                error={ctx.getFieldError("ddsClientEntryRequests")?.message}
                onChange={(e) =>
                  ctx.resetFieldsErrors([
                    "ddsClientEntryRequests",
                    "ddsPartnerEntryRequests",
                  ])
                }
              />
            </div>
            <div className="w-1/2 mb-4">
              <DocumentInput
                document={document}
                setDocument={setDocument}
                name="ddsPartnerEntryRequests"
                label="Partner"
                disabled={false}
                error={ctx.getFieldError("ddsPartnerEntryRequests")?.message}
                onChange={(e) =>
                  ctx.resetFieldsErrors([
                    "ddsClientEntryRequests",
                    "ddsPartnerEntryRequests",
                  ])
                }
              />
            </div>
          </div>
        </div>
        {/* DDS Start */}
        <div>
          <div className="flex flex-col mb-4">
            <h3 className="mb-2 font-bold">Znalosti a skúsenosti s DDS</h3>
            <div className="w-1/4">
              <ClientPartnerRadio
                name="ddsExperiences"
                document={document}
                setDocument={setDocument}
                client={{
                  hasError: !!ctx.getFieldError("ddsExperiencesClient"),
                  errorMsg: ctx.getFieldError("ddsExperiencesClient")?.message,
                  onChange: (e) =>
                    ctx.resetFieldsErrors([
                      "ddsExperiencesClient",
                      "ddsExperiencesPartner",
                    ]),
                }}
                partner={{
                  hasError: !!ctx.getFieldError("ddsExperiencesPartner"),
                  errorMsg: ctx.getFieldError("ddsExperiencesPartner")?.message,
                  onChange: (e) =>
                    ctx.resetFieldsErrors([
                      "ddsExperiencesClient",
                      "ddsExperiencesPartner",
                    ]),
                }}
              />
            </div>
          </div>

          <div className="grid pt-2">
            <div className="font-bold">Klient:</div>
            <div className="grid grid-cols-3 gap-2">
              <div>
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="ddsClientEstimateSavingTime"
                >
                  Predpokladaný čas sporenia
                </label>
                <input
                  className={`inline-block appearance-none border ${
                    ctx.getFieldError("ddsSavings") ? "border-red-400" : ""
                  } rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                  id="ddsClientEstimateSavingTime"
                  type="text"
                  name="ddsClientEstimateSavingTime"
                  placeholder=""
                  value={getValue(document, "ddsClientEstimateSavingTime")}
                  onChange={(e) => {
                    handleInputChange(
                      document,
                      setDocument,
                      "ddsClientEstimateSavingTime",
                      e
                    );
                    ctx.resetFieldError("ddsSavings");
                  }}
                  data-testid="/documents/zuz/card-02/input-ddsClientEstimateSavingTime"
                />
                {ctx.getFieldError("ddsSavings") ? (
                  <p className="text-red-400">
                    {ctx.getFieldError("ddsSavings")?.message}
                  </p>
                ) : null}
              </div>
              <div>
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="ddsClientDue"
                >
                  Výška príspevku klienta*
                </label>
                <input
                  className={`inline-block appearance-none border ${
                    ctx.getFieldError("ddsSavings") ? "border-red-400" : ""
                  } rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                  id="ddsClientDue"
                  type="text"
                  name="ddsClientDue"
                  placeholder=""
                  value={getValue(document, "ddsClientDue")}
                  onChange={(e) => {
                    handleInputChange(document, setDocument, "ddsClientDue", e);
                    ctx.resetFieldError("ddsSavings");
                  }}
                  data-testid="/documents/zuz/card-02/input-ddsClientDue"
                />
                {ctx.getFieldError("ddsSavings") ? (
                  <p className="text-red-400">
                    {ctx.getFieldError("ddsSavings")?.message}
                  </p>
                ) : null}
              </div>
              <div>
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="ddsClientEmployerDue"
                >
                  Výška príspevku zamestnávateľa*
                </label>
                <input
                  className={`inline-block appearance-none border ${
                    ctx.getFieldError("ddsSavings") ? "border-red-400" : ""
                  } rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                  id="ddsClientEmployerDue"
                  type="text"
                  name="ddsClientEmployerDue"
                  placeholder=""
                  value={getValue(document, "ddsClientEmployerDue")}
                  onChange={(e) => {
                    handleInputChange(
                      document,
                      setDocument,
                      "ddsClientEmployerDue",
                      e
                    );
                    ctx.resetFieldError("ddsSavings");
                  }}
                  data-testid="/documents/zuz/card-02/input-ddsClientEmployerDue"
                />
                {ctx.getFieldError("ddsSavings") ? (
                  <p className="text-red-400">
                    {ctx.getFieldError("ddsSavings")?.message}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
          <div className="grid pt-2">
            <div className="font-bold">Partner:</div>
            <div className="grid grid-cols-3 gap-2">
              <div>
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="ddsPartnerEstimateSavingTime"
                >
                  Predpokladaný čas sporenia
                </label>
                <input
                  className={`inline-block appearance-none border ${
                    ctx.getFieldError("ddsSavings") ? "border-red-400" : ""
                  } rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                  id="ddsPartnerEstimateSavingTime"
                  type="text"
                  name="ddsPartnerEstimateSavingTime"
                  placeholder=""
                  value={getValue(document, "ddsPartnerEstimateSavingTime")}
                  onChange={(e) => {
                    handleInputChange(
                      document,
                      setDocument,
                      "ddsPartnerEstimateSavingTime",
                      e
                    );
                    ctx.resetFieldError("ddsSavings");
                  }}
                  data-testid="/documents/zuz/card-02/input-ddsPartnerEstimateSavingTime"
                />
                {ctx.getFieldError("ddsSavings") ? (
                  <p className="text-red-400">
                    {ctx.getFieldError("ddsSavings")?.message}
                  </p>
                ) : null}
              </div>
              <div>
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="ddsPartnerDue"
                >
                  Výška príspevku partnera*
                </label>
                <input
                  className={`inline-block appearance-none border ${
                    ctx.getFieldError("ddsSavings") ? "border-red-400" : ""
                  } rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                  id="ddsPartnerDue"
                  type="text"
                  name="ddsPartnerDue"
                  placeholder=""
                  value={getValue(document, "ddsPartnerDue")}
                  onChange={(e) => {
                    handleInputChange(
                      document,
                      setDocument,
                      "ddsPartnerDue",
                      e
                    );
                    ctx.resetFieldError("ddsSavings");
                  }}
                  data-testid="/documents/zuz/card-02/input-ddsPartnerDue"
                />
                {ctx.getFieldError("ddsSavings") ? (
                  <p className="text-red-400">
                    {ctx.getFieldError("ddsSavings")?.message}
                  </p>
                ) : null}
              </div>
              <div>
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="ddsPartnerEmployerDue"
                >
                  Výška príspevku zamestnávateľa*
                </label>
                <input
                  className={`inline-block appearance-none border ${
                    ctx.getFieldError("ddsSavings") ? "border-red-400" : ""
                  } rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                  id="ddsPartnerEmployerDue"
                  type="text"
                  name="ddsPartnerEmployerDue"
                  placeholder=""
                  value={getValue(document, "ddsPartnerEmployerDue")}
                  onChange={(e) => {
                    handleInputChange(
                      document,
                      setDocument,
                      "ddsPartnerEmployerDue",
                      e
                    );
                    ctx.resetFieldError("ddsSavings");
                  }}
                  data-testid="/documents/zuz/card-02/input-ddsPartnerEmployerDue"
                />
                {ctx.getFieldError("ddsSavings") ? (
                  <p className="text-red-400">
                    {ctx.getFieldError("ddsSavings")?.message}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
          <p className="py-4 text-sm">
            Pozn.: Zamestnanec v 3. alebo vo 4. rizikovej kategórii alebo
            tanečný umelec alebo hráč na dychový nástroj majú povinnú účasť zo
            strany zamestnávateľa na doplnkovom dôchodkovom sporení.
          </p>
          <p> * EUR alebo % z vymeriavacieho základu</p>
          {/* <div className="flex flex-grow w-full"> */}
          <div className="w-full">
            Mám záujem o uplatňovanie daňovej úľavy
            <div className="inline-block px-5">
              <ClientPartnerCheckbox
                document={document}
                setDocument={setDocument}
                name="DDSWantTaxRelief"
                text=""
              />
            </div>
          </div>
          <p className="py-3 font-bold">
            Pri sprostredkovaní finančných služieb v sektore DDS je potrebné
            vyplniť s Klientom/Partnerom Investičný-rizikový dotazník.
          </p>
          {/* </div> */}
        </div>
        {/* DDS End */}
      </div>
    </Card>
  );
}

export default Page02DDSCard;
