import { baseSplitApi as api } from "./baseApi";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAdminUserInfo: build.query<
      GetAdminUserInfoApiResponse,
      GetAdminUserInfoApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/oauth2/admin/agent-info`,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    updateRoleUserStatus: build.mutation<
      UpdateRoleUserStatusApiResponse,
      UpdateRoleUserStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/role-users/${queryArg.id}/update-status`,
        method: "PATCH",
        body: queryArg.roleUserStatusUpdateRequest,
      }),
    }),
    getUsers: build.query<GetUsersApiResponse, GetUsersApiArg>({
      query: (queryArg) => ({
        url: `/v1/users/`,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort: queryArg.sort,
          keyword: queryArg.keyword,
          userStates: queryArg.userStates,
        },
      }),
    }),
    getUserById: build.query<GetUserByIdApiResponse, GetUserByIdApiArg>({
      query: (queryArg) => ({
        url: `/v1/users/${queryArg.id}`,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    getAllRoles: build.query<GetAllRolesApiResponse, GetAllRolesApiArg>({
      query: (queryArg) => ({ url: `/v1/users/${queryArg.id}/all-roles` }),
    }),
    getRoles: build.query<GetRolesApiResponse, GetRolesApiArg>({
      query: (queryArg) => ({ url: `/v1/users/${queryArg.id}/roles` }),
    }),
    updateUserStatus: build.mutation<
      UpdateUserStatusApiResponse,
      UpdateUserStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/users/${queryArg.id}/update-status`,
        method: "PATCH",
        body: queryArg.userStatusUpdate,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as spfApi };
export type GetAdminUserInfoApiResponse =
  /** status 200 OK */ OAuth2UserInfoResponse;
export type GetAdminUserInfoApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
};
export type UpdateRoleUserStatusApiResponse =
  /** status 200 Resource was found */ SuccessResponse200;
export type UpdateRoleUserStatusApiArg = {
  /** uuid */
  id: string;
  /** the new status */
  roleUserStatusUpdateRequest: RoleUserStatusUpdateRequest;
};
export type GetUsersApiResponse = /** status 200 OK */ UserPage;
export type GetUsersApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** page */
  page?: number;
  /** size */
  size?: number;
  /** Format is sort=email:desc,display_name:asc. Supported keys: created_at, display_name, email. */
  sort?: string[];
  /** keyword to search for in users */
  keyword?: string;
  /** allowed states */
  userStates?: string[];
};
export type GetUserByIdApiResponse = /** status 200 OK */ User;
export type GetUserByIdApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** uuid */
  id: string;
};
export type GetAllRolesApiResponse =
  /** status 200 OK */ RoleUserListResponse[];
export type GetAllRolesApiArg = {
  /** uuid */
  id: string;
};
export type GetRolesApiResponse = /** status 200 OK */ RoleUserListResponse[];
export type GetRolesApiArg = {
  /** uuid */
  id: string;
};
export type UpdateUserStatusApiResponse =
  /** status 200 Resource was found */ SuccessResponse200;
export type UpdateUserStatusApiArg = {
  /** uuid */
  id: string;
  /** the new status */
  userStatusUpdate: UserStatusUpdate;
};
export type OAuth2User = {
  email?: string;
  evidenceNumber?: string;
  firstName?: string;
  licenseNumber?: string;
  phone?: string;
  surname?: string;
};
export type OAuth2UserInfoResponse = {
  User?: OAuth2User;
};
export type ValidationError = {
  code?: string;
  field?: string;
  message?: string;
};
export type ErrorResponse400 = {
  code?: number;
  data?: ValidationError[];
  message?: string;
};
export type ErrorResponse404 = {
  code?: number;
  data?: {
    [key: string]: string;
  }[];
  message?: string;
};
export type SuccessResponse200 = {
  id?: string;
  status?: string;
};
export type ErrorResponse401 = {
  code?: number;
  data?: {
    [key: string]: string;
  }[];
  message?: string;
};
export type ErrorResponse403 = {
  code?: number;
  data?: {
    [key: string]: string;
  }[];
  message?: string;
};
export type RoleUserStatusKey = "rus-active" | "rus-deactivated";
export type RoleUserStatusUpdateRequest = {
  statusKey?: RoleUserStatusKey;
};
export type UserStatusKey = "us-active" | "us-deactivated" | "us-resigned";
export type UserFilter = {
  keyword?: string;
  userStates?: UserStatusKey[];
};
export type OriginSystemKey = "uo-portal" | "uo-icm";
export type OriginSystem = {
  key?: OriginSystemKey;
  name?: string;
};
export type UserStatus = {
  key?: UserStatusKey;
  name?: string;
};
export type User = {
  createdAt?: string;
  displayName: string;
  email: string;
  id?: string;
  originSystem?: OriginSystem;
  status?: UserStatus;
  updatedAt?: string;
};
export type SortFieldDirection = "asc" | "desc";
export type SortFieldKey =
  | "createdAt"
  | "updatedAt"
  | "startsAt"
  | "validTo"
  | "validUntil"
  | "firstname"
  | "lastname"
  | "name"
  | "displayName"
  | "evidenceNumber"
  | "email"
  | "pin"
  | "ico"
  | "advisorEmail"
  | "customerEmail"
  | "mentorEmail"
  | "phoneNumber"
  | "displayId"
  | "street"
  | "city"
  | "zip"
  | "id"
  | "owner"
  | "type"
  | "status"
  | "customer"
  | "advisor"
  | "updatedBy";
export type SortField = {
  direction?: SortFieldDirection;
  field?: SortFieldKey;
};
export type UserPage = {
  count?: number;
  filter?: UserFilter;
  items?: User[];
  last?: boolean;
  page?: number;
  size?: number;
  sort?: SortField[];
};
export type RoleKey =
  | "rk-assistant"
  | "rk-advisor"
  | "rk-central"
  | "rk-system-admin";
export type Role = {
  key?: RoleKey;
  name?: string;
};
export type RoleUserListResponse = {
  advisorBusinessName?: string;
  advisorDisplayName?: string;
  advisorId?: string;
  id?: string;
  role?: Role;
  status?: RoleUserStatusKey;
  userId?: string;
};
export type ErrorResponse500 = {
  code?: number;
  data?: {
    [key: string]: string;
  }[];
  message?: string;
};
export type UserStatusUpdate = {
  statusKey?: UserStatusKey;
};
export const {
  useGetAdminUserInfoQuery,
  useLazyGetAdminUserInfoQuery,
  useUpdateRoleUserStatusMutation,
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useGetUserByIdQuery,
  useLazyGetUserByIdQuery,
  useGetAllRolesQuery,
  useLazyGetAllRolesQuery,
  useGetRolesQuery,
  useLazyGetRolesQuery,
  useUpdateUserStatusMutation,
} = injectedRtkApi;
