import React, { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import Button from "../buttons/Button";
import CancelButton from "../buttons/CancelButton";

interface Properties {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  callback: () => void;
}

function FormIsDirtyModal({
  show,
  setShow,
  callback,
}: Properties): JSX.Element {
  const { t } = useTranslation();
  const [isInProgress, setIsInProgress] = useState(false);

  const handleCloseClick = () => {
    if (show) {
      setShow(false);
    }
  };

  const handleGetBack = () => {
    setShow(false);
    callback();
  };

  const checkKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (!show) return;
      if (!isInProgress) {
        if (e.key === "Escape") {
          handleCloseClick();
        }
        if (e.key === "Enter") {
          handleGetBack();
        }
      }
    },
    [show]
  );

  useEffect(() => {
    window.addEventListener("keydown", checkKeyPress);
    return () => {
      window.removeEventListener("keydown", checkKeyPress);
    };
  }, [checkKeyPress]);

  const handleClose = () => {
    setIsInProgress(false);
    setShow(false);
  };

  if (!show) return <div />;

  return (
    <>
      {show && (
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto mx-auto max-w-3xl">
            {/* content */}
            <div className="rounded-lg shadow-xl relative flex flex-col bg-white w-96">
              {/* header */}
              <div className="flex flex-cols px-4 py-4" />
              {/* body */}
              <div className="px-4 py-4 my-4 text-blueGray-500">
                <span>{t("formIsDirtyModal.question")}</span>
              </div>
              <div className="border-gray-300 text-center">
                <div className="px-4 py-4">
                  <Button className="mr-1" onClick={handleGetBack}>
                    <span>{t("button.yes")}</span>
                  </Button>
                  <CancelButton onClick={handleClose} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="opacity-25 fixed inset-0 z-40 bg-black" />
    </>
  );
}

FormIsDirtyModal.defaultProps = {};

export default FormIsDirtyModal;
