enum AdvisorPositionType {
  SP = "SP",
  KR = "KR",
  RR = "RR",
  OR = "OR",
  M = "M",
  S3 = "S3",
  S2 = "S2",
  S1 = "S1",
  SK = "SK",
  K2 = "K2",
  K1 = "K1",
  T = "T",
  BP = "BP",
  BP90 = "BP90",
  BP85 = "BP85",
  BP70 = "BP70",
  BP00 = "BP00",
  FEL1 = "FEL1",
  FEL2 = "FEL2",
  FEL3 = "FEL3",
  FEL4 = "FEL4",
}

export default AdvisorPositionType;
