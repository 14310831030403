enum ZUZDocumentSection {
  SIGNATURE = "signatureType",
  AGENT_CLINET_PARTNER = "acp",
  DDS = "ast-sup",
  SDS = "ast-old",
  INSURANCE = "ast-ins",
  INCOME = "ast-acc",
  CREDIT = "ast-len",
  CAPITAL_MARKET = "ast-cap",
  INVEST_QUESTIONNAIRE = "investQuestionnaire",
  SOLUTIONS = "solutions",
  CONSENT = "consent",
}

export default ZUZDocumentSection;
