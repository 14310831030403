import ClientPartnerCheckboxOptions from "../enums/ClientPartnerCheckboxOptions";
import { ClientPartnerRadioOptions } from "../enums/ClientPartnerRadio";
import { InvestmentCustomerType } from "../enums/InvestmentCustomerType";
import { InvestmentType } from "../enums/InvestmentType";
import ZUZDocumentSection from "../enums/ZUZDocumentSection";
import {
  DocumentPassedToCustomer,
  EsgQ1Checkboxes,
  EsgQ2Checkboxes,
  EsgQ3Checkboxes,
  PersonType,
  PFASector,
  PrivacyPolicy,
} from "./CheckboxItemField";
import { ClientType, PartnerType } from "./CustomerSkillType";

type TableValues = (string | number)[][] | never[] | never;

export type DocumentContent = {
  disabledSections: ZUZDocumentSection[];
  showInvestmentSurvey: boolean;

  // Advisor data
  pfaID: string;
  pfaName: string;
  pfaCompany: string;
  pfaAddress: string;
  pfaBusinessPlace: string;
  pfaNBS: string;
  pfaSPFId: string;
  pfaICO: string;
  pfaSector: PFASector[];
  pfaEmail: string;

  // Client data
  clientID: string;
  clientName: string;
  clientIdNumber: string;
  clientBirthdate: string;
  clientHomeAddress: string;
  clientBusinessAddress: string;
  // @deprecated
  clientPostAddress: string;
  clientEmail: string;
  clientPhone: string;
  clientCompany: string;
  clientBusinessAdress: string;
  clientBusinessId: string;
  clientBusinessFunction: string;
  clientIdType: string; // TODO: remove after old documents are closed
  clientIdDocumentType: string;
  clientPersonalDocumentValue: string;
  clientIdDisplayValue: string;
  clientIdValidUntil: string;
  clientState: string;
  clientOccupation: string;
  clientType: ClientType[];
  clientPersonType: PersonType[];

  // Partner data
  partnerID: string;
  partnerName: string;
  partnerIdNumber: string;
  partnerBirthdate: string;
  partnerHomeAddress: string;
  partnerPostAddress: string;
  partnerEmail: string;
  partnerPhone: string;
  partnerIdType: string; // TODO: remove after old documents are closed
  partnerIdDocumentType: string;
  partnerPersonalDocumentValue: string;
  partnerIdDisplayValue: string;
  partnerIdValidUntil: string;
  partnerState: string;
  partnerType: PartnerType[];

  // Mentor data
  mentorID: string;
  mentorName: string;
  mentorAddress: string;
  mentorBusinessID: string;
  mentorNBS: string;
  mentorSPF: string;

  // Client Checkboxes
  clientPFAApproved: ClientPartnerCheckboxOptions;
  clientInformationAcknowledge: ClientPartnerCheckboxOptions;
  clientPFANotAffected: ClientPartnerCheckboxOptions;
  clientRiskAcknowledge: ClientPartnerCheckboxOptions;
  clientDataApproved: ClientPartnerCheckboxOptions;
  clientOnHisOwnName: ClientPartnerCheckboxOptions;
  clientOnHisOwnAccount: ClientPartnerCheckboxOptions;
  clientWithHisOwnMoney: ClientPartnerCheckboxOptions;
  clientContractUnder15000: ClientPartnerCheckboxOptions;
  clientFinalUserBenefits: ClientPartnerCheckboxOptions;
  clientNotPolitic: ClientPartnerCheckboxOptions;
  clientDisagreeEntryInformation: ClientPartnerCheckboxOptions;
  clientDisagreeEntryInformationAndAskForContract: ClientPartnerCheckboxOptions;
  clientAgreedPFANotResponsible: ClientPartnerCheckboxOptions;
  sourceIncomeFromActivity: ClientPartnerCheckboxOptions;
  sourceIncomeFromLowReserve: ClientPartnerCheckboxOptions;
  sourceIncomeFromHighReserve: ClientPartnerCheckboxOptions;
  sourceIncomeOther: ClientPartnerCheckboxOptions;
  otherSourceIncomeValue: string;
  clientDebtLoansDebt: string;
  partnerDebtLoansDebt: string;
  clientRepaymentSellDebt: string;
  partnerRepaymentSellDebt: string;
  clientLeasingDebt: string;
  partnerLeasingDebt: string;
  clientOtherDebt: string;
  partnerOtherDebt: string;
  ddsExperiences: ClientPartnerRadioOptions;
  ddsClientEntryRequests: string;
  ddsPartnerEntryRequests: string;
  ddsClientEstimateSavingTime: string;
  ddsClientDue: string;
  ddsClientEmployerDue: string;
  ddsPartnerSector: string;
  ddsPartnerEstimateSavingTime: string;
  ddsPartnerDue: string;
  ddsPartnerEmployerDue: string;
  DDSWantTaxRelief: ClientPartnerCheckboxOptions;
  sdsExperiences: ClientPartnerRadioOptions;
  sdsClientEntryRequests: string;
  sdsPartnerEntryRequests: string;
  SDSWantTaxRelief: ClientPartnerCheckboxOptions;
  SDSWantVoluntaryDues: ClientPartnerCheckboxOptions;
  sdsVoluntaryDuesAmount: string;
  insuranceExperiences: ClientPartnerRadioOptions;
  insuranceClientEntryRequests: string;
  insurancePartnerEntryRequests: string;
  insuranceTypeRisk: ClientPartnerCheckboxOptions;
  insuranceTypeCapital: ClientPartnerCheckboxOptions;
  insuranceTypeInvestment: ClientPartnerCheckboxOptions;
  insuranceTypeAccident: ClientPartnerCheckboxOptions;
  insuranceFamilySecuring: ClientPartnerCheckboxOptions;
  insuranceIndividualSecuring: ClientPartnerCheckboxOptions;
  insurancePensionSecuring: ClientPartnerCheckboxOptions;
  insuranceChildrenSecuring: ClientPartnerCheckboxOptions;
  insuranceCreditSecuring: ClientPartnerCheckboxOptions;
  insuranceUpdatedPaymentCalendar: ClientPartnerCheckboxOptions;
  insurancePurposeOther: ClientPartnerCheckboxOptions;
  insurancePurposeOtherValue: string;
  insuranceOther: ClientPartnerCheckboxOptions;
  insuranceOtherValue: string;
  insurancePaymentOnce: ClientPartnerCheckboxOptions;
  insurancePaymentPeriodically: ClientPartnerCheckboxOptions;
  insuranceRiskLifeLost: ClientPartnerCheckboxOptions;
  insuranceRiskInvalidity: ClientPartnerCheckboxOptions;
  insuranceRiskInsurancePaymentFree: ClientPartnerCheckboxOptions;
  insuranceRiskWorkIncapacity: ClientPartnerCheckboxOptions;
  insuranceRiskAccidentDeath: ClientPartnerCheckboxOptions;
  insuranceRiskAccidentLastingConsequences: ClientPartnerCheckboxOptions;
  insuranceRiskAccidentDailyCompensation: ClientPartnerCheckboxOptions;
  insuranceRiskHospitalization: ClientPartnerCheckboxOptions;
  insuranceRiskCriticalIllnesses: ClientPartnerCheckboxOptions;
  insuranceRiskSurgery: ClientPartnerCheckboxOptions;
  insuranceRiskOther: ClientPartnerCheckboxOptions;
  insuranceRiskOtherValue: string;
  insurancePropertyHousehold: ClientPartnerCheckboxOptions;
  insurancePropertyRealty: ClientPartnerCheckboxOptions;
  insuranceVehicleAccident: ClientPartnerCheckboxOptions;
  insurancePropertyOther: ClientPartnerCheckboxOptions;
  insurancePropertyOtherValue: string;
  insuranceGeneralLiability: ClientPartnerCheckboxOptions;
  insurancePZP: ClientPartnerCheckboxOptions;
  insuranceLiabilityProfession: ClientPartnerCheckboxOptions;
  insuranceLiabilityOther: ClientPartnerCheckboxOptions;
  insuranceLiabilityOtherValue: string;
  insuranceLegalSafetyTransport: ClientPartnerCheckboxOptions;
  insuranceLegalSafetyOther: ClientPartnerCheckboxOptions;
  insuranceLegalSafetyOtherValue: string;
  insuranceTravel: ClientPartnerCheckboxOptions;
  financeDepositClientEntryRequests: string;
  financeDepositPartnerEntryRequests: string;
  bankDepositExperiences: ClientPartnerRadioOptions;
  bankDepositNoExperiences: ClientPartnerCheckboxOptions;
  bankDepositMediumExperiences: ClientPartnerCheckboxOptions;
  bankDepositFullExperiences: ClientPartnerCheckboxOptions;
  financeOneTimeDeposit: string;
  financePeriodicDeposit: string;
  financeOneTimeDepositExpectedDuration: string;
  financePeriodicDepositExpectedDuration: string;
  financeEarlyWithdrawalPresumption: ClientPartnerCheckboxOptions;
  financeDepositApplyStateAid: ClientPartnerCheckboxOptions;
  financeDepositConstructionLoanAssumption: ClientPartnerCheckboxOptions;
  financeDepositIntermediateLoanAssumption: ClientPartnerCheckboxOptions;
  financeLoansClientEntryRequests: string;
  financeLoansPartnerEntryRequests: string;
  loanExperiences: ClientPartnerRadioOptions;
  loanNoExperiences: ClientPartnerCheckboxOptions;
  loanMediumExperiences: ClientPartnerCheckboxOptions;
  loanFullExperiences: ClientPartnerCheckboxOptions;
  loanPurposeRealEstate: ClientPartnerCheckboxOptions;
  loanPurposeMovableProperty: ClientPartnerCheckboxOptions;
  loanPurposeRefinanceOtherLoan: ClientPartnerCheckboxOptions;
  loanRefinanceOtherLoanAmountClient: string;
  loanRefinanceOtherLoanAmountPartner: string;
  loanPurposeOther: ClientPartnerCheckboxOptions;
  loanPurposeOtherAmountClient: string;
  loanPurposeOtherAmountPartner: string;
  loanSecuringRealEstate: ClientPartnerCheckboxOptions;
  loanSecuringMovableProperty: ClientPartnerCheckboxOptions;
  loanSecuringMovablePropertyAmountClient: string;
  loanSecuringMovablePropertyAmountPartner: string;
  loanSecuringLiability: ClientPartnerCheckboxOptions;
  loanSecuringCodebtor: ClientPartnerCheckboxOptions;
  loanSecuringDebtOtherType: ClientPartnerCheckboxOptions;
  loanSecuringOtherTypeClientValue: string;
  loanSecuringOtherTypePartnerValue: string;
  loanAmountOfCreditRequired: string;
  loanExpectedMaturity: string;
  loanMaximumMonthlyPaymentAmount: string;
  loanEarlyRepaymentRequired: ClientPartnerCheckboxOptions;
  loanInsuranceRequired: ClientPartnerCheckboxOptions;
  loanOtherRequirements: string;
  capitalMarketClientEntryRequests: string;
  capitalMarketPartnerEntryRequests: string;

  // Investment
  questionaire: Questionare;
  questionaryPoints: QuestionaryPoints;

  tableInvestmentOrders: TableValues;
  investingRiskSurveyResult: TableValues;
  singleInvestmentPlanResult: TableValues;
  periodicallyInvestmentPlanResult: TableValues;
  investingSurveyChosenSolution: TableValues;
  pfaRecomendationsTable: TableValues;
  pfaRecomendationsNotes: string;
  capitalInvestmentOrdersDate: Date | string | null;

  declarationsOfSuitabilityChecks: string | undefined;
  documentsPassedtoCustomers: DocumentPassedToCustomer[];
  otherDocumentsPassedToCustomersValue: string;

  // Privacy
  clientPrivacyPolicy: PrivacyPolicy[];
  partnerPrivacyPolicy: PrivacyPolicy[];

  // Signature
  signatureType: string;

  // esg
  esgSocialPercent: string;
  esgEnviromentPercent: string;
  esgCorporatePercent: string;
  esgNoPreferenciesPercent: string;
  esgAllPreferenciesPercent: string;
  esgAtLeastOnePreferenciesPercent: string;
  esgQ1Checkboxes: EsgQ1Checkboxes[];
  esgQ2Checkboxes: EsgQ2Checkboxes[];
  esgQ3Checkboxes: EsgQ3Checkboxes[];
};

export type DocumentContentTypeCheckboxArray =
  | "pfaSector"
  | "clientType"
  | "clientPersonType"
  | "partnerType"
  | "documentsPassedtoCustomers"
  | "clientPrivacyPolicy"
  | "partnerPrivacyPolicy"
  | "esgQ1Checkboxes"
  | "esgQ2Checkboxes"
  | "esgQ3Checkboxes";

export type DocumentContentTypeStrings =
  | "pfaName"
  | "pfaCompany"
  | "pfaAddress"
  | "pfaBusinessPlace"
  | "pfaNBS"
  | "pfaSPFId"
  | "pfaICO"
  | "clientID"
  | "clientName"
  | "clientIdNumber"
  | "clientBirthdate"
  | "clientHomeAddress"
  | "clientBusinessAddress"
  | "clientPostAddress"
  | "clientEmail"
  | "clientPhone"
  | "clientCompany"
  | "clientBusinessAdress"
  | "clientBusinessId"
  | "clientBusinessFunction"
  | "clientIdDisplayValue"
  | "clientPersonalDocumentValue"
  | "clientIdValidUntil"
  | "clientState"
  | "clientOccupation"
  | "partnerID"
  | "partnerIdDisplayValue"
  | "partnerPersonalDocumentValue"
  | "partnerName"
  | "partnerIdNumber"
  | "partnerBirthdate"
  | "partnerHomeAddress"
  | "partnerPostAddress"
  | "partnerEmail"
  | "partnerPhone"
  | "partnerIdType"
  | "partnerIdValidUntil"
  | "partnerState"
  | "mentorID"
  | "mentorName"
  | "mentorAddress"
  | "mentorBusinessID"
  | "mentorNBS"
  | "mentorSPF"
  | "otherSourceIncomeValue"
  | "clientDebtLoansDebt"
  | "partnerDebtLoansDebt"
  | "clientRepaymentSellDebt"
  | "partnerRepaymentSellDebt"
  | "clientLeasingDebt"
  | "partnerLeasingDebt"
  | "clientOtherDebt"
  | "partnerOtherDebt"
  | "ddsClientEntryRequests"
  | "ddsPartnerEntryRequests"
  | "ddsClientEstimateSavingTime"
  | "ddsClientDue"
  | "ddsClientEmployerDue"
  | "ddsPartnerSector"
  | "ddsPartnerEstimateSavingTime"
  | "ddsPartnerDue"
  | "ddsPartnerEmployerDue"
  | "sdsClientEntryRequests"
  | "sdsPartnerEntryRequests"
  | "sdsVoluntaryDuesAmount"
  | "insuranceClientEntryRequests"
  | "insurancePartnerEntryRequests"
  | "insurancePurposeOtherValue"
  | "insuranceOtherValue"
  | "insuranceRiskOtherValue"
  | "insurancePropertyOtherValue"
  | "insuranceLiabilityOtherValue"
  | "insuranceLegalSafetyOtherValue"
  | "financeDepositClientEntryRequests"
  | "financeDepositPartnerEntryRequests"
  | "financeOneTimeDeposit"
  | "financePeriodicDeposit"
  | "financeOneTimeDepositExpectedDuration"
  | "financePeriodicDepositExpectedDuration"
  | "financeLoansClientEntryRequests"
  | "financeLoansPartnerEntryRequests"
  | "loanRefinanceOtherLoanAmountClient"
  | "loanRefinanceOtherLoanAmountPartner"
  | "loanPurposeOtherAmountClient"
  | "loanPurposeOtherAmountPartner"
  | "loanSecuringMovablePropertyAmountClient"
  | "loanSecuringMovablePropertyAmountPartner"
  | "loanSecuringOtherTypeClientValue"
  | "loanSecuringOtherTypePartnerValue"
  | "loanAmountOfCreditRequired"
  | "loanExpectedMaturity"
  | "loanMaximumMonthlyPaymentAmount"
  | "loanOtherRequirements"
  | "capitalMarketClientEntryRequests"
  | "capitalMarketPartnerEntryRequests"
  | "pfaRecomendationsNotes"
  | "otherDocumentsPassedToCustomersValue"
  | "esgEnviromentPercent"
  | "esgSocialPercent"
  | "esgCorporatePercent"
  | "esgNoPreferenciesPercent"
  | "esgAllPreferenciesPercent"
  | "esgAtLeastOnePreferenciesPercent"
  | "signatureType"
  | "declarationsOfSuitabilityChecks";

export type DocumentContentTypeCheckboxes =
  | "insurancePurposeOther"
  | "DDSWantTaxRelief"
  | "clientPFAApproved"
  | "clientInformationAcknowledge"
  | "clientPFANotAffected"
  | "clientRiskAcknowledge"
  | "clientDataApproved"
  | "clientOnHisOwnName"
  | "clientOnHisOwnAccount"
  | "clientWithHisOwnMoney"
  | "clientContractUnder15000"
  | "clientFinalUserBenefits"
  | "clientNotPolitic"
  | "clientDisagreeEntryInformation"
  | "clientDisagreeEntryInformationAndAskForContract"
  | "clientAgreedPFANotResponsible"
  | "sourceIncomeFromActivity"
  | "sourceIncomeFromLowReserve"
  | "sourceIncomeFromHighReserve"
  | "sourceIncomeOther"
  | "insuranceTypeRisk"
  | "insuranceTypeCapital"
  | "insuranceTypeInvestment"
  | "insuranceTypeAccident"
  | "insuranceFamilySecuring"
  | "insuranceIndividualSecuring"
  | "insurancePensionSecuring"
  | "insuranceChildrenSecuring"
  | "insuranceCreditSecuring"
  | "insuranceUpdatedPaymentCalendar"
  | "insurancePaymentOnce"
  | "insurancePaymentPeriodically"
  | "insuranceRiskLifeLost"
  | "insuranceRiskInvalidity"
  | "insuranceRiskInsurancePaymentFree"
  | "insuranceRiskWorkIncapacity"
  | "insuranceRiskAccidentDeath"
  | "insuranceRiskAccidentLastingConsequences"
  | "insuranceRiskAccidentDailyCompensation"
  | "insuranceRiskHospitalization"
  | "insuranceRiskCriticalIllnesses"
  | "insuranceRiskSurgery"
  | "insuranceRiskOther"
  | "insurancePropertyHousehold"
  | "insurancePropertyRealty"
  | "insuranceVehicleAccident"
  | "insurancePropertyOther"
  | "insuranceGeneralLiability"
  | "insurancePZP"
  | "insuranceLiabilityProfession"
  | "insuranceLiabilityOther"
  | "insuranceLegalSafetyTransport"
  | "insuranceLegalSafetyOther"
  | "insuranceTravel"
  | "insuranceOther"
  | "SDSWantVoluntaryDues"
  | "bankDepositNoExperiences"
  | "bankDepositMediumExperiences"
  | "bankDepositFullExperiences"
  | "financeEarlyWithdrawalPresumption"
  | "financeDepositApplyStateAid"
  | "financeDepositConstructionLoanAssumption"
  | "financeDepositIntermediateLoanAssumption"
  | "loanNoExperiences"
  | "loanMediumExperiences"
  | "loanFullExperiences"
  | "loanPurposeRealEstate"
  | "loanPurposeMovableProperty"
  | "loanPurposeRefinanceOtherLoan"
  | "loanPurposeOther"
  | "loanSecuringRealEstate"
  | "loanSecuringMovableProperty"
  | "loanSecuringLiability"
  | "loanSecuringCodebtor"
  | "loanSecuringDebtOtherType"
  | "loanEarlyRepaymentRequired"
  | "loanInsuranceRequired";

export type DocumentContentTypeRadios =
  | "ddsExperiences"
  | "insuranceExperiences"
  | "sdsExperiences"
  | "loanExperiences"
  | "bankDepositExperiences";

export type DocumentContentTypeTables =
  | "pfaRecomendationsTable"
  | "investingRiskSurveyResult"
  | "singleInvestmentPlanResult"
  | "periodicallyInvestmentPlanResult"
  | "investingSurveyChosenSolution"
  | "tableInvestmentOrders";

export type DocumentContentTypeDates = "capitalInvestmentOrdersDate";

export type DocumentContentKey = keyof DocumentContent;

export type DocumentContentTypes =
  | string
  | boolean
  | []
  | PFASector[]
  | ClientType[]
  | PersonType[]
  | PartnerType[]
  | ClientPartnerCheckboxOptions
  | DocumentPassedToCustomer[]
  | PrivacyPolicy[]
  | TableValues;

export enum AnswersABCDE {
  ANSWER_A = "A",
  ANSWER_B = "B",
  ANSWER_C = "C",
  ANSWER_D = "D",
  ANSWER_E = "E",
}

export enum FinancialTools {
  BOND = "BOND",
  STOCK = "STOCK",
  MUTUAL_FUND = "MUTUAL_FUND",
  ETF = "ETF",
  DERIVATIVE = "DERIVATIVE",
}

export enum YearsPeriod {
  NONE = "NONE",
  LESS_THAN_3 = "LESS_THAN_3",
  MORE_THAN_3 = "MORE_THAN_3",
}

export enum EsgInterest {
  NO_INTEREST = "NO_INTEREST",
  INTEREST = "INTEREST",
}

export type Answer = {
  answer: AnswersABCDE;
  text: string;
};

export type FinancialToolsFields = {
  [key: string]: YearsPeriod | undefined;
  bond?: YearsPeriod | undefined;
  stock?: YearsPeriod | undefined;
  mutualFund?: YearsPeriod | undefined;
  etf?: YearsPeriod | undefined;
  derivative?: YearsPeriod | undefined;
};

export type AnswerClientPartnerType = {
  client: string | undefined;
  partner: string | undefined;
};

export type KnownFinancialToolsType = {
  client: Array<FinancialTools> | undefined;
  partner: Array<FinancialTools> | undefined;
};

export type FinancialToolsExperienceType = {
  client: FinancialToolsFields | undefined;
  partner: FinancialToolsFields | undefined;
};

export type FinancialToolsExperienceMap = {
  bond: {
    answer: YearsPeriod;
    points: number;
  }[];
  stock: {
    answer: YearsPeriod;
    points: number;
  }[];
  mutualFund: {
    answer: YearsPeriod;
    points: number;
  }[];
  etf: {
    answer: YearsPeriod;
    points: number;
  }[];
  derivate: {
    answer: YearsPeriod;
    points: number;
  }[];
};

export type Questionare = {
  investitionType: AnswerClientPartnerType;
  reactionToLoss: AnswerClientPartnerType;
  knownFinancialTools: KnownFinancialToolsType;
  financialToolsExperience: FinancialToolsExperienceType;
  interestInEsg: EsgInterestType;
  esgPreferences: EsgPreferences;
  sustainabilityRequest: SustainabiltyRequestType;
  investmentIntentionsOnce: InvestmentIntentionOnce[];
  investmentIntentionsRepeated: InvestmentIntentionRepeated[];
};

export type QuestionaryPointsItem = {
  client: number;
  partner: number;
};

export type QuestionaryPoints = {
  investitionType: QuestionaryPointsItem;
  reactionToLoss: { client: number; partner: number };
  financialToolsExperience: QuestionaryPointsItem;
  knownFinancialTools: QuestionaryPointsItem;
  total: QuestionaryPointsItem;
};

export type InvestmentIntention = {
  frontendID: string;
  investmentCustomerType: InvestmentCustomerType | undefined;
  investmentCustomerTypePDF: string;
  investmentType?: InvestmentType;
  investmentPurpose?: AnswersABCDE;
  investmentTimeHorizon?: AnswersABCDE;
  investmentOnceAmount?: AnswersABCDE | undefined;
  investmentRepeatedAmount?: AnswersABCDE | undefined;
  description?: string;
  points?: string;
  investorType?: string;
  achivable?: string; // input from user
};

export type InvestmentIntentionOnce = InvestmentIntention & {
  investmentType: InvestmentType.ONCE;
};

export type InvestmentIntentionRepeated = InvestmentIntention & {
  investmentType: InvestmentType.REPEATED;
};

export type EsgInterestType = {
  client: EsgInterest | undefined;
  partner: EsgInterest | undefined;
};

export type EsgPreference = {
  checked: boolean;
  percentage: string;
};

export type EsgPreferenceFields = {
  environmental?: EsgPreference | undefined;
  social?: EsgPreference | undefined;
  governance?: EsgPreference | undefined;
  noPreference?: EsgPreference | undefined;
};

export type EsgPreferences = {
  client: EsgPreferenceFields | undefined;
  partner: EsgPreferenceFields | undefined;
};

export enum SustainabiltyRequest {
  ATLEAST_ONE = "ATLEAST_ONE",
  ALL_THE_SAME_TIME = "ALL_THE_SAME_TIME",
}

export type SustainabiltyRequestType = {
  client: SustainabiltyRequest | undefined;
  partner: SustainabiltyRequest | undefined;
};
