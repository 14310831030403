import { PermissionRuleTypeKey } from "../api/permissionRule.generated";
import SelectOption from "./SelectOption";

const PermissionRuleTypeOptions: SelectOption<PermissionRuleTypeKey>[] = [
  {
    key: "pr-all",
    name: "Všetci",
  },
  {
    key: "pr-advisor-position",
    name: "Pozícia poradcu",
  },
  {
    key: "pr-role",
    name: "Rola",
  },
  {
    key: "pr-advisor-tree",
    name: "Podstrom poradcu",
  },
  {
    key: "pr-advisor-children",
    name: "Priama linia poradcu",
  },
  {
    key: "pr-user-role",
    name: "Vlastná skupina",
  },
];

export const getNameByKey = (key: string): string => {
  const option = PermissionRuleTypeOptions.find((o) => o.key === key);
  return option ? option.name : "";
};

export default PermissionRuleTypeOptions;
