import React from "react";

import {
  KnownFinancialToolsType,
  FinancialTools,
} from "../../../../models/DocumentContent";

export type CPfield = "client" | "partner";

interface Props {
  name: string;
  question: string;
  answers: KnownFinancialToolsType;
  onChange: (answers: KnownFinancialToolsType) => void;
}

function KnownFinancialTools({ question, answers, name, onChange }: Props) {
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: CPfield
  ) => {
    const newAnswers = { ...answers };
    const newValue = event.target.value as FinancialTools;
    if (newAnswers[field]?.includes(newValue)) {
      newAnswers[field] = newAnswers[field]?.filter(
        (value) => value !== newValue
      );
    } else {
      newAnswers[field] = newAnswers[field]?.concat(newValue);
    }
    onChange(newAnswers);
  };

  return (
    <table className="mb-4">
      <tbody>
        <tr className="border border-slate-300 bg-slate-200">
          <td className="px-2 py-1 font-bold w-full">{question}</td>
          <td className="!text-center px-2 py-1 text-sm w-1/10">Klient</td>
          <td className="!text-center px-2 py-1 text-sm w-1/10">Partner</td>
        </tr>
        <tr>
          <td className="px-2 py-1">Dlhopisy</td>
          <td className="px-2 py-1 text-center">
            <input
              type="checkbox"
              id={`${name}ClientQuestionCheckbox`}
              name={`${name}ClientQuestionCheckbox`}
              value={FinancialTools.BOND}
              checked={answers.client?.includes(FinancialTools.BOND)}
              onChange={(event) => handleChange(event, "client")}
              data-testid={`/documents/zuz/esg/input-${name}-ClientQuestionCheckbox`}
            />
          </td>
          <td className="!text-center px-2 py-1">
            <input
              type="checkbox"
              id={`${name}PartnerQuestionCheckbox`}
              name={`${name}PartnerQuestionCheckbox`}
              value={FinancialTools.BOND}
              checked={answers.partner?.includes(FinancialTools.BOND)}
              onChange={(event) => handleChange(event, "partner")}
              data-testid={`/documents/zuz/esg/input-${name}-PartnerQuestionCheckbox`}
            />
          </td>
        </tr>
        <tr>
          <td className="px-2 py-1">Akcie</td>
          <td className="px-2 py-1 text-center">
            <input
              type="checkbox"
              id={`${name}ClientQuestionCheckbox`}
              name={`${name}ClientQuestionCheckbox`}
              value={FinancialTools.STOCK}
              checked={answers.client?.includes(FinancialTools.STOCK)}
              onChange={(event) => handleChange(event, "client")}
              data-testid={`/documents/zuz/esg/input-${name}-ClientQuestionCheckbox`}
            />
          </td>
          <td className="!text-center px-2 py-1">
            <input
              type="checkbox"
              id={`${name}PartnerQuestionCheckbox`}
              name={`${name}PartnerQuestionCheckbox`}
              value={FinancialTools.STOCK}
              checked={answers.partner?.includes(FinancialTools.STOCK)}
              onChange={(event) => handleChange(event, "partner")}
              data-testid={`/documents/zuz/esg/input-${name}-PartnerQuestionCheckbox`}
            />
          </td>
        </tr>
        <tr>
          <td className="px-2 py-1">Podielové fondy</td>
          <td className="px-2 py-1 text-center">
            <input
              type="checkbox"
              id={`${name}ClientQuestionCheckbox`}
              name={`${name}ClientQuestionCheckbox`}
              value={FinancialTools.MUTUAL_FUND}
              checked={answers.client?.includes(FinancialTools.MUTUAL_FUND)}
              onChange={(event) => handleChange(event, "client")}
              data-testid={`/documents/zuz/esg/input-${name}-ClientQuestionCheckbox`}
            />
          </td>
          <td className="!text-center px-2 py-1">
            <input
              type="checkbox"
              id={`${name}PartnerQuestionCheckbox`}
              name={`${name}PartnerQuestionCheckbox`}
              value={FinancialTools.MUTUAL_FUND}
              checked={answers.partner?.includes(FinancialTools.MUTUAL_FUND)}
              onChange={(event) => handleChange(event, "partner")}
              data-testid={`/documents/zuz/esg/input-${name}-PartnerQuestionCheckbox`}
            />
          </td>
        </tr>
        <tr>
          <td className="px-2 py-1">ETF</td>
          <td className="px-2 py-1 text-center">
            <input
              type="checkbox"
              id={`${name}ClientQuestionCheckbox`}
              name={`${name}ClientQuestionCheckbox`}
              value={FinancialTools.ETF}
              checked={answers.client?.includes(FinancialTools.ETF)}
              onChange={(event) => handleChange(event, "client")}
              data-testid={`/documents/zuz/esg/input-${name}-ClientQuestionCheckbox`}
            />
          </td>
          <td className="!text-center px-2 py-1">
            <input
              type="checkbox"
              id={`${name}PartnerQuestionCheckbox`}
              name={`${name}PartnerQuestionCheckbox`}
              value={FinancialTools.ETF}
              checked={answers.partner?.includes(FinancialTools.ETF)}
              onChange={(event) => handleChange(event, "partner")}
              data-testid={`/documents/zuz/esg/input-${name}-PartnerQuestionCheckbox`}
            />
          </td>
        </tr>
        <tr>
          <td className="px-2 py-1">
            Deriváty (opcie, futures, forwardy, swapy)
          </td>
          <td className="px-2 py-1 text-center">
            <input
              type="checkbox"
              id={`${name}ClientQuestionCheckbox`}
              name={`${name}ClientQuestionCheckbox`}
              value={FinancialTools.DERIVATIVE}
              checked={answers.client?.includes(FinancialTools.DERIVATIVE)}
              onChange={(event) => handleChange(event, "client")}
              data-testid={`/documents/zuz/esg/input-${name}-ClientQuestionCheckbox`}
            />
          </td>
          <td className="!text-center px-2 py-1">
            <input
              type="checkbox"
              id={`${name}PartnerQuestionCheckbox`}
              name={`${name}PartnerQuestionCheckbox`}
              value={FinancialTools.DERIVATIVE}
              checked={answers.partner?.includes(FinancialTools.DERIVATIVE)}
              onChange={(event) => handleChange(event, "partner")}
              data-testid={`/documents/zuz/esg/input-${name}-PartnerQuestionCheckbox`}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default KnownFinancialTools;
