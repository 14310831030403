import React from "react";

import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

import ApplicationRoute from "../../features/route/ApplicationRoute";
import Button from "./Button";

interface Properties {
  className?: string;
  inProgress?: boolean;
  disabled?: boolean;
  label?: string;
  to?: ApplicationRoute | undefined;
  onClick?: () => void | undefined;
}

function DownloadButton({
  className,
  inProgress,
  disabled,
  label,
  to,
  onClick,
}: Properties) {
  const { t } = useTranslation();
  return (
    <Button
      inProgress={inProgress}
      className={className}
      disabled={disabled}
      icon={faDownload}
      onClick={onClick}
      to={to}
    >
      {label ? t(label) : t("button.download")}
    </Button>
  );
}

DownloadButton.defaultProps = {
  className: "",
  inProgress: false,
  disabled: false,
  label: "button.download",
  to: undefined,
  onClick: undefined,
};

export default DownloadButton;
