import React, { useEffect, useState } from "react";

import {
  faChevronRight,
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ZUZDocumentSection from "../../enums/ZUZDocumentSection";
import Button from "../buttons/Button";

export type AccordionStateType = {
  uuid: ZUZDocumentSection | string;
  disabled: boolean;
};

export interface ToggableAccordionProps {
  title: string;
  children: React.ReactNode;
  defaultOpen?: boolean;
  disabled?: boolean;
  uuid: string | ZUZDocumentSection;
  errorsCount?: number;
  dataTestId?: string;
  hideToggleButton?: boolean;
  onDisableButtonClick?: (accordionState: AccordionStateType) => void;
  onOpenAccordionClick?: () => void;
}

function ToggableAccordion({
  uuid,
  title,
  children,
  defaultOpen,
  disabled,
  errorsCount,
  dataTestId,
  onDisableButtonClick,
  onOpenAccordionClick,
  hideToggleButton,
}: ToggableAccordionProps) {
  const [open, setOpen] = useState(defaultOpen);
  const [contentVisible, setContentVisible] = useState(defaultOpen);
  const handleAccordionOpen = () => {
    if (errorsCount && errorsCount > 0) {
      setOpen(true);
    }

    if (disabled) {
      return;
    }

    setOpen(!open);

    if (onOpenAccordionClick) {
      onOpenAccordionClick();
    }
  };

  const handleToggle = () => {
    if (onDisableButtonClick) {
      onDisableButtonClick({
        uuid,
        disabled: !disabled,
      });

      if (onOpenAccordionClick) {
        onOpenAccordionClick();
      }

      if (disabled) {
        setOpen(true);
      }
    }
  };

  useEffect(() => {
    if (!disabled) {
      setContentVisible(open);
    }
  }, [open]);

  useEffect(() => {
    if (disabled) {
      setOpen(false);
    }
  }, [disabled]);

  useEffect(() => {
    if (!disabled) {
      setOpen(defaultOpen);
    }
  }, [defaultOpen]);

  return (
    <div className="bg-white first:rounded-t-sm last:rounded-b-sm border border-gray-200 border-t-0 first:border-t">
      <div
        className={`flex justify-between items-center px-4 hover:bg-gray-50 cursor-pointer transition ${
          errorsCount && errorsCount > 0 && !disabled
            ? "bg-red-200 hover:bg-red-200"
            : "bg-none"
        }`}
      >
        <button
          className={`flex gap-3 items-center py-4  w-full ${
            disabled ? "opacity-50" : ""
          }`}
          role={disabled ? "none" : "button"}
          type="button"
          onClick={() => handleAccordionOpen()}
        >
          <FontAwesomeIcon
            icon={faChevronRight}
            className={`${
              open ? "rotate-90" : "rotate-0"
            } transition duration-300`}
          />
          <span>{title}</span>
        </button>
        {onDisableButtonClick ? (
          <div className="w-36 flex justify-end">
            <Button
              icon={disabled ? faCircleCheck : faCircleXmark}
              className="text-sm"
              onClick={() => handleToggle()}
              dataTestId={dataTestId}
            >
              {disabled ? "Zapnúť" : "Vypnúť"}
            </Button>
          </div>
        ) : null}
      </div>
      <div
        className={`border-t border-gray-200 p-4 ${open ? "block" : "hidden"}`}
      >
        <div
          className={`transition duration-500 ${
            contentVisible ? "opacity-100" : "opacity-0"
          }`}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

ToggableAccordion.defaultProps = {
  defaultOpen: false,
  disabled: false,
  hideToggleButton: false,
  onDisableButtonClick: undefined,
  errorsCount: 0,
  dataTestId: "",
  onOpenAccordionClick: undefined,
};

export default ToggableAccordion;
