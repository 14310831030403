import React, { useCallback, useEffect, useState } from "react";

import { faFileSignature } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
  Document,
  useGetDocumentByIdQuery,
  useSignDocumentMutation,
} from "../../api/documents.generated";
import { SIGNED } from "../../enums/DocumentStatusKey";
import SignatureType from "../../enums/SignatureType";
import Loader from "../../features/loader/Loader";
import useFormatters from "../../hooks/formatter";
import { getRoleId } from "../../hooks/role";
import Button from "../buttons/Button";
import CancelButton from "../buttons/CancelButton";
import ConfirmModal from "./ConfirmModal";

interface Properties {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  document: Document | undefined;
  callback: () => void;
}

function SignDocumentModal({
  show,
  setShow,
  document,
  callback,
}: Properties): JSX.Element {
  const { t } = useTranslation();
  const [currentNrOfButtonsClicked, setCurrentNrOfButtonsClicked] = useState(0);
  const [maxNrOfButtonsClicked, setMaxNrOfButtonsClicked] = useState(1);
  const [doSignDocument, signDocumentStatus] = useSignDocumentMutation({});
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const { formatApiError } = useFormatters();
  const [skipPoll, setSkipPoll] = useState(true);
  const { data: pollingData } = useGetDocumentByIdQuery(
    { "X-Role-Id": getRoleId(), id: document?.id ?? "" },
    {
      pollingInterval: 3000,
      skip: skipPoll,
    }
  );

  const handleCloseClick = () => {
    if (show) {
      setShow(false);
    }
  };

  const checkKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (!show) return;
      if (!signDocumentStatus.isLoading) {
        if (e.key === "Escape") {
          handleCloseClick();
        }
        if (e.key === "Enter") {
          // handle enter press
        }
      }
    },
    [show, signDocumentStatus.isLoading]
  );

  useEffect(() => {
    window.addEventListener("keydown", checkKeyPress);
    return () => {
      window.removeEventListener("keydown", checkKeyPress);
    };
  }, [checkKeyPress]);

  const handleClose = () => {
    setShow(false);
  };

  const handleSignatureModeEmail = () => {
    if (signDocumentStatus.isLoading) return;

    doSignDocument({
      "X-Role-Id": getRoleId(),
      id: document?.id ?? "",
      mode: SignatureType.EMAIL,
    })
      .unwrap()
      .then((signResponse) => {
        toast.info(t("app.document.toast.sign.successEmail"));
        if (signResponse.mentor_id) {
          toast.success(t("app.zuz.mentorAdded"));
        }
      })
      .catch((error) => {
        toast.error(formatApiError(error));
      })
      .finally(() => {
        callback();
        setShowConfirmModal(false);
        setShow(false);
      });
  };

  useEffect(() => {
    if (pollingData?.status?.key === SIGNED) {
      setSkipPoll(true);
      handleClose();
    }
  }, [pollingData]);

  const increaseButtonsClicked = () => {
    if (currentNrOfButtonsClicked + 1 >= maxNrOfButtonsClicked) {
      setSkipPoll(false);
    } else {
      setCurrentNrOfButtonsClicked(currentNrOfButtonsClicked + 1);
    }
  };

  const handleSignatureModeLink = () => {
    if (signDocumentStatus.isLoading) return;

    doSignDocument({
      "X-Role-Id": getRoleId(),
      id: document?.id ?? "",
      mode: SignatureType.LINK,
    })
      .unwrap()
      .then((response) => {
        setMaxNrOfButtonsClicked((response.notifications ?? []).length);
        if (response.mentor_id) {
          toast.success(t("app.zuz.mentorAdded"));
        }
      })
      .catch((error) => {
        toast.error(formatApiError(error));
        setShow(false);
      })
      .finally(() => {
        callback();
        setShowConfirmModal(false);
      });
  };

  const persons = [
    {
      email: document?.advisor?.user?.email,
      name: `${document?.advisor?.firstname} ${document?.advisor?.lastname}`,
    },
  ];
  document?.customers?.forEach((customer) => {
    persons.push({
      email: customer?.email,
      name: `${customer?.firstname} ${customer?.lastname}`,
    });
  });

  if (document?.mentor) {
    persons.push({
      email: document?.mentor?.user?.email,
      name: `${document?.mentor?.firstname} ${document?.mentor?.lastname}`,
    });
  }

  useEffect(() => {
    if (show) {
      setShowConfirmModal(true);
    }
  }, [show]);

  if (!show) return <div />;

  if (showConfirmModal) {
    return (
      <>
        <ConfirmModal
          showModal={showConfirmModal}
          setShowModal={setShowConfirmModal}
          text={t(
            document?.content?.signatureType === SignatureType.EMAIL
              ? "app.document.modal.sign.mail-confirm-question"
              : "app.document.modal.sign.device-confirm-question"
          )}
          confirmBtnText={t("app.document.modal.sign.confirm")}
          noBtnText={t("button.back")}
          actionConfirm={
            document?.content?.signatureType === SignatureType.EMAIL
              ? handleSignatureModeEmail
              : handleSignatureModeLink
          }
          actionNo={() => {
            setShowConfirmModal(false);
            setShow(false);
          }}
          showX={false}
          useKeyHandler={false}
          actionInProgress={signDocumentStatus?.isLoading}
        />
        <div className="opacity-25 fixed inset-0 z-40 bg-black" />
      </>
    );
  }

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto mx-auto max-w-3xl">
          {/* content */}
          <div className="rounded-lg shadow-xl relative flex flex-col bg-white w-96">
            {/* header */}
            <div className="flex flex-cols border-b px-4 py-4">
              <h3 className="">
                <FontAwesomeIcon icon={faFileSignature} className="mr-2" />
                <span>{t("app.document.modal.sign.head")}</span>
              </h3>
            </div>
            {/* body */}
            <div className="px-4 py-4 my-4 text-blueGray-500">
              {document?.signatureNotification ? (
                <>
                  <div className="text-2xl mb-4">
                    <span>{t("app.document.modal.sign.parties")}</span>
                  </div>
                  <div className="flex flex-col gap-4">
                    {(document.signatureNotification ?? []).map((item) => (
                      <Button
                        key={`link-${item.email}`}
                        icon={faFileSignature}
                        onClick={() => {
                          increaseButtonsClicked();
                          window.open(item?.link, "_blank");
                        }}
                      >
                        {
                          persons
                            .filter(
                              (person) =>
                                person.email?.toLowerCase() === item?.email
                            )
                            .pop()?.name
                        }
                      </Button>
                    ))}
                  </div>
                </>
              ) : (
                ""
              )}
              {signDocumentStatus.isLoading ? (
                <div className="flex place-items-center place-content-center h-32">
                  <Loader size={64} />
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="border-t border-gray-300 text-right">
              <div className="px-4 py-4">
                <CancelButton onClick={handleClose} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black" />
    </>
  );
}

SignDocumentModal.defaultProps = {};

export default SignDocumentModal;
