import React, { FormEvent, useState } from "react";

import { faPlus, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import {
  Advisor,
  GetMentorsApiArg,
  MentorFilter,
  useGetMentorsQuery,
} from "../../api/mentors.generated";
import TableBodyLoading from "../../features/table/TableBodyLoading";
import TablePagination from "../../features/table/TablePagination";
import { getRoleId } from "../../hooks/role";
import Alert from "../alerts/Alert";
import AddButton from "../buttons/AddButton";
import Button from "../buttons/Button";
import CancelButton from "../buttons/CancelButton";

interface Properties {
  label?: string;
  selectedMentorID?: string;
  callback: (row: Advisor) => void;
  nbsActiveFilter?: boolean;
  capitalMarketRegistration?: boolean;
}

export default function MentorSelectModal({
  label,
  selectedMentorID,
  callback,
  nbsActiveFilter,
  capitalMarketRegistration,
}: Properties) {
  const { t } = useTranslation();
  const [showModal, setShowModal] = React.useState(false);

  const initialQuery = {
    size: 10,
    sort: ["lastname", "asc"],
    page: 1,
    mentorStates: ["rus-active"],
    keyword: "",
    nbs: nbsActiveFilter,
    capitalMarket: capitalMarketRegistration,
  };
  const [query, setQuery] = useState<GetMentorsApiArg>({
    ...initialQuery,
    "X-Role-Id": getRoleId(),
  });
  const { currentData, isFetching } = useGetMentorsQuery(query, {});

  const [searchValue, setSearchValue] = useState("");
  const searchInputRef = React.createRef<HTMLInputElement>();
  const [selectedRow, setSelectedRow] = useState<Advisor>();

  const setSelectedUser = (mentor: Advisor): void => {
    setSelectedRow(mentor);
  };

  const selectUserAndClose = (mentor: Advisor): void => {
    setSelectedRow(mentor);
    handleChange();
    cleanUp();
  };

  const searchFilterChange = (event: FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearchValue(value);
    return null;
  };

  const searchAdvisors = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === "keyup" &&
      (event as React.KeyboardEvent).key === "Escape"
    ) {
      setShowModal(false);
      return null;
    }

    if (
      event.type === "keyup" &&
      (event as React.KeyboardEvent).key !== "Enter"
    ) {
      return null;
    }

    event.stopPropagation();
    event.preventDefault();

    if (searchValue.length > 2 || searchValue.length === 0) {
      setQuery({
        ...query,
        keyword: searchValue,
        page: 1,
      });
    }

    return null;
  };

  const handleChange = () => {
    setShowModal(false);
    if (selectedRow) {
      callback(selectedRow);
      setSelectedRow(undefined);
    }
  };

  const handleCloseClick = () => {
    cleanUp();
    setSelectedRow(undefined);
    setShowModal(false);
  };

  const handleModalOpen = () => {
    setShowModal(true);
    searchInputRef?.current?.focus();
  };

  const cleanUp = () => {
    setSearchValue("");
    setQuery({ ...initialQuery, "X-Role-Id": getRoleId() });
  };

  React.useEffect(() => {
    if (showModal && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [showModal]);

  const finalLabel = label ? t(label) : t("app.mentor-select-modal.title");

  return (
    <div className="inline-block">
      <div className="inline">
        <Button
          onClick={handleModalOpen}
          className="text-sm px-2 font-normal py-1 ml-auto w-[170px]"
          icon={faPlus}
        >
          <span>{t(finalLabel)}</span>
        </Button>
      </div>
      <div className={`${showModal ? "block" : "hidden"}`}>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-normal">
          <div className="relative w-auto my-6 mx-auto max-w-3xl">
            {/* content */}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-192 bg-white outline-none focus:outline-none">
              {/* header */}
              <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                <h3 className="text-2xl font-semibold">
                  <span>{t(finalLabel)}</span>
                </h3>
                <button
                  type="button"
                  className="p-1 ml-auto bg-transparent border-0 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={handleCloseClick}
                >
                  <span className="bg-transparent h-6 w-6 text-2xl outline-none focus:outline-none flex items-center">
                    <FontAwesomeIcon icon={faTimes} />
                  </span>
                </button>
              </div>
              {/* body */}
              <div className="relative p-6 pt-2 flex-auto">
                <div className="bg-slate-200 p-4 my-2 mb-4 rounded flex">
                  <div className="flex-grow flex border border-gray-300 rounded">
                    <span className="w-8 text-center text-gray-300 px-1 border-gray-300 border-r bg-white rounded-l">
                      <FontAwesomeIcon
                        icon={faSearch}
                        className="text-gray-600 mt-1.5"
                      />
                    </span>
                    <input
                      ref={searchInputRef}
                      type="text"
                      className="w-full border-none text-gray-700 px-4 text-sm py-1 outline-0 rounded-r"
                      placeholder={t("table.filter.name") || ""}
                      value={searchValue}
                      onKeyUp={searchAdvisors}
                      onChange={searchFilterChange}
                    />
                  </div>
                  <input
                    type="button"
                    className="ml-1 border border-gray-300 bg-white text-gray-700 px-4 text-sm py-1 outline-0 rounded"
                    value={t("button.search") as string}
                    onClick={searchAdvisors}
                  />
                </div>
                <table className="text-sm">
                  <thead>
                    <tr>
                      <th>
                        <span>{t("table.header.name")}</span>
                      </th>
                      <th>
                        <span>{t("table.header.email")}</span>
                      </th>
                      <th>
                        <span>{t("table.header.externalId")}</span>
                      </th>
                    </tr>
                  </thead>
                  {isFetching && !currentData?.items ? (
                    <TableBodyLoading cols={3} rows={3} />
                  ) : (
                    <tbody>
                      <tr>
                        <td />
                      </tr>
                    </tbody>
                  )}
                  <tbody>
                    {currentData?.items?.map((mentor) => (
                      <tr
                        className={
                          mentor === selectedRow
                            ? "cursor-pointer bg-green-200 hover:bg-green-300"
                            : "cursor-pointer bg-white"
                        }
                        key={mentor.id}
                        onClick={() => setSelectedUser(mentor)}
                        onDoubleClick={() => selectUserAndClose(mentor)}
                      >
                        <td>
                          {mentor.firstname} {mentor.lastname}
                        </td>
                        <td>{mentor.user?.email}</td>
                        <td>{mentor.externalId}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {!isFetching && currentData?.count === 0 ? (
                  <Alert className="mt-4">
                    <span>{t("table.noData")}</span>
                  </Alert>
                ) : (
                  ""
                )}
              </div>
              {/* footer */}
              <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                <TablePagination
                  callback={setQuery}
                  query={query}
                  count={currentData?.count ?? 0}
                />
                <div className="ml-auto">
                  <AddButton
                    className="mr-2"
                    onClick={handleChange}
                    disabled={!selectedRow}
                  />
                  <CancelButton onClick={handleCloseClick} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black" />
      </div>
    </div>
  );
}

MentorSelectModal.defaultProps = {
  label: "app.mentor-select-modal.title",
  selectedMentorID: undefined,
  nbsActiveFilter: false,
  capitalMarketRegistration: false,
};
