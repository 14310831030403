import React from "react";

import { faSave, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

import ApplicationRoute from "../../features/route/ApplicationRoute";
import Button from "./Button";

interface Properties {
  inProgress?: boolean;
  className?: string;
  dataTestId?: string;
  disabled?: boolean;
  to?: ApplicationRoute | undefined;
  form?: string;
  onClick?: () => void | Promise<void> | undefined;
}

function SaveButton({
  className,
  disabled,
  to,
  inProgress,
  form,
  dataTestId,
  onClick,
}: Properties) {
  const { t } = useTranslation();
  return (
    <Button
      className={className}
      disabled={disabled}
      icon={inProgress ? faSpinner : faSave}
      inProgress={inProgress}
      to={to}
      form={form}
      dataTestId={dataTestId}
      type="submit"
      onClick={onClick}
    >
      <span>{t("button.save")}</span>
    </Button>
  );
}

SaveButton.defaultProps = {
  className: "",
  dataTestId: "/global/btn-save",
  inProgress: false,
  form: "",
  disabled: false,
  to: undefined,
  onClick: undefined,
};

export default SaveButton;
