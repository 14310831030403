import React from "react";

import { decode } from "html-entities";

import Input from "../../../components/inputs/Input";
import { Document } from "../../../models/Document";
import { DocumentContentTypeStrings } from "../../../models/DocumentContent";

type Props = {
  disabled: boolean;
  label: string;
  name: DocumentContentTypeStrings;
  document: Document;
  setDocument: React.Dispatch<Document>;
  placeholder?: string;
  className?: string;
  dataTestId?: string;
  error?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

function DocumentInput({
  name,
  label,
  placeholder,
  document,
  disabled,
  className,
  dataTestId,
  setDocument,
  error,
  onChange,
}: Props) {
  let finalValue = decode(document.content[name] || "");

  // TODO: remove after old documents are closed
  if (name === "clientIdDisplayValue") {
    const oldStyleValue = decode(document.content.clientIdType || "");
    finalValue = oldStyleValue !== "" ? oldStyleValue : finalValue;
  }

  if (name === "partnerIdDisplayValue") {
    const oldStyleValue = decode(document.content.partnerIdType || "");
    finalValue = oldStyleValue !== "" ? oldStyleValue : finalValue;
  }

  const finalPlaceholder = placeholder || label;
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDocument({
      ...document,
      content: {
        ...document.content,
        [name]: e.currentTarget.value as string,
      },
    });
    onChange && onChange(e);
  };
  const finalDataTestId = dataTestId || `/documenst/zuz/input-${name}`;
  return (
    <Input
      label={label}
      name={name}
      placeholder={finalPlaceholder}
      onChange={onChangeHandler}
      value={finalValue}
      disabled={disabled}
      classNameWrapper={className}
      error={error}
      dataTestId={finalDataTestId}
    />
  );
}

DocumentInput.defaultProps = {
  placeholder: "",
  className: "",
  dataTestId: "",
  error: undefined,
  onChange: undefined,
};

export default DocumentInput;
