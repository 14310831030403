import React, { useState } from "react";

import {
  faMinusCircle,
  faPlusCircle,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import propTypes from "prop-types";

import { ExternalLink } from "../../api/external-link.generated";
import ExternalLinkTypeEnum from "../../enums/ExternalLinkTypeEnum";
import ImageResponseType from "../../enums/ImageResponseType";

interface Properties {
  item: ExternalLink;
  enabledFavouriteIcons?: boolean;
  isFavourite: boolean;
  callbackLinkClick: (link: ExternalLink) => void;
  callbackFavouriteClick?: (link: ExternalLink) => void;
  enabled?: boolean;
  borderColorClass?: string;
  enabledShadow?: boolean;
}

function ExternalLinkItem({
  item,
  isFavourite,
  callbackLinkClick,
  callbackFavouriteClick,
  enabled,
  enabledFavouriteIcons,
  borderColorClass,
  enabledShadow,
}: Properties) {
  const handleCategoryLinkClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    clickedItem: ExternalLink
  ) => {
    const target = e.target as HTMLElement;
    if (
      target.classList.contains("overlay-image") ||
      target.classList.contains("external-link-card")
    ) {
      callbackLinkClick(clickedItem);
    }
  };

  return (
    <div className="w-[210px] my-4">
      <div
        onClick={(e) => handleCategoryLinkClick(e, item)}
        aria-hidden="true"
        className={`${
          // eslint-disable-next-line no-nested-ternary
          enabled
            ? `group border-2 border-${borderColorClass} hover:border-spf-primary hover:cursor-pointer external-link-card`
            : isFavourite
            ? `group`
            : ``
        } w-full h-[120px] absolute bg-white ${
          enabledShadow ? `shadow-md` : ``
        } rounded-md relative`}
      >
        {/* image */}
        <div className="absolute top-0 w-[210px]">
          <img
            className="p-4 mx-auto rounded-lg object-contain overlay-image max-w-[210px] h-[120px]"
            src={
              item.image
                ? item.image
                    ?.filter(
                      (imageType) =>
                        imageType.type === ImageResponseType.FIT_IN_SMALL
                    )
                    .pop()?.url
                : "/externalLinkPlaceholder.svg"
            }
            alt=""
          />
        </div>
        {/* disabled overlay */}
        <div
          className={`${
            enabled ? "hidden" : "block"
          } w-full h-full bg-black absolute opacity-10 rounded-md`}
        />
        {enabledFavouriteIcons ? (
          <div className="absolute z-10 top-0 right-0 hidden group-hover:block p-1">
            <FontAwesomeIcon
              className="text-spf-gray text-xl cursor-pointer"
              icon={isFavourite ? faMinusCircle : faPlusCircle}
              onClick={
                callbackFavouriteClick
                  ? () => {
                      callbackFavouriteClick(item);
                    }
                  : undefined
              }
            />
          </div>
        ) : (
          ""
        )}
        {item.type?.key === ExternalLinkTypeEnum.PERSONALIZED ? (
          <div className="absolute bottom-0 right-0 p-1">
            <FontAwesomeIcon icon={faUsers} className="text-gray-300 text-xl" />
          </div>
        ) : (
          ""
        )}
      </div>
      <p className="text-center p-2 text-sm">{item.name}</p>
    </div>
  );
}

ExternalLinkItem.defaultProps = {
  enabled: true,
  enabledFavouriteIcons: true,
  callbackFavouriteClick: undefined,
  borderColorClass: "white",
  enabledShadow: true,
};

export default ExternalLinkItem;
