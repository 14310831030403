import { createContext, useMemo } from "react";

import ZUZDocumentSection from "../../enums/ZUZDocumentSection";
import type { ZUZDocumentError } from "../../hooks/documentValidation";

export interface DocumentZuzFormViewContext {
  validationErrors: Array<ZUZDocumentError>;
  getSectionErrors: (section: ZUZDocumentSection) => Array<ZUZDocumentError>;
  getFieldError: (field: string) => ZUZDocumentError | undefined;
  resetFieldError: (name: string) => void;
  resetFieldsErrors: (names: Array<string>) => void;
}

const DocumentZuzContext = createContext<DocumentZuzFormViewContext>({
  validationErrors: [],
  getSectionErrors: () => [],
  getFieldError: () => undefined,
  resetFieldError: () => null,
  resetFieldsErrors: () => null,
});

export default DocumentZuzContext;
