/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext } from "react";

import Card from "../../../components/card/Card";
import ClientPartnerCheckbox from "../../../components/inputs/ClientPartnerCheckbox";
import ClientPartnerRadio from "../../../components/inputs/ClientPartnerRadio";
import useDocumentGetters from "../../../hooks/documentContent";
import { Document } from "../../../models/Document";
import DocumentZuzContext from "../DocumentZuzFormViewContext";

interface Properties {
  document: Document;
  setDocument: React.Dispatch<Document>;
}

function Page02InsuranceCard({ document, setDocument }: Properties) {
  const { getValue, handleInputChange, handleTextareaChange } =
    useDocumentGetters();
  const ctx = useContext(DocumentZuzContext);
  return (
    <Card className="mb-8 px-4">
      <div className="mb-8">
        <div className="py-3 mb-6">
          <div className="mb-4">
            <div className="text-base font-bold my-2">
              Vstupné informácie (podľa § 35 zákona č. 186/2009 Z.z.)
            </div>
            <div className="text-base font-bold my-2">
              Požiadavky a potreby vyslovené Klientom / Partnerom
            </div>
            <div className="flex">
              <div className="w-1/2 mb-4 pr-2 inline-block">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="insuranceClientEntryRequests"
                >
                  Klient
                </label>
                <input
                  className={`appearance-none border ${
                    ctx.getFieldError("insuranceClientEntryRequests")
                      ? "border-red-400"
                      : ""
                  } rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                  id="insuranceClientEntryRequests"
                  type="text"
                  name="insuranceClientEntryRequests"
                  placeholder="Klient"
                  value={getValue(document, "insuranceClientEntryRequests")}
                  onChange={(e) => {
                    handleInputChange(
                      document,
                      setDocument,
                      "insuranceClientEntryRequests",
                      e
                    );
                    ctx.resetFieldsErrors([
                      "insuranceClientEntryRequests",
                      "insurancePartnerEntryRequests",
                    ]);
                  }}
                  data-testid="/documents/zuz/card-02/input-insuranceClientEntryRequests"
                />
                {ctx.getFieldError("insuranceClientEntryRequests") ? (
                  <p className="text-red-400">
                    {ctx.getFieldError("insuranceClientEntryRequests")?.message}
                  </p>
                ) : null}
              </div>
              <div className="w-1/2 mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="insurancePartnerEntryRequests"
                >
                  Partner
                </label>
                <input
                  className={`appearance-none border ${
                    ctx.getFieldError("insurancePartnerEntryRequests")
                      ? "border-red-400"
                      : ""
                  } rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                  id="insurancePartnerEntryRequests"
                  type="text"
                  name="insurancePartnerEntryRequests"
                  placeholder="Partner"
                  value={getValue(document, "insurancePartnerEntryRequests")}
                  onChange={(e) => {
                    handleInputChange(
                      document,
                      setDocument,
                      "insurancePartnerEntryRequests",
                      e
                    );
                    ctx.resetFieldsErrors([
                      "insuranceClientEntryRequests",
                      "insurancePartnerEntryRequests",
                    ]);
                  }}
                  data-testid="/documents/zuz/card-02/input-insurancePartnerEntryRequests"
                />
                {ctx.getFieldError("insurancePartnerEntryRequests") ? (
                  <p className="text-red-400">
                    {
                      ctx.getFieldError("insurancePartnerEntryRequests")
                        ?.message
                    }
                  </p>
                ) : null}
              </div>
            </div>
          </div>

          <div className="flex flex-col mb-4">
            <h3 className="mb-2 font-bold">
              Znalosti a skúsenosti s poistením
            </h3>
            <div className="w-1/4">
              <ClientPartnerRadio
                name="insuranceExperiences"
                document={document}
                setDocument={setDocument}
                client={{
                  hasError: !!ctx.getFieldError("insuranceExperiencesClient"),
                  errorMsg: ctx.getFieldError("insuranceExperiencesClient")
                    ?.message,
                  onChange: (e) => {
                    ctx.resetFieldError("insuranceExperiencesClient");
                  },
                }}
                partner={{
                  hasError: !!ctx.getFieldError("insuranceExperiencesPartner"),
                  errorMsg: ctx.getFieldError("insuranceExperiencesPartner")
                    ?.message,
                  onChange: (e) => {
                    ctx.resetFieldError("insuranceExperiencesPartner");
                  },
                }}
              />
            </div>
          </div>

          {/* poistenie osob */}
          <div>
            <h4 className="font-bold uppercase underline underline-offset-1">
              1. Poistenie osôb
            </h4>
          </div>
          <div className="flex flex-grow">
            <div className="pr-5">Typ poistenia :</div>
            <div className="w-2/12 pr-5">
              <ClientPartnerCheckbox
                document={document}
                setDocument={setDocument}
                name="insuranceTypeRisk"
                text="rizikové"
              />
            </div>
            <div className="w-2/12 pr-5">
              <ClientPartnerCheckbox
                document={document}
                setDocument={setDocument}
                name="insuranceTypeCapital"
                text="kapitálové"
              />
            </div>
            <div className="w-2/12">
              <ClientPartnerCheckbox
                document={document}
                setDocument={setDocument}
                name="insuranceTypeInvestment"
                text="investičné"
              />
            </div>
            <div className="w-2/12">
              <ClientPartnerCheckbox
                document={document}
                setDocument={setDocument}
                name="insuranceTypeAccident"
                text="úrazové"
              />
            </div>
          </div>
          <div className="flex w-full">
            <div className="w-full font-bold mt-3 mb-1">Účel poistenia</div>
          </div>
          <div className="flex flex-wrap -mx-1 overflow-hidden">
            <div className="md:w-1/3 sm:w-1/2">
              <ClientPartnerCheckbox
                document={document}
                setDocument={setDocument}
                name="insuranceFamilySecuring"
                text="a. Základné zabezpečenie rodiny"
              />
              <ClientPartnerCheckbox
                document={document}
                setDocument={setDocument}
                name="insuranceIndividualSecuring"
                text="b. Individuálne zabezpečenie"
              />
              <ClientPartnerCheckbox
                document={document}
                setDocument={setDocument}
                name="insurancePensionSecuring"
                text="c. Dôchodkové zabezpečenie"
              />
            </div>
            <div className="md:w-1/3 sm:w-1/2">
              <ClientPartnerCheckbox
                document={document}
                setDocument={setDocument}
                name="insuranceChildrenSecuring"
                text="d. Zabezpečenie detí"
              />
              <ClientPartnerCheckbox
                document={document}
                setDocument={setDocument}
                name="insuranceCreditSecuring"
                text="e. Poistenie úveru"
              />
              <ClientPartnerCheckbox
                document={document}
                setDocument={setDocument}
                name="insuranceUpdatedPaymentCalendar"
                text="f. Upravený splátkový kalendár HÚ"
              />
            </div>
            <div className="md:w-1/3 sm:w-1/2">
              <ClientPartnerCheckbox
                document={document}
                setDocument={setDocument}
                name="insurancePurposeOther"
                text="g. Iné"
              />
              <div className="flex flex-col">
                <textarea
                  className={`form-control
                  block
                  w-full
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid ${
                    ctx.getFieldError("insurancePurposeOtherValue")
                      ? "border-red-400"
                      : "border-gray-300"
                  }
                  rounded
                  transition
                  ease-in-out
                  m-0
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none`}
                  id="insurancePurposeOtherValue"
                  name="insurancePurposeOtherValue"
                  rows={3}
                  value={getValue(document, "insurancePurposeOtherValue")}
                  onChange={(e) =>
                    handleTextareaChange(
                      document,
                      setDocument,
                      "insurancePurposeOtherValue",
                      e
                    )
                  }
                  data-testid="/documents/zuz/textarea-insurancePurposeOtherValue"
                />
                {ctx.getFieldError("insurancePurposeOtherValue") ? (
                  <p className="text-red-400">
                    {ctx.getFieldError("insurancePurposeOtherValue")?.message}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
          <div className="flex flex-grow mt-5">
            <div className="pr-5">Poistné :</div>
            <div className="pr-5">
              <ClientPartnerCheckbox
                document={document}
                setDocument={setDocument}
                name="insurancePaymentOnce"
                text="jednorazové"
              />
            </div>
            <div className="pr-5">
              <ClientPartnerCheckbox
                document={document}
                setDocument={setDocument}
                name="insurancePaymentPeriodically"
                text="pravidelné"
              />
            </div>
          </div>
          <div className="flex w-full">
            <div className="w-full font-bold mt-3 mb-1">
              Rozsah poistenia (riziká)
            </div>
          </div>
          <div className="flex flex-wrap -mx-1 overflow-hidden">
            <div className="md:w-1/3 sm:w-1/2">
              <ClientPartnerCheckbox
                document={document}
                setDocument={setDocument}
                name="insuranceRiskLifeLost"
                text="a. Strata života - zabezpeč. blízkych"
              />
              <ClientPartnerCheckbox
                document={document}
                setDocument={setDocument}
                name="insuranceRiskInvalidity"
                text="b. Invalidita"
              />
              <ClientPartnerCheckbox
                document={document}
                setDocument={setDocument}
                name="insuranceRiskInsurancePaymentFree"
                text="c. Oslobodenie od platenia poistného"
              />
              <ClientPartnerCheckbox
                document={document}
                setDocument={setDocument}
                name="insuranceRiskWorkIncapacity"
                text="d. Práceneschopnosť"
              />
              <ClientPartnerCheckbox
                document={document}
                setDocument={setDocument}
                name="insuranceRiskAccidentDeath"
                text="e. Smrť následkom úrazu"
              />
            </div>
            <div className="md:w-1/3 sm:w-1/2">
              <ClientPartnerCheckbox
                document={document}
                setDocument={setDocument}
                name="insuranceRiskAccidentLastingConsequences"
                text="f. Trvalé následky úrazu"
              />
              <ClientPartnerCheckbox
                document={document}
                setDocument={setDocument}
                name="insuranceRiskAccidentDailyCompensation"
                text="g. Úraz - denné odškodné / ČNL"
              />
              <ClientPartnerCheckbox
                document={document}
                setDocument={setDocument}
                name="insuranceRiskHospitalization"
                text="h. Hospitalizácia"
              />
              <ClientPartnerCheckbox
                document={document}
                setDocument={setDocument}
                name="insuranceRiskCriticalIllnesses"
                text="i. Kritické choroby"
              />
              <ClientPartnerCheckbox
                document={document}
                setDocument={setDocument}
                name="insuranceRiskSurgery"
                text="j. Chirurgický zákrok"
              />
            </div>
            <div className="md:w-1/3 sm:w-1/2">
              <ClientPartnerCheckbox
                document={document}
                setDocument={setDocument}
                name="insuranceRiskOther"
                text="k. Iné"
              />
              <div className="flex flex-col">
                <textarea
                  className={`form-control
                  block
                  w-full
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid ${
                    ctx.getFieldError("insuranceRiskOtherValue")
                      ? "border-red-400"
                      : "border-gray-300"
                  }
                  rounded
                  transition
                  ease-in-out
                  m-0
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none`}
                  id="insuranceRiskOtherValue"
                  name="insuranceRiskOtherValue"
                  rows={3}
                  value={getValue(document, "insuranceRiskOtherValue")}
                  onChange={(e) =>
                    handleTextareaChange(
                      document,
                      setDocument,
                      "insuranceRiskOtherValue",
                      e
                    )
                  }
                  data-testid="/documents/zuz/textarea-insuranceRiskOtherValue"
                />
                {ctx.getFieldError("insuranceRiskOtherValue") ? (
                  <p className="text-red-400">
                    {ctx.getFieldError("insuranceRiskOtherValue")?.message}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
          <p className="pt-3 pb-3">
            <strong>
              Pri sprostredkovaní IŽP (investičný produkt založený na poistení)
              je potrebné vyplniť s Klientom/Partnerom Investičný-rizikový
              dotazník
            </strong>
          </p>
          <div className="flex flex-wrap -mx-1 overflow-hidden">
            <div className="md:w-1/3 sm-w-1/2">
              <h4 className="font-bold uppercase underline underline-offset-1 mb-2">
                2. Poistenie majetku
              </h4>
              <ClientPartnerCheckbox
                document={document}
                setDocument={setDocument}
                name="insurancePropertyHousehold"
                text="Domácnosť (hnuteľný majetok)"
              />
              <ClientPartnerCheckbox
                document={document}
                setDocument={setDocument}
                name="insurancePropertyRealty"
                text="Nehnuteľnosť"
              />
              <ClientPartnerCheckbox
                document={document}
                setDocument={setDocument}
                name="insuranceVehicleAccident"
                text="Havarijné poistenie"
              />
              {/* ine */}
              <div className="flex">
                <div className="align-self-center">
                  <ClientPartnerCheckbox
                    document={document}
                    setDocument={setDocument}
                    name="insurancePropertyOther"
                    text="Iné"
                  />
                </div>
                <div>
                  <input
                    className={`appearance-none border rounded w-full py-1 px-2 ${
                      ctx.getFieldError("insurancePropertyOtherValue")
                        ? "border-red-400"
                        : "border-gray-300"
                    } leading-tight focus:outline-none focus:shadow-outline ml-3`}
                    id="insurancePropertyOtherValue"
                    type="text"
                    name="insurancePropertyOtherValue"
                    placeholder=""
                    value={getValue(document, "insurancePropertyOtherValue")}
                    onChange={(e) =>
                      handleInputChange(
                        document,
                        setDocument,
                        "insurancePropertyOtherValue",
                        e
                      )
                    }
                    data-testid="/documents/zuz/card-02/input-insurancePropertyOtherValue"
                  />

                  {ctx.getFieldError("insurancePropertyOtherValue") ? (
                    <p className="text-red-400">
                      {
                        ctx.getFieldError("insurancePropertyOtherValue")
                          ?.message
                      }
                    </p>
                  ) : null}
                </div>
              </div>
              {/* ine end */}
            </div>
            <div className="md:w-1/3 sm-w-1/2">
              <h4 className="font-bold uppercase underline underline-offset-1 mb-2">
                3. Poistenie zodpovednosti
              </h4>
              <ClientPartnerCheckbox
                document={document}
                setDocument={setDocument}
                name="insuranceGeneralLiability"
                text="Všeobecná zodpovednosť"
              />
              <ClientPartnerCheckbox
                document={document}
                setDocument={setDocument}
                name="insurancePZP"
                text="Povinné zmluvné poistenie"
              />
              <ClientPartnerCheckbox
                document={document}
                setDocument={setDocument}
                name="insuranceLiabilityProfession"
                text="Z výkonu povolania"
              />
              {/* ine */}
              <div className="flex">
                <div className="align-self-center">
                  <ClientPartnerCheckbox
                    document={document}
                    setDocument={setDocument}
                    name="insuranceLiabilityOther"
                    text="Iné"
                  />
                </div>
                <div>
                  <input
                    className={`appearance-none border rounded w-full py-1 px-2 ${
                      ctx.getFieldError("insuranceLiabilityOtherValue")
                        ? "border-red-400"
                        : "border-gray-300"
                    } leading-tight focus:outline-none focus:shadow-outline ml-3`}
                    id="insuranceLiabilityOtherValue"
                    type="text"
                    name="insuranceLiabilityOtherValue"
                    placeholder=""
                    value={getValue(document, "insuranceLiabilityOtherValue")}
                    onChange={(e) =>
                      handleInputChange(
                        document,
                        setDocument,
                        "insuranceLiabilityOtherValue",
                        e
                      )
                    }
                    data-testid="/documents/zuz/card-02/input-insuranceLiabilityOtherValue"
                  />
                  {ctx.getFieldError("insuranceLiabilityOtherValue") ? (
                    <p className="text-red-400">
                      {
                        ctx.getFieldError("insuranceLiabilityOtherValue")
                          ?.message
                      }
                    </p>
                  ) : null}
                </div>
              </div>
              {/* ine end */}
            </div>
            <div className="md:w-1/3 sm-w-1/2">
              <h4 className="font-bold uppercase underline underline-offset-1 mb-2">
                4. Poistenie právnej ochrany
              </h4>
              <ClientPartnerCheckbox
                document={document}
                setDocument={setDocument}
                name="insuranceLegalSafetyTransport"
                text="V doprave"
              />
              {/* ine */}
              <div className="flex">
                <div className="align-self-center">
                  <ClientPartnerCheckbox
                    document={document}
                    setDocument={setDocument}
                    name="insuranceLegalSafetyOther"
                    text="Iné"
                  />
                </div>
                <div>
                  <input
                    className={`appearance-none border rounded w-full py-1 px-2 ${
                      ctx.getFieldError("insuranceLegalSafetyOtherValue")
                        ? "border-red-400"
                        : "border-gray-300"
                    } leading-tight focus:outline-none focus:shadow-outline ml-3`}
                    id="insuranceLegalSafetyOtherValue"
                    type="text"
                    name="insuranceLegalSafetyOtherValue"
                    placeholder=""
                    value={getValue(document, "insuranceLegalSafetyOtherValue")}
                    onChange={(e) =>
                      handleInputChange(
                        document,
                        setDocument,
                        "insuranceLegalSafetyOtherValue",
                        e
                      )
                    }
                    data-testid="/documents/zuz/card-02/input-insuranceLegalSafetyOtherValue"
                  />
                  {ctx.getFieldError("insuranceLegalSafetyOtherValue") ? (
                    <p className="text-red-400">
                      {
                        ctx.getFieldError("insuranceLegalSafetyOtherValue")
                          ?.message
                      }
                    </p>
                  ) : null}
                </div>
              </div>
              {/* ine end */}
              <h4 className="font-bold uppercase underline underline-offset-1 mb-2 mt-2">
                5. Cestovné poistenie
              </h4>
              <ClientPartnerCheckbox
                document={document}
                setDocument={setDocument}
                name="insuranceTravel"
                text="Cestovné poistenie"
              />
            </div>
            <div className="md:w-1/3 sm-w-1/2">
              <h4 className="font-bold uppercase underline underline-offset-1 mb-2">
                6. Iné
              </h4>
              <ClientPartnerCheckbox
                document={document}
                setDocument={setDocument}
                name="insuranceOther"
                text="Iné"
              />
              <div>
                <textarea
                  className={`form-control
                  block
                  w-full
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid ${
                    ctx.getFieldError("insuranceOtherValue")
                      ? "border-red-400"
                      : "border-gray-300"
                  }
                  rounded
                  transition
                  ease-in-out
                  m-0
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                `}
                  id="insuranceOtherValue"
                  name="insuranceOtherValue"
                  rows={3}
                  value={getValue(document, "insuranceOtherValue")}
                  onChange={(e) =>
                    handleTextareaChange(
                      document,
                      setDocument,
                      "insuranceOtherValue",
                      e
                    )
                  }
                  data-testid="/documents/zuz/textarea-insuranceOtherValue"
                />
                {ctx.getFieldError("insuranceOtherValue") ? (
                  <p className="text-red-400">
                    {ctx.getFieldError("insuranceOtherValue")?.message}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}

export default Page02InsuranceCard;
