/* eslint-disable no-nested-ternary */
import React, { useState } from "react";

import {
  faCube,
  faCubes,
  faHistory,
  faMapMarkerAlt,
  faSyncAlt,
  faUser,
  faUserAlt,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";

import { useGetAdvisorByIdQuery } from "../../api/advisors.generated";
import { ValidationError } from "../../api/auth.generated";
import { useSynchronizeSingleAdvisorMutation } from "../../api/synchronization.generated";
import BackButton from "../../components/buttons/BackButton";
import Button from "../../components/buttons/Button";
import Card from "../../components/card/Card";
import CardBody from "../../components/card/CardBody";
import CardHeader from "../../components/card/CardHeader";
import SelectAdvisorPosition from "../../components/inputs/SelectAdvisorPosition";
import SelectAdvisorType from "../../components/inputs/SelectAdvisorType";
import SelectCountry from "../../components/inputs/SelectCountry";
import SelectGender from "../../components/inputs/SelectGender";
import ValidatedInput from "../../components/inputs/ValidatedInput";
import PageHeader from "../../components/page/PageHeader";
import AdvisorType from "../../enums/AdvisorType";
import Country from "../../enums/Country";
import Gender from "../../enums/Gender";
import useFormatters from "../../hooks/formatter";
import { getRoleId } from "../../hooks/role";
import { authSelector } from "../auth/authSlice";
import Error403View from "../error/Error403View";
import Error404View from "../error/Error404View";
import PageLoader from "../loader/PageLoader";
import AllRoutes from "../route/Route";

function AdvisorEditView() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const { currentRole } = useSelector(authSelector);
  const [syncSingleAdvisor, syncSingleAdvisorResult] =
    useSynchronizeSingleAdvisorMutation();

  const { formatDate, formatTime, formatEmail } = useFormatters();

  const { currentData, isFetching, isError, error } = useGetAdvisorByIdQuery(
    {
      "X-Role-Id": getRoleId(),
      id: id ?? "",
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  // TODO: rethink the whole concept of create/update of advisor and the historical part
  const [advisorData, setAdvisorData] = useState<any>(currentData);

  const [formErrors] = useState<ValidationError[]>([]);

  if (!isFetching && isError && error) {
    if ("data" in error && error.status === 403) {
      return <Error403View error={error} />;
    }

    return <Error404View />;
  }

  if (isFetching && !currentData) return <PageLoader />;

  return (
    <>
      <PageHeader icon={faUserAlt} label={`${currentData?.businessName}`}>
        <div className="px-4 py-2 flex gap-2 rounded-full bg-slate-200">
          <FontAwesomeIcon icon={faUserCircle} className="mt-1" />
          {currentData?.updatedBy
            ? `${currentData?.updatedBy?.displayName}`
            : currentData?.updatedByICM}
        </div>
        <div className="px-4 py-2 mr-8 flex gap-2 rounded-full bg-slate-200">
          <FontAwesomeIcon icon={faHistory} className="mt-1" />
          {formatTime(currentData?.updatedAt)} -{" "}
          <span>{t("app.form.unlimited")}</span>
        </div>
        {currentRole?.role?.key === "rk-system-admin" ? (
          <Button
            className="bg-green-500"
            inProgress={syncSingleAdvisorResult.isLoading}
            disabled={syncSingleAdvisorResult.isLoading}
            onClick={() => {
              if (!syncSingleAdvisorResult.isLoading) {
                syncSingleAdvisor({
                  "X-Role-Id": getRoleId(),
                  advisorId: currentData?.id || "",
                })
                  .then((response) => {
                    toast(`${JSON.stringify(response)}`);
                  })
                  .catch((e) => toast.error(e));
              }
            }}
          >
            Synchronizuj s ICM
          </Button>
        ) : (
          <div />
        )}
        <BackButton
          onClick={() => {
            navigate(AllRoutes.ADVISORS_LIST.path);
          }}
        />
      </PageHeader>

      <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
        <div className="flex flex-col gap-4">
          <Card>
            <CardHeader
              icon={faUser}
              label={t("app.advisor.edit.card.advisor.header") || ""}
            >
              {isFetching ? (
                <FontAwesomeIcon className="mr-2" icon={faSyncAlt} spin />
              ) : (
                ""
              )}
            </CardHeader>
            <CardBody>
              <div className="py-1 grid grid-cols-5 gap-4">
                <ValidatedInput
                  label={t("app.form.firstname")}
                  field="businessName"
                  type="text"
                  value={
                    advisorData?.businessName ?? currentData?.businessName ?? ""
                  }
                  errors={formErrors}
                  disabled
                  required={false}
                  callback={(e) => {
                    setAdvisorData({
                      ...advisorData,
                      businessName: e.currentTarget.value,
                    });
                  }}
                />
                <div className="col-span-2">
                  <ValidatedInput
                    label={t("app.form.ico")}
                    field="ico"
                    type="text"
                    value={advisorData?.ico ?? currentData?.ico ?? ""}
                    errors={formErrors}
                    disabled
                    required={false}
                    callback={(e) => {
                      setAdvisorData({
                        ...advisorData,
                        ico: e.currentTarget.value,
                      });
                    }}
                  />
                </div>
                <div className="col-span-2">
                  <ValidatedInput
                    className="col-span-2"
                    label={t("app.form.nbsRegistrationNumber")}
                    field="nbsRegistrationNumber"
                    type="text"
                    value={
                      advisorData?.nbsRegistrationNumber ??
                      currentData?.nbsRegistrationNumber ??
                      ""
                    }
                    errors={formErrors}
                    disabled
                    callback={(e) => {
                      setAdvisorData({
                        ...advisorData,
                        nbsRegistrationNumber: e.currentTarget.value,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="py-1 grid grid-cols-2 gap-4">
                <ValidatedInput
                  label={t("app.form.email")}
                  field="email"
                  type="text"
                  value={
                    advisorData?.user || currentData?.user
                      ? advisorData?.user?.email ??
                        currentData?.user?.email ??
                        ""
                      : ""
                  }
                  errors={formErrors}
                  disabled
                  required={false}
                  callback={(e) => {
                    setAdvisorData({
                      ...advisorData,
                      user: {
                        ...advisorData?.user,
                        email: formatEmail(e.currentTarget.value),
                      },
                    });
                  }}
                />

                <SelectAdvisorPosition
                  selectedOption={currentData?.position?.key}
                  disabled
                  onChange={(e) => {
                    setAdvisorData({
                      ...advisorData,
                      type:
                        AdvisorType.filter(
                          (item) => item.key === e.currentTarget.value
                        ).pop() ?? AdvisorType.pop(),
                    });
                  }}
                />
              </div>

              <div className="py-1 grid grid-cols-2 gap-4">
                <SelectAdvisorType
                  selectedOption={currentData?.type?.key}
                  disabled
                  onChange={(e) => {
                    setAdvisorData({
                      ...advisorData,
                      type:
                        AdvisorType.filter(
                          (item) => item.key === e.currentTarget.value
                        ).pop() ?? AdvisorType.pop(),
                    });
                  }}
                />
                <ValidatedInput
                  label={t("app.form.externalId")}
                  field="externalId"
                  type="text"
                  value={
                    advisorData?.externalId ?? currentData?.externalId ?? ""
                  }
                  errors={formErrors}
                  disabled
                  required={false}
                  callback={(e) => {
                    setAdvisorData({
                      ...advisorData,
                      externalId: e.currentTarget.value,
                    });
                  }}
                />
              </div>
              <div className="py-1 grid grid-cols-2 gap-4">
                <SelectGender
                  selectedOption={currentData?.gender}
                  disabled
                  onChange={(e) => {
                    setAdvisorData({
                      ...advisorData,
                      gender:
                        Gender.filter(
                          (item) => item.key === e.currentTarget.value
                        ).pop() ?? Gender.pop(),
                    });
                  }}
                />
                <ValidatedInput
                  label={t("form.label.phone")}
                  field="phone_number"
                  type="text"
                  value={
                    advisorData?.phone_number ?? currentData?.phone_number ?? ""
                  }
                  errors={formErrors}
                  disabled
                  required={false}
                  callback={(e) => {
                    setAdvisorData({
                      ...advisorData,
                      phone_number: e.currentTarget.value,
                    });
                  }}
                />
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardHeader
              icon={faMapMarkerAlt}
              label={t("app.advisor.edit.card.business-address.header") || ""}
            >
              {isFetching ? (
                <FontAwesomeIcon className="mr-2" icon={faSyncAlt} spin />
              ) : (
                ""
              )}
            </CardHeader>
            <CardBody>
              <div className="py-1 grid grid-cols-2 gap-4">
                <ValidatedInput
                  label={t("app.form.street")}
                  field="street"
                  type="text"
                  required={false}
                  value={
                    advisorData?.businessAddress?.street ??
                    currentData?.businessAddress?.street ??
                    ""
                  }
                  errors={formErrors}
                  disabled
                  callback={(e) => {
                    setAdvisorData({
                      ...advisorData,
                      ...{
                        businessAddress: {
                          ...advisorData?.businessAddress,
                          street: e.currentTarget.value,
                        },
                      },
                    });
                  }}
                />

                <ValidatedInput
                  label={t("app.form.city")}
                  field="city"
                  type="text"
                  value={
                    advisorData?.businessAddress?.city ??
                    currentData?.businessAddress?.city ??
                    ""
                  }
                  errors={formErrors}
                  disabled
                  required={false}
                  callback={(e) => {
                    setAdvisorData({
                      ...advisorData,
                      ...{
                        businessAddress: {
                          ...advisorData?.businessAddress,
                          city: e.currentTarget.value,
                        },
                      },
                    });
                  }}
                />
              </div>

              <div className="py-1 grid grid-cols-2 gap-4">
                <ValidatedInput
                  label={t("app.form.zip")}
                  field="zip"
                  type="text"
                  value={
                    advisorData?.businessAddress?.zip ??
                    currentData?.businessAddress?.zip ??
                    ""
                  }
                  errors={formErrors}
                  disabled
                  required={false}
                  callback={(e) => {
                    setAdvisorData({
                      ...advisorData,
                      ...{
                        businessAddress: {
                          ...advisorData?.businessAddress,
                          zip: e.currentTarget.value,
                        },
                      },
                    });
                  }}
                />

                <SelectCountry
                  selectedOption={
                    currentData?.businessAddress?.country?.key ??
                    advisorData?.businessAddress?.country?.key ??
                    Country[0].key
                  }
                  disabled
                  onChange={(e) => {
                    setAdvisorData({
                      ...advisorData,
                      ...{
                        businessAdress: {
                          ...advisorData?.businessAddress,
                          country: {
                            key: e.currentTarget.value,
                          },
                        },
                      },
                    });
                  }}
                />
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardHeader
              icon={faMapMarkerAlt}
              label={t("app.advisor.edit.card.permanent-address.header") || ""}
            >
              {isFetching ? (
                <FontAwesomeIcon className="mr-2" icon={faSyncAlt} spin />
              ) : (
                ""
              )}
              {/* <OriginBadge origin={currentData?.origin} /> */}
            </CardHeader>
            <CardBody>
              <div className="py-1 grid grid-cols-2 gap-4">
                <ValidatedInput
                  label={t("app.form.street")}
                  field="street"
                  type="text"
                  required={false}
                  value={
                    advisorData?.permanentAddress?.street ??
                    currentData?.permanentAddress?.street ??
                    ""
                  }
                  errors={formErrors}
                  disabled
                  callback={(e) => {
                    setAdvisorData({
                      ...advisorData,
                      ...{
                        permanentAddress: {
                          ...advisorData?.permanentAddress,
                          street: e.currentTarget.value,
                        },
                      },
                    });
                  }}
                />

                <ValidatedInput
                  label={t("app.form.city")}
                  field="city"
                  type="text"
                  value={
                    advisorData?.permanentAddress?.city ??
                    currentData?.permanentAddress?.city ??
                    ""
                  }
                  errors={formErrors}
                  disabled
                  required={false}
                  callback={(e) => {
                    setAdvisorData({
                      ...advisorData,
                      ...{
                        permanentAddress: {
                          ...advisorData?.permanentAddress,
                          city: e.currentTarget.value,
                        },
                      },
                    });
                  }}
                />
              </div>

              <div className="py-1 grid grid-cols-2 gap-4">
                <ValidatedInput
                  label={t("app.form.zip")}
                  field="zip"
                  type="text"
                  value={
                    advisorData?.permanentAddress?.zip ??
                    currentData?.permanentAddress?.zip ??
                    ""
                  }
                  errors={formErrors}
                  disabled
                  required={false}
                  callback={(e) => {
                    setAdvisorData({
                      ...advisorData,
                      ...{
                        permanentAddress: {
                          ...advisorData?.permanentAddress,
                          zip: e.currentTarget.value,
                        },
                      },
                    });
                  }}
                />

                <SelectCountry
                  selectedOption={
                    currentData?.permanentAddress?.country?.key ??
                    advisorData?.permanentAddress?.country?.key ??
                    Country[0].key
                  }
                  disabled
                  onChange={(e) => {
                    setAdvisorData({
                      ...advisorData,
                      ...{
                        permanentAddress: {
                          ...advisorData?.permanentAddress,
                          country: {
                            key: e.currentTarget.value,
                          },
                        },
                      },
                    });
                  }}
                />
              </div>
            </CardBody>
          </Card>
        </div>

        <div className="flex flex-col gap-4">
          <Card>
            <CardHeader
              label={t("app.advisor.edit.card.sectors.header") || ""}
              icon={faCubes}
            />
            <CardBody className="flex flex-col gap-4">
              {(currentData?.sectors ?? [])?.map((sector, index) => (
                <div key={`customer-card-${sector.id}`}>
                  <div className="flex gap-2 rounded-t p-2 px-4 border border-b-0 text-sm bg-slate-500 text-white">
                    <FontAwesomeIcon
                      icon={faCube}
                      className="relative top-0.5"
                    />
                    {sector.type?.name}
                  </div>
                  <div className="px-4 py-2 first:mt-4 mb-8 last:mb-2 border border-gray-200 rounded-b">
                    <div className="grid grid-cols-2 gap-x-4">
                      <ValidatedInput
                        label={t("form.label.registrationFrom")}
                        field=""
                        value={
                          sector?.registration_from
                            ? formatDate(sector?.registration_from)
                            : ""
                        }
                        errors={[]}
                        disabled
                        callback={() => {
                          console.log("");
                        }}
                      />
                      <ValidatedInput
                        label={t("form.label.registrationTo")}
                        field=""
                        value={
                          sector?.registration_from
                            ? formatDate(sector?.registration_to)
                            : ""
                        }
                        errors={[]}
                        disabled
                        callback={() => {
                          console.log("");
                        }}
                      />
                    </div>
                    <div className="grid grid-cols-2 gap-x-4">
                      <ValidatedInput
                        label={t("form.label.certificationFrom")}
                        field=""
                        value={
                          sector?.certificate_from
                            ? formatDate(sector?.certificate_from)
                            : ""
                        }
                        errors={[]}
                        disabled
                        callback={() => {
                          console.log("");
                        }}
                      />
                      <ValidatedInput
                        label={t("form.label.certificationTo")}
                        field=""
                        value={
                          sector?.certificate_to
                            ? formatDate(sector?.certificate_to)
                            : ""
                        }
                        errors={[]}
                        disabled
                        callback={() => {
                          console.log("");
                        }}
                      />
                    </div>
                    <div className="grid grid-cols-2 gap-x-4">
                      <ValidatedInput
                        label={t("form.label.ofvFrom")}
                        field=""
                        value={
                          sector?.ofv_from ? formatDate(sector?.ofv_from) : ""
                        }
                        errors={[]}
                        disabled
                        callback={() => {
                          console.log("");
                        }}
                      />
                      <ValidatedInput
                        label={t("form.label.ofvTo")}
                        field=""
                        value={sector?.ofv_to ? formatDate(sector?.ofv_to) : ""}
                        errors={[]}
                        disabled
                        callback={() => {
                          console.log("");
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}

export default AdvisorEditView;
