import { useState } from "react";

import { useNavigate } from "react-router";

import AllRoutes from "../features/route/Route";
import useLogger from "./logger";
import { SortablePaginationQuery, useQuery } from "./query";
import { makeRoute } from "./route";

export interface MatrixQuery extends SortablePaginationQuery {
  keyword: string;
}

export type MatrixQueryDispatcher = (query: MatrixQuery) => void;

type MatrixQueryHook = [MatrixQuery, (query: MatrixQuery) => void];

const localStorageSizeKey = "matrix.page.size";

function getMatrixPageSizeFromStorage(): number {
  return Number(localStorage.getItem(localStorageSizeKey)) || 10;
}

function setMatrixPageSizeToStorage(newSize: number): void {
  localStorage.setItem(localStorageSizeKey, newSize.toString());
}

export function useMatrixListQuery(): MatrixQueryHook {
  const navigate = useNavigate();
  const query = useQuery();
  const { logger } = useLogger();
  const [matrixQuery, setMatrixQuery] = useState<MatrixQuery>({
    page: query.page ?? 1,
    size: query.size ?? getMatrixPageSizeFromStorage(),
    sort: query.sort ? [query.sort] : ["createdAt:desc"],
    keyword: query.keyword ?? "",
  });

  logger.debug("PARSED DOCUMENT QUERY", matrixQuery);

  return [
    matrixQuery,
    (updatedMatrixQuery: MatrixQuery) => {
      const queryString = Object.keys(updatedMatrixQuery)
        .map((key) => {
          const parameter = updatedMatrixQuery[key as keyof MatrixQuery];
          if (Array.isArray(parameter)) {
            const val = parameter.join(",");
            return val.length > 0 ? `${key}=${val}` : undefined;
          }

          return (parameter ?? "").toString().length > 0
            ? `${key}=${parameter}`
            : undefined;
        })
        .filter((item) => item !== undefined)
        .join("&");

      logger.debug("QUERY STRING", queryString);

      // Important order: First update the URI/history, than the state because
      // it forces a repaint of the component
      setMatrixPageSizeToStorage(updatedMatrixQuery.size ?? 10);
      navigate(`${makeRoute(AllRoutes.RULES_MATRIX_LIST)}?${queryString}`);
      setMatrixQuery(updatedMatrixQuery);
    },
  ];
}

const allHooks = { useMatrixListQuery };

export default allHooks;
