import React, { useEffect, useState } from "react";

import {
  faFileSignature,
  faHistory,
  faInfoCircle,
  faExternalLink,
  faUserFriends,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { decode } from "html-entities";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";

import {
  openControlDocument,
  openDocument,
  openDocumentPreview,
  openFilePreview,
} from "../../api/auth";
import {
  useGetDocumentByIdQuery,
  useUpdateDocumentStatusMutation,
  DocumentStatusKey,
} from "../../api/documents.generated";
import { useGetEventsQuery } from "../../api/events.generated";
import { getDocumentSignatureStatus } from "../../api/help";
import DocumentStatusBadge from "../../components/badges/DocumentStatusBadge";
import BackButton from "../../components/buttons/BackButton";
import CloseDocumentButton from "../../components/buttons/CloseDocumentButton";
import DeliveredButton from "../../components/buttons/DeliveredButton";
import DownloadButton from "../../components/buttons/DownloadButton";
import EditButton from "../../components/buttons/EditButton";
import PreviewButton from "../../components/buttons/PreviewButton";
import RefreshButton from "../../components/buttons/RefreshButton";
import SendDocumentButton from "../../components/buttons/SendDocumentButton";
import Card from "../../components/card/Card";
import CardBody from "../../components/card/CardBody";
import CardHeader from "../../components/card/CardHeader";
import DeleteDocumentModal from "../../components/modals/DeleteDocumentModal";
import SendCheckToCentralOrPostModal from "../../components/modals/SendCheckToCentralOrPostModal";
import SetCheckToCentralModal from "../../components/modals/SetCheckToCentralModal";
import SignDocumentModal from "../../components/modals/SignDocumentModal";
import PageHeader from "../../components/page/PageHeader";
import { CT_FO } from "../../enums/CustomerType";
import {
  CHECK_CLOSED,
  CHECK_DELETED,
  CHECK_DRAFT,
  CHECK_INCOMING_POST,
  CHECK_IN_OFFICE,
  DELETED,
  SIGNED,
  SIGNING_IN_PROGRESS,
  SIGNING_REJECTED,
} from "../../enums/DocumentStatusKey";
import { DocumentTypeKeys } from "../../enums/DocumentTypes";
import Roles from "../../enums/Roles";
import useFormatters from "../../hooks/formatter";
import { getRoleId } from "../../hooks/role";
import { makeRoute } from "../../hooks/route";
import { useAppSelector } from "../../hooks/store";
import { DocumentCheckAttachment } from "../../models/DocumentCheckAttachment";
import { DocumentCheckTableContent } from "../../models/DocumentCheckTableContent";
import { authSelector } from "../auth/authSlice";
import Error403View from "../error/Error403View";
import Error404View from "../error/Error404View";
import PageLoader from "../loader/PageLoader";
import AllRoutes from "../route/Route";
import DocumentHistory from "./DocumentHistory";

function DocumentCheckView() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { formatTimeWithoutSeconds } = useFormatters();
  const navigate = useNavigate();
  const { roleId, currentRole } = useAppSelector(authSelector);

  const { currentData, isFetching, isError, error, refetch } =
    useGetDocumentByIdQuery(
      {
        "X-Role-Id": getRoleId(),
        id: id ?? "0",
      },
      {
        refetchOnMountOrArgChange: true,
      }
    );

  const { refetch: eventRefetch, currentData: eventData } = useGetEventsQuery(
    {
      "X-Role-Id": getRoleId(),
      documentId: id ?? "0",
      page: 1,
      size: 100,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  if (currentData && currentData.type?.key === DocumentTypeKeys.ZUZ) {
    const path = makeRoute(AllRoutes.DOCUMENT_VIEW_ZUZ, {
      id,
    });
    navigate(path);
  }

  // Preview logic
  const [isPreviewInProgress, setIsPreviewInProgress] = useState(false);
  const [isPreviewDisabled, setIsPreviewDisabled] = useState(false);
  const previewPdf = () => {
    setIsPreviewInProgress(true);
    setIsPreviewDisabled(true);
    openDocumentPreview(id ?? "", roleId)
      .catch(() => {
        toast.error(t("toast.document.error.pdfPreview"));
      })
      .finally(() => {
        setIsPreviewInProgress(false);
        setIsPreviewDisabled(false);
      });
  };

  // Download logic
  const [isDownloadInProgress, setIsDownloadInProgress] = useState(false);
  const [isDownloadDisabled, setIsDownloadDisabled] = useState(false);
  const downloadPdf = () => {
    setIsDownloadInProgress(true);
    setIsDownloadDisabled(true);
    openDocument(id ?? "")
      .catch(() => {
        toast.error(t("toast.document.error.pdfPreview"));
      })
      .finally(() => {
        setIsDownloadInProgress(false);
        setIsDownloadDisabled(false);
      });
  };

  // Download logic
  const [isDownloadControlPDFInProgress, setIsDownloadControlPDFInProgress] =
    useState(false);
  const [isDownloadControlPDFDisabled, setIsDownloadControlPDFDisabled] =
    useState(false);
  const downloadControlPdf = () => {
    setIsDownloadControlPDFInProgress(true);
    setIsDownloadControlPDFDisabled(true);
    openControlDocument(id ?? "")
      .catch(() => {
        toast.error(t("toast.document.error.pdfPreview"));
      })
      .finally(() => {
        setIsDownloadControlPDFInProgress(false);
        setIsDownloadControlPDFDisabled(false);
      });
  };

  const [showSignModal, setShowSignModal] = useState(false);
  const [showSendToCentralOrPostModal, setShowSendToCentralOrPostModal] =
    useState(false);
  const [showSendToCentralModal, setShowSendToCentralModal] = useState(false);

  const [updateDocumentStatus] = useUpdateDocumentStatusMutation({});
  const doUpdateStatus = (dsKey: DocumentStatusKey, toastLabel: string) => {
    updateDocumentStatus({
      id: currentData?.id || "",
      documentStatusUpdate: { statusKey: dsKey },
    })
      .unwrap()
      .then((response: any) => {
        if (response.error) {
          return toast.error(response.error);
        }
        return toast.success(t(toastLabel));
      })
      .catch((err) => {
        toast.error(err?.data?.message);
      })
      .finally(() => {
        refetch();
        eventRefetch();
      });
  };

  const refreshDocumentStatus = () => {
    getDocumentSignatureStatus(id ?? "0").then((response) => {
      if (response.state === "completed") {
        toast.success(t("toast.document.success.signed"));
        refetch();
      } else {
        toast.info(t("toast.document.info.stillInProgress"));
      }
    });
  };

  const handlePreviewFile = (attachment: DocumentCheckAttachment) => {
    openFilePreview(attachment.file?.id ?? "", roleId).catch(() => {
      toast.error(t("toast.document.error.pdfPreview"));
    });
  };

  if (!isFetching && isError && error) {
    if ("data" in error && error.status === 403) {
      return <Error403View error={error} />;
    }

    return <Error404View />;
  }

  const hasPermissionToDelete = !(
    (currentRole.role?.key === Roles.ADVISOR ||
      currentRole.role?.key === Roles.ASSISTANT) &&
    (currentData?.status?.key === CHECK_IN_OFFICE ||
      currentData?.status?.key === CHECK_CLOSED)
  );

  const canUpdate: boolean = currentData?.canUpdate ?? false;

  if (isFetching && !currentData) return <PageLoader />;

  return (
    <>
      <PageHeader label={currentData?.displayName || ""} icon={faFileSignature}>
        <BackButton
          onClick={() => {
            navigate(makeRoute(AllRoutes.DOCUMENTS_LIST_CHECK, { id }));
          }}
        />
        {!(
          (currentRole.role?.key === Roles.ASSISTANT ||
            currentRole.role?.key === Roles.ADVISOR) &&
          currentData?.status?.key !== CHECK_DRAFT
        ) &&
        currentData?.status?.key !== CHECK_CLOSED &&
        currentData?.id &&
        canUpdate ? (
          <EditButton
            onClick={() => {
              navigate(makeRoute(AllRoutes.DOCUMENT_EDIT_CHECK, { id }));
            }}
          />
        ) : (
          ""
        )}
        {currentData?.type?.key === DocumentTypeKeys.CHECK_FORM &&
        currentData?.id ? (
          <PreviewButton
            inProgress={isPreviewInProgress}
            onClick={() => previewPdf()}
            disabled={isPreviewDisabled}
          />
        ) : (
          ""
        )}
        {currentData?.status?.key === SIGNING_IN_PROGRESS && currentData?.id ? (
          <RefreshButton onClick={refreshDocumentStatus} />
        ) : (
          ""
        )}
        {currentData?.status?.key === SIGNED && currentData?.id ? (
          <DownloadButton
            inProgress={isDownloadInProgress}
            onClick={() => downloadPdf()}
            disabled={isDownloadDisabled}
          />
        ) : (
          ""
        )}
        {currentData?.status?.key === SIGNED ||
        currentData?.status?.key === SIGNING_REJECTED ? (
          <DownloadButton
            inProgress={isDownloadControlPDFInProgress}
            label="button.downloadControl"
            onClick={() => downloadControlPdf()}
            disabled={isDownloadControlPDFDisabled}
          />
        ) : (
          ""
        )}
        {currentData?.status?.key === CHECK_DRAFT &&
        currentData?.id &&
        canUpdate ? (
          <SendDocumentButton
            onClick={() => {
              setShowSendToCentralOrPostModal(true);
            }}
            label={t("button.sendToOffice") || ""}
          />
        ) : (
          ""
        )}
        {currentRole.role?.key === Roles.CENTRAL &&
        currentData?.status?.key === CHECK_INCOMING_POST &&
        currentData?.id ? (
          <DeliveredButton
            onClick={() => {
              setShowSendToCentralModal(true);
            }}
            label={t("button.received") || ""}
          />
        ) : (
          ""
        )}
        {(currentRole.role?.key === Roles.CENTRAL ||
          currentRole.role?.key === Roles.SYSTEM_ADMIN) &&
        currentData?.status?.key === CHECK_IN_OFFICE &&
        currentData?.id ? (
          <SendDocumentButton
            onClick={() => {
              doUpdateStatus(CHECK_DRAFT, "toast.document.sendToAdvisor");
            }}
            label={t("button.sendBackToAdvisor") || ""}
          />
        ) : (
          ""
        )}
        {(currentRole.role?.key === Roles.CENTRAL ||
          currentRole.role?.key === Roles.SYSTEM_ADMIN) &&
        currentData?.status?.key !== CHECK_CLOSED &&
        currentData?.status?.key !== CHECK_DELETED &&
        currentData?.status?.key === CHECK_IN_OFFICE &&
        currentData?.id ? (
          <CloseDocumentButton
            onClick={() => {
              doUpdateStatus(CHECK_CLOSED, "toast.document.closed");
            }}
            label={t("button.close") || ""}
          />
        ) : (
          ""
        )}
        {currentData?.status?.key !== DELETED &&
        currentData?.status?.key !== CHECK_DELETED &&
        hasPermissionToDelete &&
        currentData?.id &&
        canUpdate ? (
          <DeleteDocumentModal
            documentId={currentData?.id}
            onDeleteDocument={() => {
              refetch();
            }}
          />
        ) : (
          ""
        )}
      </PageHeader>
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
        <div className="flex flex-col gap-4">
          <Card>
            <CardHeader icon={faInfoCircle} label={t("form.header.info") || ""}>
              <DocumentStatusBadge
                className="ml-2"
                status={currentData?.status}
                small
              />
            </CardHeader>
            <CardBody>
              <div className="grid grid-cols-2 gap-x-4 pb-2">
                <div className="my-2">
                  <div className="block text-gray-500 text-sm truncate">
                    <span>{t("form.label.created")}</span>
                  </div>
                  <div className="block text-sm text-gray-500 font-bold">
                    {formatTimeWithoutSeconds(currentData?.createdAt)}
                  </div>
                </div>
                <div className="my-2">
                  <div className="block text-gray-500 text-sm truncate">
                    <span>{t("form.label.updated")}</span>
                  </div>
                  <div className="block text-sm text-gray-500 font-bold">
                    {formatTimeWithoutSeconds(currentData?.updatedAt)}
                  </div>
                </div>
                <div className="my-2">
                  <div className="block text-gray-500 text-sm truncate">
                    <span>{t("form.label.administrator")}</span>
                  </div>
                  <div className="block text-sm text-gray-500 font-bold">
                    {`${currentData?.advisor?.firstname} ${currentData?.advisor?.lastname} (${currentData?.advisor?.user?.email})`}
                  </div>
                </div>
                <div className="my-2">
                  <div className="block text-gray-500 text-sm truncate">
                    <span>{t("form.label.implementor")}</span>
                  </div>
                  <div className="block text-sm text-gray-500 font-bold">
                    {`${currentData?.implementor?.firstname} ${currentData?.implementor?.lastname} (${currentData?.implementor?.user?.email})`}
                  </div>
                </div>
                <div className="my-2">
                  <div className="block text-gray-500 text-sm truncate">
                    <span>{t("form.label.bpjTotal")}</span>
                  </div>
                  <div className="block text-sm text-gray-500 font-bold">
                    {currentData?.content?.bpjTotal}
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardHeader
              label={t("app.document.history") || ""}
              icon={faHistory}
            />
            <CardBody>
              <DocumentHistory events={eventData?.items ?? []} />
            </CardBody>
          </Card>
        </div>
        <div className="flex flex-col gap-4">
          <Card>
            <CardHeader
              label={t("app.page.records.heading") || ""}
              icon={faUserFriends}
            />
            <CardBody className="flex flex-col gap-8">
              {(currentData?.content?.tableData ?? [])?.map(
                (item: DocumentCheckTableContent, index: number) => (
                  <div key={`customer-card-${item.customer?.id}`}>
                    <div className="flex gap-2 rounded-t p-2 px-4 border border-b-0 text-xs bg-slate-500 text-white">
                      <span>
                        {`${index + 1}. ${item.customer?.clientDisplayName} (${
                          item.customer?.type?.key === CT_FO
                            ? `${t("form.label.pin")}: ${item.customer?.pin})`
                            : `${t("form.label.ico")}: ${item.customer?.ico})`
                        }`}
                      </span>
                    </div>
                    <div
                      className={`grid grid-cols-2 gap-x-4 px-4 py-2 first:mt-4 last:mb-2 border-x border-gray-200 ${
                        item?.attachments && item?.attachments?.length > 0
                          ? ""
                          : "border-b rounded-b"
                      }`}
                    >
                      <div className="my-2">
                        <div className="block text-gray-500 text-sm truncate">
                          <span>{t("form.label.financialInstitution")}</span>
                        </div>
                        <div className="block text-sm text-gray-500 font-bold">
                          {`${decode(
                            currentData?.content?.tableData[index]
                              .financialInstitution
                          )} (${decode(
                            currentData?.content?.tableData[index].productLabel
                          )})`}
                        </div>
                      </div>
                      <div className="my-2 text-right">
                        <div className="block text-gray-500 text-sm truncate">
                          <span>{t("form.label.bpj")}</span>
                        </div>
                        <div className="block text-sm text-gray-500 font-bold">
                          {currentData?.content?.tableData[index].bpj !== ""
                            ? currentData?.content?.tableData[index].bpj
                            : 0}
                        </div>
                      </div>
                    </div>
                    {item?.attachments && item.attachments.length > 0 ? (
                      <div className="px-4 py-2 first:mt-4 mb-8 last:mb-2 border-t-0 border border-gray-200 rounded-b">
                        <div className="grid grid-cols-2 text-gray-500 text-sm mb-2 truncate">
                          <span>{t("form.label.attachments")}</span>
                        </div>
                        <div className="flex flex-col items-start">
                          {item.attachments.map(
                            (attachment: DocumentCheckAttachment) => (
                              <button
                                key={`customer-file-${attachment?.id}`}
                                type="button"
                                className="text-spf-primary hover:underline py-2 border-b last:border-b-0 border-gray-200 w-full text-left"
                                onClick={() => handlePreviewFile(attachment)}
                              >
                                {attachment.note ?? ""}
                                <FontAwesomeIcon
                                  icon={faExternalLink}
                                  className="hover:text-red-700 cursor-pointer ml-2"
                                />
                              </button>
                            )
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                )
              )}
            </CardBody>
          </Card>
        </div>
      </div>
      <SignDocumentModal
        document={currentData}
        show={showSignModal}
        setShow={setShowSignModal}
        callback={() => {
          refetch();
        }}
      />
      <SendCheckToCentralOrPostModal
        document={currentData}
        show={showSendToCentralOrPostModal}
        setShow={setShowSendToCentralOrPostModal}
        callback={() => {
          refetch();
        }}
        doUpdateStatus={doUpdateStatus}
      />
      <SetCheckToCentralModal
        document={currentData}
        show={showSendToCentralModal}
        setShow={setShowSendToCentralModal}
        callback={() => {
          refetch();
        }}
        doUpdateStatus={doUpdateStatus}
      />
    </>
  );
}

export default DocumentCheckView;
