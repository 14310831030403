// TODO: Remove this
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */

// import * as winston from "winston";
// import BrowserConsole from "winston-transport-browserconsole";

// const level = "debug";
// winston.configure({
//   transports: [
//     new BrowserConsole({
//       format: winston.format.simple(),
//       level,
//     }),
//   ],
// });

interface LoggerInterface {
  logger: any;
}

export const logger = {
  info(m: string, args: any) {
    console.info(m, args);
  },
  debug(m: string, args: any) {
    console.debug(m, args);
  },
  log(m: string, args: any) {
    console.log(m, args);
  },
};

function useLogger(): LoggerInterface {
  return {
    logger,
  };
}

export default useLogger;
