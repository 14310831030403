import React from "react";

interface Properties {
  className?: string;
  children: React.ReactNode;
}

function CardFooter({ className, children }: Properties): JSX.Element {
  return (
    <div className={`flex px-4 py-2 border-t border-gray-200 ${className}`}>
      {children}
    </div>
  );
}

CardFooter.defaultProps = {
  className: "",
};

export default CardFooter;
