import React from "react";

import { useTranslation } from "react-i18next";

import { externalLinkStatus } from "../../enums/Status";

interface Properties {
  callback: (name: string) => void | undefined;
  filters: string[];
}

function ExternalLinkStatusFilter({ filters, callback }: Properties) {
  const { t } = useTranslation();
  const handleChange = (type: string): void => {
    callback(type);
  };

  const lastIndex = externalLinkStatus.length - 1;
  return (
    <div className="inline-block rounded overflow-hidden">
      {externalLinkStatus.map((i, index) => (
        <button
          key={`external-link-status-filter-item-${i.type}`}
          onClick={() => handleChange(i.type)}
          type="button"
          className={`${
            filters.includes(i.type)
              ? `py-1 px-3 text-white border text-sm border-gray-300 ${
                  i.activeBgClass
                } ${index === 0 ? "border-r-0" : ""} ${
                  index === lastIndex ? "border-l-0" : ""
                }`
              : `bg-white hover:bg-gray-100 text-sm text-gray-600 py-1 px-3 border border-gray-300 ${
                  index === 0 ? "border-r-0" : ""
                } ${index === lastIndex ? "border-l-0" : ""}`
          } first:rounded-l last:rounded-r`}
        >
          <span>{t(`status.${i.type}`)}</span>
        </button>
      ))}
    </div>
  );
}

export default ExternalLinkStatusFilter;
