import { AdvisorPositionKey } from "../api/advisors.generated";
import AdvisorPositionType from "./AdvisorPositionType";
import SelectOption from "./SelectOption";

const AdvisorPosition: SelectOption<AdvisorPositionKey>[] = [
  {
    key: AdvisorPositionType.SP,
    name: "app.advisorPosition.sp",
  },
  {
    key: AdvisorPositionType.KR,
    name: "app.advisorPosition.kr",
  },
  {
    key: AdvisorPositionType.RR,
    name: "app.advisorPosition.rr",
  },
  {
    key: AdvisorPositionType.OR,
    name: "app.advisorPosition.or",
  },
  {
    key: AdvisorPositionType.M,
    name: "app.advisorPosition.m",
  },
  {
    key: AdvisorPositionType.S3,
    name: "app.advisorPosition.s3",
  },
  {
    key: AdvisorPositionType.S2,
    name: "app.advisorPosition.s2",
  },
  {
    key: AdvisorPositionType.S1,
    name: "app.advisorPosition.s1",
  },
  {
    key: AdvisorPositionType.SK,
    name: "app.advisorPosition.sk",
  },
  {
    key: AdvisorPositionType.K2,
    name: "app.advisorPosition.k2",
  },
  {
    key: AdvisorPositionType.K1,
    name: "app.advisorPosition.k1",
  },
  {
    key: AdvisorPositionType.T,
    name: "app.advisorPosition.t",
  },
  {
    key: AdvisorPositionType.BP,
    name: "app.advisorPosition.bp",
  },
  {
    key: AdvisorPositionType.BP90,
    name: "app.advisorPosition.bp90",
  },
  {
    key: AdvisorPositionType.BP85,
    name: "app.advisorPosition.bp85",
  },
  {
    key: AdvisorPositionType.BP70,
    name: "app.advisorPosition.bp70",
  },
  {
    key: AdvisorPositionType.BP00,
    name: "app.advisorPosition.bp00",
  },
  {
    key: AdvisorPositionType.FEL1,
    name: "app.advisorPosition.fel1",
  },
  {
    key: AdvisorPositionType.FEL2,
    name: "app.advisorPosition.fel2",
  },
  {
    key: AdvisorPositionType.FEL3,
    name: "app.advisorPosition.fel3",
  },
  {
    key: AdvisorPositionType.FEL4,
    name: "app.advisorPosition.fel4",
  },
];

export default AdvisorPosition;
