import React, { useState } from "react";

import {
  faCheck,
  faCheckSquare,
  faFileContract,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

import AllRoutes from "../route/Route";

function DocumentTypeSelector() {
  const navigate = useNavigate();

  return (
    <main>
      <div className="w-full h-screen flex flex-col md:items-center md:justify-center bg-gray-100 text-gray-700 overflow-hidden">
        <div className="flex flex-col md:flex-row bg-white border border-gray-200 w-full max-w-screen-md rounded-xl">
          <div className="order-2 md:order-1 w-full md:w-1/2 bg-slate-400 hover:bg-spf-blue rounded-l-xl p-8 pt-10 border-r-2">
            <Link to={AllRoutes.DOCUMENT_ADD_ZUZ.path}>
              <div className="text-center">
                <div className="inline-block border-8 border-white rounded-full w-48 h-48 pt-4 m-0">
                  <FontAwesomeIcon
                    icon={faFileContract}
                    size="7x"
                    color="white"
                    className="m-0 pt-6"
                  />
                </div>
                <div className="tracking-widest pt-3">
                  <h1 className="font-normal text-4xl text-gray-200">ZUZ</h1>
                </div>
              </div>
            </Link>
          </div>
          <div className="order-1 md:order-2 text-white bg-slate-400 hover:bg-spf-blue p-10 d-md-down-none ml-auto w-full md:w-1/2 md:rounded-r-xl flex flex-row h-full place-items-center justify-center">
            <Link to={AllRoutes.DOCUMENT_ADD_LIST.path}>
              <div className="text-center">
                <div className="inline-block border-8 border-white rounded-full w-48 h-48 pt-4 m-0">
                  <FontAwesomeIcon
                    icon={faCheckSquare}
                    size="7x"
                    color="white"
                    className="m-0 pt-6"
                  />
                </div>
                <div className="tracking-widest pt-3">
                  <h1 className="font-normal text-4xl text-gray-200">
                    Súpiska
                  </h1>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </main>
  );
}

export default DocumentTypeSelector;
