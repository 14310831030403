import { baseSplitApi as api } from "./baseApi";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    doLogin: build.mutation<DoLoginApiResponse, DoLoginApiArg>({
      query: (queryArg) => ({
        url: `/v1/auth/login`,
        method: "POST",
        body: queryArg.loginRequest,
      }),
    }),
    doLoginRefresh: build.mutation<
      DoLoginRefreshApiResponse,
      DoLoginRefreshApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/auth/login-refresh`,
        method: "POST",
        body: queryArg.authLoginRefreshRequest,
      }),
    }),
    doLoginSso: build.mutation<DoLoginSsoApiResponse, DoLoginSsoApiArg>({
      query: (queryArg) => ({
        url: `/v1/auth/login-sso`,
        method: "POST",
        body: queryArg.ssoRequest,
      }),
    }),
    doLogout: build.mutation<DoLogoutApiResponse, DoLogoutApiArg>({
      query: (queryArg) => ({
        url: `/v1/auth/logout`,
        method: "POST",
        body: queryArg.logoutRequest,
      }),
    }),
    getAuthOptions: build.query<
      GetAuthOptionsApiResponse,
      GetAuthOptionsApiArg
    >({
      query: () => ({ url: `/v1/auth/options/` }),
    }),
    requestResetPassword: build.mutation<
      RequestResetPasswordApiResponse,
      RequestResetPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/auth/request-reset-password`,
        method: "POST",
        params: { email: queryArg.email },
      }),
    }),
    resetPassword: build.mutation<
      ResetPasswordApiResponse,
      ResetPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/auth/reset-password`,
        method: "POST",
        body: queryArg.resetPasswordUpdateRequest,
      }),
    }),
    getNewOauth2Credentials: build.query<
      GetNewOauth2CredentialsApiResponse,
      GetNewOauth2CredentialsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/oauth2/credentials`,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as spfApi };
export type DoLoginApiResponse =
  /** status 200 Returns a token for further requests */ LoginResponse;
export type DoLoginApiArg = {
  /** Registration to be created. */
  loginRequest: LoginRequest;
};
export type DoLoginRefreshApiResponse =
  /** status 200 Returns a renewed token and refresh token */ LoginResponse;
export type DoLoginRefreshApiArg = {
  /** Refresh token */
  authLoginRefreshRequest: AuthLoginRefreshRequest;
};
export type DoLoginSsoApiResponse =
  /** status 200 Returns a token for further requests */ LoginResponse;
export type DoLoginSsoApiArg = {
  /** string */
  ssoRequest: SsoRequest;
};
export type DoLogoutApiResponse =
  /** status 200 Logout was successful */ SuccessResponse200;
export type DoLogoutApiArg = {
  /** Log out */
  logoutRequest: LogoutRequest;
};
export type GetAuthOptionsApiResponse =
  /** status 200 Logout was successful */ AuthOption[];
export type GetAuthOptionsApiArg = void;
export type RequestResetPasswordApiResponse =
  /** status 200 Token for reset */ SuccessResponse200;
export type RequestResetPasswordApiArg = {
  /** The users email address */
  email: string;
};
export type ResetPasswordApiResponse =
  /** status 200 Password was changed */ SuccessResponse200;
export type ResetPasswordApiArg = {
  /** Payload with update request */
  resetPasswordUpdateRequest: ResetPasswordUpdateRequest;
};
export type GetNewOauth2CredentialsApiResponse =
  /** status 200 OK */ GenerateCredentialsResponse;
export type GetNewOauth2CredentialsApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
};
export type LoginResponse = {
  access_token?: string;
  expires_at?: string;
  refresh_token?: string;
};
export type ValidationError = {
  code?: string;
  field?: string;
  message?: string;
};
export type ErrorResponse400 = {
  code?: number;
  data?: ValidationError[];
  message?: string;
};
export type ErrorResponse401 = {
  code?: number;
  data?: {
    [key: string]: string;
  }[];
  message?: string;
};
export type LoginRequest = {
  email?: string;
  password?: string;
};
export type AuthLoginRefreshRequest = {
  token?: string;
};
export type SsoRequest = {
  code?: string;
};
export type SuccessResponse200 = {
  id?: string;
  status?: string;
};
export type LogoutRequest = {
  notificationToken?: string;
};
export type AuthOption = {
  key?: string;
  name?: string;
  url?: string;
};
export type ResetPasswordUpdateRequest = {
  newPassword: string;
  token: string;
};
export type GenerateCredentialsResponse = {
  clientId?: string;
  clientSecret?: string;
};
export type ErrorResponse404 = {
  code?: number;
  data?: {
    [key: string]: string;
  }[];
  message?: string;
};
export const {
  useDoLoginMutation,
  useDoLoginRefreshMutation,
  useDoLoginSsoMutation,
  useDoLogoutMutation,
  useGetAuthOptionsQuery,
  useRequestResetPasswordMutation,
  useResetPasswordMutation,
  useGetNewOauth2CredentialsQuery,
} = injectedRtkApi;
