import { useState } from "react";

import { useNavigate } from "react-router";

import AllRoutes from "../features/route/Route";
import useLogger from "./logger";
import { SortablePaginationQuery, useQuery } from "./query";
import { makeRoute } from "./route";

export interface ExternalLinkConfigurationQuery
  extends SortablePaginationQuery {
  keyword: string;
  externalLinkId: string;
}

export type ExternalLinkConfigurationQueryDispatcher = (
  query: ExternalLinkConfigurationQuery
) => void;

type ExternalLinkConfigurationQueryHook = [
  ExternalLinkConfigurationQuery,
  (query: ExternalLinkConfigurationQuery) => void
];

const localStorageSizeKey = "external-links-configuration.page.size";

function getExternalLinkConfigurationsPageSizeFromStorage(): number {
  return Number(localStorage.getItem(localStorageSizeKey)) || 10;
}

function setExternalLinkConfigurationsPageSizeToStorage(newSize: number): void {
  localStorage.setItem(localStorageSizeKey, newSize.toString());
}

export function useExternalLinkConfigurationsQuery(
  linkId: string
): ExternalLinkConfigurationQueryHook {
  const navigate = useNavigate();
  const query = useQuery();
  const { logger } = useLogger();
  const [externalLinkConfigurationQuery, setExternalLinkConfigurationQuery] =
    useState<ExternalLinkConfigurationQuery>({
      page: query.page ?? 1,
      size: query.size ?? getExternalLinkConfigurationsPageSizeFromStorage(),
      sort: query.sort ? [query.sort] : ["createdAt:desc"],
      keyword: query.keyword ?? "",
      externalLinkId: linkId,
    });

  logger.debug(
    "PARSED EXTERNAL LINK CONFIGURATIONS QUERY",
    externalLinkConfigurationQuery
  );

  return [
    externalLinkConfigurationQuery,
    (updatedExternalLinkConfigurationQuery: ExternalLinkConfigurationQuery) => {
      const queryString = Object.keys(updatedExternalLinkConfigurationQuery)
        .map((key) => {
          const parameter =
            updatedExternalLinkConfigurationQuery[
              key as keyof ExternalLinkConfigurationQuery
            ];
          if (Array.isArray(parameter)) {
            const val = parameter.join(",");
            return val.length > 0 ? `${key}=${val}` : undefined;
          }

          return (parameter ?? "").toString().length > 0
            ? `${key}=${parameter}`
            : undefined;
        })
        .filter((item) => item !== undefined)
        .join("&");

      logger.debug("QUERY STRING", queryString);

      // Important order: First update the URI/history, than the state because
      // it forces a repaint of the component
      setExternalLinkConfigurationsPageSizeToStorage(
        updatedExternalLinkConfigurationQuery.size ?? 10
      );
      navigate(
        `${makeRoute(AllRoutes.EXTERNAL_LINKS_EDIT, {
          id: linkId,
        })}?${queryString}`
      );
      setExternalLinkConfigurationQuery(updatedExternalLinkConfigurationQuery);
    },
  ];
}

const allHooks = { useExternalLinkConfigurationsQuery };

export default allHooks;
