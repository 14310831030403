import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import {
  getMyProfile,
  getMyRoles,
  login,
  logout,
  refreshLogin,
} from "../../api/auth";
import { baseSplitApi } from "../../api/baseApi";
import { RoleUser, UserProfile } from "../../api/me.generated";
import { getRoleId, removeRoleId, setRoleId } from "../../hooks/role";
import { getRefreshToken, setTokens } from "../../hooks/token";
import type { RootState } from "../../stores/store";

interface AuthError {
  code: number;
  message: string;
}

interface AuthSuccess {
  access_token: string;
  refresh_token: string;
}

interface AuthState {
  isLoggedIn: boolean;
  isInProgress: boolean;
  isInitialized: boolean;
  roleId: string;
  currentRole: RoleUser;
  roles: RoleUser[];
  user: UserProfile;
  error: AuthError;
}

type AuthInitData = {
  profile: UserProfile;
  roles: RoleUser[];
};

const initialState: AuthState = {
  user: {
    name: "",
    email: "",
  },
  roleId: getRoleId(),
  currentRole: {},
  roles: [],
  isInitialized: false,
  isInProgress: false,
  isLoggedIn: false,
  error: {
    code: 0,
    message: "",
  },
};

const findDefaultRole = (
  currentRoleId: string,
  roles: RoleUser[]
): RoleUser => {
  let found = (
    roles.filter((item) => {
      return currentRoleId === item?.id;
    }) ?? []
  ).pop();

  // Try to find an advisor role
  if (!found) {
    found = (
      roles.filter((item) => {
        return item.role?.key === "rk-advisor";
      }) ?? []
    ).pop();
  }

  const currentRole = found || roles.pop();

  if (!currentRole) {
    toast.error("No roles associated with user");
  }

  return currentRole || {};
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    start: (state) => {
      return {
        ...state,
        isInProgress: true,
      };
    },
    success: (state, action: PayloadAction<AuthSuccess>) => {
      return {
        ...state,
        isInProgress: false,
        isLoggedIn: true,
      };
    },
    error: (state, action: PayloadAction<AuthError>) => {
      return {
        ...state,
        isInProgress: false,
        error: action.payload,
      };
    },
    logoutStart: (state) => {
      return {
        ...state,
        isInProgress: true,
      };
    },
    logoutSuccess: (state) => {
      return {
        ...state,
        user: initialState.user,
        isInProgress: false,
        isLoggedIn: false,
        roleId: "",
        roles: [],
      };
    },
    logoutError: (state) => {
      return {
        ...state,
        user: initialState.user,
        isInProgress: false,
        isLoggedIn: false,
        roleId: "",
        roles: [],
      };
    },
    initializationStart: (state) => {
      return {
        ...state,
        isInProgress: true,
      };
    },
    initializationSuccess: (state, action: PayloadAction<AuthInitData>) => {
      const newCurrentRole = findDefaultRole(
        state.roleId,
        action.payload.roles
      );
      const currentRoleId = newCurrentRole?.id as string;

      // Save to local storage
      setRoleId(currentRoleId);

      return {
        ...state,
        isInProgress: false,
        isLoggedIn: true,
        isInitialized: true,
        user: action.payload.profile,
        roleId: currentRoleId,
        currentRole: newCurrentRole,
        roles: action.payload.roles,
      };
    },
    initializationError: (state, action: PayloadAction<AuthError>) => {
      return {
        ...state,
        isInProgress: false,
        isInitialized: true,
        error: action.payload,
      };
    },
    reloadRoleId: (state) => {
      return {
        ...state,
        roleId: getRoleId() ?? "",
      };
    },
  },
});

export const authenticateUser =
  ({ username, password }: any) =>
  async (dispatch: any) => {
    try {
      dispatch(start());
      const authData = await login(username, password);
      setTokens(authData);
      dispatch(success(authData));
      dispatch(initialize());
    } catch (err: any) {
      dispatch(error(err.response.data));
    }
  };

export const logoutUser = () => async (dispatch: any) => {
  removeRoleId();
  try {
    dispatch(logoutStart());
    await logout();
    dispatch(logoutSuccess());
    dispatch(baseSplitApi.util.resetApiState());
  } catch (e: any) {
    dispatch(logoutError());
  }
};

export const initialize = () => async (dispatch: any) => {
  let tryRefresh = false;
  let error;
  dispatch(initializationStart());
  try {
    const profileData = await getMyProfile();
    const roles = await getMyRoles();
    dispatch(
      initializationSuccess({
        profile: profileData,
        roles,
      })
    );

    // Check if the role from the cookie id matches what we have on the server
    const roleId = getRoleId();
    const found = roles.filter((role) => role.id === roleId)?.pop();
    if (!found) {
      setRoleId(roles.pop()?.id ?? "");
      dispatch(reloadRoleId());
    }
  } catch (err: any) {
    tryRefresh = true;
    error = err;
  }

  if (tryRefresh) {
    const token = getRefreshToken() ?? "";
    if (token) {
      try {
        const tokens = await refreshLogin(token);
        setTokens(tokens);
        const profileData = await getMyProfile();
        const roles = await getMyRoles();
        dispatch(
          initializationSuccess({
            profile: profileData,
            roles,
          })
        );

        // Check if the role from the cookie id matches what we have on the server
        const roleId = getRoleId();
        const found = roles.filter((role) => role.id === roleId)?.pop();
        if (!found) {
          setRoleId(roles.pop()?.id ?? "");
          dispatch(reloadRoleId());
        }
      } catch (err: any) {
        dispatch(initializationError(err.response.data));
      }
    } else {
      dispatch(
        initializationError({
          code: 0,
          message: "",
        })
      );
    }
  } else {
    dispatch(initializationError(error?.response?.data ?? error?.response));
  }
};

export const {
  start,
  success,
  error,
  logoutStart,
  logoutSuccess,
  logoutError,
  initializationStart,
  initializationSuccess,
  initializationError,
  reloadRoleId,
} = authSlice.actions;

export const authSelector = (state: RootState) => state.auth;

export const authReducer = authSlice.reducer;
