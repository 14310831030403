import React from "react";

import { faList } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

import { RoleUserRight } from "../../../api/permission.generated";
import Alert from "../../../components/alerts/Alert";
import Card from "../../../components/card/Card";
import CardBody from "../../../components/card/CardBody";
import CardHeader from "../../../components/card/CardHeader";
import { getNameByKey } from "../../../enums/PermissionObjectSelectOptions";
import PermissionType from "../../../enums/PermissionType";
import { useComputeCrudText } from "../../../hooks/permissions";
import TableBodyLoading from "../../table/TableBodyLoading";

interface Props {
  computedCrud: RoleUserRight[];
  isFetching: boolean;
  selected: undefined | RoleUserRight;
  onSelect: (obj: RoleUserRight) => void;
}

export default function UserObjectAndFunctionList({
  computedCrud,
  isFetching,
  selected,
  onSelect,
}: Props) {
  const { t } = useTranslation();
  const { computeCrudText } = useComputeCrudText();

  const handleSelect = (rule: RoleUserRight) => {
    onSelect(rule);
  };

  return (
    <Card>
      <CardHeader
        icon={faList}
        label={t("card.header.rules-matrix.user.list") || ""}
      />
      <CardBody>
        <table>
          <thead>
            <tr>
              <td>
                <span>{t("table.header.functionAndObject")}</span>
              </td>
              <td className="!text-left">
                <span>{t("table.header.permission")}</span>
              </td>
            </tr>
          </thead>
          {isFetching && !computedCrud ? (
            <TableBodyLoading cols={1} rows={10} />
          ) : (
            <tbody>
              {computedCrud
                ? computedCrud?.map((rule: RoleUserRight) => (
                    <tr
                      key={`index-${rule?.type}`}
                      className={`cursor-pointer ${
                        selected?.type === rule?.type ? "bg-slate-100" : ""
                      }`}
                      onClick={() => handleSelect(rule)}
                    >
                      <td className="text-spf-primary">
                        {getNameByKey(rule?.type as PermissionType)}
                      </td>
                      <td className="text-spf-primary !text-left">
                        {computeCrudText(
                          rule?.create ?? "",
                          rule?.read ?? "",
                          rule?.update ?? "",
                          rule?.delete ?? ""
                        )}
                      </td>
                    </tr>
                  ))
                : t("table.noData") || ""}
            </tbody>
          )}
        </table>
        {!isFetching && computedCrud?.length === 0 ? (
          <Alert className="mt-4">{t("table.noData") || ""}</Alert>
        ) : (
          <span />
        )}
      </CardBody>
    </Card>
  );
}
