import React from "react";

import { Document } from "../api/documents.generated";
import {
  ClientPartnerRadioOptions,
  ClientPartnerRadioValues,
} from "../enums/ClientPartnerRadio";
import {
  clientPersonTypes,
  clientTypes,
  documentsPassedtoCustomers,
  esgQ1Checkboxes,
  esgQ2Checkboxes,
  esgQ3Checkboxes,
  pfaSectors,
  privacyPolicyAgree,
} from "../enums/Document";
import { InvestmentType } from "../enums/InvestmentType";
import ZUZDocumentSection from "../enums/ZUZDocumentSection";
import { CheckboxItems } from "../models/CheckboxItem";
import {
  DocumentPassedToCustomer,
  EsgQ1Checkboxes,
  EsgQ2Checkboxes,
  EsgQ3Checkboxes,
  PFASector,
  PersonType,
  PrivacyPolicy,
} from "../models/CheckboxItemField";
import {
  CUSTOMER_SKILL_TYPE,
  CustomerSkillTypeCheckbox,
} from "../models/CustomerSkillType";
import {
  AnswerClientPartnerType,
  AnswersABCDE,
  DocumentContent,
  EsgInterestType,
  EsgPreferences,
  FinancialToolsExperienceType,
  InvestmentIntentionOnce,
  InvestmentIntentionRepeated,
  KnownFinancialToolsType,
  Questionare,
  QuestionaryPoints,
  QuestionaryPointsItem,
  SustainabiltyRequestType,
} from "../models/DocumentContent";

const clientPartnerCheckboxDefault = {
  client: false,
  partner: false,
};

const esgPreferencesDefault: EsgPreferences = {
  client: undefined,
  partner: undefined,
};

const financialToolsExperienceDefault: FinancialToolsExperienceType = {
  client: undefined,
  partner: undefined,
};

const interestInEsgDefault: EsgInterestType = {
  client: undefined,
  partner: undefined,
};
const answerCLientPartnerTypeDefault: AnswerClientPartnerType = {
  client: undefined,
  partner: undefined,
};
const sustainabilityRequestDefault: SustainabiltyRequestType = {
  client: undefined,
  partner: undefined,
};
const investmentIntentionsOnceDefault: InvestmentIntentionOnce[] = [
  {
    frontendID: "",
    investmentCustomerType: undefined,
    investmentCustomerTypePDF: "",
    investmentType: InvestmentType.ONCE,
    achivable: "",
    description: "",
    investmentOnceAmount: undefined,
    investmentPurpose: undefined,
    investmentRepeatedAmount: undefined,
    investmentTimeHorizon: undefined,
    investorType: "",
    points: "",
  },
];

const investmentIntentionsRepeatedDefault: InvestmentIntentionRepeated[] = [
  {
    frontendID: "",
    investmentCustomerType: undefined,
    investmentCustomerTypePDF: "",
    investmentType: InvestmentType.REPEATED,
    achivable: "",
    description: "",
    investmentOnceAmount: undefined,
    investmentPurpose: undefined,
    investmentRepeatedAmount: undefined,
    investmentTimeHorizon: undefined,
    investorType: "",
    points: "",
  },
];

const knownFinancialToolsDefault: KnownFinancialToolsType = {
  client: undefined,
  partner: undefined,
};

const questionaireDefault: Questionare = {
  esgPreferences: esgPreferencesDefault,
  financialToolsExperience: financialToolsExperienceDefault,
  interestInEsg: interestInEsgDefault,
  investitionType: answerCLientPartnerTypeDefault,
  investmentIntentionsOnce: investmentIntentionsOnceDefault,
  investmentIntentionsRepeated: investmentIntentionsRepeatedDefault,
  knownFinancialTools: knownFinancialToolsDefault,
  reactionToLoss: answerCLientPartnerTypeDefault,
  sustainabilityRequest: sustainabilityRequestDefault,
};

const clientPartnerOptionsDefault: ClientPartnerRadioOptions = {
  client: ClientPartnerRadioValues.NONE,
  partner: ClientPartnerRadioValues.NONE,
};

const questionaryPointsItemDefault: QuestionaryPointsItem = {
  client: 0,
  partner: 0,
};

const questionaryPointsDefault: QuestionaryPoints = {
  financialToolsExperience: questionaryPointsItemDefault,
  investitionType: questionaryPointsItemDefault,
  knownFinancialTools: questionaryPointsItemDefault,
  reactionToLoss: questionaryPointsItemDefault,
  total: questionaryPointsItemDefault,
};

export const initialState: DocumentContent = {
  disabledSections: [
    ZUZDocumentSection.DDS,
    ZUZDocumentSection.SDS,
    ZUZDocumentSection.INSURANCE,
    ZUZDocumentSection.INCOME,
    ZUZDocumentSection.CREDIT,
    ZUZDocumentSection.CAPITAL_MARKET,
    ZUZDocumentSection.INVEST_QUESTIONNAIRE,
  ],
  showInvestmentSurvey: false,
  pfaName: "",
  pfaCompany: "",
  pfaAddress: "",
  pfaBusinessPlace: "",
  pfaNBS: "",
  pfaSPFId: "",
  pfaICO: "",
  pfaID: "",
  sdsExperiences: clientPartnerOptionsDefault,
  insuranceExperiences: clientPartnerOptionsDefault,
  bankDepositExperiences: clientPartnerOptionsDefault,
  loanExperiences: clientPartnerOptionsDefault,
  pfaEmail: "",
  clientIdType: "",
  partnerIdType: "",
  ddsExperiences: clientPartnerOptionsDefault,
  questionaire: questionaireDefault,
  questionaryPoints: questionaryPointsDefault,
  pfaSector: pfaSectors.map(
    (i: PFASector): PFASector => ({
      name: i.name,
      checked: false,
      label: i.label,
    })
  ),
  clientType: clientTypes.map(
    (i: CustomerSkillTypeCheckbox): CustomerSkillTypeCheckbox => {
      return {
        name: i.name,
        checked: i.name === CUSTOMER_SKILL_TYPE.NON_PROFESSIONAL,
        label: i.label,
      };
    }
  ),
  partnerType: clientTypes,
  clientID: "",
  clientName: "",
  clientIdNumber: "",
  clientBirthdate: "",
  clientHomeAddress: "",
  clientPostAddress: "",
  clientEmail: "",
  clientPhone: "",
  clientPersonType: clientPersonTypes.map(
    (i: PersonType): PersonType => ({
      name: i.name,
      label: i.label,
      checked: false,
    })
  ),
  clientBusinessAddress: "",
  clientCompany: "",
  clientBusinessAdress: "",
  clientBusinessId: "",
  clientBusinessFunction: "",
  clientIdDisplayValue: "",
  clientPersonalDocumentValue: "",
  clientIdDocumentType: "",
  clientIdValidUntil: "",
  clientState: "",
  clientOccupation: "",
  partnerName: "",
  partnerIdNumber: "",
  partnerBirthdate: "",
  partnerHomeAddress: "",
  partnerPostAddress: "",
  partnerEmail: "",
  partnerPhone: "",
  partnerIdDisplayValue: "",
  partnerPersonalDocumentValue: "",
  partnerIdDocumentType: "",
  partnerIdValidUntil: "",
  partnerState: "",
  // vyhlasenie klienta/partnera
  clientPFAApproved: clientPartnerCheckboxDefault,
  clientInformationAcknowledge: clientPartnerCheckboxDefault,
  clientPFANotAffected: clientPartnerCheckboxDefault,
  clientRiskAcknowledge: clientPartnerCheckboxDefault,
  clientDataApproved: clientPartnerCheckboxDefault,
  // par. 297
  clientOnHisOwnName: clientPartnerCheckboxDefault,
  clientOnHisOwnAccount: clientPartnerCheckboxDefault,
  clientWithHisOwnMoney: clientPartnerCheckboxDefault,
  clientContractUnder15000: clientPartnerCheckboxDefault,
  clientFinalUserBenefits: clientPartnerCheckboxDefault,
  clientNotPolitic: clientPartnerCheckboxDefault,
  // special
  clientDisagreeEntryInformation: clientPartnerCheckboxDefault,
  clientDisagreeEntryInformationAndAskForContract: clientPartnerCheckboxDefault,
  clientAgreedPFANotResponsible: clientPartnerCheckboxDefault,
  // source income
  sourceIncomeFromActivity: clientPartnerCheckboxDefault,
  sourceIncomeFromLowReserve: clientPartnerCheckboxDefault,
  sourceIncomeFromHighReserve: clientPartnerCheckboxDefault,
  sourceIncomeOther: clientPartnerCheckboxDefault,
  otherSourceIncomeValue: "",
  // zadlzenost
  clientDebtLoansDebt: "",
  partnerDebtLoansDebt: "",
  clientRepaymentSellDebt: "",
  partnerRepaymentSellDebt: "",
  clientLeasingDebt: "",
  partnerLeasingDebt: "",
  clientOtherDebt: "",
  partnerOtherDebt: "",
  // dds
  ddsClientEntryRequests: "",
  ddsPartnerEntryRequests: "",
  ddsClientEstimateSavingTime: "",
  ddsClientDue: "",
  ddsClientEmployerDue: "",
  ddsPartnerSector: "",
  ddsPartnerEstimateSavingTime: "",
  ddsPartnerDue: "",
  ddsPartnerEmployerDue: "",
  DDSWantTaxRelief: clientPartnerCheckboxDefault,
  // sds
  sdsClientEntryRequests: "",
  sdsPartnerEntryRequests: "",
  SDSWantTaxRelief: clientPartnerCheckboxDefault,
  SDSWantVoluntaryDues: clientPartnerCheckboxDefault,
  sdsVoluntaryDuesAmount: "",
  // poistenie
  insuranceClientEntryRequests: "",
  insurancePartnerEntryRequests: "",
  insuranceTypeRisk: clientPartnerCheckboxDefault,
  insuranceTypeCapital: clientPartnerCheckboxDefault,
  insuranceTypeInvestment: clientPartnerCheckboxDefault,
  insuranceTypeAccident: clientPartnerCheckboxDefault,
  // ucel poistenia
  insuranceFamilySecuring: clientPartnerCheckboxDefault,
  insuranceIndividualSecuring: clientPartnerCheckboxDefault,
  insurancePensionSecuring: clientPartnerCheckboxDefault,
  insuranceChildrenSecuring: clientPartnerCheckboxDefault,
  insuranceCreditSecuring: clientPartnerCheckboxDefault,
  insuranceUpdatedPaymentCalendar: clientPartnerCheckboxDefault,
  insurancePurposeOther: clientPartnerCheckboxDefault,
  insurancePurposeOtherValue: "",
  insuranceOther: clientPartnerCheckboxDefault,
  insuranceOtherValue: "",
  insurancePaymentOnce: clientPartnerCheckboxDefault,
  insurancePaymentPeriodically: clientPartnerCheckboxDefault,
  // rizika poistenia
  insuranceRiskLifeLost: clientPartnerCheckboxDefault,
  insuranceRiskInvalidity: clientPartnerCheckboxDefault,
  insuranceRiskInsurancePaymentFree: clientPartnerCheckboxDefault,
  insuranceRiskWorkIncapacity: clientPartnerCheckboxDefault,
  insuranceRiskAccidentDeath: clientPartnerCheckboxDefault,
  insuranceRiskAccidentLastingConsequences: clientPartnerCheckboxDefault,
  insuranceRiskAccidentDailyCompensation: clientPartnerCheckboxDefault,
  insuranceRiskHospitalization: clientPartnerCheckboxDefault,
  insuranceRiskCriticalIllnesses: clientPartnerCheckboxDefault,
  insuranceRiskSurgery: clientPartnerCheckboxDefault,
  insuranceRiskOther: clientPartnerCheckboxDefault,
  insuranceRiskOtherValue: "",
  // poistenie majetku
  insurancePropertyHousehold: clientPartnerCheckboxDefault,
  insurancePropertyRealty: clientPartnerCheckboxDefault,
  insuranceVehicleAccident: clientPartnerCheckboxDefault,
  insurancePropertyOther: clientPartnerCheckboxDefault,
  insurancePropertyOtherValue: "",
  // poistenie zodpovednosti
  insuranceGeneralLiability: clientPartnerCheckboxDefault,
  insurancePZP: clientPartnerCheckboxDefault,
  insuranceLiabilityProfession: clientPartnerCheckboxDefault,
  insuranceLiabilityOther: clientPartnerCheckboxDefault,
  insuranceLiabilityOtherValue: "",
  // poistene pravnej ochrany
  insuranceLegalSafetyTransport: clientPartnerCheckboxDefault,
  insuranceLegalSafetyOther: clientPartnerCheckboxDefault,
  insuranceLegalSafetyOtherValue: "",
  // cestovne poistenie
  insuranceTravel: clientPartnerCheckboxDefault,
  // poskytovanie uveru/vkladov
  financeDepositClientEntryRequests: "",
  financeDepositPartnerEntryRequests: "",
  bankDepositNoExperiences: clientPartnerCheckboxDefault,
  bankDepositMediumExperiences: clientPartnerCheckboxDefault,
  bankDepositFullExperiences: clientPartnerCheckboxDefault,
  financeOneTimeDeposit: "",
  financePeriodicDeposit: "",
  financeOneTimeDepositExpectedDuration: "",
  financePeriodicDepositExpectedDuration: "",
  financeEarlyWithdrawalPresumption: clientPartnerCheckboxDefault,
  financeDepositApplyStateAid: clientPartnerCheckboxDefault,
  financeDepositConstructionLoanAssumption: clientPartnerCheckboxDefault,
  financeDepositIntermediateLoanAssumption: clientPartnerCheckboxDefault,
  // uver
  financeLoansClientEntryRequests: "",
  financeLoansPartnerEntryRequests: "",
  loanNoExperiences: clientPartnerCheckboxDefault,
  loanMediumExperiences: clientPartnerCheckboxDefault,
  loanFullExperiences: clientPartnerCheckboxDefault,
  // ucel uveru
  loanPurposeRealEstate: clientPartnerCheckboxDefault,
  loanPurposeMovableProperty: clientPartnerCheckboxDefault,
  loanPurposeRefinanceOtherLoan: clientPartnerCheckboxDefault,
  loanRefinanceOtherLoanAmountClient: "",
  loanRefinanceOtherLoanAmountPartner: "",
  loanPurposeOther: clientPartnerCheckboxDefault,
  loanPurposeOtherAmountClient: "",
  loanPurposeOtherAmountPartner: "",
  // zabezpecenie uveru
  loanSecuringRealEstate: clientPartnerCheckboxDefault,
  loanSecuringMovableProperty: clientPartnerCheckboxDefault,
  loanSecuringMovablePropertyAmountClient: "",
  loanSecuringMovablePropertyAmountPartner: "",
  loanSecuringLiability: clientPartnerCheckboxDefault,
  loanSecuringCodebtor: clientPartnerCheckboxDefault,
  loanSecuringDebtOtherType: clientPartnerCheckboxDefault,
  loanSecuringOtherTypeClientValue: "",
  loanSecuringOtherTypePartnerValue: "",
  loanAmountOfCreditRequired: "",
  loanExpectedMaturity: "",
  loanMaximumMonthlyPaymentAmount: "",
  loanEarlyRepaymentRequired: clientPartnerCheckboxDefault,
  loanInsuranceRequired: clientPartnerCheckboxDefault,
  loanOtherRequirements: "",
  // kapitalovy trh
  capitalMarketClientEntryRequests: "",
  capitalMarketPartnerEntryRequests: "",
  tableInvestmentOrders: [],
  capitalInvestmentOrdersDate: null,
  // investicny dotaznik
  investingRiskSurveyResult: [],
  singleInvestmentPlanResult: [],
  periodicallyInvestmentPlanResult: [],
  investingSurveyChosenSolution: [],
  // navrhovane riesenia pfa
  pfaRecomendationsTable: [],
  pfaRecomendationsNotes: "",
  declarationsOfSuitabilityChecks: undefined,
  documentsPassedtoCustomers: (() => {
    const data = documentsPassedtoCustomers.map(
      (i: DocumentPassedToCustomer): DocumentPassedToCustomer => ({
        name: i.name,
        checked: false,
        label: i.label,
      })
    );

    const index = data.findIndex(({ name }) => name === "copyOfThisDocument");
    data[index].checked = true;

    return data;
  })(),

  otherDocumentsPassedToCustomersValue: "",
  // suhlas so spracovanim os. udajov
  clientPrivacyPolicy: privacyPolicyAgree.map(
    (i: PrivacyPolicy): PrivacyPolicy => ({
      name: i.name,
      checked: false,
      label: i.label,
    })
  ),
  partnerPrivacyPolicy: privacyPolicyAgree.map(
    (i: PrivacyPolicy): PrivacyPolicy => ({
      name: i.name,
      checked: false,
      label: i.label,
    })
  ),
  partnerID: "",
  // mentor
  mentorName: "",
  mentorAddress: "",
  mentorBusinessID: "",
  mentorNBS: "",
  mentorSPF: "",
  mentorID: "",
  // esg
  esgQ1Checkboxes: esgQ1Checkboxes.map(
    (i: EsgQ1Checkboxes): EsgQ1Checkboxes => ({
      name: i.name,
      checked: false,
      label: i.label,
    })
  ),
  esgQ2Checkboxes: esgQ2Checkboxes.map(
    (i: EsgQ2Checkboxes): EsgQ2Checkboxes => ({
      name: i.name,
      checked: false,
      label: i.label,
    })
  ),
  esgQ3Checkboxes: esgQ3Checkboxes.map(
    (i: EsgQ3Checkboxes): EsgQ3Checkboxes => ({
      name: i.name,
      checked: false,
      label: i.label,
    })
  ),
  esgEnviromentPercent: "",
  esgSocialPercent: "",
  esgCorporatePercent: "",
  esgNoPreferenciesPercent: "",
  esgAllPreferenciesPercent: "",
  esgAtLeastOnePreferenciesPercent: "",
  // signatureType
  signatureType: "",
};

const useZUZState = (id: string, data: Document | undefined) => {
  // return useSessionStorage(`document-zuz-saved-state-${id}`, initialState);
  const finalState = {
    _num: Math.random(),
    ...(data?.content ?? initialState),
  };

  // TODO: declare a type
  return React.useState<any>(finalState);
};

// export const getInitialState = (mode: string) => {

// };

export default useZUZState;
