import React, { ChangeEvent } from "react";

import propTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { ExternalLinkStatusKey } from "../../api/external-link.generated";
import { ExternalLinkStatusTypes } from "../../enums/ExternalLinkStatusType";

interface Properties {
  selectedOption?: ExternalLinkStatusKey;
  disabled?: boolean;
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void | undefined;
}

function SelectExternalLinkStatusType({
  selectedOption,
  onChange,
  disabled,
}: Properties) {
  const { t } = useTranslation();
  return (
    <div>
      <div className="flex text-gray-500 text-sm mb-1">
        <span>{t("app.form.status")}</span>
      </div>
      <select
        className={`block text-gray-700 mb-2 border border-gray-300 w-full p-2 rounded ${
          disabled ? "bg-gray-100 cursor-text" : ""
        }`}
        defaultValue={selectedOption}
        onChange={onChange}
        disabled={disabled}
      >
        {ExternalLinkStatusTypes.map((item) => (
          <option
            key={`index-${item.key}`}
            label={t(item.name) || ""}
            value={item.key}
          >
            <span>{t(item.name)}</span>
          </option>
        ))}
      </select>
    </div>
  );
}

SelectExternalLinkStatusType.propTypes = {
  selectedOption: propTypes.string,
};

SelectExternalLinkStatusType.defaultProps = {
  selectedOption: undefined,
  onChange: undefined,
  disabled: false,
};

export default SelectExternalLinkStatusType;
