import React, { useState } from "react";

import {
  faCheckCircle,
  faTimes,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import { parseUrl } from "../../hooks/route";
import Button from "../buttons/Button";

interface Properties {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  callbackYes: () => void;
}

function ChangeRoleInDocumentEditModal({
  showModal,
  setShowModal,
  callbackYes,
}: Properties) {
  const { t } = useTranslation();
  const location = useLocation();

  const handleCloseClick = () => {
    setShowModal(false);
  };

  const handleConfirm = () => {
    callbackYes();
  };

  const handleNoReturn = () => {
    setShowModal(false);
  };

  return (
    <div
      id="customerFormReturnModal"
      className={`${showModal ? "block" : "hidden"}`}
    >
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-normal">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/* content */}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-192 bg-white outline-none focus:outline-none">
            {/* header */}
            <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
              <div className="ml-auto">
                <button
                  type="button"
                  className="p-1 inline-flex ml-auto bg-transparent border-0 text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={handleCloseClick}
                >
                  <span className="bg-transparent h-6 w-6 text-2xl outline-none focus:outline-none flex items-center">
                    <FontAwesomeIcon icon={faTimes} />
                  </span>
                </button>
              </div>
            </div>
            {/* body */}
            <div className="relative p-6 flex-auto">
              <span>{t("app.changeRoleInDocumentEditModal.body")}</span>
            </div>
            {/* footer */}
            <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b gap-2">
              <Button
                icon={faCheckCircle}
                className="bg-green-700 hover:bg-green-600 focus:ring-green-600"
                onClick={() => handleConfirm()}
              >
                <span>{t("button.yes")}</span>
              </Button>
              <Button
                icon={faTimesCircle}
                className="bg-gray-200 hover:bg-gray-300 text-gray-800"
                onClick={() => handleNoReturn()}
              >
                <span>{t("button.no")}</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black" />
    </div>
  );
}

export default ChangeRoleInDocumentEditModal;
