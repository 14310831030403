import React, { FormEvent, useEffect, useState } from "react";

import {
  faFileSignature,
  faList,
  faLock,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  useGetCustomerByIdQuery,
  useInitiateCustomerIdentProcessMutation,
} from "../../api/customers.generated";
import {
  Customer,
  useGetIdentificationsQuery,
} from "../../api/identification.generated";
import Alert from "../../components/alerts/Alert";
import CountBadge from "../../components/badges/CountBadge";
import IdentificationStatusBadge from "../../components/badges/IdentificationStatusBadge";
import Card from "../../components/card/Card";
import CardBody from "../../components/card/CardBody";
import CardFooter from "../../components/card/CardFooter";
import CardHeader from "../../components/card/CardHeader";
import IdentificationStatusFilter from "../../components/filters/IdentificationStatusFilter";
import LevelFilter from "../../components/filters/LevelFilter";
import ConfirmModal from "../../components/modals/ConfirmModal";
import CustomerSelectModal from "../../components/modals/CustomerSelectModal";
import PageHeader from "../../components/page/PageHeader";
import IdentificationStates from "../../enums/IdentificationStates";
import useFormatters from "../../hooks/formatter";
import {
  useCustomerIdentificationValidator,
  useIdentificationQuery,
} from "../../hooks/identification";
import { getRoleId } from "../../hooks/role";
import { makeRoute, parseUrl } from "../../hooks/route";
import AllRoutes from "../route/Route";
import TableBodyLoading from "../table/TableBodyLoading";
import TableColumnHeadingButton from "../table/TableColumnHeadingButton";
import TablePageSize from "../table/TablePageSize";
import TablePagination from "../table/TablePagination";

function IdentificationsListView() {
  const { t } = useTranslation();
  const { formatTableTime } = useFormatters();
  const [query, setQuery] = useIdentificationQuery();
  const [searchValue, setSearchValue] = useState(query.keyword);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [showIdentificationModal, setShowIdentificationModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<
    Customer | undefined
  >(undefined);

  const validator = useCustomerIdentificationValidator();

  const { currentData, refetch, isFetching, isError } =
    useGetIdentificationsQuery(
      { ...query, "X-Role-Id": getRoleId() },
      {
        refetchOnMountOrArgChange: true,
      }
    );

  // set customer
  const [customer, setCustomer] = useState<Customer>();

  const parsedQueryParams = parseUrl(location.search);

  const shouldQueryCustomer = !!parsedQueryParams.customer;

  const { currentData: customerData, isSuccess: customerSuccess } =
    useGetCustomerByIdQuery(
      {
        "X-Role-Id": getRoleId(),
        id: parsedQueryParams.customer ?? "",
      },
      {
        skip: !shouldQueryCustomer,
      }
    );

  useEffect(() => {
    if (
      parsedQueryParams &&
      parsedQueryParams.customer &&
      customerSuccess &&
      shouldQueryCustomer
    ) {
      setCustomer(customerData);

      // Remove from location
      searchParams.delete("customer");

      /// Search params
      setSearchParams(searchParams);

      setShowCustomerModal(true);
    }
  }, [customerData]);

  const [initiateCustomerIdentification, initiateCustomerIdentificationState] =
    useInitiateCustomerIdentProcessMutation();

  if (isError)
    return (
      <div>
        <span>{t("app.page.error.api")}</span>
      </div>
    );

  const redirectToDetail = (id: string) => {
    const path = makeRoute(AllRoutes.IDENTIFICATION_DETAIL, {
      id,
    });
    navigate(path);
  };

  const searchFilterChange = (event: FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearchValue(value);
  };

  const searchIdentifcations = (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event.type === "keyup" &&
      (event as React.KeyboardEvent).key !== "Enter"
    ) {
      return null;
    }

    return setQuery({
      ...query,
      page: 1,
      keyword: searchValue,
    });
  };

  const onDocumentStatusFilterChanged = (name: string) => {
    const { identificationStates } = query;
    const newIdentificationStates = [...identificationStates];
    const existingIndex = newIdentificationStates.indexOf(name);
    if (existingIndex >= 0) {
      newIdentificationStates.splice(existingIndex, 1);
    } else {
      newIdentificationStates.push(name);
    }
    setQuery({
      ...query,
      page: 1,
      identificationStates: newIdentificationStates,
    });
  };

  const handleCustomerSelected = () => {
    if (!selectedCustomer) {
      return toast.warning(t("app.customer.edit.identification.start.failed"), {
        closeOnClick: true,
      });
    }

    initiateCustomerIdentification({
      "X-Role-Id": getRoleId(),
      id: selectedCustomer.id ?? "",
    })
      .catch((err) => {
        toast.error(t("app.page.error.defaultMessage") + err);
      })
      .then((response: any) => {
        if (response.error) {
          return toast.warning(
            t("app.customer.edit.identification.start.failed"),
            {
              closeOnClick: true,
            }
          );
        }
        refetch();
        return toast.success(
          t("app.customer.edit.identification.start.success")
        );
      })
      .finally(() => {
        setSelectedCustomer(undefined);
        setShowIdentificationModal(false);
      });

    return null;
  };

  const handleCreateCustomer = () => {
    return navigate(makeRoute(AllRoutes.CUSTOMERS_ADD, {}, []));
  };

  const handleEditCustomer = (row: Customer) => {
    return navigate(makeRoute(AllRoutes.CUSTOMERS_EDIT, { id: row.id }));
  };

  return (
    <>
      <PageHeader
        icon={faFileSignature}
        label={t("app.identification.list.header")}
      >
        <CustomerSelectModal
          createEditButtons
          selectedCustomerID={customer?.id || ""}
          callback={(row: Customer) => {
            setSelectedCustomer(row);
            if (validator.validateCustomer(row)) {
              setShowIdentificationModal(true);
            }
          }}
          callbackCustomerCreate={() => {
            handleCreateCustomer();
          }}
          callbackCustomerEdit={(row: Customer) => {
            handleEditCustomer(row);
          }}
          btnIcon={faLock}
          btnLabel={t("button.identificate")}
          submitBtnLabel={t("button.identificate") || ""}
          submitBtnIcon={faLock}
          modalTitle={t("app.customer-select-modal.title")}
          showInitModal={showCustomerModal}
          setShowInitModal={setShowCustomerModal}
        />
      </PageHeader>

      <Card>
        <CardHeader
          icon={faList}
          label={t("app.identification.list.card.header") || ""}
        >
          <CountBadge count={currentData?.count} />
        </CardHeader>
        <CardBody>
          <div className="flex p-4 mb-4 bg-slate-200 rounded-lg">
            <IdentificationStatusFilter
              callback={onDocumentStatusFilterChanged}
              filters={query?.identificationStates}
            />
            <LevelFilter
              filters={query?.level}
              className="ml-3"
              callback={(level) => {
                setQuery({
                  ...query,
                  page: 1,
                  level,
                });
              }}
            />
            <div className="flex ml-auto">
              <div className="flex border border-gray-300 ml-3 rounded">
                <span className="w-8 text-center text-gray-300 px-1 border-gray-300 border-r bg-white rounded-l">
                  <FontAwesomeIcon
                    icon={faSearch}
                    className="text-gray-600 mt-1.5"
                  />
                </span>
                <input
                  type="text"
                  className="border-none text-gray-700 px-4 text-sm py-1 outline-0 rounded-r"
                  placeholder={t("table.filter.name") || ""}
                  value={searchValue}
                  onKeyUp={(e) => searchIdentifcations(e)}
                  onChange={(e) => searchFilterChange(e)}
                />
              </div>
              <input
                type="button"
                className="ml-1 border border-gray-300 bg-white text-gray-700 px-4 text-sm py-1 outline-0 rounded"
                value={t("button.search") || ""}
                onClick={(e) => searchIdentifcations(e)}
              />
            </div>
          </div>
          <table>
            <thead>
              <tr>
                <td>
                  <TableColumnHeadingButton
                    field="customer"
                    label={t("table.header.customer") || ""}
                    sort={query?.sort}
                    onClick={(field, direction) => {
                      setQuery({
                        ...query,
                        page: 1,
                        sort: [`${"customer"}:${direction}`],
                      });
                    }}
                  />
                </td>

                <td>
                  <TableColumnHeadingButton
                    field="pin"
                    label={t("table.header.pin") || ""}
                    sort={query?.sort}
                  />
                </td>

                <td>
                  <TableColumnHeadingButton
                    field="id_card"
                    label={t("table.header.id_card") || ""}
                    sort={query?.sort}
                  />
                </td>

                <td>
                  <TableColumnHeadingButton
                    field="advisor"
                    label={t("table.header.advisor") || ""}
                    sort={query?.sort}
                    onClick={(field, direction) => {
                      setQuery({
                        ...query,
                        page: 1,
                        sort: [`${field}:${direction}`],
                      });
                    }}
                  />
                </td>
                <td>
                  <TableColumnHeadingButton
                    field="user"
                    label={t("table.header.user") || ""}
                    sort={query?.sort}
                    onClick={(field, direction) => {
                      setQuery({
                        ...query,
                        page: 1,
                        sort: [`${"user"}:${direction}`],
                      });
                    }}
                  />
                </td>
                <td>
                  <TableColumnHeadingButton
                    field="createdAt"
                    label={t("table.header.createdAt") || ""}
                    sort={query?.sort}
                    onClick={(field, direction) => {
                      setQuery({
                        ...query,
                        page: 1,
                        sort: [`${field}:${direction}`],
                      });
                    }}
                  />
                </td>
                <td>
                  <TableColumnHeadingButton
                    field="updatedAt"
                    label={t("table.header.updatedAt") || ""}
                    sort={query?.sort}
                    onClick={(field, direction) => {
                      setQuery({
                        ...query,
                        page: 1,
                        sort: [`${field}:${direction}`],
                      });
                    }}
                  />
                </td>
                <td>
                  <TableColumnHeadingButton
                    field="status"
                    label={t("table.header.status") || ""}
                    sort={query?.sort}
                    onClick={(field, direction) => {
                      setQuery({
                        ...query,
                        page: 1,
                        sort: [`${field}:${direction}`],
                      });
                    }}
                  />
                </td>
              </tr>
            </thead>
            {isFetching && !currentData ? (
              <TableBodyLoading cols={5} rows={query.size ?? 10} />
            ) : (
              <tbody>
                {currentData
                  ? currentData?.items?.map((identification) => {
                      return (
                        <tr
                          key={`index-${identification.id}`}
                          className="cursor-pointer"
                          onClick={() =>
                            redirectToDetail(identification.id ?? "0")
                          }
                        >
                          <td className="text-spf-primary">
                            {identification.customer
                              ? `${identification.customer.title} ${identification.customer.firstname} ${identification.customer.lastname}`.trim()
                              : ``}
                          </td>
                          <td className="text-spf-primary">
                            {identification.status?.key ===
                              IdentificationStates.DECLINED ||
                            identification.status?.key ===
                              IdentificationStates.SUCCESS_IRELEVANT ||
                            identification.status?.key ===
                              IdentificationStates.SUCCESS
                              ? `${identification.pin ?? "?"}`
                              : `-`}
                          </td>
                          <td>
                            {identification.idCardNumber
                              ? identification.idCardNumber
                              : "-"}
                          </td>
                          <td>
                            {identification.advisor
                              ? `${identification.advisor?.firstname}  ${identification.advisor?.lastname}`
                              : ``}
                          </td>
                          <td className="text-spf-primary">
                            {identification.user?.displayName}
                          </td>
                          <td>
                            {identification.createdAt
                              ? `${formatTableTime(identification.createdAt)}`
                              : ``}
                          </td>
                          <td>
                            {identification.updatedAt
                              ? `${formatTableTime(identification.updatedAt)}`
                              : ``}
                          </td>
                          <td>
                            <IdentificationStatusBadge
                              status={identification.status}
                            />
                          </td>
                        </tr>
                      );
                    })
                  : t("table.noData")}
              </tbody>
            )}
          </table>
          {!isFetching && currentData?.count === 0 ? (
            <Alert className="mt-4">
              <span>{t("table.noData")}</span>
            </Alert>
          ) : (
            <span />
          )}
        </CardBody>
        <CardFooter>
          <div className="">
            <TablePagination
              callback={setQuery}
              query={query}
              count={currentData?.count ?? 0}
            />
          </div>
          <div className="ml-auto">
            <TablePageSize query={query} callback={setQuery} />
          </div>
        </CardFooter>
      </Card>
      <ConfirmModal
        showModal={showIdentificationModal}
        setShowModal={setShowIdentificationModal}
        text={t("modal.identificationConfirm")}
        confirmBtnText={t("button.yes")}
        noBtnText={t("button.cancel")}
        actionConfirm={() => {
          handleCustomerSelected();
        }}
        actionInProgress={initiateCustomerIdentificationState.isLoading}
        actionNo={() => setShowIdentificationModal(false)}
        showX
      />
    </>
  );
}

export default IdentificationsListView;
