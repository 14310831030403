import React, { useState } from "react";

import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import { useGetUsersQuery, User } from "../../api/users.generated";
import UserStatusType from "../../enums/UserStatusType";
import TablePagination from "../../features/table/TablePagination";
import { getRoleId } from "../../hooks/role";
import Button from "../buttons/Button";
import ChangeButton from "../buttons/ChangeButton";

interface Properties {
  callback: (row: User) => void;
  selectedUser?: User;
}

export default function UserSelectModal({
  selectedUser,
  callback,
}: Properties) {
  const { t } = useTranslation();
  const [showModal, setShowModal] = React.useState(false);
  const [query, setQuery] = useState({
    size: 10,
    sort: ["lastname", "asc"],
    userStates: [UserStatusType.ACTIVE],
    page: 1,
    keyword: "",
  });

  const { currentData, isFetching, isError } = useGetUsersQuery({
    ...query,
    "X-Role-Id": getRoleId(),
  });
  const [searchValue, setSearchValue] = useState("");
  const [selectedRow, setSelectedRow] = useState(selectedUser);

  const users = currentData?.items;

  const setSelectedUser = (user: User): void => {
    setSelectedRow(user);
  };

  const selectUserAndClose = (user: User): void => {
    setSelectedRow(user);
    handleChange();
  };

  const searchFilterChange = (event: any) => {
    const { value } = event.target;
    setSearchValue(value);
    if (value.length > 2 || value.length === 0) {
      setQuery({
        ...query,
        keyword: value,
        page: 1,
      });
    }
  };

  const handleChange = () => {
    setShowModal(false);
    if (selectedRow) {
      callback(selectedRow);
      setSelectedRow(undefined);
    }
  };

  const handleCloseClick = () => {
    setSelectedRow(undefined);
    setShowModal(false);
  };

  return (
    <div>
      <div className="py-2 px-2 flex">
        <ChangeButton
          onClick={() => setShowModal(true)}
          className="text-sm px-2 py-1 ml-auto"
        />
      </div>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-normal">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/* content */}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/* header */}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-2xl font-semibold">
                    <span>{t("app.user-select-modal.title")}</span>
                  </h3>
                  <div className="border border-gray-400 ml-3 flex self-center">
                    <span className="inline-block text-gray-300 px-1">
                      <FontAwesomeIcon icon={faSearch} />
                    </span>
                    <input
                      type="text"
                      className="border-none text-gray-700 px-1 text-sm py-1 outline-0"
                      placeholder=""
                      value={searchValue}
                      onChange={(e) => searchFilterChange(e)}
                    />
                  </div>
                  <button
                    type="button"
                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => handleCloseClick()}
                  >
                    <span className="bg-transparent text-black h-6 w-6 text-2xl outline-none focus:outline-none flex items-center">
                      ×
                    </span>
                  </button>
                </div>
                {/* body */}
                <div className="relative p-6 pt-2 flex-auto">
                  <table className="mt-5 text-sm">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isFetching ? t("app.page.loading") : ""}
                      {isError ? "Error..." : ""}
                      {users?.length === 0 ? (
                        <div>
                          <span>{t("table.noData")}</span>
                        </div>
                      ) : (
                        ""
                      )}
                      {users?.map((user: User) => (
                        <tr
                          className={
                            user === selectedRow
                              ? "cursor-pointer bg-green-400 hover:bg-green-400"
                              : "cursor-pointer bg-white"
                          }
                          key={user.id}
                          onClick={() => setSelectedUser(user)}
                          onDoubleClick={() => selectUserAndClose(user)}
                        >
                          <td>{user.displayName}</td>
                          <td>{user.email}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="mt-4 flex px-4 py-2 border-t border-gray-300">
                    <div className="">
                      <TablePagination
                        callback={setQuery}
                        query={query}
                        count={currentData?.count ?? 0}
                      />
                    </div>
                  </div>
                </div>
                {/* footer */}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  {selectedRow !== selectedUser ? (
                    <Button className="mr-2" onClick={() => handleChange()}>
                      <span>{t("button.change")}</span>
                    </Button>
                  ) : (
                    ""
                  )}
                  <Button onClick={() => handleCloseClick()}>
                    <span>{t("button.close")}</span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black" />
        </>
      ) : null}
    </div>
  );
}

UserSelectModal.defaultProps = {
  selectedUser: undefined,
};
