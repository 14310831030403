import React from "react";

import { BrowserRouter as Router, RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import ApplicationRouter from "./features/application-router/ApplicationRouter";

function App() {
  return (
    <>
      <ToastContainer
        position="top-center"
        newestOnTop={false}
        hideProgressBar={false}
        draggable={false}
        autoClose={5000}
        closeButton={false}
      />
      <RouterProvider router={ApplicationRouter} />
    </>
  );
}

export default App;
