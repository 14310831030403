import React, { useEffect, useState } from "react";

import {
  faFileSignature,
  faList,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  DocumentTypeKey,
  useGetDocumentsQuery,
} from "../../api/documents.generated";
import Alert from "../../components/alerts/Alert";
import CountBadge from "../../components/badges/CountBadge";
import DocumentStatusBadge from "../../components/badges/DocumentStatusBadge";
import AddButton from "../../components/buttons/AddButton";
import Card from "../../components/card/Card";
import CardBody from "../../components/card/CardBody";
import CardFooter from "../../components/card/CardFooter";
import CardHeader from "../../components/card/CardHeader";
import DocumentStatusFilter from "../../components/filters/DocumentStatusFilter";
import LevelFilter from "../../components/filters/LevelFilter";
import AdvisorSelectModal from "../../components/modals/AdvisorSelectModal";
import PageHeader from "../../components/page/PageHeader";
import { DocumentTypeKeys } from "../../enums/DocumentTypes";
import Roles from "../../enums/Roles";
import { useHasValidNbsLicence } from "../../hooks/advisor";
import { useDocumentQuery } from "../../hooks/documents";
import useFormatters from "../../hooks/formatter";
import { getRoleId } from "../../hooks/role";
import { makeRoute } from "../../hooks/route";
import { useAppSelector } from "../../hooks/store";
import { authSelector } from "../auth/authSlice";
import AllRoutes from "../route/Route";
import TableBodyLoading from "../table/TableBodyLoading";
import TableColumnHeadingButton from "../table/TableColumnHeadingButton";
import TablePageSize from "../table/TablePageSize";
import TablePagination from "../table/TablePagination";
import DocumentListCustomers from "./DocumentListCustomers";

interface Properties {
  type: string;
}

function DocumentsListView({ type }: Properties) {
  const { t } = useTranslation();
  const { formatTableTime } = useFormatters();
  const [query, setQuery] = useDocumentQuery(type as DocumentTypeKey);
  const [searchValue, setSearchValue] = useState(query.keyword);
  const [showSelectAdvisorModal, setShowSelectAdvisorModal] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const { currentRole } = useAppSelector(authSelector);
  const hasValidNbsLicence = useHasValidNbsLicence();

  useEffect(() => {
    if (type === DocumentTypeKeys.CHECK_FORM) {
      setQuery({
        ...query,
        // stop skipping query
        applySkip: false,
      });
    }
    if (type === DocumentTypeKeys.ZUZ) {
      setQuery({
        ...query,
        // stop skipping query
        applySkip: false,
      });
    }
  }, [type]);

  const { currentData, isFetching, isError } = useGetDocumentsQuery(
    { ...query, "X-Role-Id": getRoleId() },
    {
      refetchOnMountOrArgChange: true,
      skip: query.applySkip,
    }
  );

  if (isError)
    return (
      <div>
        <span>{t("app.page.error.api")}</span>
      </div>
    );

  const redirectToDetail = (id: string, documentType: string): void => {
    const route =
      documentType === DocumentTypeKeys.ZUZ
        ? AllRoutes.DOCUMENT_VIEW_ZUZ
        : AllRoutes.DOCUMENT_VIEW_CHECK;
    const path = makeRoute(route, {
      id,
    });
    navigate(path);
  };

  const onDocumentStatusFilterChanged = (name: string) => {
    const { documentStates } = query;
    const newDocumentStates = [...documentStates];
    const existingIndex = newDocumentStates.indexOf(name);
    if (existingIndex >= 0) {
      newDocumentStates.splice(existingIndex, 1);
    } else {
      newDocumentStates.push(name);
    }

    setQuery({
      ...query,
      page: 1,
      documentStates: newDocumentStates,
    });
  };

  const handleAddNewDocument = () => {
    if (type === DocumentTypeKeys.CHECK_FORM) {
      const path = makeRoute(AllRoutes.DOCUMENTS_ADD_CHECK);
      navigate(path);
    }

    if (type === DocumentTypeKeys.ZUZ) {
      if (currentRole?.role?.key === Roles.CENTRAL) {
        setShowSelectAdvisorModal(true);
        return;
      }

      // if current role is advisor, check for valid nbs license (not empty = valid)
      if (currentRole?.role?.key === Roles.ADVISOR && !hasValidNbsLicence) {
        toast.warn(t("document.validation.common.validNBSLicense"));

        return;
      }

      const path = makeRoute(AllRoutes.DOCUMENTS_ADD_ZUZ);
      navigate(path);
    }
  };

  const searchFilterChange = (event: any) => {
    const { value } = event.target;
    setSearchValue(value);
  };

  const searchDocuments = (event: any) => {
    if (event.type === "keyup" && event.keyCode !== 13) {
      return null;
    }

    return setQuery({
      ...query,
      page: 1,
      keyword: searchValue,
    });
  };

  return (
    <>
      <PageHeader
        label={
          type === DocumentTypeKeys.ZUZ
            ? t("app.page.documents.ezuz.heading")
            : t("app.page.documents.check.heading")
        }
        icon={faFileSignature}
      >
        <AddButton onClick={handleAddNewDocument} />
      </PageHeader>

      <Card>
        <CardHeader label={t("card.header.list") || ""} icon={faList}>
          <CountBadge count={currentData?.count} />
        </CardHeader>
        <CardBody>
          <div className="flex p-4 mb-4 bg-slate-200 rounded-lg">
            <DocumentStatusFilter
              callback={onDocumentStatusFilterChanged}
              filters={query?.documentStates}
              docType={
                type === DocumentTypeKeys.ZUZ
                  ? DocumentTypeKeys.ZUZ
                  : DocumentTypeKeys.CHECK_FORM
              }
            />
            <LevelFilter
              filters={query?.level}
              className="ml-3"
              callback={(level) => {
                setQuery({
                  ...query,
                  page: 1,
                  level,
                });
              }}
            />
            <div className="flex ml-auto">
              <div className="flex border border-gray-300 ml-3 rounded">
                <span className="w-8 text-center text-gray-300 px-1 border-gray-300 border-r bg-white rounded-l">
                  <FontAwesomeIcon
                    icon={faSearch}
                    className="text-gray-600 mt-1.5"
                  />
                </span>
                <input
                  type="text"
                  className="border-none text-gray-700 px-4 text-sm py-1 outline-0 rounded-r"
                  placeholder={t("table.filter.name") || ""}
                  value={searchValue}
                  onKeyUp={(e) => searchDocuments(e)}
                  onChange={(e) => searchFilterChange(e)}
                  data-testid="/documents/list/input-search"
                />
              </div>
              <input
                type="button"
                className="ml-1 border border-gray-300 bg-white text-gray-700 px-4 text-sm py-1 outline-0 rounded"
                value={t("button.search") as string}
                onClick={(e) => searchDocuments(e)}
                data-testid="/documents/list/btn-search"
              />
            </div>
          </div>

          <table>
            <thead>
              <tr>
                <td>
                  <TableColumnHeadingButton
                    field="displayId"
                    label="ID"
                    sort={query?.sort}
                    onClick={(field, direction) => {
                      setQuery({
                        ...query,
                        page: 1,
                        sort: [`${field}:${direction}`],
                      });
                    }}
                  />
                </td>
                <td>
                  <TableColumnHeadingButton
                    field="customer"
                    label={t("table.header.customer") || ""}
                    sort={query?.sort}
                    onClick={(field, direction) => {
                      setQuery({
                        ...query,
                        page: 1,
                        sort: [`${field}:${direction}`],
                      });
                    }}
                  />
                </td>
                <td>
                  <TableColumnHeadingButton
                    label={t("table.header.advisor") || ""}
                    field="advisor"
                    sort={query?.sort}
                    onClick={(field, direction) => {
                      setQuery({
                        ...query,
                        page: 1,
                        sort: [`${field}:${direction}`],
                      });
                    }}
                  />
                </td>
                <td>
                  <TableColumnHeadingButton
                    label={t("table.header.status") || ""}
                    field="status"
                    sort={query?.sort}
                    onClick={(field, direction) => {
                      setQuery({
                        ...query,
                        page: 1,
                        sort: [`${field}:${direction}`],
                      });
                    }}
                  />
                </td>
                <td>
                  <TableColumnHeadingButton
                    field="createdAt"
                    label={t("table.header.createdAt") || ""}
                    sort={query?.sort}
                    onClick={(field, direction) => {
                      setQuery({
                        ...query,
                        page: 1,
                        sort: [`${field}:${direction}`],
                      });
                    }}
                  />
                </td>
                <td>
                  <TableColumnHeadingButton
                    field="updatedAt"
                    label={t("table.header.updatedAt") || ""}
                    sort={query?.sort}
                    onClick={(field, direction) => {
                      setQuery({
                        ...query,
                        page: 1,
                        sort: [`${field}:${direction}`],
                      });
                    }}
                  />
                </td>
              </tr>
            </thead>
            {isFetching && !currentData ? (
              <TableBodyLoading cols={6} rows={query?.size ?? 10} />
            ) : (
              <tbody>
                {currentData ? (
                  currentData?.items?.map((document) => (
                    <tr
                      key={`index-${document?.id}-${Math.random()}`}
                      className="cursor-pointer"
                      onClick={() =>
                        redirectToDetail(
                          document.id ?? "0",
                          document.type?.key ?? ""
                        )
                      }
                    >
                      <td>{`#${document.displayID}`}</td>
                      <td className="text-spf-primary">
                        <DocumentListCustomers customers={document.customers} />
                      </td>
                      <td>
                        {document.advisor
                          ? `${document?.advisor?.firstname}  ${document?.advisor?.lastname}`
                          : ``}
                      </td>
                      <td>
                        <DocumentStatusBadge status={document.status} />
                      </td>
                      <td>{formatTableTime(document.createdAt)}</td>
                      <td>{formatTableTime(document.updatedAt)}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={6}>
                      <span>{t("table.noData")}</span>
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
          {!isFetching && currentData?.count === 0 ? (
            <Alert className="mt-4">
              <span>{t("table.noData")}</span>
            </Alert>
          ) : (
            ""
          )}
        </CardBody>
        <CardFooter>
          <TablePagination
            callback={setQuery}
            query={query}
            count={currentData?.count ?? 0}
          />
          <div className="ml-auto">
            <TablePageSize query={query} callback={setQuery} />
          </div>
        </CardFooter>
      </Card>
      <AdvisorSelectModal
        forceShowModal={showSelectAdvisorModal}
        onModalClose={() => setShowSelectAdvisorModal(false)}
        callback={(advisor) => {
          const path = makeRoute(AllRoutes.DOCUMENTS_ADD_ZUZ, undefined, [
            { name: "advisorId", value: advisor.id ?? "" },
          ]);
          navigate(path);
        }}
        initialQuery={{
          page: 1,
          size: 10,
          sort: ["lastname", "asc"],
          keyword: "",
          hasValidNbsLicence: true,
        }}
      />
    </>
  );
}

export default DocumentsListView;
