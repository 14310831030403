import { baseSplitApi as api } from "./baseApi";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    seedAdvisors: build.mutation<SeedAdvisorsApiResponse, SeedAdvisorsApiArg>({
      query: (queryArg) => ({
        url: `/v1/seeder/advisors/import-local`,
        method: "POST",
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    seedCustomers: build.mutation<
      SeedCustomersApiResponse,
      SeedCustomersApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/seeder/customers/import-local`,
        method: "POST",
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as spfApi };
export type SeedAdvisorsApiResponse = /** status 200 OK */ SuccessResponse200;
export type SeedAdvisorsApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
};
export type SeedCustomersApiResponse = /** status 200 OK */ SuccessResponse200;
export type SeedCustomersApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
};
export type SuccessResponse200 = {
  id?: string;
  status?: string;
};
export type ValidationError = {
  code?: string;
  field?: string;
  message?: string;
};
export type ErrorResponse400 = {
  code?: number;
  data?: ValidationError[];
  message?: string;
};
export type ErrorResponse401 = {
  code?: number;
  data?: {
    [key: string]: string;
  }[];
  message?: string;
};
export type ErrorResponse500 = {
  code?: number;
  data?: {
    [key: string]: string;
  }[];
  message?: string;
};
export const { useSeedAdvisorsMutation, useSeedCustomersMutation } =
  injectedRtkApi;
