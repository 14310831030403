import React from "react";

import { IconDefinition } from "@fortawesome/free-solid-svg-icons";

import CardHeader from "./CardHeader";

interface Properties {
  label?: string;
  icon?: IconDefinition;
  className?: string | undefined;
  children?: React.ReactNode;
}

function Card({ className, label, icon, children }: Properties) {
  return (
    <div className={`bg-white border border-gray-200 rounded ${className} `}>
      {label ? <CardHeader icon={icon || undefined} label={label} /> : ""}
      {children}
    </div>
  );
}

Card.defaultProps = {
  className: "",
  children: undefined,
  icon: undefined,
  label: undefined,
};

export default Card;
