/* eslint-disable react/jsx-no-useless-fragment */
import React, { CSSProperties, useEffect, useState } from "react";

import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { decode } from "html-entities";

import { DocumentContentTypeTables } from "../../models/DocumentContent";

interface HeadingMainProperties {
  title: string;
  colspan?: number;
}

interface HeadingProperties {
  title: string;
  className?: string;
  textClass?: string;
  style?: CSSProperties;
}

interface Properties {
  tableName: DocumentContentTypeTables;
  heading: HeadingProperties[];
  headingMain?: HeadingMainProperties[];
  values?: (string | number)[][];
  rows?: number;
  addRows?: boolean;
  sideNotEditableColumn?: string[];
  showHeading?: boolean;
  errors?: Array<Array<string | undefined>>;
  onChange: (
    tableName: DocumentContentTypeTables,
    values: (string | number)[][]
  ) => void | undefined;
}

function FormTable({
  tableName,
  headingMain,
  heading,
  values,
  rows,
  sideNotEditableColumn,
  addRows,
  showHeading,
  onChange,
  errors,
}: Properties) {
  const [tableInputs, setTableInputs] = useState<(string | number)[][]>(
    values ?? []
  );

  useEffect(() => {
    if (rows && values && values.length === 0) {
      let totalRows = rows - values.length;
      const newValues = [...values];
      do {
        const newArray = Array(heading.length).fill("");
        newValues?.push(newArray);
        totalRows -= 1;
      } while (totalRows > 0);
      setTableInputs(newValues);
    } else if (rows && values && values.length > 0) {
      const newValues = [...values];
      setTableInputs(newValues);
    }
  }, [rows, heading, values]);

  const onChangeValue = (
    row: number,
    column: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValues = [...tableInputs];
    const newRowValues = [...tableInputs[row]];
    const { value } = e.target;
    newRowValues[column] = value;
    newValues[row] = newRowValues;
    onChange(tableName, newValues);
    setTableInputs(newValues);
  };

  const getValue = (i: number, j: number): string | number => {
    const value = tableInputs[i][j];

    return typeof value === "string" ? decode(value) : value;
  };

  return (
    <div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <table className="table-auto">
            <tbody>
              {headingMain && showHeading && headingMain.length > 0 ? (
                <tr key="rdycftvgybhjnk">
                  {sideNotEditableColumn && sideNotEditableColumn.length > 0 ? (
                    <td className="bg-white" />
                  ) : (
                    <></>
                  )}
                  {headingMain.map((i) => (
                    <td
                      key={i.title}
                      className="border border-slate-400"
                      colSpan={i.colspan ?? 1}
                    >
                      <div className="text-center">{i.title}</div>
                    </td>
                  ))}
                </tr>
              ) : (
                <></>
              )}
              <tr key="4d5rf6t7gyh8uij" className="bg-gray-50">
                {sideNotEditableColumn && sideNotEditableColumn.length > 0 ? (
                  <td key="drfctvgybhnj" className="bg-white" />
                ) : (
                  <></>
                )}
                {showHeading &&
                  heading.map((i) => (
                    <td
                      key={`5f6tg7h8ju9i-${i.title}-${Math.random()}`}
                      className={`border border-slate-400 ${i.className ?? ""}`}
                      style={i.style ?? {}}
                    >
                      <div className={i.textClass ?? ""}>{i.title}</div>
                    </td>
                  ))}
              </tr>
              {tableInputs?.map((i, row) => (
                // eslint-disable-next-line react/no-array-index-key
                <tr key={`tr-index-${row}`}>
                  {sideNotEditableColumn &&
                  sideNotEditableColumn.length > 0 &&
                  sideNotEditableColumn[row] ? (
                    <td key="ctfvgbhjnk" className="border border-slate-400">
                      <b>{sideNotEditableColumn[row]}</b>
                    </td>
                  ) : (
                    <></>
                  )}
                  {i.map((j, column) => (
                    <td
                      // eslint-disable-next-line react/no-array-index-key
                      key={`td-index-${column}`}
                      className="border border-slate-400"
                    >
                      <input
                        type="text"
                        className="w-11/12"
                        value={getValue(row, column)}
                        onChange={(e) => onChangeValue(row, column, e)}
                      />
                      {errors?.[row]?.[column] ? (
                        <p className="text-red-400 text-sm">
                          {errors?.[row]?.[column]}
                        </p>
                      ) : undefined}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <div>
            {addRows ? (
              <FontAwesomeIcon
                icon={faPlusSquare}
                className="cursor-pointer text-slate-800 hover:text-slate-400"
                onClick={() => {
                  const newValues = [...tableInputs];
                  newValues?.push(Array(heading.length).fill(""));
                  if (newValues.length > 0) {
                    setTableInputs(newValues);
                  }
                }}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

FormTable.defaultProps = {
  values: [],
  rows: 5,
  headingMain: [],
  addRows: false,
  sideNotEditableColumn: [],
  errors: [],
  showHeading: true,
};

export default FormTable;
