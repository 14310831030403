import React from "react";

import {
  faChevronCircleDown,
  faChevronCircleUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { currentSortField, invertDirection } from "../../hooks/sort";

interface ComponentProperties {
  field: string;
  label?: string;
  sort?: string[];
  isDefault?: boolean;
  onClick?: (field: string, direction: string) => void | undefined;
}

function TableColumnHeadingButton({
  sort,
  field,
  label,
  isDefault,
  onClick,
}: ComponentProperties): JSX.Element {
  // Handle direction
  const direction = sort ? sort[0]?.split(":")[1] ?? "" : "";
  let realDirection = "desc";
  if (direction === "asc") {
    realDirection = "asc";
  }

  // Handle isDefault
  const realIsDefault = isDefault === undefined || isDefault === true;

  // Handle label
  const realLabel = label || field.toLocaleUpperCase();
  return (
    <button
      type="button"
      className={
        `hover:text-gray-600 transition-colors duration-500 ` +
        `${onClick ? "cursor-pointer" : "cursor-default"}`
      }
      onClick={() => {
        if (onClick) {
          onClick(field, invertDirection(sort ?? []));
        }
      }}
    >
      {realLabel}

      {
        // eslint-disable-next-line no-nested-ternary
        onClick ? (
          currentSortField(sort ?? []) === field ||
          ((sort ?? []).length === 0 && realIsDefault) ? (
            <FontAwesomeIcon
              icon={
                realDirection === "desc"
                  ? faChevronCircleDown
                  : faChevronCircleUp
              }
              style={{
                position: "relative",
                top: "0px",
              }}
              className="ml-2 text-primary"
            />
          ) : (
            ""
          )
        ) : (
          ""
        )
      }
    </button>
  );
}

TableColumnHeadingButton.defaultProps = {
  label: "",
  sort: [],
  onClick: undefined,
  isDefault: false,
};

export default TableColumnHeadingButton;
