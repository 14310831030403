import React from "react";

type Props = {
  title: string;
  children?: JSX.Element | string | (JSX.Element | string)[];
};

function PageCardHeading({ title, children }: Props) {
  return (
    <div
      className="flex w-full h-12 px-4 py-2 mx-2 first:mx-0 text-white text-sm bg-slate-500 rounded"
      data-testid={`/documenst/zuz/card-${title.toLowerCase()}`}
    >
      <div className="flex flex-col justify-center sfont-semibold">{title}</div>
      <div className="ml-auto flex gap-4">{children}</div>
    </div>
  );
}

PageCardHeading.defaultProps = {
  children: "",
};

export default PageCardHeading;
