import React, { useContext } from "react";

import {
  faPlus,
  faRightLeft,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

import Button from "../../../components/buttons/Button";
import Input from "../../../components/inputs/Input";
import AdvisorListModal from "../../../components/modals/AdvisorListModal";
import SignTypes from "../../../enums/SignTypes";
import { Document } from "../../../models/Document";
import DocumentZuzContext from "../DocumentZuzFormViewContext";

interface Properties {
  document: Document;
  setDocument: React.Dispatch<Document>;
}

function SignatureTypeCard({ document, setDocument }: Properties) {
  const { t } = useTranslation();
  const ctx = useContext(DocumentZuzContext);
  const { message, valid } = ctx?.validationErrors?.find(
    (error) => error.field === "signatureType"
  ) || { message: "", valid: true };

  return (
    <div className="grid grid-cols-2 gap-4">
      <div className="flex flex-col gap-4">
        <div className="flex w-full h-12 px-4 py-2 mx-2 first:mx-0 text-white text-sm bg-slate-500 rounded">
          <div className="flex flex-col justify-center sfont-semibold">
            Typ overenia
          </div>
        </div>
        <div
          className={`pb-2 bg-white ${valid ? "" : "border-red-400"} rounded `}
        >
          {SignTypes.map((item) => (
            <div className="px-2 pt-2" key={`sign-type-div-${item.key}`}>
              <input
                className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                id={item.key}
                key={`sign-type-${item.key}`}
                type="checkbox"
                name="signatureType"
                value={item.key}
                checked={
                  document?.content.signatureType === item.key ||
                  document.signatureType === item.key
                }
                onChange={(event) => {
                  ctx.resetFieldError("signatureType");
                  setDocument({
                    ...document,
                    content: {
                      ...document.content,
                      signatureType: event.currentTarget.value as string,
                    },
                  });
                }}
                data-testid={`/documents/zuz/input-${item.key}`}
              />
              <label className="px-1" htmlFor={item.key}>
                <span>{t(item.name)}</span>
              </label>
            </div>
          ))}
        </div>
        {!valid ? <p className="text-red-400">{message}</p> : null}
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex w-full h-12 px-4 py-2 mx-2 first:mx-0 text-white text-sm bg-slate-500 rounded">
          <div className="flex flex-col justify-center sfont-semibold">
            <span>{t("app.section.shareToAdvisor")}</span>
          </div>
          <div className="ml-auto flex gap-4">
            {document.sharedAdvisor ? (
              <Button
                icon={faTimes}
                className="text-sm px-2 py-1 ml-auto bg-slate-700 hover:bg-slate-600 focus:ring-slate-600"
                onClick={() => {
                  setDocument({
                    ...document,
                    sharedAdvisor: undefined,
                  });
                }}
              >
                <span>{t("button.remove")}</span>
              </Button>
            ) : null}
            <div className="inline-flex">
              <AdvisorListModal
                selectedAdvisor={undefined}
                label={
                  t(
                    document.sharedAdvisor
                      ? "button.change"
                      : "button.advisorSelect"
                  ) ?? ""
                }
                labelIcon={document.sharedAdvisor ? faRightLeft : faPlus}
                callback={(advisor) => {
                  setDocument({
                    ...document,
                    sharedAdvisor: advisor,
                  });
                }}
                initialQuery={{
                  page: 1,
                  size: 10,
                  sort: ["lastname", "asc"],
                  keyword: "",
                }}
              />
            </div>
          </div>
        </div>
        <div>
          <Input
            label="Poradca"
            name="share"
            placeholder=""
            value={
              document?.sharedAdvisor
                ? `${document.sharedAdvisor.displayName} (${document.sharedAdvisor.email})`
                : "Zatiaľ nie je vybraný žiaden poradca"
            }
            disabled
          />
        </div>
      </div>
    </div>
  );
}

export default SignatureTypeCard;
