import React from "react";

import { t } from "i18next";
import { ErrorBoundary } from "react-error-boundary";
import { useIdleTimer } from "react-idle-timer";
import { Navigate, Outlet } from "react-router";
import { toast } from "react-toastify";

import { useAppSelector, useAppDispatch } from "../../hooks/store";
import { useUserIdleTimer } from "../../hooks/userIdleTimer";
import { authSelector, logoutUser } from "../auth/authSlice";
import Error404View from "../error/Error404View";
import ErrorView from "../error/ErrorView";
import PageLoader from "../loader/PageLoader";
import AllRoutes from "../route/Route";
import Footer from "./Footer";
import Header from "./Header";
import { PrivateContentProvider } from "./PrivateContentContext";
import Sidebar from "./Sidebar";

// TODO: Someday in the future implement something meaningful here
const myErrorHandler = (error: Error, info: { componentStack: string }) => {
  // Do something with the error
  // E.g. log to an error logging client here
};

function PrivateContent(): JSX.Element {
  const dispatch = useAppDispatch();
  const { isInitialized, isLoggedIn, error } = useAppSelector(authSelector);

  const handleOnIdle = async () => {
    if (isLoggedIn) {
      toast.info(t("app.loggedOutDueToInactivity") || "");
      dispatch(logoutUser());
    }
  };

  const timeout = useUserIdleTimer();

  useIdleTimer({
    timeout,
    onIdle: handleOnIdle,
  });

  // Redirect to the login page
  if (isInitialized && !isLoggedIn) {
    return <Navigate to={AllRoutes.AUTH_LOGIN.path} />;
  }

  // If we did not load the profile info or we don't have the role information
  // available we want to prevent fast re-renderings
  if (!isInitialized) {
    return <PageLoader />;
  }

  return (
    <PrivateContentProvider>
      <div className="bg-gray-100 flex flex-col h-screen overflow-hidden">
        <Header />
        <div className="flex-grow flex h-full">
          <Sidebar />
          <main className="flex-grow flex flex-col overflow-y-auto">
            <div className="flex-grow p-4 lg:p-8">
              <ErrorBoundary
                FallbackComponent={ErrorView}
                onError={myErrorHandler}
              >
                {error?.message ? <Error404View /> : <Outlet />}
              </ErrorBoundary>
            </div>
            <Footer />
          </main>
        </div>
      </div>
    </PrivateContentProvider>
  );
}

export default PrivateContent;
