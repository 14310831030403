import React from "react";

import { useTranslation } from "react-i18next";

import { useDeleteDocumentByIdMutation } from "../../api/documents.generated";
import Button from "../buttons/Button";
import DeleteButton from "../buttons/DeleteButton";

interface Properties {
  documentId: string;
  onDeleteDocument: () => void;
}

export default function DeleteDocumentModal({
  documentId,
  onDeleteDocument,
}: Properties) {
  const { t } = useTranslation();
  const [showModal, setShowModal] = React.useState(false);

  const [deleteDocument] = useDeleteDocumentByIdMutation();

  const handleConfirm = () => {
    setShowModal(false);
    deleteDocument({
      id: documentId,
    });
    onDeleteDocument();
  };

  return (
    <>
      <DeleteButton onClick={() => setShowModal(true)} />
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/* content */}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/* header */}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    <span>{t("modal.deleteDocument.heading")}</span>
                  </h3>
                  <button
                    type="button"
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/* body */}
                <div className="relative p-6 flex-auto">
                  <p className="my-4 text-blueGray-500 text-lg leading-relaxed">
                    <span>{t("modal.deleteDocument.text")}</span>
                  </p>
                </div>
                {/* footer */}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <Button className="mr-2" onClick={() => handleConfirm()}>
                    <span>{t("button.yes")}</span>
                  </Button>
                  <Button onClick={() => setShowModal(false)}>
                    <span>{t("button.no")}</span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black" />
        </>
      ) : null}
    </>
  );
}
