enum IdentificationStates {
  INITIATED = "is-initiated",
  REFUSED = "is-refused",
  ERROR = "is-error",
  SUCCESS = "is-success",
  SUCCESS_IRELEVANT = "is-success-irrelevant",
  DECLINED = "is-declined",
}

export default IdentificationStates;
