import React from "react";

import { faCheckCircle, faStopCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import { RoleUserStatusKey } from "../../api/permissionRule.generated";
import UserRoleStatusType from "../../enums/UserRoleStatusType";

interface Properties {
  statusKey?: RoleUserStatusKey;
  callback: (status: RoleUserStatusKey) => void | undefined;
}

function UserRoleStatusButton({ statusKey, callback }: Properties) {
  const { t } = useTranslation();
  let bgClass = "bg-spf-green";
  let color = "text-stone-100";
  let icon = faCheckCircle;
  let newStatusKey = UserRoleStatusType.DEACTIVATED;
  if (statusKey === UserRoleStatusType.ACTIVE) {
    bgClass = "bg-spf-green";
    color = "text-stone-100";
    icon = faCheckCircle;
    newStatusKey = UserRoleStatusType.DEACTIVATED;
  }
  if (statusKey === UserRoleStatusType.DEACTIVATED) {
    bgClass = "bg-slate-400";
    color = "text-stone-100";
    icon = faStopCircle;
    newStatusKey = UserRoleStatusType.ACTIVE;
  }
  return (
    <div
      className={`inline-block cursor-pointer px-3 py-1 rounded-full border ${bgClass} ${color}`}
      onClick={() => {
        callback(newStatusKey);
      }}
      aria-hidden="true"
    >
      <FontAwesomeIcon icon={icon} className="mr-2" />
      <span>{t(`status.${statusKey}`)}</span>
    </div>
  );
}

UserRoleStatusButton.defaultProps = {
  statusKey: "",
};

export default UserRoleStatusButton;
