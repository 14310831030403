/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext } from "react";

import Card from "../../../components/card/Card";
import ClientPartnerCheckbox from "../../../components/inputs/ClientPartnerCheckbox";
import ClientPartnerRadio from "../../../components/inputs/ClientPartnerRadio";
import { Document } from "../../../models/Document";
import DocumentZuzContext from "../DocumentZuzFormViewContext";
import DocumentInput from "./DocumentInput";

interface Properties {
  document: Document;
  setDocument: React.Dispatch<Document>;
}

function Page03IncomeCard({ document, setDocument }: Properties) {
  const ctx = useContext(DocumentZuzContext);
  return (
    <div>
      <Card className="mb-8 px-4">
        <div className="py-3 mb-6">
          {/* <div className="w-full px-3 py-2 mb-4 font-bold bg-slate-200 ">
                  Sprostredkovanie finančných služieb v sektore Prijímania
                  vkladov
                </div> */}
          <h3 className="text-base font-bold my-2">
            Vstupné informácie (podľa § 35 zákona č. 186/2009 Z.z.)
          </h3>
          {/* poziadavky start */}
          <div className="mb-4 px-3">
            <h4 className="text-base font-bold my-2">
              Požiadavky a potreby vyslovené Klientom / Partnerom
            </h4>
            <div className="flex">
              <div className="w-1/2 mb-4 pr-2 inline-block">
                <DocumentInput
                  document={document}
                  setDocument={setDocument}
                  name="financeDepositClientEntryRequests"
                  label="Klient"
                  disabled={false}
                  error={
                    ctx.getFieldError("financeDepositClientEntryRequests")
                      ?.message
                  }
                  onChange={(e) => {
                    ctx.resetFieldsErrors([
                      "financeDepositClientEntryRequests",
                      "financeDepositPartnerEntryRequests",
                    ]);
                  }}
                />
              </div>
              <div className="w-1/2 mb-4">
                <DocumentInput
                  document={document}
                  setDocument={setDocument}
                  name="financeDepositPartnerEntryRequests"
                  label="Partner"
                  disabled={false}
                  error={
                    ctx.getFieldError("financeDepositPartnerEntryRequests")
                      ?.message
                  }
                  onChange={(e) => {
                    ctx.resetFieldsErrors([
                      "financeDepositClientEntryRequests",
                      "financeDepositPartnerEntryRequests",
                    ]);
                  }}
                />
              </div>
            </div>
          </div>
          {/* poziadavky end */}
          {/* vklady start */}
          <div className="flex flex-col mb-4">
            <h3 className="mb-2 font-bold">
              Znalosti a skúsenosti s bankovými vkladmi a so stavebným sporením
            </h3>
            <div className="w-1/4">
              <ClientPartnerRadio
                name="bankDepositExperiences"
                document={document}
                setDocument={setDocument}
                client={{
                  hasError: !!ctx.getFieldError("bankDepositExperiencesClient"),
                  errorMsg: ctx.getFieldError("bankDepositExperiencesClient")
                    ?.message,
                  onChange: (e) => {
                    ctx.resetFieldsErrors([
                      "bankDepositExperiencesPartner",
                      "bankDepositExperiencesClient",
                    ]);
                  },
                }}
                partner={{
                  hasError: !!ctx.getFieldError(
                    "bankDepositExperiencesPartner"
                  ),
                  errorMsg: ctx.getFieldError("bankDepositExperiencesPartner")
                    ?.message,
                  onChange: (e) => {
                    ctx.resetFieldsErrors([
                      "bankDepositExperiencesPartner",
                      "bankDepositExperiencesClient",
                    ]);
                  },
                }}
              />
            </div>
          </div>

          <div className="flex flex-grow w-full py-2" />
          <div className="flex flex-col md:grid md:grid-cols-2 gap-x-5 gap-y-4">
            <DocumentInput
              document={document}
              setDocument={setDocument}
              name="financeOneTimeDeposit"
              label="Jednorázový vklad"
              disabled={false}
            />
            <DocumentInput
              document={document}
              setDocument={setDocument}
              name="financePeriodicDeposit"
              label="Pravidelný vklad (suma/frekvencia)"
              disabled={false}
            />
            <DocumentInput
              document={document}
              setDocument={setDocument}
              name="financeOneTimeDepositExpectedDuration"
              label="Predpokladaná doba trvania jednorázového vkladu v rokoch"
              disabled={false}
            />
            <DocumentInput
              document={document}
              setDocument={setDocument}
              name="financePeriodicDepositExpectedDuration"
              label="Predpokladaná doba trvania pravidelného vkladu v rokoch"
              disabled={false}
            />
          </div>
          <div className="pt-2">
            <ClientPartnerCheckbox
              document={document}
              setDocument={setDocument}
              name="financeEarlyWithdrawalPresumption"
              text="Predpoklad predčasného výberu"
            />
            <ClientPartnerCheckbox
              document={document}
              setDocument={setDocument}
              name="financeDepositApplyStateAid"
              text="Uplatnenie štátnej podpory"
            />
            <ClientPartnerCheckbox
              document={document}
              setDocument={setDocument}
              name="financeDepositConstructionLoanAssumption"
              text="Predpoklad využitia stavebného úveru"
            />
            <ClientPartnerCheckbox
              document={document}
              setDocument={setDocument}
              name="financeDepositIntermediateLoanAssumption"
              text="Predpoklad využitia medziúveru"
            />
          </div>
          {/* vklady end */}
        </div>
      </Card>
    </div>
  );
}

export default Page03IncomeCard;
