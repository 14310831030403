import { baseSplitApi as api } from "./baseApi";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMyProfile: build.query<GetMyProfileApiResponse, GetMyProfileApiArg>({
      query: (queryArg) => ({
        url: `/v1/me/`,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    updateMyProfile: build.mutation<
      UpdateMyProfileApiResponse,
      UpdateMyProfileApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/me/`,
        method: "PUT",
        body: queryArg.userProfileUpdate,
      }),
    }),
    changeMyPassword: build.mutation<
      ChangeMyPasswordApiResponse,
      ChangeMyPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/me/change-password`,
        method: "PATCH",
        body: queryArg.userChangePasswordRequest,
      }),
    }),
    getMyRoles: build.query<GetMyRolesApiResponse, GetMyRolesApiArg>({
      query: () => ({ url: `/v1/me/roles` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as spfApi };
export type GetMyProfileApiResponse =
  /** status 200 The users profile information */ UserProfile;
export type GetMyProfileApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
};
export type UpdateMyProfileApiResponse =
  /** status 200 OK */ SuccessResponse200;
export type UpdateMyProfileApiArg = {
  /** The new profile info */
  userProfileUpdate: UserProfileUpdate;
};
export type ChangeMyPasswordApiResponse =
  /** status 200 Password was changed */ SuccessResponse200;
export type ChangeMyPasswordApiArg = {
  /** Paylod with the old and new password */
  userChangePasswordRequest: UserChangePasswordRequest;
};
export type GetMyRolesApiResponse = /** status 200 The RUs */ RoleUser[];
export type GetMyRolesApiArg = void;
export type OriginSystemKey = "uo-portal" | "uo-icm";
export type UserProfile = {
  defaultRoleId?: string;
  email?: string;
  hasPassword?: boolean;
  id?: string;
  language?: string;
  name?: string;
  originSystem?: OriginSystemKey;
};
export type SuccessResponse200 = {
  id?: string;
  status?: string;
};
export type ValidationError = {
  code?: string;
  field?: string;
  message?: string;
};
export type ErrorResponse400 = {
  code?: number;
  data?: ValidationError[];
  message?: string;
};
export type UserProfileUpdate = {
  name?: string;
};
export type UserChangePasswordRequest = {
  new: string;
  old: string;
};
export type CountryKey = "sk";
export type Country = {
  key?: CountryKey;
  name?: string;
};
export type Address = {
  city?: string;
  country?: Country;
  street?: string;
  zip?: string;
};
export type ExternalLinkAuthTypeKey = "elat-sso" | "elat-log" | "elat-noa";
export type LinkType = {
  key?: ExternalLinkAuthTypeKey;
  name?: string;
};
export type ExternalLinkCategoryKey =
  | "lct-int"
  | "lct-ins"
  | "lct-bnk"
  | "lct-dss"
  | "lct-oth"
  | "lct-inc";
export type ExternalLinkCategory = {
  key?: ExternalLinkCategoryKey;
  name?: string;
};
export type ImageResponse = {
  type?: string;
  url?: string;
};
export type ExternalLinkConfigurationStatusKey =
  | "elcs-active"
  | "elcs-deactivated";
export type ExternalLinkConfigurationStatus = {
  key?: ExternalLinkConfigurationStatusKey;
  name?: string;
};
export type ExternalLinkConfiguration = {
  advisorExternalID?: string;
  advisorFirstame?: string;
  advisorId?: string;
  advisorLastname?: string;
  createdAt?: string;
  id?: string;
  login?: string;
  password?: string;
  status?: ExternalLinkConfigurationStatus;
  updatedAt?: string;
};
export type AdvisorSectorTypeKey =
  | "ast-aml"
  | "ast-ins"
  | "ast-len"
  | "ast-acc"
  | "ast-cap"
  | "ast-sup"
  | "ast-old";
export type AdvisorSectorType = {
  key?: AdvisorSectorTypeKey;
  name?: string;
};
export type ExternalLinkSector = {
  mandatory?: boolean;
  sectorType?: AdvisorSectorType;
};
export type ExternalLinkStatusKey = "els-active" | "els-deactivated";
export type ExternalLinkStatus = {
  key?: ExternalLinkStatusKey;
  name?: string;
};
export type ExternalLinkTypeKey = "ast-per" | "ast-gen";
export type ExternalLinkType = {
  key?: ExternalLinkTypeKey;
  name?: string;
};
export type ExternalLink = {
  authType?: LinkType;
  category?: ExternalLinkCategory;
  createdAt?: string;
  displayLink?: string;
  id?: string;
  image?: ImageResponse[];
  imageId?: string;
  linkConfigurations?: ExternalLinkConfiguration[];
  name?: string;
  order?: number;
  sectors?: ExternalLinkSector[];
  status?: ExternalLinkStatus;
  type?: ExternalLinkType;
  updatedAt?: string;
};
export type FavouriteExternalLink = {
  createdAt?: string;
  externalLink?: ExternalLink;
  order?: number;
  updatedAt?: string;
};
export type GenderKey = "gk-male" | "gk-female" | "gk-none";
export type Gender = {
  key?: GenderKey;
  name?: string;
};
export type AdvisorPositionKey =
  | "SP"
  | "KR"
  | "RR"
  | "OR"
  | "M"
  | "S3"
  | "S2"
  | "S1"
  | "SK"
  | "K2"
  | "K1"
  | "T"
  | "BP"
  | "BP90"
  | "BP85"
  | "BP70"
  | "BP00"
  | "FEL1"
  | "FEL2"
  | "FEL3"
  | "FEL4";
export type AdvisorPosition = {
  higherMLM?: AdvisorPositionKey;
  key?: AdvisorPositionKey;
  name?: string;
};
export type AdvisorSector = {
  certificate_from?: string;
  certificate_to?: string;
  createdAt?: string;
  id?: string;
  ofv_from?: string;
  ofv_to?: string;
  registration_from?: string;
  registration_to?: string;
  type?: AdvisorSectorType;
  updatedAt?: string;
};
export type AdvisorTypeKey = "at-fo" | "at-fop";
export type AdvisorType = {
  key?: AdvisorTypeKey;
  name?: string;
};
export type OriginSystem = {
  key?: OriginSystemKey;
  name?: string;
};
export type UserStatusKey = "us-active" | "us-deactivated" | "us-resigned";
export type UserStatus = {
  key?: UserStatusKey;
  name?: string;
};
export type User = {
  createdAt?: string;
  displayName: string;
  email: string;
  id?: string;
  originSystem?: OriginSystem;
  status?: UserStatus;
  updatedAt?: string;
};
export type Advisor = {
  businessAddress?: Address;
  businessName?: string;
  createdAt?: string;
  displayName?: string;
  evidenceNumber: string;
  externalId?: string;
  favouriteLinks?: FavouriteExternalLink[];
  firstname?: string;
  gender?: Gender;
  ico?: string;
  id?: string;
  lastname?: string;
  mentor?: Advisor;
  nbsRegistrationNumber?: string;
  parent?: Advisor;
  permanentAddress?: Address;
  phone_number?: string;
  pin?: string;
  position?: AdvisorPosition;
  sectors?: AdvisorSector[];
  title?: string;
  type?: AdvisorType;
  updatedAt?: string;
  updatedBy?: User;
  updatedByICM?: string;
  user?: User;
};
export type Assistant = {
  advisor?: Advisor;
  contractEnd?: string;
  contractStart?: string;
  createdAt?: string;
  displayName?: string;
  evidenceNumber: string;
  id?: string;
  name?: string;
  phone_number?: string;
  updatedAt?: string;
  user?: User;
};
export type Central = {
  clientDisplayName?: string;
  createdAt?: string;
  externalId: string;
  firstname?: string;
  icmId: number;
  id?: string;
  lastname?: string;
  updatedAt?: string;
  user?: User;
};
export type PermissionRuleRightsActionTypeKey =
  | "prra-add"
  | "prra-none"
  | "prra-remove";
export type PermissionRuleTypeKey =
  | "pr-all"
  | "pr-advisor-position"
  | "pr-role"
  | "pr-user-role"
  | "pr-advisor-tree"
  | "pr-advisor-children";
export type ComputedRightLogEntry = {
  create?: PermissionRuleRightsActionTypeKey;
  delete?: PermissionRuleRightsActionTypeKey;
  id?: string;
  name?: string;
  read?: PermissionRuleRightsActionTypeKey;
  type?: PermissionRuleTypeKey;
  update?: PermissionRuleRightsActionTypeKey;
  updatedAt?: string;
};
export type PermissionObjectTypeKey =
  | "pt-customers"
  | "pt-external-links"
  | "pt-cca"
  | "pt-check"
  | "pt-identifications"
  | "pt-users"
  | "pt-permissions"
  | "pt-system-settings";
export type RoleUserRight = {
  create?: PermissionRuleRightsActionTypeKey;
  delete?: PermissionRuleRightsActionTypeKey;
  log?: ComputedRightLogEntry[];
  read?: PermissionRuleRightsActionTypeKey;
  type?: PermissionObjectTypeKey;
  update?: PermissionRuleRightsActionTypeKey;
};
export type RoleKey =
  | "rk-assistant"
  | "rk-advisor"
  | "rk-central"
  | "rk-system-admin";
export type Role = {
  key?: RoleKey;
  name?: string;
};
export type RoleUserStatusKey = "rus-active" | "rus-deactivated";
export type RoleUserStatus = {
  key?: RoleUserStatusKey;
  name?: string;
};
export type RoleUser = {
  advisor?: Advisor;
  assistant?: Assistant;
  central?: Central;
  computedRights?: RoleUserRight[];
  id?: string;
  role?: Role;
  status?: RoleUserStatus;
};
export const {
  useGetMyProfileQuery,
  useUpdateMyProfileMutation,
  useChangeMyPasswordMutation,
  useGetMyRolesQuery,
} = injectedRtkApi;
