/* eslint-disable react/jsx-no-useless-fragment */
import React from "react";

import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Card from "../card/Card";

interface Properties {
  icon: IconDefinition;
  label: string;
  description?: string;
  children?:
    | JSX.Element
    | JSX.Element[]
    | string
    | string[]
    | (string | JSX.Element)[];
}

function PageHeader({ icon, label, description, children }: Properties) {
  return (
    <Card className="p-4 mb-8">
      <div className="flex flex-row">
        <div className="flex flex-col justify-center">
          <h1 className="text-2xl">
            <FontAwesomeIcon
              icon={icon}
              size="1x"
              className="mr-4 text-spf-primary"
            />
            <span>{label}</span>
          </h1>
          {description ? (
            <div className="ml-8 mt-3 text-sm">{description}</div>
          ) : (
            <></>
          )}
        </div>
        <div className="ml-auto flex gap-4">{children}</div>
      </div>
    </Card>
  );
}

PageHeader.defaultProps = {
  children: undefined,
  description: "",
};

export default PageHeader;
