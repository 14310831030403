import { AdvisorTypeKey } from "../api/advisors.generated";
import SelectOption from "./SelectOption";

const AdvisorType: SelectOption<AdvisorTypeKey>[] = [
  {
    key: "at-fo",
    name: "app.customerType.fo",
  },
  {
    key: "at-fop",
    name: "app.customerType.fop",
  },
];

export default AdvisorType;
