import { PermissionObjectTypeKey } from "../api/permissionRule.generated";
import PermissionType from "./PermissionType";
import SelectOption from "./SelectOption";

const ObjectSelectOptions: SelectOption<PermissionObjectTypeKey>[] = [
  {
    key: PermissionType.CUSTOMERS,
    name: "Klienti",
  },
  {
    key: PermissionType.EXTERNAL_LINKS,
    name: "Odkazy",
  },
  {
    key: PermissionType.ZUZ,
    name: "ZUZ",
  },
  {
    key: PermissionType.CHECK,
    name: "Súpiska",
  },
  {
    key: PermissionType.IDENTIFICATIONS,
    name: "Identifikácie",
  },
  {
    key: PermissionType.USERS,
    name: "Uživatelia",
  },
  {
    key: PermissionType.PERMISSIONS,
    name: "Definícia oprávnení",
  },
  {
    key: PermissionType.SYSTEM_SETTINGS,
    name: "Systémove nastavenia",
  },
];

export const getNameByKey = (key: PermissionType): string => {
  const option = ObjectSelectOptions.find((o) => o.key === key);
  return option ? option.name : "";
};

export default ObjectSelectOptions;
