/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { ChangeEvent, FormEvent } from "react";

import propTypes from "prop-types";

import SelectOption from "../../enums/SelectOption";
import InputGroup from "../inputs/InputGroup";

interface Properties {
  options: SelectOption<string>[];
  selectedOption?: string;
  label: string;
  disabled?: boolean;
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void | undefined;
}

function SelectEdit({
  label,
  disabled,
  options,
  selectedOption,
  onChange,
}: Properties) {
  return (
    <InputGroup>
      {label ? (
        <label className="block text-gray-400 text-sm mb-2" htmlFor="">
          <span>{label}</span>
        </label>
      ) : (
        ""
      )}
      <select
        className={
          `appearance-none rounded ` +
          `w-full ml-auto px-4 py-2 ` +
          `border border-gray-300 ` +
          `${disabled ? " bg-slate-100 " : ""}`
        }
        defaultValue={selectedOption}
        onChange={onChange}
        disabled={disabled}
      >
        {options.map((item) => (
          <option
            key={`index-${item.key}`}
            label={item.name}
            value={item.key}
            className="text-gray-800"
          >
            {item.name}
          </option>
        ))}
      </select>
    </InputGroup>
  );
}

SelectEdit.propTypes = {
  selectedOption: propTypes.string,
};

SelectEdit.defaultProps = {
  disabled: false,
  selectedOption: undefined,
  onChange: undefined,
};

export default SelectEdit;
