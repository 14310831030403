import { faUser, faUserGroup } from "@fortawesome/free-solid-svg-icons";

import { USER_OWNED, STRUCTURAL, ALL } from "./FilterLevelKey";

export const FilterLevel = [
  {
    type: USER_OWNED,
    name: "filter.level.user-owned",
    icon: [faUser],
    activeBgClass: "bg-spf-blue",
  },
  {
    type: STRUCTURAL,
    name: "filter.level.structural",
    icon: [faUserGroup],
    activeBgClass: "bg-spf-blue",
  },
  {
    type: ALL,
    name: "filter.level.all",
    icon: [faUser, faUserGroup],
    activeBgClass: "bg-spf-blue",
  },
];

const allEnums = {
  FilterLevel,
};

export default allEnums;
