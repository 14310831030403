import React, { FormEvent } from "react";

import { IconProp, IconDefinition } from "@fortawesome/fontawesome-svg-core";
import PropTypes from "prop-types";

import Input from "./Input";
import InputGroup from "./InputGroup";

interface Properties {
  value: string;
  placeholder: string;
  disabled: boolean;
  type: "text" | "password" | "date" | undefined;
  icon: IconProp | IconDefinition | undefined;
  label: string | undefined;
  pattern?: string | undefined;
  dataTestId?: string;
  onChange?: (e: FormEvent<HTMLInputElement>) => void | undefined;
}

function InputEdit({
  //   name,
  label,
  placeholder,
  value,
  type,
  icon,
  disabled,
  onChange,
  pattern,
  dataTestId,
}: Properties) {
  return (
    <InputGroup>
      <Input
        icon={icon}
        type={type}
        label={label}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onChange={onChange}
        pattern={pattern}
        dataTestId={dataTestId}
      />
    </InputGroup>
  );
}

InputEdit.propTypes = {
  disabled: PropTypes.bool,
  type: PropTypes.string,
  icon: PropTypes.element,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

InputEdit.defaultProps = {
  disabled: false,
  type: "text",
  onChange: undefined,
  icon: undefined,
  label: undefined,
  placeholder: "",
  pattern: undefined,
  dataTestId: "",
};

export default InputEdit;
