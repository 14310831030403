import React, { ChangeEvent } from "react";

import propTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { PermissionRuleType } from "../../api/permissionRule.generated";
import PermissionRuleTypeOptions from "../../enums/PermissionRuleTypeOptions";

interface Properties {
  selectedOption?: string;
  onSelect: (item: PermissionRuleType) => void;
  disabled?: boolean;
}

function PermissionRuleTypeSelect({
  selectedOption,
  onSelect,
  disabled,
}: Properties) {
  const { t } = useTranslation();

  const handleOnChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const item = PermissionRuleTypeOptions.find(
      (option) => option.key === e.target.value
    ) as PermissionRuleType;
    onSelect(item as PermissionRuleType);
  };

  return (
    <div>
      <div className="flex text-gray-500 text-sm mb-1">
        <span>{t("select.permission-rule-type.label")}</span>
      </div>
      <select
        className="block text-gray-700 mb-2 border border-gray-300 w-full p-2 rounded"
        value={selectedOption}
        onChange={handleOnChange}
        disabled={disabled}
      >
        {PermissionRuleTypeOptions.map((item) => (
          <option
            key={`index-${item.key}`}
            label={t(item.name) || ""}
            value={item.key}
          >
            <span>{t(item.name)}</span>
          </option>
        ))}
      </select>
    </div>
  );
}

PermissionRuleTypeSelect.propTypes = {
  selectedOption: propTypes.string,
};

PermissionRuleTypeSelect.defaultProps = {
  selectedOption: undefined,
  disabled: false,
};

export default PermissionRuleTypeSelect;
