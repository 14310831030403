import React from "react";

import { useTranslation } from "react-i18next";

import { ValidationError } from "../../api/me.generated";

interface ComponentProperties {
  label: string;
  field: string;
  required?: boolean;
  errors: ValidationError[];
  value?: boolean;
  compareValue?: boolean;
  className?: string;
  dataTestId?: string;
  disabled?: boolean;
  callback: (event: React.FormEvent<any>) => void;
}

function ValidatedCheckbox({
  label,
  field,
  value,
  errors,
  required,
  className,
  callback,
  compareValue,
  disabled,
  dataTestId,
}: ComponentProperties): JSX.Element {
  const error = (errors ?? []).filter((item) => item.field === field);
  const { t } = useTranslation();
  const showError = error.length > 0;
  const message = error.map((i) => i.message).join(", ");
  const shouldCompare = compareValue !== undefined && compareValue !== value;
  const finalDataTestId = !dataTestId ? `/global/input-${field}` : dataTestId;
  const compareLabel = (v: boolean | undefined) => {
    const defaultLabel = t("checkbox.boolean.empty");
    switch (typeof v) {
      case "boolean":
        return v ? t("checkbox.boolean.true") : t("checkbox.boolean.false");
      default:
        return defaultLabel;
    }
  };

  return (
    <div className="mb-3">
      <label
        className="flex text-gray-500 text-sm mb-1"
        htmlFor={`checkbox-${field}`}
      >
        <input
          id={`checkbox-${field}`}
          type="checkbox"
          className={`h-5 w-5 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mr-1 cursor-pointer ${className}`}
          onChange={callback}
          checked={value}
          disabled={disabled}
          data-testid={finalDataTestId}
        />
        <span className="ml-2">
          {label}
          {required && <small className="ml-1 text-spf-red">*</small>}
        </span>
        {shouldCompare ? (
          <small className="ml-2 text-green-600">{`[${compareLabel(
            compareValue
          )}]`}</small>
        ) : null}
      </label>
      {showError && <div className="text-green-400">{message}</div>}
    </div>
  );
}

ValidatedCheckbox.defaultProps = {
  required: false,
  className: "",
  dataTestId: "",
  value: false,
  disabled: false,
  compareValue: undefined,
};

export default ValidatedCheckbox;
