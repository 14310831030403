import React from "react";

import {
  faExclamationCircle,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import propTypes from "prop-types";

// enum AlertVariants = {
//   INFO = "info"
// }

interface Properties {
  className?: string;
  variant: "info" | "danger" | undefined;
  children: React.ReactNode;
}

function Alert({ className, variant, children }: Properties) {
  const isDangerVariant = variant === "danger";
  return (
    <div
      className={`
        ${
          isDangerVariant
            ? "border-red-300 bg-red-100"
            : "border-blue-300 bg-blue-100"
        }
        border  px-4 py-3 rounded mb-4
        flex
        ${className}
      `}
    >
      <span className={isDangerVariant ? "text-red-600" : "text-blue-500"}>
        <FontAwesomeIcon
          icon={isDangerVariant ? faExclamationCircle : faInfoCircle}
        />
      </span>
      <span className="pl-3 text-sm leading-6">{children}</span>
    </div>
  );
}

Alert.propTypes = {
  variant: propTypes.string,
};

Alert.defaultProps = {
  className: "",
  variant: "info",
};

export default Alert;
