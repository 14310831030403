/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { ChangeEvent } from "react";

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Properties {
  value: string | number;
  placeholder: string;
  autoFocus?: boolean;
  className?: string;
  dataTestId?: string;
  classNameWrapper?: string;
  disabled?: boolean;
  icon?: IconProp;
  label?: string;
  name?: string;
  rows?: number;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
}

function Textarea({
  autoFocus,
  className,
  classNameWrapper,
  label,
  placeholder,
  name,
  value,
  icon,
  disabled,
  onChange,
  onKeyDown,
  onKeyUp,
  rows,
  dataTestId,
}: Properties) {
  const finalDisabled = !!disabled;
  const finalDataTestId = dataTestId || `/global/textarea-${name}`;
  return (
    <div className={classNameWrapper}>
      {label ? (
        <label className="block text-gray-500 text-sm mb-2 truncate" htmlFor="">
          {label}
        </label>
      ) : (
        ""
      )}
      <div className="group w-full flex border border-gray-300 outline-none rounded transition-colors">
        {icon ? (
          <span className="inline-block bg-gray-100 border-r border-gray-300 pt-2 px-3 text-gray-500 rounded-l w-10 text-center">
            <FontAwesomeIcon icon={icon} />
          </span>
        ) : (
          <span />
        )}
        <textarea
          className={
            `appearance-none ` +
            `w-full px-4 py-2 border-none ` +
            `${icon ? " rounded-l-0 rounded-r " : " rounded "}` +
            `${
              finalDisabled
                ? " bg-slate-100 text-gray-600 "
                : " bg-white text-gray-800 "
            }` +
            `group-focus:outline-none group-focus:outline-red-600` +
            `${className}`
          }
          name={name}
          placeholder={placeholder}
          value={value}
          rows={rows}
          autoFocus={autoFocus}
          disabled={finalDisabled}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          data-testid={finalDataTestId}
        />
      </div>
    </div>
  );
}

Textarea.defaultProps = {
  autoFocus: false,
  className: "",
  dataTestId: "",
  classNameWrapper: "",
  name: "",
  disabled: false,
  onChange: undefined,
  onKeyDown: undefined,
  onKeyUp: undefined,
  icon: undefined,
  label: undefined,
  rows: 3,
};

export default Textarea;
