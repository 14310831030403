export enum ClientPartnerRadioValues {
  NONE = "cprv-none",
  PARTIAL = "cprv-partial",
  FULL = "cprv-full",
}

export type ClientPartnerRadioOptions = {
  client: ClientPartnerRadioValues | undefined;
  partner: ClientPartnerRadioValues | undefined;
};
