import React from "react";

import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

import ApplicationRoute from "../../features/route/ApplicationRoute";
import Button from "./Button";

interface Properties {
  className?: string;
  disabled?: boolean;
  to?: ApplicationRoute | undefined;
  onClick?: () => void | undefined;
}

function DeleteButton({ className, disabled, to, onClick }: Properties) {
  const { t } = useTranslation();
  return (
    <Button
      className={`bg-spf-red ${className}`}
      disabled={disabled}
      icon={faTimesCircle}
      onClick={onClick}
      to={to}
    >
      <span>{t("button.delete")}</span>
    </Button>
  );
}

DeleteButton.defaultProps = {
  className: "",
  disabled: false,
  to: undefined,
  onClick: undefined,
};

export default DeleteButton;
