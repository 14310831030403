import { baseSplitApi as api } from "./baseApi";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    doUpload: build.mutation<DoUploadApiResponse, DoUploadApiArg>({
      query: (queryArg) => ({
        url: `/v1/upload/`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as spfApi };
export type DoUploadApiResponse = /** status 201 Created */ SuccessResponse201;
export type DoUploadApiArg = {
  body: FormData;
  // body: {
  //   file: Blob;
  // };
};
export type SuccessResponse201 = {
  id?: string;
};
export type ValidationError = {
  field?: string;
  message?: string;
};
export type ErrorResponse400 = {
  code?: number;
  data?: ValidationError[];
  message?: string;
};
export type ErrorResponse500 = {
  code?: number;
  data?: {
    [key: string]: string;
  }[];
  message?: string;
};
export const { useDoUploadMutation } = injectedRtkApi;
