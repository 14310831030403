import React, { ChangeEvent, useEffect, useRef, useState } from "react";

import { faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DndProvider, DropTargetMonitor, useDrop } from "react-dnd";
import { HTML5Backend, NativeTypes } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import Button from "../buttons/Button";
import UpdateButton from "../buttons/UpdateButton";
import Card from "../card/Card";
import CardBody from "../card/CardBody";

interface Properties {
  callback: (file: File) => void;
}

function FileUploadBox({ callback }: Properties) {
  const { t } = useTranslation();
  const [file, setFile] = useState<File | undefined>();

  const onFileSelect = (item: { files: any[] }) => {
    setFile(item.files[0]);
  };

  const handleSaveClick = () => {
    if (file) {
      callback(file);
    }
  };

  return (
    <div>
      <DndProvider backend={HTML5Backend}>
        <form onSubmit={(e) => e.preventDefault()}>
          {file ? (
            <SelectedFileView file={file} />
          ) : (
            <DropArea onFileSelect={onFileSelect} />
          )}
        </form>
      </DndProvider>
      <div className="flex justify-center">
        <Button
          className="flex mt-4"
          disabled={!(file instanceof File)}
          onClick={() => handleSaveClick()}
        >
          <span>{t("app.import")}</span>
        </Button>

        {file && (
          <Button
            className="ml-2 bg-transparent border border-slate-400 text-slate-400 hover:bg-slate-100 flex mt-4"
            onClick={() => setFile(undefined)}
          >
            <span>{t("button.cancel")}</span>
          </Button>
        )}
      </div>
    </div>
  );
}

export default FileUploadBox;

function SelectedFileView({ file }: { file: File }) {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <div className="text-2xl text-gray-400 mb-2">
        <FontAwesomeIcon icon={faFile} />
      </div>
      <div className="text-sm text-center text-gray-400">{file?.name}</div>
    </div>
  );
}

function DropArea({ onFileSelect }: { onFileSelect: (item: any) => void }) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item: { files: any[] }) {
        if (onFileSelect) {
          onFileSelect(item);
        }
      },
      canDrop(item: any) {
        return true;
      },
      collect: (monitor: DropTargetMonitor) => {
        const item = monitor.getItem() as any;

        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
    }),
    [{ onFileSelect }]
  );

  const handleOnChange = (e: ChangeEvent) => {
    if (onFileSelect) {
      onFileSelect(e.target);
    }
  };

  return (
    <label
      ref={drop}
      className={`h-32 p-4 flex justify-center items-center border-2 border-dashed border-gray-300 rounded-md outline-none cursor-pointer ${
        canDrop && isOver ? "bg-gray-100" : "bg-white"
      } hover:bg-gray-50 focus:bg-gray-50 transiton duration-200 ease-in-out relative`}
      htmlFor="input-file-upload"
    >
      <input
        ref={inputRef}
        type="file"
        id="input-file-upload"
        className="hidden"
        onChange={handleOnChange}
      />
      <p className="w-3/4 select-none text-sm text-center text-gray-500">
        Súbor preneste do tejto oblasti alebo kliknite pre výber
      </p>
    </label>
  );
}
