import React, { FormEvent, useState } from "react";

import { faList, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import {
  PermissionRuleStatus,
  useGetPermissionRulesQuery,
} from "../../api/permissionRule.generated";
import Alert from "../../components/alerts/Alert";
import CountBadge from "../../components/badges/CountBadge";
import PermissionRuleStatusBadge from "../../components/badges/PermissionRuleStatusBadge";
import AddButton from "../../components/buttons/AddButton";
import Card from "../../components/card/Card";
import CardBody from "../../components/card/CardBody";
import CardFooter from "../../components/card/CardFooter";
import CardHeader from "../../components/card/CardHeader";
import PageHeader from "../../components/page/PageHeader";
import useFormatters from "../../hooks/formatter";
import { usePermissionListQuery } from "../../hooks/permissions";
import { getRoleId } from "../../hooks/role";
import { makeRoute } from "../../hooks/route";
import AllRoutes from "../route/Route";
import TableBodyLoading from "../table/TableBodyLoading";
import TableColumnHeadingButton from "../table/TableColumnHeadingButton";
import TablePageSize from "../table/TablePageSize";
import TablePagination from "../table/TablePagination";

type FixmeLater = {
  id: string;
  name: string;
  type: { name: string };
  role: { name: string };
  advisor_position: { name: string };
  advisor: { firstname: string; lastname: string };
  updatedAt: string;
  status: PermissionRuleStatus;
};

function RulesListView() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { formatTableTime } = useFormatters();
  const [query, setQuery] = usePermissionListQuery();
  const [searchValue, setSearchValue] = useState(query.keyword);

  const { currentData, isFetching, isError } = useGetPermissionRulesQuery(
    { ...query, "X-Role-Id": getRoleId() },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const redirectToEdit = (id: string) => {
    const path = makeRoute(AllRoutes.RULES_EDIT, {
      id,
    });
    navigate(path);
  };

  const searchFilterChange = (event: FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearchValue(value);
  };

  const searchRules = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === "keyup" &&
      (event as React.KeyboardEvent).key !== "Enter"
    ) {
      return null;
    }

    return setQuery({
      ...query,
      page: 1,
      keyword: searchValue,
    });
  };

  if (isError) return <div>{t("app.page.error.api") || ""}</div>;

  return (
    <>
      <PageHeader
        icon={AllRoutes.RULES_LIST.icon}
        label={t(AllRoutes.RULES_LIST.name ?? "") || ""}
      >
        <AddButton to={AllRoutes.RULES_ADD} />
      </PageHeader>

      <Card>
        <CardHeader
          icon={faList}
          label={t("app.customer.list.card.header") || ""}
        >
          <span className="float-right">
            <CountBadge count={currentData?.count} />
          </span>
        </CardHeader>
        <CardBody>
          <div className="flex p-4 mb-4 bg-slate-200 rounded-lg">
            <div className="flex ml-auto">
              <div className="flex border border-gray-300 rounded">
                <span className="w-8 text-center text-gray-300 px-1 border-gray-300 border-r bg-white rounded-l">
                  <FontAwesomeIcon
                    icon={faSearch}
                    className="text-gray-600 mt-1.5"
                  />
                </span>
                <input
                  type="text"
                  className="border-none text-gray-700 px-4 text-sm py-1 outline-0 rounded-r"
                  placeholder={t("table.filter.name") || ""}
                  value={searchValue}
                  onKeyUp={(e) => searchRules(e)}
                  onChange={(e) => searchFilterChange(e)}
                />
              </div>
              <input
                type="button"
                className="ml-1 border border-gray-300 bg-white text-gray-700 px-4 text-sm py-1 outline-0 rounded"
                value={t("button.search") || ""}
                onClick={(e) => searchRules(e)}
              />
            </div>
          </div>

          <table>
            <thead>
              <tr>
                <td>
                  <TableColumnHeadingButton
                    field="name"
                    label={t("table.header.title") || ""}
                    sort={query?.sort}
                  />
                </td>
                <td>
                  <TableColumnHeadingButton
                    field="type"
                    label={t("table.header.type") || ""}
                    sort={query?.sort}
                  />
                </td>
                <td>
                  <TableColumnHeadingButton
                    field="role"
                    label={t("table.header.role") || ""}
                    sort={query?.sort}
                  />
                </td>
                <td>
                  <TableColumnHeadingButton
                    field="position"
                    label={t("table.header.position") || ""}
                    sort={query?.sort}
                  />
                </td>
                <td>
                  <TableColumnHeadingButton
                    field="user"
                    label={t("table.header.user") || ""}
                    sort={query?.sort}
                  />
                </td>
                <td>
                  <TableColumnHeadingButton
                    field="changed"
                    label={t("table.header.updatedAt") || ""}
                    sort={query?.sort}
                  />
                </td>
                <td>
                  <TableColumnHeadingButton
                    field="status"
                    label={t("table.header.status") || ""}
                    sort={query?.sort}
                  />
                </td>
              </tr>
            </thead>
            {isFetching && !currentData ? (
              <TableBodyLoading cols={1} rows={query.size ?? 10} />
            ) : (
              <tbody>
                {
                  // TODO: toto sa zle generuje na api
                  currentData
                    ? (currentData?.items as FixmeLater[])?.map((rule) => (
                        <tr
                          key={`index-${rule.id}`}
                          className="cursor-pointer"
                          onClick={() => redirectToEdit(rule.id ?? "")}
                        >
                          <td className="text-spf-primary">{rule.name}</td>
                          <td className="text-spf-primary">
                            {rule.type?.name}
                          </td>
                          <td className="text-spf-primary">
                            {rule.role?.name}
                          </td>
                          <td className="text-spf-primary">
                            {rule.advisor_position?.name}
                          </td>
                          <td className="text-spf-primary">
                            {`${rule.advisor?.firstname} ${rule.advisor?.lastname}`}
                          </td>
                          <td>{formatTableTime(rule.updatedAt)}</td>
                          <td>
                            <PermissionRuleStatusBadge
                              status={rule.status}
                              className="text-xs"
                            />
                          </td>
                        </tr>
                      ))
                    : t("table.noData") || ""
                }
              </tbody>
            )}
          </table>
          {!isFetching && currentData?.count === 0 ? (
            <Alert className="mt-4">{t("table.noData") || ""}</Alert>
          ) : (
            <span />
          )}
        </CardBody>
        <CardFooter>
          <div className="">
            <TablePagination
              callback={setQuery}
              query={query}
              count={currentData?.count ?? 0}
            />
          </div>
          <div className="ml-auto">
            <TablePageSize query={query} callback={setQuery} />
          </div>
        </CardFooter>
      </Card>
    </>
  );
}

export default RulesListView;
