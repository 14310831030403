import { baseSplitApi as api } from "./baseApi";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAdminExternalLinkConfigurations: build.query<
      GetAdminExternalLinkConfigurationsApiResponse,
      GetAdminExternalLinkConfigurationsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/admin/external-link-configurations/`,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort: queryArg.sort,
          keyword: queryArg.keyword,
          externalLinkId: queryArg.externalLinkId,
          documentStates: queryArg.documentStates,
        },
      }),
    }),
    createAdminExternalLinkConfiguration: build.mutation<
      CreateAdminExternalLinkConfigurationApiResponse,
      CreateAdminExternalLinkConfigurationApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/admin/external-link-configurations/`,
        method: "POST",
        body: queryArg.externalLinkConfigurationUpdateRequest,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    importExternalLinkConfigurations: build.mutation<
      ImportExternalLinkConfigurationsApiResponse,
      ImportExternalLinkConfigurationsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/admin/external-link-configurations/import-personal`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getExternalLinkConfigurationById: build.query<
      GetExternalLinkConfigurationByIdApiResponse,
      GetExternalLinkConfigurationByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/admin/external-link-configurations/${queryArg.id}`,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    updateAdminExternalLinkConfiguration: build.mutation<
      UpdateAdminExternalLinkConfigurationApiResponse,
      UpdateAdminExternalLinkConfigurationApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/admin/external-link-configurations/${queryArg.id}`,
        method: "PUT",
        body: queryArg.externalLinkConfigurationUpdateRequest,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    deleteAdminExternalLinkConfiguration: build.mutation<
      DeleteAdminExternalLinkConfigurationApiResponse,
      DeleteAdminExternalLinkConfigurationApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/admin/external-link-configurations/${queryArg.id}`,
        method: "DELETE",
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    getAdminExternalLinks: build.query<
      GetAdminExternalLinksApiResponse,
      GetAdminExternalLinksApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/admin/external-links/`,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort: queryArg.sort,
          keyword: queryArg.keyword,
          states: queryArg.states,
          types: queryArg.types,
          categories: queryArg.categories,
        },
      }),
    }),
    createAdminExternalLink: build.mutation<
      CreateAdminExternalLinkApiResponse,
      CreateAdminExternalLinkApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/admin/external-links/`,
        method: "POST",
        body: queryArg.externalLinkUpdateRequest,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    getAdminExternalLinkById: build.query<
      GetAdminExternalLinkByIdApiResponse,
      GetAdminExternalLinkByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/admin/external-links/${queryArg.id}`,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    updateExternalLink: build.mutation<
      UpdateExternalLinkApiResponse,
      UpdateExternalLinkApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/admin/external-links/${queryArg.id}`,
        method: "PUT",
        body: queryArg.externalLinkUpdateRequest,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    patchExternalLink: build.mutation<
      PatchExternalLinkApiResponse,
      PatchExternalLinkApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/admin/external-links/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.externalLinkPatchRequest,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    updateExternalLinkStatus: build.mutation<
      UpdateExternalLinkStatusApiResponse,
      UpdateExternalLinkStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/admin/external-links/${queryArg.id}/update-status`,
        method: "PATCH",
        body: queryArg.externalLinkStatusUpdate,
      }),
    }),
    updateExternalLinkConfiguration: build.mutation<
      UpdateExternalLinkConfigurationApiResponse,
      UpdateExternalLinkConfigurationApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/external-link-configurations/${queryArg.id}`,
        method: "PUT",
        body: queryArg.externalLinkConfigurationAdvisorUpdateRequest,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    getExternalLinkCategories: build.query<
      GetExternalLinkCategoriesApiResponse,
      GetExternalLinkCategoriesApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/external-links/categories`,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    changeOrderFavourites: build.mutation<
      ChangeOrderFavouritesApiResponse,
      ChangeOrderFavouritesApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/external-links/change-order-favourites`,
        method: "PATCH",
        body: queryArg.favouriteLinkChangeOrderRequest,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    getExternalLinkFavourites: build.query<
      GetExternalLinkFavouritesApiResponse,
      GetExternalLinkFavouritesApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/external-links/favourites`,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    getExternalLinkById: build.query<
      GetExternalLinkByIdApiResponse,
      GetExternalLinkByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/external-links/${queryArg.id}`,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    addExternalLinkToFavourites: build.mutation<
      AddExternalLinkToFavouritesApiResponse,
      AddExternalLinkToFavouritesApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/external-links/${queryArg.id}/add-to-favourites`,
        method: "POST",
        body: queryArg.favouriteExternalLinkUpdateRequest,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    getFavouriteExternalLinkById: build.query<
      GetFavouriteExternalLinkByIdApiResponse,
      GetFavouriteExternalLinkByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/external-links/${queryArg.id}/favourites`,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    removeFromFavourites: build.mutation<
      RemoveFromFavouritesApiResponse,
      RemoveFromFavouritesApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/external-links/${queryArg.id}/remove-from-favourites`,
        method: "DELETE",
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as spfApi };
export type GetAdminExternalLinkConfigurationsApiResponse =
  /** status 200 OK */ ExternalLinkConfigurationPage;
export type GetAdminExternalLinkConfigurationsApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** page */
  page?: number;
  /** size */
  size?: number;
  /** Format is sort=email:desc,status:asc. Supported keys: created_at, status. */
  sort?: string[];
  /** keyword to search for in externalLinkConfigurations */
  keyword?: string;
  /** uuid */
  externalLinkId: string;
  /** allowed states */
  documentStates?: string[];
};
export type CreateAdminExternalLinkConfigurationApiResponse =
  /** status 200 OK */ SuccessResponse200;
export type CreateAdminExternalLinkConfigurationApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** ExternalLink to be updated */
  externalLinkConfigurationUpdateRequest: ExternalLinkConfigurationUpdateRequest;
};
export type ImportExternalLinkConfigurationsApiResponse =
  /** status 200 OK */ ExternalLinkImportResponse;
export type ImportExternalLinkConfigurationsApiArg = {
  body: {
    file: Blob;
  };
};
export type GetExternalLinkConfigurationByIdApiResponse =
  /** status 200 OK */ ExternalLinkConfiguration;
export type GetExternalLinkConfigurationByIdApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** uuid */
  id: string;
};
export type UpdateAdminExternalLinkConfigurationApiResponse =
  /** status 200 OK */ SuccessResponse200;
export type UpdateAdminExternalLinkConfigurationApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** uuid */
  id: string;
  /** ExternalLink to be updated */
  externalLinkConfigurationUpdateRequest: ExternalLinkConfigurationUpdateRequest;
};
export type DeleteAdminExternalLinkConfigurationApiResponse =
  /** status 200 OK */ SuccessResponse200;
export type DeleteAdminExternalLinkConfigurationApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** uuid */
  id: string;
};
export type GetAdminExternalLinksApiResponse =
  /** status 200 OK */ ExternalLinkPage;
export type GetAdminExternalLinksApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** page */
  page?: number;
  /** size */
  size?: number;
  /** Format is sort=email:desc,display_name:asc. Supported keys: created_at, display_name. */
  sort?: string[];
  /** keyword to search for in externalLinks */
  keyword?: string;
  /** allowed states */
  states?: string[];
  /** allowed types */
  types?: string[];
  /** allowed categories */
  categories?: string[];
};
export type CreateAdminExternalLinkApiResponse =
  /** status 200 OK */ SuccessResponse200;
export type CreateAdminExternalLinkApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** ExternalLink to be updated */
  externalLinkUpdateRequest: ExternalLinkUpdateRequest;
};
export type GetAdminExternalLinkByIdApiResponse =
  /** status 200 OK */ ExternalLink;
export type GetAdminExternalLinkByIdApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** uuid */
  id: string;
};
export type UpdateExternalLinkApiResponse =
  /** status 200 OK */ SuccessResponse200;
export type UpdateExternalLinkApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** uuid */
  id: string;
  /** ExternalLink to be updated */
  externalLinkUpdateRequest: ExternalLinkUpdateRequest;
};
export type PatchExternalLinkApiResponse =
  /** status 200 OK */ SuccessResponse200;
export type PatchExternalLinkApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** uuid */
  id: string;
  /** ExternalLink to be updated */
  externalLinkPatchRequest: ExternalLinkPatchRequest;
};
export type UpdateExternalLinkStatusApiResponse =
  /** status 200 Resource was found */ SuccessResponse200;
export type UpdateExternalLinkStatusApiArg = {
  /** uuid */
  id: string;
  /** the new status */
  externalLinkStatusUpdate: ExternalLinkStatusUpdate;
};
export type UpdateExternalLinkConfigurationApiResponse =
  /** status 200 OK */ SuccessResponse200;
export type UpdateExternalLinkConfigurationApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** uuid */
  id: string;
  /** ExternalLink to be updated */
  externalLinkConfigurationAdvisorUpdateRequest: ExternalLinkConfigurationAdvisorUpdateRequest;
};
export type GetExternalLinkCategoriesApiResponse =
  /** status 200 OK */ ExternalLinkCategoriesResponse;
export type GetExternalLinkCategoriesApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
};
export type ChangeOrderFavouritesApiResponse =
  /** status 200 OK */ ExternalLink;
export type ChangeOrderFavouritesApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** ExternalLink to be updated */
  favouriteLinkChangeOrderRequest: FavouriteLinkChangeOrderRequest;
};
export type GetExternalLinkFavouritesApiResponse =
  /** status 200 OK */ ExternalLinkFavouritesResponse;
export type GetExternalLinkFavouritesApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
};
export type GetExternalLinkByIdApiResponse = /** status 200 OK */ ExternalLink;
export type GetExternalLinkByIdApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  id: string;
  /** uuid */
  "X-Role-Id": string;
};
export type AddExternalLinkToFavouritesApiResponse =
  /** status 200 OK */ SuccessResponse200;
export type AddExternalLinkToFavouritesApiArg = {
  /** uuid */
  id: string;
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** ExternalLink to be updated */
  favouriteExternalLinkUpdateRequest: FavouriteExternalLinkUpdateRequest;
};
export type GetFavouriteExternalLinkByIdApiResponse =
  /** status 200 OK */ ExternalLink;
export type GetFavouriteExternalLinkByIdApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  id: string;
  /** uuid */
  "X-Role-Id": string;
};
export type RemoveFromFavouritesApiResponse = /** status 200 OK */ ExternalLink;
export type RemoveFromFavouritesApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** uuid */
  id: string;
};
export type ExternalLinkConfigurationStatusKey =
  | "elcs-active"
  | "elcs-deactivated";
export type ExternalLinkConfigurationFilter = {
  configurationStates?: ExternalLinkConfigurationStatusKey[];
  externalLinkId?: string;
  keyword?: string;
};
export type ExternalLinkConfigurationStatus = {
  key?: ExternalLinkConfigurationStatusKey;
  name?: string;
};
export type ExternalLinkConfiguration = {
  advisorExternalID?: string;
  advisorFirstame?: string;
  advisorId?: string;
  advisorLastname?: string;
  createdAt?: string;
  id?: string;
  login?: string;
  password?: string;
  status?: ExternalLinkConfigurationStatus;
  updatedAt?: string;
};
export type SortFieldDirection = "asc" | "desc";
export type SortFieldKey =
  | "createdAt"
  | "updatedAt"
  | "startsAt"
  | "validTo"
  | "validUntil"
  | "firstname"
  | "lastname"
  | "name"
  | "displayName"
  | "evidenceNumber"
  | "email"
  | "pin"
  | "ico"
  | "advisorEmail"
  | "customerEmail"
  | "mentorEmail"
  | "phoneNumber"
  | "displayId"
  | "street"
  | "city"
  | "zip"
  | "id"
  | "owner"
  | "type"
  | "status"
  | "customer"
  | "advisor"
  | "updatedBy";
export type SortField = {
  direction?: SortFieldDirection;
  field?: SortFieldKey;
};
export type ExternalLinkConfigurationPage = {
  count?: number;
  filter?: ExternalLinkConfigurationFilter;
  items?: ExternalLinkConfiguration[];
  last?: boolean;
  page?: number;
  size?: number;
  sort?: SortField[];
};
export type ValidationError = {
  code?: string;
  field?: string;
  message?: string;
};
export type ErrorResponse400 = {
  code?: number;
  data?: ValidationError[];
  message?: string;
};
export type SuccessResponse200 = {
  id?: string;
  status?: string;
};
export type ErrorResponse401 = {
  code?: number;
  data?: {
    [key: string]: string;
  }[];
  message?: string;
};
export type ErrorResponse500 = {
  code?: number;
  data?: {
    [key: string]: string;
  }[];
  message?: string;
};
export type ExternalLinkConfigurationUpdateRequest = {
  advisorId?: string;
  externalLinkID?: string;
  id?: string;
  login?: string;
  password?: string;
  statusKey?: ExternalLinkConfigurationStatusKey;
};
export type ExternalLinkImportLogLine = {
  lineNr?: number;
  message?: string;
};
export type ExternalLinkImportResponse = {
  importLog?: ExternalLinkImportLogLine[];
  processed?: number;
  rejected?: number;
  successful?: number;
};
export type ErrorResponse404 = {
  code?: number;
  data?: {
    [key: string]: string;
  }[];
  message?: string;
};
export type ExternalLinkCategoryKey =
  | "lct-int"
  | "lct-ins"
  | "lct-bnk"
  | "lct-dss"
  | "lct-oth"
  | "lct-inc";
export type ExternalLinkStatusKey = "els-active" | "els-deactivated";
export type ExternalLinkTypeKey = "ast-per" | "ast-gen";
export type ExternalLinkFilter = {
  categories?: ExternalLinkCategoryKey[];
  keyword?: string;
  states?: ExternalLinkStatusKey[];
  types?: ExternalLinkTypeKey[];
};
export type ExternalLinkAuthTypeKey = "elat-sso" | "elat-log" | "elat-noa";
export type LinkType = {
  key?: ExternalLinkAuthTypeKey;
  name?: string;
};
export type ExternalLinkCategory = {
  key?: ExternalLinkCategoryKey;
  name?: string;
};
export type ImageResponse = {
  type?: string;
  url?: string;
};
export type AdvisorSectorTypeKey =
  | "ast-aml"
  | "ast-ins"
  | "ast-len"
  | "ast-acc"
  | "ast-cap"
  | "ast-sup"
  | "ast-old";
export type AdvisorSectorType = {
  key?: AdvisorSectorTypeKey;
  name?: string;
};
export type ExternalLinkSector = {
  mandatory?: boolean;
  sectorType?: AdvisorSectorType;
};
export type ExternalLinkStatus = {
  key?: ExternalLinkStatusKey;
  name?: string;
};
export type ExternalLinkType = {
  key?: ExternalLinkTypeKey;
  name?: string;
};
export type ExternalLink = {
  authType?: LinkType;
  category?: ExternalLinkCategory;
  createdAt?: string;
  displayLink?: string;
  id?: string;
  image?: ImageResponse[];
  imageId?: string;
  linkConfigurations?: ExternalLinkConfiguration[];
  name?: string;
  order?: number;
  sectors?: ExternalLinkSector[];
  status?: ExternalLinkStatus;
  type?: ExternalLinkType;
  updatedAt?: string;
};
export type ExternalLinkPage = {
  count?: number;
  filter?: ExternalLinkFilter;
  items?: ExternalLink[];
  last?: boolean;
  page?: number;
  size?: number;
  sort?: SortField[];
};
export type ErrorResponse403 = {
  code?: number;
  data?: {
    [key: string]: string;
  }[];
  message?: string;
};
export type ExternalLinkSectorUpdateRequest = {
  id?: string;
  mandatory: boolean;
  sectorTypeKey: AdvisorSectorTypeKey;
};
export type ExternalLinkUpdateRequest = {
  authTypeKey: ExternalLinkAuthTypeKey;
  categoryKey: ExternalLinkCategoryKey;
  displayLink: string;
  imageId?: string;
  linkConfigurations?: ExternalLinkConfigurationUpdateRequest[];
  linkTypeKey: ExternalLinkTypeKey;
  name: string;
  order: number;
  sectors?: ExternalLinkSectorUpdateRequest[];
  statusKey: ExternalLinkStatusKey;
};
export type ExternalLinkPatchRequest = {
  authTypeKey: ExternalLinkAuthTypeKey;
  categoryKey: ExternalLinkCategoryKey;
  displayLink: string;
  imageId?: string;
  linkTypeKey: ExternalLinkTypeKey;
  name: string;
  order: string;
  sectors?: ExternalLinkSectorUpdateRequest[];
  statusKey: ExternalLinkStatusKey;
};
export type ExternalLinkStatusUpdate = {
  statusKey?: ExternalLinkStatusKey;
};
export type ExternalLinkConfigurationAdvisorUpdateRequest = {
  login?: string;
  password?: string;
};
export type ExternalLinkCategoriesGroup = {
  categoryKey?: ExternalLinkCategoryKey;
  externalLink?: ExternalLink[];
};
export type ExternalLinkCategoriesResponse = {
  items?: ExternalLinkCategoriesGroup[];
};
export type FavouriteLinkChangeOrder = {
  id: string;
  order: number;
};
export type FavouriteLinkChangeOrderRequest = {
  items: FavouriteLinkChangeOrder[];
};
export type ExternalLinkFavouritesResponse = {
  items?: ExternalLink[];
};
export type FavouriteExternalLinkUpdateRequest = {
  order: number;
};
export const {
  useGetAdminExternalLinkConfigurationsQuery,
  useCreateAdminExternalLinkConfigurationMutation,
  useImportExternalLinkConfigurationsMutation,
  useGetExternalLinkConfigurationByIdQuery,
  useUpdateAdminExternalLinkConfigurationMutation,
  useDeleteAdminExternalLinkConfigurationMutation,
  useGetAdminExternalLinksQuery,
  useCreateAdminExternalLinkMutation,
  useGetAdminExternalLinkByIdQuery,
  useUpdateExternalLinkMutation,
  usePatchExternalLinkMutation,
  useUpdateExternalLinkStatusMutation,
  useUpdateExternalLinkConfigurationMutation,
  useGetExternalLinkCategoriesQuery,
  useChangeOrderFavouritesMutation,
  useGetExternalLinkFavouritesQuery,
  useGetExternalLinkByIdQuery,
  useAddExternalLinkToFavouritesMutation,
  useGetFavouriteExternalLinkByIdQuery,
  useRemoveFromFavouritesMutation,
} = injectedRtkApi;
