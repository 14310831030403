export enum InvestmentType {
  ONCE = "ONCE",
  REPEATED = "REPEATED",
}

export const translate = (type: InvestmentType | undefined): string => {
  if (!type) return "";
  switch (type) {
    case InvestmentType.ONCE:
      return "Jednorázova";
    case InvestmentType.REPEATED:
      return "Pravidelná";
    default:
      console.error(`Unknown InvestmentType: ${type}`);
      return "Unknown";
  }
};

const all = {
  InvestmentType,
  translate,
};

export default all;
