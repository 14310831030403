import React from "react";

import Loader from "./Loader";

interface Properties {
  size?: number;
}

/**
 * DO NOT ADD ANY KIND OF TRANSLATIONS HERE :)
 */
function PageLoader({ size }: Properties): JSX.Element {
  return (
    <main>
      <div className="loader w-full h-screen flex flex-col md:items-center md:justify-center bg-gray-100 text-gray-700 overflow-hidden">
        <div className="flex flex-col bg-white border border-gray-200 w-24 rounded-xl">
          <div className="text-center py-8 px-8">
            <Loader size={size} />
          </div>
        </div>
      </div>
    </main>
  );
}

PageLoader.defaultProps = {
  size: 32,
};

export default PageLoader;
