import { ExternalLinkTypeKey } from "../api/external-link.generated";
import ExternalLinkTypeEnum from "./ExternalLinkTypeEnum";
import SelectOption from "./SelectOption";

const ExternalLinkType: SelectOption<ExternalLinkTypeKey>[] = [
  {
    key: ExternalLinkTypeEnum.PERSONALIZED,
    name: "external-link.per",
  },
  {
    key: ExternalLinkTypeEnum.GENERAL,
    name: "external-link.gen",
  },
];

export default ExternalLinkType;
