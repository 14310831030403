import React, { FormEvent, useState } from "react";

import {
  faCheckCircle,
  faPencilAlt,
  faSearch,
  faTimes,
  faUserPlus,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import { Customer, useGetCustomersQuery } from "../../api/customers.generated";
import CustomerSelectType from "../../enums/CustomerSelectType";
import TableBodyLoading from "../../features/table/TableBodyLoading";
import TablePagination from "../../features/table/TablePagination";
import { getRoleId } from "../../hooks/role";
import Alert from "../alerts/Alert";
import Button from "../buttons/Button";
import CancelButton from "../buttons/CancelButton";
import CreateNewClientModal from "./CreateNewClientModal";

interface CustomBtnOpenModalProps {
  icon?: IconDefinition;
  text: string;
  className?: string;
}

interface Properties {
  selectedCustomerID?: string;
  btnIcon?: IconDefinition;
  btnLabel: string;
  modalTitle: string;
  submitBtnLabel?: string;
  submitBtnIcon?: IconDefinition;
  callback: (row: Customer) => void;
  callbackCustomerCreate?: (type: CustomerSelectType) => void;
  callbackCustomerEdit?: (row: Customer, type: CustomerSelectType) => void;
  customerSelectType?: CustomerSelectType;
  createEditButtons?: boolean;
  showInitModal?: boolean;
  customBtnOpenModal?: CustomBtnOpenModalProps;
  setShowInitModal?: (value: boolean) => void;
}

export default function CustomerSelectModal({
  selectedCustomerID,
  btnIcon,
  btnLabel,
  modalTitle,
  submitBtnIcon,
  submitBtnLabel,
  customerSelectType,
  createEditButtons,
  callback,
  callbackCustomerCreate,
  callbackCustomerEdit,
  showInitModal,
  setShowInitModal,
  customBtnOpenModal,
}: Properties) {
  const { t } = useTranslation();
  const [showModal, setShowModal] = React.useState(false);

  const initialQuery = {
    size: 10,
    sort: ["lastname", "asc"],
    page: 1,
    keyword: "",
  };
  const [query, setQuery] = useState(initialQuery);

  const { currentData, isFetching } = useGetCustomersQuery(
    { ...query, "X-Role-Id": getRoleId() },
    {
      refetchOnMountOrArgChange: true,
      skip: !showModal,
    }
  );
  const [searchValue, setSearchValue] = useState("");
  const searchInputRef = React.createRef<HTMLInputElement>();
  const [selectedRow, setSelectedRow] = useState<Customer>();
  const [showNewClientModal, setShowNewClientModal] = useState(false);
  const setSelectedUser = (customer: Customer): void => {
    setSelectedRow(customer);
  };

  const selectUserAndClose = (customer: Customer): void => {
    setSelectedRow(customer);
    handleChange();
    cleanUp();
  };

  const searchFilterChange = (event: FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearchValue(value);
    return null;
  };

  const searchCustomers = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === "keyup" &&
      (event as React.KeyboardEvent).key === "Escape"
    ) {
      setShowModal(false);
      return null;
    }

    if (
      event.type === "keyup" &&
      (event as React.KeyboardEvent).key !== "Enter"
    ) {
      return null;
    }

    event.stopPropagation();
    event.preventDefault();

    if (searchValue.length > 2 || searchValue.length === 0) {
      setQuery({
        ...query,
        keyword: searchValue,
        page: 1,
      });
    }

    return null;
  };

  const handleChange = () => {
    setShowModal(false);
    if (selectedRow) {
      callback(selectedRow);
      setSelectedRow(undefined);
    }
  };

  const handleCloseClick = () => {
    cleanUp();
    setSelectedRow(undefined);
    setShowModal(false);
  };

  const handleModalOpen = () => {
    setShowModal(true);
    searchInputRef?.current?.focus();
  };

  const handleCreateCustomerClick = () => {
    setShowNewClientModal(true);
  };

  const callbackOnCustomerNotExist = () => {
    if (callbackCustomerCreate && customerSelectType) {
      callbackCustomerCreate(customerSelectType);
      setSelectedRow(undefined);
    }
  };

  const callbackOnCustomerExist = (pinOrIco: string) => {
    setShowNewClientModal(false);
    setSearchValue(pinOrIco);

    setQuery({
      ...query,
      keyword: pinOrIco,
      page: 1,
    });

    if (currentData?.items) {
      const customer = currentData.items.find(
        (c) => c.pin === pinOrIco || c.ico === pinOrIco
      );

      if (customer) {
        setSelectedUser(customer);
        callback(customer);
        setShowModal(false);
      }
    }
  };

  const handleCustomerEditClick = (customer: Customer) => {
    setSelectedRow(undefined);
    if (callbackCustomerEdit && customerSelectType) {
      callbackCustomerEdit(customer, customerSelectType);
      setSelectedRow(undefined);
    }
  };

  const cleanUp = () => {
    setSearchValue("");
    setQuery(initialQuery);
  };

  React.useEffect(() => {
    if (showModal && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [showModal]);

  return (
    <>
      <div className="inline-flex">
        {customBtnOpenModal ? (
          <Button
            icon={customBtnOpenModal.icon}
            className={customBtnOpenModal.className}
            onClick={handleModalOpen}
            dataTestId="/modal/customer-select/btn-open"
          >
            {customBtnOpenModal.text}
          </Button>
        ) : (
          <Button
            onClick={handleModalOpen}
            className="px-4 py-2 rounded transition-colors duration-500"
            icon={btnIcon}
            dataTestId="/modal/customer-select/btn-open"
          >
            <span>{btnLabel}</span>
          </Button>
        )}
      </div>
      <div className={`${showModal || showInitModal ? "block" : "hidden"}`}>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-normal">
          <div className="relative w-auto my-6 mx-auto max-w-4xl">
            {/* content */}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-240 bg-white text-gray-700 outline-none focus:outline-none">
              {/* header */}
              <div className="p-5 border-b border-solid border-blueGray-200 rounded-t">
                <h3 className="text-2xl inline-block font-semibold">
                  <span>{modalTitle}</span>
                </h3>
                <div className="float-right flex">
                  <button
                    type="button"
                    className="p-1 inline-flex ml-auto bg-transparent border-0 text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={handleCloseClick}
                    data-testid="/modal/customer-select/btn-close"
                  >
                    <span className="bg-transparent h-6 w-6 text-2xl outline-none focus:outline-none flex items-center">
                      <FontAwesomeIcon icon={faTimes} />
                    </span>
                  </button>
                </div>
              </div>
              {/* body */}
              <div className="relative p-6 pt-2 flex-auto">
                <div className="bg-slate-200 p-4 my-2 mb-4 rounded flex">
                  <div className="flex-grow flex border border-gray-300 rounded">
                    <span className="w-8 text-center text-gray-300 px-1 border-gray-300 border-r bg-white rounded-l">
                      <FontAwesomeIcon
                        icon={faSearch}
                        className="text-gray-600 mt-1.5"
                      />
                    </span>
                    <input
                      ref={searchInputRef}
                      type="text"
                      className="w-full border-none text-gray-700 px-4 text-sm py-1 outline-0 rounded-r"
                      placeholder={t("table.filter.name") || ""}
                      value={searchValue}
                      onKeyUp={searchCustomers}
                      onChange={searchFilterChange}
                      data-testid="/modal/customer-select/input-search"
                    />
                  </div>
                  <input
                    type="button"
                    className="ml-1 border border-gray-300 bg-white text-gray-700 px-4 text-sm py-1 outline-0 rounded"
                    value={t("button.search") as string}
                    onClick={searchCustomers}
                    data-testid="/modal/customer-select/btn-search"
                  />
                </div>
                <table className="text-sm">
                  <thead>
                    <tr>
                      <th>
                        <span>{t("table.header.name")}</span>
                      </th>
                      <th>
                        <span>{t("table.header.pin")}</span>
                      </th>
                      <th>
                        <span>{t("table.header.ico")}</span>
                      </th>
                      <th>
                        <span>{t("table.header.email")}</span>
                      </th>
                      {createEditButtons ? <th> </th> : undefined}
                    </tr>
                  </thead>
                  {isFetching && !currentData?.items ? (
                    <TableBodyLoading cols={3} rows={3} />
                  ) : (
                    <tbody>
                      <tr>
                        <td />
                      </tr>
                    </tbody>
                  )}
                  <tbody>
                    {currentData?.items?.map((customer) => (
                      <tr
                        className={
                          customer === selectedRow
                            ? "cursor-pointer bg-green-200 hover:bg-green-300"
                            : "cursor-pointer bg-white"
                        }
                        key={customer.id}
                        onClick={() => setSelectedUser(customer)}
                        onDoubleClick={() => selectUserAndClose(customer)}
                        data-testid={`/modal/customer-select/table-row-${customer.id}`}
                      >
                        <td>
                          <div className="sinline-block struncate">
                            {customer.clientDisplayName}
                          </div>
                        </td>
                        <td>{customer.pin}</td>
                        <td>{customer.ico}</td>
                        <td>{customer.email}</td>
                        {createEditButtons ? (
                          <td className="h-[53px]">
                            <button
                              type="button"
                              className="p1-inline-block"
                              onClick={() => handleCustomerEditClick(customer)}
                              data-testid="/modal/customer-select/btn-edit"
                            >
                              <FontAwesomeIcon icon={faPencilAlt} />
                            </button>
                          </td>
                        ) : (
                          <td />
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
                {!isFetching && currentData?.count === 0 ? (
                  <Alert className="mt-4">
                    <span>{t("table.noData")}</span>
                  </Alert>
                ) : (
                  ""
                )}
              </div>
              {/* footer */}
              <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                <TablePagination
                  callback={setQuery}
                  query={query}
                  count={currentData?.count ?? 0}
                />
                <div className="ml-auto flex gap-2">
                  {createEditButtons ? (
                    <>
                      <Button
                        className="border-0 leading-none outline-none focus:outline-none"
                        onClick={handleCreateCustomerClick}
                        data-testid="/modal/customer-select/btn-add-client"
                        icon={faUserPlus}
                      >
                        <span>{t("app.customer-select-modal.new-client")}</span>
                      </Button>
                      {showNewClientModal ? (
                        <CreateNewClientModal
                          close={() => setShowNewClientModal(false)}
                          callbackOnCustomerNotExist={
                            callbackOnCustomerNotExist
                          }
                          callbackOnCustomerExists={callbackOnCustomerExist}
                        />
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {submitBtnIcon ? (
                    <Button
                      disabled={!selectedRow}
                      icon={submitBtnIcon}
                      onClick={handleChange}
                      data-testid="/modal/customer-select/btn-submit"
                    >
                      <span>{submitBtnLabel}</span>
                    </Button>
                  ) : (
                    <div className="inline-block">
                      <Button
                        onClick={handleChange}
                        disabled={!selectedRow}
                        icon={faCheckCircle}
                        data-testid="/modal/customer-select/btn-select"
                      >
                        <span>{t("button.select")}</span>
                      </Button>
                    </div>
                  )}

                  <CancelButton
                    onClick={handleCloseClick}
                    dataTestId="/modal/customer-select/btn-cancel"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black" />
      </div>
    </>
  );
}

CustomerSelectModal.defaultProps = {
  selectedCustomerID: undefined,
  btnIcon: undefined,
  submitBtnLabel: undefined,
  submitBtnIcon: undefined,
  customerSelectType: CustomerSelectType.CLIENT,
  callbackCustomerCreate: undefined,
  callbackCustomerEdit: undefined,
  createEditButtons: false,
  showInitModal: false,
  customBtnOpenModal: undefined,
  setShowInitModal: undefined,
};
