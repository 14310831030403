import axios from "axios";

import { getRoleId } from "../hooks/role";
import { getAccessToken } from "../hooks/token";
import { ErrorResponse } from "./baseApi";
import { ImportExternalLinkConfigurationsApiResponse } from "./external-link.generated";

const API_URL = "/v1/";

export const importPersonalExternalLinkConfigurations = async (
  formData: FormData
): Promise<ImportExternalLinkConfigurationsApiResponse | ErrorResponse> => {
  return axios
    .post(
      `${API_URL}admin/external-link-configurations/import-personal`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
          "x-role-id": getRoleId(),
        },
      }
    )
    .then((response) => {
      return response as ImportExternalLinkConfigurationsApiResponse;
    })
    .catch((err) => {
      return {
        error: err.response,
        code: err.response.code,
        message: err.response.message,
      } as ErrorResponse;
    });
};

const allFunctions = {
  importPersonalExternalLinkConfigurations,
};

export default allFunctions;
