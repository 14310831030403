import React from "react";

import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

interface Properties {
  className?: string;
  bgClass: string;
  color: string;
  label?: string;
  icon?: IconDefinition;
  pill?: boolean;
  small?: boolean;
  onClick?: () => void;
}

function Badge({
  className,
  bgClass,
  color,
  label,
  icon,
  pill,
  small,
  onClick,
}: Properties) {
  const { t } = useTranslation();
  return (
    <button
      type="button"
      onClick={onClick}
      className={
        `inline-flex flex-nowrap py-0.5 truncate ` +
        `${pill ? "rounded-full px-3" : "rounded px-2.5"} ` +
        `${small ? "text-xs" : "text-sm"} ` +
        `${bgClass} ${color} ${className}`
      }
    >
      {icon !== undefined ? (
        <FontAwesomeIcon icon={icon} size="1x" className="mt-0.5 mr-2" />
      ) : (
        ""
      )}
      <span className="">{t(label ?? "")}</span>
    </button>
  );
}

Badge.defaultProps = {
  pill: false,
  small: false,
  className: "",
  label: "",
  icon: undefined,
  onClick: undefined,
};

export default Badge;
