/* eslint-disable no-nested-ternary */
import React from "react";

import {
  faUser,
  faSyncAlt,
  faRectangleList,
  faDatabase,
  faFileCode,
  faCalendar,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";

import { useGetEventByIdQuery } from "../../api/events.generated";
import BackButton from "../../components/buttons/BackButton";
import Card from "../../components/card/Card";
import CardBody from "../../components/card/CardBody";
import CardHeader from "../../components/card/CardHeader";
import PageHeader from "../../components/page/PageHeader";
import useFormatters from "../../hooks/formatter";
import { getRoleId } from "../../hooks/role";
import Error403View from "../error/Error403View";
import Error404View from "../error/Error404View";
import PageLoader from "../loader/PageLoader";
import AllRoutes from "../route/Route";

function EventEditView() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const { formatTime } = useFormatters();

  const { currentData, isFetching, isError, error } = useGetEventByIdQuery(
    {
      "X-Role-Id": getRoleId(),
      id: id ?? "",
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  if (!isFetching && isError && error) {
    if ("data" in error && error.status === 403) {
      return <Error403View error={error} />;
    }

    return <Error404View />;
  }

  if (isFetching && !currentData) return <PageLoader />;

  return (
    <>
      <PageHeader icon={faRectangleList} label={currentData?.id ?? ""}>
        <div className="px-4 py-2 flex gap-2 rounded-full bg-slate-200">
          <FontAwesomeIcon icon={faRectangleList} className="mt-1" />
          <span>{t(currentData?.type?.key || "")}</span>
        </div>
        <BackButton
          className="mr-2"
          onClick={() => {
            navigate(AllRoutes.EVENTS_LIST.path);
          }}
        />
      </PageHeader>

      <div className="grid grid-cols-1">
        <div className="flex flex-col gap-4">
          <Card>
            <CardHeader icon={faDatabase} label="Data">
              {isFetching ? (
                <FontAwesomeIcon className="mr-2" icon={faSyncAlt} spin />
              ) : (
                ""
              )}
            </CardHeader>
            <CardBody>
              <div className="py-1 min-h-[200px]">
                {JSON.stringify(currentData?.data)}
              </div>
            </CardBody>
          </Card>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4 mt-4">
        <div>
          <Card>
            <CardHeader icon={faFileCode} label={t("card.events.type") || ""} />
            <CardBody>
              <span>{t(currentData?.type?.key || "")}</span>
            </CardBody>
          </Card>
        </div>
        <div>
          <Card>
            <CardHeader icon={faCalendar} label={t("card.events.date") || ""} />
            <CardBody>{formatTime(currentData?.createdAt)}</CardBody>
          </Card>
        </div>
        <div>
          <Card>
            <CardHeader icon={faUser} label={t("input.user.label") || ""} />
            <CardBody>{currentData?.user?.displayName || "-"}</CardBody>
          </Card>
        </div>
        <div>
          <Card>
            <CardHeader icon={faFile} label={t("card.events.document") || ""} />
            <CardBody>
              <div className="min-h-[24px]">
                {currentData?.document ? currentData.document?.displayID : "-"}
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}

export default EventEditView;
