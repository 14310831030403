/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";

import { decode } from "html-entities";

import { Advisor } from "../api/advisors.generated";
import { Customer } from "../api/customers.generated";
import { Document as DocumentApi } from "../api/documents.generated";
import ClientPartnerCheckboxOptions from "../enums/ClientPartnerCheckboxOptions";
import { ClientPartnerRadioOptions } from "../enums/ClientPartnerRadio";
import CustomerSelectType from "../enums/CustomerSelectType";
import { clientPersonTypes, clientTypes } from "../enums/Document";
import { DocumentTypeKeys } from "../enums/DocumentTypes";
import { InvestmentCustomerType } from "../enums/InvestmentCustomerType";
import ZUZDocumentSection from "../enums/ZUZDocumentSection";
import { CheckboxItems } from "../models/CheckboxItem";
import {
  CheckboxItemField,
  CheckboxItemFieldKeys,
  CheckboxItemFields2,
  PrivacyPolicyKeys,
} from "../models/CheckboxItemField";
import {
  CUSTOMER_SKILL_TYPE,
  CustomerSkillTypeCheckbox,
} from "../models/CustomerSkillType";
import { Document } from "../models/Document";
import {
  DocumentContent,
  DocumentContentTypeCheckboxArray,
  DocumentContentTypeCheckboxes,
  DocumentContentTypeDates,
  DocumentContentTypeRadios,
  DocumentContentTypeStrings,
  DocumentContentTypeTables,
  Questionare,
  QuestionaryPoints,
} from "../models/DocumentContent";
import { DocumentMode } from "../models/DocumentMode";
import useFormatters from "./formatter";

const clientPartnerCheckboxDefault = {
  client: true,
  partner: false,
};

const getValue = (
  document: Document,
  key: DocumentContentTypeStrings
): string | number => {
  if (!document.content) return "";

  const value = document.content[key];

  const returnValue = value || "";
  return typeof returnValue === "string" ? decode(returnValue) : returnValue;
};

const getTableValue = (
  document: Document,
  key: DocumentContentTypeTables
): (string | number)[][] => {
  if (!document.content) return [[""]];

  return document.content[key];
};

const getDateValue = (document: Document, key: DocumentContentTypeDates) => {
  if (!document.content || !document.content[key]) return null;

  const stringContentValue = document.content[key] as string;
  return new Date(stringContentValue);
};

const getClientPartnerCheckboxValues = (
  document: Document,
  clientPartnerCheckboxName: DocumentContentTypeCheckboxes
) => {
  if (!document.content) return clientPartnerCheckboxDefault;
  return (
    document.content[clientPartnerCheckboxName] ?? clientPartnerCheckboxDefault
  );
};

const getClientPartnerRadioValues = (
  document: Document,
  clientPartnerRadioName: DocumentContentTypeRadios
): ClientPartnerRadioOptions | undefined => {
  if (!document.content) return undefined;
  return document.content[clientPartnerRadioName] ?? undefined;
};

const isChecked = (
  document: Document,
  checkboxGroup: DocumentContentTypeCheckboxArray,
  name: string
): boolean => {
  const item = document.content[checkboxGroup];

  if (Array.isArray(item)) {
    const findIndex = item.findIndex((i: CheckboxItemField) => i.name === name);
    if (findIndex >= 0) {
      return item[findIndex].checked;
    }
  }

  return false;
};

const setClientPartnerCheckboxValues = (
  document: Document,
  setDocument: React.Dispatch<Document>,
  name: DocumentContentTypeCheckboxes,
  checkboxes: ClientPartnerCheckboxOptions
) => {
  const newContent = { ...document.content };
  newContent[name] = checkboxes;

  setDocument({
    ...document,
    content: newContent,
  });
};

const setClientPartnerRadioValues = (
  document: Document,
  setDocument: React.Dispatch<Document>,
  name: DocumentContentTypeRadios,
  value: ClientPartnerRadioOptions
) => {
  const newContent = { ...document.content };
  newContent[name] = value;

  setDocument({
    ...document,
    content: newContent,
  });
};

const handleInputChange = (
  document: Document,
  setDocument: React.Dispatch<Document>,
  key: DocumentContentTypeStrings,
  event: React.ChangeEvent<HTMLInputElement>
) => {
  const newInputFields = { ...document.content };

  newInputFields[key] = event.target.value;

  setDocument({
    ...document,
    content: newInputFields,
  });
};

const handleTextareaChange = (
  document: Document,
  setDocument: React.Dispatch<Document>,
  key: DocumentContentTypeStrings,
  event: React.ChangeEvent<HTMLTextAreaElement>
) => {
  const newInputFields = { ...document.content };

  newInputFields[key] = event.target.value as string;
  setDocument({
    ...document,
    content: newInputFields,
  });
};

const handleCheckboxChange = (
  document: Document,
  setDocument: React.Dispatch<Document>,
  checkboxFieldKey: DocumentContentTypeCheckboxArray,
  checkboxItemName: string,
  multiple: boolean
) => {
  let newDocument = { ...document };

  if (!document.content[checkboxFieldKey]) {
    newDocument = {
      ...newDocument,
      content: {
        ...newDocument.content,
        [checkboxFieldKey]: [
          {
            name: checkboxItemName as CheckboxItemFieldKeys,
            checked: true,
          },
        ],
      },
    };
    setDocument(newDocument);
  } else {
    const checkboxGroup: CheckboxItemField[] = [];
    document?.content?.[checkboxFieldKey]?.forEach(
      (item: CheckboxItemField) => {
        checkboxGroup.push(item);
      }
    );

    const findIndex = checkboxGroup.findIndex(
      (i: CheckboxItemField) => i.name === checkboxItemName
    );

    const old = checkboxGroup[findIndex]?.checked || false;

    if (!multiple) {
      const newCheckboxGroup: CheckboxItemFields2 = [];
      checkboxGroup.forEach((item: CheckboxItemField) => {
        if (item.name === checkboxItemName) {
          newCheckboxGroup.push({
            name: item.name as CheckboxItemFieldKeys,
            label: item.name,
            checked: old === false,
          });
        } else {
          newCheckboxGroup.push({
            name: item.name as CheckboxItemFieldKeys,
            label: item.name,
            checked: false,
          });
        }
      });
      newDocument = {
        ...newDocument,
        content: {
          ...newDocument.content,
          [checkboxFieldKey]: newCheckboxGroup,
        },
      };
      if (findIndex < 0) {
        newCheckboxGroup.push({
          name: checkboxItemName as CheckboxItemFieldKeys,
          label: checkboxItemName,
          checked: true,
        });
        newDocument = {
          ...newDocument,
          content: {
            ...newDocument.content,
            [checkboxFieldKey]: newCheckboxGroup,
          },
        };
      }
    } else if (checkboxGroup) {
      if (findIndex >= 0) {
        const newCheckboxValue = { ...checkboxGroup[findIndex] };
        newCheckboxValue.checked = old === false;
        checkboxGroup[findIndex] = newCheckboxValue;
        newDocument = {
          ...newDocument,
          content: {
            ...newDocument.content,
            [checkboxFieldKey]: checkboxGroup,
          },
        };
      } else {
        checkboxGroup.push({
          name: checkboxItemName as CheckboxItemFieldKeys,
          label: checkboxItemName,
          checked: true,
        });
        newDocument = {
          ...newDocument,
          content: {
            ...newDocument.content,
            [checkboxFieldKey]: checkboxGroup,
          },
        };
      }
    }

    setDocument(newDocument);
  }
};

const handleTableChange = (
  document: Document,
  setDocument: React.Dispatch<Document>,
  name: DocumentContentTypeTables,
  values: (string | number)[][]
) => {
  const newContent = { ...document?.content };

  newContent[name] = values;

  setDocument({
    ...document,
    content: newContent,
  });
};

const handleCustomerSelected = (
  document: Document,
  setDocument: React.Dispatch<Document>,
  item: Customer
) => {
  const newContent = getCustomerSelectedFields(document.content, item);

  const newDataCustomerSelected: {
    [key: string]: ClientPartnerCheckboxOptions;
  } = {
    clientPFAApproved: newContent.clientPFAApproved,
    clientInformationAcknowledge: newContent.clientInformationAcknowledge,
    clientPFANotAffected: newContent.clientPFANotAffected,
    clientRiskAcknowledge: newContent.clientRiskAcknowledge,
    clientDataApproved: newContent.clientDataApproved,
  };

  for (const [name] of Object.entries(newDataCustomerSelected)) {
    const newName: ClientPartnerCheckboxOptions = {
      ...newDataCustomerSelected[name],
    };
    newName.client = true;
    newDataCustomerSelected[name] = newName;
  }

  const newFinalContent = {
    ...newContent,
    ...newDataCustomerSelected,
  };

  setDocument({
    ...document,
    content: newFinalContent,
  });

  // setSelectedCustomer(item);
};

const handlePartnerSelected = (
  document: Document,
  setDocument: React.Dispatch<Document>,
  item: Customer
) => {
  const newContent = getPartnerSelectedFields(document.content, item);

  const newDataPartnerSelected: {
    [key: string]: ClientPartnerCheckboxOptions;
  } = {
    clientPFAApproved: newContent.clientPFAApproved,
    clientInformationAcknowledge: newContent.clientInformationAcknowledge,
    clientPFANotAffected: newContent.clientPFANotAffected,
    clientRiskAcknowledge: newContent.clientRiskAcknowledge,
    clientDataApproved: newContent.clientDataApproved,
  };

  for (const [name] of Object.entries(newDataPartnerSelected)) {
    const newName: ClientPartnerCheckboxOptions = {
      ...newDataPartnerSelected[name],
    };
    newName.partner = true;
    newDataPartnerSelected[name] = newName;
  }

  const newFinalContent = {
    ...newContent,
    ...newDataPartnerSelected,
  };

  setDocument({
    ...document,
    content: newFinalContent,
  });
};

const handleMentorSelected = (
  document: Document,
  setDocument: React.Dispatch<Document>,
  mentor: Advisor
) => {
  const { formatAddress } = useFormatters();

  setDocument({
    ...document,
    mentor,
    content: {
      ...document?.content,
      mentorID: mentor.id || "",
      mentorName: `${mentor?.firstname} ${mentor?.lastname}`,
      mentorAddress: formatAddress(mentor.permanentAddress),
      mentorNBS: mentor?.nbsRegistrationNumber || "",
      mentorSPF: mentor?.externalId || "",
      mentorBusinessID: mentor?.ico || "",
    },
  });
};

const handleRemoveMentor = (
  document: Document,
  setDocument: React.Dispatch<Document>
) => {
  setDocument({
    ...document,
    mentor: undefined,
    content: {
      ...document?.content,
      mentorID: "",
      mentorName: "",
      mentorAddress: "",
      mentorNBS: "",
      mentorSPF: "",
      mentorBusinessID: "",
    },
  });
};

const handleRemovePartner = (
  document: Document,
  setDocument: React.Dispatch<Document>,
  removedPartnerQuestionare: Questionare,
  setRemovedPartnerQuestionare: React.Dispatch<Questionare>
) => {
  // initalise document without partner
  const doc: Document = {
    ...document,
    content: {
      ...document?.content,
      partnerID: "",
      partnerName: "",
      partnerBirthdate: "",
      partnerPostAddress: "",
      partnerEmail: "",
      partnerPhone: "",
      partnerIdDisplayValue: "",
      partnerIdNumber: "",
      partnerState: "",
      partnerDebtLoansDebt: "",
      partnerLeasingDebt: "",
      partnerOtherDebt: "",
      partnerRepaymentSellDebt: "",
      ddsPartnerDue: "",
      ddsPartnerEmployerDue: "",
      ddsPartnerEntryRequests: "",
      ddsPartnerEstimateSavingTime: "",
      ddsPartnerSector: "",
      financeLoansPartnerEntryRequests: "",

      partnerType: clientTypes.map((i: CustomerSkillTypeCheckbox) => ({
        name: i.name,
        label: i.label,
        checked: false,
      })),
      partnerPrivacyPolicy: [
        {
          name: PrivacyPolicyKeys.NO,
          checked: false,
          label: "nie",
        },
      ],
      partnerHomeAddress: "",
      partnerIdValidUntil: "",
      sdsPartnerEntryRequests: "",
      financeDepositPartnerEntryRequests: "",
      loanRefinanceOtherLoanAmountPartner: "",
      insurancePurposeOtherValue: "",
      insuranceRiskOtherValue: "",
    },
  };

  // partner checkboxes mapping
  const newPartnerCheckboxes: {
    [key: string]: ClientPartnerCheckboxOptions;
  } = {
    clientPFAApproved: doc.content.clientPFAApproved,
    clientInformationAcknowledge: doc.content.clientInformationAcknowledge,
    clientPFANotAffected: doc.content.clientPFANotAffected,
    clientRiskAcknowledge: doc.content.clientRiskAcknowledge,
    clientDataApproved: doc.content.clientDataApproved,
    clientOnHisOwnName: doc.content.clientOnHisOwnName,
    clientOnHisOwnAccount: doc.content.clientOnHisOwnAccount,
    clientWithHisOwnMoney: doc.content.clientWithHisOwnMoney,
    clientContractUnder15000: doc.content.clientContractUnder15000,
    clientFinalUserBenefits: doc.content.clientFinalUserBenefits,
    clientNotPolitic: doc.content.clientNotPolitic,
    clientDisagreeEntryInformation: doc.content.clientDisagreeEntryInformation,
    clientDisagreeEntryInformationAndAskForContract:
      doc.content.clientDisagreeEntryInformationAndAskForContract,
    clientAgreedPFANotResponsible: doc.content.clientAgreedPFANotResponsible,
    sourceIncomeFromActivity: doc.content.sourceIncomeFromActivity,
    sourceIncomeFromLowReserve: doc.content.sourceIncomeFromLowReserve,
    sourceIncomeFromHighReserve: doc.content.sourceIncomeFromHighReserve,
    sourceIncomeOther: doc.content.sourceIncomeOther,
    DDSWantTaxRelief: doc.content.DDSWantTaxRelief,
    SDSWantTaxRelief: doc.content.SDSWantTaxRelief,
    SDSWantVoluntaryDues: doc.content.SDSWantVoluntaryDues,
    insuranceTypeRisk: doc.content.insuranceTypeRisk,
    insuranceTypeCapital: doc.content.insuranceTypeCapital,
    insuranceTypeInvestment: doc.content.insuranceTypeInvestment,
    insuranceTypeAccident: doc.content.insuranceTypeAccident,
    insuranceFamilySecuring: doc.content.insuranceFamilySecuring,
    insuranceIndividualSecuring: doc.content.insuranceIndividualSecuring,
    insurancePensionSecuring: doc.content.insurancePensionSecuring,
    insuranceChildrenSecuring: doc.content.insuranceChildrenSecuring,
    insuranceCreditSecuring: doc.content.insuranceCreditSecuring,
    insuranceUpdatedPaymentCalendar:
      doc.content.insuranceUpdatedPaymentCalendar,
    insurancePurposeOther: doc.content.insurancePurposeOther,
    insuranceOther: doc.content.insuranceOther,
    insurancePaymentOnce: doc.content.insurancePaymentOnce,
    insurancePaymentPeriodically: doc.content.insurancePaymentPeriodically,
    insuranceRiskLifeLost: doc.content.insuranceRiskLifeLost,
    insuranceRiskInvalidity: doc.content.insuranceRiskInvalidity,
    insuranceRiskInsurancePaymentFree:
      doc.content.insuranceRiskInsurancePaymentFree,
    insuranceRiskWorkIncapacity: doc.content.insuranceRiskWorkIncapacity,
    insuranceRiskAccidentDeath: doc.content.insuranceRiskAccidentDeath,
    insuranceRiskAccidentLastingConsequences:
      doc.content.insuranceRiskAccidentLastingConsequences,
    insuranceRiskAccidentDailyCompensation:
      doc.content.insuranceRiskAccidentDailyCompensation,
    insuranceRiskHospitalization: doc.content.insuranceRiskHospitalization,
    insuranceRiskCriticalIllnesses: doc.content.insuranceRiskCriticalIllnesses,
    insuranceRiskSurgery: doc.content.insuranceRiskSurgery,
    insuranceRiskOther: doc.content.insuranceRiskOther,
    insurancePropertyHousehold: doc.content.insurancePropertyHousehold,
    insurancePropertyRealty: doc.content.insurancePropertyRealty,
    insuranceVehicleAccident: doc.content.insuranceVehicleAccident,
    insurancePropertyOther: doc.content.insurancePropertyOther,
    insuranceGeneralLiability: doc.content.insuranceGeneralLiability,
    insurancePZP: doc.content.insurancePZP,
    insuranceLiabilityProfession: doc.content.insuranceLiabilityProfession,
    insuranceLiabilityOther: doc.content.insuranceLiabilityOther,
    insuranceLegalSafetyTransport: doc.content.insuranceLegalSafetyTransport,
    insuranceLegalSafetyOther: doc.content.insuranceLegalSafetyOther,
    insuranceTravel: doc.content.insuranceTravel,
    financeDepositApplyStateAid: doc.content.financeDepositApplyStateAid,
    financeDepositConstructionLoanAssumption:
      doc.content.financeDepositConstructionLoanAssumption,
    financeDepositIntermediateLoanAssumption:
      doc.content.financeDepositIntermediateLoanAssumption,
    loanPurposeRealEstate: doc.content.loanPurposeRealEstate,
    loanPurposeMovableProperty: doc.content.loanPurposeMovableProperty,
    loanPurposeRefinanceOtherLoan: doc.content.loanPurposeRefinanceOtherLoan,
    loanPurposeOther: doc.content.loanPurposeOther,
    loanSecuringRealEstate: doc.content.loanSecuringRealEstate,
    loanSecuringMovableProperty: doc.content.loanSecuringMovableProperty,
    loanSecuringLiability: doc.content.loanSecuringLiability,
    loanSecuringCodebtor: doc.content.loanSecuringCodebtor,
    loanSecuringDebtOtherType: doc.content.loanSecuringDebtOtherType,
    loanEarlyRepaymentRequired: doc.content.loanEarlyRepaymentRequired,
    loanInsuranceRequired: doc.content.loanInsuranceRequired,
  };

  // this will remove partner from all checkboxes
  for (const [name] of Object.entries(newPartnerCheckboxes)) {
    const newName = {
      ...(newPartnerCheckboxes[name] as ClientPartnerCheckboxOptions),
    };
    newName.partner = false;
    newPartnerCheckboxes[name] = newName;
  }

  // this will remove partner from all radio buttons
  const newPartnerRadios: {
    [key: string]: ClientPartnerRadioOptions;
  } = {
    ddsExperiences: doc.content.ddsExperiences,
    sdsExperiences: doc.content.sdsExperiences,
    bankDepositExperiences: doc.content.bankDepositExperiences,
    loanExperiences: doc.content.loanExperiences,
    insuranceExperiences: doc.content.insuranceExperiences,
  };

  for (const [name] of Object.entries(newPartnerRadios)) {
    const newName: ClientPartnerRadioOptions = { ...newPartnerRadios[name] };
    newName.partner = undefined;
    newPartnerRadios[name] = newName;
  }

  const newQuestionaire = { ...doc.content.questionaire };
  const newInvestitionType = { ...newQuestionaire.investitionType };
  newInvestitionType.partner = "";
  newQuestionaire.investitionType = newInvestitionType;

  const newReactionToLoss = { ...newQuestionaire.reactionToLoss };
  newReactionToLoss.partner = "";
  newQuestionaire.reactionToLoss = newReactionToLoss;

  const newKnownFinancialTools = { ...newQuestionaire.knownFinancialTools };
  newKnownFinancialTools.partner = [];
  newQuestionaire.knownFinancialTools = newKnownFinancialTools;

  const newFinancialToolsExperiences = {
    ...newQuestionaire.financialToolsExperience,
  };

  newFinancialToolsExperiences.partner = {};
  newQuestionaire.financialToolsExperience = newFinancialToolsExperiences;

  const newSustainabilityRequest = { ...newQuestionaire.sustainabilityRequest };
  newSustainabilityRequest.partner = undefined;
  newQuestionaire.sustainabilityRequest = newSustainabilityRequest;

  const newESGPreferences = { ...newQuestionaire.esgPreferences };
  newESGPreferences.partner = undefined;

  const newInterestInESG = { ...newQuestionaire.interestInEsg };
  newInterestInESG.partner = undefined;
  newQuestionaire.interestInEsg = newInterestInESG;

  newQuestionaire.investmentIntentionsOnce =
    newQuestionaire.investmentIntentionsOnce.filter(
      (item) => item.investmentCustomerType !== InvestmentCustomerType.PARTNER
    );
  newQuestionaire.investmentIntentionsRepeated =
    newQuestionaire.investmentIntentionsRepeated.filter(
      (item) => item.investmentCustomerType !== InvestmentCustomerType.PARTNER
    );

  const newContent = {
    ...doc.content,
    ...newPartnerCheckboxes,
    ...newPartnerRadios,
    // questionaire: newQuestionaire,
  };

  setDocument({
    ...doc,
    content: newContent,
  });

  setRemovedPartnerQuestionare(newQuestionaire);
};

const handleChangeDate = (
  document: Document,
  setDocument: React.Dispatch<Document>,
  key: DocumentContentTypeDates,
  date: Date
) => {
  const newContent = {
    ...document.content,
  };

  newContent[key] = date;

  setDocument({
    ...document,
    content: newContent,
  });
};

const getCustomersArray = (document: Document | DocumentApi) => {
  const customers: string[] = [];

  if (document?.content?.clientID) {
    customers.push(document.content.clientID);
  }

  if (document?.content?.partnerID) {
    customers.push(document.content.partnerID);
  }

  return customers;
};

const handleShowInvestmentSurvey = (
  document: Document,
  setDocument: React.Dispatch<Document>
) => {
  setDocument({
    ...document,
    content: {
      ...document.content,
      showInvestmentSurvey: !document.content.showInvestmentSurvey,
    },
  });
};

const fillIdCardFields = (item: Customer, customerType: CustomerSelectType) => {
  const { formatDate } = useFormatters();
  let displayValue = "";
  let documentType = "";
  let documentValue = "";
  let validUntilField = "";

  if (item.cop) {
    displayValue = `OP ${item.cop ?? ""}`;
    documentType = "OP";
    documentValue = item.cop ?? "";
    validUntilField = item.opValidUntil ?? "";
  } else if (item.passportNumber) {
    displayValue = `Pas ${item.passportNumber ?? ""}`;
    documentType = "Pas";
    documentValue = item.passportNumber ?? "";

    validUntilField = item.pasValidUntil ?? "";
  } else if (item.otherIDCardType) {
    displayValue = `${item.otherIDCardType ?? ""} ${
      item.otherIDCardNumber ?? ""
    }`;
    documentType = item.otherIDCardType ?? "";
    documentValue = item.otherIDCardNumber ?? "";
    validUntilField = item.otherIDCardValidUntil ?? "";
  }

  if (customerType === CustomerSelectType.CLIENT) {
    return {
      clientIdDisplayValue: displayValue,
      clientIdDocumentType: documentType,
      clientPersonalDocumentValue: documentValue,
      clientIdValidUntil:
        validUntilField !== ""
          ? formatDate(new Date(validUntilField).toDateString())
          : "",
    };
  }

  if (customerType === CustomerSelectType.PARTNER) {
    return {
      partnerIdDisplayValue: displayValue,
      partnerIdDocumentType: documentType,
      partnerPersonalDocumentValue: documentValue,
      partnerIdValidUntil:
        validUntilField !== ""
          ? formatDate(new Date(validUntilField).toDateString())
          : "",
    };
  }

  return {};
};

const getPartnerSelectedFields = (
  content: DocumentContent,
  item: Customer
): DocumentContent => {
  const { formatDate, formatAddress } = useFormatters();
  const newContent = {
    ...content,
    partnerID: item.id,
    partnerName: item.title
      ? `${item?.title} ${item?.firstname} ${item?.lastname}`
      : `${item?.firstname} ${item?.lastname}`,
    partnerDisplayName: item.clientDisplayName,
    partnerBirthdate: formatDate(item?.dateOfBirth),
    partnerPostAddress: formatAddress(item?.correspondenceAddress),
    partnerEmail: item?.email,
    partnerPhone: item?.phoneNumber,
    partnerIdType: item?.cop,
    partnerCompanyName: item?.businessName,
    partnerPersonType: item?.type?.key,
    partnerBusinessNumber: item?.ico,
    partnerIdNumber: item?.pin,
    partnerState: item.citizenship,
    clientBusinessFunction: item?.authorizedPersonPosition,
    clientPFAApproved: {
      ...content.clientPFAApproved,
      partner: true,
    },
    clientInformationAcknowledge: {
      ...content.clientInformationAcknowledge,
      partner: true,
    },
    clientPFANotAffected: {
      ...content.clientPFANotAffected,
      partner: true,
    },
    clientRiskAcknowledge: {
      ...content.clientRiskAcknowledge,
      partner: true,
    },
    clientDataApproved: {
      ...content.clientDataApproved,
      partner: true,
    },
    partnerType: clientTypes.map((i: CheckboxItems) => ({
      name: i.name,
      label: i.label,
      checked: i.name === CUSTOMER_SKILL_TYPE.NON_PROFESSIONAL,
    })),
    partnerHomeAddress: formatAddress(item?.permanentAddress),
    ...fillIdCardFields(item, CustomerSelectType.PARTNER),
  };

  return newContent as DocumentContent;
};

const getCustomerSelectedFields = (
  content: DocumentContent,
  item: Customer
): DocumentContent => {
  const { formatDate, formatAddress } = useFormatters();
  const newContent = {
    ...content,
    clientID: item.id,
    clientName: item.title
      ? `${item?.title} ${item?.firstname} ${item?.lastname}`
      : `${item?.firstname} ${item?.lastname}`,
    clientDisplayName: item.clientDisplayName,
    clientBirthdate: formatDate(item?.dateOfBirth),
    clientEmail: item?.email,
    clientPhone: item?.phoneNumber,
    clientHomeAddress: formatAddress(item?.permanentAddress),
    clientPostAddress: formatAddress(item?.correspondenceAddress),
    clientCompany: item?.businessName,
    clientIdNumber: item?.pin,
    clientOccupation: item?.profession,
    clientBusinessAddress: formatAddress(item?.businessAddress),
    clientBusinessId: item?.ico,
    clientPersonType: clientPersonTypes.map((i: CheckboxItems) => ({
      name: i.name,
      label: i.label,
      checked: false,
    })),
    clientState: item?.citizenship,
    clientBusinessFunction: item?.authorizedPersonPosition,
    clientPFAApproved: {
      ...content.clientPFAApproved,
      client: true,
    },
    clientInformationAcknowledge: {
      ...content.clientInformationAcknowledge,
      client: true,
    },
    clientPFANotAffected: {
      ...content.clientPFANotAffected,
      client: true,
    },
    clientRiskAcknowledge: {
      ...content.clientRiskAcknowledge,
      client: true,
    },
    clientDataApproved: {
      ...content.clientDataApproved,
      client: true,
    },
    ...fillIdCardFields(item, CustomerSelectType.CLIENT),
  };

  const clientPersonTypeIndex = newContent.clientPersonType.findIndex(
    (i) => i.name === item.type?.key
  );

  if (clientPersonTypeIndex >= 0) {
    newContent.clientPersonType[clientPersonTypeIndex].checked = true;
  }

  return newContent as DocumentContent;
};

const getRequestData = (
  mode: DocumentMode,
  document: Document | DocumentApi
) => {
  let mentorId;

  if (document.mentor) {
    mentorId = document.mentor.id || "";
  }

  const customers = getCustomersArray(document);

  const updateContent = {
    ...document.content,
    clientID: customers[0] ?? "",
    partnerID: customers[1] ?? "",
  };

  const request = {
    advisorId: document?.advisor?.id,
    typeKey: DocumentTypeKeys.ZUZ,
    sharedAdvisorId: document?.sharedAdvisor?.id ?? undefined,
    customers,
    mentorId,
    content: JSON.stringify(updateContent),
  };

  return request;
};

const handleQuestionaire = (
  document: Document,
  setDocument: React.Dispatch<Document>,
  value: Questionare,
  questionaryPoints: QuestionaryPoints,
  investmentIntensionsOncePoints: number[],
  investmentIntensionsRepeatedPoints: number[]
) => {
  const answers = Object.entries(questionaryPoints);
  answers.pop();

  const clientPoints = answers
    .map((i) => {
      return i[1].client;
    })
    .reduce((a, b) => a + b);

  const partnerPoints = answers
    .map((i) => {
      return i[1].partner;
    })
    .reduce((a, b) => a + b);

  const questionaryPointsWithTotal: QuestionaryPoints = {
    ...questionaryPoints,
    total: {
      client: clientPoints,
      partner: partnerPoints,
    },
  };

  const newQuestionaire = { ...value };
  const newInvestmentOnce = [...newQuestionaire.investmentIntentionsOnce];
  const newInvestmentRepeated = [
    ...newQuestionaire.investmentIntentionsRepeated,
  ];

  newQuestionaire.investmentIntentionsOnce.forEach((element, index) => {
    const newInvestmentOnceElement = { ...element };
    newInvestmentOnceElement.points =
      investmentIntensionsOncePoints[index].toString() ?? "";
    newInvestmentOnce[index] = newInvestmentOnceElement;
  });

  newQuestionaire.investmentIntentionsRepeated.forEach((element, index) => {
    const newInvestmentRepeatedElement = { ...element };
    newInvestmentRepeatedElement.points =
      investmentIntensionsRepeatedPoints[index].toString() ?? "";
    newInvestmentRepeated[index] = newInvestmentRepeatedElement;
  });

  newQuestionaire.investmentIntentionsOnce = newInvestmentOnce;
  newQuestionaire.investmentIntentionsRepeated = newInvestmentRepeated;

  const newContent = {
    ...document.content,
    questionaire: newQuestionaire,
    questionaryPoints: questionaryPointsWithTotal,
  };

  setDocument({
    ...document,
    content: newContent,
  });
};

const getQuestionaire = (document: Document): Questionare | undefined => {
  if (document.content.questionaire) {
    return document.content.questionaire;
  }

  return undefined;
};

const updateDisabledSections = (
  document: Document,
  setDocument: React.Dispatch<Document>,
  newSections: ZUZDocumentSection[]
) => {
  const newContent = {
    ...document.content,
    disabledSections: newSections,
  };

  setDocument({
    ...document,
    content: newContent,
  });
};

const useDocumentGetters = () => {
  return {
    isChecked,
    getValue,
    getDateValue,
    getTableValue,
    getClientPartnerCheckboxValues,
    getCustomersArray,
    getRequestData,
    getCustomerSelectedFields,
    getPartnerSelectedFields,
    handleTableChange,
    handleCustomerSelected,
    handlePartnerSelected,
    handleMentorSelected,
    handleChangeDate,
    handleShowInvestmentSurvey,
    handleInputChange,
    handleTextareaChange,
    handleCheckboxChange,
    setClientPartnerCheckboxValues,
    handleRemoveMentor,
    handleRemovePartner,
    getClientPartnerRadioValues,
    setClientPartnerRadioValues,
    handleQuestionaire,
    getQuestionaire,
    updateDisabledSections,
  };
};

export default useDocumentGetters;
