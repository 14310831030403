enum EventTypes {
  LOGIN = "et-user-login",
  LOGOUT = "et-user-logout",
  USER_INITIATED_SIGNATURE = "et-user-initiated-signature",
  DOCUMENT_SIGNED = "et-document-signed",
  DOCUMENT_STATE_CHANGED = "et-document-state-changed",
  DOCUMENT_CREATED = "et-document-created",
  DOCUMENT_UPDATED = "et-document-updated",
}

export default EventTypes;
