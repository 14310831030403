import { SignatureTypeKey } from "../api/documents.generated";
import SelectOption from "./SelectOption";
import SignatureType from "./SignatureType";

const SignTypes: SelectOption<SignatureTypeKey>[] = [
  {
    key: SignatureType.EMAIL,
    name: "app.document.sign.email",
  },
  {
    key: SignatureType.LINK,
    name: "app.document.sign.link",
  },
];

export default SignTypes;
