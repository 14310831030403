import React, { useEffect, useMemo, useState } from "react";

import { faFileSignature } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";
import ReactRouterPrompt from "react-router-prompt";
import { toast } from "react-toastify";

import {
  Advisor,
  AdvisorSector,
  useGetAdvisorByIdQuery,
} from "../../api/advisors.generated";
import { useLazyGetCustomerByIdQuery } from "../../api/customers.generated";
import {
  DocumentTypeKey,
  DocumentUpdateRequest,
  useCreateDocumentMutation,
  useGetDocumentByIdQuery,
  useUpdateDocumentMutation,
} from "../../api/documents.generated";
import ToggableAccordion, {
  AccordionStateType,
} from "../../components/accordions/ToggableAccordion";
import BackButton from "../../components/buttons/BackButton";
import Button from "../../components/buttons/Button";
import CancelButton from "../../components/buttons/CancelButton";
import CheckButton from "../../components/buttons/CheckButton";
import SaveButton from "../../components/buttons/SaveButton";
import Card from "../../components/card/Card";
import CardBody from "../../components/card/CardBody";
import FormIsDirtyModal from "../../components/modals/FormIsDirtyModal";
import PageHeader from "../../components/page/PageHeader";
import AdvisorSectorTypes from "../../enums/AdvisorSectorTypes";
import CustomerSelectType from "../../enums/CustomerSelectType";
import { pfaSectors } from "../../enums/Document";
import ZUZDocumentSection from "../../enums/ZUZDocumentSection";
import { getAdvisorDataFromRole } from "../../hooks/advisor";
import useDocumentGetters from "../../hooks/documentContent";
import useDocumentValidation from "../../hooks/documentValidation";
import useFormatters from "../../hooks/formatter";
import { getRoleId } from "../../hooks/role";
import { makeRoute, parseUrl } from "../../hooks/route";
import { useAppSelector } from "../../hooks/store";
import { initialState } from "../../hooks/zuzState";
import { PFASector, PFASectorKeys } from "../../models/CheckboxItemField";
import { Document } from "../../models/Document";
import { DocumentContent, Questionare } from "../../models/DocumentContent";
import { DocumentMode } from "../../models/DocumentMode";
import { authSelector } from "../auth/authSlice";
import Error403View from "../error/Error403View";
import Error404View from "../error/Error404View";
import PageLoader from "../loader/PageLoader";
import AllRoutes from "../route/Route";
import DocumentZuzContext from "./DocumentZuzFormViewContext";
import Page01Card from "./zuz/Page01Card";
import Page02DDSCard from "./zuz/Page02DDSCard";
import Page02InsuranceCard from "./zuz/Page02InsuranceCard";
import Page02SDSCard from "./zuz/Page02SDSCard";
import Page03CapitalMarketCard from "./zuz/Page03CapitalMarketCard";
import Page03CreditCard from "./zuz/Page03CreditCard";
import Page03IncomeCard from "./zuz/Page03IncomeCard";
import Page04Card from "./zuz/Page04Card";
import Page05Card from "./zuz/Page05Card";
import Page06Card from "./zuz/Page06Card";
import SignatureTypeCard from "./zuz/SignatureTypeCard";

const ZUZ_STORAGE_KEY = "ZUZDocumentDeveloped";

interface Properties {
  mode: DocumentMode;
}

function DocumentZuzFormView({ mode }: Properties) {
  const { id } = useParams();
  const location = useLocation();
  const parsedQueryParams = parseUrl(location.search);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { currentRole } = useAppSelector(authSelector);
  const [searchParams, setSearchParams] = useSearchParams();
  const { formatAddress } = useFormatters();
  const [document, setDocument] = useState<Document>({} as Document);
  const [removedPartnerQuestionare, setRemovedPartnerQuestionare] =
    useState<Questionare>({} as Questionare);
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [showFormIsDirtyModal, setShowFormIsDirtyModal] = useState(false);
  const [blockNavigation, setBlockNavigation] = useState(false);

  const [openAccordions, setOpenAccordions] = useState<string[]>([]);

  const onOpenAccordionClick = (uuid: string) => {
    const newOpenAccordions = [...openAccordions];
    newOpenAccordions.push(uuid);

    setOpenAccordions(newOpenAccordions);
  };

  const {
    getCustomerSelectedFields,
    getPartnerSelectedFields,
    getRequestData,
    updateDisabledSections,
  } = useDocumentGetters();

  const {
    currentData,
    isSuccess,
    isError,
    isFetching,
    error,
    refetch: refetchDocument,
  } = useGetDocumentByIdQuery(
    {
      "X-Role-Id": getRoleId(),
      id: id ?? "0",
    },
    { refetchOnMountOrArgChange: true, skip: mode === DocumentMode.CREATE }
  );

  const advisorInitData = getAdvisorDataFromRole(currentRole);
  const shouldQueryAdvisor = mode === DocumentMode.EDIT;
  const { currentData: advisorData, isSuccess: advisorSuccess } =
    useGetAdvisorByIdQuery(
      {
        "X-Role-Id": getRoleId(),
        id:
          searchParams.get("advisorId") ??
          (advisorInitData && advisorInitData.id ? advisorInitData.id : ""),
      },
      {
        skip: !searchParams.get("advisorId") && shouldQueryAdvisor,
      }
    );

  const [getCustomerData] = useLazyGetCustomerByIdQuery();

  // create document
  const [createDocument] = useCreateDocumentMutation();

  // update document
  const [updateDocument] = useUpdateDocumentMutation();

  const [disabledAccordions, setDisabledAccordions] = useState<
    Array<ZUZDocumentSection>
  >([]);

  const [initializedContent, setInitializedContent] = useState<FormData>();

  const handleSaveDocumentAndCreateCustomer = (
    customerType: CustomerSelectType
  ) => {
    const requestData: DocumentUpdateRequest = getRequestData(mode, document);
    setBlockNavigation(false);

    if (mode === DocumentMode.EDIT) {
      updateDocument({
        "X-Role-Id": getRoleId(),
        id: document.id || "0",
        documentUpdateRequest: requestData,
      }).then((response: any) => {
        if (response.error) {
          return toast.warning(t("app.document.toast.update-failed"), {
            closeOnClick: true,
          });
        }
        if (response.data.status === "ok") {
          // Reload data in background
          refetchDocument();
          setIsCreating(false);
          return navigate(
            makeRoute(AllRoutes.CUSTOMERS_ADD, {}, [
              {
                name: "document",
                value: currentData?.id || "0",
              },
              {
                name: "customerType",
                value: customerType,
              },
            ])
          );
        }

        return "";
      });
    }

    if (mode === DocumentMode.CREATE) {
      createDocument({
        "X-Role-Id": getRoleId(),
        documentUpdateRequest: requestData,
      }).then((response: any) => {
        if (response.error) {
          setIsCreating(false);
          sessionStorage.setItem(ZUZ_STORAGE_KEY, JSON.stringify(document));
          return navigate(
            makeRoute(AllRoutes.CUSTOMERS_ADD, {}, [
              {
                name: "document",
                value: "developed",
              },
              {
                name: "customerType",
                value: customerType,
              },
            ])
          );
        }
        const newId = response.data.id;
        if (newId) {
          setIsCreating(false);
          return navigate(
            makeRoute(AllRoutes.CUSTOMERS_ADD, {}, [
              {
                name: "document",
                value: newId,
              },
              {
                name: "customerType",
                value: customerType,
              },
            ])
          );
        }
        return toast.warning(t("app.page.error.defaultMessage"));
      });
    }
  };

  const handleSaveDocumentAndEditCustomer = (
    customerId: string,
    customerType: CustomerSelectType
  ) => {
    setBlockNavigation(false);
    const requestData = getRequestData(mode, document);
    if (mode === DocumentMode.EDIT) {
      updateDocument({
        "X-Role-Id": getRoleId(),
        id: document.id || "0",
        documentUpdateRequest: requestData,
      }).then((response: any) => {
        if (response.error) {
          setBlockNavigation(true);
          return toast.warning(t("app.document.toast.update-failed"), {
            closeOnClick: true,
          });
        }
        if (response.data && response.data.status === "ok") {
          // Reload data in background
          refetchDocument();
          setIsCreating(false);
          if (customerId) {
            return navigate(
              makeRoute(AllRoutes.CUSTOMERS_EDIT, { id: customerId }, [
                {
                  name: "document",
                  value: currentData?.id || "0",
                },
                {
                  name: "customerType",
                  value: customerType,
                },
              ])
            );
          }
        }

        return "";
      });
    }

    if (mode === DocumentMode.CREATE) {
      createDocument({
        "X-Role-Id": getRoleId(),
        documentUpdateRequest: requestData,
      }).then((response: any) => {
        setBlockNavigation(false);
        if (response.error) {
          setIsCreating(false);
          setBlockNavigation(true);
          sessionStorage.setItem(ZUZ_STORAGE_KEY, JSON.stringify(document));
          return navigate(
            makeRoute(AllRoutes.CUSTOMERS_EDIT, { id: customerId }, [
              {
                name: "document",
                value: "developed",
              },
              {
                name: "customerType",
                value: customerType,
              },
            ])
          );
        }
        const newId = response.data.id;
        if (newId) {
          setIsCreating(false);
          return navigate(
            makeRoute(AllRoutes.CUSTOMERS_EDIT, { id: customerId }, [
              {
                name: "document",
                value: newId,
              },
              {
                name: "customerType",
                value: customerType,
              },
            ])
          );
        }
        return toast.warning(t("app.page.error.defaultMessage"));
      });
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    e.stopPropagation();
    return false;
  };

  const scrollToTopFn = () => {
    // get element tag main
    const main = window.document.querySelector("main");

    // scroll to top
    if (main) {
      main.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const doSubmit = async (scrollToTop: boolean) => {
    setIsCreating(true);
    setBlockNavigation(false);

    if (scrollToTop) {
      scrollToTopFn();
    }

    if (mode === DocumentMode.EDIT) {
      updateDocument({
        "X-Role-Id": getRoleId(),
        id: currentData?.id || "0",
        documentUpdateRequest: getRequestData(mode, document),
      }).then((response: any) => {
        if (response.error) {
          setIsCreating(false);
          setBlockNavigation(true);
          return toast.warning(t("app.document.toast.update-failed"), {
            closeOnClick: true,
          });
        }
        if (response.data.status === "ok") {
          // Reload data in background
          refetchDocument();
          toast.success(t("app.document.toast.update-success"));
          setIsCreating(false);
          const initializedFormData = getRequestData(mode, document);
          setInitializedContent(initializedFormData);
          return redirectToDetail(id ?? "");
        }

        return toast.warning(t("app.page.error.defaultMessage"));
      });
    }

    if (mode === DocumentMode.CREATE) {
      createDocument({
        "X-Role-Id": getRoleId(),
        documentUpdateRequest: getRequestData(mode, document),
      }).then((response: any) => {
        if (response.error) {
          setBlockNavigation(true);
          setIsCreating(false);
          return toast.warning(t("app.document.toast.create-failed"), {
            closeOnClick: true,
          });
        }
        const newId = response.data.id;
        if (newId) {
          setIsCreating(false);
          toast.success(t("app.document.toast.create-success"));
          return redirectToDetail(newId);
        }
        return toast.warning(t("app.page.error.defaultMessage"));
      });
    }
  };

  const redirectToDetail = (documentId: string): void => {
    const path = makeRoute(AllRoutes.DOCUMENT_VIEW_ZUZ, {
      id: documentId,
    });
    navigate(path);
  };

  const handleAccordionChange = (s: AccordionStateType) => {
    if (s.disabled) {
      // add to disabled
      const newDisabledAccordions = [
        ...disabledAccordions,
        s.uuid as ZUZDocumentSection,
      ];
      setDisabledAccordions(newDisabledAccordions);
      updateDisabledSections(document, setDocument, newDisabledAccordions);
      if (s.uuid === ZUZDocumentSection.INVEST_QUESTIONNAIRE) {
        setDocument({
          ...document,
          content: {
            ...document.content,
            disabledSections: newDisabledAccordions,
            showInvestmentSurvey: false,
          },
        });
      }
    } else {
      // remove from disabled
      const newDisabledAccordions = disabledAccordions.filter(
        (d) => d !== s.uuid
      );
      setDisabledAccordions(newDisabledAccordions);
      updateDisabledSections(document, setDocument, newDisabledAccordions);
      if (s.uuid === ZUZDocumentSection.INVEST_QUESTIONNAIRE) {
        setDocument({
          ...document,
          content: {
            ...document.content,
            disabledSections: newDisabledAccordions,
            showInvestmentSurvey: true,
          },
        });
      }
    }
  };

  const handleAccordionChangeAndEnableQuestionaire = (
    s: AccordionStateType
  ) => {
    let newDisabledAccordions = [...disabledAccordions];
    if (s.disabled) {
      // add to disabled
      newDisabledAccordions.push(s.uuid as ZUZDocumentSection);

      // setDisabledAccordions(newDisabledAccordions);
      // updateDisabledSections(document, setDocument, newDisabledAccordions);
      if (s.uuid === ZUZDocumentSection.INVEST_QUESTIONNAIRE) {
        setDocument({
          ...document,
          content: {
            ...document.content,
            disabledSections: newDisabledAccordions,
            showInvestmentSurvey: false,
          },
        });
      }
    } else {
      // remove from disabled
      newDisabledAccordions = newDisabledAccordions.filter((d) => d !== s.uuid);
      // setDisabledAccordions(newDisabledAccordions);
      // updateDisabledSections(document, setDocument, newDisabledAccordions);
      if (s.uuid === ZUZDocumentSection.INVEST_QUESTIONNAIRE) {
        setDocument({
          ...document,
          content: {
            ...document.content,
            disabledSections: newDisabledAccordions,
            showInvestmentSurvey: true,
          },
        });
      }
    }

    if (
      newDisabledAccordions.some(
        (item) => item === ZUZDocumentSection.INVEST_QUESTIONNAIRE
      )
    ) {
      newDisabledAccordions = newDisabledAccordions.filter(
        (d) => d !== ZUZDocumentSection.INVEST_QUESTIONNAIRE
      );
    }

    setDisabledAccordions(newDisabledAccordions);
    updateDisabledSections(document, setDocument, newDisabledAccordions);
  };

  const {
    errors: validationErrors,
    validate: runValidaton,
    getSectionErrors,
    getFieldError,
    resetFieldError,
    resetFieldsErrors,
  } = useDocumentValidation();

  const validate = () => {
    scrollToTopFn();
    setOpenAccordions([]);
    const requestData = getRequestData(mode, document);
    const content = JSON.parse(requestData.content as string);

    const fields = runValidaton(
      content,
      disabledAccordions as ZUZDocumentSection[]
    );

    if (fields.length > 0) {
      toast.warn(t("toast.document.error.validation"));
    } else {
      toast.success(t("toast.document.success.validation"));
    }

    const windowErrors = window.document.getElementsByClassName("text-red-400");

    if (windowErrors.length > 0) {
      // get element tag main
      const main = window.document.querySelector("main");
      const topError = windowErrors[0] as HTMLDivElement;
      const topOffset = topError.offsetTop - 64 - 100; // top menu + input
      if (main) {
        main.scrollTo({
          top: topOffset,
          behavior: "smooth",
        });
      }
    }
  };

  const memoContext = useMemo(
    () => ({
      resetFieldError,
      getSectionErrors,
      validationErrors,
      getFieldError,
      resetFieldsErrors,
    }),
    [
      validationErrors,
      getSectionErrors,
      resetFieldError,
      getFieldError,
      resetFieldsErrors,
    ]
  );

  type FormData = {
    [key: string]: any;
    advisorId: string | undefined;
    typeKey: DocumentTypeKey;
    customers: string[];
    mentorId: string | undefined;
    content: string;
  };

  const getDirtyFields = (
    formData: FormData | undefined,
    compare: FormData
  ) => {
    if (!formData) {
      return [];
    }

    const dirtyFields: string[] = [];
    Object.keys(formData).forEach((item) => {
      if (item === "customers") {
        if (
          formData[item].length > 0 &&
          compare[item].length > 0 &&
          formData[item] !== compare[item] &&
          formData[item][0] !== compare[item][0]
        ) {
          dirtyFields.push(item);
        }
      } else {
        const isDirty = formData[item] !== compare[item];
        if (isDirty) {
          dirtyFields.push(item);
        }
      }
    });

    return dirtyFields;
  };

  useEffect(() => {
    if (isFormInitialized) {
      const requestData = getRequestData(mode, document);
      const dirtyFields = getDirtyFields(initializedContent, requestData);

      if (dirtyFields.length > 0) {
        setBlockNavigation(true);
      } else {
        setBlockNavigation(false);
      }
    }
  }, [document, isFormInitialized]);

  // ######################################################### CONDITIONAL LOGIC

  React.useEffect(() => {
    // Init Input fields
    if (
      !isFormInitialized &&
      // Vytvaram a potrebujem mat rolu a advisor
      ((mode === DocumentMode.CREATE && currentRole && advisorSuccess) ||
        // Upravujem a potrebujem mat dokument
        (mode === DocumentMode.EDIT && isSuccess && !isFetching))
      // v pripade, ze mam customera v query, tak musim pockat na nacitanie
    ) {
      // Init new input fields
      let newDocument: Document = {
        ...document,
        content: {
          ...(initialState as DocumentContent),
        },
      };
      setDisabledAccordions(initialState.disabledSections);

      // Init for ADD = Init PFA with current Advisor
      if (
        mode === DocumentMode.CREATE &&
        !newDocument.content.pfaID &&
        currentRole
      ) {
        newDocument.advisor = advisorData as Advisor;

        // Prepare sectors
        const fetchedSectors = advisorData?.sectors
          ? advisorData?.sectors.filter((s: AdvisorSector) => {
              return s.type?.key !== AdvisorSectorTypes.AML;
            })
          : [];
        const newPFASectors = pfaSectors.map((s): PFASector => {
          const advisorSector = fetchedSectors
            .filter((n) => {
              return n.type?.key === s.name;
            })
            ?.pop();
          const now = new Date();
          return {
            name: s.name,
            label: s.label,
            checked:
              new Date(advisorSector?.registration_from ?? now) < now &&
              (new Date(advisorSector?.registration_to ?? now) > now ||
                advisorSector?.registration_to === null),
          };
        });

        newDocument.content = {
          ...newDocument.content,
          pfaID: advisorData?.id || "",
          pfaName:
            `${advisorData?.title} ${advisorData?.firstname} ${advisorData?.lastname}`.trim(),
          pfaCompany: advisorData?.businessName ?? "",
          pfaNBS: advisorData?.nbsRegistrationNumber ?? "",
          pfaSPFId: advisorData?.evidenceNumber ?? "",
          pfaICO: advisorData?.ico ?? "",
          pfaAddress: formatAddress(advisorData?.businessAddress),
          pfaSector: newPFASectors,
          pfaEmail: advisorData?.user?.email ?? "",
        };

        // init questionaires
        newDocument.content = {
          ...newDocument.content,
          questionaire: {
            esgPreferences: { client: undefined, partner: undefined },
            financialToolsExperience: { client: {}, partner: {} },
            interestInEsg: { client: undefined, partner: undefined },
            investitionType: { client: "", partner: "" },
            investmentIntentionsOnce: [],
            investmentIntentionsRepeated: [],
            knownFinancialTools: { client: [], partner: [] },
            reactionToLoss: { client: "", partner: "" },
            sustainabilityRequest: { client: undefined, partner: undefined },
          },
          questionaryPoints: {
            financialToolsExperience: { client: 0, partner: 0 },
            investitionType: { client: 0, partner: 0 },
            knownFinancialTools: { client: 0, partner: 0 },
            reactionToLoss: { client: 0, partner: 0 },
            total: { client: 0, partner: 0 },
          },
        };
      }

      // Init data from API for EDIT
      // This must be done before the session storage is read
      if (mode === DocumentMode.EDIT && currentData && isSuccess) {
        newDocument = currentData as unknown as Document;
        setDisabledAccordions(newDocument.content.disabledSections);
      }

      if (
        Object.keys(parsedQueryParams).includes("customer") &&
        Object.keys(parsedQueryParams).includes("customerType")
      ) {
        // Overwrite from session storage
        const savedDocument = sessionStorage.getItem(ZUZ_STORAGE_KEY);
        if (savedDocument) {
          // Overwrite
          newDocument = { ...JSON.parse(savedDocument) };

          // Clean up
          sessionStorage.removeItem(ZUZ_STORAGE_KEY);
        }

        // Do we need to
        const { customerType } = parsedQueryParams;
        if (customerType) {
          if (customerType === CustomerSelectType.CLIENT) {
            getCustomerData({
              "X-Role-Id": getRoleId(),
              id: parsedQueryParams.customer ?? "",
            }).then((response) => {
              if (response.data) {
                newDocument = {
                  ...newDocument,
                  content: getCustomerSelectedFields(
                    newDocument.content,
                    response.data
                  ),
                };
                setDocument(newDocument);
              }
            });
          }

          if (customerType === CustomerSelectType.PARTNER) {
            getCustomerData({
              "X-Role-Id": getRoleId(),
              id: parsedQueryParams.customer ?? "",
            }).then((response) => {
              if (response.data) {
                newDocument = {
                  ...newDocument,
                  content: getPartnerSelectedFields(
                    newDocument.content,
                    response.data
                  ),
                };
                setDocument(newDocument);
              }
            });
          }

          // Remove from location
          searchParams.delete("customerType");
          searchParams.delete("customer");

          // Search params
          setSearchParams(searchParams);
        }
      }

      // Block rerender
      setIsFormInitialized(true);
      setDocument(newDocument);

      const initializedFormData = getRequestData(mode, newDocument);
      setInitializedContent(initializedFormData);
    }
  });

  // Handle error from responses
  if (id && id.length > 1 && !isFetching && isError && error) {
    if ("data" in error && error.status === 403) {
      return <Error403View error={error} />;
    }

    return <Error404View />;
  }

  // If the component still was not initialized
  if (!isFormInitialized) {
    return <PageLoader />;
  }

  return (
    <DocumentZuzContext.Provider value={memoContext}>
      <PageHeader
        icon={faFileSignature}
        label={`${
          mode === DocumentMode.CREATE
            ? t("app.page.documents.create.heading")
            : t("app.page.documents.edit.heading")
        }
          eZUZ`}
      >
        <CheckButton
          onClick={() => validate()}
          dataTestId="/documents/zuz/btn-check"
        />
        <BackButton
          dataTestId="/documents/zuz/btn-back"
          onClick={() => {
            navigate(
              makeRoute(
                mode === DocumentMode.CREATE
                  ? AllRoutes.DOCUMENTS_LIST_ZUZ
                  : AllRoutes.DOCUMENT_VIEW_ZUZ,
                { id }
              )
            );
          }}
        />
        <SaveButton
          inProgress={isCreating}
          disabled={
            isCreating ||
            (mode === DocumentMode.CREATE &&
              (!document ||
                !document?.advisor?.id ||
                !document.content.clientID))
          }
          dataTestId="/documents/zuz/btn-save"
          onClick={() => doSubmit(false)}
        />
      </PageHeader>
      <form
        id="documentForm"
        className="max-w-screen-xl mb-8"
        onSubmit={handleSubmit}
      >
        <div>
          <ToggableAccordion
            title={t("app.document.sign-and-share.title")}
            uuid={ZUZDocumentSection.SIGNATURE}
            errorsCount={getSectionErrors(ZUZDocumentSection.SIGNATURE).length}
            onOpenAccordionClick={() =>
              onOpenAccordionClick(ZUZDocumentSection.SIGNATURE)
            }
            defaultOpen={openAccordions.includes(ZUZDocumentSection.SIGNATURE)}
          >
            <SignatureTypeCard document={document} setDocument={setDocument} />
          </ToggableAccordion>
          <ToggableAccordion
            uuid={ZUZDocumentSection.AGENT_CLINET_PARTNER}
            title="Finančný agent / Klient / Partner"
            errorsCount={
              getSectionErrors(ZUZDocumentSection.AGENT_CLINET_PARTNER).length
            }
            defaultOpen={openAccordions.includes(
              ZUZDocumentSection.AGENT_CLINET_PARTNER
            )}
            onOpenAccordionClick={() =>
              onOpenAccordionClick(ZUZDocumentSection.AGENT_CLINET_PARTNER)
            }
          >
            <Page01Card
              document={document}
              setDocument={setDocument}
              removedPartnerQuestionare={removedPartnerQuestionare}
              setRemovedPartnerQuestionare={setRemovedPartnerQuestionare}
              handleSaveDocumentAndCreateCustomer={
                handleSaveDocumentAndCreateCustomer
              }
              handleSaveDocumentAndEditCustomer={
                handleSaveDocumentAndEditCustomer
              }
            />
          </ToggableAccordion>
          <ToggableAccordion
            uuid={ZUZDocumentSection.DDS}
            title="Sprostredkovanie finančných služieb v sektore DDS"
            disabled={disabledAccordions?.includes(ZUZDocumentSection.DDS)}
            onDisableButtonClick={handleAccordionChange}
            defaultOpen={openAccordions.includes(ZUZDocumentSection.DDS)}
            onOpenAccordionClick={() =>
              onOpenAccordionClick(ZUZDocumentSection.DDS)
            }
            errorsCount={getSectionErrors(ZUZDocumentSection.DDS).length}
            dataTestId="/documents/zuz/btn-toggle-sektor-dds"
          >
            <Page02DDSCard document={document} setDocument={setDocument} />
          </ToggableAccordion>
          <ToggableAccordion
            uuid={ZUZDocumentSection.SDS}
            title="Sprostredkovanie finančných služieb v sektore SDS"
            disabled={disabledAccordions?.includes(ZUZDocumentSection.SDS)}
            onDisableButtonClick={handleAccordionChange}
            defaultOpen={openAccordions.includes(ZUZDocumentSection.SDS)}
            onOpenAccordionClick={() =>
              onOpenAccordionClick(ZUZDocumentSection.SDS)
            }
            errorsCount={getSectionErrors(ZUZDocumentSection.SDS).length}
            dataTestId="/documents/zuz/btn-toggle-sektor-sds"
          >
            <Page02SDSCard document={document} setDocument={setDocument} />
          </ToggableAccordion>
          <ToggableAccordion
            uuid={ZUZDocumentSection.INSURANCE}
            title="Sprostredkovanie finančných služieb v sektore Poistenia alebo
            zaistenia"
            disabled={disabledAccordions?.includes(
              ZUZDocumentSection.INSURANCE
            )}
            onDisableButtonClick={handleAccordionChange}
            defaultOpen={openAccordions.includes(ZUZDocumentSection.INSURANCE)}
            onOpenAccordionClick={() =>
              onOpenAccordionClick(ZUZDocumentSection.INSURANCE)
            }
            errorsCount={getSectionErrors(ZUZDocumentSection.INSURANCE).length}
            dataTestId="/documents/zuz/btn-toggle-sektor-paz"
          >
            <Page02InsuranceCard
              document={document}
              setDocument={setDocument}
            />
          </ToggableAccordion>
          <ToggableAccordion
            uuid={ZUZDocumentSection.INCOME}
            title="Sprostredkovanie finančných služieb v sektore Prijímania vkladov"
            disabled={disabledAccordions?.includes(ZUZDocumentSection.INCOME)}
            onDisableButtonClick={handleAccordionChange}
            defaultOpen={openAccordions.includes(ZUZDocumentSection.INCOME)}
            onOpenAccordionClick={() =>
              onOpenAccordionClick(ZUZDocumentSection.INCOME)
            }
            errorsCount={getSectionErrors(ZUZDocumentSection.INCOME).length}
            dataTestId="/documents/zuz/btn-toggle-sektor-pvk"
          >
            <Page03IncomeCard document={document} setDocument={setDocument} />
          </ToggableAccordion>
          <ToggableAccordion
            uuid={ZUZDocumentSection.CREDIT}
            title="Sprostredkovanie finančných služieb v sektore Poskytovania úverov"
            disabled={disabledAccordions?.includes(ZUZDocumentSection.CREDIT)}
            onDisableButtonClick={handleAccordionChange}
            defaultOpen={openAccordions.includes(ZUZDocumentSection.CREDIT)}
            onOpenAccordionClick={() =>
              onOpenAccordionClick(ZUZDocumentSection.CREDIT)
            }
            errorsCount={getSectionErrors(ZUZDocumentSection.CREDIT).length}
            dataTestId="/documents/zuz/btn-toggle-sektor-puv"
          >
            <Page03CreditCard document={document} setDocument={setDocument} />
          </ToggableAccordion>
          <ToggableAccordion
            uuid={ZUZDocumentSection.CAPITAL_MARKET}
            title="Sprostredkovanie finančných služieb v sektore Kapitálového trhu"
            disabled={disabledAccordions?.includes(
              ZUZDocumentSection.CAPITAL_MARKET
            )}
            onDisableButtonClick={handleAccordionChangeAndEnableQuestionaire}
            dataTestId="/documents/zuz/btn-toggle-sektor-ktr"
            errorsCount={
              getSectionErrors(ZUZDocumentSection.CAPITAL_MARKET).length
            }
          >
            <Page03CapitalMarketCard
              document={document}
              setDocument={setDocument}
            />
          </ToggableAccordion>
          <ToggableAccordion
            uuid={ZUZDocumentSection.INVEST_QUESTIONNAIRE}
            title="Investičný dotazník"
            disabled={disabledAccordions?.includes(
              ZUZDocumentSection.INVEST_QUESTIONNAIRE
            )}
            errorsCount={
              getSectionErrors(ZUZDocumentSection.INVEST_QUESTIONNAIRE).length
            }
            onDisableButtonClick={(state) => {
              // if are enabled capital market, dont disable
              if (
                !disabledAccordions?.includes(ZUZDocumentSection.CAPITAL_MARKET)
              ) {
                return;
              }

              handleAccordionChange(state);
            }}
            defaultOpen={openAccordions.includes(
              ZUZDocumentSection.INVEST_QUESTIONNAIRE
            )}
            onOpenAccordionClick={() =>
              onOpenAccordionClick(ZUZDocumentSection.INVEST_QUESTIONNAIRE)
            }
            dataTestId="/documents/zuz/btn-toggle-ido"
          >
            <Page04Card
              document={document}
              setDocument={setDocument}
              removedPartnerQuestionare={removedPartnerQuestionare}
              setRemovedPartnerQuestionare={setRemovedPartnerQuestionare}
            />
          </ToggableAccordion>
          <ToggableAccordion
            uuid={ZUZDocumentSection.SOLUTIONS}
            title="Riešenia"
            defaultOpen={openAccordions.includes(ZUZDocumentSection.SOLUTIONS)}
            onOpenAccordionClick={() =>
              onOpenAccordionClick(ZUZDocumentSection.SOLUTIONS)
            }
            errorsCount={getSectionErrors(ZUZDocumentSection.SOLUTIONS).length}
          >
            <Page05Card document={document} setDocument={setDocument} />
          </ToggableAccordion>
          <ToggableAccordion
            uuid={ZUZDocumentSection.CONSENT}
            title="Súhlas"
            defaultOpen={openAccordions.includes(ZUZDocumentSection.CONSENT)}
            onOpenAccordionClick={() =>
              onOpenAccordionClick(ZUZDocumentSection.CONSENT)
            }
            errorsCount={getSectionErrors(ZUZDocumentSection.CONSENT).length}
          >
            <Page06Card document={document} setDocument={setDocument} />
          </ToggableAccordion>
        </div>
      </form>
      <Card>
        <CardBody className="flex justify-end gap-4">
          <CheckButton onClick={() => validate()} />
          <SaveButton
            onClick={() => doSubmit(true)}
            inProgress={isCreating}
            disabled={
              mode === DocumentMode.CREATE &&
              (!document ||
                !document?.advisor?.id ||
                !document.content.clientID)
            }
          />
        </CardBody>
      </Card>
      <FormIsDirtyModal
        show={showFormIsDirtyModal}
        setShow={setShowFormIsDirtyModal}
        callback={() => {
          navigate(
            makeRoute(
              mode === DocumentMode.CREATE
                ? AllRoutes.DOCUMENTS_LIST_ZUZ
                : AllRoutes.DOCUMENT_VIEW_ZUZ,
              { id }
            )
          );
        }}
      />
      <ReactRouterPrompt when={blockNavigation}>
        {({ isActive, onConfirm, onCancel }) =>
          isActive && (
            <>
              <div className="opacity-25 fixed inset-0 z-40 bg-black" />
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto mx-auto max-w-3xl">
                  {/* content */}
                  <div className="rounded-lg shadow-xl relative flex flex-col bg-white w-96">
                    {/* header */}
                    <div className="flex flex-cols px-4 py-4" />
                    {/* body */}
                    <div className="px-4 py-4 my-4 text-blueGray-500">
                      <span>{t("formIsDirtyModal.question")}</span>
                    </div>
                    <div className="border-gray-300 text-center">
                      <div className="px-4 py-4">
                        <Button className="mr-1" onClick={onConfirm}>
                          <span>{t("button.yes")}</span>
                        </Button>
                        <CancelButton onClick={onCancel} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
        }
      </ReactRouterPrompt>
    </DocumentZuzContext.Provider>
  );
}

export default DocumentZuzFormView;
