export const fixTimezoneOffset = (date: Date): Date => {
  const dateWithoutTime = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate()
  );
  return new Date(dateWithoutTime.getTime() - date.getTimezoneOffset() * 60000);
};

const allTimeZoneFunctions = {
  fixTimezoneOffset,
};

export default allTimeZoneFunctions;
