import { FEMALE, MALE } from "../enums/Gender";

export const isIdentificationNumberSameAsBirthDate = (
  identificationNumber: string | undefined,
  birthDate: string | undefined,
  gender: string | undefined
) => {
  const date = new Date(birthDate ?? "");

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const twoDigitLeading = (num: number, leading: number) => {
    return `${leading}${num}`.slice(-2);
  };

  const manDate = `${twoDigitLeading(year, 1)}${twoDigitLeading(
    month,
    0
  )}${twoDigitLeading(day, 0)}`;

  // womans month is + 50
  const womanDate = `${twoDigitLeading(year, 2)}${month + 50}${twoDigitLeading(
    day,
    0
  )}`;

  const pin = identificationNumber?.replace(/[/*]/g, "")?.slice(0, 6);

  let isSame;
  switch (gender) {
    case MALE:
      isSame = pin === manDate;
      break;
    case FEMALE:
      isSame = pin === womanDate;
      break;
    default:
      isSame = pin === manDate || pin === womanDate;
  }

  return isSame;
};

const useClientUtils = () => {
  return {
    isIdentificationNumberSameAsBirthDate,
  };
};

export default useClientUtils;
