import React, { useState } from "react";

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import { User, UserProfile } from "../../api/me.generated";
import Alert from "../alerts/Alert";
import Button from "../buttons/Button";
import CancelButton from "../buttons/CancelButton";
import ValidatedInput from "../inputs/ValidatedInput";

interface Properties {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  callback: () => void;
}

export default function NoPasswordModal({
  showModal,
  setShowModal,
  callback,
}: Properties) {
  const { t } = useTranslation();

  const handleCloseClick = () => {
    setShowModal(false);
  };

  const handleConfirm = () => {
    callback();
  };

  return (
    <div className={`${showModal ? "block" : "hidden"}`}>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-normal">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/* content */}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-192 bg-white outline-none focus:outline-none">
            {/* header */}
            <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
              <h3 className="text-2xl font-semibold">
                <span>{t("app.no-password-modal.title")}</span>
              </h3>
              <button
                type="button"
                className="p-1 ml-auto bg-transparent border-0 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={handleCloseClick}
              >
                <span className="bg-transparent h-6 w-6 text-2xl outline-none focus:outline-none flex items-center">
                  <FontAwesomeIcon icon={faTimes} />
                </span>
              </button>
            </div>
            {/* body */}
            <div className="relative p-6 pt-2 flex-auto">
              <span>{t("app.no-password-modal.text")}</span>
              {/* footer */}
              <div className="flex items-center pt-3">
                <Button className="mr-2" onClick={handleConfirm}>
                  <span>{t("button.yes")}</span>
                </Button>
                <CancelButton onClick={handleCloseClick} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black" />
    </div>
  );
}
