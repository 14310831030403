import axios from "axios";

import { getAccessToken } from "../hooks/token";
import { GetDocumentSignatureStatusApiResponse } from "./documents.generated";

const API_URL = "/v1/";

export const getDocumentSignatureStatus = async (
  id: string
): Promise<GetDocumentSignatureStatusApiResponse> => {
  return axios
    .get(`${API_URL}documents/${id}/signature-status`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const allFunctions = {
  getDocumentSignatureStatus,
};

export default allFunctions;
