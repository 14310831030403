import React from "react";

import {
  IconDefinition,
  faHome,
  faUserShield,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import BackButton from "../../components/buttons/BackButton";
import Button from "../../components/buttons/Button";
import Card from "../../components/card/Card";
import CardBody from "../../components/card/CardBody";
import CardFooter from "../../components/card/CardFooter";
import AllRoutes from "../route/Route";

interface Properties {
  error: FetchBaseQueryError;
}

function Error403View({ error }: Properties) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const icons: IconDefinition[] = [faUserShield];

  return (
    <div className="flex flex-col place-content-center w-full h-full">
      <Card className="mx-auto w-96 sh-96 flex flex-col text-center rounded-xl">
        <CardBody>
          <div className="p-8">
            <FontAwesomeIcon
              className="text-spf-red"
              icon={icons[Math.floor(Math.random() * icons.length)]}
              size="10x"
            />
          </div>
          <h1 className="my-4 text-4xl">
            <span>{t("app.page.error.ups")}</span>
          </h1>
          <p>
            <span>{t("app.page.error.defaultPermissionMessage")}</span>
          </p>
        </CardBody>
        <CardFooter className="place-content-center">
          <BackButton onClick={() => navigate(-1)} className="mr-2" />
          <Button to={AllRoutes.DASHBOARD}>
            <FontAwesomeIcon icon={faHome} size="1x" />
            &nbsp;<span>{t("app.goToDasboard")}</span>
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
}

export default Error403View;
