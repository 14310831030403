import { ExternalLinkConfigurationStatusKey } from "../api/external-link.generated";
import SelectOption from "./SelectOption";

const ExternalLinkConfigurationStatusType: SelectOption<ExternalLinkConfigurationStatusKey>[] =
  [
    {
      key: "elcs-active",
      name: "status.elcs-active",
    },
    {
      key: "elcs-deactivated",
      name: "status.elcs-deactivated",
    },
  ];

export default ExternalLinkConfigurationStatusType;
