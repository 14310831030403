import { Advisor, Central, RoleUser } from "../api/me.generated";
import Roles from "../enums/Roles";
import { useAppSelector } from "./store";

export function getAdvisorDataFromRole(
  currentRole: RoleUser | undefined,
  skip = false
): Advisor | Central | undefined {
  if (skip) {
    return undefined;
  }

  let advisorData: Advisor | Central | undefined;

  switch (currentRole?.role?.key) {
    case Roles.ASSISTANT:
      advisorData = currentRole?.assistant?.advisor;
      break;
    case Roles.ADVISOR:
      advisorData = currentRole?.advisor;
      break;
    case Roles.CENTRAL:
      advisorData = currentRole?.central;
      break;
    case Roles.SYSTEM_ADMIN:
      advisorData = undefined;
      break;
    default:
      throw new Error("This role is not supported for this feature");
  }

  return advisorData;
}

export function getAdvisorSections(advisor: Advisor | Central | undefined) {
  if (!advisor) {
    return undefined;
  }

  if (!("sectors" in advisor)) {
    return undefined;
  }

  const now = new Date().toISOString();
  return advisor.sectors?.filter((sector) => {
    return sector.registration_to ? sector.registration_to > now : true;
  });
}

export function useHasValidNbsLicence(): boolean {
  const { currentRole } = useAppSelector((state) => state.auth);

  if (!currentRole) {
    return false;
  }

  if (currentRole?.role?.key === Roles.ADVISOR) {
    return currentRole?.advisor?.nbsRegistrationNumber?.length !== 0;
  }

  return false;
}

const allHooks = {
  getAdvisorDataFromRole,
  getAdvisorSections,
  useHasValidNbsLicence,
};

export default allHooks;
