import React from "react";

import { useTranslation } from "react-i18next";

import { ValidationError } from "../../api/me.generated";

interface ComponentProperties {
  label: string;
  field: string;
  required?: boolean;
  readOnly?: boolean;
  type?: string;
  value: string | number | undefined;
  compareValue?: string | number | undefined;
  errors: ValidationError[];
  className?: string;
  disabled?: boolean;
  callback: (event: React.FormEvent<HTMLInputElement>) => void;
  min?: number;
  max?: number;
  dataTestId?: string;
  placeholder?: string;
}

function ValidatedInput({
  label,
  field,
  value,
  compareValue,
  type,
  errors,
  required,
  readOnly,
  className,
  callback,
  disabled,
  min,
  max,
  dataTestId,
  placeholder,
}: ComponentProperties): JSX.Element {
  const { t } = useTranslation();
  const error = (errors ?? []).filter((item) => item.field === field);
  const showError = error.length > 0;
  const message = error.map((i) => i.message).join(", ");
  const shouldCompare = compareValue !== undefined && compareValue !== value;

  const finalReadOnly = readOnly;

  const htmlFor = `input-${field}`;

  const compareLabel = (v: string | number | undefined) => {
    const defaultLabel = t("input.string.empty");
    switch (typeof v) {
      case "string":
        return v.length > 0 ? v : defaultLabel;
      case "number":
        return v;
      default:
        return defaultLabel;
    }
  };

  return (
    <div>
      <div className="mb-3">
        <label htmlFor={htmlFor} className="flex mb-1 text-sm text-gray-500">
          <span>{label || ""}</span>
          {required && <small className="ml-1 text-spf-red">*</small>}
          {shouldCompare ? (
            <small className="ml-auto text-green-600">{`[${compareLabel(
              compareValue
            )}]`}</small>
          ) : (
            ""
          )}
        </label>
        <input
          className={
            `
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none ` +
            `${className} ` +
            `${disabled ? " bg-gray-100 cursor-text" : ""}`
          }
          type={type}
          value={value}
          id={htmlFor}
          min={min}
          max={max}
          onChange={callback}
          readOnly={finalReadOnly}
          disabled={disabled}
          autoComplete="off"
          placeholder={placeholder}
          data-testid={
            dataTestId && dataTestId.length > 0 ? dataTestId : htmlFor
          }
        />
        {showError ? (
          <div className="text-green-400">{message}</div>
        ) : undefined}
      </div>
    </div>
  );
}

ValidatedInput.defaultProps = {
  required: true,
  type: "",
  className: "",
  readOnly: false,
  compareValue: undefined,
  disabled: false,
  min: 0,
  max: 100,
  dataTestId: "",
  placeholder: "",
};

export default ValidatedInput;
