enum RoutePath {
  ROOT = "ROOT",
  // LAYOUT = "LAYOUT",

  // ///////////////////////////////////////////////////////////////////////////
  // ALL USERSs
  AUTH_LOGIN = "AUTH_LOGIN",
  AUTH_SSO_LOGIN = "AUTH_SSO_LOGIN",
  AUTH_LOGOUT = "AUTH_LOGOUT",
  USER_SETTINGS = "USER_SETTINGS",
  USER_RESET_PASSWORD = "USER_RESET_PASSWORD",

  CUSTOMERS_LIST = "CUSTOMERS_LIST",
  CUSTOMERS_ADD = "CUSTOMERS_ADD",
  CUSTOMERS_EDIT = "CUSTOMERS_EDIT",

  DOCUMENTS_LIST = "DOCUMENTS_LIST",
  DOCUMENTS_LIST_ZUZ = "DOCUMENTS_LIST_ZUZ",
  DOCUMENTS_LIST_CHECK = "DOCUMENTS_LIST_CHECK",
  DOCUMENTS_ADD_ZUZ = "DOCUMENTS_ADD_ZUZ",
  DOCUMENTS_ADD_CHECK = "DOCUMENTS_ADD_CHECK",
  DOCUMENT_VIEW_CHECK = "DOCUMENT_VIEW_CHECK",
  DOCUMENT_VIEW_ZUZ = "DOCUMENT_VIEW_ZUZ",
  DOCUMENT_EDIT_ZUZ = "DOCUMENT_EDIT_ZUZ",
  DOCUMENT_EDIT_CHECK = "DOCUMENT_EDIT_CHECK",

  DOCUMENT_ADD_ZUZ = "DOCUMENT_ADD_ZUZ",
  DOCUMENT_ADD_LIST = "DOCUMENT_ADD_LIST",
  IDENTIFICATION_LIST = "IDENTIFICATION_LIST",
  IDENTIFICATION_DETAIL = "IDENTIFICATION_DETAIL",
  IDENTIFICATION_CALLBACK = "IDENTIFICATION_CALLBACK",

  USERS_LIST = "USERS_LIST",
  USERS_ADD = "USERS_ADD",
  USERS_EDIT = "USERS_EDIT",

  ADVISORS_LIST = "ADVISORS_LIST",
  ADVISORS_ADD = "ADVISORS_ADD",
  ADVISORS_EDIT = "ADVISORS_EDIT",

  RULES_LIST = "RULES_LIST",
  RULES_EDIT = "RULES_EDIT",
  RULES_ADD = "RULES_ADD",

  RULES_MATRIX_LIST = "RULES_MATRIX_LIST",
  RULES_MATRIX_DETAIL = "RULES_MATRIX_DETAIL",

  EXTERNAL_LINKS_DASHBOARD_VIEW = "EXTERNAL_LINKS_DASHBOARD_VIEW",
  EXTERNAL_LINKS_LIST = "EXTERNAL_LINKS_LIST",
  EXTERNAL_LINKS_EDIT = "EXTERNAL_LINKS_EDIT",
  EXTERNAL_LINKS_ADD = "EXTERNAL_LINKS_ADD",
  EXTERNAL_LINKS_IMPORT = "EXTERNAL_LINKS_IMPORT",

  EVENTS_LIST = "EVENTS_LIST",
  EVENTS_EDIT = "EVENTS_EDIT",

  SYSTEM_MANAGEMENT_VIEW = "SYSTEM_MANAGEMENT_VIEW",

  DASHBOARD = "DASHBOARD",

  ERROR_404 = "ERROR_404",
}

export default RoutePath;
