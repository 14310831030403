import React from "react";

import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

import Button from "./Button";

interface Properties {
  className?: string;
  label?: string;
  inProgress?: boolean;
  disabled?: boolean;
  onClick?: () => void | undefined;
}

function DeliveredButton({
  className,
  label,
  inProgress,
  disabled,
  onClick,
}: Properties) {
  const { t } = useTranslation();
  return (
    <Button
      className={className}
      disabled={disabled}
      icon={faEnvelope}
      inProgress={inProgress}
      onClick={onClick}
    >
      {label ?? t("button.send")}
    </Button>
  );
}

DeliveredButton.defaultProps = {
  className: "",
  label: undefined,
  disabled: false,
  inProgress: false,
  onClick: undefined,
};

export default DeliveredButton;
