import React from "react";

import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useVersion } from "../../hooks/version";

function Footer(): JSX.Element {
  const version = useVersion();
  return (
    <footer className="group flex flex-col place-content-center mt-16 py-4 h-16 border-t border-gray-200 px-8">
      <div className="text-right text-sm">
        <small>
          <span>version: {version}, developed by</span>
          <a className="mx-2" href="https://www.ezmid.com/">
            EZMID
          </a>
          <span>with</span>
          <FontAwesomeIcon
            className="ml-2 group-hover:text-red-600 transition-all"
            icon={faHeart}
            size="1x"
          />
        </small>
      </div>
    </footer>
  );
}

export default Footer;
