import React from "react";

import { InvestmentType, translate } from "../../../../enums/InvestmentType";

interface Props {
  selected: InvestmentType | undefined;
  onChange: (value: InvestmentType) => void;
}

function InvestmentTypeSelect({ selected, onChange }: Props) {
  return (
    <>
      <h3 className="border border-slate-300 bg-slate-200 p-2 font-bold">
        Investícia
      </h3>
      <div className="flex flex-row gap-8 py-4">
        <label
          htmlFor="clientInvestmentType"
          className="flex items-center gap-2"
        >
          <input
            id="clientInvestmentType"
            name="clientInvestmentType"
            type="radio"
            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
            checked={selected === InvestmentType.ONCE}
            onChange={() => onChange(InvestmentType.ONCE)}
            data-testid="/documents/zuz/esg/input-clientInvestmentType"
          />
          {translate(InvestmentType.ONCE)}
        </label>
        <label
          htmlFor="partnerInvestmentType"
          className="flex items-center gap-2"
        >
          <input
            id="partnerInvestmentType"
            name="partnerInvestmentType"
            type="radio"
            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
            checked={selected === InvestmentType.REPEATED}
            onChange={() => onChange(InvestmentType.REPEATED)}
            data-testid="/documents/zuz/esg/input-partnerInvestmentType"
          />
          {translate(InvestmentType.REPEATED)}
        </label>
      </div>
    </>
  );
}

export default InvestmentTypeSelect;
