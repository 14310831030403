import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";

import { InvestmentCustomerType } from "../../enums/InvestmentCustomerType";
import { InvestmentType } from "../../enums/InvestmentType";
import InvestmentCustomerTypeSelect from "../../features/documents/zuz/investmentIntention/InvestmentCustomerTypeSelect";
import InvestmentTypeSelect from "../../features/documents/zuz/investmentIntention/InvestmentTypeSelect";
import QuestionAnswer from "../../features/documents/zuz/investmentIntention/QuestionAnswer";
import {
  InvestmentIntention,
  AnswersABCDE,
} from "../../models/DocumentContent";
import CancelButton from "../buttons/CancelButton";
import SaveButton from "../buttons/SaveButton";

const getFrontendID = () => {
  return `tmp-${new Date().getTime().toString()}`;
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSave: (investmentIntention: InvestmentIntention) => void;
  intentionDefault?: InvestmentIntention | undefined;
}

function AddInvestmentIntentionModal({
  isOpen,
  onClose,
  onSave,
  intentionDefault,
}: Props) {
  const { t } = useTranslation();
  const handleCloseClick = () => {
    onClose();
  };

  const defaultState: InvestmentIntention = {
    frontendID: "",
    investmentCustomerType: undefined,
    investmentCustomerTypePDF: "",
    investmentType: undefined,
    investmentPurpose: undefined,
    investmentTimeHorizon: undefined,
    investmentOnceAmount: undefined,
    investmentRepeatedAmount: undefined,
  };

  const [investmentIntention, setInvestmentIntention] =
    React.useState<InvestmentIntention>(intentionDefault || defaultState);

  const isDisabled = () => {
    return (
      !investmentIntention.investmentCustomerType ||
      !investmentIntention.investmentType ||
      !investmentIntention.investmentPurpose ||
      !investmentIntention.investmentTimeHorizon ||
      (investmentIntention.investmentType === InvestmentType.ONCE &&
        !investmentIntention.investmentOnceAmount) ||
      (investmentIntention.investmentType === InvestmentType.REPEATED &&
        !investmentIntention.investmentRepeatedAmount)
    );
  };

  const handleSave = () => {
    let intention = investmentIntention;

    // if is empty frontendID, then it is new intention and we need assign new frontendID
    if (!intention.frontendID || intention.frontendID === "") {
      intention = {
        ...intention,
        frontendID: getFrontendID(),
      };
    }

    onSave(intention);
    setInvestmentIntention(defaultState);
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      setInvestmentIntention(intentionDefault || defaultState);
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-normal py-8">
        <div className="relative my-6 w-full mx-auto my-auto max-w-2xl">
          {/* content */}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/* header */}
            <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
              <h3 className="text-2xl font-semibold">Investičný zamer</h3>
              <button
                type="button"
                className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => handleCloseClick()}
              >
                <span className="bg-transparent text-black h-6 w-6 text-2xl outline-none focus:outline-none flex items-center">
                  ×
                </span>
              </button>
            </div>
            {/* body */}
            <div className="relative p-6 pt-2 flex-auto">
              <InvestmentCustomerTypeSelect
                selected={investmentIntention.investmentCustomerType}
                onChange={(value: InvestmentCustomerType) =>
                  setInvestmentIntention({
                    ...investmentIntention,
                    investmentCustomerType: value,
                    investmentCustomerTypePDF:
                      value === InvestmentCustomerType.CLIENT
                        ? "Klient"
                        : "Partner",
                  })
                }
              />
              <InvestmentTypeSelect
                selected={investmentIntention.investmentType}
                onChange={(value: InvestmentType) =>
                  setInvestmentIntention({
                    ...investmentIntention,
                    investmentType: value,
                  })
                }
              />
              <QuestionAnswer
                name="investmentPurpose"
                question="Účel investovania"
                answers={[
                  {
                    answer: AnswersABCDE.ANSWER_A,
                    text: "A. Chcem sa zabezpečiť na dôchodok / chcem si vytvoriť dlhodobú finančnú rezervu",
                  },
                  {
                    answer: AnswersABCDE.ANSWER_B,
                    text: "B. Kúpa nehnuteľnosti, auta, alebo inej veľmi drahej veci",
                  },
                  {
                    answer: AnswersABCDE.ANSWER_C,
                    text: "C. Budovanie majetku a diverzifikácia",
                  },
                  {
                    answer: AnswersABCDE.ANSWER_D,
                    text: "D. Zabezpečenie detí alebo príbuzných",
                  },
                  {
                    answer: AnswersABCDE.ANSWER_E,
                    text: "E. Vytvorenie krátkodobej finančnej rezervy",
                  },
                ]}
                selected={investmentIntention.investmentPurpose}
                onChange={(value: AnswersABCDE) =>
                  setInvestmentIntention({
                    ...investmentIntention,
                    investmentPurpose: value,
                  })
                }
              />
              <QuestionAnswer
                name="investmentTimeHorizon"
                question="Na akú dlhú dobu chcete investovať? (Investičný horizont)"
                answers={[
                  {
                    answer: AnswersABCDE.ANSWER_A,
                    text: "A: Na menej ako 2 roky",
                  },
                  {
                    answer: AnswersABCDE.ANSWER_B,
                    text: "B: Na viac ako 2 a menej ako 5 rokov",
                  },
                  {
                    answer: AnswersABCDE.ANSWER_C,
                    text: "C: Na viac ako 5 a menej ako 10 rokov",
                  },
                  {
                    answer: AnswersABCDE.ANSWER_D,
                    text: "D: Na viac ako 10 rokov",
                  },
                ]}
                selected={investmentIntention.investmentTimeHorizon}
                onChange={(value: AnswersABCDE) =>
                  setInvestmentIntention({
                    ...investmentIntention,
                    investmentTimeHorizon: value,
                  })
                }
              />
              {investmentIntention.investmentType === InvestmentType.ONCE ? (
                <QuestionAnswer
                  name="investmentOnceAmount"
                  question="Koľko peňazí chcete investovať jednorázovo?"
                  answers={[
                    {
                      answer: AnswersABCDE.ANSWER_A,
                      text: "A: Bola by to iba malá časť môjho majetku (do 20%)",
                    },
                    {
                      answer: AnswersABCDE.ANSWER_B,
                      text: "B: Bola by to približne tretina môjho majetku (medzi 20% až 40%)",
                    },
                    {
                      answer: AnswersABCDE.ANSWER_C,
                      text: "C: Bola by to približne polovica môjho majetku (medzi 40% až 60%)",
                    },
                    {
                      answer: AnswersABCDE.ANSWER_D,
                      text: "D: Bola by to väčšia časť môjho majetku (nad 60%)",
                    },
                  ]}
                  selected={investmentIntention.investmentOnceAmount}
                  onChange={(value: AnswersABCDE) =>
                    setInvestmentIntention({
                      ...investmentIntention,
                      investmentOnceAmount: value,
                    })
                  }
                />
              ) : null}
              {investmentIntention.investmentType ===
              InvestmentType.REPEATED ? (
                <QuestionAnswer
                  name="investmentRegularlyAmount"
                  question="Koľko peňazí chcete investovať mesačne?"
                  answers={[
                    {
                      answer: AnswersABCDE.ANSWER_A,
                      text: "A: Bola by to iba malá časť môjho čistého mesačného príjmu (do 20%)",
                    },
                    {
                      answer: AnswersABCDE.ANSWER_B,
                      text: "B: Bola by to približne tretina môjho čistého mesačného príjmu (medzi 20% až 40%)",
                    },
                    {
                      answer: AnswersABCDE.ANSWER_C,
                      text: "C: Bola by to približne polovica môjho čistého mesačného príjmu (medzi 40% až 60%)",
                    },
                    {
                      answer: AnswersABCDE.ANSWER_D,
                      text: "D: Bola by to väčšia časť môjho čistého mesačného príjmu (nad 60%)",
                    },
                  ]}
                  selected={investmentIntention.investmentRepeatedAmount}
                  onChange={(value: AnswersABCDE) =>
                    setInvestmentIntention({
                      ...investmentIntention,
                      investmentRepeatedAmount: value,
                    })
                  }
                />
              ) : null}
            </div>
            {/* footer */}
            <div className="flex gap-4 items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
              <SaveButton
                onClick={() => handleSave()}
                disabled={isDisabled()}
              />
              <CancelButton
                onClick={() => {
                  onClose();
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black" />
    </>
  );
}

AddInvestmentIntentionModal.defaultProps = {
  intentionDefault: {
    frontendID: "",
    investmentCustomerType: undefined,
    investmentType: undefined,
    investmentPurpose: undefined,
    investmentTimeHorizon: undefined,
    investmentOnceAmount: undefined,
    investmentRepeatedAmount: undefined,
  },
};

export default AddInvestmentIntentionModal;
