import React from "react";

import {
  IconDefinition,
  faFaceFrown,
  faHome,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import Button from "../../components/buttons/Button";
import Card from "../../components/card/Card";
import CardBody from "../../components/card/CardBody";
import CardFooter from "../../components/card/CardFooter";
import AllRoutes from "../route/Route";

interface Properties {
  error: any;
  resetErrorBoundary: () => void;
}

function ErrorView({ error, resetErrorBoundary }: Properties) {
  const { t } = useTranslation();
  const icons: IconDefinition[] = [faFaceFrown];

  return (
    <div className="flex flex-col place-content-center w-full h-full">
      <Card className="mx-auto w-96 sh-96 flex flex-col text-center rounded-xl">
        <CardBody>
          <div className="p-8 pb-2">
            <FontAwesomeIcon
              className="text-spf-red"
              icon={icons[Math.floor(Math.random() * icons.length)]}
              size="8x"
            />
          </div>
          <h1 className="my-4 text-4xl">
            <span>{t("app.page.error.ups")}</span>
          </h1>
          <span>
            <span>{t("app.page.error.defaultMessage")}</span>, {error.message}
          </span>
        </CardBody>
        <CardFooter className="place-content-center">
          <Button icon={faQuestion} onClick={resetErrorBoundary}>
            <span>{t("button.tryAgain")}</span>
          </Button>
          <Button icon={faHome} to={AllRoutes.DASHBOARD} className="ml-2">
            <span>{t("app.goToDasboard")}</span>
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
}

export default ErrorView;
