import { ExternalLinkStatusKey } from "../api/external-link.generated";
import SelectOption from "./SelectOption";

enum ExternalLinkStatusType {
  ACTIVE = "els-active",
  DEACTIVATED = "els-deactivated",
}

export const ACTIVE = "els-active" as ExternalLinkStatusKey;
export const DEACTIVATED = "els-deactivated" as ExternalLinkStatusKey;

export const ExternalLinkStatusKeys = {
  ACTIVE,
  DEACTIVATED,
};

export const ExternalLinkStatusTypes: SelectOption<ExternalLinkStatusKey>[] = [
  {
    key: ACTIVE,
    name: "status.els-active",
  },
  {
    key: DEACTIVATED,
    name: "status.els-deactivated",
  },
];

export default ExternalLinkStatusType;
