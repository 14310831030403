import { parse as parseProperties } from "dot-properties";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import I18NextHttpBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import Language from "../../enums/ClientLanguage";
import { LANG_STORAGE_KEY } from "../../hooks/clientLanguage";

function setupI18N() {
  i18next
    .use(I18NextHttpBackend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: Language.SK,
      defaultNS: "translation",
      backend: {
        loadPath: "/localization/locale_{{lng}}.properties",
        parse: (data: string) => parseProperties(data, true),
      },
      detection: {
        order: ["localStorage"],
        lookupLocalStorage: LANG_STORAGE_KEY,
        caches: ["localStorage"],
      },
      interpolation: {
        escapeValue: false,
      },
      react: {
        useSuspense: true,
      },
    });
}

export default setupI18N;
