/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext } from "react";

import ReactDatePicker from "react-datepicker";

import Card from "../../../components/card/Card";
import FormTable from "../../../components/tables/FormTable";
import useDocumentGetters from "../../../hooks/documentContent";
import { Document } from "../../../models/Document";
import DocumentZuzContext from "../DocumentZuzFormViewContext";
import DocumentInput from "./DocumentInput";

interface Properties {
  document: Document;
  setDocument: React.Dispatch<Document>;
}

function Page03CapitalMarketCard({ document, setDocument }: Properties) {
  const { getTableValue, handleTableChange, getDateValue, handleChangeDate } =
    useDocumentGetters();
  const ctx = useContext(DocumentZuzContext);
  return (
    <div>
      <Card className="mb-8 px-4">
        <div className="py-3 mb-6">
          {/* <div className="w-full px-3 py-2 mb-4 font-bold bg-slate-200 ">
                  Sprostredkovanie finančných služieb v sektore Kapitálového
                  trhu
                </div> */}
          <h3 className="text-base font-bold my-2">
            Vstupné informácie (podľa § 35 zákona č. 186/2009 Z.z.)
          </h3>
          <div className="mb-4 px-3">
            <h4 className="text-base font-bold my-2">
              Požiadavky a potreby vyslovené Klientom / Partnerom
            </h4>
            <div className="flex">
              <div className="w-1/2 mb-4 pr-2 inline-block">
                <DocumentInput
                  document={document}
                  setDocument={setDocument}
                  name="capitalMarketClientEntryRequests"
                  label="Klient"
                  disabled={false}
                  error={
                    ctx.getFieldError("capitalMarketClientEntryRequests")
                      ?.message
                  }
                  onChange={(e) => {
                    ctx.resetFieldsErrors([
                      "capitalMarketClientEntryRequests",
                      "capitalMarketPartnerEntryRequests",
                    ]);
                  }}
                />
              </div>
              <div className="w-1/2 mb-4">
                <DocumentInput
                  document={document}
                  setDocument={setDocument}
                  name="capitalMarketPartnerEntryRequests"
                  label="Partner"
                  disabled={false}
                  error={
                    ctx.getFieldError("capitalMarketPartnerEntryRequests")
                      ?.message
                  }
                  onChange={(e) => {
                    ctx.resetFieldsErrors([
                      "capitalMarketClientEntryRequests",
                      "capitalMarketPartnerEntryRequests",
                    ]);
                  }}
                />
              </div>
            </div>
            <p className="py-3 font-bold">
              Pri sprostredkovaní finančných služieb v sektore Kapitálového trhu
              je potrebné vyplniť s Klientom/Partnerom Investičný-rizikový
              dotazník.
            </p>
            <h3 className="text-base font-bold">
              Investičné pokyny Klienta / Partnera
            </h3>
            <p className="mb-4 text-sm">
              (Vyplniť iba v prípade prijatia pokynu od Klienta/Partnera)
            </p>
            <FormTable
              tableName="tableInvestmentOrders"
              heading={[
                { title: "Klient" },
                { title: "Partner" },
                {
                  title:
                    "Investičná spoločnosť alebo obchodník s cennými papiermi",
                },
                { title: "Názov produktu / Finančného nástroja" },
                { title: "Typ FN" },
                { title: "Nákup (N)/Predaj(P)" },
                { title: "Jednorázová investovaná čiastka" },
                { title: "Pravidelne investovaná čiastka" },
                { title: "Mena" },
              ]}
              onChange={(name, values) =>
                handleTableChange(document, setDocument, name, values)
              }
              rows={10}
              values={getTableValue(document, "tableInvestmentOrders")}
            />
            <p className="text-sm">
              Vysvetlivky: FN = finančný nástroj * AF = akciový fond, DF =
              dlhopisový fond, SF = zmiešaný fond, FPT = fond peňažného trhu, ZF
              = zaistený fond, FF = fond fondov, NF = fond nehnuteľností, KF =
              komoditný fond, ETF = fond obchodovaný na burze, A = akcie, D =
              dlhopis
            </p>
            <p className="mt-2 font-bold">
              VYHODNOTENIE PRIMERANOSTI INVESTIČNÝCH POKYNOV NA ZÁKLADE
              INVESTIČNÉHO DOTAZNÍKA
            </p>
            <p className="mt-1 font-bold text-sm">
              Vyhlásenie Klienta / Partnera k Investičným pokynom:
            </p>
            <p>
              Vyhlasujem, že som bol(a) oboznámený(á) so všetkými informáciami a
              upozornený(á) na všetky riziká a ich dôsledky súvisiace mnou
              zadanými investičnými pokynmi, vrátane informácií, či sa na
              požadovaný obchod vzťahuje systém ochrany klientov a o podmienkach
              záruk poskytovaných týmto systémom a informácií a rizík z hľadiska
              ich primeranosti na základe mnou vyplneného investičného
              dotazníka. V tomto zmysle a s týmto vedomím som vyššie uvedené
              Investičné pokyny zadal(a) k prevereniu dobrovoľne.
            </p>
            <div className="mt-2 flex items-center">
              <div className="whitespace-nowrap mr-3">
                Dátum prijatia investičných pokynov od Klienta/Partnera:
              </div>
              <div className="w-2/12">
                <ReactDatePicker
                  className="form-control flex
                  w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  showTimeSelect={false}
                  selected={getDateValue(
                    document,
                    "capitalInvestmentOrdersDate"
                  )}
                  onChange={(date: Date) => {
                    ctx.resetFieldError("capitalInvestmentOrdersDate");
                    handleChangeDate(
                      document,
                      setDocument,
                      "capitalInvestmentOrdersDate",
                      date
                    );
                  }}
                  locale="sk"
                  dateFormat="dd.MM.yyyy"
                />
                {ctx.getFieldError("capitalInvestmentOrdersDate") ? (
                  <p className="text-red-400">
                    {ctx.getFieldError("capitalInvestmentOrdersDate")?.message}
                  </p>
                ) : null}
              </div>
            </div>
            <p className="mt-2 font-bold">
              Investičné pokyny je nutné odovzdať na centrálu SPF{" "}
              <span className="underline underline-offset-2">
                max. do 2 pracovných. dní
              </span>{" "}
              od dátumu ich prijatia.
            </p>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default Page03CapitalMarketCard;
