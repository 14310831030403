/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { Suspense } from "react";

import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import App from "./App";
import { initialize } from "./features/auth/authSlice";
import setupI18N from "./features/i18n/i18n";
import PageLoader from "./features/loader/PageLoader";
import * as serviceWorker from "./serviceWorker";
import { store } from "./stores/store";

import "./index.scss";

const container = document.getElementById("root");
const root = createRoot(container!);

// Download current translations
setupI18N();

// Initialize profile
store.dispatch(initialize());

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Suspense fallback={<PageLoader />}>
        <App />
      </Suspense>
    </Provider>
  </React.StrictMode>
);

serviceWorker.unregister();
