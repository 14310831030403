import React from "react";

import Placeholder from "../placeholder/Placeholder";

interface ComponentInterface {
  cols?: number;
  rows?: number;
  placeHolder?: JSX.Element;
}

function TableBodyLoading({
  cols,
  rows,
  placeHolder,
}: ComponentInterface): JSX.Element {
  const col = (key: number) => {
    return <td key={`col-key-${key}`}>{placeHolder ?? <Placeholder />}</td>;
  };

  const row = (key: number) => {
    return (
      <tr key={`row-key-${key}`}>
        {Array.from(Array(cols).keys()).map((j) => col(j))}
      </tr>
    );
  };

  return <tbody>{Array.from(Array(rows).keys()).map((k) => row(k))}</tbody>;
}

TableBodyLoading.defaultProps = {
  cols: 5,
  rows: 10,
  placeHolder: <Placeholder />,
};

export default TableBodyLoading;
