import React from "react";

import { useTranslation } from "react-i18next";

import ClientPartnerCheckboxOptions from "../../enums/ClientPartnerCheckboxOptions";
import useDocumentGetters from "../../hooks/documentContent";
import { Document } from "../../models/Document";
import { DocumentContentTypeCheckboxes } from "../../models/DocumentContent";

type IndividualCheckboxProps = {
  disabled?: boolean;
  checked?: boolean;
  hasError?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};
interface Properties {
  document: Document;
  setDocument: React.Dispatch<Document>;
  name: DocumentContentTypeCheckboxes;
  text: string;
  client?: IndividualCheckboxProps;
  partner?: IndividualCheckboxProps;
  dataTestId?: string;
}

function ClientPartnerCheckbox({
  document,
  setDocument,
  name,
  text,
  client,
  partner,
  dataTestId,
}: Properties) {
  const { t } = useTranslation();
  const { getClientPartnerCheckboxValues, setClientPartnerCheckboxValues } =
    useDocumentGetters();
  const checkboxes = getClientPartnerCheckboxValues(document, name);

  const isClientChecked = () => {
    return document.content[name].client;
  };

  const isPartnerChecked = () => {
    return document.content[name].partner;
  };

  const handleChange = (
    type: keyof ClientPartnerCheckboxOptions,
    checked: boolean
  ): void => {
    const newCheckboxes = { ...checkboxes };
    newCheckboxes[type] = checked;
    setClientPartnerCheckboxValues(document, setDocument, name, newCheckboxes);

    if (type === "client" && client?.onChange) {
      client.onChange({
        target: { checked },
      } as React.ChangeEvent<HTMLInputElement>);
    }

    if (type === "partner" && partner?.onChange) {
      partner.onChange({
        target: { checked },
      } as React.ChangeEvent<HTMLInputElement>);
    }
  };

  const finalDataTestId = dataTestId || `/global/input-cpcb-${name}`;

  return (
    <div className="flex">
      <div className="relative form-check flex form-check-inline">
        <input
          className={`form-check-input appearance-none h-4 w-4 border ${
            client?.hasError ? "border-red-400" : "border-gray-300"
          } rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1.5 align-top bg-no-repeat bg-center bg-contain float-left mr-2 ${
            client?.disabled ? "cursor-not-allowed" : "cursor-pointer"
          }`}
          type="checkbox"
          id={`client${name}`}
          value={`client${name}`}
          name={`client${name}`}
          checked={isClientChecked()}
          onChange={(event) => handleChange("client", event.target.checked)}
          disabled={client?.disabled}
          data-testid={`${finalDataTestId}-client`}
        />
        <label
          className={`absolute top-0 left-1 form-check-label inline-block text-gray-800 ${
            client?.disabled ? "cursor-not-allowed" : "cursor-pointer"
          }`}
          htmlFor={`client${name}`}
        >
          <span
            className={`text-xs text-slate-400 cursor-pointer ${
              client?.disabled ? "cursor-not-allowed" : "cursor-pointer"
            }`}
          >
            <span>{t("clientpartner.client.abbr")}</span>
          </span>
        </label>
      </div>
      <div className="relative form-check flex form-check-inline px-2">
        <input
          className={`form-check-input appearance-none h-4 w-4 border ${
            partner?.hasError ? "border-red-400" : "border-gray-300"
          } rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1.5 align-top bg-no-repeat bg-center bg-contain float-left mr-2 ${
            partner?.disabled ? "cursor-not-allowed" : "cursor-pointer"
          }'}`}
          type="checkbox"
          id={`partner${name}`}
          value={`partner${name}`}
          name={`partner${name}`}
          onChange={(event) => handleChange("partner", event.target.checked)}
          checked={isPartnerChecked()}
          disabled={partner?.disabled}
          data-testid={`${finalDataTestId}-partner`}
        />
        <label
          className={`absolute top-0  left-3 form-check-label inline-block text-gray-800 ${
            partner?.disabled ? "cursor-not-allowed" : "cursor-pointer"
          }`}
          htmlFor={`partner${name}`}
        >
          <span
            className={`text-xs text-slate-400 ${
              partner?.disabled ? "cursor-not-allowed" : "cursor-pointer"
            }`}
          >
            <span>{t("clientpartner.partner.abbr")}</span>
          </span>
        </label>
      </div>
      <div>{text}</div>
    </div>
  );
}

ClientPartnerCheckbox.defaultProps = {
  client: { disabled: false, checked: false, hasError: false },
  partner: { disabled: false, checked: false, hasError: false },
  dataTestId: "",
};

export default ClientPartnerCheckbox;
