import React from "react";

import { AnswersABCDE, Answer } from "../../../../models/DocumentContent";

interface Props {
  name: string;
  question: string;
  answers: Answer[];
  selected: AnswersABCDE | undefined;
  onChange: (value: AnswersABCDE) => void;
}

function QuestionAnswer({
  question,
  answers,
  selected,
  onChange,
  name,
}: Props) {
  return (
    <table className="mb-4">
      <thead>
        <tr className="border border-t-0 border-slate-300 bg-slate-200">
          <th colSpan={2} className="text-left p-2 text-normal font-bold">
            {question}
          </th>
        </tr>
      </thead>
      <tbody>
        {answers.map((answer) => (
          <tr key={answer.answer}>
            <td className="p-0 cursor-pointer">
              <label
                htmlFor={`investment-question-answer-${name}-${answer.answer}`}
                className="w-full block p-2"
              >
                {answer.text}
              </label>
            </td>
            <td className="p-0 cursor-pointer">
              <input
                id={`investment-question-answer-${name}-${answer.answer}`}
                name={`investment-question-answer-${name}-${answer.answer}`}
                type="radio"
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                checked={selected === answer.answer}
                onChange={() => onChange(answer.answer)}
                data-testid={`/documents/zuz/esg/input-investment-question-answer-${name}-${answer.answer}`}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default QuestionAnswer;
