import React from "react";

import { faList } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

import { ComputedRightLogEntry } from "../../../api/permission.generated";
import CountBadge from "../../../components/badges/CountBadge";
import Card from "../../../components/card/Card";
import CardBody from "../../../components/card/CardBody";
import CardHeader from "../../../components/card/CardHeader";
import { getNameByKey } from "../../../enums/PermissionRuleTypeOptions";
import useFormatters from "../../../hooks/formatter";
import { useComputeCrudText } from "../../../hooks/permissions";

interface Props {
  logEntries: ComputedRightLogEntry[];
}

export default function ObjectAndFunctionDetail({ logEntries }: Props) {
  const { t } = useTranslation();
  const { computeCrudText } = useComputeCrudText();
  const { formatDate } = useFormatters();

  return (
    <Card>
      <CardHeader
        icon={faList}
        label={t("card.header.rules-matrix.user.history") || ""}
      >
        <span className="float-right">
          <CountBadge count={logEntries.length} />
        </span>
      </CardHeader>
      <CardBody>
        {logEntries.length > 0 ? (
          <table>
            <thead>
              <tr>
                <td className="w-4/12">{t("table.header.name") || ""}</td>
                <td className="w-auto">{t("table.header.type") || ""}</td>
                <td className="w-4/12">
                  {t("table.header.permissionForObjectOrFunction") || ""}
                </td>
                <td className="w-3/12">{t("table.header.changed_at") || ""}</td>
              </tr>
            </thead>
            <tbody>
              {logEntries?.map((entry) => (
                <tr key={`index-${entry.type}`}>
                  <td className="text-spf-primary">{entry.name}</td>
                  <td className="text-spf-primary">
                    {getNameByKey(entry?.type || "")}
                  </td>
                  <td className="text-spf-primary">
                    {computeCrudText(
                      entry?.create ?? "",
                      entry?.read ?? "",
                      entry?.update ?? "",
                      entry?.delete ?? ""
                    )}
                  </td>
                  <td className="text-spf-primary">
                    {formatDate(entry?.updatedAt)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="text-sm text-spf-gray text-center">
            {t("card.body.rules-matrix.user.history.no-entries") || ""}
          </p>
        )}
      </CardBody>
    </Card>
  );
}
