import { ExternalLinkAuthTypeKey } from "../api/external-link.generated";
import SelectOption from "./SelectOption";

export enum ExternalLinkAuthTypeEnum {
  NOA = "elat-noa",
  SSO = "elat-sso",
  LOG = "elat-log",
}

const ExternalLinkAuthType: SelectOption<ExternalLinkAuthTypeKey>[] = [
  {
    key: "elat-noa",
    name: "external-link-auth.noa",
  },
  {
    key: "elat-sso",
    name: "external-link-auth.sso",
  },
  {
    key: "elat-log",
    name: "external-link-auth.log",
  },
];

export default ExternalLinkAuthType;
