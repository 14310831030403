import React from "react";

import { ImageResponse } from "../../api/external-link.generated";
import ImageResponseType from "../../enums/ImageResponseType";

function getDefaultImageUrl(
  imageResponse: ImageResponse[]
): string | undefined {
  if (!imageResponse) {
    return undefined;
  }

  const fitInSmallUrl = imageResponse
    ?.filter((r) => {
      return r.type === ImageResponseType.FIT_IN_SMALL;
    })
    .pop()?.url;

  if (!fitInSmallUrl) {
    const originalUrl = imageResponse
      ?.filter((r) => {
        return r.type === ImageResponseType.ORIGINAL;
      })
      .pop()?.url;

    return originalUrl;
  }
  return fitInSmallUrl;
}

export default getDefaultImageUrl;
