import React from "react";

import { faEdit, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Properties {
  label: string;
  type?: string;
  dataTestId?: string;
  field: string;
  value: string | number | undefined;
  disabled?: boolean;
  suffixIcon?: IconDefinition;
  callback: (event: React.FormEvent<any>) => void;
  suffixCallback: () => void;
}

function InputWithSuffix({
  label,
  type,
  field,
  value,
  disabled,
  suffixIcon,
  dataTestId,
  suffixCallback,
  callback,
}: Properties): JSX.Element {
  const htmlFor = `${field}-${Math.random()}`;
  const finalDataTestId = !dataTestId ? `/global/input-${field}` : dataTestId;
  return (
    <div className="mb-3">
      <label htmlFor={htmlFor} className="flex mb-1 text-sm text-gray-500">
        <span>{label || ""}</span>
      </label>
      <div
        className={`${
          disabled ? `bg-gray-100` : ``
        } flex w-full border border-solid border-gray-300 rounded`}
      >
        <input
          className={
            `   w-full
                block
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none ` +
            `${disabled ? " bg-gray-100 cursor-text" : ""}`
          }
          type={type}
          value={value}
          onChange={callback}
          disabled={disabled}
          autoComplete="false"
          data-testid={finalDataTestId}
        />
        <div className="flex mr-3">
          <FontAwesomeIcon
            onClick={suffixCallback}
            className="self-center"
            icon={suffixIcon ?? faEdit}
          />
        </div>
      </div>
    </div>
  );
}

InputWithSuffix.defaultProps = {
  type: "",
  disabled: false,
  suffixIcon: faEdit,
  dataTestId: "",
};

export default InputWithSuffix;
