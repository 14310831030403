import React from "react";

import { faCheckCircle, faStopCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import { UserStatusKey } from "../../api/users.generated";
import UserStatusType from "../../enums/UserStatusType";

interface Properties {
  statusKey?: UserStatusKey;
  callback: (status: UserStatusKey) => void | undefined;
}

function UserStatusButton({ statusKey, callback }: Properties) {
  const { t } = useTranslation();
  let bgClass = "bg-spf-green";
  let color = "text-stone-100";
  let icon = faCheckCircle;
  let newStatusKey = UserStatusType.DEACTIVATED;
  if (statusKey === UserStatusType.ACTIVE) {
    bgClass = "bg-spf-green";
    color = "text-stone-100";
    icon = faCheckCircle;
    newStatusKey = UserStatusType.DEACTIVATED;
  }
  if (statusKey === UserStatusType.DEACTIVATED) {
    bgClass = "bg-slate-400";
    color = "text-stone-100";
    icon = faStopCircle;
    newStatusKey = UserStatusType.ACTIVE;
  }
  return (
    <div
      className={`inline-block cursor-pointer px-3 py-1 rounded-full border ${bgClass} ${color}`}
      onClick={() => {
        callback(newStatusKey);
      }}
      aria-hidden="true"
    >
      <FontAwesomeIcon icon={icon} className="mr-2" />
      <span>{t(`status.${statusKey}`)}</span>
    </div>
  );
}

UserStatusButton.defaultProps = {
  statusKey: "",
};

export default UserStatusButton;
