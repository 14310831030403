/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { ChangeEvent } from "react";

import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface Properties {
  value: string | number;
  autoFocus?: boolean;
  className?: string;
  classNameWrapper?: string;
  disabled?: boolean;
  icon?: IconProp;
  label?: string;
  name?: string;
  checked?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

function Checkbox({
  autoFocus,
  className,
  classNameWrapper,
  label,
  name,
  value,
  icon,
  disabled,
  onChange,
  checked,
}: Properties) {
  const finalDisabled = !!disabled;
  return (
    <div className={classNameWrapper}>
      <div className="group w-full flex flex-row gap-2">
        <input
          id={`checkbox-${name}`}
          type="checkbox"
          className={`appearance-none w-5 h-5 border border-gray-300 rounded-sm transition duration-200 cursor-pointer checked:bg-spf-primary checked:border-spf-primary focus:outline-none transition duration-200 ${className}`}
          name={name}
          value={value}
          autoFocus={autoFocus}
          disabled={finalDisabled}
          onChange={onChange}
          checked={checked}
          data-testid="as"
        />
        {label ? (
          <label
            className="flex text-gray-500 text-sm mb-1 select-none"
            htmlFor={`checkbox-${name}`}
          >
            {label}
          </label>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

Checkbox.defaultProps = {
  autoFocus: false,
  className: "",
  classNameWrapper: "",
  name: "",
  disabled: false,
  onChange: undefined,
  icon: undefined,
  label: undefined,
  checked: false,
};

export default Checkbox;
