import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

import { useAddAdvisorMutation } from "../../api/advisors.generated";
import {
  CustomerTypeKey,
  ErrorResponse404,
  useLazyFindCustomerByTypeQuery,
} from "../../api/customers.generated";
import { CustomerTypeKeys } from "../../enums/CustomerType";
import Allroutes from "../../features/route/Route";
import useFormatters from "../../hooks/formatter";
import { getRoleId } from "../../hooks/role";
import { makeRoute } from "../../hooks/route";
import Button from "../buttons/Button";
import CancelButton from "../buttons/CancelButton";
import Input from "../inputs/Input";
import CustomerTypeSelect from "../select/CustomerTypeSelect";

interface Props {
  close: () => void;
  callbackOnCustomerExists?: (inputValue: string) => void;
  callbackOnCustomerNotExist?: () => void;
}

function CreateNewClientModal({
  close,
  callbackOnCustomerExists,
  callbackOnCustomerNotExist,
}: Props) {
  const [selectedType, setSelectedType] = useState<CustomerTypeKey>(
    CustomerTypeKeys.CT_FO
  );
  const [inputValue, setInputValue] = useState("");
  const [modal, setModal] = useState({
    userDontExistIsShown: false,
    userExistNotInAdvisorsIsShown: false,
    userExistsInAdvisorsIsShown: false,
  });
  const { t } = useTranslation();
  const isFo = selectedType === CustomerTypeKeys.CT_FO;
  const [trigger, result] = useLazyFindCustomerByTypeQuery();

  const { formatPin } = useFormatters();

  const handleInputChange = (value: string): void => {
    const cleanValue = formatPin(value);
    setInputValue(cleanValue ?? "");
  };

  const handleContinue = () => {
    trigger({
      "X-Role-Id": getRoleId(),
      typeKey: selectedType,
      pin: isFo ? formatPin(inputValue) : undefined,
      ico: isFo ? undefined : inputValue,
    }).then(({ error, data }) => {
      if (error && "data" in error && error.status === 404) {
        setModal({
          ...modal,
          userDontExistIsShown: true,
        });
      }

      if (error && "data" in error && error.status !== 404) {
        const { message } = error?.data as ErrorResponse404;
        toast.error(message);
      }

      if (data && data.in_advisors === true) {
        setModal({
          ...modal,
          userExistsInAdvisorsIsShown: true,
        });
      }

      if (data && data.in_advisors === false) {
        setModal({
          ...modal,
          userExistNotInAdvisorsIsShown: true,
        });
      }
    });
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-normal">
        <div className="relative w-full my-6 mx-auto max-w-md">
          {/* content */}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/* header */}
            <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
              <h3 className="text-2xl font-semibold">
                <span>{t("modal.customer.title")}</span>
              </h3>
              <button
                type="button"
                className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => close()}
              >
                <span className="bg-transparent text-black h-6 w-6 text-2xl outline-none focus:outline-none flex items-center">
                  ×
                </span>
              </button>
            </div>
            {/* body */}
            <div className="relative p-6 pt-2 flex-auto">
              <CustomerTypeSelect
                label="Typ klienta"
                selected={selectedType}
                onSelect={(e) =>
                  setSelectedType(e.target.value as CustomerTypeKey)
                }
              />
              <Input
                label={isFo ? t("app.form.pin") || "" : t("app.form.ico") || ""}
                value={inputValue}
                onChange={(e) => handleInputChange(e.target.value)}
                onBlur={() => {
                  handleInputChange(inputValue);
                }}
                placeholder={isFo ? t("app.form.pin") : t("app.form.ico")}
              />
            </div>
            {/* footer */}
            <div className="flex gap-4 items-center justify-center p-6 border-t border-solid border-blueGray-200 rounded-b">
              <Button
                onClick={() => handleContinue()}
                className="text-sm"
                dataTestId="/admin/customers/list/modal-btn-continue"
              >
                <span>{t("button.continue")}</span>
              </Button>
              <CancelButton
                withIcon={false}
                onClick={() => close()}
                className="text-sm"
                dataTestId="/admin/customers/list/modal-btn-cancel"
              />
            </div>
          </div>
        </div>
        {modal.userDontExistIsShown ? (
          <ClientNotExist
            close={() => setModal({ ...modal, userDontExistIsShown: false })}
            selectedType={selectedType}
            inputValue={inputValue}
            callback={callbackOnCustomerNotExist}
          />
        ) : null}
        {modal.userExistNotInAdvisorsIsShown ? (
          <ClientExist
            close={() =>
              setModal({ ...modal, userExistNotInAdvisorsIsShown: false })
            }
            customerId={result?.data?.id ?? ""}
            callback={() => callbackOnCustomerExists?.(inputValue)}
          />
        ) : null}
        {modal.userExistsInAdvisorsIsShown ? (
          <ClientExistAndIsAdvisors
            close={() =>
              setModal({ ...modal, userExistsInAdvisorsIsShown: false })
            }
          />
        ) : null}
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black" />
    </>
  );
}

CreateNewClientModal.defaultProps = {
  callbackOnCustomerExists: undefined,
  callbackOnCustomerNotExist: undefined,
};

export default CreateNewClientModal;

export function ClientNotExist({
  close,
  selectedType,
  inputValue,
  callback,
}: {
  close: () => void;
  selectedType: CustomerTypeKey;
  inputValue: string;
  callback: (() => void) | undefined;
}) {
  const { t } = useTranslation();

  sessionStorage.setItem("newClientSelectedType", selectedType);
  sessionStorage.setItem("newClientInputValue", inputValue);

  const buttonProps = {
    to: !callback ? Allroutes.CUSTOMERS_ADD : undefined,
    onClick: callback,
    className: "text-sm",
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-normal">
        <div className="relative w-full mx-auto max-w-lg">
          <div className="border-0 rounded-lg shadow-lg p-8 relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <p className="text-center">
              <span>{t("modal.customer.notExist")}</span>
            </p>
            <div className="flex flex-row gap-4 justify-center mt-6">
              <Button
                to={buttonProps.to}
                onClick={buttonProps.onClick}
                className="text-sm"
                dataTestId="/admin/customers/list/modal-btn-continue-ok"
              >
                OK
              </Button>
              <CancelButton
                onClick={close}
                withIcon={false}
                className="text-sm"
                dataTestId="/admin/customers/list/modal-btn-continue-cancel"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black" />
    </>
  );
}

export function ClientExist({
  close,
  customerId,
  callback,
}: {
  close: () => void;
  customerId: string;
  callback: (() => void) | undefined;
}) {
  const [trigger] = useAddAdvisorMutation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleOnClick = () => {
    trigger({
      "X-Role-Id": getRoleId(),
      id: customerId,
    }).then((res) => {
      if ("error" in res && res.error) {
        const { message } = res.error as { message: string };
        toast.error(message);
      }

      if ("data" in res && res.data) {
        toast.success(t("toast.customer.added"));
        if (callback) {
          close();
          callback();
        } else {
          navigate(
            makeRoute(Allroutes.CUSTOMERS_EDIT, {
              id: customerId,
            })
          );
        }
      }
    });
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-normal">
        <div className="relative w-full mx-auto max-w-lg">
          <div className="border-0 rounded-lg shadow-lg p-8 relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <p className="text-center">
              <span>{t("modal.customer.exist")}</span>
            </p>
            <div className="flex flex-row gap-4 justify-center mt-6">
              <Button onClick={handleOnClick} className="text-sm">
                <span>{t("button.yes")}</span>
              </Button>
              <Button
                onClick={close}
                className="bg-gray-200 text-gray-700 hover:bg-gray-300 focus:ring-gray-300 text-sm"
              >
                <span>{t("button.no")}</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black" />
    </>
  );
}

export function ClientExistAndIsAdvisors({ close }: { close: () => void }) {
  const { t } = useTranslation();

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-normal">
        <div className="relative w-full mx-auto max-w-lg">
          <div className="border-0 rounded-lg shadow-lg p-8 relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <p className="text-center">
              <span>{t("modal.customer.isAdvisors")}</span>
            </p>
            <div className="flex flex-row gap-4 justify-center mt-6">
              <Button onClick={close} className="text-sm">
                <span>{t("button.ok")}</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black" />
    </>
  );
}
