import { parse } from "@billjs/query-string";
import { useLocation } from "react-router";

export interface PaginationQuery {
  page?: number;
  size?: number;
}

export interface SortablePaginationQuery extends PaginationQuery {
  sort: string[];
}

export function toQueryString<Q>(query: Q) {
  const queryString = Object.keys(Object(query))
    .map((key) => {
      const parameter = query[key as keyof Q];
      if (Array.isArray(parameter)) {
        const val = parameter.join(",");
        return val.length > 0 ? `${key}=${val}` : undefined;
      }

      return String(parameter || "").toString().length > 0
        ? `${key}=${parameter}`
        : undefined;
    })
    .filter((item) => item !== undefined)
    .join("&");

  return queryString;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useQuery(): Record<string, any> {
  const location = useLocation();
  return parse(location.hash.length > 0 ? location.hash : location.search);
}
