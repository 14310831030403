// TODO: Remove this
/* eslint-disable @typescript-eslint/no-explicit-any */

import { useState } from "react";

import { t } from "i18next";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

import { Customer, FilterOptionsKey } from "../api/identification.generated";
import { USER_OWNED } from "../enums/FilterLevelKey";
import AllRoutes from "../features/route/Route";
import useLogger from "./logger";
import { SortablePaginationQuery, useQuery } from "./query";
import { makeRoute } from "./route";

export interface identificationQuery extends SortablePaginationQuery {
  keyword: string;
  level: FilterOptionsKey;
  identificationStates: Array<string>;
}

export type identificationQueryDispatcher = (
  query: identificationQuery
) => void;

type identificationQueryHook = [
  identificationQuery,
  (query: identificationQuery) => void
];

const localStorageSizeKey = "identification.page.size";
const localStorageIdentificationStates =
  "identification.filter.identificationStates";

function getIdentficationPageSizeFromStorage(): number {
  return Number(localStorage.getItem(localStorageSizeKey)) || 10;
}

function setIdentficationPageSizeToStorage(newSize: number): void {
  localStorage.setItem(localStorageSizeKey, newSize.toString());
}

function getIdentificationStatesFromStorage(): Array<string> {
  const states = localStorage.getItem(localStorageIdentificationStates);
  if (states) {
    return states.split(",");
  }
  return [];
}

function setIdentificationStatesToStorage(newStates: Array<string>): void {
  localStorage.setItem(localStorageIdentificationStates, newStates.join(","));
}

export function useIdentificationQuery(): identificationQueryHook {
  const navigate = useNavigate();
  const query = useQuery();
  const { logger } = useLogger();
  const [identificationQuery, setidentificationQuery] =
    useState<identificationQuery>({
      page: query.page ?? 1,
      size: query.size ?? getIdentficationPageSizeFromStorage(),
      sort: query.sort ? [query.sort] : ["createdAt:desc"],
      keyword: query.keyword ?? "",
      level: query.level ?? USER_OWNED,
      identificationStates: query.identificationStates
        ? query.identificationStates.split(",")
        : getIdentificationStatesFromStorage(),
    });

  logger.debug("PARSED IDENTIFICATION QUERY", identificationQuery);

  return [
    identificationQuery,
    (updatedidentificationQuery: identificationQuery) => {
      const queryString = Object.keys(updatedidentificationQuery)
        .map((key) => {
          const parameter =
            updatedidentificationQuery[key as keyof identificationQuery];
          if (Array.isArray(parameter)) {
            const val = parameter.join(",");
            return val.length > 0 ? `${key}=${val}` : undefined;
          }

          return (parameter ?? "").toString().length > 0
            ? `${key}=${parameter}`
            : undefined;
        })
        .filter((item) => item !== undefined)
        .join("&");

      logger.debug("QUERY STRING", queryString);

      // Important order: First update the URI/history, than the state because
      // it forces a repaint of the component
      setIdentficationPageSizeToStorage(updatedidentificationQuery.size ?? 10);
      setIdentificationStatesToStorage(
        updatedidentificationQuery.identificationStates ?? []
      );
      navigate(`${makeRoute(AllRoutes.IDENTIFICATION_LIST)}?${queryString}`);
      setidentificationQuery(updatedidentificationQuery);
    },
  ];
}

function getIdentityCard(data: any): any {
  return data?.ids?.[0] ?? {};
}

function findCardSide(card: any, side: string) {
  return card?.pages?.find((item: any) => item.side === side);
}

function getFieldValue(card: any, field: string) {
  const f = card?.idFields?.[field];

  if (f?.confidence?.raw) {
    return `${f?.finalValue ?? ""} (${f.confidence.raw})`;
  }
  return f?.finalValue ?? "";
}

function getFieldOriginalValue(card: any, field: string) {
  const f = card?.idFields?.[field];
  return f?.originalValue ?? "";
}

function getCardFieldValue(card: any, field: string) {
  const f = card?.textFields?.find((item: any) => item.id === field);
  return f?.lines
    ?.map((item: any) => {
      if (item.confidence) {
        return `${item.text} (${item.confidence})`;
      }
      return item.text;
    })
    .join(", ");
}

function createRow(label: string, value: string) {
  return { label, value };
}

function createRowComparable(label: string, value: string, original: string) {
  return { label, value, original };
}

function createModifiedRows(fields: any) {
  if (!fields) return [];
  const rows: { label: string; value: string }[] = [];
  Object.keys(fields).forEach((key: string) => {
    const item = fields[key];
    let label;
    switch (key) {
      case "dateOfBirth":
        label = "app.form.dateOfBirth";
        break;
      case "documentNumber":
        label = "app.form.documentNumber";
        break;
      case "firstName":
        label = "app.form.firstname";
        break;
      case "fullName":
        label = "app.form.fullname";
        break;
      case "givenNames":
        label = "app.form.firstname";
        break;
      case "personalNumber":
        label = "app.form.pin";
        break;
      case "surname":
        label = "app.form.lastname";
        break;
      default:
        label = key;
    }
    rows.push(createRow(label, item?.finalValue ?? ""));
  });

  return rows;
}

// veriface
function getVerifaceResponse(data: any): any {
  const dataArray: any = [];
  data.reduce(
    (map: { [x: string]: any }, item: { key: string | number; value: any }) => {
      dataArray[item.key] = item.value;
      return dataArray;
    },
    {}
  );

  const getVerifaceFieldValue = (row: string) => {
    const key = row.toUpperCase();
    return dataArray?.[key] ?? "";
  };

  return {
    ids: [
      createRowComparable(
        "app.form.lastname",
        getVerifaceFieldValue("surname"),
        getVerifaceFieldValue("surname")
      ),
      createRowComparable(
        "app.form.firstname",
        getVerifaceFieldValue("given_name"),
        getVerifaceFieldValue("given_name")
      ),
      createRowComparable(
        "app.form.dateOfBirth",
        getVerifaceFieldValue("date_of_birth"),
        getVerifaceFieldValue("date_of_birth")
      ),
      createRowComparable(
        "app.form.pin",
        getVerifaceFieldValue("personal_number"),
        getVerifaceFieldValue("personal_number")
      ),
      createRowComparable(
        "app.form.documentNumber",
        getVerifaceFieldValue("document_number"),
        getVerifaceFieldValue("document_number")
      ),
    ],
    modified: createModifiedRows(data?.modifiedIdentificationFields),
    page: {
      front: [
        createRow("app.form.lastname", getVerifaceFieldValue("surname")),
        createRow("app.form.firstname", getVerifaceFieldValue("given_name")),
        createRow("app.form.nationality", getVerifaceFieldValue("nationality")),
        createRow(
          "app.form.dateOfBirth",
          getVerifaceFieldValue("date_of_birth")
        ),
        createRow("app.form.sex", getVerifaceFieldValue("gender")),
        createRow("app.form.pin", getVerifaceFieldValue("personal_number")),
        createRow(
          "app.form.documentNumber",
          getVerifaceFieldValue("document_number")
        ),
        createRow(
          "app.form.dateOfExpiry",
          getVerifaceFieldValue("date_of_expiry")
        ),
        createRow(
          "app.form.issuingAuthority",
          getVerifaceFieldValue("issuing_authority")
        ),
        createRow(
          "app.form.dateOfIssue",
          getVerifaceFieldValue("date_of_issue")
        ),
      ],
      back: [
        createRow("app.form.address", getVerifaceFieldValue("address")),
        createRow(
          "app.form.surnameAtBirth",
          getVerifaceFieldValue("surname_at_birth")
        ),
        createRow(
          "app.form.placeOfBirth",
          getVerifaceFieldValue("place_of_birth")
        ),
      ],
    },
  };
}

export const useCustomerIdentificationValidator = () => {
  const validateCustomer = (row: Customer) => {
    const emailTest = row?.email?.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);

    if (!emailTest) {
      toast.error(t("toast.identification.error.customerEmail"));
      return false;
    }

    return true;
  };

  return { validateCustomer };
};

export function useIdentificationResponseTransform(data: any): any {
  if (data?.extractedData) {
    return getVerifaceResponse(data.extractedData);
  }

  const card = getIdentityCard(data);
  const frontCardSide = findCardSide(card, "front");
  const backCardSide = findCardSide(card, "back");

  return {
    ids: [
      createRowComparable(
        "app.form.lastname",
        getFieldValue(card, "surname"),
        getFieldOriginalValue(card, "surname")
      ),
      createRowComparable(
        "app.form.firstname",
        getFieldValue(card, "givenNames"),
        getFieldOriginalValue(card, "givenNames")
      ),
      createRowComparable(
        "app.form.dateOfBirth",
        getFieldValue(card, "dateOfBirth"),
        getFieldOriginalValue(card, "dateOfBirth")
      ),
      createRowComparable(
        "app.form.pin",
        getFieldValue(card, "personalNumber"),
        getFieldOriginalValue(card, "personalNumber")
      ),
      createRowComparable(
        "app.form.documentNumber",
        getFieldValue(card, "documentNumber"),
        getFieldOriginalValue(card, "documentNumber")
      ),
    ],
    modified: createModifiedRows(card?.modifiedIdentificationFields),
    page: {
      front: [
        createRow(
          "app.form.lastname",
          getCardFieldValue(frontCardSide, "surname")
        ),
        createRow(
          "app.form.firstname",
          getCardFieldValue(frontCardSide, "given_names")
        ),
        createRow(
          "app.form.nationality",
          getCardFieldValue(frontCardSide, "nationality")
        ),
        createRow(
          "app.form.dateOfBirth",
          getCardFieldValue(frontCardSide, "date_of_birth")
        ),
        createRow("app.form.sex", getCardFieldValue(frontCardSide, "sex")),
        createRow(
          "app.form.pin",
          getCardFieldValue(frontCardSide, "personal_number")
        ),
        createRow(
          "app.form.documentNumber",
          getCardFieldValue(frontCardSide, "document_number")
        ),
        createRow(
          "app.form.dateOfExpiry",
          getCardFieldValue(frontCardSide, "date_of_expiry")
        ),
        createRow(
          "app.form.issuingAuthority",
          getCardFieldValue(frontCardSide, "issuing_authority")
        ),
        createRow(
          "app.form.dateOfIssue",
          getCardFieldValue(frontCardSide, "date_of_issue")
        ),
      ],
      back: [
        createRow(
          "app.form.address",
          getCardFieldValue(backCardSide, "address")
        ),
        createRow(
          "app.form.surnameAtBirth",
          getCardFieldValue(backCardSide, "surname_at_birth")
        ),
        createRow(
          "app.form.placeOfBirth",
          getCardFieldValue(backCardSide, "place_of_birth")
        ),
      ],
    },
  };
}

const allHooks = {
  useIdentificationQuery,
  useIdentificationResponseTransform,
  useCustomerIdentificationValidator,
};

export default allHooks;
