import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import logger from "redux-logger";

import { baseSplitApi } from "../api/baseApi";
import { authReducer } from "../features/auth/authSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    [baseSplitApi.reducerPath]: baseSplitApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(logger, baseSplitApi.middleware);
  },
  devTools: true,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
