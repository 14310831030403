export function invertDirection(sort: string[]): string {
  let newDirection = "asc";
  if (sort.length > 0) {
    const splitted = sort[0].split(":");
    newDirection =
      splitted.length === 2 && splitted[1] === "desc" ? "asc" : "desc";
  }

  return newDirection;
}

export function currentSortField(sort: string[]): string {
  if (sort.length > 0) {
    const splitted = (sort[0] ?? "").split(":");
    if (splitted.length > 0) return splitted[0];
  }

  return "";
}

const allFunctions = {
  currentSortField,
  invertDirection,
};

export default allFunctions;
