import {
  DocumentPassedToCustomer,
  DocumentPassedToCustomerKeys,
  EsgCheckKeys,
  EsgQ1Checkboxes,
  EsgQ2Checkboxes,
  EsgQ3Checkboxes,
  PFASector,
  PFASectorKeys,
  PersonType,
  PersonTypeKeys,
  PrivacyPolicy,
  PrivacyPolicyKeys,
} from "../models/CheckboxItemField";
import {
  CUSTOMER_SKILL_TYPE,
  CustomerSkillTypeCheckbox,
} from "../models/CustomerSkillType";

export const pfaSectors: PFASector[] = [
  {
    name: PFASectorKeys.SUP,
    checked: false,
    label: "Doplnkového Dôchodkového Sporenia",
  },
  {
    name: PFASectorKeys.ACC,
    checked: false,
    label: "Prijímania vkladov",
  },
  {
    name: PFASectorKeys.LEN,
    checked: false,
    label: "Poskytovania úverov",
  },
  {
    name: PFASectorKeys.INS,
    checked: false,
    label: "Poistenia alebo zaistenia",
  },
  {
    name: PFASectorKeys.CAP,
    checked: false,
    label: "Kapitálového trhu",
  },
  {
    name: PFASectorKeys.OLD,
    checked: false,
    label: "Starobného Dôchodkového Sporenia",
  },
];

export const clientTypes: CustomerSkillTypeCheckbox[] = [
  {
    name: CUSTOMER_SKILL_TYPE.NON_PROFESSIONAL,
    checked: false,
    label: "Neprofesionálny klient",
  },
  {
    name: CUSTOMER_SKILL_TYPE.PROFESSIONAL,
    checked: false,
    label: "Profesionálny klient",
  },
];

export const clientPersonTypes: PersonType[] = [
  {
    name: PersonTypeKeys.INDIVIDUAL,
    label: "Fyzická osoba",
    checked: false,
  },
  {
    name: PersonTypeKeys.SELF_EMPLOYED,
    label: "Fyzická osoba - podnikateľ",
    checked: false,
  },
  {
    name: PersonTypeKeys.COMPANY,
    label: "Právnická osoba",
    checked: false,
  },
];

export const declarationsOfSuitabilityChecks = [
  {
    name: "financeServiceIsSuitable",
    label:
      "Na základe informácií poskytnutých mi klientom ohľadne jeho požiadaviek a potrieb, jeho skúseností a znalostí a jeho finančnej situácie a v snahe konať v jeho najlepšom záujme VYHLASUJEM, že zvolená finančná služba JE pre klienta VHODNÁ.",
  },
  {
    name: "financeServiceIsNotSuitable",
    label:
      "UPOZORŇUJEM klienta, že na základe informácií poskytnutých mi klientom ohľadne jeho požiadaviek a potrieb, jeho skúseností a znalostí a jeho finančnej situácie, zvolená finančná služba NIE JE pre klienta VHODNÁ.",
  },
];

export const esgQ1Checkboxes: EsgQ1Checkboxes[] = [
  { name: EsgCheckKeys.ESG_INTEREST, checked: false, label: "Mám záujem" },
  { name: EsgCheckKeys.ESG_NO_INTEREST, checked: false, label: "Nemám záujem" },
];

export const esgQ2Checkboxes: EsgQ2Checkboxes[] = [
  {
    name: EsgCheckKeys.ESG_ENVIRONMENT,
    checked: false,
    label: "s environmentálnym zameraním",
  },
  {
    name: EsgCheckKeys.ESG_SOCIAL,
    checked: false,
    label: "so sociálnym zameraním",
  },
  {
    name: EsgCheckKeys.ESG_CORPORATE,
    checked: false,
    label: "s korporátnym zameraním",
  },
  {
    name: EsgCheckKeys.ESG_No_PREFERENCE,
    checked: false,
    label: "do ESG fondov, ale nemám konkrétnu preferenciu",
  },
];

export const esgQ3Checkboxes: EsgQ3Checkboxes[] = [
  {
    name: EsgCheckKeys.ESG_ALL_PREFERENCIES,
    checked: false,
    label: "Všetky súčasne",
  },
  {
    name: EsgCheckKeys.ESG_AT_LEAST_ONE_PREFERENCIES,
    checked: false,
    label: "Aspoň jedna",
  },
];

export const documentsPassedtoCustomers: DocumentPassedToCustomer[] = [
  {
    name: DocumentPassedToCustomerKeys.COPY_OF_THIS_DOCUMENT,
    checked: false,
    label: "Kópia tohto Záznamu",
  },
  {
    name: DocumentPassedToCustomerKeys.COPY_OF_CONTRACT_DRAFT,
    checked: false,
    label: "Kópia návrhu zmluvy",
  },
  {
    name: DocumentPassedToCustomerKeys.GENERAL_INSURANCE_TERMS,
    checked: false,
    label: "Všeobecné poistné podmienky",
  },
  {
    name: DocumentPassedToCustomerKeys.GENERAL_TOC,
    checked: false,
    label: "Obchodné podmienky",
  },
  {
    name: DocumentPassedToCustomerKeys.FUND_STATUS,
    checked: false,
    label: "Štatút fondu",
  },
  {
    name: DocumentPassedToCustomerKeys.WAGE_DEDUCTION_AGREEMENT,
    checked: false,
    label: "Dohoda o zrážkach zo mzdy",
  },
  {
    name: DocumentPassedToCustomerKeys.COPY_OF_CREDIT_REQUEST,
    checked: false,
    label: "Kópia úverovej žiadosti",
  },
  {
    name: DocumentPassedToCustomerKeys.KIDIPID,
    checked: false,
    label: "KID/IPID",
  },
  {
    name: DocumentPassedToCustomerKeys.OTHER,
    checked: false,
    label: "Iné",
  },
];

export const privacyPolicyAgree: PrivacyPolicy[] = [
  {
    name: PrivacyPolicyKeys.YES,
    checked: false,
    label: "áno",
  },
  {
    name: PrivacyPolicyKeys.NO,
    checked: false,
    label: "nie",
  },
];
