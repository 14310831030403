import { DocumentTypeKey } from "../api/documents.generated";
import SelectOption from "./SelectOption";

export const ZUZ = "dt-cca" as DocumentTypeKey;
export const CHECK_FORM = "dt-check" as DocumentTypeKey;

export const DocumentTypeKeys = {
  ZUZ,
  CHECK_FORM,
};

const DocumentTypes: SelectOption<DocumentTypeKey>[] = [
  {
    key: ZUZ,
    name: "ZUZ",
  },
  {
    key: CHECK_FORM,
    name: "Súpiska",
  },
];

export default DocumentTypes;
