import React, { useState } from "react";

import {
  faAddressCard,
  faCheckCircle,
  faLock,
  faTimesCircle,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import axios, { AxiosResponse } from "axios";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";

import { useUpdatePdfFromVerificationProviderMutation } from "../../api/adminIdentification.generated";
import { API_URL, openIdentificationPreview } from "../../api/auth";
import {
  CustomerIdentificationResponse,
  IdentificationStatusKey,
  useGetIdentificationByIdQuery,
  useUpdateAdminIdentificationStatusMutation,
  useUpdateStatusIdentificationsMutation,
} from "../../api/identification.generated";
import IdentificationStatusBadge from "../../components/badges/IdentificationStatusBadge";
import BackButton from "../../components/buttons/BackButton";
import Button from "../../components/buttons/Button";
import PreviewButton from "../../components/buttons/PreviewButton";
import Card from "../../components/card/Card";
import CardHeader from "../../components/card/CardHeader";
import SelectIdentificationStatus from "../../components/inputs/SelectIdentificationStatus";
import {
  DropArea,
  SelectedFileView,
} from "../../components/modals/FileUploadModal";
import PageHeader from "../../components/page/PageHeader";
import CustomerType from "../../enums/CustomerType";
import IdentificationStates from "../../enums/IdentificationStates";
import Roles from "../../enums/Roles";
import useFormatters from "../../hooks/formatter";
import { useIdentificationResponseTransform } from "../../hooks/identification";
import { getRoleId } from "../../hooks/role";
import { useAppSelector } from "../../hooks/store";
import { getAccessToken } from "../../hooks/token";
import { authSelector } from "../auth/authSlice";
import Error403View from "../error/Error403View";
import Error404View from "../error/Error404View";
import AllRoutes from "../route/Route";

interface ItemsProperties {
  label: string;
  value: string;
  original?: string;
}

function IdentificationDetailView() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { currentRole } = useSelector(authSelector);
  const navigate = useNavigate();

  const { formatApiError } = useFormatters();

  const {
    currentData,
    isFetching,
    isError,
    error: getQueryError,
    refetch,
  } = useGetIdentificationByIdQuery(
    {
      "X-Role-Id": getRoleId(),
      id: id ?? "",
    },
    { refetchOnMountOrArgChange: true }
  );

  const [triggerUpdateStatus] = useUpdateStatusIdentificationsMutation();

  const [updateAdminIdentificationStatus] =
    useUpdateAdminIdentificationStatusMutation();

  const fields = useIdentificationResponseTransform(
    currentData?.response ?? {}
  );

  const [pdfFile, setPdfFile] = useState<File | undefined>();
  const [adminStatusKey, setAdminStatusKey] =
    useState<IdentificationStatusKey>("is-success");

  // Preview logic
  const { roleId } = useAppSelector(authSelector);
  const [isPreviewInProgress, setIsPreviewInProgress] = useState(false);

  const [updatePdfFromVerificationProvider] =
    useUpdatePdfFromVerificationProviderMutation();

  const onFileSelect = (item: { files: any[] }) => {
    setPdfFile(item.files[0]);
  };

  const uploadFile = async () => {
    const token = getAccessToken();
    if (!token) {
      return;
    }

    const formData = new FormData();
    if (pdfFile) {
      formData.append("file", pdfFile, pdfFile.name);
    }

    await axios
      .post<any, AxiosResponse<void>>(
        `${API_URL}admin/identifications/${currentData?.id}/upload-document`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-Role-Id": getRoleId(),
          },
        }
      )
      .then((res) => {
        toast.success(`Document updated`);
        setPdfFile(undefined);
        refetch();
      })
      .catch((reason) => {
        toast.error(formatApiError(reason));
      });
  };

  const previewPdf = () => {
    setIsPreviewInProgress(true);
    openIdentificationPreview(currentData?.id ?? "", roleId)
      .catch(() => {
        toast.error(t("toast.document.error.pdfPreview"));
      })
      .finally(() => {
        setIsPreviewInProgress(false);
      });
  };

  const updateStatus = (statusKey: IdentificationStatusKey) => {
    triggerUpdateStatus({
      "X-Role-Id": getRoleId(),
      id: currentData?.id ?? "",
      identificationUpdateStatusRequest: {
        statusKey,
      },
    }).then((response) => {
      if ("data" in response) {
        toast.success(t("toast.identification.success.updateStatus"));
      }

      if ("error" in response) {
        toast.error(t("toast.identification.error.updateStatus"));
      }
      refetch();
    });
  };

  const triggerUpdatePdfFromVerificationProvider = () => {
    updatePdfFromVerificationProvider({
      "X-Role-Id": getRoleId(),
      id: currentData?.id ?? "",
    })
      .then((response: any) => {
        if ("data" in response) {
          refetch();
          toast.success(t("success"));
        }

        if (response?.error) {
          toast.error(JSON.stringify(response));
        }
      })
      .catch((error: any) => {
        formatApiError(error);
      });
  };

  const updateAdminStatus = (statusKey: IdentificationStatusKey) => {
    updateAdminIdentificationStatus({
      "X-Role-Id": getRoleId(),
      id: currentData?.id ?? "",
      identificationStatusUpdate: {
        statusKey,
      },
    }).then((response) => {
      if ("data" in response) {
        refetch();
        toast.success(t("toast.identification.success.updateStatus"));
      }

      if ("error" in response) {
        toast.error(t("toast.identification.error.updateStatus"));
      }
    });
  };

  if (!isFetching && isError && getQueryError) {
    if ("data" in getQueryError && getQueryError.status === 403) {
      return <Error403View error={getQueryError} />;
    }

    return <Error404View />;
  }

  if (isFetching && !currentData) return <span>{t("app.page.loading")}</span>;

  return (
    <>
      <PageHeader icon={faLock} label={t("app.identification.detail.header")}>
        <BackButton
          className="mr-2"
          onClick={() => {
            navigate(AllRoutes.IDENTIFICATION_LIST.path);
          }}
        />
        {currentData?.pdfFile?.key ? (
          <PreviewButton
            inProgress={isPreviewInProgress}
            onClick={() => previewPdf()}
            className="mr-2"
          />
        ) : (
          <div />
        )}
        {currentRole.role?.key === Roles.SYSTEM_ADMIN ||
        currentRole.role?.key === Roles.CENTRAL ? (
          <Button
            onClick={() => triggerUpdatePdfFromVerificationProvider()}
            className="mr-2"
          >
            {t("button.updatePdfFromIdentificationProvider")}
          </Button>
        ) : (
          <div />
        )}

        {(currentRole.role?.key === Roles.CENTRAL ||
          currentRole.role?.key === Roles.SYSTEM_ADMIN) &&
        IdentificationStates.SUCCESS_IRELEVANT === currentData?.status?.key ? (
          <>
            <Button
              icon={faCheckCircle}
              onClick={() => updateStatus(IdentificationStates.SUCCESS)}
            >
              Schváliť
            </Button>
            <Button
              icon={faTimesCircle}
              onClick={() => updateStatus(IdentificationStates.DECLINED)}
            >
              Zamietnuť
            </Button>
          </>
        ) : (
          ""
        )}
      </PageHeader>

      <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
        <div className="flex flex-col gap-4">
          <Card>
            <CardHeader
              label={t("app.identification.card.frontPage") || ""}
              icon={faAddressCard}
            >
              <IdentificationStatusBadge status={currentData?.status} />
            </CardHeader>
            <div className="p-4 flex flex-col gap-4">
              {(fields?.page?.front ?? []).map((item: ItemsProperties) => {
                return (
                  <div className="" key={item.value}>
                    <div className="grid grid-cols-2 gap-4">
                      <div className="p-2 bg-slate-100">
                        <span>{t(item.label)}</span>
                      </div>
                      <div className="p-2 bg-slate-50 ">{item.value}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Card>

          {currentRole?.role?.key === "rk-system-admin" ? (
            <Card>
              <CardHeader
                label={t("app.identification.card.admin") || ""}
                icon={faAddressCard}
              />
              <div className="p-4 flex flex-col gap-4">
                <div className="grid grid-cols-2 gap-4">
                  <DndProvider backend={HTML5Backend}>
                    <form className="mt-6" onSubmit={(e) => e.preventDefault()}>
                      {pdfFile ? (
                        <SelectedFileView file={pdfFile} />
                      ) : (
                        <DropArea onFileSelect={onFileSelect} />
                      )}
                    </form>
                  </DndProvider>
                  <div className="flex flex-col">
                    <Button onClick={uploadFile} disabled={!pdfFile}>
                      {t("button.uploadPdf")}
                    </Button>
                  </div>
                </div>
              </div>
              <div className="p-4 flex flex-col gap-4">
                <div className="grid grid-cols-2 gap-4">
                  <SelectIdentificationStatus
                    selectedOption={adminStatusKey}
                    onChange={(e) =>
                      setAdminStatusKey(
                        e.target.value as IdentificationStatusKey
                      )
                    }
                  />
                  <div className="flex flex-col">
                    <Button onClick={() => updateAdminStatus(adminStatusKey)}>
                      {t("button.change")}
                    </Button>
                  </div>
                </div>
              </div>
            </Card>
          ) : null}
        </div>
        <div>
          <Card
            label={t("app.identification.card.backPage") || ""}
            icon={faAddressCard}
          >
            <div className="p-4 flex flex-col gap-4">
              {(fields?.page?.back ?? []).map((item: ItemsProperties) => {
                return (
                  <div className="" key={item.value}>
                    <div className="grid grid-cols-2 gap-4">
                      <div className="p-2 bg-slate-100">
                        <span>{t(item.label)}</span>
                      </div>
                      <div className="p-2 bg-slate-50 ">{item.value}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Card>
          <Card
            label={t("form.label.customer") || ""}
            icon={faUserGroup}
            className="mt-4"
          >
            <div className="p-4 flex flex-col gap-4">
              <div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="p-2 bg-slate-100">
                    <span>{t("app.identification.titleNameSurname")}</span>
                  </div>
                  <div className="p-2 bg-slate-50 ">
                    {`${currentData?.customer?.title} ${currentData?.customer?.firstname} ${currentData?.customer?.lastname}`.trim()}
                  </div>
                  <div className="p-2 bg-slate-100">
                    <span>{t("app.form.customerType")}</span>
                  </div>
                  <div className="p-2 bg-slate-50 ">
                    {t(
                      `app.customerType.${currentData?.customer?.type?.name?.toLowerCase()}`
                    )}
                  </div>
                  <div className="p-2 bg-slate-100">
                    <span>
                      {t("app.identification.userIdentificationSendTo")}
                    </span>
                  </div>
                  <div className="p-2 bg-slate-50 ">
                    {currentData?.customerIdentificationEmail}
                  </div>
                </div>
              </div>
            </div>
          </Card>
          {currentData?.customers?.map(
            (item: CustomerIdentificationResponse) => {
              return (
                <Card
                  label={t("form.label.customerIdentifications") || ""}
                  icon={faUserGroup}
                  className="mt-4"
                >
                  <div className="p-4 flex flex-col gap-4">
                    <div>
                      <div className="grid grid-cols-2 gap-4">
                        <div className="p-2 bg-slate-100">
                          <span>{t("app.form.name")}</span>
                        </div>
                        <div className="p-2 bg-slate-50 ">
                          {item.displayName}
                        </div>
                        <div className="p-2 bg-slate-100">
                          <span>{t("app.form.email")}</span>
                        </div>
                        <div className="p-2 bg-slate-50 ">{item.email}</div>
                        <div className="p-2 bg-slate-100">
                          <span>{t("app.form.customerType")}</span>
                        </div>
                        <div className="p-2 bg-slate-50 ">
                          {t(
                            CustomerType?.filter((ctype) => {
                              return ctype.key === item.type;
                            }).pop()?.name || ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              );
            }
          )}
        </div>
      </div>
    </>
  );
}

export default IdentificationDetailView;
