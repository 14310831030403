import React, { useContext } from "react";

import Input from "../../../../components/inputs/Input";
import {
  YearsPeriod,
  EsgPreferences,
  EsgPreferenceFields,
} from "../../../../models/DocumentContent";
import DocumentZuzContext from "../../DocumentZuzFormViewContext";

export type CPfield = "client" | "partner";

interface Props {
  name: string;
  question: string;
  answers: EsgPreferences;
  onChange: (answers: EsgPreferences) => void;
}

function roundPercentage(number: number, precision = 2) {
  const factor = 10 ** precision;
  return Math.round(number * factor) / factor;
}

function EsgPreference({ question, answers, name, onChange }: Props) {
  const ctx = useContext(DocumentZuzContext);

  const { client: clientError, partner: partnerError } = {
    client: ctx.getFieldError("clientEsgPreference")?.message,
    partner: ctx.getFieldError("partnerEsgPreference")?.message,
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    clientPartner: CPfield,
    field: keyof EsgPreferenceFields
  ) => {
    let actualFieldPercentage = parseFloat(
      answers[clientPartner]?.[field]?.percentage ?? "0"
    );

    let newFieldPercentage = parseFloat(e.target.value.replace(",", "."));

    if (isNaN(actualFieldPercentage)) actualFieldPercentage = 0;
    if (newFieldPercentage < 0) newFieldPercentage = 0;

    const remainingPercentage =
      100 - sum(clientPartner) + actualFieldPercentage;

    switch (true) {
      case newFieldPercentage > remainingPercentage:
        newFieldPercentage = remainingPercentage;
        break;
      case newFieldPercentage < 0:
      case isNaN(newFieldPercentage):
        newFieldPercentage = 0;
        break;
    }

    onChange({
      ...answers,
      [clientPartner]: {
        ...answers[clientPartner],
        [field]: {
          checked: ((answers && answers[clientPartner]) ?? {})[field]?.checked,
          percentage: e.target.value
            ? `${roundPercentage(newFieldPercentage)}`
            : undefined,
        },
      },
    });
  };

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    clientPartner: CPfield,
    field: keyof EsgPreferenceFields
  ) => {
    ctx.resetFieldsErrors(["clientEsgPreference", "partnerEsgPreference"]);
    onChange({
      ...answers,
      [clientPartner]: {
        ...answers[clientPartner],
        [field]: {
          ...answers[clientPartner]?.[field],
          checked: e.target.checked,
          percentage: e.target.checked ? "" : undefined,
        },
      },
    });
  };

  const sum = (clientPartner: CPfield) => {
    return Object.values(answers[clientPartner] ?? {}).reduce((acc, curr) => {
      if (curr.checked) {
        if (!curr.percentage) return acc; // if percentage is not set, return acc
        return acc + Number(curr.percentage);
      }
      return acc;
    }, 0);
  };

  const anyOfPreferenceChecked = (clientPartner: CPfield) => {
    return Object.values(answers[clientPartner] ?? {}).some(
      (value) => value.checked
    );
  };

  const isHunderedPercent = (clientPartner: CPfield) => {
    return sum(clientPartner) === 100;
  };

  return (
    <table className="mb-4">
      <tbody>
        <tr className="border border-t-0 border-slate-300 bg-slate-200">
          <td className="px-2 py-1 font-bold w-8/12">{question}</td>
          <td className="text-sm !text-center p-0 w-1/12">Klient</td>
          <td className="text-sm !text-center p-0 w-1/12">Partner</td>
          <td className="text-sm !text-center p-0 w-1/12">Klient</td>
          <td className="text-sm !text-center p-0 w-1/12">Partner</td>
        </tr>
        <tr>
          <td className="px-2 py-1">s environmentálnym zameraním</td>
          <td
            className={`px-2 py-1 text-center ${
              clientError || partnerError ? "bg-red-100" : ""
            }`}
          >
            <input
              type="checkbox"
              id="enviornmentalClientPreferenceCheckbox"
              name="enviornmentalClientPreferenceCheckbox"
              checked={answers.client?.environmental?.checked}
              onChange={(e) =>
                handleCheckboxChange(e, "client", "environmental")
              }
              data-testid="/documents/zuz/esg/input-environmentalClientPreferenceCheckbox"
            />
          </td>
          <td
            className={`px-2 py-1 text-center ${
              partnerError ? "bg-red-100" : ""
            }`}
          >
            <input
              type="checkbox"
              id="enviornmentalPartnerPreferenceCheckbox"
              name="enviornmentalPartnerPreferenceCheckbox"
              checked={answers.partner?.environmental?.checked}
              onChange={(e) =>
                handleCheckboxChange(e, "partner", "environmental")
              }
              data-testid="/documents/zuz/esg/input-environmentalPartnerPreferenceCheckbox"
            />
          </td>
          <td className="px-2 py-1 text-center">
            <div className="flex items-center justify-center gap-2">
              <Input
                type="number"
                classNameWrapper="w-24"
                placeholder=""
                name="enviornmentalClientPercentage"
                value={answers.client?.environmental?.percentage ?? ""}
                onChange={(e) =>
                  handleInputChange(e, "client", "environmental")
                }
                disabled={!answers.client?.environmental?.checked}
                error={
                  // empty space for triggering error outline
                  !isHunderedPercent("client") &&
                  answers.client?.environmental?.checked
                    ? " "
                    : ""
                }
              />
              %
            </div>
          </td>
          <td className="px-2 py-1 text-center">
            <div className="flex items-center justify-center gap-2">
              <Input
                classNameWrapper="w-24"
                type="number"
                placeholder=""
                name="enviornmentalPartnerPercentage"
                value={answers.partner?.environmental?.percentage ?? ""}
                onChange={(e) =>
                  handleInputChange(e, "partner", "environmental")
                }
                disabled={!answers.partner?.environmental?.checked}
                error={
                  // empty space for triggering error outline
                  !isHunderedPercent("partner") &&
                  answers.partner?.environmental?.checked
                    ? " "
                    : ""
                }
              />
              %
            </div>
          </td>
        </tr>
        <tr>
          <td className="px-2 py-1">so sociálnym zameraním</td>
          <td
            className={`px-2 py-1 text-center ${
              clientError || partnerError ? "bg-red-100" : ""
            }`}
          >
            <input
              type="checkbox"
              id="socialClientPreferenceCheckbox"
              name="socialClientPreferenceCheckbox"
              checked={answers.client?.social?.checked}
              onChange={(e) => handleCheckboxChange(e, "client", "social")}
              data-testid="/documents/zuz/esg/input-socialClientPreferenceCheckbox"
            />
          </td>
          <td
            className={`px-2 py-1 text-center ${
              partnerError ? "bg-red-100" : ""
            }`}
          >
            <input
              type="checkbox"
              id="socialPartnerPreferenceCheckbox"
              name="socialPartnerPreferenceCheckbox"
              checked={answers.partner?.social?.checked}
              onChange={(e) => handleCheckboxChange(e, "partner", "social")}
              data-testid="/documents/zuz/esg/input-socialPartnerPreferenceCheckbox"
            />
          </td>
          <td className="px-2 py-1 text-center">
            <div className="flex items-center justify-center gap-2">
              <Input
                classNameWrapper="w-24"
                type="number"
                placeholder=""
                name="socialClientPercentage"
                value={answers.client?.social?.percentage ?? ""}
                onChange={(e) => handleInputChange(e, "client", "social")}
                disabled={!answers.client?.social?.checked}
                error={
                  // empty space for triggering error outline
                  !isHunderedPercent("client") &&
                  answers.client?.social?.checked
                    ? " "
                    : ""
                }
                dataTestId="/documents/zuz/esg/input-esgPreferencesClientPercentage"
              />
              %
            </div>
          </td>
          <td className="px-2 py-1 text-center">
            <div className="flex items-center justify-center gap-2">
              <Input
                classNameWrapper="w-24"
                type="number"
                placeholder=""
                name="socialPartnerPercentage"
                value={answers.partner?.social?.percentage ?? ""}
                onChange={(e) => handleInputChange(e, "partner", "social")}
                disabled={!answers.partner?.social?.checked}
                error={
                  // empty space for triggering error outline
                  !isHunderedPercent("partner") &&
                  answers.partner?.social?.checked
                    ? " "
                    : ""
                }
                dataTestId="/documents/zuz/esg/input-socialPartnerPercentage"
              />
              %
            </div>
          </td>
        </tr>
        <tr>
          <td className="px-2 py-1">s korporátnym zameraním</td>
          <td
            className={`px-2 py-1 text-center ${
              clientError || partnerError ? "bg-red-100" : ""
            }`}
          >
            <input
              type="checkbox"
              id="governanceClientPreferenceCheckbox"
              name="governanceClientPreferenceCheckbox"
              checked={answers.client?.governance?.checked}
              onChange={(e) => handleCheckboxChange(e, "client", "governance")}
              data-testid="/documents/zuz/esg/input-governanceClientPreferenceCheckbox"
            />
          </td>

          <td
            className={`px-2 py-1 !text-center ${
              partnerError ? "bg-red-100" : ""
            }`}
          >
            <input
              type="checkbox"
              id="governancePartnerPreferenceCheckbox"
              name="governancePartnerPreferenceCheckbox"
              checked={answers.partner?.governance?.checked}
              onChange={(e) => handleCheckboxChange(e, "partner", "governance")}
              data-testid="/documents/zuz/esg/input-governancePartnerPreferenceCheckbox"
            />
          </td>
          <td className="px-2 py-1 text-center">
            <div className="flex items-center justify-center gap-2">
              <Input
                classNameWrapper="w-24"
                type="number"
                placeholder=""
                name="governanceClientPercentage"
                value={answers.client?.governance?.percentage ?? ""}
                onChange={(e) => handleInputChange(e, "client", "governance")}
                disabled={!answers.client?.governance?.checked}
                error={
                  // empty space for triggering error outline
                  !isHunderedPercent("client") &&
                  answers.client?.governance?.checked
                    ? " "
                    : ""
                }
                dataTestId="/documents/zuz/esg/input-esgPreferencesClientPercentage"
              />
              %
            </div>
          </td>
          <td className="px-2 py-1 text-center">
            <div className="flex items-center justify-center gap-2">
              <Input
                classNameWrapper="w-24"
                type="number"
                placeholder=""
                name="governancePartnerPercentage"
                value={answers.partner?.governance?.percentage ?? ""}
                onChange={(e) => handleInputChange(e, "partner", "governance")}
                disabled={!answers.partner?.governance?.checked}
                error={
                  // empty space for triggering error outline
                  !isHunderedPercent("partner") &&
                  answers.partner?.governance?.checked
                    ? " "
                    : ""
                }
                dataTestId="/documents/zuz/esg/input-esgPreferencesPartnerPercentage"
              />
              %
            </div>
          </td>
        </tr>
        <tr>
          <td className="px-2 py-1">
            do ESG fondov, ale nemám konkrétnu preferenciu
          </td>
          <td
            className={`px-2 py-1 text-center ${
              clientError || partnerError ? "bg-red-100" : ""
            }`}
          >
            <input
              type="checkbox"
              id="noPreferenceClientPreferenceCheckbox"
              name="noPreferenceClientPreferenceCheckbox"
              checked={answers.client?.noPreference?.checked}
              onChange={(e) =>
                handleCheckboxChange(e, "client", "noPreference")
              }
              data-testid="/documents/zuz/esg/input-noPreferenceClientPreferenceCheckbox"
            />
          </td>
          <td
            className={`px-2 py-1 text-center ${
              partnerError ? "bg-red-100" : ""
            }`}
          >
            <input
              type="checkbox"
              id="noPreferencePartnerPreferenceCheckbox"
              name="noPreferencePartnerPreferenceCheckbox"
              checked={answers.partner?.noPreference?.checked}
              onChange={(e) =>
                handleCheckboxChange(e, "partner", "noPreference")
              }
              data-testid="/documents/zuz/esg/input-noPreferencePartnerPreferenceCheckbox"
            />
          </td>
          <td className="px-2 py-1 text-center">
            <div className="flex items-center justify-center gap-2 pb-1">
              <Input
                classNameWrapper="w-24"
                type="number"
                placeholder=""
                name="noPreferenceClientPercentage"
                value={answers.client?.noPreference?.percentage ?? ""}
                onChange={(e) => handleInputChange(e, "client", "noPreference")}
                disabled={!answers.client?.noPreference?.checked}
                error={
                  // empty space for triggering error outline
                  !isHunderedPercent("client") &&
                  answers.client?.noPreference?.checked
                    ? " "
                    : ""
                }
                dataTestId="/documents/zuz/esg/input-esgPreferencesClientPercentage"
              />
              %
            </div>
          </td>
          <td className="px-2 py-1 text-center">
            <div className="flex items-center justify-center gap-2">
              <Input
                classNameWrapper="w-24"
                type="number"
                placeholder=""
                name="noPreferencePartnerPercentage"
                value={answers.partner?.noPreference?.percentage ?? ""}
                onChange={(e) =>
                  handleInputChange(e, "partner", "noPreference")
                }
                disabled={!answers.partner?.noPreference?.checked}
                error={
                  // empty space for triggering error outline
                  !isHunderedPercent("partner") &&
                  answers.partner?.noPreference?.checked
                    ? " "
                    : ""
                }
                dataTestId="/documents/zuz/esg/input-esgPreferencesPartnerPercentage"
              />
              %
            </div>
          </td>
        </tr>
        {ctx?.getFieldError("esgPreferencesClientPercentage")?.valid ===
          false ||
        ctx?.getFieldError("esgPreferencesPartnerPercentage")?.valid ===
          false ? (
          <tr>
            <td colSpan={3} />
            {!ctx?.getFieldError("esgPreferencesClientPercentage")?.valid ? (
              <td className="text-red-400 text-sm">
                {ctx?.getFieldError("esgPreferencesClientPercentage")?.message}
              </td>
            ) : (
              <td />
            )}
            {!ctx?.getFieldError("esgPreferencesPartnerPercentage")?.valid ? (
              <td className="text-red-400 text-sm">
                {ctx?.getFieldError("esgPreferencesPartnerPercentage")?.message}
              </td>
            ) : (
              <td />
            )}
          </tr>
        ) : null}
        {anyOfPreferenceChecked("client") ||
        anyOfPreferenceChecked("partner") ? (
          <tr className="border border-b-0 border-x-0">
            <td className="px-2 py-1 font-bold">Kontrolný súčet</td>
            <td />
            <td />
            {anyOfPreferenceChecked("client") ? (
              <td
                className={`px-2 py-1 text-center font-bold ${
                  !isHunderedPercent("client") ? "text-red-400" : ""
                }`}
              >
                {roundPercentage(sum("client"))}%
              </td>
            ) : (
              <td />
            )}
            {anyOfPreferenceChecked("partner") ? (
              <td
                className={`!text-center px-2 py-1 font-bold ${
                  !isHunderedPercent("partner") ? "text-red-400" : ""
                }`}
              >
                {roundPercentage(sum("partner"))}%
              </td>
            ) : (
              <td />
            )}
          </tr>
        ) : null}
        {clientError || partnerError ? (
          <tr>
            <td className="p-1 text-red-400 !text-left" colSpan={5}>
              {clientError || partnerError}
            </td>
          </tr>
        ) : null}
      </tbody>
    </table>
  );
}

export default EsgPreference;
