enum PermissionType {
  CUSTOMERS = "pt-customers",
  EXTERNAL_LINKS = "pt-external-links",
  ZUZ = "pt-cca",
  CHECK = "pt-check",
  IDENTIFICATIONS = "pt-identifications",
  USERS = "pt-users",
  PERMISSIONS = "pt-permissions",
  SYSTEM_SETTINGS = "pt-system-settings",
}

export default PermissionType;
