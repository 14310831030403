import { useState } from "react";

import { useNavigate } from "react-router";

import AllRoutes from "../features/route/Route";
import useLogger from "./logger";
import { SortablePaginationQuery, useQuery } from "./query";
import { makeRoute } from "./route";

export interface UserQuery extends SortablePaginationQuery {
  keyword: string;
  userStates: Array<string>;
}

export type UserQueryDispatcher = (query: UserQuery) => void;

type UserQueryHook = [UserQuery, (query: UserQuery) => void];

const localStorageSizeKey = "user.page.size";

function getUserPageSizeFromStorage(): number {
  return Number(localStorage.getItem(localStorageSizeKey)) || 10;
}

function setUserPageSizeToStorage(newSize: number): void {
  localStorage.setItem(localStorageSizeKey, newSize.toString());
}

export function useUserQuery(): UserQueryHook {
  const navigate = useNavigate();
  const query = useQuery();
  const { logger } = useLogger();
  const [userQuery, setUserQuery] = useState<UserQuery>({
    page: query.page ?? 1,
    size: query.size ?? getUserPageSizeFromStorage(),
    sort: query.sort ? [query.sort] : ["createdAt:desc"],
    keyword: query.keyword ?? "",
    userStates: query.userStates ?? [],
  });

  logger.debug("PARSED USER QUERY", userQuery);

  return [
    userQuery,
    (updatedUserQuery: UserQuery) => {
      const queryString = Object.keys(updatedUserQuery)
        .map((key) => {
          const parameter = updatedUserQuery[key as keyof UserQuery];
          if (Array.isArray(parameter)) {
            const val = parameter.join(",");
            return val.length > 0 ? `${key}=${val}` : undefined;
          }

          return (parameter ?? "").toString().length > 0
            ? `${key}=${parameter}`
            : undefined;
        })
        .filter((item) => item !== undefined)
        .join("&");

      logger.debug("QUERY STRING", queryString);

      // Important order: First update the URI/history, than the state because
      // it forces a repaint of the component
      setUserPageSizeToStorage(updatedUserQuery.size ?? 10);
      navigate(`${makeRoute(AllRoutes.USERS_LIST)}?${queryString}`);
      setUserQuery(updatedUserQuery);
    },
  ];
}

const allHooks = { useUserQuery };

export default allHooks;
