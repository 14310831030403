import { IdentificationStatusKey } from "../api/identification.generated";
import SelectOption from "./SelectOption";

const IdentificationStatusType: SelectOption<IdentificationStatusKey>[] = [
  {
    key: "is-error",
    name: "status.is-error",
  },
  {
    key: "is-declined",
    name: "status.is-declined",
  },
  {
    key: "is-initiated",
    name: "status.is-initiated",
  },
  {
    key: "is-success-irrelevant",
    name: "status.is-success-irrelevant",
  },
  {
    key: "is-refused",
    name: "status.is-refused",
  },
  {
    key: "is-success",
    name: "status.is-success",
  },
];

export default IdentificationStatusType;
