import { baseSplitApi as api } from "./baseApi";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    uploadAdminIdentificationDocument: build.mutation<
      UploadAdminIdentificationDocumentApiResponse,
      UploadAdminIdentificationDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/admin/identifications/${queryArg.id}/upload-document`,
        method: "POST",
        body: queryArg.body,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    getDocuments: build.query<GetDocumentsApiResponse, GetDocumentsApiArg>({
      query: (queryArg) => ({
        url: `/v1/documents/`,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort: queryArg.sort,
          keyword: queryArg.keyword,
          level: queryArg.level,
          documentStates: queryArg.documentStates,
          customerId: queryArg.customerId,
          advisorId: queryArg.advisorId,
          documentTypes: queryArg.documentTypes,
        },
      }),
    }),
    createDocument: build.mutation<
      CreateDocumentApiResponse,
      CreateDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/documents/`,
        method: "POST",
        body: queryArg.documentUpdateRequest,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    setDocumentSearchTsv: build.mutation<
      SetDocumentSearchTsvApiResponse,
      SetDocumentSearchTsvApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/documents/search-tsv`,
        method: "PATCH",
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    getDocumentById: build.query<
      GetDocumentByIdApiResponse,
      GetDocumentByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/documents/${queryArg.id}`,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    updateDocument: build.mutation<
      UpdateDocumentApiResponse,
      UpdateDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/documents/${queryArg.id}`,
        method: "PUT",
        body: queryArg.documentUpdateRequest,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    deleteDocumentById: build.mutation<
      DeleteDocumentByIdApiResponse,
      DeleteDocumentByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/documents/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    downloadControlDocument: build.query<
      DownloadControlDocumentApiResponse,
      DownloadControlDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/documents/${queryArg.id}/download-control-file`,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    downloadSignedDocument: build.query<
      DownloadSignedDocumentApiResponse,
      DownloadSignedDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/documents/${queryArg.id}/download-signed`,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    signDocumentValidation: build.query<
      SignDocumentValidationApiResponse,
      SignDocumentValidationApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/documents/${queryArg.id}/presign-errors`,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    previewDocument: build.mutation<
      PreviewDocumentApiResponse,
      PreviewDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/documents/${queryArg.id}/preview`,
        method: "POST",
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
        params: { download: queryArg.download },
      }),
    }),
    sendDocumentSignedEmail: build.mutation<
      SendDocumentSignedEmailApiResponse,
      SendDocumentSignedEmailApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/documents/${queryArg.id}/send-document-signed-email`,
        method: "PATCH",
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    signDocument: build.mutation<SignDocumentApiResponse, SignDocumentApiArg>({
      query: (queryArg) => ({
        url: `/v1/documents/${queryArg.id}/sign`,
        method: "POST",
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
        params: { mode: queryArg.mode },
      }),
    }),
    getDocumentSignatureStatus: build.query<
      GetDocumentSignatureStatusApiResponse,
      GetDocumentSignatureStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/documents/${queryArg.id}/signature-status`,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    updateDocumentStatus: build.mutation<
      UpdateDocumentStatusApiResponse,
      UpdateDocumentStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/documents/${queryArg.id}/update-status`,
        method: "PATCH",
        body: queryArg.documentStatusUpdate,
      }),
    }),
    getMyDocuments: build.query<
      GetMyDocumentsApiResponse,
      GetMyDocumentsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/me/documents/`,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort: queryArg.sort,
          keyword: queryArg.keyword,
          documentStates: queryArg.documentStates,
          documentTypes: queryArg.documentTypes,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as spfApi };
export type UploadAdminIdentificationDocumentApiResponse =
  /** status 200 OK */ SuccessResponse200;
export type UploadAdminIdentificationDocumentApiArg = {
  /** uuid */
  id: string;
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  body: {
    file: Blob;
  };
};
export type GetDocumentsApiResponse = /** status 200 OK */ DocumentPage;
export type GetDocumentsApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** page */
  page?: number;
  /** size */
  size?: number;
  /** Format is sort=email:desc,display_name:asc. Supported keys: created_at, display_name. */
  sort?: string[];
  /** keyword to search for in documents */
  keyword?: string;
  /** advisor visibility level */
  level?: string;
  /** allowed states */
  documentStates?: string[];
  /** uuid */
  customerId?: string;
  /** uuid */
  advisorId?: string;
  /** allowed types */
  documentTypes?: string[];
};
export type CreateDocumentApiResponse = /** status 200 OK */ SuccessResponse200;
export type CreateDocumentApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** Document to be updated */
  documentUpdateRequest: DocumentUpdateRequest;
};
export type SetDocumentSearchTsvApiResponse =
  /** status 200 ok */ SuccessResponse200;
export type SetDocumentSearchTsvApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
};
export type GetDocumentByIdApiResponse = /** status 200 OK */ Document;
export type GetDocumentByIdApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** uuid */
  id: string;
};
export type UpdateDocumentApiResponse = /** status 200 OK */ SuccessResponse200;
export type UpdateDocumentApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** uuid */
  id: string;
  /** Document to be updated */
  documentUpdateRequest: DocumentUpdateRequest;
};
export type DeleteDocumentByIdApiResponse =
  /** status 200 OK */ SuccessResponse200;
export type DeleteDocumentByIdApiArg = {
  /** uuid */
  id: string;
};
export type DownloadControlDocumentApiResponse =
  /** status 200 The actual PDF file */ Blob;
export type DownloadControlDocumentApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  id: string;
};
export type DownloadSignedDocumentApiResponse =
  /** status 200 The actual PDF file */ Blob;
export type DownloadSignedDocumentApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  id: string;
};
export type SignDocumentValidationApiResponse =
  /** status 200 OK */ DocumentPresignValidationResponse;
export type SignDocumentValidationApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** uuid */
  id: string;
};
export type PreviewDocumentApiResponse =
  /** status 200 OK */ SuccessResponse200;
export type PreviewDocumentApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** uuid */
  id: string;
  /** Enables you to download the preview */
  download: boolean;
};
export type SendDocumentSignedEmailApiResponse =
  /** status 200 ok */ SuccessResponse200;
export type SendDocumentSignedEmailApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** uuid */
  id: string;
};
export type SignDocumentApiResponse = /** status 200 OK */ SignDocumentResponse;
export type SignDocumentApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** uuid */
  id: string;
  /** Set the mode. Options are email or link */
  mode?: string;
};
export type GetDocumentSignatureStatusApiResponse =
  /** status 200 OK */ GetSignatureStatusResponse;
export type GetDocumentSignatureStatusApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** uuid */
  id: string;
};
export type UpdateDocumentStatusApiResponse =
  /** status 200 Resource was found */ SuccessResponse200;
export type UpdateDocumentStatusApiArg = {
  /** uuid */
  id: string;
  /** the new status */
  documentStatusUpdate: DocumentStatusUpdate;
};
export type GetMyDocumentsApiResponse = /** status 200 OK */ DocumentPage;
export type GetMyDocumentsApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** page */
  page?: number;
  /** size */
  size?: number;
  /** Format is sort=email:desc,display_name:asc. Supported keys: created_at, display_name. */
  sort?: string[];
  /** keyword to search for in documents */
  keyword?: string;
  /** allowed states */
  documentStates?: string[];
  /** allowed types */
  documentTypes?: string[];
};
export type SuccessResponse200 = {
  id?: string;
  status?: string;
};
export type ValidationError = {
  code?: string;
  field?: string;
  message?: string;
};
export type ErrorResponse400 = {
  code?: number;
  data?: ValidationError[];
  message?: string;
};
export type ErrorResponse401 = {
  code?: number;
  data?: {
    [key: string]: string;
  }[];
  message?: string;
};
export type ErrorResponse500 = {
  code?: number;
  data?: {
    [key: string]: string;
  }[];
  message?: string;
};
export type DocumentStatusKey =
  | "ds-draft"
  | "ds-signed"
  | "ds-signing-in-progress"
  | "ds-signing-rejected"
  | "ds-signing-expired"
  | "ds-deleted"
  | "ds-check-draft"
  | "ds-check-in-office"
  | "ds-check-closed"
  | "ds-check-deleted"
  | "ds-check-incoming-post";
export type DocumentTypeKey = "dt-cca" | "dt-check";
export type FilterOptionsKey = "fo-user-owned" | "fo-structural" | "fo-all";
export type DocumentFilter = {
  advisorId?: string;
  customerId?: string;
  documentStates?: DocumentStatusKey[];
  documentTypes?: DocumentTypeKey[];
  implementorId?: string;
  keyword?: string;
  level?: FilterOptionsKey;
  sharedAdvisorId?: string;
};
export type CountryKey = "sk";
export type Country = {
  key?: CountryKey;
  name?: string;
};
export type Address = {
  city?: string;
  country?: Country;
  street?: string;
  zip?: string;
};
export type ExternalLinkAuthTypeKey = "elat-sso" | "elat-log" | "elat-noa";
export type LinkType = {
  key?: ExternalLinkAuthTypeKey;
  name?: string;
};
export type ExternalLinkCategoryKey =
  | "lct-int"
  | "lct-ins"
  | "lct-bnk"
  | "lct-dss"
  | "lct-oth"
  | "lct-inc";
export type ExternalLinkCategory = {
  key?: ExternalLinkCategoryKey;
  name?: string;
};
export type ImageResponse = {
  type?: string;
  url?: string;
};
export type ExternalLinkConfigurationStatusKey =
  | "elcs-active"
  | "elcs-deactivated";
export type ExternalLinkConfigurationStatus = {
  key?: ExternalLinkConfigurationStatusKey;
  name?: string;
};
export type ExternalLinkConfiguration = {
  advisorExternalID?: string;
  advisorFirstame?: string;
  advisorId?: string;
  advisorLastname?: string;
  createdAt?: string;
  id?: string;
  login?: string;
  password?: string;
  status?: ExternalLinkConfigurationStatus;
  updatedAt?: string;
};
export type AdvisorSectorTypeKey =
  | "ast-aml"
  | "ast-ins"
  | "ast-len"
  | "ast-acc"
  | "ast-cap"
  | "ast-sup"
  | "ast-old";
export type AdvisorSectorType = {
  key?: AdvisorSectorTypeKey;
  name?: string;
};
export type ExternalLinkSector = {
  mandatory?: boolean;
  sectorType?: AdvisorSectorType;
};
export type ExternalLinkStatusKey = "els-active" | "els-deactivated";
export type ExternalLinkStatus = {
  key?: ExternalLinkStatusKey;
  name?: string;
};
export type ExternalLinkTypeKey = "ast-per" | "ast-gen";
export type ExternalLinkType = {
  key?: ExternalLinkTypeKey;
  name?: string;
};
export type ExternalLink = {
  authType?: LinkType;
  category?: ExternalLinkCategory;
  createdAt?: string;
  displayLink?: string;
  id?: string;
  image?: ImageResponse[];
  imageId?: string;
  linkConfigurations?: ExternalLinkConfiguration[];
  name?: string;
  order?: number;
  sectors?: ExternalLinkSector[];
  status?: ExternalLinkStatus;
  type?: ExternalLinkType;
  updatedAt?: string;
};
export type FavouriteExternalLink = {
  createdAt?: string;
  externalLink?: ExternalLink;
  order?: number;
  updatedAt?: string;
};
export type GenderKey = "gk-male" | "gk-female" | "gk-none";
export type Gender = {
  key?: GenderKey;
  name?: string;
};
export type AdvisorPositionKey =
  | "SP"
  | "KR"
  | "RR"
  | "OR"
  | "M"
  | "S3"
  | "S2"
  | "S1"
  | "SK"
  | "K2"
  | "K1"
  | "T"
  | "BP"
  | "BP90"
  | "BP85"
  | "BP70"
  | "BP00"
  | "FEL1"
  | "FEL2"
  | "FEL3"
  | "FEL4";
export type AdvisorPosition = {
  higherMLM?: AdvisorPositionKey;
  key?: AdvisorPositionKey;
  name?: string;
};
export type AdvisorSector = {
  certificate_from?: string;
  certificate_to?: string;
  createdAt?: string;
  id?: string;
  ofv_from?: string;
  ofv_to?: string;
  registration_from?: string;
  registration_to?: string;
  type?: AdvisorSectorType;
  updatedAt?: string;
};
export type AdvisorTypeKey = "at-fo" | "at-fop";
export type AdvisorType = {
  key?: AdvisorTypeKey;
  name?: string;
};
export type OriginSystemKey = "uo-portal" | "uo-icm";
export type OriginSystem = {
  key?: OriginSystemKey;
  name?: string;
};
export type UserStatusKey = "us-active" | "us-deactivated" | "us-resigned";
export type UserStatus = {
  key?: UserStatusKey;
  name?: string;
};
export type User = {
  createdAt?: string;
  displayName: string;
  email: string;
  id?: string;
  originSystem?: OriginSystem;
  status?: UserStatus;
  updatedAt?: string;
};
export type Advisor = {
  businessAddress?: Address;
  businessName?: string;
  createdAt?: string;
  displayName?: string;
  evidenceNumber: string;
  externalId?: string;
  favouriteLinks?: FavouriteExternalLink[];
  firstname?: string;
  gender?: Gender;
  ico?: string;
  id?: string;
  lastname?: string;
  mentor?: Advisor;
  nbsRegistrationNumber?: string;
  parent?: Advisor;
  permanentAddress?: Address;
  phone_number?: string;
  pin?: string;
  position?: AdvisorPosition;
  sectors?: AdvisorSector[];
  title?: string;
  type?: AdvisorType;
  updatedAt?: string;
  updatedBy?: User;
  updatedByICM?: string;
  user?: User;
};
export type FileStatusKey = "fs-uploaded" | "fs-active" | "fs-deleted";
export type FileStatus = {
  key?: FileStatusKey;
  name?: string;
};
export type File = {
  createdAt?: string;
  extension?: string;
  id?: string;
  key?: string;
  name?: string;
  status?: FileStatus;
  updatedAt?: string;
};
export type AdvisorCustomerOriginKey = "aco-icm" | "aco-portal";
export type CustomerOrigin = {
  key?: AdvisorCustomerOriginKey;
  name?: string;
};
export type AvisorCustomer = {
  advisor?: Advisor;
  origin?: CustomerOrigin;
};
export type IcmPortalAddressResponse = {
  city?: string;
  street?: string;
  zip?: string;
};
export type IcmPortalCustomerResponse = {
  authorizedPersonPosition?: string;
  businessAddress?: IcmPortalAddressResponse;
  businessName?: string;
  cop?: string;
  correspondenceAddress?: IcmPortalAddressResponse;
  dateOfBirth?: string;
  email?: string;
  firstname?: string;
  foreignClient?: boolean;
  genderKey?: GenderKey;
  ico?: string;
  lastname?: string;
  note?: string;
  otherIDCardNumber?: string;
  passportNumber?: string;
  permanentAddress?: IcmPortalAddressResponse;
  phoneNumber?: string;
  pin?: string;
  title?: string;
};
export type CustomerTypeKey = "ct-fo" | "ct-fop" | "ct-po";
export type CustomerType = {
  key?: CustomerTypeKey;
  name?: string;
};
export type Customer = {
  advisors?: AvisorCustomer[];
  authorizedPersonPosition?: string;
  businessAddress?: Address;
  businessName?: string;
  citizenship?: string;
  clientDisplayName?: string;
  contractAgreement?: boolean;
  contractSignedAt?: string;
  cop?: string;
  correspondenceAddress?: Address;
  createdAt?: string;
  dateOfBirth?: string;
  dateOfConsent?: string;
  email?: string;
  firstname?: string;
  foreignClient?: boolean;
  gender?: Gender;
  icmDiff?: IcmPortalCustomerResponse;
  icmID?: string;
  icmStatus?: boolean;
  ico?: string;
  id?: string;
  lastIDGuardIdentification?: string;
  lastname?: string;
  note?: string;
  opValidUntil?: string;
  origin?: OriginSystem;
  otherIDCardNumber?: string;
  otherIDCardType?: string;
  otherIDCardValidUntil?: string;
  pasValidUntil?: string;
  passportNumber?: string;
  permanentAddress?: Address;
  personalAdvisor?: Advisor;
  personalMarketingInformation?: boolean;
  phoneNumber?: string;
  pin?: string;
  profession?: string;
  title?: string;
  type?: CustomerType;
  updatedAt?: string;
  updatedBy?: User;
  updatedByICM?: string;
  validIdentificationId?: string;
};
export type CustomerDocumentFile = {
  customer?: Customer;
  file?: File;
  id?: string;
  isCcaFile?: boolean;
  note?: string;
};
export type AdvisorProtectedView = {
  displayName?: string;
  email?: string;
  evidenceNumber?: string;
  id?: string;
};
export type DocumentSignatureNotification = {
  email?: string;
  link?: string;
};
export type SignatureTypeKey = "st-email" | "st-link";
export type SignatureType = {
  key?: SignatureTypeKey;
  name?: string;
};
export type DocumentStatus = {
  key?: DocumentStatusKey;
  name?: string;
};
export type DocumentType = {
  key?: DocumentTypeKey;
  name?: string;
};
export type Document = {
  advisor?: Advisor;
  canUpdate?: boolean;
  content?: {
    [key: string]: any;
  };
  contractId?: string;
  controlPdf?: File;
  createdAt?: string;
  customerFiles?: CustomerDocumentFile[];
  customers?: Customer[];
  displayID?: string;
  displayName?: string;
  file?: File;
  id?: string;
  implementor?: Advisor;
  mentor?: Advisor;
  sharedAdvisor?: AdvisorProtectedView;
  signatureNotification?: DocumentSignatureNotification[];
  signatureType?: SignatureType;
  status?: DocumentStatus;
  type?: DocumentType;
  updatedAt?: string;
  url?: string;
};
export type SortFieldDirection = "asc" | "desc";
export type SortFieldKey =
  | "createdAt"
  | "updatedAt"
  | "startsAt"
  | "validTo"
  | "validUntil"
  | "firstname"
  | "lastname"
  | "name"
  | "displayName"
  | "evidenceNumber"
  | "email"
  | "pin"
  | "ico"
  | "advisorEmail"
  | "customerEmail"
  | "mentorEmail"
  | "phoneNumber"
  | "displayId"
  | "street"
  | "city"
  | "zip"
  | "id"
  | "owner"
  | "type"
  | "status"
  | "customer"
  | "advisor"
  | "updatedBy";
export type SortField = {
  direction?: SortFieldDirection;
  field?: SortFieldKey;
};
export type DocumentPage = {
  count?: number;
  filter?: DocumentFilter;
  items?: Document[];
  last?: boolean;
  page?: number;
  size?: number;
  sort?: SortField[];
};
export type CustomerFileUpdateRequest = {
  customerId: string;
  fileId: string;
  id?: string;
  note: string;
};
export type DocumentUpdateRequest = {
  advisorId?: string;
  content?: string;
  customerFiles?: CustomerFileUpdateRequest[];
  customers?: string[];
  fileId?: string;
  implementorId?: string;
  mentorId?: string;
  name?: string;
  sharedAdvisorId?: string;
  typeKey?: DocumentTypeKey;
};
export type ErrorResponse404 = {
  code?: number;
  data?: {
    [key: string]: string;
  }[];
  message?: string;
};
export type DocumentPresignValidationResponse = {
  errors?: ValidationError[];
};
export type SigniNotificationLinks = {
  contract?: string;
};
export type SigniNotifications = {
  email?: string;
  links?: SigniNotificationLinks;
};
export type SignDocumentResponse = {
  attachments?: string[];
  contract_id?: number;
  error_code?: number;
  error_message?: string;
  is_error?: boolean;
  mentor_id?: string;
  notifications?: SigniNotifications[];
  state?: string;
};
export type SignerData = {
  address?: string;
  birthdate?: string;
  firstname?: string;
  surname?: string;
};
export type SigniContractStatusKey = "pending" | "completed";
export type GetSignatureStatusResponse = {
  contract_id?: string;
  file?: string;
  modified?: string;
  signer_data?: SignerData;
  state?: SigniContractStatusKey;
};
export type ErrorResponse403 = {
  code?: number;
  data?: {
    [key: string]: string;
  }[];
  message?: string;
};
export type DocumentStatusUpdate = {
  statusKey?: DocumentStatusKey;
};
export const {
  useUploadAdminIdentificationDocumentMutation,
  useGetDocumentsQuery,
  useCreateDocumentMutation,
  useSetDocumentSearchTsvMutation,
  useGetDocumentByIdQuery,
  useUpdateDocumentMutation,
  useDeleteDocumentByIdMutation,
  useDownloadControlDocumentQuery,
  useDownloadSignedDocumentQuery,
  useSignDocumentValidationQuery,
  usePreviewDocumentMutation,
  useSendDocumentSignedEmailMutation,
  useSignDocumentMutation,
  useGetDocumentSignatureStatusQuery,
  useUpdateDocumentStatusMutation,
  useGetMyDocumentsQuery,
} = injectedRtkApi;
