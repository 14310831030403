import { baseSplitApi as api } from "./baseApi";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMentors: build.query<GetMentorsApiResponse, GetMentorsApiArg>({
      query: (queryArg) => ({
        url: `/v1/mentors/`,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort: queryArg.sort,
          keyword: queryArg.keyword,
          nbs: queryArg.nbs,
          mentorStates: queryArg.mentorStates,
          capitalMarket: queryArg.capitalMarket,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as spfApi };
export type GetMentorsApiResponse = /** status 200 OK */ MentorPage;
export type GetMentorsApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** page */
  page?: number;
  /** size */
  size?: number;
  /** Format is sort=email:desc,firstname:asc. Supported keys: created_at, firstname, lastname, email. */
  sort?: string[];
  /** keyword to search for in users */
  keyword?: string;
  /** apply active mentor filter */
  nbs?: boolean;
  /** allowed states */
  mentorStates?: string[];
  /** use capitalMarket condition */
  capitalMarket?: boolean;
};
export type RoleUserStatusKey = "rus-active" | "rus-deactivated";
export type MentorFilter = {
  capitalMarket?: boolean;
  keyword?: string;
  mentorStates?: RoleUserStatusKey[];
  nbs?: boolean;
};
export type CountryKey = "sk";
export type Country = {
  key?: CountryKey;
  name?: string;
};
export type Address = {
  city?: string;
  country?: Country;
  street?: string;
  zip?: string;
};
export type ExternalLinkAuthTypeKey = "elat-sso" | "elat-log" | "elat-noa";
export type LinkType = {
  key?: ExternalLinkAuthTypeKey;
  name?: string;
};
export type ExternalLinkCategoryKey =
  | "lct-int"
  | "lct-ins"
  | "lct-bnk"
  | "lct-dss"
  | "lct-oth"
  | "lct-inc";
export type ExternalLinkCategory = {
  key?: ExternalLinkCategoryKey;
  name?: string;
};
export type ImageResponse = {
  type?: string;
  url?: string;
};
export type ExternalLinkConfigurationStatusKey =
  | "elcs-active"
  | "elcs-deactivated";
export type ExternalLinkConfigurationStatus = {
  key?: ExternalLinkConfigurationStatusKey;
  name?: string;
};
export type ExternalLinkConfiguration = {
  advisorExternalID?: string;
  advisorFirstame?: string;
  advisorId?: string;
  advisorLastname?: string;
  createdAt?: string;
  id?: string;
  login?: string;
  password?: string;
  status?: ExternalLinkConfigurationStatus;
  updatedAt?: string;
};
export type AdvisorSectorTypeKey =
  | "ast-aml"
  | "ast-ins"
  | "ast-len"
  | "ast-acc"
  | "ast-cap"
  | "ast-sup"
  | "ast-old";
export type AdvisorSectorType = {
  key?: AdvisorSectorTypeKey;
  name?: string;
};
export type ExternalLinkSector = {
  mandatory?: boolean;
  sectorType?: AdvisorSectorType;
};
export type ExternalLinkStatusKey = "els-active" | "els-deactivated";
export type ExternalLinkStatus = {
  key?: ExternalLinkStatusKey;
  name?: string;
};
export type ExternalLinkTypeKey = "ast-per" | "ast-gen";
export type ExternalLinkType = {
  key?: ExternalLinkTypeKey;
  name?: string;
};
export type ExternalLink = {
  authType?: LinkType;
  category?: ExternalLinkCategory;
  createdAt?: string;
  displayLink?: string;
  id?: string;
  image?: ImageResponse[];
  imageId?: string;
  linkConfigurations?: ExternalLinkConfiguration[];
  name?: string;
  order?: number;
  sectors?: ExternalLinkSector[];
  status?: ExternalLinkStatus;
  type?: ExternalLinkType;
  updatedAt?: string;
};
export type FavouriteExternalLink = {
  createdAt?: string;
  externalLink?: ExternalLink;
  order?: number;
  updatedAt?: string;
};
export type GenderKey = "gk-male" | "gk-female" | "gk-none";
export type Gender = {
  key?: GenderKey;
  name?: string;
};
export type AdvisorPositionKey =
  | "SP"
  | "KR"
  | "RR"
  | "OR"
  | "M"
  | "S3"
  | "S2"
  | "S1"
  | "SK"
  | "K2"
  | "K1"
  | "T"
  | "BP"
  | "BP90"
  | "BP85"
  | "BP70"
  | "BP00"
  | "FEL1"
  | "FEL2"
  | "FEL3"
  | "FEL4";
export type AdvisorPosition = {
  higherMLM?: AdvisorPositionKey;
  key?: AdvisorPositionKey;
  name?: string;
};
export type AdvisorSector = {
  certificate_from?: string;
  certificate_to?: string;
  createdAt?: string;
  id?: string;
  ofv_from?: string;
  ofv_to?: string;
  registration_from?: string;
  registration_to?: string;
  type?: AdvisorSectorType;
  updatedAt?: string;
};
export type AdvisorTypeKey = "at-fo" | "at-fop";
export type AdvisorType = {
  key?: AdvisorTypeKey;
  name?: string;
};
export type OriginSystemKey = "uo-portal" | "uo-icm";
export type OriginSystem = {
  key?: OriginSystemKey;
  name?: string;
};
export type UserStatusKey = "us-active" | "us-deactivated" | "us-resigned";
export type UserStatus = {
  key?: UserStatusKey;
  name?: string;
};
export type User = {
  createdAt?: string;
  displayName: string;
  email: string;
  id?: string;
  originSystem?: OriginSystem;
  status?: UserStatus;
  updatedAt?: string;
};
export type Advisor = {
  businessAddress?: Address;
  businessName?: string;
  createdAt?: string;
  displayName?: string;
  evidenceNumber: string;
  externalId?: string;
  favouriteLinks?: FavouriteExternalLink[];
  firstname?: string;
  gender?: Gender;
  ico?: string;
  id?: string;
  lastname?: string;
  mentor?: Advisor;
  nbsRegistrationNumber?: string;
  parent?: Advisor;
  permanentAddress?: Address;
  phone_number?: string;
  pin?: string;
  position?: AdvisorPosition;
  sectors?: AdvisorSector[];
  title?: string;
  type?: AdvisorType;
  updatedAt?: string;
  updatedBy?: User;
  updatedByICM?: string;
  user?: User;
};
export type SortFieldDirection = "asc" | "desc";
export type SortFieldKey =
  | "createdAt"
  | "updatedAt"
  | "startsAt"
  | "validTo"
  | "validUntil"
  | "firstname"
  | "lastname"
  | "name"
  | "displayName"
  | "evidenceNumber"
  | "email"
  | "pin"
  | "ico"
  | "advisorEmail"
  | "customerEmail"
  | "mentorEmail"
  | "phoneNumber"
  | "displayId"
  | "street"
  | "city"
  | "zip"
  | "id"
  | "owner"
  | "type"
  | "status"
  | "customer"
  | "advisor"
  | "updatedBy";
export type SortField = {
  direction?: SortFieldDirection;
  field?: SortFieldKey;
};
export type MentorPage = {
  count?: number;
  filter?: MentorFilter;
  items?: Advisor[];
  last?: boolean;
  page?: number;
  size?: number;
  sort?: SortField[];
};
export type ValidationError = {
  code?: string;
  field?: string;
  message?: string;
};
export type ErrorResponse400 = {
  code?: number;
  data?: ValidationError[];
  message?: string;
};
export const { useGetMentorsQuery } = injectedRtkApi;
