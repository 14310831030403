import React from "react";

import { faDatabase } from "@fortawesome/free-solid-svg-icons";

import Badge from "./Badge";

interface Properties {
  count?: number;
}

function CountBadge({ count }: Properties) {
  return (
    <Badge
      className="self-center"
      bgClass="bg-spf-primary"
      color="text-white"
      icon={faDatabase}
      label={count?.toString()}
      pill
      small
    />
  );
}

CountBadge.defaultProps = {
  count: 0,
};

export default CountBadge;
