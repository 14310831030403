import React, { FormEvent, useState } from "react";

import { faCheck, faHeart, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";

import { useResetPasswordMutation } from "../../api/auth.generated";
import Alert from "../../components/alerts/Alert";
import Button from "../../components/buttons/Button";
import Input from "../../components/inputs/Input";
import InputGroup from "../../components/inputs/InputGroup";
import { makeRoute } from "../../hooks/route";
import { useVersion } from "../../hooks/version";
import Logo from "../logo/Logo";
import AllRoutes from "../route/Route";
import { authSelector } from "./authSlice";

function ResetPasswordView(): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { token } = useParams();
  const version = useVersion();

  const { isLoggedIn, error } = useSelector(authSelector);

  const [newPassword, setNewPassword] = useState<string>("");
  const [repeatPassword, setRepeatPassword] = useState<string>("");
  const [validationError, setValidationError] = useState<string>("");
  const [resetPasswordRequest] = useResetPasswordMutation();
  const [inProgress, setInProgress] = useState<boolean>(false);

  if (isLoggedIn) {
    return <Navigate to={AllRoutes.DASHBOARD.path} />;
  }

  const doResetPassword = () => {
    setInProgress(true);
    const inputValidation = doValidation();
    if (!inputValidation) {
      setInProgress(false);
      return;
    }
    resetPasswordRequest({
      resetPasswordUpdateRequest: {
        newPassword,
        token: token ?? "",
      },
    }).then((response: any) => {
      setInProgress(false);
      if (response.error && response.error.status === 400) {
        toast.warning(t("app.page.reset-password.tokenExpired") || "");
      }
      if (response.data && response.data.status === "ok") {
        toast.success(t("app.page.reset-password.success") || "");

        // redirect to login page
        navigate(makeRoute(AllRoutes.AUTH_LOGIN));
      }
    });
  };

  const doValidation = () => {
    setValidationError("");
    if (newPassword === "") {
      setValidationError(t("changePassword.newPasswordNotSet") || "");
      return false;
    }
    if (repeatPassword === "") {
      setValidationError(t("app.page.reset-password.form.repeat.notSet") || "");
      return false;
    }
    if (newPassword !== repeatPassword) {
      setValidationError(t("changePassword.repeatPasswordNotEqual") || "");
      return false;
    }
    return true;
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    doResetPassword();
  };

  return (
    <main>
      <div className="w-full h-screen flex flex-col md:items-center md:justify-center bg-gray-100 text-gray-700 overflow-hidden">
        <div className="flex flex-col md:flex-row bg-white border border-gray-200 w-full max-w-screen-md rounded-xl">
          <div className="order-2 md:order-1 w-full md:w-1/2 bg-white rounded-l-xl p-8 mt-4">
            <form className="login" onSubmit={onSubmit}>
              <h1 className="text-4xl mb-6">
                <span>{t("app.page.reset-password.heading")}</span>
              </h1>
              {validationError.length > 0 || error?.message ? (
                <Alert variant="danger">
                  {validationError.length ? validationError : error?.message}
                </Alert>
              ) : (
                <div className="text-gray-400 mb-4">
                  <span>{t("app.page.reset-password.info")}</span>
                </div>
              )}
              <InputGroup>
                <Input
                  autoFocus
                  type="password"
                  value={newPassword}
                  name="new-password"
                  label={t("app.page.reset-password.form.new.label") || ""}
                  placeholder=""
                  onChange={(e) => setNewPassword(e.currentTarget.value)}
                  autocomplete="new-password"
                />
              </InputGroup>
              <InputGroup>
                <Input
                  type="password"
                  value={repeatPassword}
                  name="repeat-password"
                  label={t("app.page.reset-password.form.repeat.label") || ""}
                  placeholder=""
                  onChange={(e) => setRepeatPassword(e.currentTarget.value)}
                  autocomplete="off"
                />
              </InputGroup>
              <InputGroup>
                <Button
                  className="mr-2"
                  icon={inProgress ? faSyncAlt : faCheck}
                  inProgress={inProgress}
                  type="submit"
                >
                  <span>{t("app.page.reset-password.form.submit.label")}</span>
                </Button>
              </InputGroup>
            </form>
          </div>
          <div className="order-1 md:order-2 text-white bg-spf-blue p-10 d-md-down-none ml-auto w-full md:w-1/2 md:rounded-r-xl flex flex-row h-full place-items-center justify-center">
            <div className="text-center">
              <Logo size="8x" color="white" />
              <div className="tracking-widest">
                <h1 className="font-semibold text-4xl text-gray-200">
                  <span>{t("app.name")}</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-10 text-center opacity-10 hover:opacity-80 transition-opacity">
          <small>
            <span>Version: {version}, developed by</span>
            <a className="mx-2" href="https://www.ezmid.com/">
              EZMID
            </a>
            <span>with</span>
            <FontAwesomeIcon
              className="ml-2"
              color="red"
              icon={faHeart}
              size="1x"
            />
          </small>
        </div>
      </div>
    </main>
  );
}

export default ResetPasswordView;
