import { CustomerTypeKey } from "../api/customers.generated";
import SelectOption from "./SelectOption";

export const CT_FO = "ct-fo" as CustomerTypeKey;
export const CT_FOP = "ct-fop" as CustomerTypeKey;
export const CT_PO = "ct-po" as CustomerTypeKey;

export const CustomerTypeKeys = {
  CT_FO,
  CT_FOP,
  CT_PO,
};

const CustomerType: SelectOption<CustomerTypeKey>[] = [
  {
    key: CT_FO,
    name: "app.customerType.fo",
  },
  {
    key: CT_FOP,
    name: "app.customerType.fop",
  },
  {
    key: CT_PO,
    name: "app.customerType.po",
  },
];

export default CustomerType;
