import React from "react";

interface Properties {
  children: React.ReactNode;
}

function InputGroup({ children }: Properties) {
  return <div className="py-2">{children}</div>;
}

export default InputGroup;
