import React from "react";

import { faFingerprint } from "@fortawesome/free-solid-svg-icons";

import Badge from "./Badge";

interface ComponentProperties {
  id: string;
  onClick?: () => void;
}

function IDBadge({ id, onClick }: ComponentProperties): JSX.Element {
  return (
    <Badge
      onClick={onClick}
      bgClass="bg-slate-200"
      color="text-slate-600"
      icon={faFingerprint}
      label={id.toUpperCase().substr(0, 8)}
      pill
      small
    />
  );
}
IDBadge.defaultProps = {
  onClick: undefined,
};

export default IDBadge;
