import React, { ChangeEvent } from "react";

import propTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Role } from "../../api/permissionRule.generated";
import RoleOptions from "../../enums/RoleOptions";

interface Properties {
  selectedKey?: string;
  filterOptions?: Role[];
  onSelect: (item: Role) => void;
}

function PermissionRuleSelect({
  selectedKey,
  onSelect,
  filterOptions,
}: Properties) {
  const { t } = useTranslation();

  const handleOnChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const item = RoleOptions.find(
      (option) => option.key === e.target.value
    ) as Role;
    onSelect(item as Role);
  };

  const options =
    filterOptions && filterOptions?.length > 0
      ? RoleOptions.filter(
          (option) =>
            filterOptions?.includes(option.key as Role) &&
            filterOptions.length > 0
        )
      : RoleOptions;

  return (
    <div>
      <div className="flex text-gray-500 text-sm mb-1">
        <span>{t("select.permission-rule-role.label")}</span>
      </div>
      <select
        className="block text-gray-700 mb-2 border border-gray-300 w-full p-2 rounded"
        defaultValue={selectedKey}
        onChange={handleOnChange}
      >
        {options.map((item) => (
          <option
            key={`index-${item.key}`}
            label={t(item.name) || ""}
            value={item.key}
          >
            <span>{t(item.name)}</span>
          </option>
        ))}
      </select>
    </div>
  );
}

PermissionRuleSelect.propTypes = {
  selectedKey: propTypes.string,
};

PermissionRuleSelect.defaultProps = {
  filterOptions: [],
  selectedKey: undefined,
};

export default PermissionRuleSelect;
