import React, { useContext, useEffect, useState } from "react";

import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";

import Button from "../../../components/buttons/Button";
import Card from "../../../components/card/Card";
import AddInvestmentIntentionModal from "../../../components/modals/AddInvestmentIntentionModal";
import {
  InvestmentCustomerType,
  translate as translateInvestmentCustomerType,
} from "../../../enums/InvestmentCustomerType";
import {
  translate as translateInvestmentType,
  InvestmentType,
} from "../../../enums/InvestmentType";
import useDocumentGetters from "../../../hooks/documentContent";
import useDocumentValidation from "../../../hooks/documentValidation";
import { Document } from "../../../models/Document";
import {
  InvestmentIntention,
  AnswerClientPartnerType,
  KnownFinancialToolsType,
  FinancialToolsExperienceType,
  Questionare,
  InvestmentIntentionOnce,
  InvestmentIntentionRepeated,
  YearsPeriod,
  AnswersABCDE,
  EsgInterestType,
  EsgPreferences,
  SustainabiltyRequestType,
  EsgInterest,
  FinancialToolsFields,
  QuestionaryPoints,
} from "../../../models/DocumentContent";
import DocumentZuzContext from "../DocumentZuzFormViewContext";
import EsgPreference from "./esg/EsgPreference";
import InterestInEsg from "./esg/InterestInEsg";
import SustainabiltyRequestSelect from "./esg/SustainabiltyRequestSelect";
import InvestmentIntenttionTableOverview from "./investmentIntention/InvestmentIntentionTableOverview";
import FinancialToolsExperience from "./questionare/FinancialToolsExperience";
import KnownFinancialTools from "./questionare/KnownFinancialTools";
import QuestionABC from "./questionare/QuestionABC";

interface Properties {
  document: Document;
  setDocument: React.Dispatch<Document>;
  removedPartnerQuestionare: Questionare;
  setRemovedPartnerQuestionare: React.Dispatch<Questionare>;
}

type CalculationLogEntry = {
  question: string;
  clientAnswer: string | undefined;
  clientPoints?: number | undefined;
  clientSum?: number | undefined;
  partnerAnswer: string | undefined;
  partnerPoints?: number | undefined;
  partnerSum?: number | undefined;
};

type PointsABC = {
  [key: string]: number;
};

type PointsMapType = {
  investitionType: PointsABC;
  reactionToLoss: PointsABC;
  knownFinancialTools: {
    count: number;
    points: number;
  }[];
  financialToolsExperience: {
    [key: string]: {
      answer: YearsPeriod;
      points: number;
    }[];
  };
  investmentTimeHorizon: {
    answer: AnswersABCDE;
    points: number;
  }[];
  investmentIntentionsOnce: {
    answer: AnswersABCDE;
    points: number;
  }[];
  investmentIntentionsRepeated: {
    answer: AnswersABCDE;
    points: number;
  }[];
};

function Page04Card({
  document,
  setDocument,
  removedPartnerQuestionare,
  setRemovedPartnerQuestionare,
}: Properties) {
  const { handleQuestionaire } = useDocumentGetters();
  const { getInvestmentIntentionTableErrors } = useDocumentValidation();
  const ctx = useContext(DocumentZuzContext);

  const [
    isAddInvestmentIntentionModalOpen,
    setIsAddInvestmentIntentionModalOpen,
  ] = useState(false);

  const pointsMap: PointsMapType = {
    investitionType: {
      A: 0,
      B: 6,
      C: 12,
    },
    reactionToLoss: {
      A: 0,
      B: 3,
      C: 6,
    },
    knownFinancialTools: [
      {
        count: 0,
        points: 0,
      },
      {
        count: 1,
        points: 1,
      },
      {
        count: 2,
        points: 1,
      },
      {
        count: 3,
        points: 2,
      },
      {
        count: 4,
        points: 2,
      },
      {
        count: 5,
        points: 3,
      },
    ],
    financialToolsExperience: {
      bond: [
        {
          answer: YearsPeriod.NONE,
          points: 0,
        },
        {
          answer: YearsPeriod.LESS_THAN_3,
          points: 0.5,
        },
        {
          answer: YearsPeriod.MORE_THAN_3,
          points: 1,
        },
      ],
      stock: [
        {
          answer: YearsPeriod.NONE,
          points: 0,
        },
        {
          answer: YearsPeriod.LESS_THAN_3,
          points: 0.5,
        },
        {
          answer: YearsPeriod.MORE_THAN_3,
          points: 1,
        },
      ],
      mutualFund: [
        {
          answer: YearsPeriod.NONE,
          points: 0,
        },
        {
          answer: YearsPeriod.LESS_THAN_3,
          points: 0.5,
        },
        {
          answer: YearsPeriod.MORE_THAN_3,
          points: 1,
        },
      ],
      etf: [
        {
          answer: YearsPeriod.NONE,
          points: 0,
        },
        {
          answer: YearsPeriod.LESS_THAN_3,
          points: 0.5,
        },
        {
          answer: YearsPeriod.MORE_THAN_3,
          points: 1,
        },
      ],
      derivative: [
        {
          answer: YearsPeriod.NONE,
          points: 0,
        },
        {
          answer: YearsPeriod.LESS_THAN_3,
          points: 1,
        },
        {
          answer: YearsPeriod.MORE_THAN_3,
          points: 2,
        },
      ],
    },
    investmentTimeHorizon: [
      {
        answer: AnswersABCDE.ANSWER_A,
        points: 0,
      },
      {
        answer: AnswersABCDE.ANSWER_B,
        points: 8,
      },
      {
        answer: AnswersABCDE.ANSWER_C,
        points: 16,
      },
      {
        answer: AnswersABCDE.ANSWER_D,
        points: 24,
      },
    ],
    investmentIntentionsOnce: [
      {
        answer: AnswersABCDE.ANSWER_A,
        points: 6,
      },
      {
        answer: AnswersABCDE.ANSWER_B,
        points: 4,
      },
      {
        answer: AnswersABCDE.ANSWER_C,
        points: 2,
      },
      {
        answer: AnswersABCDE.ANSWER_D,
        points: 0,
      },
    ],
    investmentIntentionsRepeated: [
      {
        answer: AnswersABCDE.ANSWER_A,
        points: 9,
      },
      {
        answer: AnswersABCDE.ANSWER_B,
        points: 6,
      },
      {
        answer: AnswersABCDE.ANSWER_C,
        points: 3,
      },
      {
        answer: AnswersABCDE.ANSWER_D,
        points: 0,
      },
    ],
  };
  const [intentionDefault, setIntentionDefault] = useState<
    InvestmentIntention | undefined
  >(undefined);

  const [questionare, setQuestionare] = useState<Questionare>(
    document.content.questionaire ?? {
      investitionType: { client: "", partner: "" },
      reactionToLoss: { client: "", partner: "" },
      knownFinancialTools: { client: [], partner: [] },
      financialToolsExperience: { client: {}, partner: {} },
      interestInEsg: {
        client: undefined,
        partner: undefined,
      },
      esgPreferences: { client: undefined, partner: undefined },
      sustainabilityRequest: { client: undefined, partner: undefined },
      investmentIntentionsOnce: [],
      investmentIntentionsRepeated: [],
    }
  );

  const [questionaryPoints, setQuestionaryPoints] = useState<QuestionaryPoints>(
    document.content.questionaryPoints ?? {
      investitionType: { client: 0, partner: 0 },
      reactionToLoss: { client: 0, partner: 0 },
      financialToolsExperience: { client: 0, partner: 0 },
      knownFinancialTools: { client: 0, partner: 0 },
      total: { client: 0, partner: 0 },
    }
  );

  const calculatePoints = (intention: InvestmentIntention) => {
    const points: {
      client: number;
      partner: number;
      clientCalculationLog: CalculationLogEntry[];
      partnerCalculationLog: CalculationLogEntry[];
    } = {
      client: 0,
      partner: 0,
      clientCalculationLog: [],
      partnerCalculationLog: [],
    };

    // InvestitionType
    if (questionare.investitionType.client) {
      points.client +=
        pointsMap.investitionType[questionare.investitionType.client];

      // add to calculation log
      points.clientCalculationLog.push({
        question: "1) Aký typ investície osobne uprednostňujete?",
        clientAnswer: questionare.investitionType.client,
        clientPoints:
          pointsMap.investitionType[questionare.investitionType.client],
        clientSum: points.client,
        partnerAnswer: undefined,
        partnerPoints: undefined,
        partnerSum: undefined,
      });
    }

    if (questionare.investitionType.partner) {
      points.partner +=
        pointsMap.investitionType[questionare.investitionType.partner];

      // add to calculation log
      points.partnerCalculationLog.push({
        question: "1) Aký typ investície osobne uprednostňujete?",
        clientAnswer: undefined,
        clientPoints: undefined,
        clientSum: undefined,
        partnerAnswer: questionare.investitionType.partner,
        partnerPoints:
          pointsMap.investitionType[questionare.investitionType.partner],
        partnerSum: points.partner,
      });
    }

    // ReactionToLoss
    if (questionare.reactionToLoss.client) {
      points.client +=
        pointsMap.reactionToLoss[questionare.reactionToLoss.client];

      // add to calculation log
      points.clientCalculationLog.push({
        question:
          "2) Ako by ste zareagovali, keby hodnota vašej investície zrazu klesla o 20%?",
        clientAnswer: questionare.reactionToLoss.client,
        clientPoints:
          pointsMap.reactionToLoss[questionare.reactionToLoss.client],
        clientSum: points.client,
        partnerAnswer: undefined,
        partnerPoints: undefined,
        partnerSum: undefined,
      });
    }

    if (questionare.reactionToLoss.partner) {
      points.partner +=
        pointsMap.reactionToLoss[questionare.reactionToLoss.partner];

      // add to calculation log
      points.partnerCalculationLog.push({
        question:
          "2) Ako by ste zareagovali, keby hodnota vašej investície zrazu klesla o 20%?",
        clientAnswer: undefined,
        clientPoints: undefined,
        clientSum: undefined,
        partnerAnswer: questionare.reactionToLoss.partner,
        partnerPoints:
          pointsMap.reactionToLoss[questionare.reactionToLoss.partner],
        partnerSum: points.partner,
      });
    }

    // KnownFinancialTools
    if (questionare.knownFinancialTools.client) {
      const count = questionare.knownFinancialTools.client.length;
      const pointsCount = pointsMap.knownFinancialTools.find(
        (item) => item.count === count
      );
      if (pointsCount) {
        points.client += pointsCount.points;

        // add to calculation log
        points.clientCalculationLog.push({
          question: "3) Ktoré finančné nástroje poznáte?",
          clientAnswer: `Zaškrtnuté ${
            questionare.knownFinancialTools.client.length + 1
          }`,
          clientPoints: pointsCount.points,
          clientSum: points.client,
          partnerAnswer: undefined,
          partnerPoints: undefined,
          partnerSum: undefined,
        });
      }
    }

    if (questionare.knownFinancialTools.partner) {
      const count = questionare.knownFinancialTools.partner.length;
      const pointsCount = pointsMap.knownFinancialTools.find(
        (item) => item.count === count
      );
      if (pointsCount) {
        points.partner += pointsCount.points;

        // add to calculation log
        points.partnerCalculationLog.push({
          question: "3) Ktoré finančné nástroje poznáte?",
          clientAnswer: undefined,
          clientPoints: undefined,
          clientSum: undefined,
          partnerAnswer: `Zaškrtnuté ${
            questionare.knownFinancialTools.partner.length + 1
          }`,
          partnerPoints: pointsCount.points,
          partnerSum: points.partner,
        });
      }
    }

    const financialToolsExperienceFields = [
      "bond",
      "stock",
      "mutualFund",
      "etf",
      "derivative",
    ];

    const financialToolsExperienceLabels: {
      [key: string]: string;
    } = {
      bond: "Dlhopisy",
      stock: "Akcie",
      mutualFund: "Podielové fondy",
      etf: "ETF",
      derivative: "Deriváty",
    };

    // FinancialToolsExperience
    financialToolsExperienceFields.forEach((field) => {
      const item = pointsMap.financialToolsExperience[field].find(
        (mp: { answer: YearsPeriod; points: number }) =>
          mp.answer === questionare?.financialToolsExperience?.client?.[field]
      );
      if (item) {
        points.client += item.points;

        // add to calculation log
        points.clientCalculationLog.push({
          question: `4) S ktorými finančnými nástrojmi už máte aj praktické skúsenosti? ${financialToolsExperienceLabels[field]}`,
          clientAnswer: questionare?.financialToolsExperience?.client?.[field],
          clientPoints: item.points,
          clientSum: points.client,
          partnerAnswer: undefined,
          partnerPoints: undefined,
          partnerSum: undefined,
        });
      }
    });

    financialToolsExperienceFields.forEach((field) => {
      const item = pointsMap.financialToolsExperience[field].find(
        (mp: { answer: YearsPeriod; points: number }) =>
          mp.answer === questionare?.financialToolsExperience?.partner?.[field]
      );
      if (item) {
        points.partner += item.points;

        // add to calculation log
        points.partnerCalculationLog.push({
          question: `4) S ktorými finančnými nástrojmi už máte aj praktické skúsenosti? ${financialToolsExperienceLabels[field]}`,
          clientAnswer: undefined,
          clientPoints: undefined,
          clientSum: undefined,
          partnerAnswer:
            questionare?.financialToolsExperience?.partner?.[field],
          partnerPoints: item.points,
          partnerSum: points.partner,
        });
      }
    });

    // investmentTimeHorizon
    if (intention.investmentTimeHorizon) {
      const pointsCount = pointsMap.investmentTimeHorizon.find(
        (item) => item.answer === intention.investmentTimeHorizon
      );

      if (pointsCount) {
        if (
          intention.investmentCustomerType === InvestmentCustomerType.CLIENT
        ) {
          points.client += pointsCount.points;

          // add to calculation log
          points.clientCalculationLog.push({
            question: "(modal) Na akú dlhú dobu chcete investovať?",
            clientAnswer: intention.investmentTimeHorizon,
            clientPoints: pointsCount.points,
            clientSum: points.client,
            partnerAnswer: undefined,
            partnerPoints: undefined,
            partnerSum: undefined,
          });
        }

        if (
          intention.investmentCustomerType === InvestmentCustomerType.PARTNER
        ) {
          points.partner += pointsCount.points;

          // add to calculation log
          points.partnerCalculationLog.push({
            question: "(modal) Na akú dlhú dobu chcete investovať?",
            clientAnswer: undefined,
            clientPoints: undefined,
            clientSum: undefined,
            partnerAnswer: intention.investmentTimeHorizon,
            partnerPoints: pointsCount.points,
            partnerSum: points.partner,
          });
        }
      }
    }

    // investmentIntentionOnce
    if (intention.investmentType === InvestmentType.ONCE) {
      const pointsCount = pointsMap.investmentIntentionsOnce.find(
        (item) => item.answer === intention.investmentOnceAmount
      );
      if (pointsCount) {
        if (
          intention.investmentCustomerType === InvestmentCustomerType.CLIENT
        ) {
          points.client += pointsCount.points;

          // add to calculation log
          points.clientCalculationLog.push({
            question: "(modal) Koľko peňazí chcete investovať jednorázovo?",
            clientAnswer: intention.investmentOnceAmount,
            clientPoints: pointsCount.points,
            clientSum: points.client,
            partnerAnswer: undefined,
            partnerPoints: undefined,
            partnerSum: undefined,
          });
        }

        if (
          intention.investmentCustomerType === InvestmentCustomerType.PARTNER
        ) {
          points.partner += pointsCount.points;

          // add to calculation log
          points.partnerCalculationLog.push({
            question: "(modal) Koľko peňazí chcete investovať jednorázovo?",
            clientAnswer: undefined,
            clientPoints: undefined,
            clientSum: undefined,
            partnerAnswer: intention.investmentOnceAmount,
            partnerPoints: pointsCount.points,
            partnerSum: points.partner,
          });
        }
      }
    }

    // investmentIntentionRepeated
    if (intention.investmentType === InvestmentType.REPEATED) {
      const pointsCount = pointsMap.investmentIntentionsRepeated.find(
        (item) => item.answer === intention.investmentRepeatedAmount
      );
      if (pointsCount) {
        if (
          intention.investmentCustomerType === InvestmentCustomerType.CLIENT
        ) {
          points.client += pointsCount.points;

          // add to calculation log
          points.clientCalculationLog.push({
            question: "(modal) Koľko peňazí chcete investovať mesačne?",
            clientAnswer: intention.investmentRepeatedAmount,
            clientPoints: pointsCount.points,
            clientSum: points.client,
            partnerAnswer: undefined,
            partnerPoints: undefined,
            partnerSum: undefined,
          });
        }

        if (
          intention.investmentCustomerType === InvestmentCustomerType.PARTNER
        ) {
          points.partner += pointsCount.points;

          // add to calculation log
          points.partnerCalculationLog.push({
            question: "(modal) Koľko peňazí chcete investovať mesačne?",
            clientAnswer: undefined,
            clientPoints: undefined,
            clientSum: undefined,
            partnerAnswer: intention.investmentRepeatedAmount,
            partnerPoints: pointsCount.points,
            partnerSum: points.partner,
          });
        }
      }
    }

    return points;
  };

  const getPoints = (intention: InvestmentIntention) => {
    const { client, partner, clientCalculationLog, partnerCalculationLog } =
      calculatePoints(intention);

    if (intention.investmentCustomerType === InvestmentCustomerType.CLIENT) {
      console.table(clientCalculationLog);
      return client;
    }

    if (intention.investmentCustomerType === InvestmentCustomerType.PARTNER) {
      console.table(partnerCalculationLog);
      return partner;
    }

    return 0;
  };

  const getInvestorType = (points: number) => {
    if (points <= 24) {
      return "Konzervatívny investor";
    }

    if (points >= 24.5 && points <= 44) {
      return "Vyvážený investor";
    }

    if (points > 44) {
      return "Dynamický investor";
    }

    return "Chyba výpočtu";
  };

  const handleUpdateQuestionare = (
    name: keyof PointsMapType,
    answers:
      | AnswerClientPartnerType
      | KnownFinancialToolsType
      | FinancialToolsExperienceType
  ) => {
    setQuestionare({ ...questionare, [name]: answers });

    const points = pointsMap[name] as PointsABC;
    let questionPoints: {
      client: number;
      partner: number;
    } = {
      client: 0,
      partner: 0,
    };

    const clientPartnerAnswer = answers as AnswerClientPartnerType;
    if (clientPartnerAnswer) {
      const { client } = clientPartnerAnswer;
      const { partner } = clientPartnerAnswer;

      if (client) {
        questionPoints.client = points[client];
      }

      if (partner) {
        questionPoints.partner = points[partner];
      }
    }

    if (name === "knownFinancialTools") {
      const { client } = answers;
      const { partner } = answers;

      const clientPoints = pointsMap[name].find(
        (i: { count: number; points: number }) => i.count === client?.length
      );

      const partnerPoints = pointsMap[name].find(
        (i: { count: number; points: number }) => i.count === partner?.length
      );
      questionPoints = {
        client: clientPoints?.points ?? 0,
        partner: partnerPoints?.points ?? 0,
      };
    }

    if (name === "financialToolsExperience") {
      const client = answers.client as FinancialToolsFields;
      const partner = answers.partner as FinancialToolsFields;

      const clientAnswersArray = Object.keys(client);
      const partnerAnswersArray = Object.keys(partner);

      let clientTotal = 0;
      let partnerTotal = 0;

      clientAnswersArray.forEach((answer) => {
        const answerPoints = getFinancialToolsExperiencePoints(
          answer,
          client,
          pointsMap[name]
        );
        clientTotal += answerPoints;
      });

      partnerAnswersArray.forEach((answer) => {
        const answerPoints = getFinancialToolsExperiencePoints(
          answer,
          partner,
          pointsMap[name]
        );
        partnerTotal += answerPoints;
      });

      questionPoints = {
        client: clientTotal,
        partner: partnerTotal,
      };
    }

    setQuestionaryPoints({
      ...questionaryPoints,
      [name]: questionPoints,
    });
  };

  const getFinancialToolsExperiencePoints = (
    financeTool: string,
    personAnswers: FinancialToolsFields,
    points: { [key: string]: { answer: string; points: number }[] }
  ): number => {
    return (
      points[financeTool].find(
        (i: { answer: string; points: number }) =>
          i.answer === personAnswers[financeTool]
      )?.points ?? 0
    );
  };

  const handleSaveInvestmentIntentionModal = (value: InvestmentIntention) => {
    const updatedValue = { ...value };
    const points = getPoints(value);
    updatedValue.points = points.toString();
    updatedValue.investorType = getInvestorType(points);
    let warningMessage;

    // try to find in intentions once
    let index = questionare.investmentIntentionsOnce.findIndex(
      (item) => item.frontendID === updatedValue.frontendID
    );

    // if not found try to find in intentions repeated
    if (index === -1) {
      index = questionare.investmentIntentionsRepeated.findIndex(
        (item) => item.frontendID === updatedValue.frontendID
      );
    }

    // check that intention type is not changed, if changed remove from old array and set index to -1 for adding to new array
    if (index !== -1) {
      const intentionOnce = questionare.investmentIntentionsOnce[index];
      if (
        intentionOnce &&
        intentionOnce.investmentType !== updatedValue.investmentType
      ) {
        index = -1;
        if (intentionOnce.investmentType === InvestmentType.ONCE) {
          const intentions = questionare.investmentIntentionsOnce;
          intentions.splice(index, 1);
          setQuestionare({
            ...questionare,
            investmentIntentionsOnce: intentions,
          });
        }
      }

      const intentionRepeated = questionare.investmentIntentionsRepeated[index];
      if (
        intentionRepeated &&
        intentionRepeated.investmentType !== updatedValue.investmentType
      ) {
        if (intentionRepeated.investmentType === InvestmentType.REPEATED) {
          index = -1;
          const intentions = questionare.investmentIntentionsRepeated;
          intentions.splice(index, 1);
          setQuestionare({
            ...questionare,
            investmentIntentionsRepeated: intentions,
          });
        }
      }
    }

    updatedValue.frontendID = new Date().getTime().toString();

    if (updatedValue.investmentType === InvestmentType.ONCE) {
      // edit
      if (index !== -1) {
        const investments = [...questionare.investmentIntentionsOnce];
        investments[index] = updatedValue as InvestmentIntentionOnce;
        setQuestionare({
          ...questionare,
          investmentIntentionsOnce: investments,
        });
      }
      // add
      else if (questionare.investmentIntentionsOnce.length < 4) {
        setQuestionare({
          ...questionare,
          investmentIntentionsOnce: [
            ...questionare.investmentIntentionsOnce,
            updatedValue as InvestmentIntentionOnce,
          ],
        });
      } else {
        warningMessage =
          "Maximálny počet jednorázových investičných návrhov je 4";
      }
    }

    if (updatedValue.investmentType === InvestmentType.REPEATED) {
      // edit
      if (index !== -1) {
        const investments = [...questionare.investmentIntentionsRepeated];
        investments[index] = updatedValue as InvestmentIntentionRepeated;
        setQuestionare({
          ...questionare,
          investmentIntentionsRepeated: investments,
        });
      }
      // add
      else if (questionare.investmentIntentionsRepeated.length < 4) {
        setQuestionare({
          ...questionare,
          investmentIntentionsRepeated: [
            ...questionare.investmentIntentionsRepeated,
            updatedValue as InvestmentIntentionRepeated,
          ],
        });
      } else {
        warningMessage =
          "Maximálny počet opakovaných investičných návrhov je 4";
      }
    }

    if (warningMessage) {
      toast.warning(warningMessage);
    }

    setIntentionDefault(undefined);
  };

  const removeIntention = (intention: InvestmentIntention) => {
    let index = questionare.investmentIntentionsOnce.findIndex(
      (item) => item.frontendID === intention.frontendID
    );

    if (index === -1) {
      index = questionare.investmentIntentionsRepeated.findIndex(
        (item) => item.frontendID === intention.frontendID
      );
    }

    if (intention.investmentType === InvestmentType.ONCE) {
      setQuestionare({
        ...questionare,
        investmentIntentionsOnce: [
          ...questionare.investmentIntentionsOnce.slice(0, index),
          ...questionare.investmentIntentionsOnce.slice(index + 1),
        ],
      });
    }

    if (intention.investmentType === InvestmentType.REPEATED) {
      setQuestionare({
        ...questionare,
        investmentIntentionsRepeated: [
          ...questionare.investmentIntentionsRepeated.slice(0, index),
          ...questionare.investmentIntentionsRepeated.slice(index + 1),
        ],
      });
    }
  };

  useEffect(() => {
    if (Object.keys(removedPartnerQuestionare).length > 0) {
      setQuestionare(removedPartnerQuestionare);
      setRemovedPartnerQuestionare({} as Questionare);
    }
  }, [removedPartnerQuestionare]);

  useEffect(() => {
    const investmentIntensionsOncePoints: number[] = [];
    const investmentRepeatedPoints: number[] = [];
    questionare?.investmentIntentionsOnce?.forEach((element) => {
      const points = getPoints(element);
      investmentIntensionsOncePoints.push(points);
    });

    questionare?.investmentIntentionsRepeated?.forEach((element) => {
      const points = getPoints(element);
      investmentRepeatedPoints.push(points);
    });

    handleQuestionaire(
      document,
      setDocument,
      questionare,
      questionaryPoints,
      investmentIntensionsOncePoints,
      investmentRepeatedPoints
    );
  }, [questionare]);

  return (
    <>
      <Card className="mb-8 px-4">
        <div className="py-3 mb-6">
          <div className="w-full px-3 py-2 mb-4 font-bold bg-slate-200 ">
            Investičný dotazník
          </div>
          <h3 className="text-base font-bold my-2">Rizikový profil</h3>
          <QuestionABC
            name="investitionType"
            question="1) Aký typ investície osobne uprednostňujete?"
            answerA={
              <>
                <strong>Konzervatívnu</strong> - som ochotný strpieť iba malé
                výkyvy v hodnote svojej investície, pretože sa riadim heslom, že
                lepšie je menej získať, ako veľa stratiť.
              </>
            }
            answerB={
              <>
                <strong>Vyváženú</strong> – z času na čas som síce ochotný
                strpieť aj nejaké výkyvy v hodnote svojej investície, očakávam
                však, že nakoniec dosiahnem oveľa vyšší výnos ako keby som bol
                konzervatívny.
              </>
            }
            answerC={
              <>
                <strong>Dynamickú</strong> – som ochotný podstúpiť aj výrazné a
                dlhšie trvajúce výkyvy v hodnote svojej investície, aby som
                nakoniec dosiahol maximálny možný výnos.
              </>
            }
            answers={
              questionare.investitionType ?? {
                client: "",
                partner: "",
              }
            }
            onChange={(answers) => {
              handleUpdateQuestionare("investitionType", answers);
              ctx.resetFieldsErrors([
                "clientInvestitionType",
                "partnerInvestitionType",
              ]);
            }}
            errors={{
              client: ctx.getFieldError("clientInvestitionType")?.message,
              partner: ctx.getFieldError("partnerInvestitionType")?.message,
            }}
          />
          <QuestionABC
            name="reactionToLoss"
            question="2) Ako by ste zareagovali, keby hodnota vašej investície zrazu klesla o 20%?"
            answerA="To si ani neviem predstaviť a aspoň jej časť by som určite radšej ihneď predal."
            answerB="Nerobil by som vôbec nič, pretože krátkodobé výkyvy ma nezaujímajú a hlavne chcem dodržať celý časový horizont investície."
            answerC="Investoval by som znova, pretože to chápem ako perfektnú príležitosť a šancu, kedy znova lacno dokúpiť."
            answers={questionare.reactionToLoss}
            onChange={(answers) => {
              handleUpdateQuestionare("reactionToLoss", answers);
              ctx.resetFieldsErrors([
                "clientReactionToLoss",
                "partnerReactionToLoss",
              ]);
            }}
            errors={{
              client: ctx.getFieldError("clientReactionToLoss")?.message,
              partner: ctx.getFieldError("partnerReactionToLoss")?.message,
            }}
          />
          <KnownFinancialTools
            name="knownFinancialTools"
            question="3) Ktoré finančné nástroje poznáte?"
            answers={questionare.knownFinancialTools}
            onChange={(answers) => {
              handleUpdateQuestionare("knownFinancialTools", answers);
              ctx.resetFieldsErrors([
                "clientKnownFinancialTools",
                "partnerKnownFinancialTools",
              ]);
            }}
          />
          <FinancialToolsExperience
            name="financialToolsExperience"
            question="4) S ktorými finančnými nástrojmi už máte aj praktické skúsenosti?"
            answers={questionare.financialToolsExperience}
            onChange={(answers) => {
              handleUpdateQuestionare("financialToolsExperience", answers);
              ctx.resetFieldsErrors([
                "clientFinancialToolsExperience",
                "partnerFinancialToolsExperience",
              ]);
            }}
            errors={{
              client: ctx.getFieldError("clientFinancialToolsExperience")
                ?.message,
              partner: ctx.getFieldError("partnerFinancialToolsExperience")
                ?.message,
            }}
          />
          <h3 className="text-lg font-bold my-2 mt-5 mb-5">
            Preferencie ohľadne ESG fondov
          </h3>
          <InterestInEsg
            question="1. Aké sú Vaše preferencie ohľadom ESG fondov?"
            name="esgInterest"
            answers={questionare.interestInEsg}
            onChange={(value: EsgInterestType) => {
              setQuestionare({
                ...questionare,
                interestInEsg: value,
              });
              ctx.resetFieldsErrors([
                "clientEsgInterest",
                "partnerEsgInterest",
              ]);
            }}
            errors={{
              client: ctx.getFieldError("clientEsgInterest")?.message,
              partner: ctx.getFieldError("partnerEsgInterest")?.message,
            }}
          />
          {questionare.interestInEsg?.client?.includes(EsgInterest.INTEREST) ||
          questionare.interestInEsg?.partner?.includes(EsgInterest.INTEREST) ? (
            <>
              <EsgPreference
                question="2. Aké sú Vaše preferencie ohľadom investícií do ESG fondov?"
                name="esgPreference"
                answers={questionare.esgPreferences}
                onChange={(value: EsgPreferences) =>
                  setQuestionare({
                    ...questionare,
                    esgPreferences: value,
                  })
                }
              />
              <SustainabiltyRequestSelect
                question="3. Požadujem, aby moje preferencie udržateľnosti boli splnené"
                name="sustainabilityRequest"
                answers={questionare.sustainabilityRequest}
                onChange={(value: SustainabiltyRequestType) => {
                  setQuestionare({
                    ...questionare,
                    sustainabilityRequest: value,
                  });
                  ctx?.resetFieldsErrors([
                    "clientSustainabilityRequest",
                    "partnerSustainabilityRequest",
                  ]);
                }}
                errors={{
                  client: ctx.getFieldError("clientSustainabilityRequest")
                    ?.message,

                  partner: ctx.getFieldError("partnerSustainabilityRequest")
                    ?.message,
                }}
              />
            </>
          ) : null}
          <h3 className="text-lg font-bold my-2 mt-5 mb-5">Investičný zámer</h3>
          {/* investicny zamer start */}
          <div>
            <Button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={() => {
                setIsAddInvestmentIntentionModalOpen(true);
              }}
            >
              Pridať investičný zámer
            </Button>
            {questionare.investmentIntentionsOnce.length > 0 ||
            questionare.investmentIntentionsRepeated.length > 0 ? (
              <table>
                <thead>
                  <tr>
                    <th className="w-2/5">&nbsp;</th>
                    <th className="w-1/5">Klient / Partner</th>
                    <th className="w-1/5">Typ investície</th>
                    <th className="w-1/5">Stratégia</th>
                    <th className="w-1/5">Akcie</th>
                  </tr>
                </thead>
                <tbody>
                  {questionare.investmentIntentionsOnce.map((item, index) => {
                    return (
                      <tr key={item.frontendID}>
                        <td>Investícia {index + 1}</td>
                        <td className="!text-center">
                          {translateInvestmentCustomerType(
                            item.investmentCustomerType
                          )}
                        </td>
                        <td className="!text-center">
                          {translateInvestmentType(item.investmentType)}
                        </td>
                        <td className="!text-center">
                          {getInvestorType(getPoints(item))}
                        </td>
                        <td className="!text-center flex gap-2">
                          <FontAwesomeIcon
                            icon={faPen}
                            onClick={() => {
                              setIntentionDefault(item);
                              setIsAddInvestmentIntentionModalOpen(true);
                            }}
                            className="cursor-pointer text-spf-primary hover:text-amber-600"
                          />
                          <FontAwesomeIcon
                            icon={faTrash}
                            onClick={() => removeIntention(item)}
                            className="cursor-pointer text-spf-primary hover:text-red-600"
                          />
                        </td>
                      </tr>
                    );
                  })}
                  {questionare.investmentIntentionsRepeated.map(
                    (item, index) => {
                      return (
                        <tr key={item.frontendID}>
                          <td>Investícia {index + 5}</td>
                          <td className="!text-center">
                            {translateInvestmentCustomerType(
                              item.investmentCustomerType
                            )}
                          </td>
                          <td className="!text-center">
                            {translateInvestmentType(item.investmentType)}
                          </td>
                          <td className="!text-center">
                            {getInvestorType(getPoints(item))}
                          </td>
                          <td className="!text-center flex gap-2">
                            <FontAwesomeIcon
                              icon={faPen}
                              onClick={() => {
                                setIntentionDefault(item);
                                setIsAddInvestmentIntentionModalOpen(true);
                              }}
                              className="cursor-pointer text-spf-primary hover:text-amber-600"
                            />
                            <FontAwesomeIcon
                              icon={faTrash}
                              onClick={() => removeIntention(item)}
                              className="cursor-pointer text-spf-primary hover:text-red-600"
                            />
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            ) : null}
          </div>
          <h3 className="text-lg font-bold my-2 mt-5">Zvolené riešenie</h3>
          <InvestmentIntenttionTableOverview
            investmentIntentionOnce={questionare.investmentIntentionsOnce}
            investmenIntentionRepeated={
              questionare.investmentIntentionsRepeated
            }
            errors={getInvestmentIntentionTableErrors(document.content)}
            onChange={(
              investmentIntentionsOnce,
              investmentIntentionsRepeated
            ) => {
              setQuestionare({
                ...questionare,
                investmentIntentionsOnce,
                investmentIntentionsRepeated,
              });
            }}
          />
          <p className="mt-2 italic">
            * V prípade, že sa Klient / Partner rozhodne pre produkt, ktorý
            nezodpovedá Investičnej stratégii, tak je potrebné uviesť v tomto
            stĺpci „NIE“. Klient / Partner týmto berie na vedomie, že bol
            oboznámený s investičnou stratégiou a je si plne vedomý toho, že ním
            zvolené riešenie (produkt) nezodpovedá Investičnej stratégii.
          </p>
          <h3 className="text-lg font-bold my-2 mt-5">
            Vyhlásenie klienta / partnera k investičnému dotazníku
          </h3>
          Vyhlasujem a svojim podpisom potvrdzujem, že na otázky Investičného
          dotazníka som odpovedal úplne a pravdivo.
          <h3 className="text-lg font-bold my-2 mt-5">
            Vyhodnotenie investičnej stratégie
          </h3>
          <table className="md:w-1/2 lg:w-1/4 table-auto border-collapse mt-3">
            <tbody>
              <tr>
                <td className="border border-slate-400">
                  Dosiahnuté&nbsp;body
                </td>
                <td className="border border-slate-400">
                  Investičná&nbsp;stratégia
                </td>
              </tr>
              <tr>
                <td className="border border-slate-400">0 - 24</td>
                <td className="border border-slate-400">
                  Konzervatívna&nbsp;(K)
                </td>
              </tr>
              <tr>
                <td className="border border-slate-400">24,5 - 44</td>
                <td className="border border-slate-400">Vyvážená (V)</td>
              </tr>
              <tr>
                <td className="border border-slate-400">od 44,5</td>
                <td className="border border-slate-400">Dynamická (D)</td>
              </tr>
            </tbody>
          </table>
          <h3 className="text-lg font-bold my-2 mt-5">Typ investora</h3>
          <p>
            <b>0-24 bodov: KONZERVATÍVNY INVESTOR</b>
          </p>
          <p>
            Na základe vyhodnotenia Vašich odpovedí, si dovoľujeme konštatovať,
            že Váš rizikový profil rovnako ako aj Váš investičný zámer,
            považujeme za primeraný jedine v prípade výberu konzervatívnej
            investičnej stratégie. Pokiaľ i napriek tomu budete trvať na svojom
            výbere finančného produktu či nástroja, ktorý nezodpovedá Vášmu
            výsledku (vyvážená a dynamická investičná stratégia), sme v súlade s
            právnymi predpismi oprávnení Vám danú investičnú službu poskytnúť. V
            takom prípade Vám ale odporúčame dôsledne sa oboznámiť so všetkými
            podstatnými okolnosťami investovania a najmä s tými, ktoré sa týkajú
            potenciálnych rizík, vyplývajúcich z jednotlivým finančným produktov
            či nástrojov, ktoré chcete využiť. Toto upozornenie nepovažujte za
            zhodnotenie vhodnosti Vašej konkrétnej investície.
          </p>
          <p>
            <b>24,5-44 bodov: VYVÁŽENÝ INVESTOR</b>
          </p>
          <p>
            Na základe vyhodnotenia Vašich odpovedí, si dovoľujeme konštatovať,
            že Váš rizikový profil rovnako ako aj Váš investičný zámer,
            považujeme za primeraný iba v prípade výberu konzervatívnej a
            vyváženej investičnej stratégie. Pokiaľ i napriek tomu budete trvať
            na svojom výbere finančného produktu či nástroja, ktorý nezodpovedá
            Vášmu výsledku (dynamická investičná stratégia), sme v súlade s
            právnymi predpismi oprávnení Vám danú investičnú službu poskytnúť. V
            takom prípade Vám ale odporúčame dôsledne sa oboznámiť so všetkými
            podstatnými okolnosťami investovania a najmä s tými, ktoré sa týkajú
            potenciálnych rizík, vyplývajúcich z jednotlivým finančným produktov
            či nástrojov, ktoré chcete využiť. Toto upozornenie nepovažujte za
            zhodnotenie vhodnosti Vašej konkrétnej investície.
          </p>
          <p>
            <b>44,5 a viac bodov: DYNAMICKÝ INVESTOR</b>
          </p>
          <p>
            Na základe vyhodnotenia Vašich odpovedí, si dovoľujeme konštatovať,
            že Váš rizikový profil rovnako ako aj Váš investičný zámer,
            považujeme za primeraný v prípade výberu konzervatívnej, vyváženej a
            dokonca aj dynamickej investičnej stratégie. Aj napriek vyššie
            uvedeným skutočnostiam venujte vždy pozornosť popisu rizík
            prislúchajúcich k jednotlivým finančným produktom či nástrojom,
            ktoré chcete využiť. Toto upozornenie nepovažujte za zhodnotenie
            vhodnosti Vašej konkrétnej investície.
          </p>
        </div>
        {/* ) : (
        ""
      )} */}
      </Card>
      <AddInvestmentIntentionModal
        isOpen={isAddInvestmentIntentionModalOpen}
        onClose={() => {
          setIsAddInvestmentIntentionModalOpen(false);
          setIntentionDefault(undefined);
        }}
        onSave={(value: InvestmentIntention) =>
          handleSaveInvestmentIntentionModal(value)
        }
        intentionDefault={intentionDefault}
      />
    </>
  );
}

export default Page04Card;
