import { useState } from "react";

import { t } from "i18next";
import { useNavigate } from "react-router";

import { FilterOptionsKey } from "../api/customers.generated";
import { USER_OWNED } from "../enums/FilterLevelKey";
import AllRoutes from "../features/route/Route";
import useLogger from "./logger";
import { SortablePaginationQuery, useQuery } from "./query";
import { makeRoute } from "./route";

export interface CustomerQuery extends SortablePaginationQuery {
  keyword: string;
  level: FilterOptionsKey;
}

export type CustomerQueryDispatcher = (query: CustomerQuery) => void;

type CustomerQueryHook = [CustomerQuery, (query: CustomerQuery) => void];

const localStorageSizeKey = "customers.page.size";

function getCustomersPageSizeFromStorage(): number {
  return Number(localStorage.getItem(localStorageSizeKey)) || 10;
}

function setCustomersPageSizeToStorage(newSize: number): void {
  localStorage.setItem(localStorageSizeKey, newSize.toString());
}

export function validatePhoneNumber(phoneNumber: string) {
  const newPhoneNumber = phoneNumber.replace(" ", "");

  const toRet = {
    message: null,
    field: newPhoneNumber,
  };

  if (phoneNumber === "") {
    return toRet;
  }

  if (!phoneNumber.startsWith("+")) {
    toRet.message = t("app.phoneNumber.withoutPlus");
  }

  return toRet;
}

export function useCustomersQuery(): CustomerQueryHook {
  const navigate = useNavigate();
  const query = useQuery();
  const { logger } = useLogger();
  const [customerQuery, setCustomerQuery] = useState<CustomerQuery>({
    page: query.page ?? 1,
    size: query.size ?? getCustomersPageSizeFromStorage(),
    sort: query.sort ? [query.sort] : ["createdAt:desc"],
    keyword: query.keyword ?? "",
    level: query.level ?? USER_OWNED,
  });

  logger.debug("PARSED DOCUMENT QUERY", customerQuery);

  return [
    customerQuery,
    (updatedCustomerQuery: CustomerQuery) => {
      const queryString = Object.keys(updatedCustomerQuery)
        .map((key) => {
          const parameter = updatedCustomerQuery[key as keyof CustomerQuery];
          if (Array.isArray(parameter)) {
            const val = parameter.join(",");
            return val.length > 0 ? `${key}=${val}` : undefined;
          }

          return (parameter ?? "").toString().length > 0
            ? `${key}=${parameter}`
            : undefined;
        })
        .filter((item) => item !== undefined)
        .join("&");

      logger.debug("QUERY STRING", queryString);

      // Important order: First update the URI/history, than the state because
      // it forces a repaint of the component
      setCustomersPageSizeToStorage(updatedCustomerQuery.size ?? 10);
      navigate(`${makeRoute(AllRoutes.CUSTOMERS_LIST)}?${queryString}`);
      setCustomerQuery(updatedCustomerQuery);
    },
  ];
}

const allHooks = { useCustomersQuery, validatePhoneNumber };

export default allHooks;
