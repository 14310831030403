/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext } from "react";

import {
  faDeleteLeft,
  faEdit,
  faExchangeAlt,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

import { Customer } from "../../../api/customers.generated";
import Button from "../../../components/buttons/Button";
import ClientPartnerCheckbox from "../../../components/inputs/ClientPartnerCheckbox";
import CustomerSelectModal from "../../../components/modals/CustomerSelectModal";
import { GetAdvisorSectorTypeTranslationKey } from "../../../enums/AdvisorSectorTypes";
import ClientPartnerCheckboxOptions from "../../../enums/ClientPartnerCheckboxOptions";
import CustomerSelectType from "../../../enums/CustomerSelectType";
import { clientPersonTypes, clientTypes } from "../../../enums/Document";
import useDocumentGetters from "../../../hooks/documentContent";
import { Document } from "../../../models/Document";
import { DocumentContent, Questionare } from "../../../models/DocumentContent";
import DocumentZuzContext from "../DocumentZuzFormViewContext";
import DocumentInput from "./DocumentInput";
import PageCardHeading from "./PageCardHeading";

interface Properties {
  document: Document;
  setDocument: React.Dispatch<Document>;
  removedPartnerQuestionare: Questionare;
  setRemovedPartnerQuestionare: React.Dispatch<Questionare>;
  handleSaveDocumentAndCreateCustomer: (type: CustomerSelectType) => void;
  handleSaveDocumentAndEditCustomer: (
    row: string,
    type: CustomerSelectType
  ) => void;
}

function Page01Card({
  document,
  setDocument,
  handleSaveDocumentAndCreateCustomer,
  handleSaveDocumentAndEditCustomer,
  removedPartnerQuestionare,
  setRemovedPartnerQuestionare,
}: Properties) {
  const { t } = useTranslation();
  const {
    isChecked,
    handleCheckboxChange,
    handleCustomerSelected,
    handlePartnerSelected,
    handleRemovePartner,
  } = useDocumentGetters();
  const ctx = useContext(DocumentZuzContext);

  const resetClientFieldErrors = () => {
    ctx.resetFieldsErrors([
      "clientName",
      "clientIdNumber",
      "clientBirthDate",
      "clientHomeAddress",
      "clientPostAddress",
      "clientEmail",
      "clientPhone",
      "clientCompany",
      "clientBusinessId",
      "clientBusinessFunction",
      "clientIdDisplayValue",
      "clientIdValidUntil",
      "clientState",
      "clientBusinessAddress",
    ]);
  };

  const resetPartnerFieldErrors = () => {
    ctx.resetFieldsErrors([
      "partnerName",
      "partnerIdNumber",
      "partnerBirthDate",
      "partnerHomeAddress",
      "partnerPostAddress",
      "partnerEmail",
      "partnerPhone",
      "partnerIdDisplayValue",
      "partnerIdValidUntil",
      "partnerState",
    ]);
  };

  const amlFieldsToCheck: Array<keyof DocumentContent> = [
    "clientOnHisOwnName",
    "clientOnHisOwnAccount",
    "clientWithHisOwnMoney",
    "clientContractUnder15000",
    "clientFinalUserBenefits",
    "clientNotPolitic",
  ];

  const showAmlClientWarning = (() => {
    const amlFields = amlFieldsToCheck
      .map((field) => {
        const documentField = document.content[
          field
        ] as ClientPartnerCheckboxOptions;
        return documentField?.client;
      })
      .filter((v) => !v);
    if (amlFields.length === 0) return false;
    return true;
  })();

  const showAmlPartnerWarning = (() => {
    if (document.content.partnerIdNumber === "") return false;
    const amlFields = amlFieldsToCheck
      .map((field) => {
        const documentField = document.content[
          field
        ] as ClientPartnerCheckboxOptions;
        return documentField?.partner;
      })
      .filter((v) => !v);
    if (amlFields.length === 0) return false;
    return true;
  })();

  return (
    <>
      <div className="flex flex-col gap-4">
        <PageCardHeading title="PFA - Údaje o podriadenom finančnom agentovi" />
        <div className="flex flex-col gap-4 mx-4">
          <div className="grid grid-cols-2 gap-4">
            <DocumentInput
              label="Titul, meno a priezvisko"
              name="pfaName"
              document={document}
              setDocument={setDocument}
              disabled
            />
            <DocumentInput
              label="Obchodné meno"
              name="pfaCompany"
              setDocument={setDocument}
              document={document}
              disabled
            />
            <DocumentInput
              label="Trvalý pobyt / Sídlo"
              name="pfaAddress"
              setDocument={setDocument}
              document={document}
              disabled
            />
            <DocumentInput
              label="Miesto podnikania (pokiaľ je odlišné ako Trvalý pobyt / Sídlo)"
              placeholder="Miesto podnikania"
              name="pfaBusinessPlace"
              setDocument={setDocument}
              document={document}
              disabled={false}
            />
          </div>
          <div className="grid grid-cols-3 gap-4">
            <DocumentInput
              label="Reg. číslo v registri NBS"
              name="pfaNBS"
              setDocument={setDocument}
              document={document}
              disabled
            />
            <DocumentInput
              label="Evidenčné číslo SPF"
              name="pfaSPFId"
              setDocument={setDocument}
              document={document}
              disabled
            />
            <DocumentInput
              label="IČO"
              name="pfaICO"
              setDocument={setDocument}
              document={document}
              disabled
            />
          </div>
          <div className="flex flex-col gap-2 mb-4">
            <label
              htmlFor="financeSector"
              className="block text-gray-500 text-sm"
            >
              Finančný sektor
            </label>
            <div className="flex text-sm gap-2 p-4 bg-slate-200 rounded">
              {document.content.pfaSector.map(({ name }) => (
                <div
                  className="flex flex-grow form-check"
                  key={`checkbox-${name}`}
                >
                  <input
                    className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="checkbox"
                    value={name}
                    key={`pfa-input-${name}`}
                    name="pfaSectors"
                    id={`pfaSector-${name}`}
                    onChange={(e) =>
                      handleCheckboxChange(
                        document,
                        setDocument,
                        "pfaSector",
                        name,
                        true
                      )
                    }
                    checked={isChecked(document, "pfaSector", name)}
                    data-testid={`/documents/zuz/card-01/input-pfaSector-${name}`}
                  />
                  <label
                    className="mt-0.5 form-check-label inline-block text-gray-800"
                    htmlFor={`pfaSector-${name}`}
                    key={`label-${name}`}
                  >
                    {t(GetAdvisorSectorTypeTranslationKey(name)) ?? ""}
                  </label>
                </div>
              ))}
              {/*  */}
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-4 mt-8">
        <PageCardHeading title="Klient">
          {document.content.clientID ? (
            <Button
              className="text-sm px-2 py-1 ml-auto bg-slate-700 hover:bg-slate-600 focus:ring-slate-600"
              onClick={() =>
                handleSaveDocumentAndEditCustomer(
                  document.content.clientID,
                  CustomerSelectType.CLIENT
                )
              }
              icon={faEdit}
              dataTestId="/documents/zuz/card-01/btn-client-edit"
            >
              Upraviť
            </Button>
          ) : (
            ""
          )}
          {/* Select customer no.1 */}
          <CustomerSelectModal
            createEditButtons
            selectedCustomerID={document.content.clientID}
            callback={(row: Customer) => {
              resetClientFieldErrors();
              handleCustomerSelected(document, setDocument, row);
            }}
            callbackCustomerCreate={(type: CustomerSelectType) =>
              handleSaveDocumentAndCreateCustomer(CustomerSelectType.CLIENT)
            }
            callbackCustomerEdit={(row: Customer) =>
              handleSaveDocumentAndEditCustomer(
                row.id || "",
                CustomerSelectType.CLIENT
              )
            }
            btnIcon={document.content.clientID ? faExchangeAlt : faPlus}
            btnLabel={
              document.content.clientID ? t("button.change") : t("button.add")
            }
            modalTitle={t("app.customer-select-modal.title")}
            customerSelectType={CustomerSelectType.CLIENT}
          />
        </PageCardHeading>

        <div className="grid grid-cols-2 gap-4 mx-4">
          <div className="flex flex-col gap-4">
            <div className="flex justify-center w-full gap-2 p-4 bg-slate-200 rounded">
              {clientTypes.map(({ name, label }) => (
                <div className="form-check flex-grow" key={`checkbox-${name}`}>
                  <input
                    className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="checkbox"
                    value={name}
                    key={`client-type-input-${name}`}
                    name="clientType"
                    id={`clientType-${name}`}
                    onChange={(e) => {
                      handleCheckboxChange(
                        document,
                        setDocument,
                        "clientType",
                        name,
                        false
                      );
                    }}
                    // defaultChecked={isChecked("clientType", name)}
                    checked={isChecked(document, "clientType", name)}
                    data-testid={`/documents/zuz/card-01/input-clientType-${name}`}
                  />
                  <label
                    className="form-check-label inline-block text-gray-800"
                    htmlFor={`clientType-${name}`}
                    key={`label-${name}`}
                  >
                    {label}
                  </label>
                </div>
              ))}
            </div>
            <div className="grid grid-cols-6 gap-4">
              <DocumentInput
                label="Titul, meno a priezvisko"
                name="clientName"
                setDocument={setDocument}
                document={document}
                className="col-span-6"
                disabled
                error={ctx.getFieldError("clientName")?.message}
              />
              <DocumentInput
                label="Rodné číslo"
                name="clientIdNumber"
                setDocument={setDocument}
                document={document}
                className="col-span-3"
                disabled
                error={ctx.getFieldError("clientIdNumber")?.message}
              />
              <DocumentInput
                label="Dátum narodenia"
                name="clientBirthdate"
                setDocument={setDocument}
                document={document}
                className="col-span-3"
                disabled
                error={ctx.getFieldError("clientBirthdate")?.message}
              />
              <DocumentInput
                label="Adresa trvalého bydliska"
                name="clientHomeAddress"
                setDocument={setDocument}
                document={document}
                className="col-span-6"
                disabled
                error={ctx.getFieldError("clientHomeAddress")?.message}
              />
              <DocumentInput
                label="Korešpondenčná adresa"
                name="clientPostAddress"
                setDocument={setDocument}
                document={document}
                className="col-span-6"
                disabled
                error={ctx.getFieldError("clientPostAddress")?.message}
              />
              <DocumentInput
                label="E-mail"
                name="clientEmail"
                setDocument={setDocument}
                document={document}
                className="col-span-4"
                disabled
                error={ctx.getFieldError("clientEmail")?.message}
              />
              <DocumentInput
                label="Telefón"
                name="clientPhone"
                setDocument={setDocument}
                document={document}
                className="col-span-2"
                disabled
                error={ctx.getFieldError("clientPhone")?.message}
              />
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex justify-center w-full gap-2 p-4 bg-slate-200 rounded">
              {clientPersonTypes.map(({ name, label }) => (
                <div className="form-check flex-grow" key={`checkbox-${name}`}>
                  <input
                    className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="checkbox"
                    value={name}
                    disabled
                    key={`client-person-type-input-${name}`}
                    name="clientPersonType"
                    id={`clientPersonType-${name}`}
                    onChange={(e) =>
                      handleCheckboxChange(
                        document,
                        setDocument,
                        "clientPersonType",
                        name,
                        false
                      )
                    }
                    // defaultChecked={isChecked("clientType", name)}
                    checked={isChecked(document, "clientPersonType", name)}
                    data-testid={`/documents/zuz/card-01/input-clientPersonType-${name}`}
                  />
                  <label
                    className="form-check-label inline-block text-gray-800"
                    htmlFor={`clientPersonType-${name}`}
                    key={`label-${name}`}
                  >
                    {label}
                  </label>
                </div>
              ))}
            </div>
            <div className="grid grid-cols-8 gap-4">
              {/* flex */}
              {isChecked(document, "clientPersonType", "ct-po") ||
              isChecked(document, "clientPersonType", "ct-fop") ? (
                <>
                  <DocumentInput
                    label="Obchodné meno"
                    name="clientCompany"
                    setDocument={setDocument}
                    document={document}
                    className="col-span-4"
                    disabled
                    error={ctx.getFieldError("clientCompany")?.message}
                  />
                  <DocumentInput
                    label="Sídlo / Miesto podnikania"
                    name="clientBusinessAddress"
                    setDocument={setDocument}
                    document={document}
                    className="col-span-4"
                    disabled
                    error={ctx.getFieldError("clientBusinessAddress")?.message}
                  />
                  <DocumentInput
                    label="IČO"
                    name="clientBusinessId"
                    setDocument={setDocument}
                    document={document}
                    className="col-span-4"
                    disabled
                    error={ctx.getFieldError("clientBusinessId")?.message}
                  />
                  <DocumentInput
                    label="Funkcia osoby oprávnenej konať"
                    name="clientBusinessFunction"
                    setDocument={setDocument}
                    document={document}
                    className="col-span-4"
                    disabled
                    error={ctx.getFieldError("clientBusinessFunction")?.message}
                  />
                </>
              ) : (
                ""
              )}
              <DocumentInput
                label="Druh a číslo dokladu totožnosti"
                name="clientIdDisplayValue"
                setDocument={setDocument}
                document={document}
                className="col-span-4"
                disabled
                error={ctx.getFieldError("clientIdDisplayValue")?.message}
              />
              <DocumentInput
                label="Platnosť do"
                name="clientIdValidUntil"
                setDocument={setDocument}
                document={document}
                className="col-span-2"
                disabled
                error={ctx.getFieldError("clientIdValidUntil")?.message}
              />
              <DocumentInput
                label="Št. príslušnosť"
                name="clientState"
                setDocument={setDocument}
                document={document}
                className="col-span-2"
                disabled
                error={ctx.getFieldError("clientState")?.message}
              />
              <DocumentInput
                label="Povolanie"
                name="clientOccupation"
                setDocument={setDocument}
                document={document}
                className="col-span-8"
                disabled
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-4 mt-8">
        <PageCardHeading title="Partner">
          {document.content.partnerID ? (
            <>
              <Button
                className="text-xs px-4 py-1 inline-block bg-slate-700 hover:bg-slate-600 focus:ring-slate-600"
                icon={faDeleteLeft}
                onClick={() =>
                  handleRemovePartner(
                    document,
                    setDocument,
                    removedPartnerQuestionare,
                    setRemovedPartnerQuestionare
                  )
                }
              >
                Odobrať
              </Button>
              <Button
                className="text-xs px-4 py-1 inline-block bg-slate-700 hover:bg-slate-600 focus:ring-slate-600"
                icon={faEdit}
                onClick={() =>
                  handleSaveDocumentAndEditCustomer(
                    document.content.partnerID,
                    CustomerSelectType.PARTNER
                  )
                }
              >
                Upraviť
              </Button>
            </>
          ) : (
            ""
          )}
          {/* Select customer no.2 = Partner */}
          <CustomerSelectModal
            createEditButtons
            selectedCustomerID={document.content.partnerID}
            callback={(row: Customer) => {
              resetPartnerFieldErrors();
              handlePartnerSelected(document, setDocument, row);
            }}
            btnIcon={document.content.partnerID ? faExchangeAlt : faPlus}
            btnLabel={
              document.content.partnerID ? t("button.change") : t("button.add")
            }
            modalTitle={t("app.customer-select-modal.partner-title")}
            callbackCustomerCreate={(type: CustomerSelectType) =>
              handleSaveDocumentAndCreateCustomer(CustomerSelectType.PARTNER)
            }
            callbackCustomerEdit={(row: Customer) =>
              handleSaveDocumentAndEditCustomer(
                row.id || "",
                CustomerSelectType.PARTNER
              )
            }
            customerSelectType={CustomerSelectType.PARTNER}
          />
        </PageCardHeading>
        <div className="flex flex-col gap-4 mx-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="flex flex-col gap-4">
              <div className="flex justify-center w-full gap-2 p-4 bg-slate-200 rounded">
                {clientTypes.map(({ name, label }) => (
                  <div
                    className="form-check flex-grow"
                    key={`checkbox-${name}`}
                  >
                    <input
                      className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="checkbox"
                      value={name}
                      key={`partner-type-input-${name}`}
                      name="partnerType"
                      id={`partnerType-${name}`}
                      onChange={(e) =>
                        handleCheckboxChange(
                          document,
                          setDocument,
                          "partnerType",
                          name,
                          false
                        )
                      }
                      // defaultChecked={isChecked("clientType", name)}
                      checked={isChecked(document, "partnerType", name)}
                      data-testid={`/documents/zuz/card-01/input-partnerType-${name}`}
                    />
                    <label
                      className="form-check-label inline-block text-gray-800"
                      htmlFor={`partnerType-${name}`}
                      key={`partnerType-${name}`}
                    >
                      {label}
                    </label>
                  </div>
                ))}
              </div>
              <div className="grid grid-cols-2 gap-4">
                <DocumentInput
                  label="Titul, meno a priezvisko"
                  name="partnerName"
                  setDocument={setDocument}
                  document={document}
                  disabled
                  error={ctx.getFieldError("partnerName")?.message}
                />
                <DocumentInput
                  label="Adresa trvalého bydliska"
                  name="partnerHomeAddress"
                  setDocument={setDocument}
                  document={document}
                  disabled
                  error={ctx.getFieldError("partnerHomeAddress")?.message}
                />
                <DocumentInput
                  label="Rodné číslo"
                  name="partnerIdNumber"
                  setDocument={setDocument}
                  document={document}
                  disabled
                  error={ctx.getFieldError("partnerIdNumber")?.message}
                />
                <DocumentInput
                  label="Dátum narodenia"
                  name="partnerBirthdate"
                  setDocument={setDocument}
                  document={document}
                  disabled
                  error={ctx.getFieldError("partnerBirthdate")?.message}
                />
                <DocumentInput
                  label="Korešpondenčná adresa"
                  name="partnerPostAddress"
                  setDocument={setDocument}
                  document={document}
                  disabled
                />
                <DocumentInput
                  label="E-mail"
                  name="partnerEmail"
                  setDocument={setDocument}
                  document={document}
                  disabled
                  error={ctx.getFieldError("partnerEmail")?.message}
                />
                <DocumentInput
                  label="Telefón"
                  name="partnerPhone"
                  setDocument={setDocument}
                  document={document}
                  disabled
                  error={ctx.getFieldError("partnerPhone")?.message}
                />
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex justify-center w-full gap-2 p-4 border border-white rounded">
                &nbsp;
              </div>
              <div className="grid grid-cols-2 gap-4">
                <DocumentInput
                  label="Druh a číslo dokladu totožnosti"
                  name="partnerIdDisplayValue"
                  setDocument={setDocument}
                  document={document}
                  disabled
                  error={ctx.getFieldError("partnerIdDisplayValue")?.message}
                />
                <DocumentInput
                  label="Platnosť do"
                  name="partnerIdValidUntil"
                  setDocument={setDocument}
                  document={document}
                  disabled
                  error={ctx.getFieldError("partnerIdValidUntil")?.message}
                />
                <DocumentInput
                  label="Št. príslušnosť"
                  name="partnerState"
                  setDocument={setDocument}
                  document={document}
                  disabled
                  error={ctx.getFieldError("partnerState")?.message}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-4 my-8">
        <PageCardHeading title="Vyhlásenie klienta / partnera" />
        {/* tu budu vyhlasenia klienta/partnera */}
        <div className="px-4 mb-4">
          <h3 className="font-bold pt-2">Všeobecné</h3>
          <p className="py-3">
            Ako Klient/Partner týmto potvrdzujem, že žiadam a súhlasím s
            poskytnutím tohto Záznamu, prípadne súvisiacej zmluvnej dokumentácie
            a ostatných informácii elektronicky na uvedenú e-mailovú adresu,
            pričom výslovne súhlasím s tým, že mi budú všetky dokumenty
            poskytnuté v elektronickej verzii na uvedenú e-mailovú adresu. Týmto
            zároveň písomne potvrdzujem, že vyššie uvedený e-mail pravidelne
            využívam, a taktiež potvrdzujem, že uvádzaný e-mail a aj telefónne
            číslo sú moje, sú aktuálne a pravdivé. Súčasne:
          </p>
          <ClientPartnerCheckbox
            document={document}
            setDocument={setDocument}
            name="clientPFAApproved"
            text="vyhlasujem, že beriem na vedomie, že PFA je v súlade s ustanovením § 31 zákona č. 186/2009 Z.z. oprávnený získavať osobné údaje a iné informácie od klienta a zástupcu klienta"
            client={{
              disabled: true,
            }}
            partner={{
              disabled: true,
            }}
          />
          <ClientPartnerCheckbox
            document={document}
            setDocument={setDocument}
            name="clientInformationAcknowledge"
            text="vyhlasujem, že všetky informácie potrebné na to, aby som mohol prijať rozhodnutie týkajúce sa finančnej služby, som dostal v dostatočnom časovom predstihu pred uzavretím zmluvy o poskytnutí finančnej služby"
            client={{
              disabled: true,
            }}
            partner={{
              disabled: true,
            }}
          />
          <ClientPartnerCheckbox
            document={document}
            setDocument={setDocument}
            name="clientPFANotAffected"
            text="vyhlasujem, že pri vypĺňaní tohto Záznamu som nebol PFA nijakým spôsobom ovplyvňovaný, a že mi boli z jeho strany poskytnuté jednoznačné, vyčerpávajúce, presné a zrozumiteľné informácie o odporúčaných i zvolených finančných službách a o existencii a povahe odmeny pre PFA/SPF v zmysle §32 zákona č.186/2009Z.z."
            client={{
              disabled: true,
            }}
            partner={{
              disabled: true,
            }}
          />
          <ClientPartnerCheckbox
            document={document}
            setDocument={setDocument}
            name="clientRiskAcknowledge"
            text="vyhlasujem, že som bol oboznámený s rizikami spojenými s odporúčanými i zvolenými finančnými službami, porozumel som im a som schopný tieto riziká zvládnuť"
            client={{
              disabled: true,
            }}
            partner={{
              disabled: true,
            }}
          />
          <ClientPartnerCheckbox
            document={document}
            setDocument={setDocument}
            name="clientDataApproved"
            text="vyhlasujem, že všetky mnou uvedené údaje v tomto Zázname sú pravdivé, úplné, správne a aktuálne"
            client={{
              disabled: true,
            }}
            partner={{
              disabled: true,
            }}
          />
          {/* par. 297 */}
          <div className="bg-slate-200 border border-slate-300 px-4 py-4 mt-8 rounded">
            <h4 className="font-semibold mb-2">
              Vyhlasujem v zmysle zákona č. 297/2008 Z. z., že:
            </h4>
            <ClientPartnerCheckbox
              document={document}
              setDocument={setDocument}
              name="clientOnHisOwnName"
              text="tento obchod vykonávam vo vlastnom mene"
            />
            <ClientPartnerCheckbox
              document={document}
              setDocument={setDocument}
              name="clientOnHisOwnAccount"
              text="tento obchod vykonávam na vlastný účet"
            />
            <ClientPartnerCheckbox
              document={document}
              setDocument={setDocument}
              name="clientWithHisOwnMoney"
              text="finančné prostriedky použité pri tomto obchode sú mojím vlastníctvom a pochádzajú z mojich vlastných zdrojov"
            />
            <ClientPartnerCheckbox
              document={document}
              setDocument={setDocument}
              name="clientContractUnder15000"
              text="suma finančných prostriedkov v rámci uzavretia tohto obchodného vzťahu za kalendárny rok nedosiahne alebo nepresiahne sumu 15.000 Eur"
            />
            <ClientPartnerCheckbox
              document={document}
              setDocument={setDocument}
              name="clientFinalUserBenefits"
              text="som konečný užívateľ výhod"
            />
            <ClientPartnerCheckbox
              document={document}
              setDocument={setDocument}
              name="clientNotPolitic"
              text="nie som tzv.„politicky exponovanou osobou“, ani tzv. „sankcionovanou osobou“"
            />
            {showAmlClientWarning ? (
              <p className="text-red-400">
                Je potrebné vykonať zvýšenú starostlivosť Klienta v&nbsp;zmysle
                zákona AML. K&nbsp;produkcii prosím priložte dodatočný AML
                formulár.
              </p>
            ) : null}
            {showAmlPartnerWarning ? (
              <p className="text-red-400">
                Je potrebné vykonať zvýšenú starostlivosť Partnera v&nbsp;zmysle
                zákona AML. K&nbsp;produkcii prosím priložte dodatočný AML
                formulár.
              </p>
            ) : null}
          </div>
        </div>
        <div className="px-4">
          <h3 className="font-bold py-3">Špecifické</h3>
          {/* special */}
          <ClientPartnerCheckbox
            document={document}
            setDocument={setDocument}
            name="clientDisagreeEntryInformation"
            text="vyhlasujem, že som odmietol poskytnúť vstupné informácie podľa § 35 zákona č. 186/2009 Z.z. a trvám na uzavretí zmluvy o poskytnutí finančnej služby. Súčasne beriem na vedomie, že ak som odmietol poskytnúť vstupné informácie, tak nie je možné určiť, či je zvolená finančná služba (bod IX. tohto protokolu) vhodná."
          />
          <ClientPartnerCheckbox
            document={document}
            setDocument={setDocument}
            name="clientDisagreeEntryInformationAndAskForContract"
            text="týmto výslovne vyhlasujem, že odmietam SPF, resp. PFA poskytnúť vyššie uvedené informácie, trvám na uzatvorení príslušnej zmluvy o poskytnutí finančnej služby a som si vedomý(á) všetkých následkov spojených s týmto vyhlásením."
          />
          <ClientPartnerCheckbox
            document={document}
            setDocument={setDocument}
            name="clientAgreedPFANotResponsible"
            text="vyhlasujem, že beriem na vedomie, že ak nesúhlasím s odporúčaním PFA a mám záujem o zvolené finančné služby (bod IX. tohto protokolu), SPF ani PFA nenesie zodpovednosť za neprimeranosť rizík a nevhodnosť zvolenej finančnej služby."
          />
        </div>
      </div>
      {/* zistenie financnej situacie */}
      <div className="flex flex-col flex-wrap gap-4">
        <PageCardHeading title="Zistenie finančnej situácie klienta / partner v zmysle § 35 zákona č. 186/2009 Z.Z.:" />
        {/* tu budu vyhlasenia klienta/partnera */}
        <div className="flex flex-col gap-4 mx-4">
          <div>
            <h3 className="text-base font-bold mb-4">
              Zdroj príjmov Klienta/Partnera pochádza najmä
            </h3>
            <div className="flex flex-col gap-2">
              <ClientPartnerCheckbox
                document={document}
                setDocument={setDocument}
                name="sourceIncomeFromActivity"
                text="z aktívnej činnosti (zamestnanec, podnikanie)"
                client={{
                  hasError: !!ctx.getFieldError("clientIncomeCheckboxes"),
                  onChange: (e) =>
                    ctx.resetFieldError("clientIncomeCheckboxes"),
                }}
                partner={{
                  hasError: !!ctx.getFieldError("partnerIncomeCheckboxes"),
                  onChange: (e) =>
                    ctx.resetFieldError("partnerIncomeCheckboxes"),
                }}
              />
              <ClientPartnerCheckbox
                document={document}
                setDocument={setDocument}
                name="sourceIncomeFromLowReserve"
                text="z finančnej rezervy, ktorej výška je menej ako 6-násobok môjho čistého mesačného príjmu"
                client={{
                  hasError: !!ctx.getFieldError("clientIncomeCheckboxes"),
                  onChange: (e) =>
                    ctx.resetFieldError("clientIncomeCheckboxes"),
                }}
                partner={{
                  hasError: !!ctx.getFieldError("partnerIncomeCheckboxes"),
                  onChange: (e) =>
                    ctx.resetFieldError("partnerIncomeCheckboxes"),
                }}
              />
              <ClientPartnerCheckbox
                document={document}
                setDocument={setDocument}
                name="sourceIncomeFromHighReserve"
                text="z finančnej rezervy, ktorej výška je viac ako 6-násobok môjho čistého mesačného príjmu"
                client={{
                  hasError: !!ctx.getFieldError("clientIncomeCheckboxes"),
                  onChange: (e) =>
                    ctx.resetFieldError("clientIncomeCheckboxes"),
                }}
                partner={{
                  hasError: !!ctx.getFieldError("partnerIncomeCheckboxes"),
                  onChange: (e) =>
                    ctx.resetFieldError("partnerIncomeCheckboxes"),
                }}
              />
              {/* iny zdroj prijmov */}
              <div className="flex gap-4">
                <div className="flex flex-col justify-center">
                  <ClientPartnerCheckbox
                    document={document}
                    setDocument={setDocument}
                    name="sourceIncomeOther"
                    text="Iné"
                    client={{
                      hasError: !!ctx.getFieldError("clientIncomeCheckboxes"),
                      onChange: (e) =>
                        ctx.resetFieldError("clientIncomeCheckboxes"),
                    }}
                    partner={{
                      hasError: !!ctx.getFieldError("partnerIncomeCheckboxes"),
                      onChange: (e) =>
                        ctx.resetFieldError("partnerIncomeCheckboxes"),
                    }}
                  />
                </div>
                <div className="">
                  <DocumentInput
                    document={document}
                    setDocument={setDocument}
                    name="otherSourceIncomeValue"
                    placeholder=""
                    label=""
                    disabled={false}
                    error={ctx.getFieldError("otherSourceIncomeValue")?.message}
                    onChange={(e) =>
                      ctx.resetFieldError("otherSourceIncomeValue")
                    }
                  />
                </div>
              </div>
              {ctx.getFieldError("clientIncomeCheckboxes") ? (
                <p className="text-red-400">
                  {ctx.getFieldError("clientIncomeCheckboxes")?.message}
                </p>
              ) : null}
              {ctx.getFieldError("partnerIncomeCheckboxes") ? (
                <p className="text-red-400">
                  {ctx.getFieldError("partnerIncomeCheckboxes")?.message}
                </p>
              ) : null}
            </div>
          </div>
          {/* iny zdroj prijmov koniec */}
          <div>
            <h3 className="text-base font-bold my-2">
              Aktuálna výška zadĺženosti Klienta / Partner je:
            </h3>
            <div className="inline-grid grid-cols-4 gap-2">
              {/* box start */}
              <div>
                <div className="grid grid-cols-2 gap-2 border border-slate-300">
                  <div className="pl-2 py-2">
                    <DocumentInput
                      label="Klient"
                      name="clientDebtLoansDebt"
                      setDocument={setDocument}
                      document={document}
                      disabled={false}
                      error={ctx.getFieldError("clientDebtLoansDebt")?.message}
                      onChange={(e) =>
                        ctx.resetFieldError("clientDebtLoansDebt")
                      }
                    />
                  </div>
                  <div className="pr-2 py-2">
                    <DocumentInput
                      label="Partner"
                      name="partnerDebtLoansDebt"
                      setDocument={setDocument}
                      document={document}
                      disabled={false}
                      error={ctx.getFieldError("partnerDebtLoansDebt")?.message}
                      onChange={(e) =>
                        ctx.resetFieldError("partnerDebtLoansDebt")
                      }
                    />
                  </div>
                  {/* </div> */}
                  <div className="col-span-2 text-center pb-2">
                    z čerpaných úverov (Eur)
                  </div>
                </div>
              </div>
              {/* box end */}
              {/* box start */}
              <div>
                <div className="grid grid-cols-2 gap-2 border border-slate-300">
                  <div className="pl-2 py-2">
                    <DocumentInput
                      label="Klient"
                      name="clientRepaymentSellDebt"
                      setDocument={setDocument}
                      document={document}
                      disabled={false}
                      error={
                        ctx.getFieldError("clientRepaymentSellDebt")?.message
                      }
                      onChange={(e) =>
                        ctx.resetFieldError("clientRepaymentSellDebt")
                      }
                    />
                  </div>
                  <div className="pr-2 py-2">
                    <DocumentInput
                      label="Partner"
                      name="partnerRepaymentSellDebt"
                      setDocument={setDocument}
                      document={document}
                      disabled={false}
                      error={
                        ctx.getFieldError("partnerRepaymentSellDebt")?.message
                      }
                      onChange={(e) =>
                        ctx.resetFieldError("partnerRepaymentSellDebt")
                      }
                    />
                  </div>
                  <div className="col-span-2 text-center pb-2">
                    splátkových predajov (Eur)
                  </div>
                </div>
              </div>
              {/* box end */}
              {/* box start */}
              <div>
                <div className="grid grid-cols-2 gap-2 border border-slate-300">
                  <div className="pl-2 py-2">
                    <DocumentInput
                      label="Klient"
                      name="clientLeasingDebt"
                      setDocument={setDocument}
                      document={document}
                      disabled={false}
                      error={ctx.getFieldError("clientLeasingDebt")?.message}
                      onChange={(e) => ctx.resetFieldError("clientLeasingDebt")}
                    />
                  </div>
                  <div className="pr-2 py-2">
                    <DocumentInput
                      label="Partner"
                      name="partnerLeasingDebt"
                      setDocument={setDocument}
                      document={document}
                      disabled={false}
                      error={ctx.getFieldError("partnerLeasingDebt")?.message}
                      onChange={(e) =>
                        ctx.resetFieldError("partnerLeasingDebt")
                      }
                    />
                  </div>
                  <div className="col-span-2 text-center pb-2">
                    leasingov (Eur)
                  </div>
                </div>
              </div>
              {/* box end */}
              {/* box start */}
              <div>
                <div className="grid grid-cols-2 gap-2 border border-slate-300">
                  <div className="pl-2 py-2">
                    <DocumentInput
                      label="Klient"
                      name="clientOtherDebt"
                      setDocument={setDocument}
                      document={document}
                      disabled={false}
                      error={ctx.getFieldError("clientOtherDebt")?.message}
                      onChange={(e) => ctx.resetFieldError("clientOtherDebt")}
                    />
                  </div>
                  <div className="pr-2 py-2">
                    <DocumentInput
                      label="Partner"
                      name="partnerOtherDebt"
                      setDocument={setDocument}
                      document={document}
                      disabled={false}
                      error={ctx.getFieldError("partnerOtherDebt")?.message}
                      onChange={(e) => ctx.resetFieldError("partnerOtherDebt")}
                    />
                  </div>
                  <div className="col-span-2 text-center pb-2">iné (Eur)</div>
                </div>
              </div>
              {/* box end */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Page01Card;
