import React, { useEffect, useState } from "react";

import {
  faCheckCircle,
  faFileSignature,
  faPencilAlt,
  faPlus,
  faTrashAlt,
  faExternalLink,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import sk from "date-fns/locale/sk";
import { decode } from "html-entities";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import ReactRouterPrompt from "react-router-prompt";
import { toast } from "react-toastify";

import { openFilePreview } from "../../api/auth";
import {
  Customer,
  useGetCustomerByIdQuery,
  useLazyGetCustomerByIdQuery,
} from "../../api/customers.generated";
import {
  CustomerFileUpdateRequest,
  DocumentUpdateRequest,
  useCreateDocumentMutation,
  useGetDocumentByIdQuery,
  useUpdateDocumentMutation,
} from "../../api/documents.generated";
import { Advisor } from "../../api/me.generated";
import BackButton from "../../components/buttons/BackButton";
import Button from "../../components/buttons/Button";
import CancelButton from "../../components/buttons/CancelButton";
import SaveButton from "../../components/buttons/SaveButton";
import Checkbox from "../../components/inputs/Checkbox";
import FileUploadWithNoteModal from "../../components/inputs/FileUploadWithNoteModal";
import Input from "../../components/inputs/Input";
import Textarea from "../../components/inputs/Textarea";
import CustomerSelectModal from "../../components/modals/CustomerSelectModal";
import FormIsDirtyModal from "../../components/modals/FormIsDirtyModal";
import MentorSelectModal from "../../components/modals/MentorSelectModal";
import ZuzSelectModal from "../../components/modals/ZuzSelectModal";
import PageHeader from "../../components/page/PageHeader";
import CustomerSelectType from "../../enums/CustomerSelectType";
import { CT_FO } from "../../enums/CustomerType";
import { DocumentTypeKeys } from "../../enums/DocumentTypes";
import Roles from "../../enums/Roles";
import { getAdvisorDataFromRole } from "../../hooks/advisor";
import useFormatters from "../../hooks/formatter";
import { getRoleId } from "../../hooks/role";
import { makeRoute, parseUrl } from "../../hooks/route";
import { useAppSelector } from "../../hooks/store";
import { DocumentCheckAttachment } from "../../models/DocumentCheckAttachment";
import { DocumentCheckContent } from "../../models/DocumentCheckContent";
import { DocumentCheckTableContent } from "../../models/DocumentCheckTableContent";
import { DocumentMode } from "../../models/DocumentMode";
import { authSelector } from "../auth/authSlice";
import AllRoutes from "../route/Route";

// type TableContent = { [key: string]: any };

interface AttachmentData {
  file: File | undefined;
  note: string;
  id: string;
  isCcaFile: boolean;
  name: string;
}

interface TableContent {
  bpj: string;
  notes: string;
  customer: Customer;
  fileNotes: string;
  bpjPercenet: string;
  contractId: string;
  attachments: AttachmentData[];
  customerPin: string;
  customerName: string;
  productLabel: string;
  attachmentZUZ: boolean;
  paymentPeriod: string;
  contractNumber: string;
  contactTransfer: boolean;
  attachmentReceipt: boolean;
  sentToInstitution: boolean;
  contractPersonType: boolean;
  financialInstitution: string;
  name?: string;
  file?: File;
}

interface Properties {
  mode: string;
}

function DocumentCheckFormView({ mode }: Properties) {
  const { id } = useParams();
  const { t } = useTranslation();
  registerLocale("sk", sk);

  const location = useLocation();

  // show modal
  const [showModal, setShowModal] = useState<boolean>(false);

  // creation of document
  const [documentCreation, setDocumentCreation] = useState<any>();
  const [blockNavigation, setBlockNavigation] = useState(false);

  // modal window
  const modalRowDataDefault: DocumentCheckTableContent = {
    customer: undefined,
    customerName: "",
    customerPin: "",
    financialInstitution: "",
    productLabel: "",
    contactTransfer: false,
    contractNumber: "",
    paymentPeriod: "",
    bpj: "",
    bpjPercent: "",
    contractPersonType: false,
    attachmentZUZ: false,
    attachmentReceipt: false,
    sentToInstitution: false,
    contractId: "",
    notes: "",
    fileNotes: "",
    attachments: [],
  };

  const [modalRowData, setModalRowData] =
    useState<DocumentCheckTableContent>(modalRowDataDefault);

  // create document
  const [createDocument] = useCreateDocumentMutation();

  // update document
  const [updateDocument] = useUpdateDocumentMutation();

  const { currentData, isLoading } = useGetDocumentByIdQuery(
    {
      "X-Role-Id": getRoleId(),
      id: id ?? "0",
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const parsedQueryParams = parseUrl(location.search);

  const [initialModalRowData] = useState(modalRowData);

  const [editingTableRowId, setEditingTableRowId] = useState<number>(-1);

  const [editInitialized, setEditInitialized] = useState(false);

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const [content, setContent] = useState<DocumentCheckContent>({
    inventoryDate: new Date(),
    implementor: null, // Realizator je advisor, ale vzdy musi mat licenciu
    administrator: null, // Spravca je advisor, ktory vytvara
    tableData: [],
    bpjTotal: 0,
    documentNotes: "",
  });

  const [initializedContent, setInitializedContent] =
    useState<DocumentCheckContent>(content);

  const { formatPriceWithoutCurrency } = useFormatters();

  const [isCreating, setIsCreating] = useState<boolean>(false);

  const { currentRole, roleId } = useAppSelector(authSelector);

  const [showFormIsDirtyModal, setShowFormIsDirtyModal] = useState(false);

  const [getCustomerData] = useLazyGetCustomerByIdQuery();
  const shouldQueryCustomer = !!parsedQueryParams.customer;
  const { currentData: customerData } = useGetCustomerByIdQuery(
    {
      "X-Role-Id": getRoleId(),
      id: parsedQueryParams.customer ?? "",
    },
    {
      skip: !shouldQueryCustomer,
    }
  );

  useEffect(() => {
    if (initializedContent) {
      const dirtyFields = getDirtyFields(initializedContent);
      if (dirtyFields.length > 0) {
        setBlockNavigation(true);
      } else {
        setBlockNavigation(false);
      }
    }
  }, [initializedContent, content]);

  useEffect(() => {
    if (
      mode === DocumentMode.EDIT &&
      currentData?.content &&
      !editInitialized &&
      !isLoading
    ) {
      setContent(currentData?.content as DocumentCheckContent);
      setInitializedContent(currentData?.content as DocumentCheckContent);
      setEditInitialized(true);
    }

    if (
      mode === DocumentMode.CREATE &&
      currentRole?.role?.key !== Roles.CENTRAL &&
      currentRole?.role?.key !== Roles.SYSTEM_ADMIN &&
      !content.administrator
    ) {
      const advisorData = getAdvisorDataFromRole(currentRole) as Advisor;

      const newContent = {
        ...content,
        administrator: advisorData,
        implementor: advisorData?.nbsRegistrationNumber ? advisorData : null,
      };

      setContent(newContent);
      setInitializedContent(newContent);
    }

    // init modal data
    if (sessionStorage.getItem("checkDevelopmenModalContent")) {
      const parsedModalContent = JSON.parse(
        sessionStorage.getItem("checkDevelopmenModalContent") || "{}"
      );
      setModalRowData(parsedModalContent);
      sessionStorage.removeItem("checkDevelopmenModalContent");
    }

    if (sessionStorage.getItem("checkDevelopmentRow")) {
      const parsedModalRowId = JSON.parse(
        sessionStorage.getItem("checkDevelopmentRow") || "{}"
      );
      setEditingTableRowId(parsedModalRowId);
      setShowModal(true);
      sessionStorage.removeItem("checkDevelopmentRow");
    }

    if (
      Object.keys(parsedQueryParams).includes("customer") &&
      Object.keys(parsedQueryParams).includes("customerType")
    ) {
      getCustomerData({
        "X-Role-Id": getRoleId(),
        id: parsedQueryParams.customer ?? "",
      }).then((data) => {
        if (data.data) {
          handleCustomerSelected(data.data);
          searchParams.delete("customerType");
          searchParams.delete("customer");

          // Search params
          setSearchParams(searchParams);
        }
      });
    }
  }, [
    mode,
    customerData,
    modalRowData,
    content,
    initializedContent,
    isLoading,
  ]);

  const getInventoryDate = (): Date => {
    return new Date(content.inventoryDate);
  };

  const handleImplementorSelected = (implementor: Advisor) => {
    const newDocument = { ...documentCreation };
    newDocument.implementorId = implementor.id;

    const newInputFields = {
      ...content,
      implementor,
    };

    setContent(newInputFields);
    setDocumentCreation(newDocument);
  };

  const handleAdministratorSelected = (administrator: Advisor) => {
    const newDocument = { ...documentCreation };
    newDocument.advisorId = administrator.id;

    const newInputFields = {
      ...content,
      ...{
        administrator,
      },
    };

    setContent(newInputFields);
    setDocumentCreation(newDocument);
  };

  const handleCustomerSelected = (customer: Customer) => {
    const newModalRowData = { ...modalRowData };
    newModalRowData.customer = customer;
    newModalRowData.customerName = `${customer.clientDisplayName}`;
    newModalRowData.customerPin =
      customer.type?.key === CT_FO ? customer.pin ?? "" : customer.ico ?? "";
    setModalRowData(newModalRowData);
  };

  const handleInventoryDateChange = (date: Date) => {
    const newInputFields = {
      ...content,
    };

    newInputFields.inventoryDate = date;

    setContent(newInputFields);
  };

  const handleInputChange = (
    key: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newModalRowData = { ...modalRowData };

    newModalRowData[key] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    setModalRowData(newModalRowData);
  };

  const handleCheckboxChange = (checkbox: string): any => {
    const newModalRowData = { ...modalRowData };
    newModalRowData[checkbox] = !modalRowData[checkbox];

    setModalRowData(newModalRowData);
  };

  const isChecked = (checkbox: string): boolean => {
    return modalRowData[checkbox] === true;
  };

  const handleTextareaChange = (
    variable: string,
    key: string,
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (variable === "modalRowData") {
      const newModalRowData = { ...modalRowData };
      newModalRowData[key] = event.target.value;

      setModalRowData(newModalRowData);
    } else {
      const newContent = { ...content };
      newContent[key] = event.target.value;

      setContent(newContent);
    }
  };

  const getRowZuzColumnValue = (row: DocumentCheckTableContent): string => {
    if (!row?.attachments) {
      return "";
    }

    if (
      row?.attachments.filter((item: DocumentCheckAttachment) => {
        return item.isCcaFile === true;
      }).length > 0
    ) {
      return "eZUZ";
    }

    if (row.attachmentZUZ === true) {
      return "ZUZ";
    }

    return "";
  };

  const getModalValue = (
    key:
      | "financialInstitution"
      | "productLabel"
      | "contractNumber"
      | "paymentPeriod"
      | "bpj"
      | "bpjPercent"
      | "contractId"
      | "notes"
  ): string | number => {
    return modalRowData[key] ?? "";
  };

  const redirectToDetail = (documentId: string): void => {
    const path = makeRoute(AllRoutes.DOCUMENT_VIEW_CHECK, {
      id: documentId,
    });
    navigate(path);
  };

  const handleEditTableRowClick = (index: number) => {
    setEditingTableRowId(index);
    const tableRowData = content.tableData[index];
    setModalRowData(tableRowData);
    setShowModal(true);
  };

  const handleRemoveTableRowClick = async (index: number) => {
    const newContentData = {
      ...content,
      tableData: (content?.tableData ?? []).filter(
        (_: any, i: number) => i !== index
      ),
    };

    newContentData.bpjTotal = calculateBPJTotal(newContentData);
    setContent(newContentData);
  };

  const saveDevelopmentToSessionStorage = () => {
    sessionStorage.setItem(
      "checkDevelopmentRow",
      JSON.stringify(editingTableRowId)
    );
    sessionStorage.setItem(
      "checkDevelopmenModalContent",
      JSON.stringify(modalRowData)
    );
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    e.stopPropagation();
    return false;
  };

  const doSubmit = async () => {
    if (content.tableData.length === 0) {
      toast.warning(t("app.checkNoData"));
      return;
    }

    const customersArray: string[] = [];
    content.tableData.forEach((i: DocumentCheckTableContent) => {
      if (i.customer && i.customer.id) {
        customersArray.push(i.customer.id);
      }
    });

    // if is as central, then advisor and customer is required
    if (currentRole?.role?.key === Roles.CENTRAL) {
      if (!content.implementor || !content.administrator) {
        toast.warning(
          t("document.validation.common.requiredAdministratorAndImplementor")
        );
        return;
      }
    }

    const documentData: DocumentUpdateRequest = {
      customers: customersArray,
      customerFiles: getAllAttachments(),
      content: JSON.stringify(content),
      typeKey: DocumentTypeKeys.CHECK_FORM,
    };

    if (content.administrator && content.administrator.id) {
      documentData.advisorId = content.administrator.id;
    }

    if (content.implementor && content.implementor.id) {
      documentData.implementorId = content.implementor.id;
    }

    if (mode === DocumentMode.CREATE) {
      setIsCreating(true);
      setBlockNavigation(false);
      createDocument({
        "X-Role-Id": getRoleId(),
        documentUpdateRequest: {
          ...documentData,
        },
      }).then((response: any) => {
        if (response.error) {
          setIsCreating(false);
          setBlockNavigation(true);
          return toast.warning(t("app.document.toast.create-failed"), {
            closeOnClick: true,
          });
        }
        const newId = response.data.id;
        if (newId) {
          setIsCreating(false);
          toast.success(t("app.document.toast.create-success"));
          setInitializedContent(content);
          return redirectToDetail(newId);
        }
        return toast.warning(t("app.page.error.defaultMessage"));
      });
    }

    if (mode === DocumentMode.EDIT) {
      setIsCreating(true);
      setBlockNavigation(false);
      updateDocument({
        "X-Role-Id": getRoleId(),
        id: currentData?.id || "0",
        documentUpdateRequest: {
          ...documentData,
        },
      }).then((response: any) => {
        if (response.error) {
          setIsCreating(false);
          setBlockNavigation(true);
          return toast.warning(t("app.document.toast.update-failed"), {
            closeOnClick: true,
          });
        }
        if (response.data.status === "ok") {
          setInitializedContent(content);
          toast.success(t("app.document.toast.update-success"));
          setIsCreating(false);
          return redirectToDetail(id ?? "");
        }
        return toast.warning(t("app.page.error.defaultMessage"));
      });
    }
  };

  const handleSubmitTableRowClick = () => {
    const newContent = { ...content };

    if (editingTableRowId < 0) {
      newContent.tableData = [...newContent.tableData, modalRowData];
    } else {
      const newTableData = [...content.tableData];
      newTableData[editingTableRowId] = modalRowData;
      newContent.tableData = newTableData;
      setEditingTableRowId(-1);
    }

    newContent.bpjTotal = calculateBPJTotal(newContent);

    setContent(newContent);

    setShowModal(false);
    setModalRowData(initialModalRowData);
  };

  const calculateBPJTotal = (newContent: DocumentCheckContent) => {
    let bpjTotal = 0;
    newContent.tableData.forEach((item: DocumentCheckTableContent) => {
      if (item?.bpj) {
        const parsedNumber = parseFloat(item.bpj);
        bpjTotal += !isNaN(parsedNumber) ? parsedNumber : 0;
      }
    });
    return bpjTotal;
  };

  const getAllAttachments = (): CustomerFileUpdateRequest[] => {
    const allAttachments: CustomerFileUpdateRequest[] = [];

    content.tableData.forEach((item: DocumentCheckTableContent) => {
      if (item.attachments) {
        item.attachments.forEach((attachment: DocumentCheckAttachment) => {
          allAttachments.push({
            customerId: item.customer?.id ?? "",
            note: attachment.note ?? "",
            fileId: attachment.file?.id ?? "",
            id: attachment?.id ?? undefined,
          });
        });
      }
    });

    return allAttachments;
  };

  const handleSaveDocumentAndCreateCustomer = (
    customerType: CustomerSelectType
  ) => {
    const customersArray: string[] = [];
    content.tableData.forEach((row: DocumentCheckTableContent) => {
      if (row.customer && row.customer.id) {
        customersArray.push(row.customer.id);
      }
    });

    const documentData: DocumentUpdateRequest = {
      customers: customersArray,
      customerFiles: getAllAttachments(),
      content: JSON.stringify(content),
      typeKey: DocumentTypeKeys.CHECK_FORM,
    };

    if (content.administrator && content.administrator.id) {
      documentData.advisorId = content.administrator.id;
    }

    saveDevelopmentToSessionStorage();

    if (mode === DocumentMode.EDIT) {
      setIsCreating(true);
      updateDocument({
        "X-Role-Id": getRoleId(),
        id: currentData?.id || "0",
        documentUpdateRequest: {
          ...documentData,
        },
      }).then((response: any) => {
        if (response.data.status === "ok") {
          setIsCreating(false);
          return navigate(
            makeRoute(AllRoutes.CUSTOMERS_ADD, {}, [
              {
                name: "document",
                value: currentData?.id || "0",
              },
              {
                name: "customerType",
                value: customerType,
              },
              {
                name: "documentType",
                value: "check",
              },
            ])
          );
        }
        return "";
      });
    }
    if (mode === DocumentMode.CREATE) {
      setIsCreating(true);
      createDocument({
        "X-Role-Id": getRoleId(),
        documentUpdateRequest: {
          ...documentData,
        },
      }).then((response: any) => {
        if (response.error) {
          setIsCreating(false);
          sessionStorage.setItem(
            "CheckDocumentDeveloped",
            JSON.stringify(documentData)
          );
          sessionStorage.setItem(
            "CheckDocumentModalDeveloped",
            JSON.stringify(modalRowData)
          );
          return navigate(
            makeRoute(AllRoutes.CUSTOMERS_ADD, {}, [
              {
                name: "document",
                value: "developed",
              },
              {
                name: "customerType",
                value: customerType,
              },
              {
                name: "documentType",
                value: "check",
              },
            ])
          );
        }
        const newId = response.data.id;
        if (newId) {
          setIsCreating(false);
          return navigate(
            makeRoute(AllRoutes.CUSTOMERS_ADD, {}, [
              {
                name: "document",
                value: newId,
              },
              {
                name: "customerType",
                value: customerType,
              },
              {
                name: "documentType",
                value: "check",
              },
            ])
          );
        }
        return toast.warning(t("app.page.error.defaultMessage"));
      });
    }
  };

  const handleSaveDocumentAndEditCustomer = (
    item: Customer,
    customerType: CustomerSelectType
  ) => {
    const customersArray: string[] = [];
    content.tableData.forEach((i: DocumentCheckTableContent) => {
      if (i.customer && i.customer.id) {
        customersArray.push(i.customer.id);
      }
    });

    const documentData: DocumentUpdateRequest = {
      customers: customersArray,
      content: JSON.stringify(content),
      customerFiles: getAllAttachments(),
      typeKey: DocumentTypeKeys.CHECK_FORM,
    };

    if (content.administrator && content.administrator.id) {
      documentData.advisorId = content.administrator.id;
    }

    saveDevelopmentToSessionStorage();

    if (mode === DocumentMode.EDIT) {
      updateDocument({
        "X-Role-Id": getRoleId(),
        id: currentData?.id || "0",
        documentUpdateRequest: {
          ...documentData,
        },
      }).then((response: any) => {
        if (response.data.status === "ok") {
          setIsCreating(false);
          return navigate(
            makeRoute(AllRoutes.CUSTOMERS_EDIT, { id: item.id }, [
              {
                name: "document",
                value: currentData?.id || "0",
              },
              {
                name: "customerType",
                value: customerType,
              },
              {
                name: "documentType",
                value: "check",
              },
            ])
          );
        }
        return "";
      });
    }
    if (mode === DocumentMode.CREATE) {
      setIsCreating(true);
      createDocument({
        "X-Role-Id": getRoleId(),
        documentUpdateRequest: {
          ...documentData,
        },
      }).then((response: any) => {
        if (response.error) {
          setIsCreating(false);
          sessionStorage.setItem(
            "CheckDocumentDeveloped",
            JSON.stringify(documentData)
          );
          return navigate(
            makeRoute(AllRoutes.CUSTOMERS_EDIT, { id: item.id }, [
              {
                name: "document",
                value: "developed",
              },
              {
                name: "customerType",
                value: customerType,
              },
              {
                name: "documentType",
                value: "check",
              },
            ])
          );
        }
        const newId = response.data.id;
        if (newId) {
          setIsCreating(false);
          return navigate(
            makeRoute(AllRoutes.CUSTOMERS_ADD, {}, [
              {
                name: "document",
                value: newId,
              },
              {
                name: "customerType",
                value: customerType,
              },
              {
                name: "documentType",
                value: "check",
              },
            ])
          );
        }
        return toast.warning(t("app.page.error.defaultMessage"));
      });
    }
  };

  const handleModalData = (data: DocumentCheckAttachment) => {
    const newModalRowData: DocumentCheckTableContent = {
      ...modalRowData,
      attachments: [...(modalRowData.attachments ?? [])],
    };

    newModalRowData?.attachments?.push(data);

    newModalRowData.fileNotes = concatRowNotes(
      newModalRowData?.attachments ?? []
    );

    setModalRowData(newModalRowData);
  };

  const removeAttachmentByIndex = (removeIndex: number) => {
    const newModalRowData = { ...modalRowData };

    setModalRowData({
      ...newModalRowData,
      attachments: newModalRowData?.attachments?.filter(
        (attachment: DocumentCheckAttachment, index: number) => {
          return index !== removeIndex;
        }
      ),
    });
  };

  const previewFile = (attachment: DocumentCheckAttachment) => {
    openFilePreview(attachment.file?.id ?? "", roleId).catch(() => {
      toast.error(t("toast.file.error.alreadySelected"));
    });
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalRowData(modalRowDataDefault);
  };

  const mainRowNote = (row: DocumentCheckTableContent) => {
    const notes: string[] = [];
    if (row.notes) {
      notes.push(row.notes);
    }

    return notes;
  };

  const concatRowNotes = (attachments: DocumentCheckAttachment[]) => {
    const notes: string[] = [];
    attachments.forEach((attachment: DocumentCheckAttachment) => {
      if (attachment.note) {
        notes.push(attachment.note);
      }
    });

    return notes.join(" | ");
  };

  const getDirtyFields = (formData: any) => {
    const dirtyFields: string[] = [];
    Object.keys(formData).forEach((item) => {
      const isDirty = formData[item] !== content[item];
      if (isDirty) {
        dirtyFields.push(item);
      }
    });

    return dirtyFields;
  };

  return (
    <>
      <PageHeader
        label={`${
          mode === DocumentMode.CREATE
            ? t("app.page.documents.create.heading")
            : t("app.page.documents.edit.heading")
        }
          Súpiska`}
        icon={faFileSignature}
      >
        <BackButton
          onClick={() => {
            navigate(
              makeRoute(
                mode === DocumentMode.CREATE
                  ? AllRoutes.DOCUMENTS_LIST_CHECK
                  : AllRoutes.DOCUMENT_VIEW_CHECK,
                { id }
              )
            );
          }}
          className="mr-2"
        />
        <SaveButton
          inProgress={isCreating}
          onClick={doSubmit}
          disabled={isCreating}
        />
      </PageHeader>
      <div>
        <form
          className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
          onSubmit={handleSubmit}
          id="documentForm"
        >
          <div className="mb-6">
            <div className="grid grid-cols-3 gap-4">
              <div className="bg-slate-100 p-4">
                <div className="block text-gray-700 text-sm font-bold mb-2">
                  Dátum súpisu
                </div>
                <ReactDatePicker
                  className="form-control
                  w-full
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  m-0
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  showTimeSelect={false}
                  selected={getInventoryDate()}
                  onChange={(date: Date) => handleInventoryDateChange(date)}
                  locale="sk"
                  dateFormat="dd.MM.yyyy"
                />
              </div>

              <div className="bg-slate-100 p-4">
                <div className="flex flex-grow">
                  <h3 className="font-bold text-sm mb-2">Správca</h3>
                  <div className="ml-auto">
                    {currentRole?.role?.key === Roles.SYSTEM_ADMIN ||
                    currentRole?.role?.key === Roles.CENTRAL ? (
                      <MentorSelectModal
                        label="app.administrator-select-modal.title"
                        selectedMentorID={content?.administrator?.id || ""}
                        callback={(row: Advisor) =>
                          handleAdministratorSelected(row)
                        }
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <Input
                  type="text"
                  label=""
                  placeholder=""
                  disabled
                  value={
                    content.administrator
                      ? `${content.administrator.firstname} ${content.administrator.lastname} (${content.administrator.externalId})`
                      : "-"
                  }
                />
              </div>
              <div className="bg-slate-100 p-4">
                <div className="flex flex-grow">
                  <h3 className="font-bold text-sm mb-2">Realizátor</h3>
                  <div className="ml-auto">
                    <MentorSelectModal
                      label="app.implementor-select-modal.title"
                      selectedMentorID={content?.implementor?.id || ""}
                      callback={(row: Advisor) =>
                        handleImplementorSelected(row)
                      }
                      nbsActiveFilter
                      capitalMarketRegistration={false}
                    />
                  </div>
                </div>
                <Input
                  type="text"
                  label=""
                  placeholder=""
                  disabled
                  value={
                    content.implementor
                      ? `${content.implementor.firstname} ${content.implementor.lastname} (${content.implementor.externalId})`
                      : "-"
                  }
                />
              </div>
              <div />
            </div>
            <div className="mt-5">
              <Button className="mb-2" onClick={() => setShowModal(true)}>
                <span>{t("app.document.add-record")}</span>
              </Button>
              <div className="flex flex-col">
                <div className="overflow-x-auto">
                  <table className="table-auto">
                    <tbody>
                      <tr>
                        <td rowSpan={2} colSpan={2} />
                        <td
                          colSpan={2}
                          className="border border-slate-400 text-center"
                        >
                          Klient
                        </td>
                        <td
                          rowSpan={2}
                          className="border border-slate-400 text-center"
                        >
                          Finančná inštitúcia
                        </td>
                        <td
                          rowSpan={2}
                          className="border border-slate-400 text-center"
                        >
                          Označenie produktu
                        </td>
                        <td
                          rowSpan={2}
                          className="border border-slate-400 text-center"
                        >
                          Prevod zmluvy
                        </td>
                        <td
                          rowSpan={2}
                          className="border border-slate-400 text-center"
                        >
                          Číslo zmluvy
                        </td>
                        <td
                          rowSpan={2}
                          className="border border-slate-400 text-center"
                        >
                          Suma / perióda
                        </td>
                        <td
                          rowSpan={2}
                          className="border border-slate-400 text-center"
                        >
                          BPJ
                        </td>
                        <td
                          rowSpan={2}
                          className="border border-slate-400 text-center"
                        >
                          Správca % BPJ
                        </td>
                        <td
                          colSpan={2}
                          className="border border-slate-400 text-center"
                        >
                          Prílohy
                        </td>
                        <td
                          rowSpan={2}
                          className="border border-slate-400 text-center"
                        >
                          Odoslané do inštitúcie
                        </td>
                        <td
                          rowSpan={2}
                          className="border border-slate-400 text-center"
                        >
                          Vlastná zmluva, pre Blízku osobu,...
                        </td>
                        <td
                          rowSpan={2}
                          className="border border-slate-400 text-center"
                        >
                          ID zmluvy (vyplní centrála)
                        </td>
                      </tr>
                      <tr>
                        <td className="border border-slate-400 text-center">
                          Meno a Priezvisko
                        </td>
                        <td className="border border-slate-400 text-center">
                          Rodné číslo, IČO
                        </td>
                        <td className="border border-slate-400 text-center">
                          ZUZ
                        </td>
                        <td className="border border-slate-400 text-center">
                          Doklad o zaplatení
                        </td>
                      </tr>
                      {content.tableData.map(
                        (row: DocumentCheckTableContent, index: number) => (
                          <tr>
                            <td className="flex flex-col">
                              {/* edit */}
                              <span>
                                <FontAwesomeIcon
                                  icon={faPencilAlt}
                                  className="text-xs hover:text-amber-600 cursor-pointer"
                                  onClick={() => handleEditTableRowClick(index)}
                                />
                              </span>
                              {/* remove */}
                              <span>
                                <FontAwesomeIcon
                                  icon={faTrashAlt}
                                  className="text-xs hover:text-red-700 cursor-pointer"
                                  onClick={() =>
                                    handleRemoveTableRowClick(index)
                                  }
                                />
                              </span>
                            </td>
                            <td className="border border-slate-400 text-center">
                              {index + 1}
                            </td>
                            <td className="border border-slate-400 text-center">
                              {row.customerName}
                            </td>
                            <td className="border border-slate-400 text-center">
                              {row.customerPin}
                            </td>
                            <td className="border border-slate-400 text-center">
                              {decode(row.financialInstitution)}
                            </td>
                            <td className="border border-slate-400 text-center">
                              {decode(row.productLabel)}
                            </td>
                            <td className="border border-slate-400 text-center">
                              {row.contactTransfer === true ? (
                                <span>
                                  <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    className="text-green-800"
                                  />
                                </span>
                              ) : (
                                ""
                              )}
                            </td>
                            <td className="border border-slate-400 text-center">
                              {row.contractNumber}
                            </td>
                            <td className="border border-slate-400 text-center">
                              {row.paymentPeriod}
                            </td>
                            <td className="border border-slate-400 text-center">
                              {row.bpj}
                            </td>
                            <td className="border border-slate-400 text-center">
                              {row.bpjPercent}
                            </td>
                            <td className="border border-slate-400 text-center">
                              {getRowZuzColumnValue(row)}
                            </td>
                            <td className="border border-slate-400 text-center">
                              {row.attachmentReceipt === true ? (
                                <span>
                                  <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    className="text-green-800"
                                  />
                                </span>
                              ) : (
                                ""
                              )}
                            </td>
                            <td className="border border-slate-400 text-center">
                              {row.sentToInstitution === true ? (
                                <span>
                                  <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    className="text-green-800"
                                  />
                                </span>
                              ) : (
                                ""
                              )}
                            </td>
                            <td className="border border-slate-400 text-center">
                              {row.contractPersonType === true ? (
                                <span>
                                  <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    className="text-green-800"
                                  />
                                </span>
                              ) : (
                                ""
                              )}
                            </td>
                            <td className="border border-slate-400 text-center">
                              {row.contractId}
                            </td>
                          </tr>
                        )
                      )}
                      <tr>
                        <td colSpan={8} />
                        <td className="border border-slate-400">BPJ Celkom</td>
                        <td className="border border-slate-400 text-center">
                          {formatPriceWithoutCurrency(
                            content.bpjTotal.toString()
                          )}
                        </td>
                        <td colSpan={6} />
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="mt-3">
              <h3 className="font-bold">Poznámky k záznamom / príloham</h3>
              <div className="grid grid-cols-3 gap-3 mt-2">
                {content.tableData.map(
                  (row: DocumentCheckTableContent, index: number) => (
                    <div className="border border-slate-400 px-3 py-2">
                      <span className="font-bold text-xl">{index + 1}</span>{" "}
                      {mainRowNote(row)}
                      <div>
                        Prílohy:{" "}
                        {row?.attachments
                          ? concatRowNotes(row.attachments)
                          : ""}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="mt-3">
              <div>
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="documentNotes"
                >
                  Poznámky k súpiske
                  <textarea
                    className="form-control
                      block
                      w-full
                      px-3
                      py-1.5
                      text-base
                      font-normal
                      text-gray-700
                      bg-white bg-clip-padding
                      border border-solid border-gray-300
                      rounded
                      transition
                      ease-in-out
                      m-0
                      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                    "
                    id="documentNotes"
                    name="documentNotes"
                    rows={3}
                    value={content.documentNotes}
                    onChange={(e) =>
                      handleTextareaChange("content", "documentNotes", e)
                    }
                    data-testid="/documents/check/textarea-documentNotes"
                  />
                </label>
              </div>
            </div>
          </div>
        </form>
      </div>
      <FormIsDirtyModal
        show={showFormIsDirtyModal}
        setShow={setShowFormIsDirtyModal}
        callback={() => {
          navigate(
            makeRoute(
              mode === DocumentMode.CREATE
                ? AllRoutes.DOCUMENTS_LIST_CHECK
                : AllRoutes.DOCUMENT_VIEW_CHECK,
              { id }
            )
          );
        }}
      />
      <ReactRouterPrompt when={blockNavigation}>
        {({ isActive, onConfirm, onCancel }) =>
          isActive && (
            <>
              <div className="opacity-25 fixed inset-0 z-40 bg-black" />
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto mx-auto max-w-3xl">
                  {/* content */}
                  <div className="rounded-lg shadow-xl relative flex flex-col bg-white w-96">
                    {/* header */}
                    <div className="flex flex-cols px-4 py-4" />
                    {/* body */}
                    <div className="px-4 py-4 my-4 text-blueGray-500">
                      <span>{t("formIsDirtyModal.question")}</span>
                    </div>
                    <div className="border-gray-300 text-center">
                      <div className="px-4 py-4">
                        <Button className="mr-1" onClick={onConfirm}>
                          <span>{t("button.yes")}</span>
                        </Button>
                        <CancelButton onClick={onCancel} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
        }
      </ReactRouterPrompt>
      {/* Main modal */}
      {showModal ? (
        <div
          id="defaultModal"
          className="bg-black bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-normal"
        >
          <div className="relative px-4 w-full max-w-4xl h-full md:h-auto">
            <div className="relative bg-white rounded-lg shadow">
              <div className="flex justify-between items-start p-5 rounded-t border-b">
                <h3 className="text-xl font-semibold text-gray-900 lg:text-2xl">
                  <span>{t("app.documents.check.recordModal.title")}</span>
                </h3>
                <Button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                  data-modal-toggle="defaultModal"
                  onClick={() => handleCloseModal()}
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Button>
              </div>
              <div className="grid p-6">
                <div className="grid grid-cols-2 gap-4">
                  <Input
                    label="Klient"
                    name="customer"
                    disabled
                    value={
                      modalRowData.customer
                        ? `${modalRowData.customer.clientDisplayName} (${
                            modalRowData.customer.type?.key === CT_FO
                              ? modalRowData.customer.pin
                              : modalRowData.customer.ico
                          })`
                        : "-"
                    }
                    placeholder=""
                  />
                  <div className="flex items-end">
                    <CustomerSelectModal
                      createEditButtons
                      selectedCustomerID={modalRowData.customer?.id}
                      callback={(row: Customer) => handleCustomerSelected(row)}
                      btnIcon={faPlus}
                      btnLabel={t("button.add")}
                      customBtnOpenModal={{
                        text: "Zmeniť",
                      }}
                      modalTitle={t("app.customer-select-modal.title")}
                      callbackCustomerCreate={(type: CustomerSelectType) =>
                        handleSaveDocumentAndCreateCustomer(
                          CustomerSelectType.CLIENT
                        )
                      }
                      callbackCustomerEdit={(row: Customer) =>
                        handleSaveDocumentAndEditCustomer(
                          row,
                          CustomerSelectType.CLIENT
                        )
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4 mt-2">
                  <Input
                    label="Finančná inštitúcia"
                    name="financialInstitution"
                    placeholder=""
                    value={getModalValue("financialInstitution")}
                    onChange={(e) =>
                      handleInputChange("financialInstitution", e)
                    }
                  />
                  <Input
                    label="Označenie produktu"
                    name="productLabel"
                    placeholder=""
                    value={getModalValue("productLabel")}
                    onChange={(e) => handleInputChange("productLabel", e)}
                  />
                </div>
                <div className="grid grid-cols-4 gap-4 mt-3">
                  <Input
                    label="Číslo zmluvy"
                    name="contractNumber"
                    placeholder=""
                    value={getModalValue("contractNumber")}
                    onChange={(e) => handleInputChange("contractNumber", e)}
                  />
                  <Input
                    label="Suma / perióda platenia"
                    name="paymentPeriod"
                    placeholder=""
                    value={getModalValue("paymentPeriod")}
                    onChange={(e) => handleInputChange("paymentPeriod", e)}
                  />
                  <Input
                    label="BPJ"
                    name="bpj"
                    placeholder=""
                    value={getModalValue("bpj")}
                    onChange={(e) => handleInputChange("bpj", e)}
                  />
                  <Input
                    label="Správca % BPJ"
                    name="bpjPercent"
                    placeholder=""
                    value={getModalValue("bpjPercent")}
                    onChange={(e) => handleInputChange("bpjPercent", e)}
                  />
                </div>
                <div className="grid grid-cols-2 gap-4 mt-3">
                  <div className="mt-4 flex flex-col gap-4">
                    <Checkbox
                      label="Prevod zmluvy"
                      name="contactTransfer"
                      value="contactTransfer"
                      onChange={(e) => handleCheckboxChange("contactTransfer")}
                      checked={isChecked("contactTransfer")}
                    />
                    <Checkbox
                      label="ZUZ"
                      name="attachmentZUZ"
                      value="attachmentZUZ"
                      onChange={(e) => handleCheckboxChange("attachmentZUZ")}
                      checked={isChecked("attachmentZUZ")}
                    />
                    <Checkbox
                      label="Doklad o zaplatení"
                      name="attachmentReceipt"
                      value="attachmentReceipt"
                      onChange={(e) =>
                        handleCheckboxChange("attachmentReceipt")
                      }
                      checked={isChecked("attachmentReceipt")}
                    />
                    <Checkbox
                      label="Odoslané do inštitúcie"
                      name="sentToInstitution"
                      value="sentToInstitution"
                      onChange={(e) =>
                        handleCheckboxChange("sentToInstitution")
                      }
                      checked={isChecked("sentToInstitution")}
                    />
                    <Checkbox
                      label="Vlastná zmluva, pre Blízku osobu, pre Kolegu"
                      name="contractPersonType"
                      value="contractPersonType"
                      onChange={(e) =>
                        handleCheckboxChange("contractPersonType")
                      }
                      checked={isChecked("contractPersonType")}
                    />
                  </div>
                  <div>
                    <Input
                      label="ID zmluvy (vyplní centrála)"
                      name="contractId"
                      placeholder=""
                      value={getModalValue("contractId")}
                      onChange={(e) => handleInputChange("contractId", e)}
                      disabled={
                        currentRole.role?.key !== Roles.CENTRAL &&
                        currentRole.role?.key !== Roles.SYSTEM_ADMIN
                      }
                    />
                    <Textarea
                      label="Poznámky k záznamu"
                      name="notes"
                      classNameWrapper="mt-3"
                      placeholder=""
                      value={getModalValue("notes")}
                      onChange={(e) =>
                        handleTextareaChange("modalRowData", "notes", e)
                      }
                      dataTestId="/documents/check/textarea-notes"
                    />
                  </div>
                </div>
              </div>
              <div className="border-t p-6">
                <div className="flex justify-between">
                  <h4 className="font-bold text-sm">Priložené súbory</h4>
                  <div className="flex gap-4">
                    <FileUploadWithNoteModal
                      header="Pridať prílohu"
                      callback={(modalData) => handleModalData(modalData)}
                    />
                    <ZuzSelectModal
                      callback={(modalData) => handleModalData(modalData)}
                      btnLabel="Pridať eZUZ"
                      modalTitle="Pridať eZUZ"
                      customer={modalRowData?.customer}
                      advisor={
                        content.implementor ? content.implementor : undefined
                      }
                    />
                  </div>
                </div>
                <div className="max-h-56 overflow-auto mt-4">
                  <table className="table-fixed">
                    <thead className="bg-white sticky top-0">
                      <tr>
                        <td className="pl-0 normal-case text-sm">
                          Poznámky k súborom
                        </td>
                        <td />
                      </tr>
                    </thead>
                    <tbody>
                      {!modalRowData?.attachments ||
                      (modalRowData?.attachments &&
                        modalRowData?.attachments.length === 0) ? (
                        <tr>
                          <td colSpan={2}>
                            <p className="text-sm text-gray-500 text-center">
                              Žiadne priložené súbory
                            </p>
                          </td>
                        </tr>
                      ) : null}
                      {modalRowData?.attachments?.map(
                        (item: DocumentCheckAttachment, index: number) => (
                          <tr>
                            <td className="pl-0">
                              {item.isCcaFile ? (
                                <button
                                  type="button"
                                  className="text-spf-primary hover:underline"
                                  onClick={() => previewFile(item)}
                                >
                                  {item.note ?? ""}
                                  <FontAwesomeIcon
                                    icon={faExternalLink}
                                    className="hover:text-red-700 cursor-pointer ml-2"
                                  />
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="text-spf-primary hover:underline"
                                  onClick={() => previewFile(item)}
                                >
                                  {item.note ?? ""}
                                  <FontAwesomeIcon
                                    icon={faExternalLink}
                                    className="hover:text-red-700 cursor-pointer ml-2"
                                  />
                                </button>
                              )}
                            </td>
                            <td>
                              <FontAwesomeIcon
                                icon={faTrashAlt}
                                className="hover:text-red-700 cursor-pointer"
                                onClick={() => removeAttachmentByIndex(index)}
                              />
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="flex justify-center p-6 space-x-2 rounded-b border-gray-200">
                <Button
                  onClick={() => handleSubmitTableRowClick()}
                  type="button"
                  disabled={!modalRowData.customer}
                >
                  {editingTableRowId && editingTableRowId >= 0
                    ? t("button.save")
                    : t("button.insert")}
                </Button>
                <Button
                  onClick={() => handleCloseModal()}
                  type="button"
                  className="flex justify-center p-6 space-x-2 rounded-b border-gray-200"
                >
                  {t("button.close")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {}
    </>
  );
}

export default DocumentCheckFormView;
