import React from "react";

import { Customer } from "../../api/customers.generated";

interface Properties {
  customers: Customer[] | undefined;
}

function DocumentListCustomers({ customers }: Properties) {
  return (
    <div>
      {customers?.map((item, index) => (
        <span key={`customer-${item.id}`} className={index > 0 ? "pl-2" : ""}>
          {item.clientDisplayName}
          {index < customers.length - 1 ? "," : ""}
        </span>
      ))}
    </div>
  );
}

export default DocumentListCustomers;
