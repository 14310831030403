import React from "react";

import { useTranslation } from "react-i18next";

import { PaginationQuery } from "../../hooks/query";

interface ComponentInterface<Q extends PaginationQuery> {
  query: Q;
  count: number;
  callback: (query: Q) => void;
}

function TablePagination<Q extends PaginationQuery>({
  query,
  count,
  callback,
}: ComponentInterface<Q>): JSX.Element {
  const { t } = useTranslation();
  if (count === 0) {
    return <span />;
  }

  const additionalPages = 3;
  const page = Number(query.page) ?? 1;
  const size = Number(query.size) ?? 10;
  let lastPage = query.size ? Math.ceil(count / size) : 1;
  const from = page - additionalPages > 0 ? page - additionalPages : 1;

  let totalPages =
    page + additionalPages > lastPage
      ? additionalPages * 2 + 1 - (page + additionalPages - lastPage)
      : additionalPages * 2 + 1;

  if (size > count) {
    totalPages = 1;
    lastPage = 1;
  }

  if (totalPages > lastPage) {
    totalPages = lastPage;
  }

  return (
    <div className="text-sm border border-gray-300 sblock sw-full rounded overflow-hidden">
      <button
        key="page-item-first"
        type="button"
        className={
          `px-4 py-2 border-r border-gray-300 ` +
          `${
            page === 1 ? "text-gray-400" : "text-spf-primary hover:bg-gray-100"
          }`
        }
        onClick={() => {
          callback({
            ...query,
            page: 1,
          });
        }}
      >
        <span>{t("app.table.pagination.first")}</span>
      </button>
      {Array.from(Array(totalPages).keys()).map((i) => (
        <button
          key={`page-item-${i}`}
          type="button"
          className={
            `px-4 py-2 border-r border-gray-300 ` +
            `${
              page === from + i
                ? "bg-spf-primary text-white"
                : "text-spf-primary  hover:bg-gray-100"
            }`
          }
          onClick={() => {
            callback({
              ...query,
              page: from + i,
            });
          }}
        >
          {from + i}
        </button>
      ))}
      <button
        type="button"
        key="page-item-last"
        className={
          `px-4 py-2 ` +
          `${
            page === lastPage
              ? "text-gray-400"
              : "text-spf-primary hover:bg-gray-100"
          }`
        }
        onClick={() => {
          callback({
            ...query,
            page: lastPage,
          });
        }}
      >
        <span>{t("app.table.pagination.last")}</span>
      </button>
    </div>
  );
}

export default TablePagination;
