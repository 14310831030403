import { useState } from "react";

import { useNavigate } from "react-router";

import AllRoutes from "../features/route/Route";
import useLogger from "./logger";
import { SortablePaginationQuery, useQuery } from "./query";
import { makeRoute } from "./route";

export interface EventsQuery extends SortablePaginationQuery {
  keyword: string;
  eventsStates: Array<string>;
}

export type EventsQueryDispatcher = (query: EventsQuery) => void;

type EventQueryHook = [EventsQuery, (query: EventsQuery) => void];

const localStorageSizeKey = "event.page.size";

function getEventPageSizeFromStorage(): number {
  return Number(localStorage.getItem(localStorageSizeKey)) || 10;
}

function setEventPageSizeToStorage(newSize: number): void {
  localStorage.setItem(localStorageSizeKey, newSize.toString());
}

export function useEventsQuery(): EventQueryHook {
  const navigate = useNavigate();
  const query = useQuery();
  const { logger } = useLogger();
  const [eventQuery, setEventQuery] = useState<EventsQuery>({
    page: query.page ?? 1,
    size: query.size ?? getEventPageSizeFromStorage(),
    sort: query.sort ? [query.sort] : ["createdAt:desc"],
    keyword: query.keyword ?? "",
    eventsStates: query.eventsStates ?? [],
  });

  logger.debug("PARSED USER QUERY", eventQuery);

  return [
    eventQuery,
    (updatedEventQuery: EventsQuery) => {
      const queryString = Object.keys(updatedEventQuery)
        .map((key) => {
          const parameter = updatedEventQuery[key as keyof EventsQuery];
          if (Array.isArray(parameter)) {
            const val = parameter.join(",");
            return val.length > 0 ? `${key}=${val}` : undefined;
          }

          return (parameter ?? "").toString().length > 0
            ? `${key}=${parameter}`
            : undefined;
        })
        .filter((item) => item !== undefined)
        .join("&");

      logger.debug("QUERY STRING", queryString);

      // Important order: First update the URI/history, than the state because
      // it forces a repaint of the component
      setEventPageSizeToStorage(updatedEventQuery.size ?? 10);
      navigate(`${makeRoute(AllRoutes.EVENTS_LIST)}?${queryString}`);
      setEventQuery(updatedEventQuery);
    },
  ];
}

const allHooks = { useEventsQuery };

export default allHooks;
