import React, { useState } from "react";

import { faList } from "@fortawesome/free-solid-svg-icons";
import { t } from "i18next";
import { toast } from "react-toastify";

import {
  ExternalLinkImportResponse,
  ExternalLinkImportLogLine,
} from "../../api/external-link.generated";
import { importPersonalExternalLinkConfigurations } from "../../api/external-link.manual";
import Card from "../../components/card/Card";
import CardBody from "../../components/card/CardBody";
import CardHeader from "../../components/card/CardHeader";
import FileUploadBox from "../../components/inputs/FileUploadBox";
import PageHeader from "../../components/page/PageHeader";
import useFormatters from "../../hooks/formatter";
import AllRoutes from "../route/Route";

function ExternalLinksImportView() {
  const [result, setResult] = useState<
    ExternalLinkImportResponse | undefined
  >();
  const { formatExternalLinkImportResult } = useFormatters();

  return (
    <>
      <PageHeader
        icon={AllRoutes.EXTERNAL_LINKS_LIST.icon}
        label={t("app.page.external-links-import.heading")}
      />

      <div className="grid grid-cols-4 gap-4">
        <div className="col-span-1">
          <Card>
            <CardHeader
              icon={faList}
              label={t("app.external-links-import.import-card.title") || ""}
            />
            <CardBody>
              <FileUploadBox
                callback={(file: File) => {
                  if (file) {
                    setResult(undefined);
                    const formData = new FormData();
                    formData.append("file", file);
                    importPersonalExternalLinkConfigurations(formData).then(
                      (response: any) => {
                        if (response.data) {
                          const data =
                            response.data as ExternalLinkImportResponse;

                          const logLines: ExternalLinkImportLogLine[] = [];
                          data?.importLog?.map((item) => {
                            if (item.message) {
                              logLines.push({
                                lineNr: item.lineNr,
                                message: formatExternalLinkImportResult(
                                  item.message
                                ),
                              });
                            }
                          });

                          setResult({ ...data, importLog: logLines });
                        } else if (response.error) {
                          toast.warning(response.error.data.message);
                        }
                      }
                    );
                  }
                }}
              />
            </CardBody>
          </Card>
        </div>
        <div className="col-span-3">
          <Card>
            <CardHeader
              icon={faList}
              label={t("app.external-links-import.results-card.title") || ""}
            />
            <CardBody>
              {result?.importLog?.map((item: ExternalLinkImportLogLine) => (
                <p key={`import-result-${item.lineNr}`}>
                  {`${t("app.external-link-import.line")} ${item.lineNr}:${" "}
                  ${item.message}`}
                </p>
              ))}
              {result && (
                <p className="mt-2">
                  {`${t("app.external-link-import.processed")}: ${
                    result?.processed ?? 0
                  }, ${t("app.external-link-import.succesfully")}: ${
                    result?.successful ?? 0
                  }, ${t("app.external-link-import.rejected")}: ${
                    result?.rejected ?? 0
                  } `}
                </p>
              )}
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}

export default ExternalLinksImportView;
