import React from "react";

import { useTranslation } from "react-i18next";

import {
  ClientPartnerRadioOptions,
  ClientPartnerRadioValues,
} from "../../enums/ClientPartnerRadio";
import useDocumentGetters from "../../hooks/documentContent";
import { Document } from "../../models/Document";
import { DocumentContentTypeRadios } from "../../models/DocumentContent";

type IndividualCheckboxProps = {
  disabled?: boolean;
  checked?: boolean;
  hasError?: boolean;
  errorMsg?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};
interface Properties {
  document: Document;
  setDocument: React.Dispatch<Document>;
  name: DocumentContentTypeRadios;
  client?: IndividualCheckboxProps;
  partner?: IndividualCheckboxProps;
  dataTestId?: string;
}

function ClientPartnerRadio({
  document,
  setDocument,
  client,
  name,
  partner,
  dataTestId,
}: Properties) {
  const { t } = useTranslation();
  const { setClientPartnerRadioValues, getClientPartnerRadioValues } =
    useDocumentGetters();
  const radios = getClientPartnerRadioValues(document, name);

  const isClientValueSelected = (expectedValue: ClientPartnerRadioValues) => {
    return document.content[name]?.client === expectedValue;
  };

  const isPartnerValueSelected = (expectedValue: ClientPartnerRadioValues) => {
    return document.content[name]?.partner === expectedValue;
  };

  const handleChange = (
    type: keyof ClientPartnerRadioOptions,
    value: ClientPartnerRadioValues
  ): void => {
    const newRadioValues = { ...radios } as ClientPartnerRadioOptions;
    newRadioValues[type] = value;
    setClientPartnerRadioValues(document, setDocument, name, newRadioValues);

    if (type === "client" && client?.onChange) {
      client.onChange({} as React.ChangeEvent<HTMLInputElement>);
    }

    if (type === "partner" && partner?.onChange) {
      partner.onChange({} as React.ChangeEvent<HTMLInputElement>);
    }
  };

  const handleClick = (type: keyof ClientPartnerRadioOptions): void => {
    const newRadioValues = { ...radios } as ClientPartnerRadioOptions;
    newRadioValues[type] = undefined;
    setClientPartnerRadioValues(document, setDocument, name, newRadioValues);

    if (type === "client" && client?.onChange) {
      client.onChange({} as React.ChangeEvent<HTMLInputElement>);
    }

    if (type === "partner" && partner?.onChange) {
      partner.onChange({} as React.ChangeEvent<HTMLInputElement>);
    }
  };

  const values = [
    ClientPartnerRadioValues.NONE,
    ClientPartnerRadioValues.PARTIAL,
    ClientPartnerRadioValues.FULL,
  ];

  const finalDataTestId = dataTestId || `/global/input-cprad-${name}`;

  return (
    <div className="flex">
      <table>
        <tbody>
          <tr>
            <td className="p-1 !text-center !text-xs uppercase text-gray-500">
              &nbsp;
            </td>
            <td className="p-1 !text-center !text-xs uppercase text-gray-500">
              žiadne
            </td>
            <td className="p-1 !text-center !text-xs uppercase text-gray-500">
              čiastočné
            </td>
            <td className="p-1 !text-center !text-xs uppercase text-gray-500">
              úplne
            </td>
          </tr>
          <tr className={client?.hasError ? "bg-red-100" : ""}>
            <td className={`p-1 ${client?.hasError ? "text-red-400" : ""}`}>
              Klient
            </td>
            {values.map((value) => {
              return (
                <td className="!text-center p-1" key={`client${name}-${value}`}>
                  <input
                    type="radio"
                    id={`client${name}`}
                    name={`client${name}`}
                    value={value}
                    checked={isClientValueSelected(value)}
                    onChange={(event) =>
                      handleChange(
                        "client",
                        event.target.value as ClientPartnerRadioValues
                      )
                    }
                    onClick={() => handleClick("client")}
                    data-testid={`${finalDataTestId}-client`}
                  />
                </td>
              );
            })}
          </tr>
          {client?.hasError ? (
            <tr>
              <td className="p-1 text-red-400" colSpan={4}>
                {client?.errorMsg}
              </td>
            </tr>
          ) : null}
          <tr className={partner?.hasError ? "bg-red-100" : ""}>
            <td className={`p-1 ${partner?.hasError ? "text-red-400" : ""}`}>
              Partner
            </td>
            {values.map((value) => {
              return (
                <td
                  className="!text-center p-1"
                  key={`partner${name}-${value}`}
                >
                  <input
                    type="radio"
                    id={`partner${name}`}
                    name={`partner${name}`}
                    value={value}
                    checked={isPartnerValueSelected(value)}
                    onChange={(event) =>
                      handleChange(
                        "partner",
                        event.target.value as ClientPartnerRadioValues
                      )
                    }
                    onClick={() => handleClick("partner")}
                    data-testid={`${finalDataTestId}-partner`}
                  />
                </td>
              );
            })}
          </tr>
          {partner?.hasError ? (
            <tr>
              <td className="p-1 text-red-400" colSpan={4}>
                {partner?.errorMsg}
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
    </div>
  );
}

ClientPartnerRadio.defaultProps = {
  client: { disabled: false, checked: false, hasError: false },
  partner: { disabled: false, checked: false, hasError: false },
  dataTestId: "",
};

export default ClientPartnerRadio;
