import React from "react";

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

import ApplicationRoute from "../../features/route/ApplicationRoute";
import Button from "./Button";

interface Properties {
  className?: string;
  dataTestId?: string;
  disabled?: boolean;
  to?: ApplicationRoute;
  onClick?: () => void;
  withIcon?: boolean;
}

function CancelButton({
  className,
  disabled,
  to,
  onClick,
  withIcon,
  dataTestId,
}: Properties) {
  const { t } = useTranslation();
  return (
    <Button
      className={`bg-gray-200 text-gray-700 hover:bg-gray-300 focus:ring-gray-300 ${className}`}
      disabled={disabled}
      icon={withIcon ? faTimes : undefined}
      onClick={onClick}
      to={to}
      dataTestId={dataTestId}
    >
      <span>{t("button.cancel")}</span>
    </Button>
  );
}

CancelButton.defaultProps = {
  className: "",
  dataTestId: "",
  disabled: false,
  to: undefined,
  onClick: undefined,
  withIcon: true,
};

export default CancelButton;
