import PermissionRuleStatusKey from "./PermissionRuleStatusKey";
import SelectOption from "./SelectOption";

const PermisssionRuleStatusSelectOptions: SelectOption<PermissionRuleStatusKey>[] =
  [
    {
      name: "Aktívne",
      key: PermissionRuleStatusKey.ACTIVE,
    },
    {
      name: "Neaktívne",
      key: PermissionRuleStatusKey.DEACTIVATED,
    },
  ];

export const getRoleName = (key: string | undefined) => {
  const role = PermisssionRuleStatusSelectOptions.find((r) => r.key === key);
  return role ? role.name : "";
};

export default PermisssionRuleStatusSelectOptions;
