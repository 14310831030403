/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { ChangeEvent } from "react";

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Properties {
  value: string | number;
  placeholder: string;
  autoFocus?: boolean;
  className?: string;
  dataTestId?: string;
  classNameWrapper?: string;
  disabled?: boolean;
  icon?: IconProp;
  label?: string;
  name?: string;
  pattern?: string;
  error?: string;
  type?: "text" | "password" | "date" | "submit" | "number";
  autocomplete?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

function Input({
  autoFocus,
  className,
  classNameWrapper,
  label,
  placeholder,
  name,
  value,
  type,
  icon,
  disabled,
  pattern,
  autocomplete,
  onChange,
  onKeyDown,
  onKeyUp,
  onBlur,
  error,
  dataTestId,
}: Properties) {
  const finalDisabled = !!disabled;
  const showError = error !== undefined && error !== "";
  const finalDataTestId = !dataTestId ? `/global/input-${name}` : dataTestId;
  return (
    <div className={classNameWrapper}>
      {label ? (
        <label className="block text-gray-500 text-sm mb-2 truncate" htmlFor="">
          <span>{label}</span>
        </label>
      ) : (
        ""
      )}
      <div
        className={`group w-full flex border ${
          showError ? "border-red-400" : "border-gray-300"
        } outline-none rounded transition-colors`}
      >
        {icon ? (
          <span className="inline-block bg-gray-100 border-r border-gray-300 pt-2 px-3 text-gray-500 rounded-l w-10 text-center">
            <FontAwesomeIcon icon={icon} />
          </span>
        ) : (
          <span />
        )}
        <input
          className={
            `appearance-none ` +
            `w-full px-4 py-2 border-none ` +
            `${icon ? " rounded-l-0 rounded-r " : " rounded "}` +
            `${
              finalDisabled
                ? " bg-slate-100 text-gray-600 "
                : " bg-white text-gray-800 "
            }` +
            `group-focus:outline-none group-focus:outline-red-600` +
            ` ${className}`
          }
          type={type}
          name={name}
          placeholder={placeholder}
          value={value}
          autoFocus={autoFocus}
          disabled={finalDisabled}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          onBlur={onBlur}
          pattern={pattern}
          autoComplete={autocomplete || "off"}
          data-testid={finalDataTestId}
        />
      </div>
      {showError && <div className="text-red-400">{error}</div>}
    </div>
  );
}

Input.defaultProps = {
  autoFocus: false,
  className: "",
  dataTestId: "",
  classNameWrapper: "",
  name: "",
  pattern: undefined,
  disabled: false,
  type: "text",
  onChange: undefined,
  onKeyDown: undefined,
  onKeyUp: undefined,
  icon: undefined,
  label: undefined,
  error: undefined,
  autocomplete: "off",
  onBlur: undefined,
};

export default Input;
