/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-bind */
import React, { useState } from "react";

import {
  faUserAlt,
  faUser,
  faSyncAlt,
  faShieldAlt,
  faUserShield,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";

import { ValidationError } from "../../api/auth.generated";
import { RoleUserStatusKey } from "../../api/permissionRule.generated";
import {
  SuccessResponse200,
  User,
  UserStatusKey,
  useGetAllRolesQuery,
  useGetUserByIdQuery,
  useUpdateRoleUserStatusMutation,
  useUpdateUserStatusMutation,
} from "../../api/users.generated";
import BackButton from "../../components/buttons/BackButton";
import UserRoleStatusButton from "../../components/buttons/UserRoleStatusButton";
import UserStatusButton from "../../components/buttons/UserStatusButton";
import Card from "../../components/card/Card";
import CardBody from "../../components/card/CardBody";
import CardHeader from "../../components/card/CardHeader";
import ValidatedInput from "../../components/inputs/ValidatedInput";
import PageHeader from "../../components/page/PageHeader";
import { getRoleId } from "../../hooks/role";
import Error403View from "../error/Error403View";
import Error404View from "../error/Error404View";
import PageLoader from "../loader/PageLoader";
import AllRoutes from "../route/Route";

function UserEditView() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const { currentData, isFetching, isError, error, refetch } =
    useGetUserByIdQuery(
      {
        "X-Role-Id": getRoleId(),
        id: id ?? "",
      },
      {
        refetchOnMountOrArgChange: true,
      }
    );

  const {
    currentData: allRoles,
    isFetching: isFetchingRoles,
    refetch: refetchRoles,
  } = useGetAllRolesQuery(
    {
      id: id ?? "",
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const [updateUserRoleStatus] = useUpdateRoleUserStatusMutation();
  const [updateUserStatus] = useUpdateUserStatusMutation();

  const [userData, setUserData] = useState(currentData as User);
  const [formErrors] = useState<ValidationError[]>([]);

  const handleChangeUserStatus = (key: UserStatusKey) => {
    const newStatus = { statusKey: key };
    updateUserStatus({
      id: id ?? "",
      userStatusUpdate: newStatus,
    })
      .unwrap()
      .then((payload: SuccessResponse200) => {
        refetch();
      })
      .catch((err) => {
        toast.warning(err.data.message);
      });
  };

  const handleChangeUserRoleStatus = (
    roleId: string,
    key: RoleUserStatusKey
  ) => {
    const newStatus = { statusKey: key };
    updateUserRoleStatus({
      id: roleId,
      roleUserStatusUpdateRequest: newStatus,
    })
      .unwrap()
      .then((payload: SuccessResponse200) => {
        refetchRoles();
      })
      .catch((err) => {
        toast.warning(err.data.message);
      });
  };

  if (!isFetching && isError && error) {
    if ("data" in error && error.status === 403) {
      return <Error403View error={error} />;
    }

    return <Error404View />;
  }

  if (isFetching && !currentData) return <PageLoader />;

  return (
    <>
      <PageHeader icon={faUserAlt} label={`${currentData?.displayName}`}>
        <BackButton
          className="mr-2"
          onClick={() => {
            navigate(AllRoutes.USERS_LIST.path);
          }}
        />
      </PageHeader>

      <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
        <div className="flex flex-col gap-4">
          <Card>
            <CardHeader
              icon={faUser}
              label={t("app.user.edit.card.customer.header") || ""}
            >
              {isFetching ? (
                <FontAwesomeIcon className="mr-2" icon={faSyncAlt} spin />
              ) : (
                ""
              )}
            </CardHeader>
            <CardBody>
              <div className="py-1 grid grid-cols-5 gap-4">
                <ValidatedInput
                  label={t("app.form.displayName")}
                  field="displayName"
                  type="text"
                  value={currentData?.displayName ?? ""}
                  errors={formErrors}
                  disabled
                  required={false}
                  callback={(e) =>
                    setUserData({
                      ...userData,
                      displayName: e.currentTarget?.value,
                    })
                  }
                />
              </div>
              <div className="py-1 grid grid-cols-2 gap-4">
                <ValidatedInput
                  className="col-span-2"
                  label={t("app.form.email")}
                  field="email"
                  type="text"
                  value={currentData?.email ?? ""}
                  errors={formErrors}
                  disabled
                  required={false}
                  callback={(e) =>
                    setUserData({
                      ...userData,
                      email: e.currentTarget.value,
                    })
                  }
                />
                <div>
                  <div className="mb-3">
                    <label
                      htmlFor="status"
                      className="flex mb-1 text-sm text-gray-500"
                    >
                      <span>{t("app.form.status")}</span>
                    </label>
                    <div>
                      <UserStatusButton
                        callback={handleChangeUserStatus}
                        statusKey={currentData?.status?.key}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>

        <div>
          <Card>
            <CardHeader icon={faShieldAlt} label={t("card.roles.header") || ""}>
              {isFetchingRoles ? (
                <FontAwesomeIcon className="mr-2" icon={faSyncAlt} spin />
              ) : (
                ""
              )}
            </CardHeader>
            <CardBody>
              <table className="mt-1 text-sm">
                <thead>
                  <tr>
                    <td className="pl-10">
                      <span>{t("app.table.users-roles.role.header")}</span>
                    </td>
                    <td className="pr-10">
                      <span>{t("app.table.users-roles.status.header")}</span>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {(allRoles ?? [])?.map((role, index) => (
                    <tr>
                      <td>
                        <FontAwesomeIcon className="mr-2" icon={faUserShield} />
                        <span>{t(`role.label.${role.role?.key}`)}</span>
                        {role.advisorId
                          ? ` pre ${role.advisorBusinessName ?? ""}`
                          : ""}
                      </td>
                      <td>
                        <UserRoleStatusButton
                          statusKey={role.status}
                          callback={(status) =>
                            handleChangeUserRoleStatus(role.id ?? "", status)
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}

export default UserEditView;
