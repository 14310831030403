import React from "react";

import ReactDatePicker from "react-datepicker";
import { useParams } from "react-router-dom";

import { ValidationError } from "../../api/me.generated";
import useFormatters from "../../hooks/formatter";
import { fixTimezoneOffset } from "../../hooks/timezone";

import "react-datepicker/dist/react-datepicker.css";

interface ComponentProperties {
  label: string;
  field: string;
  required?: boolean;
  disabled?: boolean;
  value?: Date | string;
  compareValue?: Date | undefined;
  errors: ValidationError[];
  className?: string;
  dataTestId?: string;
  callback: (date: Date) => void;
}

function ValidatedInputDate({
  label,
  field,
  value,
  compareValue,
  errors,
  required,
  className,
  disabled,
  dataTestId,
  callback,
}: ComponentProperties): JSX.Element {
  const { historyId } = useParams();
  const { formatDate } = useFormatters();
  const error = (errors ?? []).filter((item) => item.field === field);
  const showError = error.length > 0;
  const message = error.map((i) => i.message).join(", ");
  const parsedValue =
    typeof value === "string" && value !== "" ? new Date(value) : value;
  const shouldCompare =
    parsedValue !== "" &&
    historyId !== "undefined" &&
    typeof historyId !== "undefined" &&
    compareValue?.toDateString() !== parsedValue?.toDateString();
  const finalReadOnly = disabled;
  const displayValue = shouldCompare ? compareValue : parsedValue || undefined;
  const finalCompareValue =
    shouldCompare && value !== undefined
      ? parsedValue?.toDateString()
      : new Date().toDateString();

  const htmlFor = `input-datepicker-${field}`;

  return (
    <div>
      <div className="mb-3">
        <label htmlFor={htmlFor} className="flex mb-1 text-sm text-gray-500">
          <span>{label || ""}</span>
          {required && <small className="ml-1 text-spf-red">*</small>}
          {shouldCompare ? (
            <small className="ml-2 text-green-600">{`[${formatDate(
              finalCompareValue
            )}]`}</small>
          ) : (
            ""
          )}
        </label>
        <ReactDatePicker
          disabled={disabled}
          className={
            `form-control
              w-full
              px-3
              py-1.5
              text-base
              font-normal
              text-gray-700
              bg-white bg-clip-padding
              border border-solid border-gray-300
              rounded
              transition
              ease-in-out
              m-0
              focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none ` +
            `${className} ` +
            `${
              finalReadOnly
                ? " bg-slate-100 focus:bg-slate-100 focus:border-slate-300 "
                : ""
            }`
          }
          showTimeSelect={false}
          selected={displayValue}
          onChange={(date) => callback(fixTimezoneOffset(date as Date))}
          locale="en"
          dateFormat="dd.MM.yyyy"
          customInput={
            <input
              type="text"
              data-testid={
                dataTestId && dataTestId.length > 0 ? dataTestId : htmlFor
              }
            />
          }
        />
        {showError && <div className="text-red-400">{message}</div>}
      </div>
    </div>
  );
}

ValidatedInputDate.defaultProps = {
  required: true,
  disabled: false,
  className: "",
  dataTestId: "",
  compareValue: undefined,
  value: undefined,
};

export default ValidatedInputDate;
