import React, { useEffect, useState } from "react";

import {
  faEye,
  faEyeSlash,
  faSave,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import {
  ExternalLink,
  ExternalLinkConfiguration,
  useUpdateExternalLinkConfigurationMutation,
  ValidationError,
} from "../../api/external-link.generated";
import ExternalLinkTypeEnum from "../../enums/ExternalLinkTypeEnum";
import { authSelector } from "../../features/auth/authSlice";
import { getRoleId } from "../../hooks/role";
import setLinkAccessToken from "../../hooks/setLinkAccessToken";
import Alert from "../alerts/Alert";
import Button from "../buttons/Button";
import CancelButton from "../buttons/CancelButton";
import InputWithSuffix from "../inputs/InputWithSuffix";
import ValidatedInput from "../inputs/ValidatedInput";

interface Properties {
  link?: ExternalLink;
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  callback: () => void;
}

function ExternalLinkConfigurationInfoModal({
  link,
  showModal,
  setShowModal,
  callback,
}: Properties) {
  const { t } = useTranslation();
  const [validationError, setValidationError] = useState<string>("");
  const { currentRole } = useSelector(authSelector);
  const [formErrors, setFormErrors] = useState<ValidationError[]>([]);
  const [linkData, setLinkData] = useState<ExternalLink | undefined>(link);
  const [linkConfiguration, setLinkConfiguration] = useState<
    ExternalLinkConfiguration | undefined
  >();

  useEffect(() => {
    if (link) {
      setLinkData(link);
      if (link.linkConfigurations) {
        if (link.type?.key === ExternalLinkTypeEnum.GENERAL) {
          setLinkConfiguration(link.linkConfigurations[0]);
        } else if (
          link.type?.key === ExternalLinkTypeEnum.PERSONALIZED &&
          currentRole.advisor?.id
        ) {
          const advisorId = currentRole.advisor?.id;
          const advisorLinkConfiguration = link.linkConfigurations.find(
            (item) => item.advisorId === advisorId
          );
          if (advisorLinkConfiguration) {
            setLinkConfiguration(advisorLinkConfiguration);
          }
        }
      }
    }
  }, [link, currentRole.advisor?.id]);

  const [passwordInputType, setPasswordInputType] = useState<
    "password" | "text"
  >("password");

  const [doUpdatePersonalLinkConfiguration] =
    useUpdateExternalLinkConfigurationMutation();

  const handleLinkButtonClick = () => {
    setLinkAccessToken();
    window.open(link?.displayLink ?? "", "_blank");
  };

  const handleCloseClick = () => {
    setShowModal(false);
  };

  const handleChangeConfiguration = () => {
    if (link?.type?.key === ExternalLinkTypeEnum.PERSONALIZED) {
      doUpdatePersonalLinkConfiguration({
        "X-Role-Id": getRoleId(),
        id: linkConfiguration?.id ?? "",
        externalLinkConfigurationAdvisorUpdateRequest: {
          login: linkConfiguration?.login ?? "",
          password: linkConfiguration?.password ?? "",
        },
      }).then((response: any) => {
        if (response.data.status === "ok") {
          toast.success(t("app.external-links.advisorConfigurationSuccess"));
          callback();
        } else {
          toast.warning(t("app.external-links.advisoeConfigurationFailed"));
        }
      });
    }
  };

  return (
    <div className={`${showModal ? "block" : "hidden"}`}>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-normal">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/* content */}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-[500px] bg-white outline-none focus:outline-none">
            {/* header */}
            <div className="flex items-start justify-between py-5 px-10 border-b border-solid border-blueGray-200 rounded-t">
              <h3 className="text-2xl ">{link?.name}</h3>
              <button
                type="button"
                className="p-1 ml-auto bg-transparent border-0 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={handleCloseClick}
              >
                <span className="bg-transparent h-6 w-6 text-2xl outline-none focus:outline-none flex items-center">
                  <FontAwesomeIcon icon={faTimes} />
                </span>
              </button>
            </div>
            {/* body */}
            <div className="relative p-6 pt-7 flex-auto">
              {validationError.length > 0 ? (
                <Alert variant="danger">
                  {validationError.length ? validationError : ""}
                </Alert>
              ) : (
                ""
              )}
              <div className="grid grid-cols-3 gap-4">
                <div className="col-span-2">
                  <ValidatedInput
                    label={t("app.form.address")}
                    field="address"
                    type="text"
                    value={link?.displayLink ?? link?.displayLink ?? ""}
                    errors={formErrors}
                    required={false}
                    disabled
                    callback={(e) =>
                      setLinkData({
                        ...linkData,
                        displayLink: e.currentTarget.value,
                      })
                    }
                  />
                </div>
                <div>
                  <Button
                    className="mt-6 w-full"
                    onClick={handleLinkButtonClick}
                  >
                    <span>{t("app.open")}</span>
                  </Button>
                </div>
                <div className="col-span-2">
                  <form autoComplete="off">
                    <ValidatedInput
                      label={t("app.form.firstname")}
                      field="login"
                      type="text"
                      value={linkConfiguration?.login ?? ""}
                      errors={formErrors}
                      required={false}
                      disabled={
                        link?.type?.key !== ExternalLinkTypeEnum.PERSONALIZED
                      }
                      callback={(e) =>
                        setLinkConfiguration({
                          ...linkConfiguration,
                          login: e.currentTarget.value,
                        })
                      }
                    />
                  </form>
                </div>
                <div>
                  <Button
                    className="mt-6 w-full"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        linkConfiguration?.login ?? ""
                      );
                    }}
                  >
                    <span>{t("app.copy")}</span>
                  </Button>
                </div>
                <div className="col-span-2">
                  <InputWithSuffix
                    label={t("app.form.password")}
                    field="password"
                    type={passwordInputType}
                    value={linkConfiguration?.password ?? ""}
                    disabled={
                      link?.type?.key !== ExternalLinkTypeEnum.PERSONALIZED
                    }
                    callback={(e) =>
                      setLinkConfiguration({
                        ...linkConfiguration,
                        password: e.currentTarget.value,
                      })
                    }
                    suffixIcon={
                      passwordInputType === "password" ? faEye : faEyeSlash
                    }
                    suffixCallback={() => {
                      if (passwordInputType === "password") {
                        setPasswordInputType("text");
                      } else setPasswordInputType("password");
                    }}
                  />
                </div>
                <div>
                  <Button
                    className="mt-6 w-full"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        linkConfiguration?.password ?? ""
                      );
                    }}
                  >
                    <span>{t("app.copy")}</span>
                  </Button>
                </div>
              </div>
            </div>
            {/* footer */}
            <div className="flex gap-4 items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
              {link?.type?.key === ExternalLinkTypeEnum.PERSONALIZED ? (
                <Button onClick={handleChangeConfiguration} icon={faSave}>
                  <span>{t("button.save")}</span>
                </Button>
              ) : (
                ""
              )}

              <CancelButton onClick={handleCloseClick} />
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black" />
    </div>
  );
}

ExternalLinkConfigurationInfoModal.defaultProps = {
  link: undefined,
};

export default ExternalLinkConfigurationInfoModal;
