/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext } from "react";

import Card from "../../../components/card/Card";
import ClientPartnerCheckbox from "../../../components/inputs/ClientPartnerCheckbox";
import ClientPartnerRadio from "../../../components/inputs/ClientPartnerRadio";
import { Document } from "../../../models/Document";
import DocumentZuzContext from "../DocumentZuzFormViewContext";
import DocumentInput from "./DocumentInput";

interface Properties {
  document: Document;
  setDocument: React.Dispatch<Document>;
}

function Page03CreditCard({ document, setDocument }: Properties) {
  const ctx = useContext(DocumentZuzContext);
  return (
    <div>
      <Card className="mb-8 px-4">
        {/* <div className="w-full px-3 py-2 mb-4 font-bold bg-slate-200 ">
                Sprostredkovanie finančných služieb v sektore Poskytovania
                úverov
              </div> */}
        <h3 className="text-base font-bold my-2">
          Vstupné informácie (podľa § 35 zákona č. 186/2009 Z.z.)
        </h3>
        {/* poziadavky start */}
        <div className="mb-4 px-3">
          <h4 className="text-base font-bold my-2">
            Požiadavky a potreby vyslovené Klientom / Partnerom
          </h4>
          <div className="flex">
            <div className="w-1/2 mb-4 pr-2 inline-block">
              <DocumentInput
                document={document}
                setDocument={setDocument}
                name="financeLoansClientEntryRequests"
                label="Klient"
                disabled={false}
                error={
                  ctx?.getFieldError("financeLoansClientEntryRequests")?.message
                }
                onChange={(e) => {
                  ctx.resetFieldsErrors([
                    "financeLoansClientEntryRequests",
                    "financeLoansPartnerEntryRequests",
                  ]);
                }}
              />
            </div>
            <div className="w-1/2 mb-4">
              <DocumentInput
                document={document}
                setDocument={setDocument}
                name="financeLoansPartnerEntryRequests"
                label="Partner"
                disabled={false}
                error={
                  ctx?.getFieldError("financeLoansPartnerEntryRequests")
                    ?.message
                }
                onChange={(e) => {
                  ctx.resetFieldsErrors([
                    "financeLoansClientEntryRequests",
                    "financeLoansPartnerEntryRequests",
                  ]);
                }}
              />
            </div>
          </div>
        </div>
        {/* poziadavky end */}
        {/* uvery start */}
        {/* <div className="w-full bg-slate-200 my-3">
          <h3 className="pl-1 text-base font-bold">Úvery</h3>
        </div> */}
        <div className="flex flex-grow" />

        <div className="flex flex-col mb-4">
          <h3 className="mb-2 font-bold">Znalosti a skúsenosti s úvermi</h3>
          <div className="w-1/4">
            <ClientPartnerRadio
              name="loanExperiences"
              document={document}
              setDocument={setDocument}
              client={{
                hasError: !!ctx.getFieldError("loanExperiencesClient"),
                errorMsg: ctx.getFieldError("loanExperiencesClient")?.message,
                onChange: () => {
                  ctx.resetFieldsErrors([
                    "loanExperiencesClient",
                    "loanExperiencesPartner",
                  ]);
                },
              }}
              partner={{
                hasError: !!ctx.getFieldError("loanExperiencesPartner"),
                errorMsg: ctx.getFieldError("loanExperiencesPartner")?.message,
                onChange: () => {
                  ctx.resetFieldsErrors([
                    "loanExperiencesClient",
                    "loanExperiencesPartner",
                  ]);
                },
              }}
            />
          </div>
        </div>

        <div className="pt-3 font-bold">Účel úveru</div>
        <div>
          <ClientPartnerCheckbox
            document={document}
            setDocument={setDocument}
            name="loanPurposeRealEstate"
            text="Kúpa nehnuteľnosti"
            client={{
              hasError: !!ctx.getFieldError("loanPurposeClient"),
              onChange: () => {
                ctx.resetFieldsErrors([
                  "loanPurposeClient",
                  "loanPurposePartner",
                ]);
              },
            }}
            partner={{
              hasError: !!ctx.getFieldError("loanPurposePartner"),
              onChange: () => {
                ctx.resetFieldsErrors([
                  "loanPurposePartner",
                  "loanPurposeClient",
                ]);
              },
            }}
          />
          <ClientPartnerCheckbox
            document={document}
            setDocument={setDocument}
            name="loanPurposeMovableProperty"
            text="Kúpa hnuteľného majetku"
            client={{
              hasError: !!ctx.getFieldError("loanPurposeClient"),
              onChange: () => {
                ctx.resetFieldsErrors([
                  "loanPurposeClient",
                  "loanPurposePartner",
                ]);
              },
            }}
            partner={{
              hasError: !!ctx.getFieldError("loanPurposePartner"),
              onChange: () => {
                ctx.resetFieldsErrors([
                  "loanPurposeClient",
                  "loanPurposePartner",
                ]);
              },
            }}
          />
          <div className="flex flex-grow items-center gap-4">
            <ClientPartnerCheckbox
              document={document}
              setDocument={setDocument}
              name="loanPurposeRefinanceOtherLoan"
              text="Refinancovanie iného úveru vo výške"
              client={{
                hasError: !!ctx.getFieldError("loanPurposeClient"),
                onChange: () => {
                  ctx.resetFieldsErrors([
                    "loanPurposeClient",
                    "loanPurposePartner",
                  ]);
                },
              }}
              partner={{
                hasError: !!ctx.getFieldError("loanPurposePartner"),
                onChange: () => {
                  ctx.resetFieldsErrors([
                    "loanPurposeClient",
                    "loanPurposePartner",
                  ]);
                },
              }}
            />
            <DocumentInput
              document={document}
              setDocument={setDocument}
              name="loanRefinanceOtherLoanAmountClient"
              label="Klient"
              disabled={false}
              error={
                ctx?.getFieldError("loanRefinanceOtherLoanAmountClient")
                  ?.message
              }
            />
            <DocumentInput
              document={document}
              setDocument={setDocument}
              name="loanRefinanceOtherLoanAmountPartner"
              label="Partner"
              disabled={false}
              error={
                ctx?.getFieldError("loanRefinanceOtherLoanAmountPartner")
                  ?.message
              }
            />
          </div>
          <div className="flex flex-grow items-center py-1 gap-4">
            <ClientPartnerCheckbox
              document={document}
              setDocument={setDocument}
              name="loanPurposeOther"
              text="Iný účel úveru"
              client={{
                hasError: !!ctx.getFieldError("loanPurposeClient"),
                onChange: () => {
                  ctx.resetFieldError("loanPurposeClient");
                },
              }}
              partner={{
                hasError: !!ctx.getFieldError("loanPurposePartner"),
                onChange: () => {
                  ctx.resetFieldError("loanPurposePartner");
                },
              }}
            />
            <DocumentInput
              document={document}
              setDocument={setDocument}
              name="loanPurposeOtherAmountClient"
              label="Klient"
              disabled={false}
              error={
                ctx?.getFieldError("loanPurposeOtherAmountClient")?.message
              }
            />
            <DocumentInput
              document={document}
              setDocument={setDocument}
              name="loanPurposeOtherAmountPartner"
              label="Partner"
              disabled={false}
              error={
                ctx?.getFieldError("loanPurposeOtherAmountPartner")?.message
              }
            />
          </div>
          {ctx.getFieldError("loanPurposeClient") ? (
            <p className="text-red-400">
              {ctx.getFieldError("loanPurposeClient")?.message}
            </p>
          ) : null}
          {ctx.getFieldError("loanPurposePartner") ? (
            <p className="text-red-400">
              {ctx.getFieldError("loanPurposePartner")?.message}
            </p>
          ) : null}
        </div>
        <div className="pt-3 font-bold">Zabezpečenie úveru</div>
        <div>
          <ClientPartnerCheckbox
            document={document}
            setDocument={setDocument}
            name="loanSecuringRealEstate"
            text="Nehnuteľnosť"
            client={{
              hasError: !!ctx.getFieldError("loanSecuringClient"),
              onChange: () => {
                ctx.resetFieldsErrors([
                  "loanSecuringClient",
                  "loanSecuringPartner",
                ]);
              },
            }}
            partner={{
              hasError: !!ctx.getFieldError("loanSecuringPartner"),
              onChange: () => {
                ctx.resetFieldsErrors([
                  "loanSecuringClient",
                  "loanSecuringPartner",
                ]);
              },
            }}
          />
          <div className="flex flex-grow items-center py-1 gap-4">
            <ClientPartnerCheckbox
              document={document}
              setDocument={setDocument}
              name="loanSecuringMovableProperty"
              text="Hnuteľným majetkom v hodnote"
              client={{
                hasError: !!ctx.getFieldError("loanSecuringClient"),
                onChange: () => {
                  ctx.resetFieldsErrors([
                    "loanSecuringClient",
                    "loanSecuringPartner",
                  ]);
                },
              }}
              partner={{
                hasError: !!ctx.getFieldError("loanSecuringPartner"),
                onChange: () => {
                  ctx.resetFieldsErrors([
                    "loanSecuringClient",
                    "loanSecuringPartner",
                  ]);
                },
              }}
            />
            <DocumentInput
              document={document}
              setDocument={setDocument}
              name="loanSecuringMovablePropertyAmountClient"
              label="Klient"
              disabled={false}
              error={
                ctx?.getFieldError("loanSecuringMovablePropertyAmountClient")
                  ?.message
              }
            />
            <DocumentInput
              document={document}
              setDocument={setDocument}
              name="loanSecuringMovablePropertyAmountPartner"
              label="Partner"
              disabled={false}
              error={
                ctx?.getFieldError("loanSecuringMovablePropertyAmountPartner")
                  ?.message
              }
            />
          </div>
          <ClientPartnerCheckbox
            document={document}
            setDocument={setDocument}
            name="loanSecuringLiability"
            text="Ručenie"
            client={{
              hasError: !!ctx.getFieldError("loanSecuringClient"),
              onChange: () => {
                ctx.resetFieldsErrors([
                  "loanSecuringClient",
                  "loanSecuringPartner",
                ]);
              },
            }}
            partner={{
              hasError: !!ctx.getFieldError("loanSecuringPartner"),
              onChange: () => {
                ctx.resetFieldsErrors([
                  "loanSecuringClient",
                  "loanSecuringPartner",
                ]);
              },
            }}
          />
          <ClientPartnerCheckbox
            document={document}
            setDocument={setDocument}
            name="loanSecuringCodebtor"
            text="Spoludlžník"
            client={{
              hasError: !!ctx.getFieldError("loanSecuringClient"),
              onChange: () => {
                ctx.resetFieldsErrors([
                  "loanSecuringClient",
                  "loanSecuringPartner",
                ]);
              },
            }}
            partner={{
              hasError: !!ctx.getFieldError("loanSecuringPartner"),
              onChange: () => {
                ctx.resetFieldsErrors([
                  "loanSecuringClient",
                  "loanSecuringPartner",
                ]);
              },
            }}
          />
          <div className="flex flex-grow items-center py-1 gap-4">
            <ClientPartnerCheckbox
              document={document}
              setDocument={setDocument}
              name="loanSecuringDebtOtherType"
              text="Iné zabezpečenie úveru"
              client={{
                hasError: !!ctx.getFieldError("loanSecuringClient"),
                onChange: () => {
                  ctx.resetFieldsErrors([
                    "loanSecuringClient",
                    "loanSecuringPartner",
                  ]);
                },
              }}
              partner={{
                hasError: !!ctx.getFieldError("loanSecuringPartner"),
                onChange: () => {
                  ctx.resetFieldsErrors([
                    "loanSecuringClient",
                    "loanSecuringPartner",
                  ]);
                },
              }}
            />
            <DocumentInput
              document={document}
              setDocument={setDocument}
              name="loanSecuringOtherTypeClientValue"
              label="Klient"
              disabled={false}
              error={
                ctx?.getFieldError("loanSecuringOtherTypeClientValue")?.message
              }
            />
            <DocumentInput
              document={document}
              setDocument={setDocument}
              name="loanSecuringOtherTypePartnerValue"
              label="Partner"
              disabled={false}
              error={
                ctx?.getFieldError("loanSecuringOtherTypePartnerValue")?.message
              }
            />
          </div>
          {ctx.getFieldError("loanSecuringClient") ? (
            <p className="text-red-400">
              {ctx.getFieldError("loanSecuringClient")?.message}
            </p>
          ) : null}
          {ctx.getFieldError("loanSecuringPartner") ? (
            <p className="text-red-400">
              {ctx.getFieldError("loanSecuringPartner")?.message}
            </p>
          ) : null}
        </div>
        <div className="flex flex-grow mt-2">
          <div className="sm:w-full md:w-1/3 inline-block pl-2">
            <DocumentInput
              document={document}
              setDocument={setDocument}
              name="loanAmountOfCreditRequired"
              label="Výška požadovaného úveru"
              disabled={false}
              error={ctx.getFieldError("loanAmountOfCreditRequired")?.message}
              onChange={(e) => {
                ctx.resetFieldError("loanAmountOfCreditRequired");
              }}
            />
          </div>
          <div className="sm:w-full md:w-1/3 inline-block pl-2">
            <DocumentInput
              document={document}
              setDocument={setDocument}
              name="loanExpectedMaturity"
              label="Predpokladaná splatnosť úveru"
              disabled={false}
              error={ctx.getFieldError("loanExpectedMaturity")?.message}
              onChange={(e) => {
                ctx.resetFieldError("loanExpectedMaturity");
              }}
            />
          </div>
          <div className="sm:w-full md:w-1/3 inline-block pl-2">
            <DocumentInput
              document={document}
              setDocument={setDocument}
              name="loanMaximumMonthlyPaymentAmount"
              label="Maximálna výška mesačných splátok"
              disabled={false}
              error={
                ctx.getFieldError("loanMaximumMonthlyPaymentAmount")?.message
              }
              onChange={(e) => {
                ctx.resetFieldError("loanMaximumMonthlyPaymentAmount");
              }}
            />
          </div>
        </div>
        <div className="mt-2">
          <ClientPartnerCheckbox
            document={document}
            setDocument={setDocument}
            name="loanEarlyRepaymentRequired"
            text="Požiadavka na predčasnú splatnosť úveru"
          />
          <ClientPartnerCheckbox
            document={document}
            setDocument={setDocument}
            name="loanInsuranceRequired"
            text="Požiadavka na poistenie úveru (odporúča sa)"
          />
        </div>
        <div className="w-full inline-block pt-2 pl-2 mb-3">
          <DocumentInput
            document={document}
            setDocument={setDocument}
            name="loanOtherRequirements"
            label="Ďalšie požiadavky (prípadné poplatky, podmienky, úrokové sadzby, fixácia):"
            disabled={false}
          />
        </div>
        {/* sprotredkovanie vkladov/uverov end */}
        {/* sprostredkovanie v oblasti kapitaloveho trhu start */}
      </Card>
    </div>
  );
}

export default Page03CreditCard;
