import React, { ChangeEvent, useEffect, useRef, useState } from "react";

import { faFile, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDrop, DndProvider, DropTargetMonitor } from "react-dnd";
import { HTML5Backend, NativeTypes } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useDoUploadMutation } from "../../api/upload.generated";
import { DocumentCheckAttachment } from "../../models/DocumentCheckAttachment";
import Button from "../buttons/Button";
import Card from "../card/Card";
import CardBody from "../card/CardBody";
import Input from "./Input";

interface Properties {
  header: string;
  callback: (modalData: DocumentCheckAttachment) => void;
}

function FileUploadWithNoteModal({ header, callback }: Properties) {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [note, setNote] = useState("");
  const [doUpload, status] = useDoUploadMutation();

  const [file, setFile] = useState<File>();

  const handleSaveClick = () => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      doUpload({ body: formData }).then((response: any) => {
        if (response.data) {
          callback({
            uploadedFile: file,
            note: file.name ? `${note} [${file.name}]` : note,
            id: undefined,
            isCcaFile: false,
            documentId: "",
            file: {
              id: response.data.id,
            },
          });
          setShowModal(false);
        }
        if (response.error) {
          toast.error(t("error.generic"));
        }
      });
    }
  };

  const onFileSelect = (item: { files: any[] }) => {
    setFile(item.files[0]);
  };

  useEffect(() => {
    setFile(undefined);
    setNote("");
  }, [showModal]);

  return (
    <div>
      <Button className="text-sm" onClick={() => setShowModal(true)}>
        Pridať
      </Button>
      {showModal ? (
        <div
          id="defaultModal"
          className="bg-black bg-opacity-50 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-normal"
        >
          <div className="relative w-full my-6 mx-auto max-w-md">
            {/* content */}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/* header */}
              <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                <h3 className="text-2xl font-semibold">{header}</h3>
                <button
                  type="button"
                  className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => setShowModal(false)}
                >
                  <span className="bg-transparent text-black h-6 w-6 text-2xl outline-none focus:outline-none flex items-center">
                    ×
                  </span>
                </button>
              </div>
              {/* body */}
              <div>
                <Card className="border-b-0">
                  <CardBody>
                    <div className="py-1 grid grid-cols-1 gap-4">
                      <Input
                        label="Poznámka"
                        name="note"
                        type="text"
                        placeholder=""
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                      />
                      <DndProvider backend={HTML5Backend}>
                        <form
                          className="mt-6"
                          onSubmit={(e) => e.preventDefault()}
                        >
                          {file ? (
                            <SelectedFileView file={file} />
                          ) : (
                            <DropArea onFileSelect={onFileSelect} />
                          )}
                        </form>
                      </DndProvider>
                    </div>
                  </CardBody>
                </Card>
              </div>
              {/* footer */}
              <div className="flex justify-center p-6 space-x-2 rounded-b border-gray-200">
                <Button
                  onClick={handleSaveClick}
                  type="button"
                  disabled={status?.isLoading}
                >
                  {status?.isLoading ? (
                    <FontAwesomeIcon
                      icon={faSyncAlt}
                      className="animate-spin"
                    />
                  ) : (
                    <span>{t("button.save")}</span>
                  )}
                </Button>
                <Button
                  onClick={() => setShowModal(false)}
                  type="button"
                  disabled={status?.isLoading}
                >
                  <span>{t("button.close")}</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default FileUploadWithNoteModal;

function SelectedFileView({ file }: { file: File }) {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <div className="text-2xl text-gray-400 mb-2">
        <FontAwesomeIcon icon={faFile} />
      </div>
      <div className="text-sm text-center text-gray-400">{file?.name}</div>
    </div>
  );
}

function DropArea({ onFileSelect }: { onFileSelect: (item: any) => void }) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item: { files: any[] }) {
        if (onFileSelect) {
          onFileSelect(item);
        }
      },
      canDrop(item: any) {
        // console.log("canDrop", item.files, item.items);
        return true;
      },
      hover(item: any) {
        // console.log("hover", item.files, item.items);
      },
      collect: (monitor: DropTargetMonitor) => {
        const item = monitor.getItem() as any;
        if (item) {
          // console.log("collect", item.files, item.items);
        }

        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
    }),
    [{ onFileSelect }]
  );

  const handleOnChange = (e: ChangeEvent) => {
    if (onFileSelect) {
      onFileSelect(e.target);
    }
  };

  return (
    <label
      ref={drop}
      className={`h-32 p-4 flex justify-center items-center border-2 border-dashed border-gray-300 rounded-md outline-none cursor-pointer ${
        canDrop && isOver ? "bg-gray-100" : "bg-white"
      } hover:bg-gray-50 focus:bg-gray-50 transiton duration-200 ease-in-out relative`}
      htmlFor="input-file-upload"
    >
      <input
        ref={inputRef}
        type="file"
        id="input-file-upload"
        className="hidden"
        onChange={handleOnChange}
      />
      <p className="w-3/4 select-none text-sm text-center text-gray-500">
        Súbor preneste do tejto oblasti alebo kliknite pre výber
      </p>
    </label>
  );
}
