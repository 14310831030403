export enum CUSTOMER_SKILL_TYPE {
  PROFESSIONAL = "professional",
  NON_PROFESSIONAL = "non-professional",
}

export type CustomerSkillTypeCheckbox = {
  checked: boolean;
  name: CUSTOMER_SKILL_TYPE;
  label: string;
};

/** @deprecated */
export type ClientType = CustomerSkillTypeCheckbox;

/** @deprecated */
export type PartnerType = CustomerSkillTypeCheckbox;
