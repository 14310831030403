import React, { useCallback, useEffect, useMemo } from "react";

import { countries as countriesDataset, type Country } from "countries-list";
import { useTranslation } from "react-i18next";

import { ValidationError } from "../../api/me.generated";

type CountriesDatasetType = {
  [key: string]: Country;
};

interface ComponentProperties {
  label: string;
  value: string | number;
  compareValue?: string | number | undefined;
  field: string;
  errors: ValidationError[];
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  callback: (event: React.FormEvent<HTMLInputElement>) => void;
  placeholder?: string;
  dataTestId?: string;
}

const fixDatasetMultipleValues = (
  dataset: CountriesDatasetType
): CountriesDatasetType => {
  const newDataset: CountriesDatasetType = {};

  Object.keys(dataset).forEach((key) => {
    const phones = dataset[key].phone.split(",");

    phones.forEach((phone, index) => {
      const newKey = index === 0 ? key : `${key}-${index}`;
      newDataset[newKey] = {
        ...dataset[key],
        phone,
      };
    });
  });

  return newDataset;
};

function ValidatedInputPhone({
  label,
  value,
  compareValue,
  field,
  errors,
  required,
  readOnly,
  disabled,
  callback,
  placeholder,
  dataTestId,
}: ComponentProperties): JSX.Element {
  const { t } = useTranslation();

  const countries: CountriesDatasetType = useMemo(
    () => fixDatasetMultipleValues(countriesDataset),
    []
  );

  const findCountry = (prefix: string) => {
    return Object.keys(countries).find((cc) => {
      return countries[cc].phone === prefix;
    });
  };

  const splitValue = (
    valueToSplit: string | number | undefined
  ): {
    prefix: string;
    phone: string;
  } => {
    if (
      valueToSplit === undefined ||
      valueToSplit === null ||
      valueToSplit === ""
    ) {
      return {
        prefix: "421",
        phone: "",
      };
    }

    const cleanValue = valueToSplit
      .toString()
      .replace("+", "")
      .replaceAll(" ", "");

    let country: string | undefined;
    let prefixLength = 3;

    [4, 3, 2, 1].forEach((length) => {
      if (!country) {
        const possiblePrefix = cleanValue.substring(0, length);
        country = findCountry(possiblePrefix);
        prefixLength = length;
      }
    });

    const possiblePhone = cleanValue.substring(prefixLength, cleanValue.length);

    if (country === undefined) {
      return {
        prefix: "421",
        phone: valueToSplit.toString(),
      };
    }

    return {
      prefix: countries[country].phone,
      phone: possiblePhone,
    };
  };

  const [internalValue, setInternalValue] = React.useState({
    prefix: splitValue(value).prefix,
    phone: splitValue(value).phone,
  });

  const error = (errors ?? []).filter((item) => item.field === field);
  const showError = error.length > 0;
  const message = error.map((i) => i.message).join(", ");
  const shouldCompare = compareValue !== undefined && compareValue !== value;

  const compareLabel = (v: string | number | undefined) => {
    const defaultLabel = t("input.string.empty");
    switch (typeof v) {
      case "string":
        return v.length > 0 ? v : defaultLabel;
      case "number":
        return v;
      default:
        return defaultLabel;
    }
  };

  const htmlFor = `input-${field}`;
  const commonClassnames =
    "py-1.5 px-3 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none";

  const neighbours = ["SK", "CZ", "AT", "PL", "HU", "UA"];
  const countryListGroups = [
    {
      groupName: "Slovensko a susedia",
      items: neighbours.map((cc) => {
        return countries[cc];
      }),
    },
    {
      groupName: "Ostatné krajiny",
      items: Object.keys(countries)
        .filter((cc) => !neighbours.includes(cc))
        .map((cc) => {
          return countries[cc];
        }),
    },
  ];

  const emitUpdate = useCallback(() => {
    const phone = internalValue.phone.startsWith("0")
      ? internalValue.phone.substring(1, internalValue.phone.length)
      : internalValue.phone;

    let newValue = "";
    if (phone) {
      newValue = `+${internalValue.prefix}${phone}`.replaceAll(/[^+0-9]/g, "");
    }

    callback({
      target: {
        value: newValue,
      },
      currentTarget: {
        value: newValue,
      },
    } as unknown as React.FormEvent<HTMLInputElement>);
  }, [internalValue]);

  // on value cahnge
  useEffect(() => {
    setInternalValue((prevValue) => splitValue(value));
  }, [value]);

  // to emit value
  useEffect(emitUpdate, [
    emitUpdate,
    internalValue.prefix,
    internalValue.phone,
  ]);

  return (
    <div>
      <div className="mb-3">
        <label htmlFor={htmlFor} className="flex mb-1 text-sm text-gray-500">
          <span>{label || ""}</span>
          {required && <small className="ml-1 text-spf-red">*</small>}
          {shouldCompare ? (
            <small className="ml-auto text-green-600">{`[${compareLabel(
              compareValue
            )}]`}</small>
          ) : (
            ""
          )}
        </label>
        <div className="flex gap-2">
          <select
            name=""
            id=""
            className={`w-28 ${commonClassnames}`}
            onChange={(event) =>
              setInternalValue((prevValue) => {
                return {
                  ...prevValue,
                  prefix: event.target.value,
                };
              })
            }
            value={internalValue.prefix}
          >
            {countryListGroups.map((group) => {
              return (
                <optgroup label={group.groupName} key={group.groupName}>
                  {group.items.map((country) => {
                    return (
                      <option value={country.phone} key={country.name}>
                        + {country.phone} {country.emoji}
                      </option>
                    );
                  })}
                </optgroup>
              );
            })}
          </select>
          <input
            className={
              `

                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none ` +
              `${commonClassnames}` +
              `${disabled ? " bg-gray-100 cursor-text" : ""}`
            }
            onBlur={() => emitUpdate()}
            type="text"
            value={internalValue.phone}
            id={htmlFor}
            onChange={(event) =>
              setInternalValue((prevValue) => {
                return {
                  ...prevValue,
                  phone: event.target.value,
                };
              })
            }
            readOnly={readOnly}
            disabled={disabled}
            autoComplete="false"
            placeholder={placeholder}
            data-testid={
              dataTestId && dataTestId.length > 0 ? dataTestId : htmlFor
            }
          />
        </div>
        {showError ? (
          <div className="text-green-400">{message}</div>
        ) : undefined}
      </div>
    </div>
  );
}

ValidatedInputPhone.defaultProps = {
  compareValue: undefined,
  required: false,
  readOnly: false,
  disabled: false,
  placeholder: "",
  dataTestId: "",
};

export default ValidatedInputPhone;
