import { baseSplitApi as api } from "./baseApi";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getComputedRights: build.query<
      GetComputedRightsApiResponse,
      GetComputedRightsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/permissions/`,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort: queryArg.sort,
        },
      }),
    }),
    getComputedRightById: build.query<
      GetComputedRightByIdApiResponse,
      GetComputedRightByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/permissions/${queryArg.id}`,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as spfApi };
export type GetComputedRightsApiResponse = /** status 200 OK */ PermissionPage;
export type GetComputedRightsApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** page */
  page?: number;
  /** size */
  size?: number;
  /** Format is sort=created_at:desc. Supported keys: created_at */
  sort?: string[];
};
export type GetComputedRightByIdApiResponse =
  /** status 200 OK */ PermissionPageItem;
export type GetComputedRightByIdApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** uuid */
  id: string;
};
export type PermissionFilter = {
  keyword?: string;
};
export type PermissionRuleRightsActionTypeKey =
  | "prra-add"
  | "prra-none"
  | "prra-remove";
export type PermissionRuleTypeKey =
  | "pr-all"
  | "pr-advisor-position"
  | "pr-role"
  | "pr-user-role"
  | "pr-advisor-tree"
  | "pr-advisor-children";
export type ComputedRightLogEntry = {
  create?: PermissionRuleRightsActionTypeKey;
  delete?: PermissionRuleRightsActionTypeKey;
  id?: string;
  name?: string;
  read?: PermissionRuleRightsActionTypeKey;
  type?: PermissionRuleTypeKey;
  update?: PermissionRuleRightsActionTypeKey;
  updatedAt?: string;
};
export type PermissionObjectTypeKey =
  | "pt-customers"
  | "pt-external-links"
  | "pt-cca"
  | "pt-check"
  | "pt-identifications"
  | "pt-users"
  | "pt-permissions"
  | "pt-system-settings";
export type RoleUserRight = {
  create?: PermissionRuleRightsActionTypeKey;
  delete?: PermissionRuleRightsActionTypeKey;
  log?: ComputedRightLogEntry[];
  read?: PermissionRuleRightsActionTypeKey;
  type?: PermissionObjectTypeKey;
  update?: PermissionRuleRightsActionTypeKey;
};
export type RoleKey =
  | "rk-assistant"
  | "rk-advisor"
  | "rk-central"
  | "rk-system-admin";
export type PermissionPageItem = {
  computedCrud?: RoleUserRight[];
  displayName?: string;
  id?: string;
  roleKey?: RoleKey;
};
export type SortFieldDirection = "asc" | "desc";
export type SortFieldKey =
  | "createdAt"
  | "updatedAt"
  | "startsAt"
  | "validTo"
  | "validUntil"
  | "firstname"
  | "lastname"
  | "name"
  | "displayName"
  | "evidenceNumber"
  | "email"
  | "pin"
  | "ico"
  | "advisorEmail"
  | "customerEmail"
  | "mentorEmail"
  | "phoneNumber"
  | "displayId"
  | "street"
  | "city"
  | "zip"
  | "id"
  | "owner"
  | "type"
  | "status"
  | "customer"
  | "advisor"
  | "updatedBy";
export type SortField = {
  direction?: SortFieldDirection;
  field?: SortFieldKey;
};
export type PermissionPage = {
  count?: number;
  filter?: PermissionFilter;
  items?: PermissionPageItem[];
  last?: boolean;
  page?: number;
  size?: number;
  sort?: SortField[];
};
export type ValidationError = {
  code?: string;
  field?: string;
  message?: string;
};
export type ErrorResponse400 = {
  code?: number;
  data?: ValidationError[];
  message?: string;
};
export type ErrorResponse404 = {
  code?: number;
  data?: {
    [key: string]: string;
  }[];
  message?: string;
};
export const { useGetComputedRightsQuery, useGetComputedRightByIdQuery } =
  injectedRtkApi;
