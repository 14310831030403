import React from "react";

import { faCheckToSlot } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

import ApplicationRoute from "../../features/route/ApplicationRoute";
import Button from "./Button";

interface Properties {
  className?: string;
  disabled?: boolean;
  dataTestId?: string;
  to?: ApplicationRoute | undefined;
  onClick?: () => void | undefined;
}

function CheckButton({
  className,
  disabled,
  to,
  dataTestId,
  onClick,
}: Properties) {
  const { t } = useTranslation();
  return (
    <Button
      className={className}
      disabled={disabled}
      icon={faCheckToSlot}
      onClick={onClick}
      to={to}
      dataTestId={dataTestId}
    >
      <span>{t("button.check")}</span>
    </Button>
  );
}

CheckButton.defaultProps = {
  className: "",
  dataTestId: "/global/btn-check",
  disabled: false,
  to: undefined,
  onClick: undefined,
};

export default CheckButton;
