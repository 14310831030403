import { useState } from "react";

import i18next, { t } from "i18next";

import { Customer } from "../api/documents.generated";
import ClientPartnerCheckboxOptions from "../enums/ClientPartnerCheckboxOptions";
import { ClientPartnerRadioValues } from "../enums/ClientPartnerRadio";
import ZUZDocumentSection from "../enums/ZUZDocumentSection";
import type { Document } from "../models/Document";
import {
  DocumentContent,
  EsgInterest,
  InvestmentIntentionOnce,
  InvestmentIntentionRepeated,
  SustainabiltyRequest,
} from "../models/DocumentContent";
import useDocumentGetters from "./documentContent";

export type InvestmentIntentionError = {
  frontendID: string;
  description?: string;
  achivable?: string;
};

export type ZUZDocumentError = {
  section: ZUZDocumentSection;
  field: string;
  valid: boolean;
  message: string;
};

export type RequiredFieldsArgs = {
  document: DocumentContent;
  documentGetters: ReturnType<typeof useDocumentGetters>;
};

const getSignatureRequiredFields = ({
  document,
  documentGetters,
}: RequiredFieldsArgs): Array<ZUZDocumentError> => {
  return [
    {
      section: ZUZDocumentSection.SIGNATURE,
      field: "signatureType",
      valid: document.signatureType !== "",
      message: i18next.t("document.validation.common.required"),
    },
  ];
};

const isPartnerSelected = (document: DocumentContent) => {
  return document.partnerIdNumber !== "";
};

const parseFormatedDate = (date: string): Date => {
  const [day, month, year] = date.replace(" ", "").split(".");
  return new Date(`${year}-${month}-${day}`);
};

// page 01 card - client
const getClientRequiredFields = ({
  document,
  documentGetters,
}: RequiredFieldsArgs): Array<ZUZDocumentError> => {
  const { isChecked } = documentGetters;

  const fields = [
    {
      section: ZUZDocumentSection.AGENT_CLINET_PARTNER,
      field: "clientName",
      valid: document.clientName !== "",
      message: i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.AGENT_CLINET_PARTNER,
      field: "clientIdNumber",
      valid: document.clientIdNumber !== "",
      message: i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.AGENT_CLINET_PARTNER,
      field: "clientBirthDate",
      valid: document.clientBirthdate !== "",
      message: i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.AGENT_CLINET_PARTNER,
      field: "clientHomeAddress",
      valid: document.clientHomeAddress !== "",
      message: i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.AGENT_CLINET_PARTNER,
      field: "clientEmail",
      valid: (() => {
        if (isPartnerSelected(document)) {
          if (
            document.partnerEmail === document.clientEmail ||
            document.partnerEmail === document.pfaEmail
          ) {
            return false;
          }
        }

        if (document.clientEmail === document.pfaEmail) {
          return false;
        }

        return document.clientEmail !== "";
      })(),
      message: (() => {
        if (isPartnerSelected(document)) {
          if (
            document.partnerEmail === document.clientEmail ||
            document.partnerEmail === document.pfaEmail
          ) {
            return i18next.t(
              "document.validation.common.clientEmailSameNotAsPartnerEmail"
            );
          }
        }

        if (document.clientEmail === document.pfaEmail) {
          return i18next.t(
            "document.validation.common.clientEmailSameNotAsPartnerEmail"
          );
        }

        return i18next.t("document.validation.common.required");
      })(),
    },
    {
      section: ZUZDocumentSection.AGENT_CLINET_PARTNER,
      field: "clientPhone",
      valid: document.clientPhone !== "",
      message: i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.AGENT_CLINET_PARTNER,
      field: "clientIdDisplayValue",
      valid: document.clientIdDisplayValue !== "",
      message: i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.AGENT_CLINET_PARTNER,
      field: "clientIdValidUntil",
      valid: (() => {
        const isExpired =
          document.clientIdValidUntil !== "" &&
          parseFormatedDate(document.clientIdValidUntil).getTime() <
            new Date().getTime();

        return document.clientIdValidUntil !== "" && !isExpired;
      })(),

      message: (() => {
        if (document.clientIdValidUntil === "") {
          return i18next.t("document.validation.common.required");
        }

        if (
          parseFormatedDate(document.clientIdValidUntil).getTime() <
          new Date().getTime()
        ) {
          return i18next.t("document.validation.idValidUntil.expired");
        }

        return "";
      })(),
    },
    {
      section: ZUZDocumentSection.AGENT_CLINET_PARTNER,
      field: "clientState",
      valid: document.clientState !== "",
      message: i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.AGENT_CLINET_PARTNER,
      field: "clientCompany",
      valid: document.clientCompany !== "",
      message: i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.AGENT_CLINET_PARTNER,
      field: "clientBusinessAddress",
      valid: document.clientBusinessAddress !== "",
      message: i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.AGENT_CLINET_PARTNER,
      field: "clientBusinessId",
      valid: document.clientBusinessId !== "",
      message: i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.AGENT_CLINET_PARTNER,
      field: "clientBusinessFunction",
      valid: document.clientBusinessFunction !== "",
      message: i18next.t("document.validation.common.required"),
    },
  ];

  // if person or fo/fop we want to show all fields
  if (
    isChecked(
      { content: document } as unknown as Document,
      "clientPersonType",
      "ct-po"
    ) ||
    isChecked(
      { content: document } as unknown as Document,
      "clientPersonType",
      "ct-fop"
    )
  ) {
    return fields;
  }

  // if basic person we want to hide some fields
  return fields.filter(
    (field) =>
      [
        "clientCompany",
        "clientBusinessAddress",
        "clientBusinessId",
        "clientBusinessFunction",
      ].indexOf(field.field) === -1
  );
};

const getPartnerRequiredFields = ({
  document,
  documentGetters,
}: RequiredFieldsArgs): Array<ZUZDocumentError> => {
  if (!isPartnerSelected(document)) return [];
  return [
    {
      section: ZUZDocumentSection.AGENT_CLINET_PARTNER,
      field: "partnerName",
      valid: document.partnerName !== "",
      message: i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.AGENT_CLINET_PARTNER,
      field: "partnerIdNumber",
      valid: document.partnerIdNumber !== "",
      message: i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.AGENT_CLINET_PARTNER,
      field: "partnerBirthdate",
      valid: document.partnerBirthdate !== "",
      message: i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.AGENT_CLINET_PARTNER,
      field: "partnerHomeAddress",
      valid: document.partnerHomeAddress !== "",
      message: i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.AGENT_CLINET_PARTNER,
      field: "partnerEmail",
      valid: (() => {
        if (
          document.partnerEmail === document.clientEmail ||
          document.partnerEmail === document.pfaEmail ||
          document.clientEmail === document.pfaEmail
        ) {
          return false;
        }
        return document.partnerEmail !== "";
      })(),
      message: (() => {
        if (
          document.partnerEmail === document.clientEmail ||
          document.partnerEmail === document.pfaEmail ||
          document.clientEmail === document.pfaEmail
        ) {
          return i18next.t(
            "document.validation.common.clientEmailSameNotAsPartnerEmail"
          );
        }

        return i18next.t("document.validation.common.required");
      })(),
    },
    {
      section: ZUZDocumentSection.AGENT_CLINET_PARTNER,
      field: "partnerPhone",
      valid: document.partnerPhone !== "",
      message: i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.AGENT_CLINET_PARTNER,
      field: "partnerIdDisplayValue",
      valid: document.partnerIdDisplayValue !== "",
      message: i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.AGENT_CLINET_PARTNER,
      field: "partnerIdValidUntil",
      valid: (() => {
        const isExpired =
          document.partnerIdValidUntil !== "" &&
          parseFormatedDate(document.partnerIdValidUntil).getTime() <
            new Date().getTime();

        return document.partnerIdValidUntil !== "" && !isExpired;
      })(),
      message: (() => {
        if (document.partnerIdValidUntil === "") {
          return i18next.t("document.validation.common.required");
        }

        if (
          parseFormatedDate(document.partnerIdValidUntil).getTime() <
          new Date().getTime()
        ) {
          return i18next.t("document.validation.idValidUntil.expired");
        }

        return "";
      })(),
    },
    {
      section: ZUZDocumentSection.AGENT_CLINET_PARTNER,
      field: "partnerState",
      valid: document.partnerState !== "",
      message: i18next.t("document.validation.common.required"),
    },
  ];
};

const getIncomeRequiredFields = ({
  document,
  documentGetters,
}: RequiredFieldsArgs): Array<ZUZDocumentError> => {
  const incomeFields: Array<keyof DocumentContent> = [
    "sourceIncomeFromActivity",
    "sourceIncomeFromLowReserve",
    "sourceIncomeFromHighReserve",
    "sourceIncomeOther",
  ];

  const isAtleastOneIncomeOfClientChecked = (): boolean => {
    return incomeFields.some((field) => {
      const option = document[field] as ClientPartnerCheckboxOptions;
      return option.client;
    });
  };

  const isAtleastOneIncomeOfPartnerChecked = (): boolean => {
    return incomeFields.some((field) => {
      const option = document[field] as ClientPartnerCheckboxOptions;
      return option.partner;
    });
  };

  const partnerSelected = isPartnerSelected(document);

  return [
    {
      section: ZUZDocumentSection.AGENT_CLINET_PARTNER,
      field: "clientIncomeCheckboxes",
      valid: isAtleastOneIncomeOfClientChecked(),
      message: i18next.t("document.validation.client.atLeastOneChecked"),
    },
    {
      section: ZUZDocumentSection.AGENT_CLINET_PARTNER,
      field: "partnerIncomeCheckboxes",
      valid: !partnerSelected || isAtleastOneIncomeOfPartnerChecked(),
      message: i18next.t("document.validation.partner.atLeastOneChecked"),
    },
    {
      section: ZUZDocumentSection.AGENT_CLINET_PARTNER,
      field: "otherSourceIncomeValue",
      valid: (() => {
        const isIncomeOtherChecked =
          document.sourceIncomeOther.client ||
          document.sourceIncomeOther.partner;

        if (!isIncomeOtherChecked) {
          return true;
        }

        return document.otherSourceIncomeValue !== "";
      })(),
      message: i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.AGENT_CLINET_PARTNER,
      field: "clientDebtLoansDebt",
      valid: document.clientDebtLoansDebt !== "",
      message: i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.AGENT_CLINET_PARTNER,
      field: "partnerDebtLoansDebt",
      valid: (() => {
        if (partnerSelected) {
          return document.partnerDebtLoansDebt !== "";
        }
        return true;
      })(),
      message: i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.AGENT_CLINET_PARTNER,
      field: "clientRepaymentSellDebt",
      valid: document.clientRepaymentSellDebt !== "",
      message: i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.AGENT_CLINET_PARTNER,
      field: "partnerRepaymentSellDebt",
      valid: (() => {
        if (partnerSelected) {
          return document.partnerRepaymentSellDebt !== "";
        }
        return true;
      })(),
      message: i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.AGENT_CLINET_PARTNER,
      field: "clientLeasingDebt",
      valid: document.clientLeasingDebt !== "",
      message: i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.AGENT_CLINET_PARTNER,
      field: "partnerLeasingDebt",
      valid: (() => {
        if (partnerSelected) {
          return document.partnerLeasingDebt !== "";
        }
        return true;
      })(),
      message: i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.AGENT_CLINET_PARTNER,
      field: "clientOtherDebt",
      valid: document.clientOtherDebt !== "",
      message: i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.AGENT_CLINET_PARTNER,
      field: "partnerOtherDebt",
      valid: (() => {
        if (partnerSelected) {
          return document.partnerOtherDebt !== "";
        }
        return true;
      })(),
      message: i18next.t("document.validation.common.required"),
    },
  ];
};

const getDDSRequiredFields = ({
  document,
  documentGetters,
}: RequiredFieldsArgs): Array<ZUZDocumentError> => {
  return [
    {
      section: ZUZDocumentSection.DDS,
      field: "ddsClientEntryRequests",
      valid: (() => {
        if (!isPartnerSelected(document)) {
          return document.ddsClientEntryRequests !== "";
        }

        return (
          document.ddsClientEntryRequests !== "" ||
          document.ddsPartnerEntryRequests !== ""
        );
      })(),
      message: (() => {
        if (!isPartnerSelected(document)) {
          return i18next.t("document.validation.common.required");
        }

        return i18next.t("document.validation.common.atLeastOneFilled");
      })(),
    },
    {
      section: ZUZDocumentSection.DDS,
      field: "ddsPartnerEntryRequests",
      valid: (() => {
        if (!isPartnerSelected(document)) {
          return true;
        }

        return (
          document.ddsClientEntryRequests !== "" ||
          document.ddsPartnerEntryRequests !== ""
        );
      })(),
      message: i18next.t("document.validation.common.atLeastOneFilled"),
    },
    {
      section: ZUZDocumentSection.DDS,
      field: "ddsExperiencesClient",
      valid: (() => {
        const CPRV = Object.values(ClientPartnerRadioValues);
        const clientSelected = document?.ddsExperiences?.client
          ? CPRV.includes(document.ddsExperiences?.client)
          : false;
        const partnerSelected = document?.ddsExperiences?.partner
          ? CPRV.includes(document.ddsExperiences?.partner)
          : false;

        return (
          clientSelected || (partnerSelected && isPartnerSelected(document))
        );
      })(),
      message: isPartnerSelected(document)
        ? i18next.t("document.validation.common.requiredForClientOrPartner")
        : i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.DDS,
      field: "ddsExperiencesPartner",
      valid: (() => {
        const CPRV = Object.values(ClientPartnerRadioValues);
        const clientSelected = document?.ddsExperiences?.client
          ? CPRV.includes(document.ddsExperiences?.client)
          : false;
        const partnerSelected = document?.ddsExperiences?.partner
          ? CPRV.includes(document.ddsExperiences?.partner)
          : false;

        return (
          !isPartnerSelected(document) ||
          clientSelected ||
          (partnerSelected && isPartnerSelected(document))
        );
      })(),
      message: isPartnerSelected(document)
        ? i18next.t("document.validation.common.requiredForClientOrPartner")
        : i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.DDS,
      field: "ddsSavings",
      valid: (() => {
        return (
          document.ddsClientEstimateSavingTime !== "" ||
          document.ddsClientDue !== "" ||
          document.ddsClientEmployerDue !== "" ||
          document.ddsPartnerEstimateSavingTime !== "" ||
          document.ddsPartnerDue !== "" ||
          document.ddsPartnerEmployerDue !== ""
        );
      })(),
      message: i18next.t(
        "document.validation.common.atLeastOneFilledForClientOrPartner"
      ),
    },
  ];
};

const getSDSRequiredFields = ({
  document,
  documentGetters,
}: RequiredFieldsArgs): Array<ZUZDocumentError> => {
  return [
    {
      section: ZUZDocumentSection.SDS,
      field: "sdsClientEntryRequests",
      valid: (() => {
        if (!isPartnerSelected(document)) {
          return document.sdsClientEntryRequests !== "";
        }

        return (
          document.sdsClientEntryRequests !== "" ||
          document.sdsPartnerEntryRequests !== ""
        );
      })(),
      message: (() => {
        if (!isPartnerSelected(document)) {
          return i18next.t("document.validation.common.required");
        }

        return i18next.t("document.validation.common.atLeastOneFilled");
      })(),
    },
    {
      section: ZUZDocumentSection.SDS,
      field: "sdsPartnerEntryRequests",
      valid: (() => {
        if (!isPartnerSelected(document)) {
          return true;
        }

        return (
          document.sdsClientEntryRequests !== "" ||
          document.sdsPartnerEntryRequests !== ""
        );
      })(),
      message: i18next.t("document.validation.common.atLeastOneFilled"),
    },
    {
      section: ZUZDocumentSection.SDS,
      field: "sdsExperiencesClient",
      valid: (() => {
        const CPRV = Object.values(ClientPartnerRadioValues);
        const clientSelected = document?.sdsExperiences?.client
          ? CPRV.includes(document.sdsExperiences?.client)
          : false;
        const partnerSelected = document?.sdsExperiences?.partner
          ? CPRV.includes(document.sdsExperiences?.partner)
          : false;

        return (
          clientSelected || (partnerSelected && isPartnerSelected(document))
        );
      })(),
      message: isPartnerSelected(document)
        ? i18next.t("document.validation.common.requiredForClientOrPartner")
        : i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.SDS,
      field: "sdsExperiencesPartner",
      valid: (() => {
        const CPRV = Object.values(ClientPartnerRadioValues);
        const clientSelected = document?.sdsExperiences?.client
          ? CPRV.includes(document.sdsExperiences?.client)
          : false;
        const partnerSelected = document?.sdsExperiences?.partner
          ? CPRV.includes(document.sdsExperiences?.partner)
          : false;

        return (
          !isPartnerSelected(document) ||
          clientSelected ||
          (partnerSelected && isPartnerSelected(document))
        );
      })(),
      message: isPartnerSelected(document)
        ? i18next.t("document.validation.common.requiredForClientOrPartner")
        : i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.SDS,
      field: "sdsVoluntaryDuesAmount",
      valid: (() => {
        if (
          document.SDSWantVoluntaryDues.client ||
          document.SDSWantVoluntaryDues.partner
        ) {
          return document.sdsVoluntaryDuesAmount !== "";
        }

        return true;
      })(),
      message: i18next.t("document.validation.common.required"),
    },
  ];
};

const getInsuranceRequiredFields = ({
  document,
  documentGetters,
}: RequiredFieldsArgs): Array<ZUZDocumentError> => {
  return [
    {
      section: ZUZDocumentSection.INSURANCE,
      field: "insuranceClientEntryRequests",
      valid: (() => {
        if (!isPartnerSelected(document)) {
          return document.insuranceClientEntryRequests !== "";
        }

        return (
          document.insuranceClientEntryRequests !== "" ||
          document.insurancePartnerEntryRequests !== ""
        );
      })(),
      message: (() => {
        if (!isPartnerSelected(document)) {
          return i18next.t("document.validation.common.required");
        }

        return i18next.t("document.validation.common.atLeastOneFilled");
      })(),
    },
    {
      section: ZUZDocumentSection.INSURANCE,
      field: "insurancePartnerEntryRequests",
      valid: (() => {
        if (!isPartnerSelected(document)) {
          return true;
        }

        return (
          document.insuranceClientEntryRequests !== "" ||
          document.insurancePartnerEntryRequests !== ""
        );
      })(),
      message: i18next.t("document.validation.common.atLeastOneFilled"),
    },
    {
      section: ZUZDocumentSection.INSURANCE,
      field: "insuranceExperiencesClient",
      valid: (() => {
        const CPRV = Object.values(ClientPartnerRadioValues);
        const clientSelected = document?.insuranceExperiences?.client
          ? CPRV.includes(document.insuranceExperiences?.client)
          : false;

        const partnerSelected = document?.insuranceExperiences?.partner
          ? CPRV.includes(document.insuranceExperiences?.partner)
          : false;

        return (
          clientSelected || (partnerSelected && isPartnerSelected(document))
        );
      })(),
      message: isPartnerSelected(document)
        ? i18next.t("document.validation.common.requiredForClientOrPartner")
        : i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.INSURANCE,
      field: "insuranceExperiencesPartner",
      valid: (() => {
        const CPRV = Object.values(ClientPartnerRadioValues);
        const clientSelected = document?.insuranceExperiences?.client
          ? CPRV.includes(document.insuranceExperiences?.client)
          : false;

        const partnerSelected = document?.insuranceExperiences?.partner
          ? CPRV.includes(document.insuranceExperiences?.partner)
          : false;

        return (
          !isPartnerSelected(document) ||
          clientSelected ||
          (partnerSelected && isPartnerSelected(document))
        );
      })(),
      message: i18next.t(
        "document.validation.common.requiredForClientOrPartner"
      ),
    },
    {
      section: ZUZDocumentSection.INSURANCE,
      field: "insurancePurposeOtherValue",
      valid: (() => {
        if (
          document.insurancePurposeOther.client ||
          document.insurancePurposeOther.partner
        ) {
          return document.insurancePurposeOtherValue !== "";
        }

        return true;
      })(),
      message: i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.INSURANCE,
      field: "insuranceRiskOtherValue",
      valid: (() => {
        if (
          document.insuranceRiskOther.client ||
          document.insuranceRiskOther.partner
        ) {
          return document.insuranceRiskOtherValue !== "";
        }

        return true;
      })(),
      message: i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.INSURANCE,
      field: "insurancePropertyOtherValue",
      valid: (() => {
        if (
          document.insurancePropertyOther.client ||
          document.insurancePropertyOther.partner
        ) {
          return document.insurancePropertyOtherValue !== "";
        }

        return true;
      })(),
      message: i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.INSURANCE,
      field: "insuranceLiabilityOtherValue",
      valid: (() => {
        if (
          document.insuranceLiabilityOther.client ||
          document.insuranceLiabilityOther.partner
        ) {
          return document.insuranceLiabilityOtherValue !== "";
        }

        return true;
      })(),
      message: i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.INSURANCE,
      field: "insuranceLegalSafetyOtherValue",
      valid: (() => {
        if (
          document.insuranceLegalSafetyOther.client ||
          document.insuranceLegalSafetyOther.partner
        ) {
          return document.insuranceLegalSafetyOtherValue !== "";
        }

        return true;
      })(),
      message: i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.INSURANCE,
      field: "insuranceOtherValue",
      valid: (() => {
        if (document.insuranceOther.client || document.insuranceOther.partner) {
          return document.insuranceOtherValue !== "";
        }

        return true;
      })(),
      message: i18next.t("document.validation.common.required"),
    },
  ];
};

const getFinancialIncomeRequiredFields = ({
  document,
  documentGetters,
}: RequiredFieldsArgs): Array<ZUZDocumentError> => {
  return [
    {
      section: ZUZDocumentSection.INCOME,
      field: "financeDepositClientEntryRequests",
      valid: (() => {
        if (!isPartnerSelected(document)) {
          return document.financeDepositClientEntryRequests !== "";
        }

        return (
          document.financeDepositClientEntryRequests !== "" ||
          document.financeDepositPartnerEntryRequests !== ""
        );
      })(),
      message: (() => {
        if (!isPartnerSelected(document)) {
          return i18next.t("document.validation.common.required");
        }

        return i18next.t("document.validation.common.atLeastOneFilled");
      })(),
    },
    {
      section: ZUZDocumentSection.INCOME,
      field: "financeDepositPartnerEntryRequests",
      valid: (() => {
        if (!isPartnerSelected(document)) {
          return true;
        }

        return (
          document.financeDepositClientEntryRequests !== "" ||
          document.financeDepositPartnerEntryRequests !== ""
        );
      })(),
      message: i18next.t("document.validation.common.atLeastOneFilled"),
    },
    {
      section: ZUZDocumentSection.INCOME,
      field: "bankDepositExperiencesClient",
      valid: (() => {
        const CPRV = Object.values(ClientPartnerRadioValues);
        const clientSelected = document?.bankDepositExperiences?.client
          ? CPRV.includes(document.bankDepositExperiences?.client)
          : false;
        const partnerSelected = document?.bankDepositExperiences?.partner
          ? CPRV.includes(document.bankDepositExperiences?.partner)
          : false;

        return (
          clientSelected || (partnerSelected && isPartnerSelected(document))
        );
      })(),
      message: isPartnerSelected(document)
        ? i18next.t("document.validation.common.requiredForClientOrPartner")
        : i18next.t("document.validation.client.required"),
    },
    {
      section: ZUZDocumentSection.INCOME,
      field: "bankDepositExperiencesPartner",
      valid: (() => {
        const CPRV = Object.values(ClientPartnerRadioValues);
        const clientSelected = document?.bankDepositExperiences?.client
          ? CPRV.includes(document.bankDepositExperiences?.client)
          : false;
        const partnerSelected = document?.bankDepositExperiences?.partner
          ? CPRV.includes(document.bankDepositExperiences?.partner)
          : false;

        return (
          !isPartnerSelected(document) ||
          clientSelected ||
          (partnerSelected && isPartnerSelected(document))
        );
      })(),
      message: isPartnerSelected(document)
        ? i18next.t("document.validation.common.requiredForClientOrPartner")
        : i18next.t("document.validation.partner.required"),
    },
  ];
};

const getLoanRequiredFields = ({
  document,
  documentGetters,
}: RequiredFieldsArgs): Array<ZUZDocumentError> => {
  return [
    {
      section: ZUZDocumentSection.CREDIT,
      field: "financeLoansClientEntryRequests",
      valid: (() => {
        if (!isPartnerSelected(document)) {
          return document.financeLoansClientEntryRequests !== "";
        }

        return (
          document.financeLoansClientEntryRequests !== "" ||
          document.financeLoansPartnerEntryRequests !== ""
        );
      })(),
      message: (() => {
        if (!isPartnerSelected(document)) {
          return i18next.t("document.validation.common.required");
        }

        return i18next.t("document.validation.common.atLeastOneFilled");
      })(),
    },
    {
      section: ZUZDocumentSection.CREDIT,
      field: "financeLoansPartnerEntryRequests",
      valid: (() => {
        if (!isPartnerSelected(document)) {
          return true;
        }

        return (
          document.financeLoansClientEntryRequests !== "" ||
          document.financeLoansPartnerEntryRequests !== ""
        );
      })(),
      message: i18next.t("document.validation.common.atLeastOneFilled"),
    },
    {
      section: ZUZDocumentSection.CREDIT,
      field: "loanExperiencesClient",
      valid: (() => {
        const CPRV = Object.values(ClientPartnerRadioValues);
        const clientSelected = document?.loanExperiences?.client
          ? CPRV.includes(document.loanExperiences?.client)
          : false;

        const partnerSelected = document?.loanExperiences?.partner
          ? CPRV.includes(document.loanExperiences?.partner)
          : false;

        return (
          clientSelected || (partnerSelected && isPartnerSelected(document))
        );
      })(),
      message: isPartnerSelected(document)
        ? i18next.t("document.validation.common.requiredForClientOrPartner")
        : i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.CREDIT,
      field: "loanExperiencesPartner",
      valid: (() => {
        const CPRV = Object.values(ClientPartnerRadioValues);
        const clientSelected = document?.loanExperiences?.client
          ? CPRV.includes(document.loanExperiences?.client)
          : false;

        const partnerSelected = document?.loanExperiences?.partner
          ? CPRV.includes(document.loanExperiences?.partner)
          : false;

        return (
          !isPartnerSelected(document) ||
          clientSelected ||
          (partnerSelected && isPartnerSelected(document))
        );
      })(),
      message: isPartnerSelected(document)
        ? i18next.t("document.validation.common.requiredForClientOrPartner")
        : i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.CREDIT,
      field: "loanPurposeClient",
      valid: (() => {
        const loanCheckboxes: Array<keyof DocumentContent> = [
          "loanPurposeRealEstate",
          "loanPurposeMovableProperty",
          "loanPurposeRefinanceOtherLoan",
          "loanPurposeOther",
        ];

        return isPartnerSelected(document)
          ? loanCheckboxes.some((checkbox) => {
              const options = document[
                checkbox
              ] as ClientPartnerCheckboxOptions;
              return options.client || options.partner;
            })
          : loanCheckboxes.some((checkbox) => {
              const options = document[
                checkbox
              ] as ClientPartnerCheckboxOptions;
              return options.client;
            });
      })(),
      message: isPartnerSelected(document)
        ? ""
        : i18next.t("document.validation.client.atLeastOneChecked"),
    },
    {
      section: ZUZDocumentSection.CREDIT,
      field: "loanPurposePartner",
      valid: (() => {
        if (!isPartnerSelected(document)) {
          return true;
        }

        const loanCheckboxes: Array<keyof DocumentContent> = [
          "loanPurposeRealEstate",
          "loanPurposeMovableProperty",
          "loanPurposeRefinanceOtherLoan",
          "loanPurposeOther",
        ];

        return loanCheckboxes.some((checkbox) => {
          const options = document[checkbox] as ClientPartnerCheckboxOptions;
          return options.partner || options.client;
        });
      })(),
      message: i18next.t(
        "document.validation.common.atLeastOneCheckedForClientOrPartner"
      ),
    },
    {
      section: ZUZDocumentSection.CREDIT,
      field: "loanSecuringClient",
      valid: (() => {
        const loanCheckboxes: Array<keyof DocumentContent> = [
          "loanSecuringRealEstate",
          "loanSecuringMovableProperty",
          "loanSecuringLiability",
          "loanSecuringCodebtor",
          "loanSecuringDebtOtherType",
        ];

        return isPartnerSelected(document)
          ? loanCheckboxes.some((checkbox) => {
              const options = document[
                checkbox
              ] as ClientPartnerCheckboxOptions;
              return options.client || options.partner;
            })
          : loanCheckboxes.some((checkbox) => {
              const options = document[
                checkbox
              ] as ClientPartnerCheckboxOptions;

              return options.client;
            });
      })(),
      message: isPartnerSelected(document)
        ? ""
        : i18next.t("document.validation.client.atLeastOneChecked"),
    },
    {
      section: ZUZDocumentSection.CREDIT,
      field: "loanRefinanceOtherLoanAmountClient",
      valid: (() => {
        if (!document.loanPurposeRefinanceOtherLoan.client) {
          return true;
        }

        return document.loanRefinanceOtherLoanAmountClient !== "";
      })(),
      message: i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.CREDIT,
      field: "loanRefinanceOtherLoanAmountPartner",
      valid: (() => {
        if (!isPartnerSelected(document)) {
          return true;
        }

        if (!document.loanPurposeRefinanceOtherLoan.partner) {
          return true;
        }

        return document.loanRefinanceOtherLoanAmountPartner !== "";
      })(),
      message: i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.CREDIT,
      field: "loanPurposeOtherAmountClient",
      valid: (() => {
        if (!document.loanPurposeOther.client) {
          return true;
        }

        return document.loanPurposeOtherAmountClient !== "";
      })(),
      message: i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.CREDIT,
      field: "loanPurposeOtherAmountPartner",
      valid: (() => {
        if (!isPartnerSelected(document)) {
          return true;
        }

        if (!document.loanPurposeOther.partner) {
          return true;
        }

        return document.loanPurposeOtherAmountPartner !== "";
      })(),
      message: i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.CREDIT,
      field: "loanSecuringPartner",
      valid: (() => {
        if (!isPartnerSelected(document)) {
          return true;
        }

        const loanCheckboxes: Array<keyof DocumentContent> = [
          "loanSecuringRealEstate",
          "loanSecuringMovableProperty",
          "loanSecuringLiability",
          "loanSecuringCodebtor",
          "loanSecuringDebtOtherType",
        ];

        return loanCheckboxes.some((checkbox) => {
          const options = document[checkbox] as ClientPartnerCheckboxOptions;
          return options.partner || options.client;
        });
      })(),
      message: i18next.t(
        "document.validation.common.atLeastOneCheckedForClientOrPartner"
      ),
    },
    {
      section: ZUZDocumentSection.CREDIT,
      field: "loanSecuringMovablePropertyAmountClient",
      valid: (() => {
        if (!document.loanSecuringMovableProperty.client) {
          return true;
        }

        return document.loanSecuringMovablePropertyAmountClient !== "";
      })(),
      message: i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.CREDIT,
      field: "loanSecuringMovablePropertyAmountPartner",
      valid: (() => {
        if (!isPartnerSelected(document)) {
          return true;
        }

        if (!document.loanSecuringMovableProperty.partner) {
          return true;
        }

        return document.loanSecuringMovablePropertyAmountPartner !== "";
      })(),
      message: i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.CREDIT,
      field: "loanSecuringOtherTypeClientValue",
      valid: (() => {
        if (!document.loanSecuringDebtOtherType.client) {
          return true;
        }

        return document.loanSecuringOtherTypeClientValue !== "";
      })(),
      message: i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.CREDIT,
      field: "loanSecuringOtherTypePartnerValue",
      valid: (() => {
        if (!isPartnerSelected(document)) {
          return true;
        }

        if (!document.loanSecuringDebtOtherType.partner) {
          return true;
        }

        return document.loanSecuringOtherTypePartnerValue !== "";
      })(),
      message: i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.CREDIT,
      field: "loanAmountOfCreditRequired",
      valid: document.loanAmountOfCreditRequired !== "",
      message: i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.CREDIT,
      field: "loanExpectedMaturity",
      valid: document.loanExpectedMaturity !== "",
      message: i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.CREDIT,
      field: "loanMaximumMonthlyPaymentAmount",
      valid: document.loanMaximumMonthlyPaymentAmount !== "",
      message: i18next.t("document.validation.common.required"),
    },
  ];
};

const getCapitalMarketRequiredFields = ({
  document,
  documentGetters,
}: RequiredFieldsArgs): Array<ZUZDocumentError> => {
  return [
    {
      section: ZUZDocumentSection.CAPITAL_MARKET,
      field: "capitalMarketClientEntryRequests",
      valid: (() => {
        if (!isPartnerSelected(document)) {
          return document.capitalMarketClientEntryRequests !== "";
        }

        return (
          document.capitalMarketClientEntryRequests !== "" ||
          document.capitalMarketPartnerEntryRequests !== ""
        );
      })(),
      message: (() => {
        if (!isPartnerSelected(document)) {
          return i18next.t("document.validation.common.required");
        }

        return i18next.t("document.validation.common.atLeastOneFilled");
      })(),
    },
    {
      section: ZUZDocumentSection.CAPITAL_MARKET,
      field: "capitalMarketPartnerEntryRequests",
      valid: (() => {
        if (!isPartnerSelected(document)) {
          return true;
        }

        return (
          document.capitalMarketClientEntryRequests !== "" ||
          document.capitalMarketPartnerEntryRequests !== ""
        );
      })(),
      message: i18next.t("document.validation.common.atLeastOneFilled"),
    },
    {
      section: ZUZDocumentSection.CAPITAL_MARKET,
      field: "capitalInvestmentOrdersDate",
      valid: (() => {
        const isAnyValueFilled = document.tableInvestmentOrders.some((row) => {
          return row.some((value) => {
            return value !== "";
          });
        });

        if (isAnyValueFilled) {
          return document.capitalInvestmentOrdersDate !== null;
        }

        return true;
      })(),
      message: i18next.t("document.validation.common.required"),
    },
  ];
};

const getInvestQuestionnaireRequiredFields = ({
  document,
  documentGetters,
}: RequiredFieldsArgs): Array<ZUZDocumentError> => {
  const sumPreferences = (field: "client" | "partner") => {
    const pref = document.questionaire?.esgPreferences[field];

    return Object.values(pref ?? {}).reduce((acc, curr) => {
      if (curr.checked) {
        if (!curr.percentage) return acc; // if percentage is not set, return acc
        return acc + Number(curr.percentage);
      }
      return acc;
    }, 0);
  };

  const isESGInterestChecked = (field: "client" | "partner") => {
    const pref = document.questionaire?.interestInEsg[field];
    return pref?.includes(EsgInterest.INTEREST);
  };

  const anyOfPreferenceChecked = (field: "client" | "partner") => {
    const pref = document.questionaire?.esgPreferences[field];
    return Object.values(pref ?? {}).some((value) => value.checked);
  };

  const isAllFilled = (
    intentions: Array<InvestmentIntentionOnce | InvestmentIntentionRepeated>
  ) => {
    return intentions.every(
      (item) =>
        !["", undefined].includes(item.description) &&
        !["", undefined].includes(item.achivable)
    );
  };

  const clientToolsList = [
    document.questionaire?.financialToolsExperience?.client?.bond,
    document.questionaire?.financialToolsExperience?.client?.stock,
    document.questionaire?.financialToolsExperience?.client?.mutualFund,
    document.questionaire?.financialToolsExperience?.client?.etf,
    document.questionaire?.financialToolsExperience?.client?.derivative,
  ];

  const clinetPartnerToolsList = [
    ...clientToolsList,
    document.questionaire?.financialToolsExperience?.partner?.bond,
    document.questionaire?.financialToolsExperience?.partner?.stock,
    document.questionaire?.financialToolsExperience?.partner?.mutualFund,
    document.questionaire?.financialToolsExperience?.partner?.etf,
    document.questionaire?.financialToolsExperience?.partner?.derivative,
  ];

  return [
    {
      section: ZUZDocumentSection.INVEST_QUESTIONNAIRE,
      field: "clientInvestitionType",
      valid: (() => {
        if (!isPartnerSelected(document)) {
          return (
            document.questionaire.investitionType.client !== undefined &&
            document.questionaire.investitionType.client !== ""
          );
        }
        return (
          (document.questionaire.investitionType.client !== undefined &&
            document.questionaire.investitionType.client !== "") ||
          (document.questionaire.investitionType.partner !== undefined &&
            document.questionaire.investitionType.partner !== "")
        );
      })(),
      message: (() => {
        if (!isPartnerSelected(document)) {
          return i18next.t("document.validation.common.required");
        }

        return i18next.t("document.validation.common.atLeastOneFilled");
      })(),
    },
    {
      section: ZUZDocumentSection.INVEST_QUESTIONNAIRE,
      field: "partnerInvestitionType",
      valid: (() => {
        if (!isPartnerSelected(document)) {
          return true;
        }

        return (
          (document.questionaire.investitionType.client !== undefined &&
            document.questionaire.investitionType.client !== "") ||
          (document.questionaire.investitionType.partner !== "" &&
            document.questionaire.investitionType.partner !== undefined)
        );
      })(),
      message: (() => {
        return i18next.t(
          "document.validation.common.atLeastOneCheckedForClientOrPartner"
        );
      })(),
    },
    {
      section: ZUZDocumentSection.INVEST_QUESTIONNAIRE,
      field: "clientReactionToLoss",
      valid: (() => {
        if (!isPartnerSelected(document)) {
          return (
            document.questionaire.reactionToLoss.client !== undefined &&
            document.questionaire.reactionToLoss.client !== ""
          );
        }

        return (
          (document.questionaire.reactionToLoss.client !== undefined &&
            document.questionaire.reactionToLoss.client !== "") ||
          (document.questionaire.reactionToLoss.partner !== undefined &&
            document.questionaire.reactionToLoss.partner !== "")
        );
      })(),
      message: (() => {
        if (!isPartnerSelected(document)) {
          return i18next.t("document.validation.common.required");
        }

        return i18next.t("document.validation.common.atLeastOneFilled");
      })(),
    },
    {
      section: ZUZDocumentSection.INVEST_QUESTIONNAIRE,
      field: "partnerReactionToLoss",
      valid: (() => {
        if (!isPartnerSelected(document)) {
          return true;
        }

        return (
          (document.questionaire.reactionToLoss.client !== undefined &&
            document.questionaire.reactionToLoss.client !== "") ||
          (document.questionaire.reactionToLoss.partner !== "" &&
            document.questionaire.reactionToLoss.partner !== undefined)
        );
      })(),
      message: (() => {
        return i18next.t("document.validation.common.atLeastOneFilled");
      })(),
    },
    {
      section: ZUZDocumentSection.INVEST_QUESTIONNAIRE,
      field: "clientFinancialToolsExperience",
      valid: (() => {
        if (isPartnerSelected(document)) {
          return true;
        }

        return clientToolsList.some((value) => value);
      })(),
      message: (() => {
        if (isPartnerSelected(document)) {
          return "";
        }

        return i18next.t("document.validation.client.required");
      })(),
    },
    {
      section: ZUZDocumentSection.INVEST_QUESTIONNAIRE,
      field: "partnerFinancialToolsExperience",
      valid: (() => {
        if (!isPartnerSelected(document)) {
          return true;
        }

        return clinetPartnerToolsList.some((value) => value);
      })(),
      message: (() => {
        if (!isPartnerSelected(document)) {
          return "";
        }

        return i18next.t(
          "document.validation.common.atLeastOneCheckedForClientOrPartner"
        );
      })(),
    },
    {
      section: ZUZDocumentSection.INVEST_QUESTIONNAIRE,
      field: "clientEsgInterest",
      valid: (() => {
        if (isPartnerSelected(document)) {
          return true;
        }

        return (
          (document.questionaire.interestInEsg.client?.includes(
            EsgInterest.INTEREST
          ) ||
            document.questionaire.interestInEsg.client?.includes(
              EsgInterest.NO_INTEREST
            )) ??
          false
        );
      })(),
      message: (() => {
        if (isPartnerSelected(document)) {
          return "";
        }

        return i18next.t("document.validation.client.required");
      })(),
    },
    {
      section: ZUZDocumentSection.INVEST_QUESTIONNAIRE,
      field: "partnerEsgInterest",
      valid: (() => {
        if (!isPartnerSelected(document)) {
          return true;
        }

        return (
          document.questionaire.interestInEsg.client?.length !== 0 ||
          document.questionaire.interestInEsg.partner?.length !== 0
        );
      })(),
      message: (() => {
        if (!isPartnerSelected(document)) {
          return "";
        }

        return i18next.t(
          "document.validation.common.requiredForClientOrPartner"
        );
      })(),
    },
    {
      section: ZUZDocumentSection.INVEST_QUESTIONNAIRE,
      field: "clientEsgPreference",
      valid: (() => {
        if (!isESGInterestChecked("client")) {
          return true;
        }

        if (isPartnerSelected(document)) {
          return true;
        }

        return anyOfPreferenceChecked("client");
      })(),
      message: (() => {
        if (isPartnerSelected(document)) {
          return "";
        }

        return i18next.t("document.validation.client.atLeastOneChecked");
      })(),
    },
    {
      section: ZUZDocumentSection.INVEST_QUESTIONNAIRE,
      field: "partnerEsgPreference",
      valid: (() => {
        if (
          !isESGInterestChecked("client") &&
          !isESGInterestChecked("partner")
        ) {
          return true;
        }

        if (!isPartnerSelected(document)) {
          return true;
        }

        return (
          anyOfPreferenceChecked("client") || anyOfPreferenceChecked("partner")
        );
      })(),
      message: (() => {
        return i18next.t(
          "document.validation.common.atLeastOneCheckedForClientOrPartner"
        );
      })(),
    },
    {
      section: ZUZDocumentSection.INVEST_QUESTIONNAIRE,
      field: "clientSustainabilityRequest",
      valid: (() => {
        if (isPartnerSelected(document)) {
          return true;
        }

        if (!isESGInterestChecked("client")) {
          return true;
        }

        return (
          (document.questionaire.sustainabilityRequest?.client?.includes(
            SustainabiltyRequest.ALL_THE_SAME_TIME
          ) ||
            document.questionaire.sustainabilityRequest?.client?.includes(
              SustainabiltyRequest.ATLEAST_ONE
            )) ??
          false
        );
      })(),
      message: (() => {
        if (isPartnerSelected(document)) {
          return "";
        }

        return i18next.t("document.validation.client.required");
      })(),
    },
    {
      section: ZUZDocumentSection.INVEST_QUESTIONNAIRE,
      field: "partnerSustainabilityRequest",
      valid: (() => {
        if (!isPartnerSelected(document)) {
          return true;
        }

        if (
          !isESGInterestChecked("client") &&
          !isESGInterestChecked("partner")
        ) {
          return true;
        }

        return (
          (document.questionaire.sustainabilityRequest.client?.includes(
            SustainabiltyRequest.ALL_THE_SAME_TIME
          ) ||
            document.questionaire.sustainabilityRequest.partner?.includes(
              SustainabiltyRequest.ALL_THE_SAME_TIME
            ) ||
            document.questionaire.sustainabilityRequest.client?.includes(
              SustainabiltyRequest.ATLEAST_ONE
            ) ||
            document.questionaire.sustainabilityRequest.partner?.includes(
              SustainabiltyRequest.ATLEAST_ONE
            )) ??
          false
        );
      })(),
      message: (() => {
        if (!isPartnerSelected(document)) {
          return "";
        }

        return i18next.t(
          "document.validation.common.requiredForClientOrPartner"
        );
      })(),
    },
    {
      section: ZUZDocumentSection.INVEST_QUESTIONNAIRE,
      field: "investmentIntentionsOnce",
      valid: (() => {
        return isAllFilled(document.questionaire?.investmentIntentionsOnce);
      })(),
      message: i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.INVEST_QUESTIONNAIRE,
      field: "investmentIntentionsRepeated",
      valid: (() => {
        return isAllFilled(document.questionaire?.investmentIntentionsRepeated);
      })(),

      message: i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.INVEST_QUESTIONNAIRE,
      field: "esgPreferencesClientPercentage",
      valid: (() => {
        if (!isESGInterestChecked("client")) {
          return true;
        }

        return (
          !anyOfPreferenceChecked("client") || sumPreferences("client") === 100
        );
      })(),
      message: "Kontrolný súčet musí byť 100%",
    },
    {
      section: ZUZDocumentSection.INVEST_QUESTIONNAIRE,
      field: "esgPreferencesPartnerPercentage",
      valid: (() => {
        if (!isESGInterestChecked("client")) {
          return true;
        }
        return (
          !anyOfPreferenceChecked("partner") ||
          sumPreferences("partner") === 100
        );
      })(),
      message: "Kontrolný súčet musí byť 100%",
    },
  ];
};

const getInvestmentIntentionTableErrors = (
  document: DocumentContent
): Array<InvestmentIntentionError> => {
  const validate = (
    item: InvestmentIntentionOnce | InvestmentIntentionRepeated
  ) => {
    const error: InvestmentIntentionError = {
      frontendID: item.frontendID,
    };

    if (item.description === "" || item.description === undefined) {
      error.description =
        i18next.t("document.validation.common.required") ?? "";
    }

    if (item.achivable === "" || item.achivable === undefined) {
      error.achivable = i18next.t("document.validation.common.required") ?? "";
    }

    return error;
  };

  let resutlt: Array<InvestmentIntentionError> = [];

  if (document?.questionaire?.investmentIntentionsOnce.length > 0) {
    resutlt = [
      ...resutlt,
      ...document.questionaire.investmentIntentionsOnce
        .map((item: InvestmentIntentionOnce, index) => validate(item))
        .filter((item) => item !== undefined),
    ];
  }

  if (document?.questionaire?.investmentIntentionsRepeated.length > 0) {
    resutlt = [
      ...resutlt,
      ...document.questionaire.investmentIntentionsRepeated
        .map((item: InvestmentIntentionRepeated, index) => validate(item))
        .filter((item) => item !== undefined),
    ];
  }

  return resutlt;
};

const getSolutionsRequiredFields = ({
  document,
  documentGetters,
}: RequiredFieldsArgs): Array<ZUZDocumentError> => {
  const firstTableRow = document.pfaRecomendationsTable[0];
  return [
    {
      section: ZUZDocumentSection.SOLUTIONS,
      field: "declarationsOfSuitabilityChecks",
      valid: !!document.declarationsOfSuitabilityChecks,
      message: "Jedno pole musí byť vyznačené",
    },
    {
      section: ZUZDocumentSection.SOLUTIONS,
      field: "pfaRecomendationsTable[0]",
      valid: (() => {
        return firstTableRow?.[0] !== undefined && firstTableRow?.[0] !== "";
      })(),
      message: (() => {
        return firstTableRow?.[0] !== undefined && firstTableRow?.[0] !== ""
          ? ""
          : "Toto pole je povinné";
      })(),
    },
    {
      section: ZUZDocumentSection.SOLUTIONS,
      field: "pfaRecomendationsTable[1]",
      valid: (() => {
        return firstTableRow?.[1] !== undefined && firstTableRow?.[1] !== "";
      })(),
      message: (() => {
        return firstTableRow?.[1] !== undefined && firstTableRow?.[1] !== ""
          ? ""
          : "Toto pole je povinné";
      })(),
    },
    {
      section: ZUZDocumentSection.SOLUTIONS,
      field: "pfaRecomendationsTable[2]",
      valid: (() => {
        return (
          (firstTableRow?.[2] !== undefined && firstTableRow?.[2] !== "") ||
          (firstTableRow?.[3] !== undefined && firstTableRow?.[3] !== "")
        );
      })(),
      message: (() => {
        return (firstTableRow?.[2] !== undefined &&
          firstTableRow?.[2] !== "") ||
          (firstTableRow?.[3] !== undefined && firstTableRow?.[3] !== "")
          ? ""
          : "Aspoň jedno pole je povinné";
      })(),
    },
    {
      section: ZUZDocumentSection.SOLUTIONS,
      field: "pfaRecomendationsTable[3]",
      valid: (() => {
        return (
          (firstTableRow?.[2] !== undefined && firstTableRow?.[2] !== "") ||
          (firstTableRow?.[3] !== undefined && firstTableRow?.[3] !== "")
        );
      })(),
      message: (() => {
        return (firstTableRow?.[2] !== undefined &&
          firstTableRow?.[2] !== "") ||
          (firstTableRow?.[3] !== undefined && firstTableRow?.[3] !== "")
          ? ""
          : "Aspoň jedno pole je povinné";
      })(),
    },
  ];
};

const getConsentRequiredFields = ({
  document,
  documentGetters,
}: RequiredFieldsArgs): Array<ZUZDocumentError> => {
  const partnerSelected = isPartnerSelected(document);
  return [
    {
      section: ZUZDocumentSection.CONSENT,
      field: "clientPrivacyPolicy",
      valid: document.clientPrivacyPolicy.some((item) => item.checked === true),
      message: i18next.t("document.validation.common.required"),
    },
    {
      section: ZUZDocumentSection.CONSENT,
      field: "partnerPrivacyPolicy",
      valid: (() => {
        if (partnerSelected) {
          return document.partnerPrivacyPolicy.some(
            (item) => item.checked === true
          );
        }
        return true;
      })(),
      message: i18next.t("document.validation.common.required"),
    },
  ];
};

export const requiredFiledsMap: {
  [kein in string]: (args: RequiredFieldsArgs) => Array<ZUZDocumentError>;
} = {
  getSignatureRequiredFields,
  getClientRequiredFields,
  getPartnerRequiredFields,
  getIncomeRequiredFields,
  getDDSRequiredFields,
  getSDSRequiredFields,
  getInsuranceRequiredFields,
  getFinancialIncomeRequiredFields,
  getLoanRequiredFields,
  getCapitalMarketRequiredFields,
  getInvestQuestionnaireRequiredFields,
  getSolutionsRequiredFields,
  getConsentRequiredFields,
};

export type requiredFieldsMapType = typeof requiredFiledsMap;

const useDocumentValidation = () => {
  const [errors, setErrors] = useState<Array<ZUZDocumentError>>([]);
  const documentGetters = useDocumentGetters();

  const preValidate = (document: DocumentContent) => {
    let fields: Array<ZUZDocumentError> = [];

    Object.keys(requiredFiledsMap).forEach((section) => {
      const sectionFields = requiredFiledsMap[section]({
        document,
        documentGetters,
      });
      fields = [...fields, ...sectionFields];
    });

    return fields;
  };

  const validateDocumentCustomers = (
    customers: Customer[] | undefined
  ): string[] => {
    if (!customers) {
      return [];
    }

    const errorMessages: string[] = [];

    customers.forEach((customer: Customer) => {
      const phoneNumberTest = customer?.phoneNumber?.match(/\s+/g);
      const emailTest = customer?.email?.match(/\s+/g);
      const email2Test = customer?.email?.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);

      if (customer.phoneNumber === "" || phoneNumberTest) {
        errorMessages.push(
          `${t("document.validationCustomer.phone.false")} : ${
            customer.firstname
          } ${customer.lastname}`
        );
      }
      if (customer.email === "" || emailTest || !email2Test) {
        errorMessages.push(
          `${t("document.validationCustomer.email.false")} : ${
            customer.firstname
          } ${customer.lastname}`
        );
      }
    });
    return errorMessages;
  };

  const validate = (
    document: DocumentContent,
    disabledSections: Array<ZUZDocumentSection>
  ) => {
    const fields = preValidate(document);
    const fitlered = fields
      // filter all disabled sections
      .filter((f) => !(disabledSections ?? []).includes(f.section))

      // filter all valid fields
      .filter((f) => !f.valid);

    setErrors(fitlered);

    return fitlered;
  };

  const resetFieldError = (name: string) => {
    setErrors(errors.filter((error) => error.field !== name));
  };

  const resetFieldsErrors = (names: Array<string>) => {
    setErrors(errors.filter((error) => names.indexOf(error.field) === -1));
  };

  const getSectionErrors = (
    section: ZUZDocumentSection
  ): Array<ZUZDocumentError> => {
    return errors.filter((error) => error.section === section);
  };

  const getFieldError = (name: string): ZUZDocumentError | undefined => {
    return errors.find((error) => error.field === name);
  };

  return {
    errors,
    setErrors,
    getSectionErrors,
    validate,
    preValidate,
    resetFieldError,
    resetFieldsErrors,
    getFieldError,
    getInvestmentIntentionTableErrors,
    validateDocumentCustomers,
  };
};

export default useDocumentValidation;
