import React, { FormEvent, useState } from "react";

import { faList, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { useGetUsersQuery } from "../../api/users.generated";
import Alert from "../../components/alerts/Alert";
import CountBadge from "../../components/badges/CountBadge";
import EmailBadge from "../../components/badges/EmailBadge";
import IDBadge from "../../components/badges/IDBadge";
import UserStatusBadge from "../../components/badges/UserStatusBadge";
import Card from "../../components/card/Card";
import CardBody from "../../components/card/CardBody";
import CardFooter from "../../components/card/CardFooter";
import CardHeader from "../../components/card/CardHeader";
import PageHeader from "../../components/page/PageHeader";
import useFormatters from "../../hooks/formatter";
import { getRoleId } from "../../hooks/role";
import { makeRoute } from "../../hooks/route";
import { useUserQuery } from "../../hooks/user";
import AllRoutes from "../route/Route";
import TableBodyLoading from "../table/TableBodyLoading";
import TableColumnHeadingButton from "../table/TableColumnHeadingButton";
import TablePageSize from "../table/TablePageSize";
import TablePagination from "../table/TablePagination";

function UsersListView() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { formatDate } = useFormatters();
  const [query, setQuery] = useUserQuery();
  const [searchValue, setSearchValue] = useState(query.keyword);

  const { currentData, isFetching, isError } = useGetUsersQuery(
    { ...query, "X-Role-Id": getRoleId() },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const searchFilterChange = (event: FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearchValue(value);
  };

  const searchDocuments = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === "keyup" &&
      (event as React.KeyboardEvent).key !== "Enter"
    ) {
      return null;
    }

    return setQuery({
      ...query,
      page: 1,
      keyword: searchValue,
    });
  };

  if (isError)
    return (
      <div>
        <span>{t("app.page.error.api")}</span>
      </div>
    );

  return (
    <>
      <PageHeader
        icon={AllRoutes.USERS_LIST.icon}
        label={t("app.page.users.heading")}
      />

      <Card>
        <CardHeader label={t("card.header.list") || ""} icon={faList}>
          <CountBadge count={currentData?.count} />
        </CardHeader>
        <CardBody>
          <div className="flex p-4 mb-4 bg-slate-200 rounded-lg">
            <div className="flex ml-auto">
              <div className="flex border border-gray-300 rounded">
                <span className="w-8 text-center text-gray-300 px-1 border-gray-300 border-r bg-white rounded-l">
                  <FontAwesomeIcon
                    icon={faSearch}
                    className="text-gray-600 mt-1.5"
                  />
                </span>
                <input
                  type="text"
                  className="border-none text-gray-700 px-4 text-sm py-1 outline-0 rounded-r"
                  placeholder={t("table.filter.name") || ""}
                  value={searchValue}
                  onKeyUp={(e) => searchDocuments(e)}
                  onChange={(e) => searchFilterChange(e)}
                />
              </div>
              <input
                type="button"
                className="ml-1 border border-gray-300 bg-white text-gray-700 px-4 text-sm py-1 outline-0 rounded"
                value={t("button.search") as string}
                onClick={(e) => searchDocuments(e)}
              />
            </div>
          </div>
          <table>
            <thead>
              <tr className="">
                <td>
                  <TableColumnHeadingButton
                    field="name"
                    sort={query?.sort}
                    onClick={(field, direction) => {
                      setQuery({
                        ...query,
                        page: 1,
                        sort: [`${field}:${direction}`],
                      });
                    }}
                  />
                </td>
                <td>
                  <TableColumnHeadingButton
                    field="id"
                    sort={query?.sort}
                    onClick={(field, direction) => {
                      setQuery({
                        ...query,
                        page: 1,
                        sort: [`${field}:${direction}`],
                      });
                    }}
                  />
                </td>
                <td>
                  <TableColumnHeadingButton
                    field="email"
                    sort={query?.sort}
                    onClick={(field, direction) => {
                      setQuery({
                        ...query,
                        page: 1,
                        sort: [`${field}:${direction}`],
                      });
                    }}
                  />
                </td>
                <td>
                  <TableColumnHeadingButton
                    field="createdAt"
                    label="CREATED"
                    sort={query?.sort}
                    onClick={(field, direction) => {
                      setQuery({
                        ...query,
                        page: 1,
                        sort: [`${field}:${direction}`],
                      });
                    }}
                  />
                </td>
                <td>
                  <TableColumnHeadingButton
                    field="status"
                    label={t("table.header.status") || ""}
                    sort={query?.sort}
                    onClick={(field, direction) => {
                      setQuery({
                        ...query,
                        page: 1,
                        sort: [`${field}:${direction}`],
                      });
                    }}
                  />
                </td>
              </tr>
            </thead>
            {isFetching && !currentData ? (
              <TableBodyLoading cols={5} rows={query.size ?? 10} />
            ) : (
              <tbody>
                {currentData
                  ? currentData?.items?.map((user) => (
                      <tr
                        className="cursor-pointer"
                        key={`index-${user.id}`}
                        onClick={() => {
                          navigate(
                            makeRoute(AllRoutes.USERS_EDIT, { id: user.id })
                          );
                        }}
                      >
                        <td className="text-spf-primary">
                          {user?.displayName ?? ""}
                        </td>
                        <td>
                          <IDBadge id={user.id ?? ""} />
                        </td>
                        <td>
                          <EmailBadge email={user.email ?? ""} />
                        </td>
                        <td>
                          <span className="">{formatDate(user.createdAt)}</span>
                        </td>
                        <td>
                          <UserStatusBadge status={user.status} />
                        </td>
                      </tr>
                    ))
                  : t("table.noData")}
              </tbody>
            )}
          </table>
          {!isFetching && currentData?.count === 0 ? (
            <Alert className="mt-4">
              <span>{t("table.noData")}</span>
            </Alert>
          ) : (
            <span />
          )}
        </CardBody>
        <CardFooter>
          <TablePagination
            callback={setQuery}
            query={query}
            count={currentData?.count ?? 0}
          />
          <div className="ml-auto">
            <TablePageSize query={query} callback={setQuery} />
          </div>
        </CardFooter>
      </Card>
    </>
  );
}

export default UsersListView;
