import React, { ChangeEvent } from "react";

import { useTranslation } from "react-i18next";

import { Gender as GenderType } from "../../api/customers.generated";
import { GenderKey } from "../../api/documents.generated";
import Gender from "../../enums/Gender";

interface Properties {
  selectedOption: GenderType | undefined;
  compareValue?: GenderKey | undefined;
  disabled?: boolean;
  dataTestId?: string;
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void | undefined;
}

function SelectGender({
  disabled,
  selectedOption,
  compareValue,
  dataTestId,
  onChange,
}: Properties) {
  const { t } = useTranslation();

  const shouldCompare =
    compareValue !== undefined && compareValue !== selectedOption?.key;

  const htmlFor = `select-gender`;

  return (
    <div>
      <label htmlFor={htmlFor} className="flex mb-1 text-sm text-gray-500">
        <span>{t("app.form.gender")}</span>
        {shouldCompare ? (
          <small className="ml-auto text-green-600">
            [
            {t(
              Gender.filter((item) => item.key === compareValue)?.pop()?.name ||
                ""
            ) || ""}
            ]
          </small>
        ) : (
          ""
        )}
      </label>
      <select
        id={htmlFor}
        className={`
          block text-gray-700 mb-2 border border-gray-300 w-full p-2 rounded
          ${disabled ? " bg-gray-100 cursor-text" : ""}
        `}
        value={selectedOption?.key}
        onChange={onChange}
        disabled={disabled}
        data-testid={dataTestId && dataTestId.length > 0 ? dataTestId : htmlFor}
      >
        {Gender.map((item) => (
          <option
            key={`select-gender-${item.key}`}
            label={t(item.name) || ""}
            value={item.key}
          >
            <span>{t(item.name)}</span>
          </option>
        ))}
      </select>
    </div>
  );
}

SelectGender.defaultProps = {
  disabled: false,
  compareValue: undefined,
  onChange: undefined,
  dataTestId: "",
};

export default SelectGender;
