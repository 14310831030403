import { useState } from "react";

import { useNavigate } from "react-router";

import { DocumentTypeKey, FilterOptionsKey } from "../api/documents.generated";
import { DocumentTypeKeys, ZUZ } from "../enums/DocumentTypes";
import { USER_OWNED } from "../enums/FilterLevelKey";
import AllRoutes from "../features/route/Route";
import useLogger from "./logger";
import { SortablePaginationQuery, useQuery } from "./query";
import { makeRoute } from "./route";

export interface DocumentQuery extends SortablePaginationQuery {
  applySkip: boolean;
  keyword: string;
  level: FilterOptionsKey;
  documentStates: Array<string>;
  documentTypes: Array<string>;
}

export type DocumentQueryDispatcher = (query: DocumentQuery) => void;

type DocumentQueryHook = [DocumentQuery, (query: DocumentQuery) => void];

const localStorageSizeKey = "documents.page.size";
const localStorageDocumentZuzStates = "documents.filter.documentStates.zuz";
const localStorageDocumentCheckStates = "documents.filter.documentStates.check";

function getDocumentPageSizeFromStorage(): number {
  return Number(localStorage.getItem(localStorageSizeKey)) || 10;
}

function setDocumentPageSizeToStorage(newSize: number): void {
  localStorage.setItem(localStorageSizeKey, newSize.toString());
}

function getDocumentStatesFromStorage(type: DocumentTypeKey): Array<string> {
  const states =
    type === ZUZ
      ? localStorage.getItem(localStorageDocumentZuzStates)
      : localStorage.getItem(localStorageDocumentCheckStates);
  if (states) {
    return states.split(",");
  }
  return [];
}

function setDocumentStatesToStorage(
  type: DocumentTypeKey,
  newStates: Array<string>
): void {
  const states =
    type === ZUZ
      ? localStorageDocumentZuzStates
      : localStorageDocumentCheckStates;
  localStorage.setItem(states, newStates.join(","));
}

export function useDocumentQuery(type: DocumentTypeKey): DocumentQueryHook {
  const navigate = useNavigate();
  const query = useQuery();
  const { logger } = useLogger();
  const [documentQuery, setDocumentQuery] = useState<DocumentQuery>({
    // Important: The applySkip parameter is used to determine if the skip
    applySkip: true,
    page: query.page ?? 1,
    size: query.size ?? getDocumentPageSizeFromStorage(),
    sort: query.sort ? [query.sort] : ["createdAt:desc"],
    keyword: query.keyword ?? "",
    level: query.level ?? USER_OWNED,
    documentStates: query.documentStates
      ? query.documentStates.split(",")
      : getDocumentStatesFromStorage(type),
    documentTypes: query?.documentTypes
      ? query.documentTypes.split(",")
      : [type],
  });

  logger.debug("PARSED DOCUMENT QUERY", documentQuery);

  return [
    documentQuery,
    (updatedDocumentQuery: DocumentQuery) => {
      const queryString = Object.keys(updatedDocumentQuery)
        .map((key) => {
          // hide the applySkip parameter from the query string
          if (key === "applySkip") {
            return undefined;
          }

          const parameter = updatedDocumentQuery[key as keyof DocumentQuery];
          if (Array.isArray(parameter)) {
            const val = parameter.join(",");
            return val.length > 0 ? `${key}=${val}` : undefined;
          }

          return (parameter ?? "").toString().length > 0
            ? `${key}=${parameter}`
            : undefined;
        })
        .filter((item) => item !== undefined)
        .join("&");

      logger.debug("QUERY STRING", queryString);

      // Important order: First update the URI/history, than the state because
      // it forces a repaint of the component
      setDocumentPageSizeToStorage(updatedDocumentQuery.size ?? 10);
      setDocumentStatesToStorage(
        type,
        updatedDocumentQuery.documentStates ?? []
      );
      navigate(
        `${makeRoute(
          type === DocumentTypeKeys.ZUZ
            ? AllRoutes.DOCUMENTS_LIST_ZUZ
            : AllRoutes.DOCUMENTS_LIST_CHECK
        )}?${queryString}`
      );
      setDocumentQuery(updatedDocumentQuery);
    },
  ];
}
