import { matchPath, useLocation } from "react-router";

import ApplicationRoute from "../features/route/ApplicationRoute";
import AllRoutes from "../features/route/Route";

export interface Role {
  /**
   *
   * @type {string}
   * @memberof Role
   */
  key?: string;
  /**
   *
   * @type {string}
   * @memberof Role
   */
  name?: string;
}

export interface CompanyRoleUserListResponse {
  // /**
  //  *
  //  * @type {Company}
  //  * @memberof CompanyRoleUserListResponse
  //  */
  // company?: Company;
  /**
   *
   * @type {string}
   * @memberof CompanyRoleUserListResponse
   */
  id?: string;
  /**
   *
   * @type {Role}
   * @memberof CompanyRoleUserListResponse
   */
  role?: Role;
  /**
   *
   * @type {string}
   * @memberof CompanyRoleUserListResponse
   */
  userId?: string;
}

export function matchRoute(path: string, pathname: string): boolean {
  const m = matchPath(pathname, path);
  return !!m;
}

export function useMatchedRoute(): ApplicationRoute {
  const location = useLocation();

  const matched = Object.values(AllRoutes).filter((value) => {
    return !!matchPath(value.path, location.pathname);
  });

  return matched[0] ?? AllRoutes.DASHBOARD;
}

type ApplicationRouteParameters = {
  id?: string;
  name?: string;
};

type ApplicationQueryParameters = {
  name: string;
  value: string;
};

export function makeRoute(
  route: ApplicationRoute,
  data?: ApplicationRouteParameters,
  queryParams?: ApplicationQueryParameters[]
): string {
  let final: string = route.path;

  if (data) {
    Object.keys(data).forEach((name) => {
      const value = data.id;
      final = final.replace(`/:${name}`, `/${value}`);
    });
  }

  if (queryParams) {
    let queryString = "";
    queryParams.forEach((item) => {
      queryString += `${item.name}=${item.value}&`;
    });
    queryString = queryString.replace(/.$/, "");
    final += `?${queryString}`;
  }

  return final;
}

interface QueryParamterMap {
  [key: string]: string;
}

export function parseUrl(url: string): QueryParamterMap {
  const query: QueryParamterMap = {};

  const pairs = (url[0] === "?" ? url.substr(1) : url).split("&");
  pairs.forEach((_, i) => {
    const pair = pairs[i].split("=");
    const decodedParam = decodeURIComponent(pair[0]);
    const decodedValue = decodeURIComponent(pair[1]);
    query[decodedParam] = decodedValue;
  });

  return query;
}

export function parseUrlParam(url: string, parameter: string): string {
  const paramValue = parseUrl(url)[parameter];
  if (paramValue) {
    return String(paramValue);
  }

  return "";
}

export function hasAccessToRoute(
  existingRoles: CompanyRoleUserListResponse[],
  requiredRoles: string[]
): boolean {
  if (!requiredRoles || requiredRoles.length === 0) {
    return true;
  }

  // Prefill
  const roleCheck: { [key: string]: boolean } = {};
  requiredRoles?.forEach((requiredRole) => {
    roleCheck[requiredRole] = false;
  });

  // Check
  existingRoles.forEach((role) => {
    if (Object.keys(roleCheck).indexOf(role.role?.key ?? "") !== -1) {
      roleCheck[role.role?.key ?? ""] = true;
    }
  });

  // Final check if all are met
  let hasAccess = true;
  requiredRoles?.forEach((requiredRole) => {
    if (!roleCheck[requiredRole]) {
      hasAccess = false;
    }
  });

  return hasAccess;
}

const allHooks = { useMatchedRoute, hasAccessToRoute };

export default allHooks;
