/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext } from "react";

import Card from "../../../components/card/Card";
import FormTable from "../../../components/tables/FormTable";
import {
  declarationsOfSuitabilityChecks,
  documentsPassedtoCustomers,
} from "../../../enums/Document";
import useDocumentGetters from "../../../hooks/documentContent";
import { Document } from "../../../models/Document";
import DocumentZuzContext from "../DocumentZuzFormViewContext";

interface Properties {
  document: Document;
  setDocument: React.Dispatch<Document>;
}

function Page05Card({ document, setDocument }: Properties) {
  const {
    getValue,
    handleTextareaChange,
    handleInputChange,
    getTableValue,
    handleTableChange,
    handleCheckboxChange,
    isChecked,
  } = useDocumentGetters();

  const ctx = useContext(DocumentZuzContext);

  return (
    <Card className="mb-8 px-4">
      <div className="py-3 mb-6">
        <div className="w-full px-3 py-2 mb-4 font-bold bg-slate-200 ">
          Navrhované riešenia PFA (resp. SPF) / Odporúčania / Vhodnosť
        </div>
        <h3 className="text-base font-bold my-2">Zvolené riešenie</h3>
        <FormTable
          tableName="pfaRecomendationsTable"
          heading={[
            { title: "Finančná inštitúcia", textClass: "text-center" },
            {
              title: "Typ a označenie produktu/Fondy",
              textClass: "text-center",
            },
            {
              title: "Výška cieľovej sumy/úveru",
              textClass: "text-center",
            },
            {
              title:
                "Výška a frekvencia vkladov/splátok/poistného alebo príspevkov klienta",
              textClass: "text-center",
            },
          ]}
          errors={[
            [
              ctx.getFieldError("pfaRecomendationsTable[0]")?.message,
              ctx.getFieldError("pfaRecomendationsTable[1]")?.message,
              ctx.getFieldError("pfaRecomendationsTable[2]")?.message,
              ctx.getFieldError("pfaRecomendationsTable[3]")?.message,
            ],
          ]}
          onChange={(name, values) =>
            handleTableChange(document, setDocument, name, values)
          }
          rows={6}
          values={getTableValue(document, "pfaRecomendationsTable")}
        />
        <label
          className="block text-gray-700 text-sm font-bold mb-2 mt-2"
          htmlFor="pfaRecomendationsNotes"
        >
          Poznámky
        </label>
        <textarea
          className="form-control
                  block
                  w-full
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  m-0
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                "
          id="pfaRecomendationsNotes"
          name="pfaRecomendationsNotes"
          rows={3}
          value={getValue(document, "pfaRecomendationsNotes")}
          onChange={(e) =>
            handleTextareaChange(
              document,
              setDocument,
              "pfaRecomendationsNotes",
              e
            )
          }
          data-testid="/documents/zuz/textarea-pfaRecomendationsNotes"
        />
        <h3 className="text-base font-bold my-2">
          Vyhlásenie o vhodnosti / Upozornenie na nevhodnosť
        </h3>
        {declarationsOfSuitabilityChecks.map(({ name, label }) => (
          <div
            className="flex form-check mr-2 flex gap-4"
            key={`checkbox-${name}`}
          >
            <input
              className="cursor-pointer"
              type="radio"
              value={name}
              key={`key-${name}`}
              name="declarationsOfSuitabilityChecks"
              id={`declarationsOfSuitabilityChecks-${name}`}
              checked={
                document.content.declarationsOfSuitabilityChecks === name
              }
              onChange={(e) => {
                handleInputChange(
                  document,
                  setDocument,
                  "declarationsOfSuitabilityChecks",
                  e
                );
                ctx.resetFieldError("declarationsOfSuitabilityChecks");
              }}
              data-testid="/documents/zuz/card-05/input-declarationsOfSuitabilityChecks"
            />
            <label
              className="form-check-label inline-block text-gray-800 cursor-pointer"
              htmlFor={`declarationsOfSuitabilityChecks-${name}`}
              key={`label-${name}`}
            >
              {label}
            </label>
          </div>
        ))}
        {ctx.getFieldError("declarationsOfSuitabilityChecks") ? (
          <p className="text-red-400">
            {ctx.getFieldError("declarationsOfSuitabilityChecks")?.message}
          </p>
        ) : null}

        <h3 className="text-base font-bold my-2">
          Dokumenty odovzdané Klientovi a Partnerovi
        </h3>
        <div className="flex ">
          {documentsPassedtoCustomers.map(({ name, label }) => (
            <div className="flex form-check mr-2" key={`checkbox-${name}`}>
              <input
                className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="checkbox"
                value={name}
                key={`key-${name}`}
                name="documentsPassedtoCustomers"
                id={`documentsPassedtoCustomers-${name}`}
                onChange={(e) =>
                  handleCheckboxChange(
                    document,
                    setDocument,
                    "documentsPassedtoCustomers",
                    name,
                    true
                  )
                }
                checked={
                  name === "copyOfThisDocument"
                    ? true
                    : isChecked(document, "documentsPassedtoCustomers", name)
                }
                disabled={name === "copyOfThisDocument"}
                data-testid="/documents/zuz/card-05/input-copyOfThisDocument"
              />
              <label
                className={`form-check-label inline-block text-gray-800 ${
                  name === "copyOfThisDocument"
                    ? "cursor-not-allowed"
                    : "cursor-pointer"
                }`}
                htmlFor={`documentsPassedtoCustomers-${name}`}
                key={`label-${name}`}
              >
                {label}
              </label>
            </div>
          ))}
        </div>
        <textarea
          className="form-control
                  block
                  w-full
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  mt-4
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                "
          id="otherDocumentsPassedToCustomersValue"
          name="otherDocumentsPassedToCustomersValue"
          rows={3}
          value={getValue(document, "otherDocumentsPassedToCustomersValue")}
          onChange={(e) =>
            handleTextareaChange(
              document,
              setDocument,
              "otherDocumentsPassedToCustomersValue",
              e
            )
          }
          data-testid="/documents/zuz/textarea-otherDocumentsPassedToCustomersValue"
        />
      </div>
    </Card>
  );
}

export default Page05Card;
