import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";

export type MenuStateType = {
  show: boolean;
  toggle: () => void;
  close: () => void;
  open: () => void;
};

export type PrivateContentContextType = {
  menu: MenuStateType;
};

interface PrivateContentContextProps {
  menu: MenuStateType;
}

const PrivateContentContext = createContext<
  PrivateContentContextProps | undefined
>(undefined);

const largeDesktop = window.matchMedia("(min-width: 1440px)");
const desktop = window.matchMedia("(min-width: 1023px)");
const tablet = window.matchMedia("(min-width: 768px)");
const mobile = window.matchMedia("(max-width: 767px)");

export function PrivateContentProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [menu, setMenu]: [
    MenuStateType,
    Dispatch<SetStateAction<MenuStateType>>
  ] = useState<MenuStateType>({
    // default show only on desktop
    show: largeDesktop.matches,
    toggle: () => {
      setMenu((prev: MenuStateType) => ({ ...prev, show: !prev.show }));
    },
    close: () => {
      if (largeDesktop.matches) return;
      setMenu((prev: MenuStateType) => ({ ...prev, show: false }));
    },
    open: () => {
      setMenu((prev: MenuStateType) => ({ ...prev, show: true }));
    },
  });

  const contextValue = useMemo<PrivateContentContextProps>(
    () => ({
      menu,
    }),
    [menu]
  );

  useEffect(() => {
    const handleResize = () => {
      if (largeDesktop.matches) {
        setMenu((prev: MenuStateType) => ({ ...prev, show: true }));
      } else if (tablet.matches || mobile.matches) {
        setMenu((prev: MenuStateType) => ({ ...prev, show: false }));
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <PrivateContentContext.Provider value={contextValue}>
      {children}
    </PrivateContentContext.Provider>
  );
}

export const usePrivateContentContext = (): PrivateContentContextType => {
  const context = useContext(PrivateContentContext);
  if (!context) {
    throw new Error(
      "usePrivateContentContext must be used within a PrivateContentProvider"
    );
  }

  return context;
};
