import React, { useEffect, useState } from "react";

import {
  faFrown,
  faHeart,
  faSignInAlt,
  faSmile,
  faSyncAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import {
  useDoLoginSsoMutation,
  useGetAuthOptionsQuery,
} from "../../api/auth.generated";
import Alert from "../../components/alerts/Alert";
import BackButton from "../../components/buttons/BackButton";
import Button from "../../components/buttons/Button";
import InputGroup from "../../components/inputs/InputGroup";
import AuthTypes from "../../enums/AuthTypes";
import { useQuery } from "../../hooks/query";
import { useAppDispatch, useAppSelector } from "../../hooks/store";
import { setTokens } from "../../hooks/token";
import { useVersion } from "../../hooks/version";
import PageLoader from "../loader/PageLoader";
import Logo from "../logo/Logo";
import AllRoutes from "../route/Route";
import { authSelector, initialize } from "./authSlice";

function SSORedirectView(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const version = useVersion();
  const [init, setInit] = useState(false);
  const { code } = useQuery();
  const [error, setError] = useState("");

  const { isLoggedIn } = useAppSelector(authSelector);

  const [ssoLogin, result] = useDoLoginSsoMutation();
  const { currentData: loginOptions, isSuccess: loginOptionSuccess } =
    useGetAuthOptionsQuery();

  // In case I don't have a redirect code this component should automatically
  // try to redirect to the ICM SSO
  useEffect(() => {
    if (
      loginOptionSuccess &&
      code === undefined &&
      loginOptions &&
      loginOptions?.length > 0
    ) {
      const icmSSO = loginOptions.find((i) => i.key === AuthTypes.SSO_ICM);
      if (icmSSO && icmSSO.url) {
        window.location.href = icmSSO.url;
      }
    }
  }, [loginOptions]);

  useEffect(() => {
    if (!init) {
      setInit(true);
      ssoLogin({ ssoRequest: { code } })
        .unwrap()
        .then((response) => {
          if (response) {
            setTokens({
              access_token: response?.access_token || "",
              refresh_token: response?.refresh_token || "",
            });
            dispatch(initialize());
          }
        })
        .catch((err) => {
          const text = err?.data?.message ?? "unknown";
          if (text.includes("error: ")) {
            let detailedError;
            try {
              detailedError = JSON.parse(text.replace("error: ", ""));
              setError(detailedError.msg);
            } finally {
              detailedError = text.replace("error: ", "");
              setError(detailedError);
            }
          } else {
            setError(text);
          }
        });
    }
    if (isLoggedIn) {
      navigate(AllRoutes.DASHBOARD.path);
    }
  }, [isLoggedIn, code, ssoLogin, init, setInit]);

  // Don't show
  if (!loginOptionSuccess) {
    return <PageLoader />;
  }

  return (
    <main>
      <div className="w-full h-screen flex flex-col md:items-center md:justify-center bg-gray-100 text-gray-700 overflow-hidden">
        <div className="flex flex-col md:flex-row bg-white border border-gray-200 w-full max-w-screen-md rounded-xl">
          <div className="order-2 md:order-1 w-full md:w-1/2 bg-white rounded-l-xl p-8 mt-4">
            <form className="login">
              <div className="relative text-center h-40 mb-16 ml-20">
                <FontAwesomeIcon
                  icon={faFrown}
                  size="10x"
                  className={
                    `text-spf-red ` +
                    `absolute transition-all duration-500 ` +
                    `${
                      code && result.isError
                        ? "opacity-100 block"
                        : "hidden opacity-0"
                    }`
                  }
                />
                <FontAwesomeIcon
                  icon={faSmile}
                  size="10x"
                  className={
                    `text-spf-green ` +
                    `absolute transition-all duration-500 ` +
                    `${
                      code && result.isSuccess
                        ? "opacity-100 block"
                        : "hidden opacity-0"
                    }`
                  }
                />
                <FontAwesomeIcon
                  icon={faSyncAlt}
                  spin
                  size="10x"
                  className={`text-gray-400 absolute transition-all
                    ${
                      !code || result.isUninitialized || result.isLoading
                        ? "opacity-100 block"
                        : "hidden opacity-0"
                    }`}
                />
              </div>
              {code && result.isError && error ? (
                <>
                  <Alert variant="danger">
                    <p>
                      <span>{t("error.generic")}</span>: {error}
                    </p>
                  </Alert>
                  <InputGroup>
                    <BackButton
                      className="mr-2"
                      onClick={() => {
                        navigate(AllRoutes.AUTH_LOGIN.path);
                      }}
                    />
                    {(loginOptions ?? []).map((item) => (
                      <Button
                        key={item.key}
                        icon={faSignInAlt}
                        onClick={() => {
                          window.location.href = item?.url ?? "";
                        }}
                      >
                        <span>{t("app.auth.ssoLogin")}</span>
                      </Button>
                    ))}
                  </InputGroup>
                </>
              ) : (
                <Alert>
                  <span>{t("app.auth.ssoSignInProcess")}</span>
                </Alert>
              )}
            </form>
          </div>
          <div className="order-1 md:order-2 text-white bg-spf-blue p-10 d-md-down-none ml-auto w-full md:w-1/2 md:rounded-r-xl flex flex-row h-full place-items-center justify-center">
            <div className="text-center">
              <Logo size="8x" color="white" />
              <div className="tracking-widest">
                <h1 className="font-semibold text-4xl text-gray-200">
                  <span>{t("app.name")}</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-10 text-center opacity-10 hover:opacity-80 transition-opacity">
          <small>
            <span>Version: {version}, developed by</span>
            <a className="mx-2" href="https://www.ezmid.com/">
              EZMID
            </a>
            <span>with</span>
            <FontAwesomeIcon
              className="ml-2"
              color="red"
              icon={faHeart}
              size="1x"
            />
          </small>
        </div>
      </div>
    </main>
  );
}

export default SSORedirectView;
