import React from "react";

import { faSave } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

import ApplicationRoute from "../../features/route/ApplicationRoute";
import Button from "./Button";

interface Properties {
  className?: string;
  dataTestId?: string;
  disabled?: boolean;
  to?: ApplicationRoute | undefined;
  onClick?: () => void | undefined;
}

function UpdateButton({
  className,
  disabled,
  to,
  dataTestId,
  onClick,
}: Properties) {
  const { t } = useTranslation();
  return (
    <Button
      className={className}
      disabled={disabled}
      icon={faSave}
      onClick={onClick}
      to={to}
      dataTestId={dataTestId}
    >
      <span>{t("button.save")}</span>
    </Button>
  );
}

UpdateButton.defaultProps = {
  className: "",
  dataTestId: "",
  disabled: false,
  to: undefined,
  onClick: undefined,
};

export default UpdateButton;
