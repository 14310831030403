import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";

import {
  RoleUserRight,
  useGetComputedRightByIdQuery,
} from "../../api/permission.generated";
import BackButton from "../../components/buttons/BackButton";
import PageHeader from "../../components/page/PageHeader";
import { getRoleName } from "../../enums/RoleOptions";
import { getRoleId } from "../../hooks/role";
import AllRoutes from "../route/Route";
import ObjectAndFunctionDetail from "./matrix/ObjectAndFunctionDetail";
import UserObjectAndFunctionList from "./matrix/UserObjectAndFunctionList";

function MatrixDetailView() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selected, setSelected] = useState<RoleUserRight>({} as RoleUserRight);

  const { id } = useParams();
  const { currentData, isFetching } = useGetComputedRightByIdQuery({
    "X-Role-Id": getRoleId(),
    id: id || "",
  });

  return (
    <>
      <PageHeader
        icon={AllRoutes.RULES_MATRIX_LIST.icon}
        label={`${t("app.page.rule-matrix.heading") || ""} - ${
          currentData?.displayName || ""
        } / ${getRoleName(currentData?.roleKey)}`}
      >
        <BackButton
          onClick={() => {
            navigate(AllRoutes.RULES_MATRIX_LIST.path);
          }}
        />
      </PageHeader>
      <div className="grid grid-cols-2 gap-4">
        <UserObjectAndFunctionList
          computedCrud={currentData?.computedCrud || []}
          isFetching={isFetching}
          selected={selected}
          onSelect={(obj: RoleUserRight) => setSelected(obj)}
        />
        <ObjectAndFunctionDetail logEntries={selected?.log || []} />
      </div>
    </>
  );
}

export default MatrixDetailView;
