import React from "react";

import { faHouseUser } from "@fortawesome/free-solid-svg-icons";

import { OriginSystem } from "../../api/customers.generated";
import Badge from "./Badge";

interface Properties {
  origin: OriginSystem | undefined;
}

function OriginBadge({ origin }: Properties): JSX.Element {
  const bgClass = "bg-blue-500";
  let color = "text-stone-100";
  if (origin && origin?.key === "uo-portal") {
    color = "text-stone-100";
  }
  if (origin && origin?.key === "uo-icm") {
    color = "text-stone-100";
  }
  return (
    <Badge
      icon={faHouseUser}
      bgClass={bgClass}
      color={color}
      label={origin?.name}
      pill
      small
    />
  );
}

export default OriginBadge;
