import { CountryKey } from "../api/advisors.generated";
import SelectOption from "./SelectOption";

const Country: SelectOption<CountryKey>[] = [
  {
    key: "sk",
    name: "app.select.country.sk",
  },
];

export default Country;
