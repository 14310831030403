import React from "react";

import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

import { PermissionRuleStatus } from "../../api/permissionRule.generated";
import PermissionRuleStatusKey from "../../enums/PermissionRuleStatusKey";
import Badge from "./Badge";

interface Properties {
  className?: string;
  small?: boolean;
  status: PermissionRuleStatus | undefined;
}

function PermissionRuleStatusBadge({
  className,
  small,
  status,
}: Properties): JSX.Element {
  let icon = faCheckCircle;
  let bgClass = "bg-spf-green";
  let color = "text-stone-100";
  if (status && status.key === PermissionRuleStatusKey.DEACTIVATED) {
    bgClass = "bg-spf-red";
    color = "text-stone-100";
    icon = faTimesCircle;
  }
  return (
    <Badge
      className={className}
      bgClass={bgClass}
      color={color}
      icon={icon}
      label={`status.${status?.key}`}
      small={small}
      pill
    />
  );
}

PermissionRuleStatusBadge.defaultProps = {
  className: "",
  small: false,
};

export default PermissionRuleStatusBadge;
