interface tokens {
  access_token: string;
  refresh_token: string;
}

export const setTokens = (data: tokens) => {
  localStorage.setItem("access_token", data.access_token);
  localStorage.setItem("refresh_token", data.refresh_token);
};

export const removeTokens = () => {
  localStorage.removeItem("auth_tokens");
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
};

export const getAccessToken = () => localStorage.getItem("access_token");

export const getRefreshToken = () => localStorage.getItem("refresh_token");
