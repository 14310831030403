import React from "react";

import {
  faBan,
  faCheckCircle,
  faEnvelope,
  faFile,
  faHourglassStart,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import { DocumentStatus } from "../../api/documents.generated";
import DocumentStatusKey, {
  CHECK_CLOSED,
  CHECK_DELETED,
  CHECK_DRAFT,
  CHECK_INCOMING_POST,
  CHECK_IN_OFFICE,
  DELETED,
  DRAFT,
  SIGNED,
  SIGNING_EXPIRED,
  SIGNING_IN_PROGRESS,
  SIGNING_REJECTED,
} from "../../enums/DocumentStatusKey";
import Badge from "./Badge";

interface Properties {
  className?: string;
  small?: boolean;
  status: DocumentStatus | undefined;
}

function DocumentStatusBadge({
  className,
  small,
  status,
}: Properties): JSX.Element {
  let icon = faCheckCircle;
  let bgClass = "bg-spf-green";
  let color = "text-stone-100";
  if (status && status.key === SIGNED) {
    bgClass = "bg-spf-green";
    color = "text-stone-100";
    icon = faCheckCircle;
  }
  if (status && status.key === DRAFT) {
    bgClass = "bg-spf-yellow";
    color = "text-stone-100";
    icon = faFile;
  }
  if (status && status.key === SIGNING_IN_PROGRESS) {
    bgClass = "bg-sky-500";
    color = "text-stone-100";
    icon = faHourglassStart;
  }
  if (status && status.key === SIGNING_REJECTED) {
    bgClass = "bg-purple-500";
    color = "text-stone-100";
    icon = faBan;
  }
  if (status && status.key === SIGNING_EXPIRED) {
    bgClass = "bg-spf-violet";
    color = "text-stone-100";
    icon = faBan;
  }
  if (status && status.key === DELETED) {
    bgClass = "bg-spf-red";
    color = "text-stone-100";
    icon = faTrash;
  }
  if (status && status.key === CHECK_DRAFT) {
    bgClass = "bg-spf-yellow";
    color = "text-stone-100";
    icon = faFile;
  }
  if (status && status.key === CHECK_IN_OFFICE) {
    bgClass = "bg-sky-500";
    color = "text-stone-100";
    icon = faHourglassStart;
  }
  if (status && status.key === CHECK_CLOSED) {
    bgClass = "bg-spf-green";
    color = "text-stone-100";
    icon = faCheckCircle;
  }
  if (status && status.key === CHECK_DELETED) {
    bgClass = "bg-spf-red";
    color = "text-stone-100";
    icon = faTrash;
  }
  if (status && status.key === CHECK_INCOMING_POST) {
    bgClass = "bg-purple-500";
    color = "text-stone-100";
    icon = faEnvelope;
  }
  return (
    <Badge
      className={className}
      bgClass={bgClass}
      color={color}
      icon={icon}
      label={`status.${status?.key}`}
      small={small}
      pill
    />
  );
}

DocumentStatusBadge.defaultProps = {
  className: "",
  small: false,
};

export default DocumentStatusBadge;
