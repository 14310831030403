import React from "react";

import { faPen, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

import ApplicationRoute from "../../features/route/ApplicationRoute";
import Button from "./Button";

interface Properties {
  className?: string;
  inProgress?: boolean;
  disabled?: boolean;
  to?: ApplicationRoute | undefined;
  onClick?: () => void | undefined;
}

function RefreshButton({
  className,
  inProgress,
  disabled,
  to,
  onClick,
}: Properties) {
  const { t } = useTranslation();
  return (
    <Button
      className={className}
      disabled={disabled}
      inProgress={inProgress}
      icon={faSyncAlt}
      onClick={onClick}
      to={to}
    >
      <span>{t("button.refresh")}</span>
    </Button>
  );
}

RefreshButton.defaultProps = {
  className: "",
  inProgress: false,
  disabled: false,
  to: undefined,
  onClick: undefined,
};

export default RefreshButton;
