import React, { useState } from "react";

import i18next from "i18next";
import { useTranslation } from "react-i18next";

import SaveButton from "../../components/buttons/SaveButton";
import Card from "../../components/card/Card";
import CardBody from "../../components/card/CardBody";
import PageHeader from "../../components/page/PageHeader";
import SelectEdit from "../../components/select/SelectEdit";
import ClientLanguage from "../../enums/ClientLanguage";
import { getLanguage } from "../../hooks/clientLanguage";
import AllRoutes from "../route/Route";

interface ClientSettingsInterface {
  language: string;
}

function UserSettingsView() {
  const { t } = useTranslation();
  const languageOptions = Object.entries(ClientLanguage).map((v) => ({
    key: v[0],
    name: v[1],
  }));

  const selectedLanguage = getLanguage();
  const [clientSettings, setClientSettings] = useState<ClientSettingsInterface>(
    {
      language: selectedLanguage ?? "sk-SK",
    }
  );

  const handleSaveSettings = () => {
    if (clientSettings.language) {
      i18next.changeLanguage(clientSettings.language);
    }
  };

  return (
    <>
      <PageHeader icon={AllRoutes.USER_SETTINGS.icon} label={t("app.settings")}>
        <SaveButton onClick={() => handleSaveSettings()} />
      </PageHeader>
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
        <Card>
          <CardBody>
            <SelectEdit
              label={t("app.page.settings.language")}
              selectedOption={selectedLanguage ?? ""}
              options={languageOptions}
              onChange={(e) => {
                setClientSettings({
                  ...clientSettings,
                  language: e.currentTarget.value,
                });
              }}
            />
          </CardBody>
        </Card>
      </div>
    </>
  );
}

export default UserSettingsView;
