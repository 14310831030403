import React, { useEffect } from "react";

import { faTimes, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import { Advisor } from "../../api/advisors.generated";
import { Assistant, Central } from "../../api/me.generated";
import {
  RoleUser,
  Role as RoleApi,
  User,
} from "../../api/permissionRule.generated";
import {
  RoleKey,
  RoleUserListResponse,
  useLazyGetAllRolesQuery,
} from "../../api/users.generated";
import Button from "../../components/buttons/Button";
import RoleSelect from "../../components/inputs/RoleSelect";
import ValidatedInput from "../../components/inputs/ValidatedInput";
import UserSelectModal from "../../components/modals/UserSelectModal";
import { getRoleName } from "../../enums/RoleOptions";
import Roles from "../../enums/Roles";
import SelectOption from "../../enums/SelectOption";

interface UserRoleTableProperties {
  usersRole: RoleUser[];
  onChange: (usersRole: RoleUser[]) => void;
}

export const getProperty = (
  userRole: RoleUser
): Advisor | Assistant | Central | undefined => {
  const propertiesToCheck: Array<keyof RoleUser> = [
    "advisor",
    "assistant",
    "central",
  ];
  for (const prop of propertiesToCheck) {
    // Check if the user_role object has a defined property from the propertiesToCheck array
    const currentProperty = userRole[prop];
    if (
      currentProperty &&
      typeof currentProperty === "object" &&
      "user" in currentProperty
    ) {
      // Check if the user has a non-empty email
      if (currentProperty?.user?.email) {
        // Return the user's firstname and lastname
        return userRole[prop] as Advisor | Assistant | Central;
      }
    }
  }

  return undefined;
};

export const getUser = (userRole: RoleUser): User | undefined => {
  const userProperty: Advisor | Central | Assistant | undefined =
    getProperty(userRole);
  const user = userProperty?.user;

  if (user) {
    return user;
  }

  return undefined;
};

type UserRoleTableForm = {
  user?: User;
  roleKey?: RoleKey;
  filterRoles: SelectOption<RoleKey>[];
};

function UserRoleTable({ usersRole, onChange }: UserRoleTableProperties) {
  const { t } = useTranslation();

  const getName = (userRole: RoleUser) => {
    const user = getUser(userRole);

    if (!user) {
      return "";
    }

    return `${user.displayName}`;
  };

  const getRole = (userRole: RoleUser): RoleApi | undefined => {
    const property = getProperty(userRole);

    // from backend
    if (userRole.role) {
      return userRole.role;
    }

    // from frontend
    if (property && "role" in property) {
      return property.role as RoleApi;
    }

    return undefined;
  };

  const handleAdd = () => {
    let roleName;
    switch (form.roleKey) {
      case Roles.CENTRAL:
        roleName = "central";
        break;
      case Roles.ASSISTANT:
        roleName = "assistant";
        break;
      case Roles.ADVISOR:
        roleName = "advisor";
        break;
      default:
        throw new Error("Invalid role key");
    }

    const role = userRoles.find(
      (userRole) => userRole?.role?.key === form.roleKey
    );

    const newRoleUser: RoleUser = {
      id: role?.id,
      [roleName]: {
        id: "",
        role: {
          id: role?.id,
          key: form.roleKey,
        },
        user: form.user,
      },
    };

    // call parent function that table data has changed
    onChange([...usersRole, newRoleUser]);
    setTableData([...usersRole, newRoleUser]);

    cleanAndHideModal();
    return null;
  };

  const cleanAndHideModal = () => {
    // reset form
    setForm({
      user: undefined,
      roleKey: undefined,
      filterRoles: [],
    });

    // reset temp user
    setTempUser({} as User);

    setShowModal(false);
  };

  const removeUserByIndex = (index: number) => {
    const newTableData = [...tableData];
    newTableData.splice(index, 1);
    setTableData(newTableData);
    onChange(newTableData);
  };

  const [showModal, setShowModal] = React.useState(false);
  const [getAllRoles] = useLazyGetAllRolesQuery();

  const [tableData, setTableData] = React.useState(usersRole);

  const [tempUser, setTempUser] = React.useState({} as User);
  const [userRoles, setUserRoles] = React.useState(
    [] as RoleUserListResponse[]
  );
  const [form, setForm] = React.useState<UserRoleTableForm>({
    user: undefined,
    roleKey: undefined,
    filterRoles: [],
  });

  useEffect(() => {
    getAllRoles({
      id: tempUser.id ?? "",
    }).then(({ data }) => {
      if (data) {
        const allUserRoles = data.map((item: RoleUserListResponse) => {
          return {
            key: item?.role?.key,
            name: item?.role?.name,
          } as SelectOption<RoleKey>;
        });
        setUserRoles(data);
        setForm({
          ...form,
          user: tempUser,
          roleKey: data[0]?.role?.key ?? undefined,
          filterRoles: allUserRoles.filter(
            (item) => item.key !== Roles.SYSTEM_ADMIN
          ),
        });
      }
    });
  }, [tempUser.id]);

  return (
    <>
      <div className="w-full">
        <div className="flex text-gray-500 text-sm mb-4 justify-end">
          <Button
            className="!py-1 !px-2 !text-sm"
            onClick={() => setShowModal(true)}
          >
            Pridať
          </Button>
        </div>
        <table className="text-sm">
          <thead>
            <tr>
              <th className="text-left">
                <span>{t("table.header.user")}</span>
              </th>
              <th className="text-left">
                <span>{t("table.header.role")}</span>
              </th>
              <th className="text-right">
                <span>{t("table.header.action")}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData?.map((data, index) => {
              return (
                <tr
                  key={`table-data-${data.id ? data.id : index}`}
                  className="cursor-default hover:bg-white"
                >
                  <td className="px-0 text-left w-2/5">{getName(data)}</td>
                  <td className="px-0 text-left w-2/5">
                    {getRoleName(getRole(data)?.key)}
                  </td>
                  <td className="px-0 text-right w-1/5">
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      className="text-red-500 cursor-pointer"
                      onClick={() => removeUserByIndex(index)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {showModal ? (
        <div>
          <div>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-normal">
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/* content */}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-96 bg-white outline-none focus:outline-none">
                  {/* header */}
                  <div className="flex items-start justify-between p-3 rounded-t">
                    <button
                      type="button"
                      className="p-1 ml-auto bg-transparent border-0 float-right leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => cleanAndHideModal()}
                    >
                      <span className="bg-transparent h-6 w-6 outline-none focus:outline-none flex items-center">
                        <FontAwesomeIcon icon={faTimes} />
                      </span>
                    </button>
                  </div>
                  {/* body */}
                  <div className="relative p-6 pt-2 flex-auto">
                    <div>
                      <div className="flex flex-col items-end">
                        <UserSelectModal
                          callback={(user) => setTempUser(user)}
                        />
                      </div>
                      <ValidatedInput
                        label={t("input.user.label")}
                        value={`${tempUser.displayName ?? ""}`}
                        field="name"
                        callback={(e) => console.log(e)}
                        errors={[]}
                        disabled
                      />
                      {form?.user?.id ? (
                        <RoleSelect
                          selectedKey={form?.roleKey ?? ""}
                          filterOptions={form?.filterRoles}
                          onSelect={(value: RoleApi) =>
                            setForm({
                              ...form,
                              roleKey: value.key ?? undefined,
                            })
                          }
                        />
                      ) : null}
                    </div>
                    {/* footer */}
                    <div className="flex items-center pt-5">
                      <Button className="mr-2" onClick={() => handleAdd()}>
                        Pridať
                      </Button>
                      <Button
                        className="mr-2 bg-gray-200 text-gray-700 hover:bg-gray-300"
                        onClick={() => cleanAndHideModal()}
                      >
                        Zrušiť
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black" />
          </div>
        </div>
      ) : null}
    </>
  );
}

export default UserRoleTable;
