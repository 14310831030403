import {
  faCheckCircle,
  faFile,
  faHourglassStart,
  faTrash,
  faBan,
  faExclamationTriangle,
  faTimesCircle,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

import DocumentStatusKey, {
  CHECK_CLOSED,
  CHECK_DELETED,
  CHECK_DRAFT,
  CHECK_INCOMING_POST,
  CHECK_IN_OFFICE,
  DELETED,
  DRAFT,
  SIGNED,
  SIGNING_EXPIRED,
  SIGNING_IN_PROGRESS,
  SIGNING_REJECTED,
} from "./DocumentStatusKey";
import ExternalLinkCategoryType from "./ExternalLinkCategoryType";
import {
  ExternalLinkStatusTypes,
  ExternalLinkStatusKeys,
} from "./ExternalLinkStatusType";
import IdentificationStates from "./IdentificationStates";

export const documentStatusZuz = [
  {
    type: DRAFT,
    name: "Draft",
    icon: faFile,
    activeBgClass: "bg-spf-yellow",
  },
  {
    type: SIGNING_IN_PROGRESS,
    name: "In progress",
    icon: faHourglassStart,
    activeBgClass: "bg-sky-500",
  },
  {
    type: SIGNING_REJECTED,
    name: "Signing rejected",
    icon: faBan,
    activeBgClass: "bg-purple-500",
  },
  {
    type: SIGNING_EXPIRED,
    name: "Signing expired",
    icon: faBan,
    activeBgClass: "bg-spf-violet",
  },
  {
    type: SIGNED,
    name: "Signed",
    icon: faCheckCircle,
    activeBgClass: "bg-spf-green",
  },
  {
    type: DELETED,
    name: "Deleted",
    icon: faTrash,
    activeBgClass: "bg-spf-red",
  },
];
export const documentStatusCheck = [
  {
    type: CHECK_DRAFT,
    name: "Draft",
    icon: faFile,
    activeBgClass: "bg-spf-yellow",
  },
  {
    type: CHECK_INCOMING_POST,
    name: "Post",
    icon: faEnvelope,
    activeBgClass: "bg-purple-500",
  },
  {
    type: CHECK_IN_OFFICE,
    name: "In office",
    icon: faHourglassStart,
    activeBgClass: "bg-sky-500",
  },
  {
    type: CHECK_CLOSED,
    name: "Closed",
    icon: faCheckCircle,
    activeBgClass: "bg-spf-green",
  },
  {
    type: CHECK_DELETED,
    name: "Deleted",
    icon: faTrash,
    activeBgClass: "bg-spf-red",
  },
];

export const userStatus = [
  { key: "cs-active", name: "app.status.user.active" },
  { key: "cs-deactivated", name: "app.status.user.deactivated" },
];

export const identificationStatus = [
  {
    type: IdentificationStates.SUCCESS,
    name: "Success",
    icon: faCheckCircle,
    activeBgClass: "bg-spf-green",
  },
  {
    type: IdentificationStates.SUCCESS_IRELEVANT,
    name: "Success Irelevant",
    icon: faExclamationTriangle,
    activeBgClass: "bg-spf-yellow",
  },
  {
    type: IdentificationStates.ERROR,
    name: "Error",
    icon: faTimesCircle,
    activeBgClass: "bg-spf-red",
  },
  {
    type: IdentificationStates.REFUSED,
    name: "Refused",
    icon: faBan,
    activeBgClass: "bg-purple-500",
  },
  {
    type: IdentificationStates.DECLINED,
    name: "Declined",
    icon: faBan,
    activeBgClass: "bg-spf-violet",
  },
  {
    type: IdentificationStates.INITIATED,
    name: "Initiate",
    icon: faHourglassStart,
    activeBgClass: "bg-blue-500",
  },
];

export const externalLinkStatus = ExternalLinkStatusTypes.map((status) => {
  return {
    type: status.key,
    name: status.name,
    activeBgClass:
      status.key === ExternalLinkStatusKeys.ACTIVE
        ? "bg-spf-green"
        : "bg-spf-red",
  };
});

export const externalLinkCategories = ExternalLinkCategoryType.map(
  (category) => {
    return {
      type: category.key,
      name: category.name,
      activeBgClass: "bg-slate-400",
    };
  }
);

const allEnums = {
  documentStatus: documentStatusZuz,
  userStatus,
};

export default allEnums;
