import React, { useEffect, useState } from "react";

import { faLink, faList, faSave } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

import {
  AdvisorSectorTypeKey,
  ExternalLinkConfiguration,
} from "../../api/advisors.generated";
import {
  ExternalLinkAuthTypeKey,
  ExternalLinkCategoryKey,
  ExternalLinkStatusKey,
  ExternalLinkTypeKey,
  ExternalLinkUpdateRequest,
  useCreateAdminExternalLinkMutation,
  ValidationError,
} from "../../api/external-link.generated";
import BackButton from "../../components/buttons/BackButton";
import Button from "../../components/buttons/Button";
import Card from "../../components/card/Card";
import CardBody from "../../components/card/CardBody";
import CardHeader from "../../components/card/CardHeader";
import SelectExternalLinkAuthType from "../../components/inputs/SelectExternalLinkAuthType";
import SelectExternalLinkCategory from "../../components/inputs/SelectExternalLinkCategory";
import SelectExternalLinkStatusType from "../../components/inputs/SelectExternalLinkStatusType";
import SelectExternalLinkType from "../../components/inputs/SelectExternalLinkType";
import ValidatedInput from "../../components/inputs/ValidatedInput";
import ExternalLinkConfigurationModal from "../../components/modals/ExternalLinkConfigurationModal";
import FileUploadModal from "../../components/modals/FileUploadModal";
import PageHeader from "../../components/page/PageHeader";
import { GetAdvisorSectorTypeTranslationKey } from "../../enums/AdvisorSectorTypes";
import { pfaSectors } from "../../enums/Document";
import { ExternalLinkStatusKeys } from "../../enums/ExternalLinkStatusType";
import { getRoleId } from "../../hooks/role";
import { makeRoute } from "../../hooks/route";
import AllRoutes from "../route/Route";

function ExternalLinkAddView() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formErrors] = useState<ValidationError[]>([]);

  const [selectedLinkConfiguration] = useState<ExternalLinkConfiguration>();

  const [showLinkConfigModal, setShowLinkConfigModal] = useState(false);
  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const [uploadedImageData, setUploadedImageData] = useState<
    string | undefined
  >(undefined);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [doCreateLink] = useCreateAdminExternalLinkMutation();
  const [mandatorySectors, setMandatorySectors] = useState<string[]>([]);

  type FormDefault = {
    name: string;
    displayLink: string;
    authTypeKey: ExternalLinkAuthTypeKey;
    linkTypeKey: ExternalLinkTypeKey;
    imageId: string | undefined;
    order: number;
    statusKey: ExternalLinkStatusKey;
    categoryKey: ExternalLinkCategoryKey;
  };

  const [form, setForm] = useState<FormDefault>({
    name: "",
    displayLink: "",
    authTypeKey: "elat-noa",
    linkTypeKey: "ast-gen",
    imageId: undefined,
    order: 1,
    statusKey: ExternalLinkStatusKeys.ACTIVE,
    categoryKey: "lct-int",
  });

  const validateForm = () => {
    if (form.name !== "" && form.displayLink !== "") {
      return true;
    }
    return false;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newObject: ExternalLinkUpdateRequest = {
      ...form,
    };

    newObject.displayLink = form.displayLink;

    newObject.sectors = pfaSectors.map((sector) => {
      return {
        sectorTypeKey: sector.name as AdvisorSectorTypeKey,
        mandatory: mandatorySectors.includes(sector.name),
      };
    });

    doCreateLink({
      "X-Role-Id": getRoleId(),
      externalLinkUpdateRequest: newObject,
    }).then((response: any) => {
      if (response.data) {
        toast.success(t("app.external-links.createdSuccess"));
        const newId = response.data.id;
        return navigate(
          makeRoute(AllRoutes.EXTERNAL_LINKS_EDIT, { id: newId })
        );
      }
      return toast.warning(t("app.external-links.createError"));
    });
  };

  const handleShowUploadModal = () => {
    setShowUploadFileModal(true);
  };

  useEffect(() => {
    if (validateForm()) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [form]);

  return (
    <div>
      <PageHeader
        icon={faLink}
        label={t("app.page.external-link.link-create.heading")}
      >
        <BackButton
          onClick={() => {
            navigate(makeRoute(AllRoutes.EXTERNAL_LINKS_LIST));
          }}
        />
        <Button
          icon={faSave}
          disabled={submitDisabled}
          form="externalLinkAddForm"
          type="submit"
        >
          <span>{t("button.addNewLink")}</span>
        </Button>
      </PageHeader>
      <form
        className="grid grid-cols-1 xl:grid-cols-2 gap-4"
        onSubmit={handleSubmit}
        id="externalLinkAddForm"
        autoComplete="false"
      >
        <div className="flex flex-col gap-4">
          <Card>
            <CardHeader
              icon={faList}
              label={t("app.page.external-link.link-detail.heading") || ""}
            />
            <CardBody>
              <div className="py-1 grid grid-cols-2 gap-4">
                <ValidatedInput
                  label={t("app.form.name")}
                  field="name"
                  type="text"
                  value={form.name}
                  errors={formErrors}
                  required={false}
                  disabled={false}
                  callback={(e) =>
                    setForm({
                      ...form,
                      name: e.currentTarget.value,
                    })
                  }
                />
                <SelectExternalLinkStatusType
                  selectedOption={form?.statusKey}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      statusKey: e.target.value as ExternalLinkStatusKey,
                    });
                  }}
                />
                <SelectExternalLinkCategory
                  selectedOption={form?.categoryKey}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      categoryKey: e.target.value as ExternalLinkCategoryKey,
                    });
                  }}
                />
                <SelectExternalLinkType
                  selectedOption={form.linkTypeKey}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      linkTypeKey: e.target.value as ExternalLinkTypeKey,
                    });
                  }}
                />
                <SelectExternalLinkAuthType
                  selectedOption={form.authTypeKey}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      authTypeKey: e.target.value as ExternalLinkAuthTypeKey,
                    });
                  }}
                />
                <ValidatedInput
                  label={t("app.form.categoryOrder")}
                  field="order"
                  type="number"
                  min={1}
                  max={100}
                  value={form.order}
                  errors={formErrors}
                  required={false}
                  disabled={false}
                  callback={(e) =>
                    setForm({
                      ...form,
                      order: parseInt(e.currentTarget.value, 10),
                    })
                  }
                />
                <div className="col-span-2">
                  <ValidatedInput
                    label={t("app.form.link")}
                    field="link"
                    type="text"
                    value={form.displayLink}
                    errors={formErrors}
                    required={false}
                    disabled={false}
                    callback={(e) =>
                      setForm({
                        ...form,
                        displayLink: e.currentTarget.value,
                      })
                    }
                  />
                </div>
                <div>
                  {/* sektory */}
                  <div className="mb-2">
                    <span>{t("app.nbs_sectors")}</span>
                  </div>
                  {pfaSectors.map((sector, index) => (
                    <div
                      className="mt-3 flex flex-grow form-check"
                      key={`checkbox-${sector.name}`}
                    >
                      <input
                        className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        type="checkbox"
                        value={sector.name}
                        key={`pfa-input-${sector.name}`}
                        name="pfaSectors"
                        id={`pfaSector-${sector?.name}`}
                        onChange={(e) => {
                          const mandatories = [...mandatorySectors];
                          if (mandatorySectors.includes(sector.name)) {
                            const filteredMandatories = mandatories.filter(
                              (item) => {
                                return item !== sector.name;
                              }
                            );
                            setMandatorySectors(filteredMandatories);
                          } else {
                            mandatories.push(sector.name);
                            setMandatorySectors(mandatories);
                          }
                        }}
                        checked={mandatorySectors.includes(sector.name)}
                      />
                      <label
                        className="ml-2 form-check-label inline-block text-gray-800"
                        htmlFor={`pfaSector-${sector.name}`}
                        key={`label-${sector.name}`}
                      >
                        {t(GetAdvisorSectorTypeTranslationKey(sector.name)) ??
                          ""}
                      </label>
                    </div>
                  ))}
                </div>
                <div>
                  <div className="mb-2">
                    <span>{t("app.image")}</span>
                  </div>
                  <Card className="flex cursor-pointer justify-center h-[200px]">
                    <div
                      className="h-full w-full"
                      onClick={handleShowUploadModal}
                      aria-hidden="true"
                    >
                      {uploadedImageData ? (
                        <img
                          className="object-contain object-center h-[200px] px-5"
                          alt=""
                          src={uploadedImageData}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </Card>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </form>
      {selectedLinkConfiguration && showLinkConfigModal ? (
        <ExternalLinkConfigurationModal
          item={selectedLinkConfiguration}
          showModal={showLinkConfigModal}
          setShowModal={setShowLinkConfigModal}
        />
      ) : (
        ""
      )}
      <FileUploadModal
        header={t("app.upload.image")}
        callback={(imageId: string, file: File) => {
          const reader = new FileReader();
          reader.addEventListener("loadend", () => {
            setUploadedImageData(reader.result as string);
            setForm({
              ...form,
              imageId,
            });
            setShowUploadFileModal(false);
          });
          reader.readAsDataURL(file);
        }}
        showModal={showUploadFileModal}
        setShowModal={setShowUploadFileModal}
      />
    </div>
  );
}

export default ExternalLinkAddView;
