import React from "react";

import { SizeProp } from "@fortawesome/fontawesome-svg-core";

interface LogoProperties {
  color: string;
  size: SizeProp;
}

function Logo({ color, size }: LogoProperties): JSX.Element {
  return (
    <div className="inline-block w-64 py-4 m-0">
      <img className="inline-block" src="/logo-white.svg" alt="" />
    </div>
  );
}

export default Logo;
