enum AdvisorSectorTypes {
  AML = "ast-aml",
  INS = "ast-ins",
  LEN = "ast-len",
  ACC = "ast-acc",
  CAP = "ast-cap",
  SUP = "ast-sup",
  OLD = "ast-old",
}

export function GetAdvisorSectorTypeTranslationKey(s: string): string {
  return (
    AdvisorSectorTypeTranslations.filter((sectorTranslation) => {
      return sectorTranslation.advisorSectorKey === s;
    }).pop()?.translationKey ?? ""
  );
}

const AdvisorSectorTypeTranslations = [
  {
    advisorSectorKey: AdvisorSectorTypes.AML,
    translationKey: "advisor.sector.type.aml",
  },
  {
    advisorSectorKey: AdvisorSectorTypes.SUP,
    translationKey: "advisor.sector.type.sup",
  },
  {
    advisorSectorKey: AdvisorSectorTypes.ACC,
    translationKey: "advisor.sector.type.acc",
  },
  {
    advisorSectorKey: AdvisorSectorTypes.LEN,
    translationKey: "advisor.sector.type.len",
  },
  {
    advisorSectorKey: AdvisorSectorTypes.INS,
    translationKey: "advisor.sector.type.ins",
  },
  {
    advisorSectorKey: AdvisorSectorTypes.CAP,
    translationKey: "advisor.sector.type.cap",
  },
  {
    advisorSectorKey: AdvisorSectorTypes.OLD,
    translationKey: "advisor.sector.type.old",
  },
];

export default AdvisorSectorTypes;
