import React, { ChangeEvent } from "react";

import propTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { AdvisorPosition } from "../../api/permissionRule.generated";
import PermissionRuleTypePositionOptions from "../../enums/PermissionRuleTypePositionOptions";

interface Properties {
  selectedKey?: string;
  onSelect: (item: AdvisorPosition) => void;
}

function PermissionRulePositionSelect({ selectedKey, onSelect }: Properties) {
  const { t } = useTranslation();

  const handleOnChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const item = PermissionRuleTypePositionOptions.find(
      (option) => option.key === e.target.value
    ) as AdvisorPosition;
    onSelect(item as AdvisorPosition);
  };

  return (
    <div>
      <div className="flex text-gray-500 text-sm mb-1">
        <span>{t("select.permission-rule-position.label")}</span>
      </div>
      <select
        className="block text-gray-700 mb-2 border border-gray-300 w-full p-2 rounded"
        defaultValue={selectedKey}
        onChange={handleOnChange}
      >
        {PermissionRuleTypePositionOptions.map((item) => (
          <option
            key={`index-${item.key}`}
            label={t(item.name) || ""}
            value={item.key}
          >
            {t(item.name) || ""}
          </option>
        ))}
      </select>
    </div>
  );
}

PermissionRulePositionSelect.propTypes = {
  selectedKey: propTypes.string,
};

PermissionRulePositionSelect.defaultProps = {
  selectedKey: undefined,
};

export default PermissionRulePositionSelect;
