/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { ExternalLink } from "../../api/advisors.generated";
import {
  FavouriteExternalLinkUpdateRequest,
  useAddExternalLinkToFavouritesMutation,
  useChangeOrderFavouritesMutation,
  useGetExternalLinkCategoriesQuery,
  useGetExternalLinkFavouritesQuery,
  useRemoveFromFavouritesMutation,
} from "../../api/external-link.generated";
import ExternalLinkItem from "../../components/links/ExternalLinkItem";
import ExternalLinkConfigurationInfoModal from "../../components/modals/ExternalLinkConfigurationInfoModal";
import PageHeader from "../../components/page/PageHeader";
import ExternalLinkAuthTypeEnum from "../../enums/ExternalLinkAuthTypeEnum";
import ExternalLinkCategoryType from "../../enums/ExternalLinkCategoryType";
import { ExternalLinkStatusKeys } from "../../enums/ExternalLinkStatusType";
import {
  getAdvisorDataFromRole,
  getAdvisorSections,
} from "../../hooks/advisor";
import { isLinkEnabled } from "../../hooks/externalLinks";
import { getRoleId } from "../../hooks/role";
import setLinkAccessToken from "../../hooks/setLinkAccessToken";
import { useAppSelector } from "../../hooks/store";
import { authSelector } from "../auth/authSlice";
import AllRoutes from "../route/Route";

function ExternalLinksDashboardView() {
  const { t } = useTranslation();
  const [clickedItem, setClickedItem] = useState<ExternalLink | undefined>();
  const [showLinkInfoModal, setShowLinkInfoModal] = useState(false);

  const { currentData: favourites, refetch: refetchFavouriteLinks } =
    useGetExternalLinkFavouritesQuery({ "X-Role-Id": getRoleId() });

  const { currentData: categories, refetch: refetchCategoriesLinks } =
    useGetExternalLinkCategoriesQuery(
      { "X-Role-Id": getRoleId() },
      {
        refetchOnMountOrArgChange: true,
      }
    );

  useEffect(() => {
    updateFavouritesDnDObject(favourites?.items);
  }, [favourites]);

  const [favouritesDnDObjects, updateFavouritesDnDObject] = useState(
    favourites?.items
  );

  if (!favouritesDnDObjects && favourites) {
    updateFavouritesDnDObject(favourites.items);
  }

  const [doAddToFavourites] = useAddExternalLinkToFavouritesMutation();
  const [doRemoveFromFavourites] = useRemoveFromFavouritesMutation();
  const [doChangeFavouriteOrder] = useChangeOrderFavouritesMutation();

  const { currentRole } = useAppSelector(authSelector);

  const advisorData = getAdvisorDataFromRole(
    currentRole,
    !currentRole?.role?.key
  );
  const sectors = getAdvisorSections(advisorData);

  const handleExternalLinkCardClick = (link: ExternalLink) => {
    if (isLinkEnabled(link, sectors, advisorData)) {
      if (
        link.authType?.key === ExternalLinkAuthTypeEnum.SSO ||
        link.authType?.key === ExternalLinkAuthTypeEnum.NO_AUTHENTIFICATION
      ) {
        setLinkAccessToken();
        window.open(link.displayLink, "_blank");
      } else if (link.linkConfigurations) {
        setClickedItem(link);
        setShowLinkInfoModal(true);
      }
    }
  };

  const addToFavourite = (item: ExternalLink) => {
    const favouriteItems = favourites?.items?.length ?? 0;
    if (favouriteItems >= 5) {
      toast.warning(t("app.external-links.maximumFavouritesWarning"));
    } else {
      const orderObj: FavouriteExternalLinkUpdateRequest = {
        order: favouriteItems + 1,
      };
      doAddToFavourites({
        "X-Role-Id": getRoleId(),
        id: item.id ?? "",
        favouriteExternalLinkUpdateRequest: orderObj,
      }).then((response: any) => {
        refetchFavouriteLinks();
        toast.success(t("app.external-links.addedToFavourites"));
      });
    }
  };

  const removeFromFavourite = (item: ExternalLink) => {
    doRemoveFromFavourites({
      "X-Role-Id": getRoleId(),
      id: item.id ?? "",
    }).then((response: any) => {
      if (response.status === "ok") {
        refetchFavouriteLinks();
      }
      if (response.error) {
        toast.warning(response.error.data.message);
      }
      refetchFavouriteLinks();
    });
  };

  const handleOnDragEnd = (result: any) => {
    if (!result.destination || !favouritesDnDObjects) {
      return;
    }
    const items = Array.from(favouritesDnDObjects);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    updateFavouritesDnDObject(items);

    const updateFavouriteOrdersArray = items.map((item, index) => {
      return {
        id: item.id ?? "",
        order: index + 1,
      };
    });

    doChangeFavouriteOrder({
      "X-Role-Id": getRoleId(),
      favouriteLinkChangeOrderRequest: { items: updateFavouriteOrdersArray },
    }).then((response: any) => {
      refetchFavouriteLinks();
    });
  };

  return (
    <>
      <PageHeader
        icon={AllRoutes.EXTERNAL_LINKS_DASHBOARD_VIEW.icon}
        label={t("app.page.external-links.heading")}
      />

      <section>
        <div className="py-4">
          <span className="text-spf-dark font-bold">{t("app.favourites")}</span>
        </div>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable direction="horizontal" droppableId="favourites">
            {(provided) => (
              <div
                className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-8"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {favouritesDnDObjects &&
                  favouritesDnDObjects.map((item, index) => (
                    <Draggable
                      key={item.id ? item.id : index.toString()}
                      draggableId={item.id ? item.id : index.toString()}
                      index={index}
                    >
                      {(draggableProvided) => (
                        <div
                          className="float-left"
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                          ref={draggableProvided.innerRef}
                        >
                          <ExternalLinkItem
                            item={item}
                            isFavourite
                            callbackLinkClick={handleExternalLinkCardClick}
                            callbackFavouriteClick={removeFromFavourite}
                            enabled={isLinkEnabled(item, sectors, advisorData)}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                {/* {provided.placeholder} */}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </section>

      {categories?.items?.map((category) => {
        const categoryName =
          ExternalLinkCategoryType.filter(
            (item) => item.key === category.categoryKey
          ).pop()?.name ?? "";
        return (
          <section key={category.categoryKey}>
            <div className="py-4">
              <span className="text-spf-dark font-bold">{t(categoryName)}</span>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-8">
              {category.externalLink
                ?.filter((item) => {
                  return item.status?.key === ExternalLinkStatusKeys.ACTIVE;
                })
                .map((item, index) => (
                  <ExternalLinkItem
                    key={item.id ? item.id : index.toString()}
                    isFavourite={false}
                    item={item}
                    enabled={isLinkEnabled(item, sectors, advisorData)}
                    callbackFavouriteClick={addToFavourite}
                    callbackLinkClick={handleExternalLinkCardClick}
                  />
                ))}
            </div>
          </section>
        );
      })}
      <ExternalLinkConfigurationInfoModal
        link={clickedItem}
        showModal={showLinkInfoModal}
        setShowModal={setShowLinkInfoModal}
        callback={() => {
          refetchCategoriesLinks();
          refetchFavouriteLinks();
        }}
      />
    </>
  );
}

export default ExternalLinksDashboardView;
