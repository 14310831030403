import { GenderKey } from "../api/customers.generated";
import SelectOption from "./SelectOption";

export const MALE = "gk-male" as GenderKey;
export const FEMALE = "gk-female" as GenderKey;
export const NONE = "gk-none" as GenderKey;

export const GenderTypeKeys = {
  MALE,
  FEMALE,
  NONE,
};

const Gender: SelectOption<GenderKey>[] = [
  {
    key: MALE,
    name: "app.gender.male",
  },
  {
    key: FEMALE,
    name: "app.gender.female",
  },
  {
    key: NONE,
    name: "app.gender.none",
  },
];

export default Gender;
