import React, { ChangeEvent } from "react";

import { useTranslation } from "react-i18next";

import AdvisorPosition from "../../enums/AdvisorPosition";

interface Properties {
  selectedOption?: string;
  disabled?: boolean;
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void | undefined;
}

function SelectAdvisorPosition({
  selectedOption,
  onChange,
  disabled,
}: Properties) {
  const { t } = useTranslation();
  return (
    <div>
      <div className="flex text-gray-500 text-sm mb-1">
        <span>{t("app.form.position")}</span>
      </div>
      <select
        className={`block text-gray-700 mb-2 h-[38px] border border-gray-300 w-full py-1 px-2 rounded ${
          disabled ? "bg-gray-100 cursor-text" : ""
        }`}
        defaultValue={selectedOption}
        onChange={onChange}
        disabled={disabled}
        value={selectedOption}
      >
        {AdvisorPosition.map((item) => (
          <option
            key={`index-${item.key}`}
            label={t(item.name) || ""}
            value={item.key}
          >
            <span>{t(item.name)}</span>
          </option>
        ))}
      </select>
    </div>
  );
}

SelectAdvisorPosition.defaultProps = {
  selectedOption: undefined,
  onChange: undefined,
  disabled: false,
};

export default SelectAdvisorPosition;
