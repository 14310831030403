import {
  faCog,
  faFileSignature,
  faHome,
  faPlus,
  faUserFriends,
  faUsers,
  faLock,
  faCogs,
  faLockOpen,
  faLink,
  faUser,
  faLayerGroup,
  faRectangleList,
} from "@fortawesome/free-solid-svg-icons";

import ApplicationRoutes from "./ApplicationRoutes";

const AllRoutes: ApplicationRoutes = {
  AUTH_LOGIN: {
    path: "/auth/login",
    exact: true,
    isPrivate: false,
    icon: faHome,
    name: "app.page.home.heading",
  },

  AUTH_LOGOUT: {
    path: "/auth/logout",
    exact: true,
    isPrivate: false,
    icon: faHome,
  },

  AUTH_SSO_LOGIN: {
    path: "/user/login/sso-response",
    exact: true,
    isPrivate: false,
    icon: faHome,
  },

  ERROR_404: {
    path: "/error",
    exact: false,
    isPrivate: false,
    icon: faHome,
  },

  ADVISORS_LIST: {
    path: "/admin/advisors/list",
    isPrivate: true,
    icon: faUserFriends,
    name: "app.page.advisors.heading",
  },

  ADVISORS_ADD: {
    path: "/admin/advisors/add",
    isPrivate: true,
    icon: faUserFriends,
  },

  ADVISORS_EDIT: {
    path: "/admin/advisors/edit/:id",
    isPrivate: true,
    icon: faUserFriends,
  },

  USER_RESET_PASSWORD: {
    path: "/auth/reset-password/:token",
    isPrivate: false,
    icon: faLockOpen,
    name: "app.page.reset-password.heading",
  },

  USER_SETTINGS: {
    path: "/admin/user/settings",
    isPrivate: true,
    icon: faCog,
    name: "Settings",
  },

  CUSTOMERS_LIST: {
    path: "/admin/customers/list",
    isPrivate: true,
    icon: faUserFriends,
    name: "app.page.customers.heading",
  },

  CUSTOMERS_ADD: {
    path: "/admin/customers/add",
    isPrivate: true,
    icon: faUserFriends,
  },

  CUSTOMERS_EDIT: {
    path: "/admin/customers/edit/:id",
    isPrivate: true,
    icon: faUserFriends,
  },

  DOCUMENTS_LIST: {
    path: "/admin/documents/list/:type",
    isPrivate: true,
    icon: faFileSignature,
    name: "app.page.documents.zuz.heading",
  },

  DOCUMENTS_LIST_ZUZ: {
    path: "/admin/documents/list/zuz",
    isPrivate: true,
    icon: faFileSignature,
    name: "app.page.documents.ezuz.heading",
  },

  DOCUMENTS_LIST_CHECK: {
    path: "/admin/documents/list/check",
    isPrivate: true,
    icon: faFileSignature,
    name: "app.page.documents.check.heading",
  },

  DOCUMENT_VIEW_ZUZ: {
    path: "/admin/documents/zuz/:id",
    isPrivate: true,
    icon: faFileSignature,
  },

  DOCUMENT_VIEW_CHECK: {
    path: "/admin/documents/check/:id",
    isPrivate: true,
    icon: faFileSignature,
  },

  DOCUMENT_EDIT_CHECK: {
    path: "/admin/documents/check/:id/edit",
    isPrivate: true,
    icon: faFileSignature,
  },

  DOCUMENT_EDIT_ZUZ: {
    path: "/admin/documents/zuz/:id/edit",
    isPrivate: true,
    icon: faFileSignature,
  },

  DOCUMENTS_ADD_ZUZ: {
    path: "/admin/documents/add/zuz",
    isPrivate: true,
    icon: faPlus,
  },

  DOCUMENTS_ADD_CHECK: {
    path: "/admin/documents/add/check",
    isPrivate: true,
    icon: faPlus,
  },

  DOCUMENT_ADD_ZUZ: {
    path: "/admin/documents/add/zuz",
    isPrivate: true,
    icon: faPlus,
  },

  DOCUMENT_ADD_LIST: {
    path: "/admin/documents/add/list",
    isPrivate: true,
    icon: faPlus,
  },

  USERS_LIST: {
    path: "/admin/users/list",
    isPrivate: true,
    icon: faUsers,
    name: "app.page.users.heading",
  },

  IDENTIFICATION_LIST: {
    path: "/admin/identifications/list",
    isPrivate: true,
    icon: faLock,
    name: "app.page.identifications.heading",
  },

  IDENTIFICATION_CALLBACK: {
    path: "/identification/id-provider-callback",
    isPrivate: false,
    icon: faLock,
  },

  IDENTIFICATION_DETAIL: {
    path: "/admin/identification/:id",
    isPrivate: true,
    icon: faLock,
  },

  USERS_ADD: {
    path: "/admin/users/add",
    isPrivate: true,
    icon: faUsers,
  },

  USERS_EDIT: {
    path: "/admin/users/edit/:id",
    isPrivate: true,
    icon: faUsers,
  },

  SYSTEM_MANAGEMENT_VIEW: {
    path: "/admin/system/management",
    isPrivate: true,
    icon: faCogs,
    name: "app.page.systemManagement.heading",
  },

  EXTERNAL_LINKS_DASHBOARD_VIEW: {
    path: "/admin/my-external-links",
    isPrivate: true,
    icon: faLink,
    name: "app.page.external-links.heading",
  },

  EXTERNAL_LINKS_LIST: {
    path: "/admin/external-links",
    isPrivate: true,
    icon: faLink,
    name: "app.page.external-links.heading",
  },

  EXTERNAL_LINKS_EDIT: {
    path: "/admin/external-links/edit/:id",
    isPrivate: true,
    icon: faLink,
  },

  EXTERNAL_LINKS_ADD: {
    path: "/admin/external-links/add",
    isPrivate: true,
    icon: faLink,
  },

  EXTERNAL_LINKS_IMPORT: {
    path: "/admin/external-links/import",
    isPrivate: true,
    icon: faLink,
    name: "app.page.external-links-import.heading",
  },

  RULES_LIST: {
    path: "/admin/rules/list",
    isPrivate: true,
    icon: faUsers,
    name: "app.page.rules.heading",
  },

  RULES_ADD: {
    path: "/admin/rules/add",
    icon: faUserFriends,
    isPrivate: true,
  },

  RULES_EDIT: {
    path: "/admin/rules/edit/:id",
    isPrivate: true,
    icon: faUser,
  },

  RULES_MATRIX_LIST: {
    path: "/admin/rules/matrix",
    isPrivate: true,
    icon: faLayerGroup,
    name: "app.page.rules-matrix.heading",
  },

  RULES_MATRIX_DETAIL: {
    path: "/admin/rules/matrix/detail/:id",
    isPrivate: true,
    icon: faLayerGroup,
  },

  EVENTS_LIST: {
    path: "/admin/events/list",
    isPrivate: true,
    icon: faRectangleList,
    name: "app.page.events.heading",
  },
  EVENTS_EDIT: {
    path: "/admin/events/edit/:id",
    isPrivate: true,
    icon: faRectangleList,
  },

  DASHBOARD: {
    path: "/admin/dashboard",
    isPrivate: true,
    icon: faHome,
    name: "app.page.dashboard.heading",
  },
  ROOT: {
    path: "/",
    isPrivate: true,
    icon: faHome,
  },
};

export const useAllRoutesAsForRouter = () => {
  return Object.values(AllRoutes).map((item) => ({
    path: item.path,
    // element: item.element,
  }));
};

export default AllRoutes;
