export enum InvestmentCustomerType {
  CLIENT = "CLIENT",
  PARTNER = "PARTNER",
}

export const translate = (type: InvestmentCustomerType | undefined): string => {
  if (!type) return "";
  switch (type) {
    case InvestmentCustomerType.CLIENT:
      return "Klient";
    case InvestmentCustomerType.PARTNER:
      return "Partner";
    default:
      console.error(`Unknown InvestmentCustomerType: ${type}`);
      return "Unknown";
  }
};

const all = {
  InvestmentCustomerType,
  translate,
};

export default all;
