import React from "react";

import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";

type Props = {
  to: string;
  children: React.ReactNode;
};

export default function NavLinkItem({ to, children }: Props) {
  return (
    <div>
      <FontAwesomeIcon className="pr-1" size="xs" icon={faLink} />
      <NavLink className="text-spf-primary underline" to={to}>
        {children}
      </NavLink>
    </div>
  );
}
