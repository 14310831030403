import React, { FormEvent, useEffect, useState } from "react";

import {
  faLink,
  faList,
  faSearch,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";

import {
  Advisor,
  ExternalLinkConfiguration,
} from "../../api/advisors.generated";
import {
  ExternalLinkConfigurationStatusKey,
  ExternalLinkPatchRequest,
  ExternalLinkSector,
  useCreateAdminExternalLinkConfigurationMutation,
  useDeleteAdminExternalLinkConfigurationMutation,
  useGetAdminExternalLinkByIdQuery,
  useGetAdminExternalLinkConfigurationsQuery,
  usePatchExternalLinkMutation,
  useUpdateAdminExternalLinkConfigurationMutation,
  ValidationError,
} from "../../api/external-link.generated";
import Alert from "../../components/alerts/Alert";
import CountBadge from "../../components/badges/CountBadge";
import ExternalLinkConfigurationStatusBadge from "../../components/badges/ExternalLinkConfigurationStatusBadge";
import IDBadge from "../../components/badges/IDBadge";
import BackButton from "../../components/buttons/BackButton";
import Button from "../../components/buttons/Button";
import UpdateButton from "../../components/buttons/UpdateButton";
import Card from "../../components/card/Card";
import CardBody from "../../components/card/CardBody";
import CardFooter from "../../components/card/CardFooter";
import CardHeader from "../../components/card/CardHeader";
import getDefaultImageUrl from "../../components/image-response/ImageResponse";
import SelectExternalLinkAuthType from "../../components/inputs/SelectExternalLinkAuthType";
import SelectExternalLinkCategory from "../../components/inputs/SelectExternalLinkCategory";
import SelectExternalLinkStatusType from "../../components/inputs/SelectExternalLinkStatusType";
import SelectExternalLinkType from "../../components/inputs/SelectExternalLinkType";
import ValidatedInput from "../../components/inputs/ValidatedInput";
import AdvisorSelectModal from "../../components/modals/AdvisorSelectModal";
import ExternalLinkConfigurationModal from "../../components/modals/ExternalLinkConfigurationModal";
import FileUploadModal from "../../components/modals/FileUploadModal";
import PageHeader from "../../components/page/PageHeader";
import AdvisorSectorTypes from "../../enums/AdvisorSectorTypes";
import ExternalLinkAuthType, {
  ExternalLinkAuthTypeEnum,
} from "../../enums/ExternalLinkAuthType";
import ExternalLinkCategoryType from "../../enums/ExternalLinkCategoryType";
import ExternalLinkConfigurationStatusTypeEnum from "../../enums/ExternalLinkConfigurationStatusTypeEnum";
import { ExternalLinkStatusTypes } from "../../enums/ExternalLinkStatusType";
import ExternalLinkType from "../../enums/ExternalLinkType";
import ExternalLinkTypeEnum from "../../enums/ExternalLinkTypeEnum";
import { useExternalLinkConfigurationsQuery } from "../../hooks/externalLinkConfigurations";
import { getRoleId } from "../../hooks/role";
import { makeRoute } from "../../hooks/route";
import PageLoader from "../loader/PageLoader";
import AllRoutes from "../route/Route";
import TableBodyLoading from "../table/TableBodyLoading";
import TableColumnHeadingButton from "../table/TableColumnHeadingButton";
import TablePageSize from "../table/TablePageSize";
import TablePagination from "../table/TablePagination";

function ExternalLinkEditView() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState<ValidationError[]>([]);
  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const [uploadedImageData, setUploadedImageData] = useState<
    string | undefined
  >(undefined);
  const [query, setQuery] = useExternalLinkConfigurationsQuery(id ?? "");

  const [isCreating, setIsCreating] = useState(false);
  const [selectedLinkConfiguration, setSelectedLinkConfiguration] =
    useState<ExternalLinkConfiguration>();

  const [selectedAddingAdvisor, setSelectedAddingAdvisor] = useState<
    Advisor | undefined
  >(undefined);

  const [doPatchExternalLink] = usePatchExternalLinkMutation();
  const [doRemoveConfig] = useDeleteAdminExternalLinkConfigurationMutation();
  const [doUpdateExternalLinkConfiguration] =
    useUpdateAdminExternalLinkConfigurationMutation();

  const searchFilterChange = (event: FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearchValue(value);
  };

  const searchCustomers = (event: any) => {
    if (event.type === "keyup" && event.keyCode !== 13) {
      return null;
    }

    return setQuery({
      ...query,
      externalLinkId: id ?? "",
      page: 1,
      keyword: searchValue,
    });
  };

  const { currentData, isFetching, refetch } = useGetAdminExternalLinkByIdQuery(
    {
      "X-Role-Id": getRoleId(),
      id: id ?? "",
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    currentData: linkConfigurations,
    isFetching: isFetchingConfigs,
    refetch: refetchConfigs,
  } = useGetAdminExternalLinkConfigurationsQuery(
    { "X-Role-Id": getRoleId(), ...query },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const [saveConfiguration] = useUpdateAdminExternalLinkConfigurationMutation();
  const [createConfiguration] =
    useCreateAdminExternalLinkConfigurationMutation();

  const [searchValue, setSearchValue] = useState(query.keyword);
  const [showLinkConfigModal, setShowLinkConfigModal] = useState(false);

  const [externalLinkData, setExternalLinkData] = useState(currentData);

  const handleSelectAdvisor = (advisor: Advisor) => {
    setSelectedLinkConfiguration(undefined);
    setSelectedAddingAdvisor(advisor);

    if (ExternalLinkAuthTypeEnum.LOG === externalLinkData?.authType?.key) {
      setShowLinkConfigModal(true);
    } else {
      handleExternalLinkConfigCallback(
        advisor,
        undefined,
        undefined,
        undefined
      );
    }
  };

  const updateLink = () => {
    const newObject: ExternalLinkPatchRequest = {
      name: externalLinkData?.name ?? currentData?.name ?? "",
      displayLink:
        externalLinkData?.displayLink ?? currentData?.displayLink ?? "",
      authTypeKey:
        externalLinkData?.authType?.key ??
        currentData?.authType?.key ??
        ExternalLinkAuthType.pop()?.key ??
        "elat-noa",
      linkTypeKey:
        externalLinkData?.type?.key ??
        currentData?.type?.key ??
        ExternalLinkTypeEnum.GENERAL,
      imageId: externalLinkData?.imageId ?? currentData?.imageId ?? undefined,
      order: `${externalLinkData?.order}` ?? `${currentData?.order}` ?? "0",
      statusKey:
        externalLinkData?.status?.key ??
        currentData?.status?.key ??
        "els-active",
      categoryKey:
        externalLinkData?.category?.key ??
        currentData?.category?.key ??
        ExternalLinkCategoryType.pop()?.key ??
        "lct-int",
    };

    if (externalLinkData?.sectors) {
      const newSectors = externalLinkData.sectors.map((sector) => {
        return {
          mandatory: sector.mandatory ?? false,
          sectorTypeKey: sector.sectorType?.key ?? AdvisorSectorTypes.AML,
        };
      });
      newObject.sectors = newSectors;
    }

    if (validateForm(newObject)) {
      setIsCreating(true);
      doPatchExternalLink({
        "X-Role-Id": getRoleId(),
        id: id ?? "",
        externalLinkPatchRequest: newObject,
      }).then((response: any) => {
        if (response.error) {
          setIsCreating(false);
          toast.warning(
            `${t("app.external-links.updateError")}: ${
              response.error.data.message
            }`
          );
        } else {
          setExternalLinkData(undefined);
          toast.success(t("app.external-links.updateSuccess"));
          refetch();
          refetchConfigs();
          setIsCreating(false);
        }
      });
    }
    if (newObject.imageId === "") {
      toast.warning(t("app.external-links.form.imageError"));
    }
  };

  const validateForm = (request: ExternalLinkPatchRequest) => {
    const newFormErrors = [];
    let isValid = true;
    if (request.name === "") {
      newFormErrors.push({
        field: "name",
        message: t("app.external-links.form.nameError"),
      });
      isValid = false;
    }
    if (request.displayLink === "") {
      newFormErrors.push({
        field: "link",
        message: t("app.external-links.form.linkError"),
      });
      isValid = false;
    }

    if (!isValid) {
      setFormErrors(newFormErrors);
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (!externalLinkData && currentData) {
      setExternalLinkData(currentData);
    }
  }, [currentData]);

  const handleExternalLinkConfigCallback = (
    advisor?: Advisor,
    login?: string,
    password?: string,
    status?: ExternalLinkConfigurationStatusKey
  ) => {
    setShowLinkConfigModal(false);

    const updateRequest = {
      id: selectedLinkConfiguration?.id ?? "",
      externalLinkConfigurationUpdateRequest: {
        externalLinkID: id,
        advisorId: selectedLinkConfiguration?.advisorId ?? undefined,
        login: login ?? selectedLinkConfiguration?.login ?? undefined,
        password: password ?? selectedLinkConfiguration?.password ?? undefined,
        statusKey:
          status ??
          selectedLinkConfiguration?.status?.key ??
          ExternalLinkConfigurationStatusTypeEnum.ACTIVE,
      },
    };

    const createRequest = {
      externalLinkConfigurationUpdateRequest: {
        advisorId: advisor?.id,
        externalLinkID: id,
        login: login ?? undefined,
        password: password ?? undefined,
        statusKey: status ?? ExternalLinkConfigurationStatusTypeEnum.ACTIVE,
      },
    };

    const mode = selectedLinkConfiguration?.id !== undefined ? "edit" : "add";

    const result =
      mode === "edit"
        ? saveConfiguration({ "X-Role-Id": getRoleId(), ...updateRequest })
        : createConfiguration({ "X-Role-Id": getRoleId(), ...createRequest });

    result.then((response: any) => {
      if (response.error) {
        toast.warning(
          `${t("app.page.error.api")} : ${response.error.data.message}`
        );
      } else {
        toast.success(
          mode === "edit"
            ? t("app.external-links.configuration.updatedSuccess")
            : t("app.external-links.configuration.createdSuccess")
        );

        // hide modal, refetch data
        setShowLinkConfigModal(false);
        refetchConfigs();
        refetch();
      }
    });
  };

  const handleShowUploadModal = () => {
    setShowUploadFileModal(true);
  };

  if (isFetching && !currentData) return <PageLoader />;

  const getDisplayImageUrl = () => {
    if (uploadedImageData) {
      return uploadedImageData;
    }
    if (externalLinkData?.image) {
      return getDefaultImageUrl(externalLinkData?.image);
    }

    return undefined;
  };

  const handleChangeConfigStatus = (config: ExternalLinkConfiguration) => {
    doUpdateExternalLinkConfiguration({
      "X-Role-Id": getRoleId(),
      id: config.id ?? "",
      externalLinkConfigurationUpdateRequest: {
        login: config.login ?? "",
        password: config.password ?? "",
        externalLinkID: id,
        statusKey:
          config.status?.key === ExternalLinkConfigurationStatusTypeEnum.ACTIVE
            ? ExternalLinkConfigurationStatusTypeEnum.DEACTIVATED
            : ExternalLinkConfigurationStatusTypeEnum.ACTIVE,
      },
    }).then((response: any) => {
      if (response.data.status === "ok") {
        refetchConfigs();
      } else {
        toast.warning(response.error.data.message);
      }
    });
  };

  const handleRemoveConfig = (config: ExternalLinkConfiguration) => {
    doRemoveConfig({
      "X-Role-Id": getRoleId(),
      id: config.id ?? "",
    }).then((response: any) => {
      refetchConfigs();
      refetch();
    });
  };

  const handleCopyID = () => {
    const linkID = externalLinkData?.id ?? "";

    navigator.clipboard.writeText(linkID);
    toast.success(t("app.external-links.id-coppied"));
  };

  return (
    <div>
      <PageHeader icon={faLink} label={`${currentData?.name}`}>
        <BackButton
          onClick={() => {
            navigate(makeRoute(AllRoutes.EXTERNAL_LINKS_LIST));
          }}
        />
        <UpdateButton onClick={() => updateLink()} disabled={isCreating} />
      </PageHeader>
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
        <div className="flex flex-col gap-4">
          <Card>
            <CardHeader
              icon={faList}
              label={t("app.page.external-link.link-detail.heading") || ""}
            >
              <IDBadge id={externalLinkData?.id ?? ""} onClick={handleCopyID} />
            </CardHeader>
            <CardBody>
              <div className="py-1 grid grid-cols-2 gap-4">
                <ValidatedInput
                  label={t("app.form.name")}
                  field="name"
                  type="text"
                  value={externalLinkData?.name ?? currentData?.name ?? ""}
                  errors={formErrors}
                  required={false}
                  disabled={false}
                  callback={(e) =>
                    setExternalLinkData({
                      ...externalLinkData,
                      name: e.currentTarget.value,
                    })
                  }
                />
                <SelectExternalLinkStatusType
                  selectedOption={currentData?.status?.key}
                  onChange={(e) => {
                    setExternalLinkData({
                      ...externalLinkData,
                      status:
                        ExternalLinkStatusTypes.filter(
                          (item) => item.key === e.currentTarget.value
                        ).pop() ?? ExternalLinkStatusTypes.pop(),
                    });
                  }}
                />
                <SelectExternalLinkCategory
                  selectedOption={currentData?.category?.key}
                  onChange={(e) => {
                    setExternalLinkData({
                      ...externalLinkData,
                      category:
                        ExternalLinkCategoryType.filter(
                          (item) => item.key === e.currentTarget.value
                        ).pop() ?? ExternalLinkCategoryType.pop(),
                    });
                  }}
                />
                <SelectExternalLinkType
                  selectedOption={currentData?.type?.key}
                  onChange={(e) => {
                    setExternalLinkData({
                      ...externalLinkData,
                      type:
                        ExternalLinkType.filter(
                          (item) => item.key === e.currentTarget.value
                        ).pop() ?? ExternalLinkType.pop(),
                    });
                  }}
                  disabled
                />
                <SelectExternalLinkAuthType
                  selectedOption={currentData?.authType?.key}
                  onChange={(e) => {
                    setExternalLinkData({
                      ...externalLinkData,
                      authType:
                        ExternalLinkAuthType.filter(
                          (item) => item.key === e.currentTarget.value
                        ).pop() ?? ExternalLinkAuthType.pop(),
                    });
                  }}
                  disabled
                />
                <ValidatedInput
                  label={t("app.form.categoryOrder")}
                  field="order"
                  type="number"
                  min={0}
                  max={100}
                  value={externalLinkData?.order ?? currentData?.order ?? 0}
                  errors={formErrors}
                  required={false}
                  disabled={false}
                  callback={(e) =>
                    setExternalLinkData({
                      ...externalLinkData,
                      order: parseInt(e.currentTarget.value, 10),
                    })
                  }
                />
                <div className="col-span-2">
                  <ValidatedInput
                    label={t("app.form.link")}
                    field="link"
                    type="text"
                    value={
                      externalLinkData?.displayLink ??
                      currentData?.displayLink ??
                      ""
                    }
                    errors={formErrors}
                    required={false}
                    disabled={false}
                    callback={(e) =>
                      setExternalLinkData({
                        ...externalLinkData,
                        displayLink: e.currentTarget.value,
                      })
                    }
                  />
                </div>
                <div>
                  {/* sektory */}
                  <div className="mb-2">
                    <span>{t("app.nbs_sectors")}</span>
                  </div>
                  {currentData?.sectors?.map(
                    (sector: ExternalLinkSector, index) => (
                      <div
                        className="flex flex-grow form-check"
                        key={`checkbox-${sector.sectorType?.key}`}
                      >
                        <input
                          className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                          type="checkbox"
                          value={sector.sectorType?.key}
                          key={`pfa-input-${sector.sectorType?.key}`}
                          name="pfaSectors"
                          id={`pfaSector-${sector.sectorType?.key}`}
                          onChange={(e) => {
                            if (externalLinkData?.sectors) {
                              const newSectors = [
                                ...(externalLinkData?.sectors ?? []),
                              ];
                              const currentSector = newSectors[index];
                              const currentSectorData = { ...currentSector };
                              currentSectorData.mandatory =
                                !currentSectorData.mandatory;
                              newSectors[index] = currentSectorData;
                              setExternalLinkData({
                                ...externalLinkData,
                                sectors: newSectors,
                              });
                            }
                          }}
                          checked={
                            // eslint-disable-next-line no-nested-ternary
                            externalLinkData?.sectors
                              ? externalLinkData?.sectors[index].mandatory
                              : currentData?.sectors
                              ? currentData?.sectors[index].mandatory
                              : false
                          }
                        />
                        <label
                          className="mt-0.5 form-check-label inline-block text-gray-800"
                          htmlFor={`pfaSector-${sector.sectorType?.key}`}
                          key={`label-${sector.sectorType?.key}`}
                        >
                          {sector.sectorType?.name}
                        </label>
                      </div>
                    )
                  )}
                </div>
                <div>
                  <div className="mb-2">
                    <span>{t("app.image")}</span>
                  </div>
                  <Card className="flex cursor-pointer justify-center h-[200px]">
                    <div
                      className="h-full w-full"
                      onClick={handleShowUploadModal}
                      aria-hidden="true"
                    >
                      {getDisplayImageUrl() ? (
                        <img
                          className="object-contain object-center h-[200px] px-5"
                          alt=""
                          src={getDisplayImageUrl()}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </Card>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        {!(
          externalLinkData?.type?.key === ExternalLinkTypeEnum.GENERAL &&
          externalLinkData?.authType?.key === ExternalLinkAuthTypeEnum.NOA
        ) ? (
          <div className="flex flex-col gap-4">
            <Card>
              <CardHeader
                icon={faList}
                label={
                  externalLinkData?.type?.key ===
                  ExternalLinkTypeEnum.PERSONALIZED
                    ? t("app.page.external-link.advisors-list.heading") || ""
                    : t("app.page.external-link.configs-list.heading") || ""
                }
              >
                <div className="float-right flex">
                  <div className="mr-2">
                    {externalLinkData?.type?.key ===
                    ExternalLinkTypeEnum.PERSONALIZED ? (
                      <AdvisorSelectModal
                        label={t("button.addAdvisor") || ""}
                        callback={handleSelectAdvisor}
                        initialQuery={{
                          page: 1,
                          size: 10,
                          sort: ["lastname", "asc"],
                          keyword: "",
                          hasValidNbsLicence: true,
                        }}
                      />
                    ) : (
                      <Button
                        className="text-sm px-2 py-1 ml-auto"
                        onClick={() => {
                          setShowLinkConfigModal(true);
                        }}
                        disabled={currentData?.linkConfigurations?.length === 1}
                      >
                        <span>{t("button.addConfig")}</span>
                      </Button>
                    )}
                  </div>
                  <CountBadge count={linkConfigurations?.count} />
                </div>
              </CardHeader>
              <CardBody>
                {externalLinkData?.type?.key ===
                ExternalLinkTypeEnum.PERSONALIZED ? (
                  <div className="flex p-4 mb-4 bg-slate-200 rounded-lg">
                    <div className="flex border border-gray-300 rounded">
                      <span className="w-8 text-center text-gray-300 px-1 border-gray-300 border-r bg-white rounded-l">
                        <FontAwesomeIcon
                          icon={faSearch}
                          className="text-gray-600 mt-1.5"
                        />
                      </span>
                      <input
                        type="text"
                        className="border-none text-gray-700 px-4 text-sm py-1 outline-0 rounded-r"
                        placeholder={t("table.filter.name") || ""}
                        value={searchValue}
                        onKeyUp={(e) => searchCustomers(e)}
                        onChange={(e) => searchFilterChange(e)}
                      />
                    </div>
                    <input
                      type="button"
                      className="ml-1 border border-gray-300 bg-white text-gray-700 px-4 text-sm py-1 outline-0 rounded"
                      value={t("button.search") as string}
                      onClick={(e) => searchCustomers(e)}
                    />
                  </div>
                ) : (
                  ""
                )}

                <table>
                  <thead>
                    <tr>
                      {externalLinkData?.type?.key ===
                      ExternalLinkTypeEnum.PERSONALIZED ? (
                        <td className="w-full">
                          <TableColumnHeadingButton
                            field="name"
                            label={t("table.header.name") || ""}
                            sort={query?.sort}
                            onClick={(field, direction) => {
                              setQuery({
                                ...query,
                                externalLinkId: id ?? "",
                                page: 1,
                                sort: [`${field}:${direction}`],
                              });
                            }}
                          />
                        </td>
                      ) : null}
                      {ExternalLinkAuthTypeEnum.LOG ===
                      externalLinkData?.authType?.key ? (
                        <td>
                          <TableColumnHeadingButton
                            field="login"
                            label={t("table.header.login") || ""}
                            sort={query?.sort}
                            onClick={(field, direction) => {
                              setQuery({
                                ...query,
                                externalLinkId: id ?? "",
                                page: 1,
                                sort: [`${field}:${direction}`],
                              });
                            }}
                          />
                        </td>
                      ) : null}
                      <td>
                        <TableColumnHeadingButton
                          field="status"
                          label={t("table.header.status") || ""}
                          sort={query?.sort}
                          onClick={(field, direction) => {
                            setQuery({
                              ...query,
                              externalLinkId: id ?? "",
                              page: 1,
                              sort: [`${field}:${direction}`],
                            });
                          }}
                        />
                      </td>
                      <td>
                        <span>{t("card.header.actions")}</span>
                      </td>
                    </tr>
                  </thead>
                  {isFetchingConfigs && !linkConfigurations ? (
                    <TableBodyLoading cols={3} rows={query.size ?? 10} />
                  ) : (
                    <tbody>
                      {linkConfigurations
                        ? linkConfigurations?.items?.map((config) => (
                            <tr
                              key={`index-${config.id}`}
                              className="cursor-pointer"
                              onClick={(e: any) => {
                                if (e.target.localName === "td") {
                                  setSelectedLinkConfiguration(config);
                                  setShowLinkConfigModal(true);
                                }
                              }}
                            >
                              {externalLinkData?.type?.key ===
                              ExternalLinkTypeEnum.PERSONALIZED ? (
                                <td className="text-spf-primary">
                                  {`${config.advisorFirstame} ${config.advisorLastname}`}
                                </td>
                              ) : null}
                              {ExternalLinkAuthTypeEnum.LOG ===
                              externalLinkData?.authType?.key ? (
                                <td>{config.login}</td>
                              ) : null}
                              <td>
                                <div
                                  className="flex status-badge"
                                  onClick={() => {
                                    handleChangeConfigStatus(config);
                                  }}
                                  aria-hidden="true"
                                >
                                  <ExternalLinkConfigurationStatusBadge
                                    status={config.status}
                                  />
                                </div>
                              </td>
                              <td>
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  onClick={() => handleRemoveConfig(config)}
                                />
                              </td>
                            </tr>
                          ))
                        : t("table.noData")}
                    </tbody>
                  )}
                </table>
                {!isFetchingConfigs &&
                linkConfigurations?.items?.length === 0 ? (
                  <Alert className="mt-4">
                    <span>{t("table.noData")}</span>
                  </Alert>
                ) : (
                  <span />
                )}
              </CardBody>
              {externalLinkData?.type?.key ===
              ExternalLinkTypeEnum.PERSONALIZED ? (
                <CardFooter>
                  <div className="">
                    <TablePagination
                      callback={setQuery}
                      query={query}
                      count={linkConfigurations?.count ?? 0}
                    />
                  </div>
                  <div className="ml-auto">
                    <TablePageSize query={query} callback={setQuery} />
                  </div>
                </CardFooter>
              ) : (
                ""
              )}
            </Card>
          </div>
        ) : null}
      </div>

      {showLinkConfigModal ? (
        <ExternalLinkConfigurationModal
          item={selectedLinkConfiguration}
          addingAdvisor={selectedAddingAdvisor}
          showModal={showLinkConfigModal}
          setShowModal={setShowLinkConfigModal}
          callback={handleExternalLinkConfigCallback}
        />
      ) : (
        ""
      )}
      <FileUploadModal
        header={t("app.upload.image")}
        callback={(imageId: string, file: File) => {
          const reader = new FileReader();
          reader.addEventListener("loadend", () => {
            setUploadedImageData(reader.result as string);
            setExternalLinkData({
              ...externalLinkData,
              imageId,
            });
            setShowUploadFileModal(false);
          });
          reader.readAsDataURL(file);
        }}
        showModal={showUploadFileModal}
        setShowModal={setShowUploadFileModal}
      />
    </div>
  );
}

export default ExternalLinkEditView;
