import { baseSplitApi as api } from "./baseApi";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    checkValidCustomers: build.mutation<
      CheckValidCustomersApiResponse,
      CheckValidCustomersApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/admin/identifications/check-valid-customers`,
        method: "POST",
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    checkValidCustomersSingle: build.mutation<
      CheckValidCustomersSingleApiResponse,
      CheckValidCustomersSingleApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/admin/identifications/${queryArg.id}/check-valid-customers`,
        method: "POST",
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    getCustomers: build.query<GetCustomersApiResponse, GetCustomersApiArg>({
      query: (queryArg) => ({
        url: `/v1/customers/`,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort: queryArg.sort,
          keyword: queryArg.keyword,
          customerStates: queryArg.customerStates,
          level: queryArg.level,
        },
      }),
    }),
    createCustomer: build.mutation<
      CreateCustomerApiResponse,
      CreateCustomerApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/customers/`,
        method: "POST",
        body: queryArg.customerUpdateRequest,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    findCustomerByType: build.query<
      FindCustomerByTypeApiResponse,
      FindCustomerByTypeApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/customers/find-by-type`,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
        params: {
          typeKey: queryArg.typeKey,
          pin: queryArg.pin,
          ico: queryArg.ico,
        },
      }),
    }),
    migrateCustomerDates: build.mutation<
      MigrateCustomerDatesApiResponse,
      MigrateCustomerDatesApiArg
    >({
      query: (queryArg) => ({ url: `/v1/customers/migrate`, method: "PATCH" }),
    }),
    updateCustomerDisplayNames: build.mutation<
      UpdateCustomerDisplayNamesApiResponse,
      UpdateCustomerDisplayNamesApiArg
    >({
      query: () => ({
        url: `/v1/customers/update-display-names`,
        method: "PATCH",
      }),
    }),
    getCustomerById: build.query<
      GetCustomerByIdApiResponse,
      GetCustomerByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/customers/${queryArg.id}`,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    updateCustomer: build.mutation<
      UpdateCustomerApiResponse,
      UpdateCustomerApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/customers/${queryArg.id}`,
        method: "PUT",
        body: queryArg.customerUpdateRequest,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    initiateCustomerIdentProcess: build.mutation<
      InitiateCustomerIdentProcessApiResponse,
      InitiateCustomerIdentProcessApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/customers/${queryArg.id}/initiate-identification`,
        method: "PATCH",
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    removeCustomerFile: build.mutation<
      RemoveCustomerFileApiResponse,
      RemoveCustomerFileApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/documents/${queryArg.id}/files/${queryArg.fileId}`,
        method: "DELETE",
      }),
    }),
    seedCustomers: build.mutation<
      SeedCustomersApiResponse,
      SeedCustomersApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/seeder/customers/import-local`,
        method: "POST",
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    synchronizeCustomersSingleDiff: build.mutation<
      SynchronizeCustomersSingleDiffApiResponse,
      SynchronizeCustomersSingleDiffApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/synchronization/customers/single-sync-diff/`,
        method: "POST",
        body: queryArg.singleClientSyncRequest,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    synchronizeCustomers: build.mutation<
      SynchronizeCustomersApiResponse,
      SynchronizeCustomersApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/synchronization/customers/${queryArg.days}`,
        method: "POST",
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    synchronizeSendCustomerEmail: build.mutation<
      SynchronizeSendCustomerEmailApiResponse,
      SynchronizeSendCustomerEmailApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/synchronization/send-customer-sync-email`,
        method: "POST",
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    synchronizeCustomerIcmData: build.mutation<
      SynchronizeCustomerIcmDataApiResponse,
      SynchronizeCustomerIcmDataApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/synchronization/update-customer-icm-data`,
        method: "PATCH",
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as spfApi };
export type CheckValidCustomersApiResponse =
  /** status 200 OK */ SuccessResponse200;
export type CheckValidCustomersApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
};
export type CheckValidCustomersSingleApiResponse =
  /** status 200 OK */ SuccessResponse200;
export type CheckValidCustomersSingleApiArg = {
  /** uuid */
  id: string;
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
};
export type GetCustomersApiResponse = /** status 200 OK */ CustomerPage;
export type GetCustomersApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** page */
  page?: number;
  /** size */
  size?: number;
  /** Format is sort=email:desc,display_name:asc. Supported keys: created_at, display_name. */
  sort?: string[];
  /** keyword to search for in customers */
  keyword?: string;
  /** allowed states */
  customerStates?: string[];
  /** visibility level */
  level?: string;
};
export type CreateCustomerApiResponse =
  /** status 201 Created */ SuccessResponse201;
export type CreateCustomerApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** Customer to be updated */
  customerUpdateRequest: CustomerUpdateRequest;
};
export type FindCustomerByTypeApiResponse =
  /** status 200 OK */ FindCustomerByTypeResponse;
export type FindCustomerByTypeApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** type */
  typeKey?: string;
  /** pin */
  pin?: string;
  /** ico */
  ico?: string;
};
export type MigrateCustomerDatesApiResponse =
  /** status 200 ok */ SuccessResponse200[];
export type MigrateCustomerDatesApiArg = {
  /** true */
  doUpdate: boolean;
};
export type UpdateCustomerDisplayNamesApiResponse =
  /** status 200 ok */ SuccessResponse200[];
export type UpdateCustomerDisplayNamesApiArg = void;
export type GetCustomerByIdApiResponse = /** status 200 OK */ Customer;
export type GetCustomerByIdApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** uuid */
  id: string;
};
export type UpdateCustomerApiResponse = /** status 200 OK */ SuccessResponse200;
export type UpdateCustomerApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** uuid */
  id: string;
  /** Customer to be updated */
  customerUpdateRequest: CustomerUpdateRequest;
};
export type InitiateCustomerIdentProcessApiResponse =
  /** status 200 OK */ SuccessResponse200;
export type InitiateCustomerIdentProcessApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** uuid */
  id: string;
};
export type RemoveCustomerFileApiResponse =
  /** status 200 Resource was found */ SuccessResponse200;
export type RemoveCustomerFileApiArg = {
  /** uuid */
  id: string;
  /** uuid */
  fileId: string;
};
export type SeedCustomersApiResponse = /** status 200 OK */ SuccessResponse200;
export type SeedCustomersApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
};
export type SynchronizeCustomersSingleDiffApiResponse =
  /** status 200 actualDiff, diffAfterSync, ICMClient */ SingleCustomerDiffResponse;
export type SynchronizeCustomersSingleDiffApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** pin or ico of customer for diff */
  singleClientSyncRequest: SingleClientSyncRequest;
};
export type SynchronizeCustomersApiResponse =
  /** status 200 OK */ SuccessResponse200;
export type SynchronizeCustomersApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** int */
  days: number;
};
export type SynchronizeSendCustomerEmailApiResponse =
  /** status 200 OK */ SuccessResponse200;
export type SynchronizeSendCustomerEmailApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
};
export type SynchronizeCustomerIcmDataApiResponse =
  /** status 200 ok */ SuccessResponse200;
export type SynchronizeCustomerIcmDataApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
};
export type SuccessResponse200 = {
  id?: string;
  status?: string;
};
export type ValidationError = {
  code?: string;
  field?: string;
  message?: string;
};
export type ErrorResponse400 = {
  code?: number;
  data?: ValidationError[];
  message?: string;
};
export type ErrorResponse401 = {
  code?: number;
  data?: {
    [key: string]: string;
  }[];
  message?: string;
};
export type ErrorResponse500 = {
  code?: number;
  data?: {
    [key: string]: string;
  }[];
  message?: string;
};
export type FilterOptionsKey = "fo-user-owned" | "fo-structural" | "fo-all";
export type CustomerFilter = {
  keyword?: string;
  level?: FilterOptionsKey;
};
export type CountryKey = "sk";
export type Country = {
  key?: CountryKey;
  name?: string;
};
export type Address = {
  city?: string;
  country?: Country;
  street?: string;
  zip?: string;
};
export type ExternalLinkAuthTypeKey = "elat-sso" | "elat-log" | "elat-noa";
export type LinkType = {
  key?: ExternalLinkAuthTypeKey;
  name?: string;
};
export type ExternalLinkCategoryKey =
  | "lct-int"
  | "lct-ins"
  | "lct-bnk"
  | "lct-dss"
  | "lct-oth"
  | "lct-inc";
export type ExternalLinkCategory = {
  key?: ExternalLinkCategoryKey;
  name?: string;
};
export type ImageResponse = {
  type?: string;
  url?: string;
};
export type ExternalLinkConfigurationStatusKey =
  | "elcs-active"
  | "elcs-deactivated";
export type ExternalLinkConfigurationStatus = {
  key?: ExternalLinkConfigurationStatusKey;
  name?: string;
};
export type ExternalLinkConfiguration = {
  advisorExternalID?: string;
  advisorFirstame?: string;
  advisorId?: string;
  advisorLastname?: string;
  createdAt?: string;
  id?: string;
  login?: string;
  password?: string;
  status?: ExternalLinkConfigurationStatus;
  updatedAt?: string;
};
export type AdvisorSectorTypeKey =
  | "ast-aml"
  | "ast-ins"
  | "ast-len"
  | "ast-acc"
  | "ast-cap"
  | "ast-sup"
  | "ast-old";
export type AdvisorSectorType = {
  key?: AdvisorSectorTypeKey;
  name?: string;
};
export type ExternalLinkSector = {
  mandatory?: boolean;
  sectorType?: AdvisorSectorType;
};
export type ExternalLinkStatusKey = "els-active" | "els-deactivated";
export type ExternalLinkStatus = {
  key?: ExternalLinkStatusKey;
  name?: string;
};
export type ExternalLinkTypeKey = "ast-per" | "ast-gen";
export type ExternalLinkType = {
  key?: ExternalLinkTypeKey;
  name?: string;
};
export type ExternalLink = {
  authType?: LinkType;
  category?: ExternalLinkCategory;
  createdAt?: string;
  displayLink?: string;
  id?: string;
  image?: ImageResponse[];
  imageId?: string;
  linkConfigurations?: ExternalLinkConfiguration[];
  name?: string;
  order?: number;
  sectors?: ExternalLinkSector[];
  status?: ExternalLinkStatus;
  type?: ExternalLinkType;
  updatedAt?: string;
};
export type FavouriteExternalLink = {
  createdAt?: string;
  externalLink?: ExternalLink;
  order?: number;
  updatedAt?: string;
};
export type GenderKey = "gk-male" | "gk-female" | "gk-none";
export type Gender = {
  key?: GenderKey;
  name?: string;
};
export type AdvisorPositionKey =
  | "SP"
  | "KR"
  | "RR"
  | "OR"
  | "M"
  | "S3"
  | "S2"
  | "S1"
  | "SK"
  | "K2"
  | "K1"
  | "T"
  | "BP"
  | "BP90"
  | "BP85"
  | "BP70"
  | "BP00"
  | "FEL1"
  | "FEL2"
  | "FEL3"
  | "FEL4";
export type AdvisorPosition = {
  higherMLM?: AdvisorPositionKey;
  key?: AdvisorPositionKey;
  name?: string;
};
export type AdvisorSector = {
  certificate_from?: string;
  certificate_to?: string;
  createdAt?: string;
  id?: string;
  ofv_from?: string;
  ofv_to?: string;
  registration_from?: string;
  registration_to?: string;
  type?: AdvisorSectorType;
  updatedAt?: string;
};
export type AdvisorTypeKey = "at-fo" | "at-fop";
export type AdvisorType = {
  key?: AdvisorTypeKey;
  name?: string;
};
export type OriginSystemKey = "uo-portal" | "uo-icm";
export type OriginSystem = {
  key?: OriginSystemKey;
  name?: string;
};
export type UserStatusKey = "us-active" | "us-deactivated" | "us-resigned";
export type UserStatus = {
  key?: UserStatusKey;
  name?: string;
};
export type User = {
  createdAt?: string;
  displayName: string;
  email: string;
  id?: string;
  originSystem?: OriginSystem;
  status?: UserStatus;
  updatedAt?: string;
};
export type Advisor = {
  businessAddress?: Address;
  businessName?: string;
  createdAt?: string;
  displayName?: string;
  evidenceNumber: string;
  externalId?: string;
  favouriteLinks?: FavouriteExternalLink[];
  firstname?: string;
  gender?: Gender;
  ico?: string;
  id?: string;
  lastname?: string;
  mentor?: Advisor;
  nbsRegistrationNumber?: string;
  parent?: Advisor;
  permanentAddress?: Address;
  phone_number?: string;
  pin?: string;
  position?: AdvisorPosition;
  sectors?: AdvisorSector[];
  title?: string;
  type?: AdvisorType;
  updatedAt?: string;
  updatedBy?: User;
  updatedByICM?: string;
  user?: User;
};
export type AdvisorCustomerOriginKey = "aco-icm" | "aco-portal";
export type CustomerOrigin = {
  key?: AdvisorCustomerOriginKey;
  name?: string;
};
export type AvisorCustomer = {
  advisor?: Advisor;
  origin?: CustomerOrigin;
};
export type IcmPortalAddressResponse = {
  city?: string;
  street?: string;
  zip?: string;
};
export type IcmPortalCustomerResponse = {
  authorizedPersonPosition?: string;
  businessAddress?: IcmPortalAddressResponse;
  businessName?: string;
  cop?: string;
  correspondenceAddress?: IcmPortalAddressResponse;
  dateOfBirth?: string;
  email?: string;
  firstname?: string;
  foreignClient?: boolean;
  genderKey?: GenderKey;
  ico?: string;
  lastname?: string;
  note?: string;
  otherIDCardNumber?: string;
  passportNumber?: string;
  permanentAddress?: IcmPortalAddressResponse;
  phoneNumber?: string;
  pin?: string;
  title?: string;
};
export type CustomerTypeKey = "ct-fo" | "ct-fop" | "ct-po";
export type CustomerType = {
  key?: CustomerTypeKey;
  name?: string;
};
export type Customer = {
  advisors?: AvisorCustomer[];
  authorizedPersonPosition?: string;
  businessAddress?: Address;
  businessName?: string;
  citizenship?: string;
  clientDisplayName?: string;
  contractAgreement?: boolean;
  contractSignedAt?: string;
  cop?: string;
  correspondenceAddress?: Address;
  createdAt?: string;
  dateOfBirth?: string;
  dateOfConsent?: string;
  email?: string;
  firstname?: string;
  foreignClient?: boolean;
  gender?: Gender;
  icmDiff?: IcmPortalCustomerResponse;
  icmID?: string;
  icmStatus?: boolean;
  ico?: string;
  id?: string;
  lastIDGuardIdentification?: string;
  lastname?: string;
  note?: string;
  opValidUntil?: string;
  origin?: OriginSystem;
  otherIDCardNumber?: string;
  otherIDCardType?: string;
  otherIDCardValidUntil?: string;
  pasValidUntil?: string;
  passportNumber?: string;
  permanentAddress?: Address;
  personalAdvisor?: Advisor;
  personalMarketingInformation?: boolean;
  phoneNumber?: string;
  pin?: string;
  profession?: string;
  title?: string;
  type?: CustomerType;
  updatedAt?: string;
  updatedBy?: User;
  updatedByICM?: string;
  validIdentificationId?: string;
};
export type SortFieldDirection = "asc" | "desc";
export type SortFieldKey =
  | "createdAt"
  | "updatedAt"
  | "startsAt"
  | "validTo"
  | "validUntil"
  | "firstname"
  | "lastname"
  | "name"
  | "displayName"
  | "evidenceNumber"
  | "email"
  | "pin"
  | "ico"
  | "advisorEmail"
  | "customerEmail"
  | "mentorEmail"
  | "phoneNumber"
  | "displayId"
  | "street"
  | "city"
  | "zip"
  | "id"
  | "owner"
  | "type"
  | "status"
  | "customer"
  | "advisor"
  | "updatedBy";
export type SortField = {
  direction?: SortFieldDirection;
  field?: SortFieldKey;
};
export type CustomerPage = {
  count?: number;
  filter?: CustomerFilter;
  items?: Customer[];
  last?: boolean;
  page?: number;
  size?: number;
  sort?: SortField[];
};
export type SuccessResponse201 = {
  id?: string;
};
export type AddressUpdateRequest = {
  city?: string;
  countryKey?: CountryKey;
  street?: string;
  zip?: string;
};
export type CustomerUpdateRequest = {
  authorizedPersonPosition?: string;
  businessAddress?: AddressUpdateRequest;
  businessName?: string;
  citizenship?: string;
  contractAgreement?: boolean;
  contractSignedAt?: string;
  cop?: string;
  correspondenceAddress?: AddressUpdateRequest;
  dateOfBirth?: string;
  dateOfConsent?: string;
  email: string;
  firstname: string;
  foreignClient?: boolean;
  genderKey?: GenderKey;
  ico?: string;
  lastname: string;
  note?: string;
  opValidUntil?: string;
  otherIDCardNumber?: string;
  otherIDCardType?: string;
  otherIDCardValidUntil?: string;
  pasValidUntil?: string;
  passportNumber?: string;
  permanentAddress?: AddressUpdateRequest;
  personalMarketingInformation?: boolean;
  phoneNumber?: string;
  pin?: string;
  profession?: string;
  title?: string;
  typeKey?: CustomerTypeKey;
};
export type FindCustomerByTypeResponse = {
  id?: string;
  in_advisors?: boolean;
};
export type ErrorResponse404 = {
  code?: number;
  data?: {
    [key: string]: string;
  }[];
  message?: string;
};
export type ErrorResponse403 = {
  code?: number;
  data?: {
    [key: string]: string;
  }[];
  message?: string;
};
export type ModelIcmAddress = {
  city?: string;
  street?: string;
  zip?: string;
};
export type ModelIcmTime = {
  "time.Time"?: string;
};
export type ModelIcmGenderKey = "muz" | "zena" | "ziadne";
export type ModelGcClientsAdvisorFields = {
  name?: string;
  personalNumber?: string;
  surname?: string;
};
export type ModelGcClientsTypeKey = "FO" | "FOP" | "PO";
export type GcClient = {
  authorizedPersonPosition?: string;
  businessAddress?: ModelIcmAddress;
  businessname?: string;
  contractAgreement?: boolean;
  contractSignedAt?: ModelIcmTime;
  cop?: string;
  correspondenceAddress?: ModelIcmAddress;
  dateOfBirth?: ModelIcmTime;
  dateOfConsent?: ModelIcmTime;
  email?: string;
  firstname?: string;
  foregnClient?: boolean;
  gender?: ModelIcmGenderKey;
  hasRights?: ModelGcClientsAdvisorFields[];
  icmId?: string;
  icmStatus?: boolean;
  ico?: string;
  lastUpdatedAt?: ModelIcmTime;
  lastname?: string;
  note?: string;
  otherIdCardNumber?: string;
  passportNumber?: string;
  permanentAddress?: ModelIcmAddress;
  personalAdvisorFields?: ModelGcClientsAdvisorFields;
  personalMarketingInformation?: boolean;
  phoneNumber?: string;
  pin?: string;
  title?: string;
  type?: ModelGcClientsTypeKey;
  updatedByIcm?: string;
};
export type SingleCustomerDiffResponse = {
  actualDiff?: string;
  diffAfterSync?: string;
  icmClientToSync?: GcClient;
};
export type SingleClientSyncRequest = {
  ico?: string;
  pin?: string;
};
export const {
  useCheckValidCustomersMutation,
  useCheckValidCustomersSingleMutation,
  useGetCustomersQuery,
  useLazyGetCustomersQuery,
  useCreateCustomerMutation,
  useFindCustomerByTypeQuery,
  useLazyFindCustomerByTypeQuery,
  useMigrateCustomerDatesMutation,
  useUpdateCustomerDisplayNamesMutation,
  useGetCustomerByIdQuery,
  useLazyGetCustomerByIdQuery,
  useUpdateCustomerMutation,
  useInitiateCustomerIdentProcessMutation,
  useRemoveCustomerFileMutation,
  useSeedCustomersMutation,
  useSynchronizeCustomersSingleDiffMutation,
  useSynchronizeCustomersMutation,
  useSynchronizeSendCustomerEmailMutation,
  useSynchronizeCustomerIcmDataMutation,
} = injectedRtkApi;
