import React from "react";

import { Navigate, useRoutes } from "react-router";
import { createBrowserRouter } from "react-router-dom";

import { DocumentTypeKeys } from "../../enums/DocumentTypes";
import { DocumentMode } from "../../models/DocumentMode";
import AdvisorEditView from "../advisors/AdvisorEditView";
import AdvisorsAddView from "../advisors/AdvisorsAddView";
import AdvisorsListView from "../advisors/AdvisorsListView";
import LoginView from "../auth/LoginView";
import ResetPasswordView from "../auth/ResetPasswordView";
import SSORedirectView from "../auth/SSORedirectView";
import CustomersAddView from "../customers/CustomerAddView";
import CustomerEditView from "../customers/CustomerEditView";
import CustomersListView from "../customers/CustomersListView";
import DashboardView from "../dashboard/DashboardView";
import DocumentCheckFormView from "../documents/DocumentCheckFormView";
import DocumentCheckView from "../documents/DocumentCheckView";
import DocumentsListView from "../documents/DocumentsListView";
import DocumentTypeSelector from "../documents/DocumentTypeSelector";
import DocumentZuzFormView from "../documents/DocumentZuzFormView";
import DocumentZuzView from "../documents/DocumentZuzView";
import Error404View from "../error/Error404View";
import EventEditView from "../events/EventEditView";
import EventsListView from "../events/EventsListView";
import ExternalLinkAddView from "../external-links/ExternalLinkAddView";
import ExternalLinkEditView from "../external-links/ExternalLinkEditView";
import ExternalLinksDashboardView from "../external-links/ExternalLinksDashboardView";
import ExternalLinksImportView from "../external-links/ExternalLinksImportView";
import ExternalLinksListView from "../external-links/ExternalLinksListView";
import IdentificationDetailView from "../identifications/IdentificationDetailView";
import IdentificationsListView from "../identifications/IdentificationsListView";
import PrivateContent from "../layout/PrivateContent";
import MatrixDetailView from "../system/MatrixDetailView";
import MatrixListView from "../system/MatrixListView";
import RulesListView from "../system/RulesListView";
import RuleView from "../system/RuleView";
import SystemManagementView from "../system/SystemManagementView";
import UserEditView from "../users/UserEditView";
import UserSettingsView from "../users/UserSettingsView";
import UsersListView from "../users/UsersListView";

const router = createBrowserRouter([
  {
    index: true,
    path: "/",
    element: <Navigate to="/admin" />,
  },
  {
    index: true,
    path: "/auth/login",
    element: <LoginView />,
  },
  {
    index: true,
    path: "/auth/sso-login",
    element: <SSORedirectView />,
  },
  {
    index: true,
    path: "/auth/reset-password/:token",
    element: <ResetPasswordView />,
  },
  {
    path: "/admin",
    element: <PrivateContent />,
    children: [
      {
        path: "dashboard",
        element: <DashboardView />,
      },
      {
        path: "user/settings",
        element: <UserSettingsView />,
      },
      {
        path: "users/list",
        element: <UsersListView />,
      },
      {
        path: "advisors/list",
        element: <AdvisorsListView />,
      },
      {
        path: "advisors/add",
        element: <AdvisorsAddView />,
      },
      {
        path: "advisors/edit/:id",
        element: <AdvisorEditView />,
      },
      {
        path: "advisors/edit/:id/history/:historyId",
        element: <AdvisorEditView />,
      },
      {
        path: "customers/list",
        element: <CustomersListView />,
      },
      {
        path: "rules/list",
        element: <RulesListView />,
      },
      {
        path: "rules/add",
        element: <RuleView />,
      },
      {
        path: "rules/edit/:id",
        element: <RuleView />,
      },
      {
        path: "rules/matrix",
        element: <MatrixListView />,
      },
      {
        path: "rules/matrix/detail/:id",
        element: <MatrixDetailView />,
      },
      {
        path: "customers/add",
        element: <CustomersAddView />,
      },
      {
        path: "customers/edit/:id",
        element: <CustomerEditView />,
      },
      {
        path: "customers/edit/:id/history/:historyId",
        element: <CustomerEditView />,
      },
      {
        path: "documents/list/zuz",
        element: (
          <DocumentsListView
            type={DocumentTypeKeys.ZUZ}
            key={DocumentTypeKeys.ZUZ}
          />
        ),
      },
      {
        path: "documents/list/check",
        element: (
          <DocumentsListView
            type={DocumentTypeKeys.CHECK_FORM}
            key={DocumentTypeKeys.CHECK_FORM}
          />
        ),
      },
      {
        path: "identification/:id",
        element: <IdentificationDetailView />,
      },
      {
        path: "identifications/list",
        element: <IdentificationsListView />,
      },
      {
        path: "documents/add",
        element: <DocumentTypeSelector />,
      },
      {
        path: "documents/add/zuz",
        element: <DocumentZuzFormView mode={DocumentMode.CREATE} />,
      },
      {
        path: "documents/zuz/:id/edit",
        element: <DocumentZuzFormView mode={DocumentMode.EDIT} />,
      },
      {
        path: "documents/add/check",
        element: <DocumentCheckFormView mode={DocumentMode.CREATE} />,
      },
      {
        path: "documents/check/:id/edit",
        element: <DocumentCheckFormView mode={DocumentMode.EDIT} />,
      },
      {
        path: "documents/zuz/:id",
        element: <DocumentZuzView />,
      },
      {
        path: "documents/check/:id",
        element: <DocumentCheckView />,
      },
      {
        path: "users/edit/:id",
        element: <UserEditView />,
      },
      {
        path: "system/management",
        element: <SystemManagementView />,
      },
      {
        path: "my-external-links",
        element: <ExternalLinksDashboardView />,
      },
      {
        path: "external-links",
        element: <ExternalLinksListView />,
      },
      {
        path: "external-links/edit/:id",
        element: <ExternalLinkEditView />,
      },
      {
        path: "external-links/add",
        element: <ExternalLinkAddView />,
      },
      {
        path: "external-links/import",
        element: <ExternalLinksImportView />,
      },
      {
        path: "events/list",
        element: <EventsListView />,
      },
      {
        path: "events/edit/:id",
        element: <EventEditView />,
      },
      {
        path: "*",
        element: <Error404View />,
      },
    ],
  },
  {
    path: "*",
    element: <Error404View />,
  },
]);

export default router;
