import React from "react";

import { useTranslation } from "react-i18next";

import CustomerType from "../../enums/CustomerType";

interface Properties {
  label: string;
  selected: string;
  onSelect: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

function UserSelect({ label, onSelect, selected }: Properties) {
  const { t } = useTranslation();

  return (
    <div className="mb-3">
      <label
        htmlFor="customer-type-select"
        className="block text-gray-500 text-sm mb-2 truncate"
      >
        {label}
      </label>
      <select
        name="customer-type-select"
        className="form-select
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding bg-no-repeat
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
        onChange={onSelect}
        aria-label="Default select example"
      >
        <option hidden>Choose</option>
        {CustomerType?.map((type, index) => (
          <option
            value={type.key}
            key={`user-option-${type.key}`}
            selected={type.key === selected}
          >
            <span>{t(type.name)}</span>
          </option>
        ))}
      </select>
    </div>
  );
}

export default UserSelect;
