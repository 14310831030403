import React from "react";

import { InvestmentCustomerType } from "../../../../enums/InvestmentCustomerType";

interface Props {
  selected: InvestmentCustomerType | undefined;
  onChange: (value: InvestmentCustomerType) => void;
}

function InvestmentCustomerTypeSelect({ selected, onChange }: Props) {
  return (
    <>
      <h3 className="border border-slate-300 bg-slate-200 p-2 font-bold">
        Investícia pre
      </h3>
      <div className="flex flex-row gap-8 py-4">
        <label
          htmlFor="clientInvestmenrCustomerType"
          className="flex items-center gap-2"
        >
          <input
            id="clientInvestmenrCustomerType"
            name="clientInvestmenrCustomerType"
            type="radio"
            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
            checked={selected === InvestmentCustomerType.CLIENT}
            onChange={() => onChange(InvestmentCustomerType.CLIENT)}
            data-testid="/documents/zuz/esg/input-clientInvestmenrCustomerType"
          />
          Klient
        </label>
        <label
          htmlFor="partnerInvestmenrCustomerType"
          className="flex items-center gap-2"
        >
          <input
            id="partnerInvestmenrCustomerType"
            name="partnerInvestmenrCustomerType"
            type="radio"
            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
            checked={selected === InvestmentCustomerType.PARTNER}
            onChange={() => onChange(InvestmentCustomerType.PARTNER)}
            data-testid="/documents/zuz/esg/input-partnerInvestmenrCustomerType"
          />
          Partner
        </label>
      </div>
    </>
  );
}

export default InvestmentCustomerTypeSelect;
