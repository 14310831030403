enum PermissionRuleType {
  ALL = "pr-all",
  ADVISOR_POSITION = "pr-advisor-position",
  ROLE = "pr-role",
  USER_ROLE = "pr-user-role",
  ADVISOR_TREE = "pr-advisor-tree",
  ADVISOR_CHILDREN = "pr-advisor-children",
}

export default PermissionRuleType;
