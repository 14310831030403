import React from "react";

import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import { FilterOptionsKey } from "../../api/documents.generated";
import { DocumentTypeKeys } from "../../enums/DocumentTypes";
import { FilterLevel } from "../../enums/FilterLevel";
import { documentStatusZuz } from "../../enums/Status";

interface Properties {
  callback: (name: FilterOptionsKey) => void | undefined;
  filters: string;
  className?: string;
}

function LevelFilter({ filters, callback, className = "" }: Properties) {
  const { t } = useTranslation();
  const handleChange = (type: FilterOptionsKey): void => {
    callback(type);
  };

  const lastIndex = documentStatusZuz.length - 1;

  const drawIcons = (icons: IconDefinition[]) => {
    // if is onlny one icon
    if (icons.length === 1) {
      return <FontAwesomeIcon icon={icons[0]} />;
    }

    return (
      <>
        {icons.map((icon, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <span key={`icon-${index}`}>
            <FontAwesomeIcon icon={icon} />
            {index !== icons.length - 1 ? (
              <span className="pl-1 pr-1">+</span>
            ) : null}
          </span>
        ))}
      </>
    );
  };

  return (
    <div
      className={`inline-block rounded border border-gray-300 text-sm overflow-hidden ${className}`}
    >
      {FilterLevel.map((i, index) => (
        <button
          key={`document-status-filter-item-${i.type}`}
          onClick={() => handleChange(i.type)}
          type="button"
          title={t(i.name) ?? ""}
          className={
            `py-1 px-3 text-white ` +
            `${
              filters.includes(i.type)
                ? `${i.activeBgClass} hover:bg-opacity-90 `
                : "bg-white text-gray-600 hover:bg-gray-100 "
            } ` +
            `${index !== lastIndex ? "border-r border-gray-300 " : ""}`
          }
        >
          {drawIcons(i.icon)}
        </button>
      ))}
    </div>
  );
}

LevelFilter.defaultProps = {
  className: "",
};

export default LevelFilter;
